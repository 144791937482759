import React from 'react'
import { Select } from 'antd'

/**
 * The reason why this is a normal function and not a react component is that we don't want to
 * add the key to the actual component otherwise it would be pointless to wrap this Select.Option in another component
 */
const createOption = (labelProp, valueProp) => item =>
  <Select.Option key={item[valueProp]}>{item[labelProp]}</Select.Option>

export default createOption
