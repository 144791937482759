import { filter, flatten, get, isNil, keys, map } from 'lodash'

import { formatDescriptors, mergeArrayOfObjects } from '../../common/utils'

import { DISH_FIELDS } from '../constants'
import extractHighlightListFromAiIngredients from '../services/extractHighlightListFromAiIngredients'

const groupHighlightingWordByDishId = (
  sourceDishList,
  isSimpleView,
  highlightsFromLabelLegends,
) =>
  mergeArrayOfObjects(sourceDishList, (acc, dish) => {
    const aiIngredientsOfDish = [
      ...(isSimpleView
        ? []
        : [
            ...filter(dish.mainIngredients, 'isSuggestedUsingParser'),
            ...filter(dish.choiceIngredients, 'isSuggestedUsingParser'),
            ...(dish.excludedIngredients || []),
            ...flatten(
              map(
                get(dish, 'aiSuggestions.diets.predictions'),
                dietPrediction =>
                  flatten(
                    map(dietPrediction.occurrences, occurrence => ({
                      ...occurrence,
                      originField: !isNil(occurrence.menuTitleId)
                        ? `${occurrence.menuTitleId}:${occurrence.originField}`
                        : occurrence.originField,
                      matchType: 'labelOccurrence',
                    })),
                  ),
              ),
            ),
            ...flatten(
              map(
                get(dish, 'aiSuggestions.allergens.predictions'),
                allergenPrediction =>
                  flatten(
                    map(allergenPrediction.occurrences, occurrence => ({
                      ...occurrence,
                      originField: !isNil(occurrence.menuTitleId)
                        ? `${occurrence.menuTitleId}:${occurrence.originField}`
                        : occurrence.originField,
                      matchType: 'labelOccurrence',
                    })),
                  ),
              ),
            ),
          ]),
      ...filter(
        highlightsFromLabelLegends,
        brandHighlight => brandHighlight.dishId === dish.id,
      ),
    ]

    const fieldsForHighlighting = {
      [DISH_FIELDS.NAME]: dish.name,
      [DISH_FIELDS.DESCRIPTION]: dish.description,
      [DISH_FIELDS.INGREDIENTS_TEXT]: dish.ingredientsText,
      ...(isSimpleView
        ? {}
        : {
            [DISH_FIELDS.ADDON_DESCRIPTORS]: formatDescriptors(
              dish.addonDescriptors,
            ),
            ...mergeArrayOfObjects(
              map(dish.allergenDescriptors, (allergenDescriptor, index) => ({
                [`allergenDescriptors:${index}`]: allergenDescriptor,
              })),
            ),

            ...mergeArrayOfObjects(
              map(dish.dietDescriptors, (dietDescriptor, index) => {
                const parserDietDescriptor = JSON.parse(dietDescriptor)
                return mergeArrayOfObjects(
                  map(keys(parserDietDescriptor), dietDescriptorKey => ({
                    [`dietDescriptors:${dietDescriptorKey}:${index}`]: get(
                      parserDietDescriptor,
                      dietDescriptorKey,
                    ),
                  })),
                )
              }),
            ),
            ...mergeArrayOfObjects(
              map(
                dish.menuTitleParents,
                ({
                  menuTitleId,
                  menuTitleDescription,
                  menuTitleAddonDescriptors,
                  menuTitleAllergenDescriptors,
                  menuTitleDietDescriptors,
                }) => ({
                  [`menuTitleDescriptionId_${menuTitleId}`]:
                    menuTitleDescription || '',
                  ...mergeArrayOfObjects([
                    ...map(
                      menuTitleAddonDescriptors,
                      (addonDescriptor, index) => ({
                        [`menuTitleAddonDescriptorId_${menuTitleId}_Index_${index}`]:
                          addonDescriptor,
                      }),
                    ),
                    ...map(
                      menuTitleAllergenDescriptors,
                      (allergenDescriptor, index) => ({
                        [`${menuTitleId}:allergenDescriptors:${index}`]:
                          allergenDescriptor,
                      }),
                    ),
                    ...map(
                      menuTitleDietDescriptors,
                      (dietDescriptor, index) => {
                        const parserDietDescriptor = JSON.parse(dietDescriptor)
                        return mergeArrayOfObjects(
                          map(
                            keys(parserDietDescriptor),
                            dietDescriptorKey => ({
                              [`${menuTitleId}:dietDescriptors:${dietDescriptorKey}:${index}`]:
                                get(parserDietDescriptor, dietDescriptorKey),
                            }),
                          ),
                        )
                      },
                    ),
                  ]),
                }),
              ),
            ),
          }),
    }

    const highlights = extractHighlightListFromAiIngredients(
      aiIngredientsOfDish,
      fieldsForHighlighting,
      dish.additionalIngredients,
    )

    return {
      ...acc,
      [dish.id]: highlights,
    }
  })

export default groupHighlightingWordByDishId
