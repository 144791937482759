import React, { useMemo } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import update from 'immutability-helper'
import { map, orderBy, reduce, unionBy } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import PropTypes from 'prop-types'

import { TimeSlot } from '../../../../common/components'
import { WEEKDAYS } from '../../../../common/constants'

const defaultTimeSlot = {
  openTime: '12:00:00',
  closeTime: '22:00:00',
  kitchenCloseTime: '22:00:00',
}

const defaultWorkingHour = {
  disabled: true,
  timeSlots: [defaultTimeSlot],
}

const defaultWorkingHours = map(WEEKDAYS, (_, index) => ({
  ...defaultWorkingHour,
  dayOfWeek: index,
}))

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  dayOfWeekCheckbox: {
    width: 110,
  },
  timeSlotLabelOffset: {
    marginLeft: 115,
  },
  timeSlotLabel: {
    width: 100,
    textAlign: 'center',
    marginRight: 5,
    fontWeight: 500,
  },
  timeSlotContainer: {
    flexDirection: 'row',
  },
  secondTimeSlot: {
    marginLeft: 5,
  },
  addRemoveButton: {
    marginLeft: 5,
  },
}

const DayHours = ({ workingHour, onValueChanged, disabled: formDisabled }) => {
  const {
    disabled: workingHourDisabled,
    dayOfWeek,
    timeSlots,
    isDefault,
  } = workingHour
  const disabled = formDisabled || workingHourDisabled

  return (
    <div style={styles.container}>
      <Checkbox
        checked={!disabled}
        onChange={({ target: { checked } }) => {
          onValueChanged({ ...workingHour, disabled: !checked })
        }}
        style={styles.dayOfWeekCheckbox}
        disabled={formDisabled}
      >
        {WEEKDAYS[dayOfWeek]}
      </Checkbox>
      <TimeSlot
        disabled={disabled}
        openTime={timeSlots[0].openTime}
        closeTime={timeSlots[0].closeTime}
        kitchenCloseTime={timeSlots[0].kitchenCloseTime}
        onValueChanged={timeSlot => {
          const newTimeSlots = update(timeSlots, { 0: { $set: timeSlot } })
          onValueChanged({ ...workingHour, timeSlots: newTimeSlots })
        }}
      />
      {timeSlots.length > 1 && (
        <TimeSlot
          disabled={disabled}
          openTime={timeSlots[1].openTime}
          closeTime={timeSlots[1].closeTime}
          kitchenCloseTime={timeSlots[1].kitchenCloseTime}
          style={styles.secondTimeSlot}
          onValueChanged={timeSlot => {
            const newTimeSlots = update(timeSlots, { 1: { $set: timeSlot } })
            onValueChanged({ ...workingHour, timeSlots: newTimeSlots })
          }}
        />
      )}
      {timeSlots.length > 1 ? (
        <Button
          size="small"
          icon={<MinusOutlined />}
          shape="circle"
          style={styles.addRemoveButton}
          disabled={disabled}
          onClick={() => {
            const newTimeSlots = update(timeSlots, { $splice: [[1, 1]] })
            onValueChanged({ ...workingHour, timeSlots: newTimeSlots })
          }}
        />
      ) : (
        <Button
          size="small"
          icon={<PlusOutlined />}
          shape="circle"
          style={styles.addRemoveButton}
          disabled={disabled}
          onClick={() => {
            const newTimeSlots = update(timeSlots, { $push: [defaultTimeSlot] })
            onValueChanged({ ...workingHour, timeSlots: newTimeSlots })
          }}
        />
      )}
      {isDefault && (
        <span>
          <sup>&nbsp;*default value</sup>
        </span>
      )}
    </div>
  )
}

const timeSlotPropType = PropTypes.shape({
  openTime: PropTypes.string.isRequired,
  closeTime: PropTypes.string.isRequired,
  kitchenCloseTime: PropTypes.string.isRequired,
})

const workingHourPropType = PropTypes.shape({
  disabled: PropTypes.bool.isRequired,
  dayOfWeek: PropTypes.number.isRequired,
  timeSlots: PropTypes.arrayOf(timeSlotPropType).isRequired,
  isDefault: PropTypes.bool,
})

DayHours.propTypes = {
  workingHour: workingHourPropType.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

DayHours.defaultProps = {
  disabled: false,
}

const dayOfWeekProperty = 'dayOfWeek'

export default function WorkingHours({
  workingHours,
  newWorkingHours,
  onValueChanged,
  disabled,
}) {
  const nonNullWorkingHours = orderBy(
    unionBy(
      newWorkingHours || workingHours,
      defaultWorkingHours,
      dayOfWeekProperty,
    ),
    dayOfWeekProperty,
  )

  const timeSlotMaxLength = useMemo(
    () =>
      reduce(
        nonNullWorkingHours,
        (acc, current) =>
          current.timeSlots.length > acc ? current.timeSlots.length : acc,
        1,
      ),
    [nonNullWorkingHours],
  )

  return (
    <div>
      <div style={styles.container}>
        <div style={{ ...styles.timeSlotLabel, ...styles.timeSlotLabelOffset }}>
          Opens
        </div>
        <div style={styles.timeSlotLabel}>Closes</div>
        <div style={styles.timeSlotLabel}>Kitchen Cl.</div>
        {timeSlotMaxLength > 1 && (
          <>
            <div style={{ ...styles.timeSlotLabel, ...styles.secondTimeSlot }}>
              Opens
            </div>
            <div style={styles.timeSlotLabel}>Closes</div>
            <div style={styles.timeSlotLabel}>Kitchen Cl.</div>
          </>
        )}
      </div>
      {map(nonNullWorkingHours, workingHour => (
        <DayHours
          key={workingHour.dayOfWeek}
          workingHour={workingHour}
          onValueChanged={wh => {
            const { dayOfWeek: index } = wh
            const updatedWorkingHours = update(nonNullWorkingHours, {
              [index]: { $set: { ...wh, isDefault: false } },
            })

            // As the data may come from Apollo (when the brand has working hours in the db and nothing is yet saved
            // in the redux store) then we have __typename properties in the working hours object. We want to omit this
            // property (__typename) as executing the upsert brand mutation with them fails.
            const workingHoursWithoutTypename = omitDeep(
              updatedWorkingHours,
              '__typename',
            )
            onValueChanged(workingHoursWithoutTypename)
          }}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

WorkingHours.propTypes = {
  workingHours: PropTypes.arrayOf(workingHourPropType),
  newWorkingHours: PropTypes.arrayOf(workingHourPropType),
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

WorkingHours.defaultProps = {
  workingHours: undefined,
  newWorkingHours: undefined,
  disabled: false,
}
