import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { DayOfWeekDropdown } from '../DayOfWeekDropdown'

const DaysOfWeek = ({
  disabled,
  startDayOfWeek,
  endDayOfWeek,
  onValueChanged,
}) => {
  const handleStartDayChange = useCallback(
    value => onValueChanged({ startDayOfWeek: value, endDayOfWeek }),
    [endDayOfWeek, onValueChanged],
  )

  const handleEndDayChange = useCallback(
    value => onValueChanged({ startDayOfWeek, endDayOfWeek: value }),
    [onValueChanged, startDayOfWeek],
  )
  return (
    <>
      <DayOfWeekDropdown
        selectedDayOfWeek={startDayOfWeek}
        onValueChanged={handleStartDayChange}
        disabled={disabled}
      />
      <DayOfWeekDropdown
        selectedDayOfWeek={endDayOfWeek}
        onValueChanged={handleEndDayChange}
        disabled={disabled}
      />
    </>
  )
}

DaysOfWeek.propTypes = {
  disabled: PropTypes.bool.isRequired,
  startDayOfWeek: PropTypes.number.isRequired,
  endDayOfWeek: PropTypes.number.isRequired,
  onValueChanged: PropTypes.func.isRequired,
}

export default DaysOfWeek
