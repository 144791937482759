import React, { useCallback } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm, Spin } from 'antd'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAuthentication } from '../../../../common/hooks'

import { DEFAULT_CLEANUP_TASKS_FILTERS } from '../../../constants'
import {
  CLEANUP_TASKS_QUERY,
  DELETE_CLEANUP_TASK_GROUP_MUTATION,
} from '../../../graphql'
import { cleanupTasksMetadataTypePropType } from '../../../propTypes'

const CleanupTaskDeleteButton = ({ taskId, metadataType, disabled }) => {
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    status = DEFAULT_CLEANUP_TASKS_FILTERS.STATUS,
    type = DEFAULT_CLEANUP_TASKS_FILTERS.TYPE,
    assignedUserId = DEFAULT_CLEANUP_TASKS_FILTERS.ASSIGNED_USER,
  } = useQueryParams()
  const [deleteTask, { loading }] = useMutation(
    DELETE_CLEANUP_TASK_GROUP_MUTATION,
    {
      refetchQueries: [
        {
          query: CLEANUP_TASKS_QUERY,
          variables: {
            metadataType,
            currentPage: Number(page),
            pageSize: Number(pageSize),
            filters: {
              status,
              type,
              assignedUserId,
            },
          },
          onError: handleMutationError,
        },
      ],
    },
  )

  const handleDelete = useCallback(async () => {
    await deleteTask({
      variables: {
        id: taskId,
        metadataType,
      },
    })
  }, [deleteTask, metadataType, taskId])

  if (loading) {
    return <Spin size="small" />
  }

  return (
    <Popconfirm
      placement="left"
      title="Are you sure you want to delete this task?"
      onConfirm={handleDelete}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
    >
      <Button
        size="small"
        icon={<DeleteOutlined />}
        shape="circle"
        disabled={disabled}
      />
    </Popconfirm>
  )
}

CleanupTaskDeleteButton.propTypes = {
  taskId: PropTypes.string.isRequired,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  disabled: PropTypes.bool,
}

CleanupTaskDeleteButton.defaultProps = {
  disabled: false,
}

export default CleanupTaskDeleteButton
