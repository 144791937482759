const APP_ROUTES = {
  BASE_ROUTES: {
    Root: '/',
  },

  ACCOUNT_ROUTES: {
    Account: '/account',
    Login: '/account/login',
    TokenLogin: '/account/token-login',
  },

  BRANDS_AND_LOCATIONS_ROUTES: {
    BrandsAndLocations: '/brands-and-locations',
  },

  BRAND_ROUTES: {
    BrandsPrefix: '/brands',
    NewBrand: '/brands/new',
    BrandsWithValidation: '/brands/:brandId',
  },

  LOCATION_ROUTES: {
    LocationsPrefix: '/locations',
    NewLocation: '/locations/new',
    LocationsWithValidation: '/locations/:locationId(\\d+)',
  },

  USER_ROUTES: {
    Users: '/users',
    UserCreate: '/users/create',
    UserDetails: '/users/:id(\\d+)',
    UserSettings: '/users/settings',
  },

  REPORT_ROUTES: {
    Reports: '/reports/:reportName',
  },

  AI_SETTINGS_ROUTES: {
    AiSettings: '/aiSettings',
  },

  CLEANUP_TASK_ROUTES: {
    CleanupTasks: '/cleanupTasks',
    MlCleanupTaskCreate: '/cleanupTasks/ml/create',
    MlCleanupTaskDetails: '/cleanupTasks/ml/:metadataType/:id',
    ReviewCleanupTaskCreate: '/cleanupTasks/review/create',
    ReviewCleanupTaskDetails: '/cleanupTasks/review/:metadataType/:id',
    ReplaceCleanupTaskDetails: '/cleanupTasks/replace/:metadataType/:id',
  },

  INGREDIENTS_RERUN_ROUTES: {
    IngredientsRerun: '/ingredientsRerun',
  },
}

export default APP_ROUTES
