import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { startAppLoading, stopAppLoading } from '../redux/actions'

// TODO only show a single loader and for this we need a count
const useAppLoading = () => {
  const isLoading = useSelector(({ app }) => app.isLoading)
  const dispatch = useDispatch()
  const startLoading = useCallback(
    () => dispatch(startAppLoading()),
    [dispatch],
  )
  const stopLoading = useCallback(() => dispatch(stopAppLoading()), [dispatch])

  return { isLoading, startLoading, stopLoading }
}

export default useAppLoading
