import React from 'react'
import { notification, Typography } from 'antd'
import { filter, get, isEmpty, map, pick } from 'lodash'

import { EVENT_TYPES, suggestionEndpoints } from '../../common/constants'
import { googleAnalyticsEventsService } from '../../common/services'
import { getAiSuggestions } from '../../common/utils'

import { ADDITIONAL_INGREDIENTS, MAIN_INGREDIENTS } from '../constants'
import { dishIngredientsSuggestionTransformer } from '../transformers'

export default async function suggestIngredients({
  dish,
  additionalInformation = {},
}) {
  try {
    const [suggestions] = await getAiSuggestions(
      suggestionEndpoints.ingredients,
      [
        dishIngredientsSuggestionTransformer.transformDishToAiRequestDto(
          dish,
          additionalInformation,
        ),
      ],
      dishIngredientsSuggestionTransformer.transformDishFromAiResponseDto,
    )

    if (
      isEmpty(suggestions?.mainIngredients) &&
      isEmpty(suggestions?.additionalIngredients)
    ) {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.NO_SUGGESTIONS,
        { for: MAIN_INGREDIENTS },
      )
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.NO_SUGGESTIONS,
        { for: ADDITIONAL_INGREDIENTS },
      )

      notification.error({
        message: 'An error occured',
        description: 'AI could not offer any suggestions. This is a bug.',
        placement: 'topLeft',
      })

      return null
    }

    if (isEmpty(suggestions?.mainIngredients)) {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.NO_SUGGESTIONS,
        { for: MAIN_INGREDIENTS },
      )
    }

    if (isEmpty(suggestions?.additionalIngredients)) {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.NO_SUGGESTIONS,
        { for: ADDITIONAL_INGREDIENTS },
      )
    }

    const aiMessages = get(suggestions, 'messages')
    if (!isEmpty(aiMessages)) {
      const errorMessages = filter(aiMessages, ({ level }) => level === 'Error')
      const warningMessages = filter(
        aiMessages,
        ({ level }) => level === 'Warning',
      )

      if (!isEmpty(errorMessages)) {
        notification.error({
          message: 'An error occured',
          description: map(errorMessages, ({ message }, index) => (
            <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
              {message}
            </Typography.Paragraph>
          )),
          placement: 'topLeft',
        })
      }

      if (!isEmpty(warningMessages)) {
        notification.warning({
          message: 'Ai issued some warnings',
          description: map(warningMessages, ({ message }, index) => (
            <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
              {message}
            </Typography.Paragraph>
          )),
          placement: 'topLeft',
        })
      }

      return null
    }

    return {
      aiSuggestions: pick(suggestions, [
        'mainIngredients',
        'excludedIngredients',
        'additionalIngredients',
        'explanations',
        'certainty',
      ]),
    }
  } catch (e) {
    notification.error({
      message: 'An error occured while suggesting main ingredients',
      description: e.message,
      placement: 'topLeft',
    })

    return null
  }
}
