import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { PropTypes } from 'prop-types'

import { DiscardButton } from '../../../../common/components'
import { APP_ROUTES, EVENT_TYPES } from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

const UserDiscardButtonComponent = ({ userId }) => {
  const history = useHistory()

  const {
    formState: { isDirty },
  } = useFormContext()

  const handleDiscardUser = useCallback(() => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.USER_EVENTS.CANCEL_SAVE,
      { userId },
    )
    history.push(APP_ROUTES.USER_ROUTES.Users)
  }, [userId, history])

  return <DiscardButton disabled={!isDirty} onClick={handleDiscardUser} />
}
UserDiscardButtonComponent.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default memo(UserDiscardButtonComponent)
