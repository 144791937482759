import { useQuery } from '@apollo/client'
import { find } from 'lodash'

import { AI_SETTINGS_QUERY } from '../../aiSettings/graphql'

const useMlIngredientsEnabled = () => {
  const { data = {} } = useQuery(AI_SETTINGS_QUERY)

  const mlIngredientsSetting = find(
    data?.aiSettings,
    setting => setting.name === 'INGREDIENTS_ML',
  )

  return mlIngredientsSetting?.isEnabled || false
}

export default useMlIngredientsEnabled
