import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import { isEqual, isNil, omit } from 'lodash'
import PropTypes from 'prop-types'

import { Spinner } from '../../core/components'
import { usePrevious } from '../../core/hooks'

import { AuthContext } from '../contexts'
import { REFRESH_ACCESS_TOKEN_MUTATION } from '../graphql/mutations'
import { googleAnalyticsEventsService, sessionManager } from '../services'
import { decodeToken } from '../utils'

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => sessionManager.getUser())

  const [isRefreshTokenAvailable, setIsRefreshTokenAvailable] = useState(
    () => !isNil(sessionManager.getRefreshToken()),
  )

  const [isSpinnerVisible, setIsSpinnerVisible] = useState(
    isRefreshTokenAvailable,
  )

  const [refreshAccessToken, { loading: refreshTokenLoading }] = useMutation(
    REFRESH_ACCESS_TOKEN_MUTATION,
    {
      onCompleted: ({ refreshAccessToken: { accessToken, refreshToken } }) => {
        const googleAccessToken = sessionManager.getGoogleAccessToken()
        const userFromToken = decodeToken(accessToken)
        userFromToken.id = userFromToken.id.toString()

        sessionManager.createSession(
          userFromToken,
          accessToken,
          refreshToken,
          googleAccessToken,
        )

        googleAnalyticsEventsService.setAnalyticsUser(userFromToken)
        setUser(userFromToken)
        setIsRefreshTokenAvailable(true)
      },

      onError: ({ message }) => {
        setUser(null)
        setIsRefreshTokenAvailable(false)

        notification.open({
          message: 'Could not refresh access data.',
          description: message,
          type: 'error',
        })
      },
    },
  )

  useEffect(() => {
    const refreshToken = sessionManager.getRefreshToken()

    if (refreshToken) {
      refreshAccessToken({ variables: { refreshToken } })
    }
  }, [refreshAccessToken])

  const prevRefreshTokenLoading = usePrevious(refreshTokenLoading)

  useEffect(() => {
    if (!refreshTokenLoading && prevRefreshTokenLoading && isSpinnerVisible) {
      setIsSpinnerVisible(false)
    }
  }, [refreshTokenLoading, isSpinnerVisible, prevRefreshTokenLoading])

  const setUserInfo = useCallback(
    userInfo => {
      googleAnalyticsEventsService.setAnalyticsUser(userInfo)
      setUser(userInfo)
      setIsRefreshTokenAvailable(!isNil(userInfo))
    },
    [setUser],
  )

  const prevUser = usePrevious(user)

  useEffect(() => {
    const accessToken = sessionManager.getAccessToken()

    if (
      accessToken &&
      !isEqual(omit(user, ['iat', 'exp']), omit(prevUser, ['iat', 'exp']))
    ) {
      const userFromToken = decodeToken(accessToken)
      userFromToken.id = userFromToken.id.toString()
      setUserInfo(userFromToken)
    }
  }, [user, prevUser, setUserInfo])

  if (isSpinnerVisible) {
    return <Spinner size="large" />
  }

  return (
    <AuthContext.Provider
      value={{ userInfo: user, isRefreshTokenAvailable, setUserInfo }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthProvider
