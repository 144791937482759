import React, { memo, useMemo } from 'react'
import { Row } from 'antd'
import { filter, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'

import { DietDescriptorCollapse } from '../../../../dishes/components/molecules'
import { getHighlightListFromArrayForDescriptor } from '../../../helpers'
import { formatDescriptors } from '../../../utils'
import { HighlightingTextField } from '../../molecules'

const DescriptorsTable = ({
  miscDescriptors,
  addonDescriptors,
  dietDescriptors,
  nutritionsDescriptors,
  allergenDescriptors,
  highlightWords,
}) => {
  const descriptorsToShow = useMemo(
    () =>
      filter(
        [
          {
            label: 'Addon Descriptors',
            name: 'addonDescriptors',
            value: formatDescriptors(addonDescriptors),
            type: 'text',
          },
          {
            label: 'Misc Descriptors',
            name: 'miscDescriptors',
            value: formatDescriptors(miscDescriptors),
            type: 'text',
          },
          {
            label: 'Diet Descriptors',
            name: 'dietDescriptors',
            value: dietDescriptors,
            type: 'collapsible',
          },
          {
            label: 'Nutritions Descriptors',
            name: 'nutritionsDescriptors',
            value: formatDescriptors(nutritionsDescriptors),
            type: 'text',
          },
          {
            label: 'Allergens Descriptors',
            name: 'allergenDescriptors',
            value: formatDescriptors(allergenDescriptors),
            type: 'text',
          },
        ],
        descriptor => !isEmpty(descriptor.value),
      ),
    [
      addonDescriptors,
      miscDescriptors,
      dietDescriptors,
      nutritionsDescriptors,
      allergenDescriptors,
    ],
  )

  if (isEmpty(descriptorsToShow)) {
    return null
  }

  return (
    <Row gutter={16}>
      {map(descriptorsToShow, descriptor => (
        <GridFormItem key={descriptor.name} span={8} label={descriptor.label}>
          {descriptor.type === 'text' ? (
            <HighlightingTextField
              value={descriptor.value}
              highlightingList={getHighlightListFromArrayForDescriptor(
                highlightWords,
                descriptor.name,
              )}
              disabled
              disabledWithInput
            />
          ) : (
            <DietDescriptorCollapse
              highlights={highlightWords}
              value={descriptor.value}
            />
          )}
        </GridFormItem>
      ))}
    </Row>
  )
}

DescriptorsTable.propTypes = {
  miscDescriptors: PropTypes.arrayOf(PropTypes.string),
  addonDescriptors: PropTypes.arrayOf(PropTypes.string),
  dietDescriptors: PropTypes.arrayOf(PropTypes.string),
  allergenDescriptors: PropTypes.arrayOf(PropTypes.string),
  nutritionsDescriptors: PropTypes.arrayOf(PropTypes.string),
  highlightWords: PropTypes.object,
}

DescriptorsTable.defaultProps = {
  miscDescriptors: [],
  addonDescriptors: [],
  dietDescriptors: [],
  allergenDescriptors: [],
  nutritionsDescriptors: [],
  highlightWords: {},
}

export default memo(DescriptorsTable)
