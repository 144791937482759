import { useContext } from 'react'
import { castArray, intersection, isArray, isEmpty, map } from 'lodash'

import { AuthContext } from '../contexts'

const useUserHasRoles = roles => {
  const rolesArray = isArray(roles) ? roles : castArray(roles)
  const {
    userInfo: { roles: userRoles },
  } = useContext(AuthContext)

  return !isEmpty(intersection(map(userRoles, 'name'), rolesArray))
}

export default useUserHasRoles
