import { join } from 'path'

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'

import { useQueryParams } from '../../core/hooks'

import {
  APP_ROUTES,
  DEFAULT_PAGINATION_CONFIG,
  idNew,
} from '../../common/constants'
import {
  removeUnsavedAiSuggestionsForOwner,
  setActiveBrandName,
} from '../../common/redux/actions'

import { UPSERT_BRAND_MUTATION } from '../graphql'

const useUpsertBrand = ({ brandId, refetchQueries }) => {
  const dispatch = useDispatch()

  const setNameOnHeader = useCallback(
    name => dispatch(setActiveBrandName(name)),
    [dispatch],
  )

  const removeSuggestionsForBrand = useCallback(
    () =>
      dispatch(
        removeUnsavedAiSuggestionsForOwner({
          ownerId: brandId,
          type: 'brands',
        }),
      ),
    [brandId, dispatch],
  )

  const { setQueryParams } = useQueryParams()

  const history = useHistory()

  const [upsertBrand] = useMutation(UPSERT_BRAND_MUTATION, {
    onCompleted: removeSuggestionsForBrand,
    refetchQueries,
  })

  return useCallback(
    async brand => {
      try {
        const {
          data: { upsertBrand: upsertedBrand },
        } = await upsertBrand({
          variables: { input: brand },
        })

        if (brandId === idNew) {
          history.push(
            join(APP_ROUTES.BRAND_ROUTES.BrandsPrefix, upsertedBrand.id),
          )
        } else {
          setQueryParams({
            currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
            menuTitleId: null,
          })
        }
        setNameOnHeader(upsertedBrand.name)
      } catch (error) {
        notification.error({
          message: 'An error occured',
          description: error.message,
          placement: 'topLeft',
        })
      }
    },
    [brandId, history, setNameOnHeader, setQueryParams, upsertBrand],
  )
}

export default useUpsertBrand
