export default Object.freeze({
  title: {
    display: 'inline',
  },
  margins: {
    marginLeft: '1rem',
  },
  select: {
    width: '12rem',
    marginLeft: '0.5rem',
  },
  justifyFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuLink: {
    padding: '0 15px',
  },
})
