import { gql } from '@apollo/client'

const METADATA_NODE_FRAGMENT = gql`
  fragment MetadataNodeFragment on MetadataNode {
    id
    name
    idPath
    namePath
    isCurationEnabled
    level

    ... on DishType {
      isIgnored
      courseTypeId
    }

    ... on CuisineType {
      isErrorProne
    }

    ... on CourseType {
      isIgnored
      dishTypeId
    }

    children {
      id
      name
      idPath
      namePath
      isCurationEnabled
      level

      ... on CuisineType {
        isErrorProne
      }

      children {
        id
        name
        idPath
        namePath
        isCurationEnabled
        level

        ... on CuisineType {
          isErrorProne
        }
      }
    }
  }
`

export default METADATA_NODE_FRAGMENT
