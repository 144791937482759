import BRAND_UNASSIGNED_USER_FILTER_VALUE from './brandUnassignedUserFilterValue'
import NEGATIVE_BRAND_TAGS from './negativeBrandTags'

const DEFAULT_BRAND_LIST_FILTERS = Object.freeze({
  STATUS: null,
  SEARCH: '',
  CUISINE_TYPES: '',
  PREDOMINANT_LANGUAGES: '',
  UNKNOWN_WORDS_LANGUAGES: '',
  ASSIGNED_USER: BRAND_UNASSIGNED_USER_FILTER_VALUE,
  QA_DETAIL_LEVEL: undefined,
  IS_PROBLEMATIC: null,
  TAGS: [
    NEGATIVE_BRAND_TAGS.IS_NOT_FAKE.value,
    NEGATIVE_BRAND_TAGS.IS_NOT_TEST.value,
    NEGATIVE_BRAND_TAGS.IS_NOT_PROBLEMATIC.value,
  ],
})

export default DEFAULT_BRAND_LIST_FILTERS
