import React, { useCallback, useMemo } from 'react'
import { Col, Row, Select } from 'antd'
import { filter, includes, intersectionBy, isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { createOption, selectFilterOption } from '../../../../core/utils'

const styles = {
  select: { width: '100%' },
}

const MultipleSelect = ({
  options,
  defaultValue,
  value,
  onValueChanged,
  disabled,
  size,
  placeholder,
  labelProp,
  valueProp,
  optionFilterProp,
  filterOption,
}) => {
  const multiSelectValue = value || defaultValue

  const displayValues = useMemo(
    () =>
      intersectionBy(
        map(multiSelectValue, item => ({ [valueProp]: item })),
        options,
        valueProp,
      ),
    [multiSelectValue, options, valueProp],
  )

  const optionsBasedOnLabelAndValueProps = useMemo(
    () => map(options, createOption(labelProp, valueProp)),
    [labelProp, options, valueProp],
  )

  const handleOnChange = useCallback(
    oldValue => selectedValue => {
      let newValue

      if (isNil(selectedValue)) {
        newValue = filter(multiSelectValue, item => item !== oldValue)
      } else {
        newValue = map(multiSelectValue, item =>
          item === oldValue ? selectedValue : item,
        )
      }

      onValueChanged(newValue)
    },
    [multiSelectValue, onValueChanged],
  )

  const handleAddItem = useCallback(
    selectedValue => {
      if (includes(multiSelectValue, selectedValue)) {
        return
      }

      onValueChanged([...multiSelectValue, selectedValue])
    },
    [multiSelectValue, onValueChanged],
  )

  return (
    <Row gutter={[16, 16]}>
      {map(displayValues, item => (
        <Col key={`${item[labelProp]}-${item[valueProp]}}`} span={4}>
          <Select
            showSearch
            style={styles.select}
            value={item[valueProp]}
            allowClear
            disabled={disabled}
            size={size}
            optionFilterProp={optionFilterProp}
            onChange={handleOnChange(item[valueProp])}
          >
            {optionsBasedOnLabelAndValueProps}
          </Select>
        </Col>
      ))}
      <Col span={4}>
        <Select
          value={null}
          showSearch
          style={styles.select}
          disabled={disabled}
          size={size}
          placeholder={placeholder}
          optionFilterProp={optionFilterProp}
          filterOption={filterOption}
          onChange={handleAddItem}
        >
          {optionsBasedOnLabelAndValueProps}
        </Select>
      </Col>
    </Row>
  )
}

MultipleSelect.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  placeholder: PropTypes.string,
  labelProp: PropTypes.string,
  valueProp: PropTypes.string,
  optionFilterProp: PropTypes.string,
  filterOption: PropTypes.func,
}

MultipleSelect.defaultProps = {
  value: undefined,
  defaultValue: [],
  disabled: false,
  labelProp: 'label',
  valueProp: 'value',
  size: 'default',
  placeholder: 'Select...',
  optionFilterProp: 'children',
  filterOption: selectFilterOption,
}

export default MultipleSelect
