import { gql } from '@apollo/client'

import { USER_FRAGMENT } from '../../../common/graphql'

const USER_MANAGEMENT_FRAGMENT = gql`
  fragment UserManagementFragment on User {
    ...UserFragment
    isActive
    isDeleted
    lastLoginDate
    createdAt
    seniority
    personalEmail
    clockifyEmail
    invoiceEmail
    birthdate
    joinedAt
    gender
    extracurricularActivities
    education {
      id
      schoolName
      degree
      fieldOfStudy
    }
    country
    timeZone
    phone
    previousWorkExperience
    status
    tier
    lastReviewAt
    resignedAt
    terminatedAt
    terminatedReason
    otherQualification
    workSchedule {
      id
      startDayOfWeek
      endDayOfWeek
      startTime
      endTime
    }
    graphRelevantFields {
      isNameGraphRelevant
      isBirthdateGraphRelevant
      isGenderGraphRelevant
      isEducationGraphRelevant
      isDepartmentGraphRelevant
      isQualificationGraphRelevant
      isCompetencyGraphRelevant
      isCuisineKnowlegeGraphRelevant
      isLanguageGraphRelevant
      isCountryGraphRelevant
      isTimeZoneGraphRelevant
      isWorkScheduleGraphRelevant
      isPhoneGraphRelevant
      isEmailGraphRelevant
      isJoinedAtGraphRelevant
      isPreviousWorkExperienceGraphRelevant
      isStatusGraphRelevant
      isTierGraphRelevant
      isLastReviewAtGraphRelevant
    }
    departments {
      id
      value
      label
    }
    qualifications {
      id
      value
      label
    }
    competencies {
      id
      competencyId
      type
      isActive
    }
  }
  ${USER_FRAGMENT}
`

export default USER_MANAGEMENT_FRAGMENT
