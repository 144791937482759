import React from 'react'
import { Provider } from 'react-redux'
import { includes } from 'lodash'
import { PersistGate } from 'redux-persist/integration/react'

import { getEnvironmentVariable } from '../../core/helpers'
import { initSentry } from '../../core/services'

import { configureStore } from '../redux'

import Bootstrap from './Bootstrap'

const { store, persistor } = configureStore()

if (process.env.NODE_ENV !== 'development') {
  initSentry({
    dsn: getEnvironmentVariable('REACT_APP_SENTRY_DSN'),
    environment: getEnvironmentVariable('REACT_APP_SENTRY_APP_ENVIRONMENT'),
    beforeSend: event => {
      const {
        exception: { values },
      } = event

      if (includes(values[0].value, 'ResizeObserver loop limit exceeded')) {
        return null
      }

      return event
    },
  })
}

// eslint-disable-next-line no-alert
// alert(
//   'You are using the old curator tool. Please use the new one at https://menu-curator.foodstyles.com',
// )

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Bootstrap />
    </PersistGate>
  </Provider>
)

export default App
