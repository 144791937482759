import update from 'immutability-helper'
import { findIndex } from 'lodash'

import { BRAND_SUGGESTED_WORDS } from '../queries'

const removeBrandSuggestedWord =
  (
    id,
    brandId,
    sortUnknownWordsBy,
    brandSuggestedWordsCurrentPage,
    brandSuggestedWordsPageSize,
  ) =>
  (
    cache,
    { data: { deleteNotExportedBrandSuggestedWord: isDeleted } = {} },
  ) => {
    if (!isDeleted) {
      return
    }

    const { brandSuggestedWords } = cache.readQuery({
      query: BRAND_SUGGESTED_WORDS,
      variables: {
        brandId,
        sortBy: sortUnknownWordsBy,
        pagination: {
          currentPage: Number(brandSuggestedWordsCurrentPage),
          pageSize: Number(brandSuggestedWordsPageSize),
        },
      },
    })

    const index = findIndex(brandSuggestedWords, word => word.id === id)
    const newWordList = update(brandSuggestedWords, { $splice: [[index, 1]] })

    cache.writeQuery({
      query: BRAND_SUGGESTED_WORDS,
      variables: {
        brandId,
        sortBy: sortUnknownWordsBy,
        pagination: {
          currentPage: Number(brandSuggestedWordsCurrentPage),
          pageSize: Number(brandSuggestedWordsPageSize),
        },
      },
      data: { brandSuggestedWords: newWordList },
    })
  }

export default removeBrandSuggestedWord
