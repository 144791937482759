import { gql } from '@apollo/client'

import { LOCATION_FRAGMENT } from '../fragments'

export default gql`
  mutation upsertLocation($input: UpsertLocationInput!) {
    upsertLocation(input: $input) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`
