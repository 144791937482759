export default Object.freeze({
  IGNORED: {
    label: 'Ignored',
    value: 'IGNORED',
  },
  NOT_IGNORED: {
    label: 'Not ignored',
    value: 'NOT_IGNORED',
  },
})
