import React, { useCallback, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from '@apollo/client'
import { find, isEmpty, join, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../core/hooks'

import { handleMutationError } from '../../common/helpers'
import { useRowHighlight } from '../../common/hooks'

import { AVAILABLE_HOTKEYS, CLEANUP_TASK_TYPES } from '../constants'
import { CleanupTableFocusActionsContext } from '../contexts'
import {
  UPDATE_CLEANUP_TASK_CURATION_DONE_MUTATION,
  UPDATE_CLEANUP_TASK_QA_DONE_MUTATION,
  UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION,
  UPDATE_HUMAN_CLEANUP_QA_DONE_MUTATION,
  UPDATE_ML_CLEANUP_CURATION_DONE_MUTATION,
  UPDATE_ML_CLEANUP_QA_DONE_MUTATION,
} from '../graphql'
import { cleanupTasksMetadataTypePropType } from '../propTypes'

const CleanupTableFocusActionsProvider = ({
  type,
  taskId,
  children,
  cleanups,
  cleanupsLoading,
  metadataType,
  isCurationDone,
  isQaDone,
  tableSelector,
  rowsSelector,
  scrollContainerSelector,
  disabled,
}) => {
  const [hotkeyEditMode, setHotkeyEditMode] = useState(false)
  const [isBatchEditModeActive, setIsBatchEditModeActive] = useState(false)
  const [navigationEnabled, setNavigationEnabled] = useState(false)
  const [updateHumanCleanupCurationDone] = useMutation(
    UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION,
    {
      onError: handleMutationError,
    },
  )
  const [updateHumanCleanupQaDone] = useMutation(
    UPDATE_HUMAN_CLEANUP_QA_DONE_MUTATION,
    {
      onError: handleMutationError,
    },
  )
  const [updateMlCleanupCurationDone] = useMutation(
    UPDATE_ML_CLEANUP_CURATION_DONE_MUTATION,
    {
      onError: handleMutationError,
    },
  )
  const [updateMlCleanupQaDone] = useMutation(
    UPDATE_ML_CLEANUP_QA_DONE_MUTATION,
    {
      onError: handleMutationError,
    },
  )
  const [updateTaskCurationDone] = useMutation(
    UPDATE_CLEANUP_TASK_CURATION_DONE_MUTATION,
    {
      onError: handleMutationError,
    },
  )
  const [updateTaskQaDone] = useMutation(UPDATE_CLEANUP_TASK_QA_DONE_MUTATION, {
    onError: handleMutationError,
  })

  const { initHighlight, selectedRowKey } = useRowHighlight({
    tableSelector,
    rowsSelector,
    scrollContainerSelector,
    disableHotkeys: hotkeyEditMode || !navigationEnabled,
    disabledCellSelection: true,
    disabledRowSelection: !navigationEnabled,
  })

  const { pageSize, currentPage, status, search } = useQueryParams()

  useEffect(() => {
    setTimeout(initHighlight, 0)
    setHotkeyEditMode(false)
  }, [cleanupsLoading, initHighlight, pageSize, currentPage, status, search])

  const handleKeyboardNavigationActivation = useCallback(() => {
    setNavigationEnabled(true)
    const [scrollContainer] = document.querySelectorAll(
      '.review-cleanup-table .ant-table-body',
    )
    if (scrollContainer) {
      scrollContainer.scrollIntoView()
    }
  }, [])

  const handleCleanupQaCurationDone = useCallback(() => {
    const cleanup = find(cleanups, ({ id }) => id === selectedRowKey)
    if (!isEmpty(cleanup)) {
      if (!isCurationDone && type === CLEANUP_TASK_TYPES.ML) {
        return updateMlCleanupCurationDone({
          variables: {
            ids: [selectedRowKey],
            value: !cleanup.isCurationDone,
            metadataType,
            taskId,
          },
        })
      }

      if (!isCurationDone && type !== CLEANUP_TASK_TYPES.ML) {
        return updateHumanCleanupCurationDone({
          variables: {
            ids: [selectedRowKey],
            value: !cleanup.isCurationDone,
            metadataType,
            taskId,
          },
        })
      }

      if (isCurationDone && type === CLEANUP_TASK_TYPES.ML) {
        return updateMlCleanupQaDone({
          variables: {
            ids: [selectedRowKey],
            value: !cleanup.isQanDone,
            metadataType,
            taskId,
          },
        })
      }

      if (isCurationDone && type !== CLEANUP_TASK_TYPES.ML) {
        return updateHumanCleanupQaDone({
          variables: {
            ids: [selectedRowKey],
            value: !cleanup.isQanDone,
            metadataType,
            taskId,
          },
        })
      }
    }
    return null
  }, [
    cleanups,
    isCurationDone,
    metadataType,
    selectedRowKey,
    updateHumanCleanupCurationDone,
    updateHumanCleanupQaDone,
    updateMlCleanupCurationDone,
    updateMlCleanupQaDone,
    taskId,
    type,
  ])

  const handleAllCleanupsQaCurationDone = useCallback(() => {
    const cleanupIds = map(cleanups, 'id')
    const currentValue = !some(cleanups, cleanup =>
      !isCurationDone ? !cleanup.isCurationDone : !cleanup.isQaDone,
    )

    if (!isCurationDone && type === CLEANUP_TASK_TYPES.ML) {
      return updateMlCleanupCurationDone({
        variables: {
          ids: cleanupIds,
          value: !currentValue,
          taskId,
          metadataType,
        },
      })
    }

    if (!isCurationDone && type !== CLEANUP_TASK_TYPES.ML) {
      return updateHumanCleanupCurationDone({
        variables: {
          ids: cleanupIds,
          value: !currentValue,
          taskId,
          metadataType,
        },
      })
    }

    if (isCurationDone && type === CLEANUP_TASK_TYPES.ML) {
      return updateMlCleanupQaDone({
        variables: {
          ids: cleanupIds,
          value: !currentValue,
          taskId,
          metadataType,
        },
      })
    }

    if (isCurationDone && type !== CLEANUP_TASK_TYPES.ML) {
      return updateHumanCleanupQaDone({
        variables: {
          ids: cleanupIds,
          value: !currentValue,
          taskId,
          metadataType,
        },
      })
    }

    return null
  }, [
    cleanups,
    isCurationDone,
    metadataType,
    updateHumanCleanupCurationDone,
    updateHumanCleanupQaDone,
    updateMlCleanupCurationDone,
    updateMlCleanupQaDone,
    taskId,
    type,
  ])

  const handleTaskQaCurationDone = useCallback(() => {
    if (isCurationDone) {
      return updateTaskQaDone({
        variables: {
          id: taskId,
          value: !isQaDone,
          metadataType,
        },
      })
    }
    return updateTaskCurationDone({
      variables: {
        id: taskId,
        value: !isCurationDone,
        metadataType,
      },
    })
  }, [
    isCurationDone,
    isQaDone,
    metadataType,
    updateTaskCurationDone,
    updateTaskQaDone,
    taskId,
  ])

  useHotkeys(
    AVAILABLE_HOTKEYS.DOWN.hotkey,
    handleKeyboardNavigationActivation,
    {
      enabled: !navigationEnabled && !disabled,
    },
    [navigationEnabled, handleKeyboardNavigationActivation, disabled],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.S.hotkey,
    handleCleanupQaCurationDone,
    {
      enabled: !hotkeyEditMode && navigationEnabled,
    },
    [hotkeyEditMode, navigationEnabled, handleCleanupQaCurationDone],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.SHIFT_S.hotkey,
    handleAllCleanupsQaCurationDone,
    {
      enabled: !hotkeyEditMode,
    },
    [hotkeyEditMode, handleAllCleanupsQaCurationDone],
  )

  useHotkeys(
    join(
      [AVAILABLE_HOTKEYS.CMD_D.hotkey, AVAILABLE_HOTKEYS.CTRL_D.hotkey],
      ', ',
    ),
    event => {
      event.preventDefault()
      handleTaskQaCurationDone()
    },
    {
      enabled: !hotkeyEditMode,
    },
    [hotkeyEditMode, handleTaskQaCurationDone],
  )

  return (
    <CleanupTableFocusActionsContext.Provider
      value={{
        selectedRowKey,
        hotkeyEditMode,
        setHotkeyEditMode,
        isBatchEditModeActive,
        setIsBatchEditModeActive,
      }}
    >
      {children}
    </CleanupTableFocusActionsContext.Provider>
  )
}

CleanupTableFocusActionsProvider.propTypes = {
  type: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cleanups: PropTypes.arrayOf(PropTypes.object),
  cleanupsLoading: PropTypes.bool,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  isCurationDone: PropTypes.bool,
  isQaDone: PropTypes.bool,
  tableSelector: PropTypes.string.isRequired,
  rowsSelector: PropTypes.string.isRequired,
  scrollContainerSelector: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

CleanupTableFocusActionsProvider.defaultProps = {
  cleanups: [],
  cleanupsLoading: false,
  isCurationDone: false,
  isQaDone: false,
  disabled: false,
}

export default CleanupTableFocusActionsProvider
