import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce, isNil, omit } from 'lodash'
import PropTypes from 'prop-types'

import { usePrevious } from '../../../../core/hooks'

import { stylePropType } from '../../../propTypes'

const shouldPropagateValueOnChangeByInputType = {
  text: () => true,
  float: value => !Number.isNaN(value),
}

const DebouncedInput = ({
  defaultValue,
  value,
  onValueChanged: onValueChangedProp,
  InputComponent,
  disabled,
  prefix,
  style,
  inputType,
  size,
  placeholder,
  autoSize,
}) => {
  const previousDefaultValue = usePrevious(defaultValue)
  const previousValue = usePrevious(value)
  const [text, setText] = useState(defaultValue)
  const debouncedOnChange = useMemo(
    () =>
      debounce(changedValue => {
        onValueChangedProp(changedValue)
      }, 300),
    [onValueChangedProp],
  )

  const onChange = useCallback(
    ({ target: { value: changedValue } }) => {
      if (shouldPropagateValueOnChangeByInputType[inputType](changedValue)) {
        setText(changedValue)
      }
    },
    [inputType],
  )

  useEffect(() => {
    if (!isNil(previousValue) && isNil(value) && defaultValue !== text) {
      setText(defaultValue)
    } else if (previousDefaultValue !== defaultValue) {
      setText(defaultValue)
    }
    // In case we revert manually to the default value we still want to propagate the change
    else if (
      defaultValue !== text ||
      (defaultValue === text && !isNil(value) && value !== defaultValue)
    ) {
      debouncedOnChange(text)
    }
  }, [
    previousDefaultValue,
    defaultValue,
    previousValue,
    value,
    text,
    debouncedOnChange,
  ])

  // React throws a warning if we give autoSize (even undefined) to ant design normal input
  const propsToGive = omit(
    {
      value: text,
      onChange,
      disabled,
      prefix,
      style,
      size,
      placeholder,
      autoSize,
    },
    !autoSize ? 'autoSize' : [],
  )

  return <InputComponent {...propsToGive} />
}

DebouncedInput.propTypes = {
  InputComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  onValueChanged: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  style: stylePropType,
  inputType: PropTypes.oneOf(['text', 'float']),
  size: PropTypes.string,
  placeholder: PropTypes.string,
  autoSize: PropTypes.bool,
}

DebouncedInput.defaultProps = {
  defaultValue: '',
  value: null,
  disabled: false,
  prefix: undefined,
  style: undefined,
  inputType: 'text',
  size: undefined,
  placeholder: 'Enter...',
  autoSize: false,
}

export default DebouncedInput
