import { get, isPlainObject, isUndefined, map, pick, stubFalse } from 'lodash'

const createSelectOptions = (
  options,
  labelProp,
  valueProp,
  shouldDisable = stubFalse,
  extraProps = [],
) =>
  map(options, option =>
    isPlainObject(option) && !isUndefined(labelProp) && !isUndefined(valueProp)
      ? {
          label: get(option, labelProp),
          value: get(option, valueProp),
          disabled: shouldDisable(option),
          ...pick(option, extraProps),
        }
      : {
          label: option,
          value: option,

          disabled: shouldDisable(option),
          ...pick(option, extraProps),
        },
  )

export default createSelectOptions
