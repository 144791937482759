import React, { useMemo } from 'react'
import { Table } from 'antd'
import { isEmpty, isNil, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { useWindowSize } from '../../../../core/hooks'

import { METADATA_TYPES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import { CLEANUP_TABLE_SCROLL_VALUES } from '../../../constants'
import { cleanupTasksMetadataTypePropType } from '../../../propTypes'
import { computeMlCleanupTaskTableColumns } from '../../../services'

const MlCleanupTaskTable = ({
  data,
  loading,
  metadataType,
  taskId,
  disabled,
  isTaskCurationDone,
  isTaskQaDone,
}) => {
  const { dishTypeOptions, courseTypeOptions } = useCurationMetadata()
  const { height } = useWindowSize()
  const allItemIds = useMemo(() => map(data, 'id'), [data])
  const areAllItemsCurationDone = useMemo(
    () => !some(data, ({ isCurationDone }) => !isCurationDone),
    [data],
  )
  const areAllItemsQaDone = useMemo(
    () => !some(data, ({ isQaDone }) => !isQaDone),
    [data],
  )

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
    }),
    [courseTypeOptions, dishTypeOptions],
  )

  const hideIngredientsTextColumn = useMemo(
    () => some(data, dish => !isEmpty(dish.ingredientsText)),
    [data],
  )

  const scrollConfig = useMemo(
    () => ({
      y: isNil(height)
        ? CLEANUP_TABLE_SCROLL_VALUES.HEIGHT
        : height - CLEANUP_TABLE_SCROLL_VALUES.HEIGHT,
    }),
    [height],
  )

  const tableColumns = useMemo(
    () =>
      computeMlCleanupTaskTableColumns({
        allItemIds,
        areAllItemsCurationDone,
        areAllItemsQaDone,
        curationMetadata,
        disabled,
        hideIngredientsTextColumn,
        isTaskCurationDone,
        isTaskQaDone,
        metadataType,
        taskId,
      }),
    [
      allItemIds,
      areAllItemsCurationDone,
      areAllItemsQaDone,
      curationMetadata,
      disabled,
      hideIngredientsTextColumn,
      isTaskCurationDone,
      isTaskQaDone,
      metadataType,
      taskId,
    ],
  )

  return (
    <Table
      className="ml-cleanup-table"
      loading={loading}
      rowKey={record => record.id}
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      size="small"
      scroll={scrollConfig}
      sticky
    />
  )
}

MlCleanupTaskTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  taskId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isTaskCurationDone: PropTypes.bool,
  isTaskQaDone: PropTypes.bool,
}

MlCleanupTaskTable.defaultProps = {
  loading: false,
  disabled: false,
  isTaskQaDone: false,
  isTaskCurationDone: false,
}

export default MlCleanupTaskTable
