import { gql } from '@apollo/client'

const CLEANUP_TASK_HISTORY_FRAGMENT = gql`
  fragment CleanupTaskHistoryFragment on CleanupTaskHistory {
    id
    name
    type
    metadataType
    curatedAt
    qadAt
    createdAt
    createdBy {
      id
      name
    }
  }
`

export default CLEANUP_TASK_HISTORY_FRAGMENT
