import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Spinner } from '../../../../core/components'
import { useDocumentTitle } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import { MENU_ENTRY } from '../../../../common/constants'
import { setActiveLocationName } from '../../../../common/redux/actions'

import { BRAND_QUERY } from '../../../../brands/graphql'
import { locationIdNew } from '../../../constants'
import { LocationDiscardButton, LocationSaveButton } from '../../molecules'
import { LocationEntry } from '../../sections'

export default function NewLocation() {
  useDocumentTitle('Create Location')
  const { brandId } = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setActiveLocationName('<Unsaved location>'))
  }, [dispatch])

  const {
    loading,
    error,
    data = {},
  } = useQuery(BRAND_QUERY, {
    variables: {
      id: brandId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <Spinner size="large" />
  }

  if (error) {
    return <p>Error occurred while fetching parent brand.</p>
  }

  const {
    brand: {
      name,
      status,
      locationType,
      cuisineType,
      web,
      menuSource,
      menuLink,
    },
  } = data

  return (
    <PageWithHeader
      menuItem={MENU_ENTRY.LOCATION}
      renderRightMenu={() => (
        <>
          <LocationDiscardButton />
          <LocationSaveButton locationId={locationIdNew} />
        </>
      )}
    >
      <LocationEntry
        location={{
          id: locationIdNew,
          web,
          menuSource,
          menuLink,
          brand: { id: brandId, name, status, locationType, cuisineType },
        }}
      />
    </PageWithHeader>
  )
}
