export default Object.freeze({
  SET_LOCATION_NAME: 'SET_LOCATION_NAME',
  SET_LOCATION_LOCATION_TYPE_ID: 'SET_LOCATION_LOCATION_TYPE_ID',
  SET_LOCATION_LOCATION_CUISINE_TYPE_ID:
    'SET_LOCATION_LOCATION_CUISINE_TYPE_ID',
  SET_LOCATION_STATUS: 'SET_LOCATION_STATUS',
  SET_LOCATION_EMAIL: 'SET_LOCATION_EMAIL',
  SET_LOCATION_PHONE: 'SET_LOCATION_PHONE',
  SET_LOCATION_WEB: 'SET_LOCATION_WEB',
  SET_LOCATION_ADDRESS: 'SET_LOCATION_ADDRESS',
  SET_LOCATION_ZIP_CODE: 'SET_LOCATION_ZIP_CODE',
  SET_LOCATION_LATITUDE: 'SET_LOCATION_LATITUDE',
  SET_LOCATION_LONGITUDE: 'SET_LOCATION_LONGITUDE',
  SET_LOCATION_WORKING_HOURS: 'SET_LOCATION_WORKING_HOURS',
  SET_LOCATION_SERVICING_HOURS: 'SET_LOCATION_SERVICING_HOURS',
  SET_LOCATION_INCOMPLETE: 'SET_LOCATION_INCOMPLETE',
  SET_LOCATION_MENU_SOURCE: 'SET_LOCATION_MENU_SOURCE',
  SET_LOCATION_MENU_LINK: 'SET_LOCATION_MENU_LINK',
  SET_LOCATION_SERVICING_HOURS_OVERRIDEN:
    'SET_LOCATION_SERVICING_HOURS_OVERRIDEN',
  REMOVE_LOCATION: 'REMOVE_LOCATION',
})
