import { gql } from '@apollo/client'

import { SIMPLE_ENTITY_FRAGMENT } from '../../../common/graphql'

const BRAND_DUPLICATED_SUBSCRIPTION = gql`
  subscription onBrandDuplicated {
    brandDuplicated {
      brand {
        ...SimpleEntityFragment
      }
      reason
      jobStatus
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
`

export default BRAND_DUPLICATED_SUBSCRIPTION
