const ADMIN = 'admin'
const TEAM_LEAD = 'team_lead'
const CURATOR = 'curator'
const QC_CURATOR = 'qc_curator'
const LEAD_TRAINER = 'lead_trainer'
const QA_CURATOR = 'qa'
const METADATA_CURATOR = 'metadata_curator'
const SENIOR_METADATA_CURATOR = 'senior_metadata_curator'
const DATA_ENTRY = 'data_entry'

const ROLES = {
  ADMIN: { label: 'Admin', value: ADMIN },
  CURATOR: { label: 'Curator', value: CURATOR },
  QC_CURATOR: { label: 'Qc curator', value: QC_CURATOR },
  QA_CURATOR: { label: 'Qa curator', value: QA_CURATOR },
  METADATA_CURATOR: { label: 'Metadata curator', value: METADATA_CURATOR },
  SENIOR_METADATA_CURATOR: {
    label: 'Senior metadata curator',
    value: SENIOR_METADATA_CURATOR,
  },
  DATA_ENTRY: { label: 'Data entry curator', value: DATA_ENTRY },
  TEAM_LEAD: { label: 'Team lead', value: TEAM_LEAD },
  LEAD_TRAINER: { label: 'Trainer', value: LEAD_TRAINER },
}

export default ROLES
