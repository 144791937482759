import { join } from 'path'

import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { Button, notification, Typography } from 'antd'
import { get, isNil } from 'lodash'

import { useQueryParams } from '../../core/hooks'
import { isStringTrue } from '../../core/utils'

import {
  BRAND_BATCH_AI_SUGGESTIONS_SUBSCRIPTION,
  BRAND_QUERY,
  SIMPLE_BRAND_BY_ID_QUERY,
} from '../../brands/graphql'
import { DEFAULT_DISH_LIST_FILTERS } from '../../dishes/constants'
import { BRAND_DISHES_QUERY } from '../../dishes/graphql'
import {
  APP_ROUTES,
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
  JOB_STATUS,
} from '../constants'
import { handleMutationError } from '../helpers'

import useAuthentication from './useAuthentication'
import useCachedBrand from './useCachedBrand'

const useBrandBatchAiSuggestionsSubscription = () => {
  const history = useHistory()
  const { brandId } = useParams()

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    sortDishesBy = DEFAULT_DISH_LIST_FILTERS.SORT_DISHES_BY,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
  } = useQueryParams()

  const brand = useCachedBrand()

  const { data } = useSubscription(BRAND_BATCH_AI_SUGGESTIONS_SUBSCRIPTION)

  const options = useMemo(
    () => ({
      variables: { id: brandId },
      fetchPolicy: 'network-only',
      onError: handleMutationError,
    }),
    [brandId],
  )

  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? brand?.status : null),
    [dishIsStatusSameAsBrandFilter, brand?.status],
  )

  const [fetchBrand] = useLazyQuery(BRAND_QUERY, options)
  const [fetchSimpleBrand] = useLazyQuery(SIMPLE_BRAND_BY_ID_QUERY, options)

  const [fetchBrandDishes] = useLazyQuery(BRAND_DISHES_QUERY, {
    variables: {
      brandId,
      paginationFiltersAndSorters: {
        pageSize: Number(pageSize),
        currentPage: Number(currentPage),
        filters: {
          status: statusForDishFiltering,
          servicingHours: servicingHoursFilter,
          published: dishPublishedFilter,
          ignored: dishIgnoredFilter,
          hasErrors: isStringTrue(dishHasErrorsFilter),
          menuTitle: menuTitleId,
          search,
        },
        sortDishesBy,
      },
    },
    onError: handleMutationError,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (isNil(data?.brandBatchAiSuggestions)) {
      return
    }

    if (isNil(data?.brandBatchAiSuggestions?.brandId)) {
      notification.error({
        message: 'Brand Batch Suggestion Error',
        description: `No brandId provided`,
        placement: 'topLeft',
      })
    }

    if (data?.brandBatchAiSuggestions?.jobStatus === JOB_STATUS.FAILED) {
      const { brandBatchAiSuggestions } = data

      notification.error({
        message: 'Brand Batch Suggestion Error',
        description: (
          <>
            <Typography.Paragraph>
              Curation step{' '}
              {get(CURATION_STATUS, `${brandBatchAiSuggestions.status}.label`)}{' '}
              could not be completed on brand{' '}
              <Typography.Text strong>
                {brandBatchAiSuggestions.brandName}
              </Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              {brandBatchAiSuggestions.reason}
            </Typography.Paragraph>
          </>
        ),
        placement: 'topLeft',
        duration: 6,
      })
    }

    if (data?.brandBatchAiSuggestions?.jobStatus === JOB_STATUS.SUCCESS) {
      const notificationKey = `open${Date.now()}`
      const { brandBatchAiSuggestions } = data

      notification.success({
        key: notificationKey,
        message: 'Brand batch suggestion finished',
        description: `Curation step ${get(
          CURATION_STATUS,
          `${brandBatchAiSuggestions.status}.label`,
        )} finished successfully on brand ${brandBatchAiSuggestions.brandName}`,
        placement: 'topLeft',
        btn:
          brandId !== brandBatchAiSuggestions.brandId ? (
            <Button
              onClick={() => {
                history.push(
                  join(
                    APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
                    brandBatchAiSuggestions.brandId,
                  ),
                )
                notification.close(notificationKey)
              }}
            >
              See the brand
            </Button>
          ) : null,
        duration: 20,
      })
    }

    if (brandId === data.brandBatchAiSuggestions.brandId) {
      fetchBrand()

      if (data?.brandBatchAiSuggestions?.jobStatus === JOB_STATUS.SUCCESS) {
        fetchBrandDishes()
      }
    } else {
      fetchSimpleBrand({
        variables: {
          id: data.brandBatchAiSuggestions.brandId,
        },
      })
    }
  }, [brandId, history, data, fetchBrand, fetchSimpleBrand, fetchBrandDishes])
}

export default useBrandBatchAiSuggestionsSubscription
