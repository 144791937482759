import { useCallback, useContext, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import { forEach, isNil, keys } from 'lodash'

import { CURATION_STATUS } from '../../common/constants'
import { LoadingContext } from '../../common/contexts'
import { handleMutationError } from '../../common/helpers'
import { useCachedBrand } from '../../common/hooks'

import { BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY } from '../../brands/graphql'
import { UPSERT_BRAND_DISH_MUTATION } from '../graphql'
import { translateDish } from '../services'
import { dishTransformer } from '../transformers'

import useUnsavedBrandDish from './useUnsavedBrandDish'

const useSaveBrandDish = ({ brandId, dishId, originalDish }) => {
  const { startLoading, stopLoading } = useContext(LoadingContext)
  const brand = useCachedBrand()
  const dishToSave = useUnsavedBrandDish(dishId)

  const assignedUserType = useMemo(
    () =>
      brand.status === CURATION_STATUS.UNKNOWN_WORDS_CURATION.value
        ? CURATION_STATUS.UNKNOWN_WORDS_CURATION.value
        : CURATION_STATUS.UNKNOWN_WORDS_QA.value,
    [brand.status],
  )

  const [upsertBrandDish] = useMutation(UPSERT_BRAND_DISH_MUTATION, {
    onError: handleMutationError,
    refetchQueries: [
      {
        query: BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
        variables: { brandId, assignedUserType },
      },
    ],
  })

  const save = useCallback(
    async ({ callback, modifiedDish, aiSuggestions } = {}) => {
      startLoading()

      const preparedDish = dishTransformer.transformDishToDto(
        modifiedDish || dishToSave,
        aiSuggestions,
        brandId,
        originalDish,
      )

      // get dish translations
      if (!isNil(dishToSave)) {
        if (
          !isNil(dishToSave.name) ||
          !isNil(dishToSave.description) ||
          !isNil(dishToSave.ingredientsText)
        ) {
          const translations = await translateDish(dishToSave)
          forEach(keys(translations), fieldName => {
            preparedDish[`${fieldName}Translation`] = translations[fieldName]
          })
        }
      }

      try {
        const {
          data: { upsertBrandDish: upsertedBrandDish },
        } = await upsertBrandDish({ variables: { input: preparedDish } })

        if (callback) {
          callback(upsertedBrandDish)
        }
      } catch (error) {
        notification.error({
          message: 'An error occured',
          description: error.message,
          placement: 'topLeft',
        })
      } finally {
        stopLoading()
      }
    },
    [
      startLoading,
      brandId,
      originalDish,
      dishToSave,
      upsertBrandDish,
      stopLoading,
    ],
  )

  return {
    save,
  }
}

export default useSaveBrandDish
