import { gql } from '@apollo/client'

import { SIMPLE_USER_FRAGMENT } from '../../../common/graphql'

export default gql`
  fragment UserReportRequestFragment on UserReportRequest {
    id
    status
    errorMessage
    type
    user {
      ...SimpleUserFragment
    }
    params
    createdAt
  }
  ${SIMPLE_USER_FRAGMENT}
`
