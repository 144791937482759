import * as Yup from 'yup'

const brandEntryValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  web: Yup.string().url().nullable(),
  menuLink: Yup.string().url().nullable(),
  dietLabelLegends: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
    }),
  ),
  allergenLabelLegends: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(),
    }),
  ),
})

export default brandEntryValidationSchema
