import React, { useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Modal, Spin } from 'antd'

import { useQueryParams } from '../../../../core/hooks'
import { isStringTrue } from '../../../../core/utils'

import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { useAuthentication, useCachedBrand } from '../../../../common/hooks'
import { FULL_SCREEN_MODAL_STYLES } from '../../../../common/styles'

import { DEFAULT_DISH_LIST_FILTERS } from '../../../../dishes/constants'
import { BRAND_DISHES_QUERY } from '../../../../dishes/graphql'
import { DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS } from '../../../constants'
import { BRAND_SUGGESTED_WORDS } from '../../../graphql'
import { brandSuggestedWordsCompletenessCountPropType } from '../../../propTypes'
import { BrandSuggestedWordsModalBody } from '../BrandSuggestedWordsModalBody'
import { BrandSuggestedWordsModalHeader } from '../BrandSuggestedWordsModalHeader'

import './brandSuggestedWordsModal.css'

const BrandSuggestedWordsModal = ({ brandSuggestedWordsCompletenessCount }) => {
  const { brandId } = useParams()
  const brand = useCachedBrand()
  const { status } = brand || {}

  const initialUnknownWordsSuggestedCount = useRef(
    brandSuggestedWordsCompletenessCount.wordsToResolveTotal,
  )

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    brandSuggestedWordsCurrentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    brandSuggestedWordsPageSize = userDefaultPageSize ||
      DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    sortUnknownWordsBy = DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS.SORT_BY,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    sortDishesBy,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
    setQueryParams,
  } = useQueryParams()

  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? status : null),
    [dishIsStatusSameAsBrandFilter, status],
  )

  const { loading: isLoading, data: { brandSuggestedWords } = {} } = useQuery(
    BRAND_SUGGESTED_WORDS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        brandId,
        sortBy: sortUnknownWordsBy,
        pagination: {
          currentPage: Number(brandSuggestedWordsCurrentPage),
          pageSize: Number(brandSuggestedWordsPageSize),
        },
      },
    },
  )

  const [fetchDishList] = useLazyQuery(BRAND_DISHES_QUERY, {
    variables: {
      brandId,
      paginationFiltersAndSorters: {
        pageSize: Number(pageSize),
        currentPage: Number(currentPage),
        filters: {
          status: statusForDishFiltering,
          servicingHours: servicingHoursFilter,
          published: dishPublishedFilter,
          ignored: dishIgnoredFilter,
          hasErrors: isStringTrue(dishHasErrorsFilter),
          menuTitle: menuTitleId,
          search,
        },
        sortDishesBy,
      },
    },
  })

  const closeModal = useCallback(() => {
    if (
      initialUnknownWordsSuggestedCount.current >
      brandSuggestedWordsCompletenessCount.wordsToResolveTotal
    ) {
      fetchDishList()
    }
    setQueryParams({
      isBrandSuggestedWordsModalShown: null,
      sortUnknownWordsBy: null,
      brandSuggestedWordsCurrentPage: null,
      brandSuggestedWordsPageSize: null,
    })
  }, [
    brandSuggestedWordsCompletenessCount.wordsToResolveTotal,
    fetchDishList,
    setQueryParams,
  ])

  return (
    <Modal
      closeIcon={
        <CloseCircleOutlined style={FULL_SCREEN_MODAL_STYLES.closeIcon} />
      }
      style={FULL_SCREEN_MODAL_STYLES.modal}
      wrapClassName="brand-suggested-words-modal"
      width={FULL_SCREEN_MODAL_STYLES.modal.width}
      title={
        isLoading ? (
          'Loading Words...'
        ) : (
          <BrandSuggestedWordsModalHeader
            brandSuggestedWordsCompletenessCount={
              brandSuggestedWordsCompletenessCount
            }
          />
        )
      }
      visible
      footer={null}
      onCancel={closeModal}
      bodyStyle={FULL_SCREEN_MODAL_STYLES.modalBody}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <BrandSuggestedWordsModalBody
          closeModal={closeModal}
          brandSuggestedWords={brandSuggestedWords}
          brandSuggestedWordsCompletenessCount={
            brandSuggestedWordsCompletenessCount
          }
        />
      )}
    </Modal>
  )
}

BrandSuggestedWordsModal.propTypes = {
  brandSuggestedWordsCompletenessCount:
    brandSuggestedWordsCompletenessCountPropType.isRequired,
}

export default BrandSuggestedWordsModal
