import PropTypes from 'prop-types'

const treeOptionPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
})

treeOptionPropType.children = PropTypes.arrayOf(treeOptionPropType)

export default treeOptionPropType
