import { gql } from '@apollo/client'

import { DISHES_SUMMARY_FRAGMENT } from '../../../common/graphql'

const DISHES_SUMMARY_QUERY = gql`
  query dishesSummaryQuery($brandId: ID!) {
    dishesSummary(brandId: $brandId) {
      ...DishesSummaryFragment
    }
  }
  ${DISHES_SUMMARY_FRAGMENT}
`

export default DISHES_SUMMARY_QUERY
