const { notification } = require('antd')

const handleMutationError = error =>
  notification.error({
    message: 'An error occured',
    description: error.message,
    placement: 'topLeft',
  })

export default handleMutationError
