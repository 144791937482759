export default Object.freeze({
  menuContainer: {
    display: 'flex',
    maxHeight: '800px',
    overflow: 'auto',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    width: '15rem',
    height: '2.5rem',
    borderBottom: '1px solid #d9d9d9',
  },
  priceInput: {
    width: '3.75rem',
    height: '1.5rem',
    margin: '0 0.5rem',
  },
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    display: 'flex',
    height: '2.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #d9d9d9',
  },

  priceInputSelected: {
    backgroundColor: '#e6f7ff',
  },
})
