import { gql } from '@apollo/client'

export default gql`
  fragment SimpleBrandDishFragment on SimpleBrandDish {
    id
    name
    styledName
    description
    styledDescription
    ingredientsText
    styledIngredientsText
    menuTitle {
      id
      name
    }
    aiSuggestions {
      miscAndChoice {
        certainty
        messages {
          level
          message
        }
        name {
          text
          score
        }
        description {
          text
          score
        }
        ingredientsText {
          text
          score
        }
      }
    }
  }
`
