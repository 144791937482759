import {
  filter,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  some,
  toLower,
  uniq,
} from 'lodash'

import { findNodeByValue } from '../../common/utils'

import { ALLERGENS, COURSE_TYPE, DIETS, DISH_TYPE } from '../constants'

import suggestCourseType from './suggestCourseType'
import suggestDishType from './suggestDishType'
import suggestIngredients from './suggestIngredients'
import suggestLabels from './suggestLabels'

export default async function suggestCuration({
  dish,
  additionalInformation = {},
}) {
  const { brand = {} } = additionalInformation
  const aiSuggestions = {}
  const unsavedDish = {}
  let dishType = {}

  // STEP 1. Suggest dish Type
  if (
    !additionalInformation.isRefetchFromDishType &&
    !additionalInformation.isRefetchFromDietIds &&
    !additionalInformation.isRefetchFromVariousText
  ) {
    const dishTypeSuggestions = await suggestDishType({
      dish,
      additionalInformation,
    })
    if (isEmpty(dishTypeSuggestions)) {
      return false
    }

    unsavedDish.dishTypeId = dishTypeSuggestions.dishTypeId
    aiSuggestions[DISH_TYPE] = dishTypeSuggestions.aiSuggestions
  }

  dishType = {
    id:
      get(additionalInformation, 'dishType.id') ||
      unsavedDish.dishTypeId ||
      get(dish, 'dishType.id'),
    name:
      get(
        findNodeByValue(
          get(additionalInformation, 'dishTypeOptions'),
          get(additionalInformation, 'dishType.id') ||
            unsavedDish.dishTypeId ||
            get(dish, 'dishType.id'),
        ),
        'name',
      ) || '',
  }

  // STEP 2. Suggest Diet and Allergen Labels
  if (
    (!additionalInformation.isRefetchFromDishType &&
      !additionalInformation.isRefetchFromDietIds) ||
    (additionalInformation.isRefetchFromDishType &&
      additionalInformation.isDishIgnored) ||
    additionalInformation.isRefetchFromVariousText
  ) {
    const labelSuggestions = await suggestLabels({
      dish,
      additionalInformation,
    })

    if (!isNil(labelSuggestions)) {
      const { dietLabelSuggestions, allergenLabelSuggestions } =
        labelSuggestions
      if (!isEmpty(dietLabelSuggestions.aiSuggestions)) {
        aiSuggestions[DIETS] = dietLabelSuggestions.aiSuggestions

        // when saving diets keep existing diets from brand
        unsavedDish.dietIds = uniq([
          ...filter(map(dish.diets, 'id'), dishDietId =>
            some(
              map(brand.diets, 'id'),
              brandDietId => dishDietId === brandDietId,
            ),
          ),
          ...dietLabelSuggestions.dietIds,
        ])

        // if the dish contains both vegan and vegetarian diets, keep only the vegan ones
        const veganId = get(
          find(
            additionalInformation.dietOptions,
            ({ name }) => toLower(name) === 'vegan',
          ),
          'id',
        )
        const vegetarianId = get(
          find(
            additionalInformation.dietOptions,
            ({ name }) => toLower(name) === 'vegetarian',
          ),
          'id',
        )

        if (
          includes(unsavedDish.dietIds, veganId) &&
          includes(unsavedDish.dietIds, vegetarianId)
        ) {
          unsavedDish.dietIds = filter(
            unsavedDish.dietIds,
            dietId => dietId !== vegetarianId,
          )
        }
      }

      if (!isEmpty(allergenLabelSuggestions.aiSuggestions)) {
        aiSuggestions[ALLERGENS] = allergenLabelSuggestions.aiSuggestions
      }
    }
  }
  const diets = isNil(additionalInformation.dietIds || unsavedDish.dietIds)
    ? dish.diets
    : map(additionalInformation.dietIds || unsavedDish.dietIds, dietId => ({
        id: dietId,
      }))

  // STEP 3. Suggest ingredients
  const ingredientSuggestions = await suggestIngredients({
    dish: {
      ...dish,
      diets,
      dishType,
    },
    additionalInformation,
  })

  if (!isEmpty(ingredientSuggestions)) {
    aiSuggestions.ingredients = ingredientSuggestions.aiSuggestions
  }

  // STEP 3. Suggest Course Type
  if (
    !additionalInformation.isRefetchFromDietIds &&
    !additionalInformation.isRefetchFromVariousText
  ) {
    const courseTypeSuggestions = await suggestCourseType({
      dish: {
        ...dish,
        dishType,
      },
      additionalInformation,
    })

    if (!isEmpty(courseTypeSuggestions)) {
      aiSuggestions[COURSE_TYPE] = courseTypeSuggestions.aiSuggestions
      unsavedDish.courseTypeId = courseTypeSuggestions.courseTypeId
    }
  }
  return {
    aiSuggestions,
    unsavedDish,
  }
}
