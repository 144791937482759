import { gql } from '@apollo/client'

const GET_BATCH_AI_SUGGESTIONS_FOR_STATUS_MUTATION = gql`
  mutation getBatchAiSuggestionsForStatus(
    $input: BrandBatchAiSuggestionsInput!
  ) {
    getBatchAiSuggestionsForStatus(input: $input) {
      id
      status
      pendingStatus
    }
  }
`

export default GET_BATCH_AI_SUGGESTIONS_FOR_STATUS_MUTATION
