import { combineReducers } from 'redux'

const activeNames = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_BRAND_NAME':
      return { brand: action.payload }
    case 'SET_ACTIVE_LOCATION_NAME':
      return { location: action.payload }
    case 'SET_ACTIVE_USER_EMAIL':
      return { user: action.payload }

    default: {
      return state
    }
  }
}

const activeMenuItem = (state = null, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_MENU_ITEM':
      return action.payload
    default: {
      return state
    }
  }
}

const availableReports = (state = null, action) => {
  switch (action.type) {
    case 'SET_AVAILABLE_AI_REPORTS':
      return action.payload
    default: {
      return state
    }
  }
}

const isLoadingReducer = (isLoading = false, action) => {
  switch (action.type) {
    case 'APP_LOADING_START': {
      return true
    }
    case 'APP_LOADING_STOP': {
      return false
    }
    default:
      return isLoading
  }
}

const appReducer = combineReducers({
  activeNames,
  activeMenuItem,
  availableReports,
  isLoading: isLoadingReducer,
})

export default appReducer
