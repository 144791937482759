import { gql } from '@apollo/client'

import {
  LANGUAGE_FRAGMENT,
  METADATA_NODE_FRAGMENT,
  SIMPLE_ENTITY_FRAGMENT,
} from '../fragments'

const METADATA_OPTIONS_QUERY = gql`
  query metadataOptionsQuery {
    locationTypes {
      ...MetadataNodeFragment
    }
    cuisineTypes {
      ...MetadataNodeFragment
    }
    dishTypes {
      ...MetadataNodeFragment
    }
    courseTypes {
      ...MetadataNodeFragment
    }
    ingredients {
      ...MetadataNodeFragment
    }
    diets {
      ...SimpleEntityFragment
      isCurationEnabled
    }
    allergens {
      ...SimpleEntityFragment
      isCurationEnabled
    }
    languages {
      ...LanguageFragment
    }
  }
  ${METADATA_NODE_FRAGMENT}
  ${SIMPLE_ENTITY_FRAGMENT}
  ${LANGUAGE_FRAGMENT}
`

export default METADATA_OPTIONS_QUERY
