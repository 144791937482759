import { useMemo } from 'react'
import { includes, isEmpty } from 'lodash'

import { USER_DEPARTMENT, USER_OTHER_QUALIFICATION_VALUE } from '../constants'

const useUserFormDisabledFields = ({
  isReadOnly,
  selectedDepartmentValues,
  selectedQualificationValues,
  qualificationOptions,
  loggedInUser,
  userId,
}) => {
  const isActiveFieldDisabled = useMemo(
    () => isReadOnly || loggedInUser.id === userId,
    [isReadOnly, loggedInUser.id, userId],
  )
  const isUserQualificationFieldDisabled = useMemo(
    () => isReadOnly || isEmpty(qualificationOptions),
    [isReadOnly, qualificationOptions],
  )
  const areCuisineAndLanguageFieldsDisabled = useMemo(
    () =>
      isReadOnly ||
      !includes(selectedDepartmentValues, USER_DEPARTMENT.CURATION),
    [isReadOnly, selectedDepartmentValues],
  )
  const isWorkExperienceFieldDisabled = useMemo(
    () =>
      isReadOnly ||
      (!includes(selectedDepartmentValues, USER_DEPARTMENT.CURATION) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.SCRAPING)),
    [isReadOnly, selectedDepartmentValues],
  )
  const isTierFieldDisabled = useMemo(
    () =>
      isReadOnly ||
      (!includes(selectedDepartmentValues, USER_DEPARTMENT.CURATION) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.SCRAPING) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.MANAGEMENT) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.RECRUITMENT)),
    [isReadOnly, selectedDepartmentValues],
  )

  const isOtherQualificationFieldDisabled = useMemo(
    () =>
      isReadOnly ||
      !includes(selectedQualificationValues, USER_OTHER_QUALIFICATION_VALUE),
    [isReadOnly, selectedQualificationValues],
  )

  return {
    isActiveFieldDisabled,
    isUserQualificationFieldDisabled,
    areCuisineAndLanguageFieldsDisabled,
    isWorkExperienceFieldDisabled,
    isTierFieldDisabled,
    isOtherQualificationFieldDisabled,
  }
}

export default useUserFormDisabledFields
