import { gql } from '@apollo/client'

const DISHES_SUMMARY_FRAGMENT = gql`
  fragment DishesSummaryFragment on DishesSummary {
    publishedCount
    pendingCount
    automaticIgnoredCount
    manualIgnoredCount
    qaDoneCount
    qqaDoneCount
    uncertainDishesCount
    validatedDishesCount
  }
`

export default DISHES_SUMMARY_FRAGMENT
