import { filter, isEmpty, map, size } from 'lodash'
import shortid from 'shortid'

import { stripHtmlFromText } from '../../common/utils'

const findMatchedWordsIndexesFromTextField = (
  matchedWords,
  styledTextField,
) => {
  let lastIndex = 0

  if (isEmpty(styledTextField)) {
    return []
  }

  return filter(
    map(matchedWords, matchedWord => {
      const strippedWord = stripHtmlFromText(matchedWord)
      const indexInStyledText = styledTextField.indexOf(matchedWord, lastIndex)

      if (indexInStyledText !== -1) {
        // find where the word is found and place a unique identifier such that when the html
        // is stripped from the styled text we know exaclty where the start index of the word is
        const wordStartSymbol = `*${shortid.generate()}*`
        const styledTextWithStartSymbol = `${styledTextField.substring(
          0,
          indexInStyledText,
        )}${wordStartSymbol}${styledTextField.substring(indexInStyledText)}`

        const strippedTextWithStartSymbol = stripHtmlFromText(
          styledTextWithStartSymbol,
        )
        const indexInStrippedText =
          strippedTextWithStartSymbol.indexOf(
            strippedWord,
            strippedTextWithStartSymbol.indexOf(wordStartSymbol),
          ) - size(wordStartSymbol)

        // keep track of the last index that was found such that for the next word we start from here
        // this way we will not find the same word twice
        lastIndex = indexInStyledText + size(matchedWord)
        return [indexInStrippedText, indexInStrippedText + size(strippedWord)]
      }

      return []
    }),

    indexPairs => !isEmpty(indexPairs),
  )
}

export default findMatchedWordsIndexesFromTextField
