export default Object.freeze({
  SET_DISH_NAME: 'SET_DISH_NAME',
  SET_DISH_DIET_IDS: 'SET_DISH_DIET_IDS',
  SET_DISH_ALLERGEN: 'SET_DISH_ALLERGEN',
  SET_DISH_LOW_PRICE: 'SET_DISH_LOW_PRICE',
  SET_DISH_HIGH_PRICE: 'SET_DISH_HIGH_PRICE',
  SET_DISH_DISH_TYPE_ID: 'SET_DISH_DISH_TYPE_ID',
  SET_DISH_COURSE_TYPE_ID: 'SET_DISH_COURSE_TYPE_ID',
  SET_DISH_MAIN_INGREDIENTS: 'SET_DISH_MAIN_INGREDIENTS',
  SET_DISH_ADDITIONAL_INGREDIENTS: 'SET_DISH_ADDITIONAL_INGREDIENTS',
  SET_DISH_INGREDIENTS_TEXT: 'SET_DISH_INGREDIENTS_TEXT',
  SET_DISH_MENU_TITLE_ID: 'SET_DISH_MENU_TITLE_ID',
  SET_DISH_DESCRIPTION: 'SET_DISH_DESCRIPTION',
  SET_DISH_SERVICING_HOURS_IDS: 'SET_DISH_SERVICING_HOURS_IDS',
  SET_DISH_EXCLUDED_INGREDIENTS: 'SET_DISH_EXCLUDED_INGREDIENTS',
  SET_DISH_CHOICE_INGREDIENTS: 'SET_DISH_CHOICE_INGREDIENTS',
  SET_DISH_SELECTED_LOCATIONS: 'SET_DISH_SELECTED_LOCATIONS',
})
