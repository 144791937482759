import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Select } from 'antd'
import { every, find, includes, isNil, map, toLower } from 'lodash'
import PropTypes from 'prop-types'

import { selectFilterOption } from '../../../../core/utils'

import { AVAILABLE_HOTKEYS } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import { DISH_FIELDS } from '../../../constants'
import { DishEditableSelectsRowContext } from '../../../contexts'
import { useEditableCellHotkeyEdit } from '../../../hooks'
import { dishPropType } from '../../../propTypes'

const styles = {
  select: { width: '100%' },
  dropdown: { maxHeight: 400, minWidth: 250 },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editDisabled: {
    cursor: 'not-allowed',
  },
}

const EditableCellSelectField = ({
  name,
  defaultValue,
  defaultComponent,
  disabled,
  dish,
}) => {
  const [editing, setEditing] = useState(false)
  const [selectedValue, setSelectedValue] = useState([])
  const selectRef = useRef(null)
  const { saveCuration } = useContext(DishEditableSelectsRowContext)
  const { dietOptions, allergenOptions } = useCurationMetadata()
  const { forceEditState, setHotkeyEditMode } = useEditableCellHotkeyEdit(
    dish.id,
    name,
  )

  const optionsToShow = useMemo(() => {
    const curationMetadata = {
      [DISH_FIELDS.DIETS]: dietOptions,
      [DISH_FIELDS.ALLERGENS]: allergenOptions,
    }

    return curationMetadata[name]
  }, [allergenOptions, dietOptions, name])

  useEffect(() => {
    if (!isNil(selectRef.current)) {
      selectRef.current.focus()
    }
  }, [editing])

  const initialIds = useMemo(() => map(defaultValue, 'id'), [defaultValue])

  useEffect(() => {
    setSelectedValue(initialIds)
  }, [dish.id, initialIds])

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      setEditing(true)
    },
    [setEditing],
  )

  const handleBlur = useCallback(() => {
    if (
      !every(selectedValue, id => includes(initialIds, id)) ||
      !every(initialIds, prevId => includes(selectedValue, prevId))
    ) {
      saveCuration(dish, name, selectedValue)
    }
    setEditing(false)
    setHotkeyEditMode(false)
  }, [selectedValue, initialIds, setHotkeyEditMode, saveCuration, dish, name])

  useEffect(() => {
    if (forceEditState) {
      setEditing(forceEditState)
    }
  }, [forceEditState])

  // hotkey to stop editing
  useHotkeys(
    AVAILABLE_HOTKEYS.ESC.hotkey,
    handleBlur,
    {
      enabled: editing,
      enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
    },
    [editing],
  )

  const handleDeselect = useCallback(() => {
    selectRef.current.focus()
  }, [selectRef])

  const handleChange = useCallback(
    newValues => {
      if (name === DISH_FIELDS.DIETS) {
        // do not let users select both vegan and vegetarian
        const dietNames = map(newValues, id =>
          toLower(
            (find(optionsToShow, option => option.value === id) || {}).label,
          ),
        )
        if (includes(dietNames, 'vegan') && includes(dietNames, 'vegetarian')) {
          return null
        }
      }

      return setSelectedValue(newValues)
    },
    [name, optionsToShow],
  )

  return (
    <div
      onClick={handleClick}
      role="presentation"
      style={{ ...styles.container, ...(disabled && styles.editDisabled) }}
    >
      {editing && !disabled ? (
        <Select
          mode="multiple"
          style={styles.select}
          dropdownStyle={styles.dropdown}
          dropdownClassName="dark-dropdown-highlights"
          value={selectedValue}
          onChange={handleChange}
          onDeselect={handleDeselect}
          onBlur={handleBlur}
          ref={selectRef}
          allowClear
          optionFilterProp="children"
          filterOption={selectFilterOption}
          options={optionsToShow}
        />
      ) : (
        defaultComponent
      )}
    </div>
  )
}

EditableCellSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultComponent: PropTypes.node.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  dish: dishPropType.isRequired,
}

EditableCellSelectField.defaultProps = {
  disabled: false,
  defaultValue: [],
}

export default EditableCellSelectField
