import { keys, map, reduce } from 'lodash'

export const transformToSaveArgs = formValues =>
  map(keys(formValues), key => ({
    name: key,
    isEnabled: formValues[key],
  }))

export const transformToFormValues = settings =>
  reduce(
    settings,
    (acc, setting) => ({ ...acc, [setting.name]: setting.isEnabled }),
    [],
  )
