import { gql } from '@apollo/client'

import { CLEANUP_TASK_FRAGMENT } from '../fragments'

const CLEANUP_TASKS_QUERY = gql`
  query cleanupTasks(
    $currentPage: Int!
    $metadataType: String!
    $pageSize: Int!
    $filters: CleanupTasksFiltersInput!
  ) {
    cleanupTasks(
      currentPage: $currentPage
      metadataType: $metadataType
      pageSize: $pageSize
      filters: $filters
    ) {
      count
      tasks {
        ...CleanupTaskFragment
      }
    }
  }
  ${CLEANUP_TASK_FRAGMENT}
`

export default CLEANUP_TASKS_QUERY
