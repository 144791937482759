import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from '../../../brands/graphql'

const CUISINE_TYPE_HUMAN_CLEANUP_FRAGMENT = gql`
  fragment CuisineTypeHumanCleanupFragment on CuisineTypeHumanCleanup {
    id
    brand {
      ...BrandFragment
    }
    isCurationDone
    isQaDone
    cleanupCuisineTypeId
  }
  ${BRAND_FRAGMENT}
`

export default CUISINE_TYPE_HUMAN_CLEANUP_FRAGMENT
