import React, { useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { find, get, includes, isEmpty, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../core/hooks'
import { isStringTrue } from '../../core/utils'

import { AVAILABLE_HOTKEYS, CURATION_STATUS } from '../../common/constants'
import { handleMutationError } from '../../common/helpers'
import {
  useRowHighlight,
  useUserHasBrandChangePermission,
} from '../../common/hooks'

import { DISHES_SUMMARY_QUERY } from '../../brands/graphql/queries'
import { DEFAULT_DISH_LIST_FILTERS, DISH_FIELDS } from '../constants'
import { DishTableFocusActionsContext } from '../contexts'
import {
  PUBLISH_DISH_MUTATION,
  SET_DISH_QA_DONE_MUTATION,
  SET_DISH_QQA_DONE_MUTATION,
  SET_DISH_VALIDATED_MUTATION,
} from '../graphql'

const DishTableFocusActionsProvider = ({
  children,
  dishes,
  dishesLoading,
  brandStatus,
  isBrandDirty,
}) => {
  const { brandId } = useParams()
  const [hotkeyEditMode, setHotkeyEditMode] = useState(false)
  const [isPopoverModeEnabled, setIsPopoverModeEnabled] = useState(false)
  const [navigationEnabled, setNavigationEnabled] = useState(false)
  const dishSummaryRefetchOptions = useMemo(
    () => ({
      refetchQueries: [
        {
          query: DISHES_SUMMARY_QUERY,
          variables: { brandId },
        },
      ],
    }),
    [brandId],
  )
  const [setDishValidated] = useMutation(
    SET_DISH_VALIDATED_MUTATION,
    dishSummaryRefetchOptions,
  )
  const [setQaDone] = useMutation(
    SET_DISH_QA_DONE_MUTATION,
    dishSummaryRefetchOptions,
  )
  const [setQqaDone] = useMutation(
    SET_DISH_QQA_DONE_MUTATION,
    dishSummaryRefetchOptions,
  )
  const [publishDish] = useMutation(
    PUBLISH_DISH_MUTATION,
    dishSummaryRefetchOptions,
  )
  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const {
    pageSize,
    currentPage,
    sortDishesBy,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours,
    search,
    menuTitleId,
  } = useQueryParams()

  const { initHighlight, selectedRowKey, selectedCellKey } = useRowHighlight({
    tableSelector: '.dishes-table .ant-table-body table',
    rowsSelector:
      '.dishes-table .ant-table-body table tbody > tr:not(.ant-table-measure-row)',
    cellsSelector: `.dishes-table .ant-table-body table tbody > tr:not(.ant-table-measure-row) td.cell-editable, .dishes-table .ant-table-body table tbody > tr:not(.ant-table-measure-row) td.cell-text-editable`,
    scrollContainerSelector: '.dishes-table .ant-table-body',
    disableHotkeys:
      hotkeyEditMode || !navigationEnabled || isPopoverModeEnabled,
    disabledCellSelection:
      !navigationEnabled ||
      includes(
        [CURATION_STATUS.MISC_AND_CHOICE.value, CURATION_STATUS.CURATION.value],
        brandStatus,
      ),
    disabledRowSelection:
      !navigationEnabled || brandStatus === CURATION_STATUS.CURATION.value,
  })

  useEffect(() => {
    setTimeout(initHighlight, 0)
    setHotkeyEditMode(false)
    setIsPopoverModeEnabled(false)
  }, [
    dishesLoading,
    menuTitleId,
    initHighlight,
    pageSize,
    currentPage,
    sortDishesBy,
    dishIsStatusSameAsBrandFilter,
    dishPublishedFilter,
    dishHasErrorsFilter,
    dishIgnoredFilter,
    servicingHours,
    search,
  ])

  // we use this so we cand deactive the navigation for a user that is unassinged from a brand and no longer has permissions
  useEffect(() => {
    if (!userHasBrandChangePermission) {
      setNavigationEnabled(false)
    }
  }, [userHasBrandChangePermission])

  // the first press of the "down" key will activate the table navigation and bring the table into view
  // only users that have permissions on that brand can activate the navigation
  useHotkeys(
    AVAILABLE_HOTKEYS.DOWN.hotkey,
    async () => {
      setNavigationEnabled(true)
      const [scrollContainer] = document.querySelectorAll(
        '.dishes-table .ant-table-body',
      )
      if (scrollContainer) {
        scrollContainer.scrollIntoView()
      }
    },
    {
      enabled: !navigationEnabled && userHasBrandChangePermission,
    },
    [navigationEnabled, userHasBrandChangePermission],
  )

  // hotkeys for dish validate/qa done/publish
  useHotkeys(
    AVAILABLE_HOTKEYS.S.hotkey,
    async () => {
      const dish = find(dishes, ({ id }) => id === selectedRowKey)

      if (!isEmpty(dish)) {
        try {
          if (brandStatus === CURATION_STATUS.QA.value) {
            await setQaDone({
              variables: { ids: [selectedRowKey], value: !dish.isQaDone },
            })
            return
          }

          if (brandStatus === CURATION_STATUS.QQA_CONFIRMATION.value) {
            await setQqaDone({
              variables: { ids: [selectedRowKey], value: !dish.isQqaDone },
            })
            return
          }

          if (
            (isStringTrue(dishIsStatusSameAsBrandFilter) ||
              isStringTrue(dishHasErrorsFilter)) &&
            brandStatus === CURATION_STATUS.CURATION_CONFIRMATION.value
          ) {
            await setDishValidated({
              variables: { ids: [selectedRowKey], value: !dish.isValidated },
            })
            return
          }

          if (
            userHasBrandChangePermission &&
            ((isStringTrue(dishIsStatusSameAsBrandFilter) &&
              !isStringTrue(dishHasErrorsFilter)) ||
              brandStatus === CURATION_STATUS.DONE.value)
          ) {
            await publishDish({
              variables: { id: selectedRowKey, published: !dish.published },
            })
            return
          }
        } catch (e) {
          handleMutationError(e)
        }
      }
    },
    {
      enabled:
        includes(
          [
            CURATION_STATUS.CURATION_CONFIRMATION.value,
            CURATION_STATUS.DONE.value,
            CURATION_STATUS.QA.value,
            CURATION_STATUS.QQA_CONFIRMATION.value,
          ],
          brandStatus,
        ) &&
        !hotkeyEditMode &&
        !isPopoverModeEnabled &&
        navigationEnabled,
    },
    [
      dishes,
      selectedRowKey,
      brandStatus,
      hotkeyEditMode,
      isPopoverModeEnabled,
      navigationEnabled,
      userHasBrandChangePermission,
      dishIsStatusSameAsBrandFilter,
      dishHasErrorsFilter,
      setQqaDone,
    ],
  )
  useHotkeys(
    AVAILABLE_HOTKEYS.SHIFT_S.hotkey,
    async () => {
      try {
        const dishIds = map(dishes, 'id')
        if (brandStatus === CURATION_STATUS.QA.value) {
          const currentValue = !some(dishes, dish => !dish.isQaDone)
          await setQaDone({
            variables: { ids: dishIds, value: !currentValue },
          })
          return
        }

        if (brandStatus === CURATION_STATUS.QQA_CONFIRMATION.value) {
          const currentValue = !some(dishes, dish => !dish.isQqaDone)
          await setQqaDone({
            variables: { ids: dishIds, value: !currentValue },
          })
          return
        }

        if (
          (isStringTrue(dishIsStatusSameAsBrandFilter) ||
            isStringTrue(dishHasErrorsFilter)) &&
          brandStatus === CURATION_STATUS.CURATION_CONFIRMATION.value
        ) {
          const currentValue = !some(dishes, dish => !dish.isValidated)
          await setDishValidated({
            variables: { ids: dishIds, value: !currentValue },
          })
          return
        }
      } catch (e) {
        handleMutationError(e)
      }
    },
    {
      enabled:
        includes(
          [
            CURATION_STATUS.CURATION_CONFIRMATION.value,
            CURATION_STATUS.QA.value,
            CURATION_STATUS.QQA_CONFIRMATION.value,
          ],
          brandStatus,
        ) &&
        !hotkeyEditMode &&
        !isPopoverModeEnabled &&
        navigationEnabled,
    },
    [
      dishes,
      selectedRowKey,
      brandStatus,
      hotkeyEditMode,
      isPopoverModeEnabled,
      navigationEnabled,
      dishIsStatusSameAsBrandFilter,
      dishHasErrorsFilter,
      setQqaDone,
    ],
  )

  // Hotkeys for edit mode
  useHotkeys(
    AVAILABLE_HOTKEYS.ENTER.hotkey,
    event => {
      event.preventDefault()
      // we disable edit mode for courseType if the current dish is ignored
      if (selectedCellKey === DISH_FIELDS.COURSE_TYPE) {
        const dish = find(dishes, ({ id }) => id === selectedRowKey)
        if (get(dish, 'dishType.isIgnored')) {
          return
        }
      }

      setHotkeyEditMode(true)
    },
    {
      enabled:
        includes(
          [
            CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
            CURATION_STATUS.CURATION_CONFIRMATION.value,
            CURATION_STATUS.QA.value,
            CURATION_STATUS.QQA_CONFIRMATION.value,
          ],
          brandStatus,
        ) &&
        !isBrandDirty &&
        !hotkeyEditMode &&
        !isPopoverModeEnabled &&
        navigationEnabled,
    },
    [
      hotkeyEditMode,
      isPopoverModeEnabled,
      brandStatus,
      navigationEnabled,
      isBrandDirty,
      dishes,
      selectedRowKey,
      selectedCellKey,
    ],
  )

  // Hotkeys for copy to clipboard
  useHotkeys(
    [AVAILABLE_HOTKEYS.CMD_C.hotkey, AVAILABLE_HOTKEYS.CTRL_C.hotkey].join(','),
    async () => {
      const dishLink = `http://${window.location.host}${
        window.location.pathname
      }${
        isEmpty(window.location.search) ? '' : `${window.location.search}&`
      }selectedDishId=${selectedRowKey}`

      try {
        navigator.clipboard.writeText(dishLink)
      } catch (e) {
        handleMutationError(e)
      }
    },
    {
      enabled: !hotkeyEditMode && navigationEnabled && !isPopoverModeEnabled,
    },
    [hotkeyEditMode, selectedRowKey, navigationEnabled, isPopoverModeEnabled],
  )

  return (
    <DishTableFocusActionsContext.Provider
      value={{
        selectedRowKey,
        selectedCellKey,
        hotkeyEditMode,
        isPopoverModeEnabled,
        setHotkeyEditMode,
        setIsPopoverModeEnabled,
      }}
    >
      {children}
    </DishTableFocusActionsContext.Provider>
  )
}

DishTableFocusActionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dishes: PropTypes.arrayOf(PropTypes.object),
  dishesLoading: PropTypes.bool,
  brandStatus: PropTypes.string.isRequired,
  isBrandDirty: PropTypes.bool,
}

DishTableFocusActionsProvider.defaultProps = {
  dishes: [],
  dishesLoading: false,
  isBrandDirty: false,
}

export default DishTableFocusActionsProvider
