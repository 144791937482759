import React, { memo, useMemo } from 'react'
import { Typography } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { findNodeByValue } from '../../../../common/utils'

const styles = {
  negativeSynonym: {
    marginBottom: 0,
    height: '1.25rem',
  },
}

const DishExcludedIngredients = ({
  id,
  word,
  ingredientsTreeData,
  isMlIngredient,
}) => {
  const ingredientPath = useMemo(
    () => get(findNodeByValue(ingredientsTreeData, id), 'namePath'),
    [id, ingredientsTreeData],
  )

  return (
    <Typography.Paragraph style={styles.negativeSynonym}>
      {isMlIngredient ? 'ML' : word} - {ingredientPath}
    </Typography.Paragraph>
  )
}

DishExcludedIngredients.propTypes = {
  id: PropTypes.string.isRequired,
  word: PropTypes.string.isRequired,
  ingredientsTreeData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMlIngredient: PropTypes.bool,
}

DishExcludedIngredients.defaultProps = {
  isMlIngredient: false,
}

export default memo(DishExcludedIngredients)
