import { gql } from '@apollo/client'

const IMPORT_MENU_MUTATION = gql`
  mutation importMenu($input: BrandMenuImportInput!) {
    importMenu(input: $input) {
      id
      pendingStatus
    }
  }
`

export default IMPORT_MENU_MUTATION
