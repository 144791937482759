const NEGATIVE_BRAND_TAGS = Object.freeze({
  IS_NOT_PROBLEMATIC: {
    value: 'IS_NOT_PROBLEMATIC',
    label: 'is not problematic',
    excludedBy: ['IS_PROBLEMATIC'],
  },
  IS_NOT_TEST: {
    value: 'IS_NOT_TEST',
    label: 'is not test',
    excludedBy: ['IS_TEST'],
  },
  IS_NOT_FAKE: {
    value: 'IS_NOT_FAKE',
    label: 'is not fake',
    excludedBy: ['IS_FAKE'],
  },
  IS_NOT_TRAINING: {
    value: 'IS_NOT_TRAINING',
    label: 'is not training',
    excludedBy: ['IS_TRAINING'],
  },
})

export default NEGATIVE_BRAND_TAGS
