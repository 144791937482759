import { useContext } from 'react'

import { ROLES } from '../../common/constants'
import { AuthContext } from '../../common/contexts'
import { useUserHasRoles } from '../../common/hooks'

const useUserHasCleanupTaskChangePermission = task => {
  const {
    userInfo: { id: currentUserId },
  } = useContext(AuthContext)

  const userCanEditAnyTask = useUserHasRoles([
    ROLES.ADMIN.value,
    ROLES.SENIOR_METADATA_CURATOR.value,
  ])

  const { assignedCuratorId, assignedQaId, isCurationDone, isQaDone } =
    task || {}
  const isUserCuratorOfTask = assignedCuratorId === currentUserId
  const isUserQaOfTask = assignedQaId === currentUserId

  if (userCanEditAnyTask) {
    return {
      userHasTaskChangePermission: true,
      isUserCuratorOfTask,
      isUserQaOfTask,
      userCanEditAnyTask,
    }
  }

  if (isUserCuratorOfTask && !isCurationDone) {
    return {
      userHasTaskChangePermission: true,
      isUserCuratorOfTask,
      isUserQaOfTask,
    }
  }

  if (isUserQaOfTask && isCurationDone && !isQaDone) {
    return {
      userHasTaskChangePermission: true,
      isUserCuratorOfTask,
      isUserQaOfTask,
    }
  }

  return {
    userHasTaskChangePermission: false,
    isUserCuratorOfTask,
    isUserQaOfTask,
  }
}

export default useUserHasCleanupTaskChangePermission
