import { gql } from '@apollo/client'

const SIMPLE_ENTITY_FRAGMENT = gql`
  fragment SimpleEntityFragment on SimpleEntity {
    id
    name
  }
`

export default SIMPLE_ENTITY_FRAGMENT
