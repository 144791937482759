import React, { useCallback } from 'react'
import { Tabs } from 'antd'
import { get, isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import {
  DEFAULT_PAGINATION_CONFIG,
  METADATA_TYPES,
} from '../../../../common/constants'

import { DEFAULT_CLEANUP_TASKS_FILTERS } from '../../../constants'

const styles = {
  tabBar: {
    margin: 0,
  },
}

const AVAILABLE_METADATA_TYPES = {
  [METADATA_TYPES.DISH_TYPES]: 'Dish type',
  [METADATA_TYPES.COURSE_TYPES]: 'Course type',
  [METADATA_TYPES.CUISINE_TYPES]: 'Cuisine type',
  [METADATA_TYPES.LOCATION_TYPES]: 'Location type',
  [METADATA_TYPES.INGREDIENTS]: 'Ingredient',
}

const CleanupTasksTabs = ({ summary }) => {
  const {
    metadataType = DEFAULT_CLEANUP_TASKS_FILTERS.METADATA_TYPE,
    setQueryParams,
  } = useQueryParams()

  const handleTabChange = useCallback(
    newTab => {
      setQueryParams({
        metadataType: newTab,
        page: DEFAULT_PAGINATION_CONFIG.PAGE,
      })
    },
    [setQueryParams],
  )

  return (
    <Tabs
      type="card"
      activeKey={metadataType}
      onChange={handleTabChange}
      tabBarStyle={styles.tabBar}
    >
      {map(
        [
          METADATA_TYPES.DISH_TYPES,
          METADATA_TYPES.COURSE_TYPES,
          METADATA_TYPES.CUISINE_TYPES,
          METADATA_TYPES.LOCATION_TYPES,
          METADATA_TYPES.INGREDIENTS,
        ],
        key => {
          const count = get(summary, key)
          return (
            <Tabs.TabPane
              tab={`${AVAILABLE_METADATA_TYPES[key]}${
                isNil(count) ? '' : ` (${count})`
              }`}
              key={key}
            />
          )
        },
      )}
    </Tabs>
  )
}

CleanupTasksTabs.propTypes = {
  summary: PropTypes.arrayOf(PropTypes.object),
}

CleanupTasksTabs.defaultProps = {
  summary: {},
}

export default CleanupTasksTabs
