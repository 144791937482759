import { gql } from '@apollo/client'

export default gql`
  mutation setDishPublished($id: ID!, $published: Boolean!) {
    setDishPublished(id: $id, published: $published) {
      id
      published
    }
  }
`
