import React, { memo, useMemo } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm, Spin } from 'antd'
import { isEmpty, split, toString, truncate } from 'lodash'
import PropTypes from 'prop-types'

import { EVENT_TYPES } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { BRANDS_QUERY, DELETE_BRAND_MUTATION } from '../../../graphql'
import { useBrandFilters } from '../../../hooks'

const DeleteBrandButton = ({ id, name }) => {
  const {
    page,
    pageSize,
    status,
    searchText,
    cuisineTypes,
    predominantLanguages,
    unknownWordsLanguages,
    assignedCurator,
    assignedQa,
    qaDetailLevel,
    tags,
    appropriateForUserId,
  } = useBrandFilters()

  const selectedCuisineTypes = useMemo(
    () => (isEmpty(cuisineTypes) ? [] : split(cuisineTypes, ',')),
    [cuisineTypes],
  )

  const selectedPredominantLanguages = useMemo(
    () =>
      isEmpty(predominantLanguages) ? [] : split(predominantLanguages, ','),
    [predominantLanguages],
  )

  const selectedUnknownWordsLanguages = useMemo(
    () =>
      isEmpty(unknownWordsLanguages) ? [] : split(unknownWordsLanguages, ','),
    [unknownWordsLanguages],
  )

  const [deleteBrand, { loading }] = useMutation(DELETE_BRAND_MUTATION, {
    refetchQueries: [
      {
        query: BRANDS_QUERY,
        variables: {
          pagination: {
            currentPage: page,
            pageSize: Number(pageSize),
          },
          filters: {
            status,
            searchText: toString(searchText),
            tags,
            cuisineTypeIds: selectedCuisineTypes,
            predominantLanguageIds: selectedPredominantLanguages,
            unknownWordsLanguageIds: selectedUnknownWordsLanguages,
            assignedCuratorId: assignedCurator,
            assignedQaId: assignedQa,
            qaDetailLevel,
            appropriateForUserId,
          },
        },
      },
    ],
    onError: handleMutationError,
  })

  return loading ? (
    <Spin size="small" />
  ) : (
    <Popconfirm
      placement="left"
      title={`Delete "${truncate(name)}" brand?`}
      onConfirm={() => {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_EVENTS.DELETE_BRAND_CLICK,
        )
        deleteBrand({ variables: { id } })
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button size="small" icon={<DeleteOutlined />} shape="circle" />
    </Popconfirm>
  )
}

DeleteBrandButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default memo(DeleteBrandButton)
