import React from 'react'
import { Checkbox } from 'antd'
import { PropTypes } from 'prop-types'

const OverridableCheckbox = ({
  checked,
  newChecked,
  onValueChanged,
  disabled,
  text,
  wrappedOnClick,
}) => {
  const checkboxChecked = newChecked !== undefined ? newChecked : checked
  return (
    <Checkbox
      checked={checkboxChecked}
      onChange={({ target: { checked: newCheckedState } }) =>
        onValueChanged(newCheckedState)
      }
      disabled={disabled}
      onClick={wrappedOnClick}
    >
      {text}
    </Checkbox>
  )
}

OverridableCheckbox.propTypes = {
  checked: PropTypes.bool,
  newChecked: PropTypes.bool,
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  wrappedOnClick: PropTypes.func,
}

OverridableCheckbox.defaultProps = {
  checked: false,
  newChecked: undefined,
  disabled: false,
  text: null,
  wrappedOnClick: undefined,
}

export default OverridableCheckbox
