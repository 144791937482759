import React, { useMemo } from 'react'
import { Col, Row, Table, Typography } from 'antd'
import { capitalize, get, isEmpty, isNil, some } from 'lodash'
import PropTypes from 'prop-types'

import { useWindowSize } from '../../../../core/hooks'

import { ReadMore } from '../../../../common/components'
import { formatDescriptors } from '../../../../common/utils'

import { DISH_FIELDS, DISH_TABLE_SCROLL_VALUES } from '../../../constants'
import { DishEditableVariousTextRowContext } from '../../../contexts'
import { dishPropType } from '../../../propTypes'
import { dishFieldsUtils } from '../../../utils'
import {
  DishModalErrors,
  EditableCellHighlightingTextField,
} from '../../molecules'
import { DishEditableVariousTextRow } from '../DishEditableVariousTextRow'
import { DishTableActions } from '../DishTableActions'

const textFieldStyle = { marginTop: '30px' }

const DishEditableVariousTextTable = ({
  dishes,
  dishesHighlightWords,
  brandStatus,
  disabled,
}) => {
  const { height } = useWindowSize()
  const columns = useMemo(() => {
    const hasIngredientsTextOnPage = some(
      dishes,
      ({ ingredientsText }) => !isEmpty(ingredientsText),
    )
    const hasAddonDescriptorsOnPage = some(
      dishes,
      ({ addonDescriptors }) => !isEmpty(addonDescriptors),
    )
    return [
      {
        key: DISH_FIELDS.NAME,
        title: capitalize(DISH_FIELDS.NAME),
        width: hasIngredientsTextOnPage ? '250px' : '350px',
        render: (_, dish, index) => ({
          props: {
            className: `${
              dishFieldsUtils.computeDishCellHighlight(
                dish,
                DISH_FIELDS.NAME,
                brandStatus,
              ) || ''
            } field-name cell-text-editable`,
          },
          children: (
            <EditableCellHighlightingTextField
              dish={dish}
              value={dish.styledName || dish.name}
              highlightingList={
                get(dishesHighlightWords, `${dish.id}.${DISH_FIELDS.NAME}`) ||
                []
              }
              disabled={disabled}
              name={DISH_FIELDS.NAME}
              inputStyle={index === 0 ? textFieldStyle : undefined}
            />
          ),
        }),
      },
      {
        key: DISH_FIELDS.DESCRIPTION,
        title: capitalize(DISH_FIELDS.DESCRIPTION),
        width: hasIngredientsTextOnPage ? '300px' : '400px',
        render: (_, dish, index) => ({
          props: {
            className: `${
              dishFieldsUtils.computeDishCellHighlight(
                dish,
                DISH_FIELDS.DESCRIPTION,
                brandStatus,
              ) || ''
            } field-description cell-text-editable`,
          },
          children: (
            <EditableCellHighlightingTextField
              dish={dish}
              value={dish.styledDescription || dish.description}
              highlightingList={
                get(
                  dishesHighlightWords,
                  `${dish.id}.${DISH_FIELDS.DESCRIPTION}`,
                ) || []
              }
              disabled={disabled}
              name={DISH_FIELDS.DESCRIPTION}
              inputStyle={index === 0 ? textFieldStyle : undefined}
            />
          ),
        }),
      },

      ...(hasIngredientsTextOnPage
        ? [
            {
              key: DISH_FIELDS.INGREDIENTS_TEXT,
              title: 'Ingredients Text',
              width: '250px',
              render: (_, dish, index) => ({
                props: {
                  className: `${
                    dishFieldsUtils.computeDishCellHighlight(
                      dish,
                      DISH_FIELDS.INGREDIENTS_TEXT,
                      brandStatus,
                    ) || ''
                  } field-ingredientsText cell-text-editable`,
                },
                children: (
                  <EditableCellHighlightingTextField
                    dish={dish}
                    value={dish.styledIngredientsText || dish.ingredientsText}
                    highlightingList={
                      get(
                        dishesHighlightWords,
                        `${dish.id}.${DISH_FIELDS.INGREDIENTS_TEXT}`,
                      ) || []
                    }
                    disabled={disabled}
                    name={DISH_FIELDS.INGREDIENTS_TEXT}
                    inputStyle={index === 0 ? textFieldStyle : undefined}
                  />
                ),
              }),
            },
          ]
        : []),
      ...(hasAddonDescriptorsOnPage
        ? [
            {
              key: DISH_FIELDS.ADDON_DESCRIPTORS,
              title: 'Addon descriptors',
              width: '150px',
              render: (_, dish) => (
                <ReadMore
                  maxHeight={170}
                  component={formatDescriptors(
                    dish[DISH_FIELDS.ADDON_DESCRIPTORS],
                  )}
                />
              ),
            },
          ]
        : []),
      ...(!disabled
        ? [
            {
              key: 'actions',
              width: '35px',
              render: (_text, dish) => (
                <DishTableActions
                  dish={dish}
                  contextToUse={DishEditableVariousTextRowContext}
                />
              ),
            },
          ]
        : []),
      {
        key: DISH_FIELDS.ID,
        title: 'Dish ID',
        width: '50px',
        render: (_, dish) => {
          const aiMessages = get(dish, 'aiSuggestions.miscAndChoice.messages')
          return {
            props: {
              className: dishFieldsUtils.computeDishCellHighlight(
                dish,
                'id',
                brandStatus,
              ),
            },
            children: isEmpty(aiMessages) ? (
              <Typography.Text>{dish.id}</Typography.Text>
            ) : (
              <Row gutter={[16]}>
                <Col span={6}>
                  <DishModalErrors errors={aiMessages} />
                </Col>
                <Col span={18}>
                  <Typography.Text>{dish.id}</Typography.Text>
                </Col>
              </Row>
            ),
          }
        },
      },
    ]
  }, [dishesHighlightWords, brandStatus, disabled, dishes])

  const components = useMemo(
    () => ({
      body: {
        row: DishEditableVariousTextRow,
      },
    }),
    [],
  )

  // By its nature the ant design table is not great to be used for editing as it will always perform re-renders
  // on the whole table if even a single cell is changed from the datasource
  // it might be a good ideea in the future when optimizations are paramount to investigate alternative table solutions
  // or construct from scratch
  // https://github.com/ant-design/ant-design/issues/23763
  return (
    <Table
      components={components}
      dataSource={dishes}
      rowKey="id"
      size="small"
      className="dishes-table"
      rowClassName="curation-row"
      scroll={{
        y: isNil(height)
          ? DISH_TABLE_SCROLL_VALUES.HEIGHT
          : height - DISH_TABLE_SCROLL_VALUES.HEIGHT,
      }}
      sticky
      columns={columns}
      pagination={false}
    />
  )
}

DishEditableVariousTextTable.propTypes = {
  disabled: PropTypes.bool,
  dishes: PropTypes.arrayOf(dishPropType),
  dishesHighlightWords: PropTypes.object,
  brandStatus: PropTypes.string.isRequired,
}

DishEditableVariousTextTable.defaultProps = {
  dishes: [],
  disabled: false,
  dishesHighlightWords: {},
}

export default DishEditableVariousTextTable
