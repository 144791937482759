import React, { memo, useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Checkbox, Spin, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { handleMutationError } from '../../../helpers'
import { stylePropType } from '../../../propTypes'

const MutationCheckbox = ({
  ids,
  checked,
  style,
  label,
  mutation,
  mutationAdditionalInfo,
  tooltip,
  disabled,
}) => {
  const [checkedValue, setCheckedValue] = useState(false)
  const [changeCheckedValue, { loading }] = useMutation(mutation)

  useEffect(() => {
    setCheckedValue(checked)
  }, [checked])

  const handleValidateClick = useCallback(
    async ({ target: { checked: newValidatedState } }) => {
      try {
        await changeCheckedValue({
          variables: {
            ids,
            value: newValidatedState,
            ...mutationAdditionalInfo,
          },
        })
        setCheckedValue(newValidatedState)
      } catch (error) {
        handleMutationError(error)
      }
    },
    [changeCheckedValue, ids, mutationAdditionalInfo],
  )

  if (loading) {
    return <Spin size="small" />
  }

  return (
    <Tooltip title={tooltip}>
      <Checkbox
        className="big-checkbox"
        style={style}
        checked={checkedValue}
        onChange={handleValidateClick}
        onClick={e => e.stopPropagation()}
        disabled={disabled}
      >
        {label}
      </Checkbox>
    </Tooltip>
  )
}

MutationCheckbox.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  checked: PropTypes.bool,
  style: stylePropType,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  mutation: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  mutationAdditionalInfo: PropTypes.object,
}

MutationCheckbox.defaultProps = {
  ids: undefined,
  checked: false,
  style: undefined,
  label: undefined,
  tooltip: undefined,
  disabled: false,
  mutationAdditionalInfo: {},
}

export default memo(MutationCheckbox)
