import { createAction, createPayloadAction } from '../../utils'

export const setActiveBrandName = createPayloadAction('SET_ACTIVE_BRAND_NAME')
export const setActiveLocationName = createPayloadAction(
  'SET_ACTIVE_LOCATION_NAME',
)
export const setActiveUserEmail = createPayloadAction('SET_ACTIVE_USER_EMAIL')
export const setActiveMenuItem = createPayloadAction('SET_ACTIVE_MENU_ITEM')

export const setAvailableReports = createPayloadAction(
  'SET_AVAILABLE_AI_REPORTS',
)

export const startAppLoading = createAction('APP_LOADING_START')
export const stopAppLoading = createAction('APP_LOADING_STOP')
