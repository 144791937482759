import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row } from 'antd'
import { includes } from 'lodash'

import { GridFormItem, Input, Select } from '../../../../core/components'

import { idNew, PERMISSIONS } from '../../../../common/constants'
import { getFilterOptions } from '../../../../common/helpers'
import {
  useUserHasBrandChangePermission,
  useUserHasPermissions,
} from '../../../../common/hooks'

import { BRAND_PROBLEM_REASON_OPTIONS, BRAND_TAGS } from '../../../constants'
import { brandPropType } from '../../../propTypes'
import { brandTransformer } from '../../../transformers'
import { DuplicateBrandButton } from '../../molecules'

const BrandTags = ({ brand }) => {
  const userCanDuplicate = useUserHasPermissions([PERMISSIONS.BRANDS.DUPLICATE])

  const userCanChangeTags = useUserHasPermissions([
    PERMISSIONS.BRANDS.UPDATE_TAGS,
  ])

  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const {
    id,
    tags,
    problemReason,
    problemComment,
    originalBrand,
    duplicatedAt,
  } = useMemo(() => brandTransformer.toBrandTags(brand), [brand])

  const { watch } = useFormContext()

  const tagsFormValue = watch('tags')

  const isDisabled = useMemo(
    () => !userHasBrandChangePermission && !userCanChangeTags && id !== idNew,
    [userHasBrandChangePermission, userCanChangeTags, id],
  )

  const isProblemEditingDisabled = useMemo(
    () =>
      !includes(tagsFormValue, BRAND_TAGS.IS_PROBLEMATIC.value) || isDisabled,
    [isDisabled, tagsFormValue],
  )

  const brandTagOptions = useMemo(
    () => getFilterOptions(BRAND_TAGS, 'label', 'value'),
    [],
  )

  const problemReasonOptions = useMemo(
    () => getFilterOptions(BRAND_PROBLEM_REASON_OPTIONS),
    [],
  )

  return (
    <Row gutter={16}>
      <Select
        span={8}
        labelAbove
        label="Tags"
        defaultValue={tags}
        allowClear
        mode="multiple"
        name="tags"
        options={brandTagOptions}
        placeholder="Select brand tags"
        disabled={isDisabled}
      />
      {id !== idNew && (
        <>
          <Select
            span={3}
            labelAbove
            label="Problem reason"
            placeholder="Select a problem reason"
            name="problemReason"
            defaultValue={problemReason}
            options={problemReasonOptions}
            disabled={isProblemEditingDisabled}
          />
          <Input
            span={5}
            labelAbove
            label="Problem comment"
            defaultValue={problemComment}
            name="problemComment"
            placeholder="Enter a problem comment"
            disabled={isProblemEditingDisabled}
          />
          <GridFormItem span={8} label="Duplicate">
            <DuplicateBrandButton
              status={brand.status}
              brandId={id}
              disabled={!userCanDuplicate}
              originalBrand={originalBrand}
              brandDuplicatedAt={duplicatedAt}
            />
          </GridFormItem>
        </>
      )}
    </Row>
  )
}

BrandTags.propTypes = {
  brand: brandPropType.isRequired,
}

export default BrandTags
