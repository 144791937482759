import { get, isEmpty, isNil, isUndefined, map, omit, pick } from 'lodash'
import omitDeep from 'omit-deep-lodash'

import { menuTitleTransformer } from '../../menuTitles/transformers'
import {
  ALLERGEN_LABEL_LEGENDS,
  CUISINE_TYPE,
  DIET_LABEL_LEGENDS,
  LANGUAGES,
  MENU_TITLE,
} from '../constants'

export const transformToSaveBrandArgs = (formValues, aiSuggestions) => {
  let transformedBrand = pick(
    {
      ...formValues,
      servicingHours: omitDeep(
        map(
          formValues.servicingHours,
          ({ id: servicingHourId, created, ...rest }) =>
            created ? { ...rest } : { id: servicingHourId, ...rest },
        ),
        '__typename',
      ),
      dietLabelLegends: omitDeep(
        map(
          formValues.dietLabelLegends,
          ({ id: dietLabelLegendId, created, ...rest }) =>
            pick(created ? { ...rest } : { id: dietLabelLegendId, ...rest }, [
              'id',
              'dietId',
              'highlights',
              'isSuggestedByAi',
              'label',
              'deleted',
              'highlights',
              'conflictResolutionType',
              'isChoice',
            ]),
        ),
        '__typename',
      ),
      allergenLabelLegends: omitDeep(
        map(
          formValues.allergenLabelLegends,
          ({ id: allergenLabelLegendId, created, ...rest }) =>
            pick(
              created ? { ...rest } : { id: allergenLabelLegendId, ...rest },
              [
                'id',
                'allergenId',
                'highlights',
                'isSuggestedByAi',
                'label',
                'deleted',
                'highlights',
                'conflictResolutionType',
                'isChoice',
              ],
            ),
        ),
        '__typename',
      ),
      cuisineTypeId: isUndefined(formValues.cuisineTypeId)
        ? undefined
        : formValues.cuisineTypeId,
      locationTypeId: isUndefined(formValues.locationTypeId)
        ? undefined
        : formValues.locationTypeId,
    },
    [
      'id',
      'incomplete',
      'name',
      'web',
      'locationTypeId',
      'cuisineTypeId',
      'menuSource',
      'menuLink',
      'languages',
      'servicingHours',
      'dietLabelLegends',
      'allergenLabelLegends',
      'diets',
      'tags',
      'problemReason',
      'problemComment',
      'qaDetailLevel',
    ],
  )

  if (!isNil(transformedBrand.languages)) {
    transformedBrand.languages = map(
      transformedBrand.languages,
      (language, index) => ({
        languageId: language.id,
        isSuggestedByAi: language.isSuggestedByAi,
        position: index,
      }),
    )
  }

  if (isEmpty(transformedBrand.dietLabelLegends)) {
    transformedBrand = omit(transformedBrand, 'dietLabelLegends')
  }

  if (isEmpty(transformedBrand.allergenLabelLegends)) {
    transformedBrand = omit(transformedBrand, 'allergenLabelLegends')
  }

  transformedBrand = formValues?.id
    ? transformedBrand
    : omit(transformedBrand, 'id')

  transformedBrand = formValues.menuTitles
    ? {
        ...transformedBrand,
        menuTitles: map(formValues.menuTitles, menuTitleRoot =>
          menuTitleTransformer.transformMenuTitleToDto(menuTitleRoot),
        ),
      }
    : { ...transformedBrand }

  try {
    transformedBrand = !isNil(aiSuggestions)
      ? {
          ...transformedBrand,
          aiSuggestions: {
            menuTitles: map(
              get(aiSuggestions, `${MENU_TITLE}.predictions`),
              suggestedMenuTitle => ({
                ...omit(suggestedMenuTitle, ['path', 'parents', 'status']),
                name: suggestedMenuTitle.name
                  .getCurrentContent()
                  .getPlainText(),
                score: suggestedMenuTitle.score,
                certainty: suggestedMenuTitle.certainty,
                segments: suggestedMenuTitle.segments,
              }),
            ),
            dietLabelLegends: map(
              get(aiSuggestions, DIET_LABEL_LEGENDS),
              ({
                id: dietId,
                label,
                occurrences,
                isSuggestedFromConflicting,
                isChoice,
              }) => ({
                label,
                isSuggestedFromConflicting,
                dietId: dietId.toString(),
                occurrences,
                isChoice,
              }),
            ),
            allergenLabelLegends: map(
              get(aiSuggestions, ALLERGEN_LABEL_LEGENDS),
              ({
                id: allergenId,
                label,
                isSuggestedFromConflicting,
                occurrences,
                isChoice,
              }) => ({
                label,
                isSuggestedFromConflicting,
                allergenId: allergenId.toString(),
                occurrences,
                isChoice,
              }),
            ),
            cuisineType: map(
              get(aiSuggestions, CUISINE_TYPE),
              ({ id: cuisineTypeId, score, certainty, name }) => ({
                name,
                cuisineTypeId,
                score,
                certainty,
              }),
            ),
            languages: map(
              get(aiSuggestions, LANGUAGES),
              ({ lang, score, certainty, langCode, text }) => ({
                lang,
                score,
                certainty,
                langCode,
                text,
              }),
            ),
          },
        }
      : { ...transformedBrand }
  } catch (error) {
    // seeing as we are using persistance in localstorage for suggestions, draft js is getting serialized badly and we lose functions from it
    // for the moment instead of crashing the page after a refresh we show this error
    throw new Error(
      'Something went wrong after you refreshed the page with changes, please try to discard the changes and try again',
    )
  }

  return transformedBrand
}

export const transformBrandFromDto = brand =>
  omit(
    {
      ...brand,
      diets: !isNil(brand.diets) ? map(brand.diets, 'id') : undefined,
      servicingHours: omitDeep(brand.servicingHours, '__typename'),
      dietLabelLegends: omitDeep(brand.dietLabelLegends, '__typename'),
      allergenLabelLegends: omitDeep(brand.allergenLabelLegends, '__typename'),
    },
    ['menuTitles', '__typename'],
  )

export const toBrandCuration = brand =>
  pick(brand, ['name', 'web', 'locationType', 'cuisineType', 'aiSuggestions'])

export const toBrandTags = brand =>
  pick(brand, [
    'id',
    'tags',
    'originalBrand',
    'duplicatedAt',
    'problemReason',
    'problemComment',
  ])

export const toBrandMenuInfoDietsAndLanguages = brand =>
  pick(brand, ['id', 'menuSource', 'menuLink', 'diets', 'languages'])

export const toBrandModificationDates = brand =>
  pick(brand, [
    'id',
    'name',
    'status',
    'updatedBy',
    'updatedAt',
    'lastImportedBy',
    'lastImportedAt',
    'latestMenuInfo',
    'curatedAt',
    'qadAt',
    'qqadAt',
    'curatedBy',
    'qadBy',
    'qqadBy',
  ])

export const toBrandAssignation = brand =>
  pick(brand, [
    'id',
    'status',
    'assignedUnknownWordsCurator',
    'assignedUnknownWordsQa',
    'assignedCurator',
    'assignedQa',
    'assignedQQa',
    'requiresQaAttention',
    'requiresCuratorAttention',
    'qaDetailLevel',
  ])

export const toBrandLabelLegends = brand =>
  pick(brand, ['dietLabelLegends', 'allergenLabelLegends', 'status'])
