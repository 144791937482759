import React from 'react'
import { notification, Typography } from 'antd'
import {
  filter,
  find,
  get,
  groupBy,
  isEmpty,
  isNil,
  map,
  reverse,
  sortBy,
} from 'lodash'

import {
  CURATION_STATUS,
  EVENT_TYPES,
  suggestionEndpoints,
} from '../../common/constants'
import { googleAnalyticsEventsService } from '../../common/services'
import { getAiSuggestions } from '../../common/utils'

import { DISH_TYPE } from '../constants'
import { dishDishTypeSuggestionTransformer } from '../transformers'

/**
 * Calls the Ai servicing for suggesting dish types.
 * It should:
 *   - fetch suggestions
 *
 * Returns a promise.
 */
export default async function suggestDishType({ dish, additionalInformation }) {
  try {
    // this endpoint gives us suggestions for multiple dishes, that's why we destruct here
    const [suggestions] = await getAiSuggestions(
      suggestionEndpoints.dishTypes,
      [
        dishDishTypeSuggestionTransformer.transformDishToAiRequestDto(
          dish,
          additionalInformation,
        ),
      ],
      dishDishTypeSuggestionTransformer.transformDishFromAiResponseDto,
    )

    const aiMessages = get(suggestions, 'messages')
    if (!isEmpty(aiMessages)) {
      notification.error({
        message: 'An error occured',
        description: map(aiMessages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })
      return null
    }

    if (isEmpty(suggestions)) {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.NO_SUGGESTIONS,
        { for: DISH_TYPE },
      )
      notification.warning({
        message: 'No suggestions.',
        description: 'The Ai could not suggest any dish types for this dish',
        placement: 'topLeft',
      })

      return null
    }

    const highestScoreFromSuggestions = Math.max(...map(suggestions, 'score'))

    let dishTypeId = get(
      find(
        suggestions,
        suggestion => suggestion.score === highestScoreFromSuggestions,
      ),
      'id',
    )

    const {
      brand: { status },
      dishTypeOptions,
    } = additionalInformation

    if (
      status === CURATION_STATUS.CURATION_CONFIRMATION.value &&
      !isEmpty(dishTypeOptions)
    ) {
      const groupedIgnoredDishTypes = groupBy(
        filter(dishTypeOptions, 'isIgnored'),
        'id',
      )
      const suggestionsSortedByScoreDescending = reverse(
        sortBy(suggestions, 'score'),
      )

      dishTypeId =
        get(
          find(suggestionsSortedByScoreDescending, suggestion =>
            isEmpty(get(groupedIgnoredDishTypes, suggestion.id)),
          ),
          'id',
        ) || null

      if (isNil(dishTypeId)) {
        throw new Error('Suggested dish types are all ignored')
      }
    }

    return {
      aiSuggestions: suggestions,
      dishTypeId,
    }
  } catch (e) {
    notification.error({
      message: 'An error occured while suggesting dish type',
      description: e.message,
      placement: 'topLeft',
    })
    return null
  }
}
