import { useCallback, useMemo } from 'react'
import { find, get, includes } from 'lodash'

import { CURATION_STATUS } from '../../common/constants'
import { useAuthentication } from '../../common/hooks'

import { DISH_FIELDS } from '../constants'
import { dishFieldsUtils } from '../utils'

export default function useDishFields(
  status,
  dishIsStatusSameAsBrandFilter,
  dishHasErrorsFilter,
  brandDishes,
  disabled = false,
) {
  const { userInfo } = useAuthentication()

  const dishFields = useMemo(
    () =>
      dishFieldsUtils.getDishFieldsBasedOnUserPermission(
        userInfo.permissions,
        status,
        dishIsStatusSameAsBrandFilter,
        dishHasErrorsFilter,
        brandDishes,
      ),
    [
      userInfo.permissions,
      status,
      dishIsStatusSameAsBrandFilter,
      dishHasErrorsFilter,
      brandDishes,
    ],
  )

  const shouldDisableField = useCallback(
    fieldId => {
      if (disabled) {
        return true
      }

      if (includes([DISH_FIELDS.DISH_TYPE, DISH_FIELDS.COURSE_TYPE], fieldId)) {
        return !get(
          find(dishFields, ({ id }) => fieldId === id),
          'isEditable',
        )
      }

      return includes(
        [
          CURATION_STATUS.SANITY_CHECK.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
        ],
        status,
      )
    },
    [disabled, status, dishFields],
  )

  return { dishFields, shouldDisableField }
}
