import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  TreeSelect,
} from 'antd'
import {
  filter,
  find,
  forEach,
  get,
  includes,
  isArray,
  isEmpty,
  isNil,
  map,
  omit,
  some,
  toLower,
} from 'lodash'
import moment from 'moment'
import { PropTypes } from 'prop-types'

import { selectFilterOption } from '../../../core/utils'

import { REPORT_PARAM_TYPES } from '../../constants'
import {
  getAllIdsFromTreeLevel,
  getAllIdsFromTreeToLevel,
  getSelectInputOptions,
  isSelectDataTree,
} from '../../helpers'

const styles = {
  input: {
    width: '100%',
  },
  createBtn: {
    marginTop: 5,
  },
  reportInputRow: {
    marginBottom: 10,
  },
}

const ReportsInputs = ({
  reportName,
  reportConfig,
  curationMetadata,
  onCreate,
}) => {
  const [paramValues, setParamValues] = useState({})

  const availableParams = useMemo(
    () =>
      filter(reportConfig.parameters, ({ name }) => {
        if (
          (name === 'start_date' || name === 'end_date') &&
          get(paramValues, `${reportName}.omit_timeframe`)
        ) {
          return false
        }

        return true
      }),
    [reportConfig, paramValues, reportName],
  )

  useEffect(() => {
    if (!isEmpty(availableParams)) {
      forEach(availableParams, ({ name, defaultValue }) => {
        if (
          !isNil(defaultValue) &&
          isNil(get(paramValues, `${reportName}.${name}`))
        ) {
          setParamValues({
            ...paramValues,
            [reportName]: {
              ...(paramValues[reportName] || {}),
              [name]: defaultValue,
            },
          })
        }
      })
    }
  }, [paramValues, availableParams, reportName])

  const isCreateDisabled = useMemo(
    () =>
      some(availableParams, param => {
        if (param.type === REPORT_PARAM_TYPES.BOOLEAN) {
          return false
        }

        const value = get(paramValues, `${reportName}.${param.name}`)

        if (!isEmpty(param.relatedWith) && isEmpty(value)) {
          return !some(
            param.relatedWith,
            paramName =>
              !isEmpty(get(paramValues, `${reportName}.${paramName}`)),
          )
        }

        return param.type === REPORT_PARAM_TYPES.INT
          ? isNil(value)
          : isEmpty(value)
      }),
    [availableParams, paramValues, reportName],
  )

  const handleValuesChange = useCallback(
    (field, value, dataForAll) => {
      const values = paramValues[reportName] || {}

      if (isArray(value) && includes(value, 'all') && !isEmpty(dataForAll)) {
        return setParamValues({
          ...paramValues,
          [reportName]: {
            ...values,
            [field]: map(dataForAll, 'value'),
          },
        })
      }

      if (
        isArray(value) &&
        some(value, currentValue => includes(currentValue, 'select_all_level:'))
      ) {
        const selectAllValue =
          find(value, currentValue =>
            includes(currentValue, 'select_all_level:'),
          ) || ''
        const level = Number(selectAllValue.split(':')[1] || 0)

        return setParamValues({
          ...paramValues,
          [reportName]: {
            ...values,
            [field]: getAllIdsFromTreeLevel(dataForAll, 1, level),
          },
        })
      }

      if (isArray(value) && includes(value, 'select_all')) {
        return setParamValues({
          ...paramValues,
          [reportName]: {
            ...values,
            [field]: getAllIdsFromTreeToLevel(dataForAll, 0, 3),
          },
        })
      }

      return setParamValues({
        ...paramValues,
        [reportName]: {
          ...values,
          [field]: value,
        },
      })
    },
    [reportName, setParamValues, paramValues],
  )

  const handleCreate = useCallback(async () => {
    const timeframeOmit = get(paramValues, `${reportName}.omit_timeframe`)

    if (timeframeOmit) {
      await onCreate(omit(paramValues[reportName], ['start_date', 'end_date']))
    } else {
      await onCreate(paramValues[reportName])
    }

    setParamValues({ ...paramValues, [reportName]: {} })
  }, [paramValues, reportName, onCreate])

  const reportInputs = useMemo(() => {
    if (isEmpty(reportConfig)) {
      return []
    }

    return map(availableParams, param => {
      let input
      let selectData
      if (
        includes(
          [
            REPORT_PARAM_TYPES.MULTI_SELECT,
            REPORT_PARAM_TYPES.SINGLE_SELECT,
            REPORT_PARAM_TYPES.CASCADER_SELECT,
          ],
          param.type,
        )
      ) {
        if (param.valueType) {
          selectData = get(curationMetadata, param.valueType) || []
        } else if (!isEmpty(param.values)) {
          selectData = param.values
        } else {
          selectData = []
        }
      }

      switch (param.type) {
        case REPORT_PARAM_TYPES.DATE:
          input = (
            <DatePicker
              style={styles.input}
              allowClear={false}
              format="YYYY-MM-DD HH:mm"
              showTime={{
                defaultValue: moment('00:00', 'HH:mm'),
                format: 'HH:mm',
              }}
              showToday={false}
              value={get(paramValues, `${reportName}.${param.name}`)}
              onChange={(date, dateString) =>
                handleValuesChange(param.name, moment.utc(dateString))
              }
              placeholder={param.displayName}
            />
          )
          break
        case REPORT_PARAM_TYPES.INT:
          input = (
            <InputNumber
              style={styles.input}
              allowClear={false}
              value={get(paramValues, `${reportName}.${param.name}`)}
              onChange={value => handleValuesChange(param.name, value)}
              placeholder={param.displayName}
            />
          )
          break
        case REPORT_PARAM_TYPES.CASCADER_SELECT:
          input = (
            <Cascader
              style={styles.input}
              showSearch={{
                filter: (filterInput, options) =>
                  some(
                    options,
                    option =>
                      toLower(option?.label)?.indexOf(toLower(filterInput)) >=
                      0,
                  ),
              }}
              value={get(paramValues, `${reportName}.${param.name}`)}
              placeholder={param.displayName}
              allowClear
              onChange={value => handleValuesChange(param.name, value)}
              options={isEmpty(selectData) ? [] : selectData}
            />
          )
          break
        case REPORT_PARAM_TYPES.MULTI_SELECT:
          if (isSelectDataTree(selectData)) {
            input = (
              <TreeSelect
                style={styles.input}
                showSearch
                treeNodeFilterProp="title"
                value={get(paramValues, `${reportName}.${param.name}`)}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={param.displayName}
                treeData={isEmpty(selectData) ? [] : selectData}
                maxTagCount={5}
                allowClear
                multiple
                onChange={value =>
                  handleValuesChange(param.name, value, selectData)
                }
              />
            )
          } else {
            input = (
              <Select
                style={styles.input}
                showSearch
                value={get(paramValues, `${reportName}.${param.name}`)}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={param.displayName}
                allowClear
                filterOption={selectFilterOption}
                maxTagCount={5}
                mode="multiple"
                onChange={value =>
                  handleValuesChange(param.name, value, selectData)
                }
                options={[
                  {
                    label: 'All',
                    value: 'all',
                  },
                  ...getSelectInputOptions(selectData, 'title', 'value'),
                ]}
              />
            )
          }

          break
        case REPORT_PARAM_TYPES.SINGLE_SELECT:
          if (isSelectDataTree(selectData)) {
            input = (
              <TreeSelect
                style={styles.input}
                showSearch
                treeNodeFilterProp="title"
                value={get(paramValues, `${reportName}.${param.name}`)}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={param.displayName}
                treeData={isEmpty(selectData) ? [] : selectData}
                allowClear
                onChange={value => handleValuesChange(param.name, value)}
              />
            )
          } else {
            input = (
              <Select
                style={styles.input}
                showSearch
                value={get(paramValues, `${reportName}.${param.name}`)}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={param.displayName}
                allowClear
                maxTagCount={5}
                filterOption={selectFilterOption}
                onChange={value => handleValuesChange(param.name, value)}
                options={getSelectInputOptions(selectData, 'title', 'value')}
              />
            )
          }
          break
        case REPORT_PARAM_TYPES.BOOLEAN:
          input = (
            <Checkbox
              checked={get(paramValues, `${reportName}.${param.name}`)}
              onChange={e => handleValuesChange(param.name, e.target.checked)}
            >
              {param.displayName}
            </Checkbox>
          )
          break
        default:
          input = (
            <Input
              allowClear={false}
              value={get(paramValues, `${reportName}.${param.name}`)}
              onChange={event =>
                handleValuesChange(param.name, event.target.value)
              }
              placeholder={param.displayName}
            />
          )
          break
      }

      return (
        <Row key={param.name} style={styles.reportInputRow}>
          <Col span={12}>{input}</Col>
        </Row>
      )
    })
  }, [
    availableParams,
    handleValuesChange,
    reportConfig,
    paramValues,
    reportName,
    curationMetadata,
  ])

  return (
    <>
      {reportInputs}
      <Row>
        <Col span={4}>
          <Button
            disabled={isCreateDisabled}
            style={styles.createBtn}
            onClick={handleCreate}
          >
            Create Report
          </Button>
        </Col>
      </Row>
    </>
  )
}

ReportsInputs.propTypes = {
  reportName: PropTypes.string.isRequired,
  reportConfig: PropTypes.object,
  curationMetadata: PropTypes.object,
  onCreate: PropTypes.func.isRequired,
}

ReportsInputs.defaultProps = {
  reportConfig: undefined,
  curationMetadata: {},
}

export default ReportsInputs
