import { gql } from '@apollo/client'

import { BRAND_SUGGESTED_WORD_FRAGMENT } from '../fragments'

const CONFIRM_BRAND_SUGGESTED_WORD_MUTATION = gql`
  mutation confirmBrandSuggestedWordMutation(
    $id: ID!
    $isConfirmed: Boolean!
    $isQa: Boolean!
    $data: BrandSuggestedWordInput!
  ) {
    confirmBrandSuggestedWord(
      id: $id
      isConfirmed: $isConfirmed
      isQa: $isQa
      data: $data
    ) {
      ...BrandSuggestedWordFragment
    }
  }
  ${BRAND_SUGGESTED_WORD_FRAGMENT}
`

export default CONFIRM_BRAND_SUGGESTED_WORD_MUTATION
