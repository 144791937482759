import { gql } from '@apollo/client'

import { SIMPLE_ENTITY_FRAGMENT } from '../../../common/graphql'

import { BRAND_MENU_TITLE_FRAGMENT } from '../../../menuTitles/graphql'

const BRAND_ENTRY_FRAGMENT = gql`
  fragment BrandEntryFragment on Brand {
    ...SimpleEntityFragment
    status
    web
    menuSource
    menuLink
    locationType {
      ...SimpleEntityFragment
    }
    cuisineType {
      ...SimpleEntityFragment
      isErrorProne
    }
    menuTitles {
      ...BrandMenuTitleFragment
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
  ${BRAND_MENU_TITLE_FRAGMENT}
`

export default BRAND_ENTRY_FRAGMENT
