import { createContext } from 'react'

const defaultState = {
  dishTypeOptions: [],
  courseTypeOptions: [],
  locationTypeOptions: [],
  cuisineTypeOptions: [],
  dietOptions: [],
  ingredientOptions: [],
  languageOptions: [],
}

export default createContext(defaultState)
