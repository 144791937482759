import { filter, flatten, get, includes, keys, map, toLower } from 'lodash'

const getHighlightListFromArrayForDescriptor = (
  highlightWords,
  descriptorName,
) =>
  flatten(
    map(
      filter(keys(highlightWords), originField =>
        includes(toLower(originField), toLower(descriptorName)),
      ),
      descriptorOriginField =>
        map(get(highlightWords, descriptorOriginField), highlight => ({
          ...highlight,
          // the startIndex is not really needed for descriptors as the text is formatted differently on ui
          // than the ai service uses to identify the words
          startIndex: null,
        })),
    ),
  )

export default getHighlightListFromArrayForDescriptor
