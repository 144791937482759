import React, { memo } from 'react'
import { Typography } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import {
  AI_INGREDIENT_MATCH_TYPE,
  AI_SUGGESTIONS_HIGHLIGHT_COLORS,
} from '../../../../common/constants'

const styles = {
  [AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE]: { color: '#1890ff' },
  [AI_SUGGESTIONS_HIGHLIGHT_COLORS.ORANGE]: { color: '#ff9f00' },

  [AI_INGREDIENT_MATCH_TYPE.EXACT]: {
    backgroundColor: 'rgba(0, 255, 0, .5)',
  },

  [AI_INGREDIENT_MATCH_TYPE.NEAR]: {
    backgroundColor: 'rgba(0, 255, 255, .8)',
  },

  [AI_INGREDIENT_MATCH_TYPE.SYNONYM]: {
    backgroundColor: 'rgba(195, 0, 255, .2)',
  },

  [AI_INGREDIENT_MATCH_TYPE.SYNONYM_LEVEL_1]: {
    backgroundColor: 'rgba(195, 0, 255, .2)',
  },

  [AI_INGREDIENT_MATCH_TYPE.SYNONYM_LEVEL_2]: {
    backgroundColor: 'rgba(195, 0, 255, .4)',
  },

  [AI_INGREDIENT_MATCH_TYPE.SYNONYM_LEVEL_3]: {
    backgroundColor: '#DB65FF',
  },

  [AI_INGREDIENT_MATCH_TYPE.VARIETY]: {
    backgroundColor: 'rgba(195, 0, 255, .2)',
  },

  [AI_INGREDIENT_MATCH_TYPE.VARIETY_LEVEL_1]: {
    backgroundColor: 'rgba(195, 0, 255, .2)',
  },

  [AI_INGREDIENT_MATCH_TYPE.VARIETY_LEVEL_2]: {
    backgroundColor: 'rgba(195, 0, 255, .4)',
  },

  [AI_INGREDIENT_MATCH_TYPE.VARIETY_LEVEL_3]: {
    backgroundColor: '#DB65FF',
  },

  [AI_INGREDIENT_MATCH_TYPE.ALREADY_FOUND]: {
    backgroundColor: 'rgba(211, 211, 211, .8)',
  },

  [AI_INGREDIENT_MATCH_TYPE.NEGATIVE_SYNONYM]: {
    textDecoration: 'line-through',
  },

  [AI_INGREDIENT_MATCH_TYPE.COMMON_NEGATIVE_SYNONYM]: {
    textDecoration: 'line-through',
  },

  [AI_INGREDIENT_MATCH_TYPE.ML_SUGGESTED]: {
    backgroundColor: '#ffdb40',
  },

  [AI_INGREDIENT_MATCH_TYPE.OVERRIDES]: {
    border: '1px solid black',
  },

  [`${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}Start`]: {
    border: '1px dashed black',
    borderRight: 'none',
  },

  [`${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}End`]: {
    border: '1px dashed black',
    borderLeft: 'none',
  },

  [AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE]: {
    border: '1px dashed black',
  },

  [AI_INGREDIENT_MATCH_TYPE.MIDDLE_PAIRING_PHRASE]: {
    border: '1px dashed black',
    borderRight: 'none',
    borderLeft: 'none',
  },

  [AI_INGREDIENT_MATCH_TYPE.MIDDLE_REFERENCING_WORD]: {
    border: '1px dashed black',
  },

  [`${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}Start`]: {
    border: '1px dashed black',
    borderRight: 'none',
  },

  [`${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}End`]: {
    border: '1px dashed black',
    borderLeft: 'none',
  },

  [AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD]: {
    border: '1px dashed black',
  },

  alternativeTypeMatch: { backgroundColor: '#ADA9FC' },

  [AI_INGREDIENT_MATCH_TYPE.REPLACEMENT]: {
    backgroundColor: '#D6A2BC',
  },

  strikethroughIngredient: {
    textDecoration: 'line-through',
  },
}

const DishAiHighlightingFieldValue = ({
  children,
  highlight,
  useTrailingSeparator,
  strikethroughIngredient,
  separator,
}) => (
  <>
    <Typography.Text
      style={{
        ...(get(styles, highlight) || {}),
        ...(strikethroughIngredient && styles.strikethroughIngredient),
      }}
    >
      {children}
    </Typography.Text>
    {useTrailingSeparator && separator}
  </>
)

DishAiHighlightingFieldValue.propTypes = {
  children: PropTypes.node,
  highlight: PropTypes.string,
  useTrailingSeparator: PropTypes.bool,
  strikethroughIngredient: PropTypes.bool,
  separator: PropTypes.string,
}

DishAiHighlightingFieldValue.defaultProps = {
  children: null,
  highlight: '',
  useTrailingSeparator: false,
  strikethroughIngredient: false,
  separator: ', ',
}

export default memo(DishAiHighlightingFieldValue)
