import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { CheckOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Spin, TreeSelect } from 'antd'
import { isNil, noop, size } from 'lodash'
import PropTypes from 'prop-types'

import { HOTKEYS_ENABLED_TAGS } from '../../../../common/constants'
import {
  handleMutationError,
  handleTreeSelectCustomDropdownForSearch,
} from '../../../../common/helpers'
import { stylePropType } from '../../../../common/propTypes'

import { AVAILABLE_HOTKEYS } from '../../../constants'
import { CleanupTableFocusActionsContext } from '../../../contexts'
import { cleanupTasksMetadataTypePropType } from '../../../propTypes'

const CleanupTreeSelect = ({
  multiple,
  ids,
  defaultValue,
  style,
  mutation,
  metadataType,
  fieldName,
  treeData,
  disabled,
  onComplete,
  isBatchSelect,
  isTaskCurationDone,
  showSubmitButton,
}) => {
  const {
    selectedRowKey,
    hotkeyEditMode,
    isBatchEditModeActive,
    setIsBatchEditModeActive,
    setHotkeyEditMode,
  } = useContext(CleanupTableFocusActionsContext)
  const [selectedValue, setSelectedValue] = useState(() =>
    multiple && isNil(defaultValue) ? [] : defaultValue,
  )

  const [change, { loading }] = useMutation(mutation, {
    onCompleted: onComplete,
    onError: handleMutationError,
  })
  const selectRef = useRef(null)

  const isCurrentRowSelected = useMemo(
    () =>
      !isBatchEditModeActive && size(ids) === 1 && ids[0] === selectedRowKey,
    [ids, selectedRowKey, isBatchEditModeActive],
  )

  useEffect(() => {
    setSelectedValue(multiple && isNil(defaultValue) ? [] : defaultValue)
  }, [defaultValue, multiple])

  const handleChange = useCallback(newValues => {
    setSelectedValue(newValues)
  }, [])

  const handleFocusSelect = useCallback(() => {
    selectRef.current.focus()
    setHotkeyEditMode(true)
  }, [setHotkeyEditMode])

  const handleFocusBlur = useCallback(async () => {
    await change({
      variables: {
        ids,
        curationValues: {
          [fieldName]: selectedValue,
        },
        isQaAction: isTaskCurationDone,
        metadataType,
      },
    })
    selectRef.current.blur()
    setHotkeyEditMode(false)
    setIsBatchEditModeActive(false)
  }, [
    change,
    fieldName,
    ids,
    isTaskCurationDone,
    metadataType,
    selectedValue,
    setHotkeyEditMode,
    setIsBatchEditModeActive,
  ])

  // Hotkeys for edit multiple
  useHotkeys(
    AVAILABLE_HOTKEYS.ENTER.hotkey,
    handleFocusSelect,
    {
      enabled: !hotkeyEditMode && isCurrentRowSelected && !disabled,
    },
    [
      hotkeyEditMode,
      isCurrentRowSelected,
      isBatchSelect,
      disabled,
      handleFocusSelect,
    ],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.ESC.hotkey,
    handleFocusBlur,
    {
      enabled: isCurrentRowSelected || (isBatchSelect && isBatchEditModeActive),
      enableOnTags: HOTKEYS_ENABLED_TAGS,
    },
    [
      isBatchEditModeActive,
      isCurrentRowSelected,
      isBatchSelect,
      handleFocusBlur,
    ],
  )

  useHotkeys(
    [AVAILABLE_HOTKEYS.CMD_R.hotkey, AVAILABLE_HOTKEYS.CTRL_R.hotkey].join(','),
    event => {
      event.preventDefault()
      handleFocusSelect()
      setIsBatchEditModeActive(true)
    },
    {
      enabled: !hotkeyEditMode && isBatchSelect && !disabled,
      enableOnTags: HOTKEYS_ENABLED_TAGS,
    },
    [
      hotkeyEditMode,
      isCurrentRowSelected,
      isBatchSelect,
      setIsBatchEditModeActive,
      disabled,
      handleFocusSelect,
    ],
  )

  if (loading) {
    return <Spin size="small" />
  }

  return (
    <>
      <TreeSelect
        style={style}
        ref={selectRef}
        value={selectedValue}
        onChange={handleChange}
        treeData={treeData}
        disabled={disabled}
        treeNodeFilterProp="title"
        dropdownRender={handleTreeSelectCustomDropdownForSearch}
        showSearch
        multiple={multiple}
        maxTagCount={multiple ? 'responsive' : undefined}
        onBlur={!showSubmitButton && handleFocusBlur}
      />
      {showSubmitButton && (
        <Button onClick={handleFocusBlur} icon={<CheckOutlined />} />
      )}
    </>
  )
}

CleanupTreeSelect.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  style: stylePropType,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  fieldName: PropTypes.string.isRequired,
  mutation: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  treeData: PropTypes.object,
  onComplete: PropTypes.func,
  isBatchSelect: PropTypes.bool,
  multiple: PropTypes.bool,
  isTaskCurationDone: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
}

CleanupTreeSelect.defaultProps = {
  multiple: false,
  defaultValue: undefined,
  style: undefined,
  disabled: false,
  isBatchSelect: false,
  isTaskCurationDone: false,
  treeData: undefined,
  onComplete: noop,
  showSubmitButton: false,
}

export default memo(CleanupTreeSelect)
