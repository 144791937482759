import { map } from 'lodash'

export const transformBrandToAiRequestDto = brandId => ({
  brandId,
})

export const transformDishFromAiResponseDto = responseBody =>
  map(responseBody, ({ result, messages }) => ({
    messages,
    result: map(result, ({ lang, score, certainty, langCode, text }) => ({
      lang,
      // Remove all decimals except 2
      score: parseFloat((score * 100).toFixed(2), 10),
      certainty,
      langCode,
      text,
    })),
  }))
