import { createSelector } from 'reselect'

const getLocationDish = ({ unsaved: { locationDishes } }, { id, locationId }) =>
  locationDishes.find(d => d.id === id && d.locationId === locationId)

const createMakeMapStateToDishProp = getDishFunc => (propName, newPropName) =>
  createSelector(getDishFunc, dish => {
    const { [propName]: result } = dish || {}
    return { [newPropName]: result }
  })

export const makeMapStateToLocationDishProp =
  createMakeMapStateToDishProp(getLocationDish)
