const MENU_ENTRY = Object.freeze({
  BRANDS_AND_LOCATIONS: 'Brands And Locations',
  BRAND: 'Brand',
  LOCATION: 'Location',
  USERS: 'Users',
  USER: 'User',
  REPORTS: 'Reports',
  CLEANUP_TASKS: 'Cleanup tasks',
  CREATE_CLEANUP_REVIEW_TASK: 'Create review task',
  CREATE_CLEANUP_ML_TASK: 'Create ML task',
  VIEW_CLEANUP_TASKS: 'View cleanup tasks',
  AI_SETTINGS: 'Ai settings',
  INGREDIENTS_RERUN: 'Ingredients rerun',
})

export default MENU_ENTRY
