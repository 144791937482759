import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row } from 'antd'
import { isNil, keys, map } from 'lodash'
import moment from 'moment'

import { DatePicker, Select, TreeSelect } from '../../../../core/components'

import { METADATA_TYPES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

const AVAILABLE_METADATA_TYPES = {
  [METADATA_TYPES.DISH_TYPES]: 'Dish type',
  [METADATA_TYPES.COURSE_TYPES]: 'Course type',
}

const styles = {
  select: {
    margin: '0.25rem 0',
    width: '100%',
  },
}

const MlTaskForm = () => {
  const { dishTypeOptions, courseTypeOptions } = useCurationMetadata()

  const { watch } = useFormContext()
  const selectedMetadataType = watch('metadataType')

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
    }),
    [dishTypeOptions, courseTypeOptions],
  )

  return (
    <>
      <Row>
        <Select
          style={styles.select}
          span={6}
          placeholder="Metadata type"
          name="metadataType"
          allowClear={false}
          showSearch={false}
          options={map(keys(AVAILABLE_METADATA_TYPES), metadataType => ({
            label: AVAILABLE_METADATA_TYPES[metadataType],
            value: metadataType,
          }))}
        />
      </Row>
      <Row>
        <TreeSelect
          style={styles.select}
          span={6}
          disabled={isNil(selectedMetadataType)}
          placeholder={AVAILABLE_METADATA_TYPES[selectedMetadataType]}
          allowClear={false}
          name="metadataIds"
          treeData={curationMetadata[selectedMetadataType]}
          multi
        />
      </Row>
      <Row>
        <DatePicker
          span={6}
          style={styles.select}
          format="YYYY-MM-DD HH:mm"
          showTime={{
            defaultValue: moment('00:00', 'HH:mm'),
            format: 'HH:mm',
          }}
          placeholder="Start date"
          name="startDate"
          convertFunction={moment.utc}
        />
      </Row>
      <Row>
        <DatePicker
          span={6}
          style={styles.select}
          format="YYYY-MM-DD HH:mm"
          showTime={{
            defaultValue: moment('00:00', 'HH:mm'),
            format: 'HH:mm',
          }}
          placeholder="End date"
          name="endDate"
          convertFunction={moment.utc}
        />
      </Row>
    </>
  )
}

export default MlTaskForm
