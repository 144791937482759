const DEFAULT_DISH_LIST_FILTERS = Object.freeze({
  IS_STATUS_SAME_AS_BRAND: true,
  SERVICING_HOURS: null,
  MENU_TITLE: null,
  SORT_DISHES_BY: 'Id',
  HAS_ERRORS: false,
  IGNORED: null,
  PUBLISHED: null,
  SEARCH: '',
})

export default DEFAULT_DISH_LIST_FILTERS
