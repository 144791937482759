import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, notification, Popconfirm, Typography } from 'antd'
import update from 'immutability-helper'
import { findIndex } from 'lodash'

import { APP_ROUTES, EVENT_TYPES } from '../../../../common/constants'
import { useAppLoading, useAuthentication } from '../../../../common/hooks'
import { userInfoPropType } from '../../../../common/propTypes'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DELETE_USER_MUTATION, USERS_QUERY } from '../../../graphql'

const UsersTableActionColumn = ({ user }) => {
  const history = useHistory()
  const { startLoading, stopLoading } = useAppLoading()
  const { userInfo: loggedInUser } = useAuthentication()

  const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
    update: (cache, { data: { deleteUser: id } }) => {
      if (!id) {
        return
      }

      const { users } = cache.readQuery({ query: USERS_QUERY })
      const index = findIndex(users, u => u.id === id)
      const newUsers = update(users, { $splice: [[index, 1]] })

      cache.writeQuery({
        query: USERS_QUERY,
        data: { users: newUsers },
      })
    },
    onCompleted() {
      notification.open({
        message: 'User deleted.',
        description: `User has been sucesfully deleted.`,
        type: 'success',
      })
    },
    onError({ message }) {
      notification.open({
        message: 'User deletion failed.',
        description: message,
        type: 'error',
      })
    },
  })

  const handleDeleteUser = useCallback(async () => {
    startLoading()
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.USER_EVENTS.DELETE_CLICK,
      { deletedUserId: user.id },
    )
    await deleteUser({ variables: { id: user.id } })
    stopLoading()
  }, [startLoading, stopLoading, deleteUser, user])

  const isCurrentUser = user.id === loggedInUser.id

  return (
    <>
      <Button
        onClick={() =>
          history.push(`${APP_ROUTES.USER_ROUTES.Users}/${user.id}`)
        }
        margin="no small"
      >
        Edit
      </Button>
      <Popconfirm
        title={
          <>
            <Typography.Text>
              Are you sure you want to delete this user?
            </Typography.Text>
            <br />
            <Typography.Text>
              This should only be used to mark users to be deleted completely by
              devs.
            </Typography.Text>
            <br />
            <Typography.Text>
              Use isActive checkbox from inside the user details
            </Typography.Text>
          </>
        }
        disabled={isCurrentUser}
        onConfirm={handleDeleteUser}
        placement="left"
        okText="Yes"
        cancelText="No"
      >
        <Button disabled={isCurrentUser}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </>
  )
}

UsersTableActionColumn.propTypes = {
  user: userInfoPropType.isRequired,
}

export default UsersTableActionColumn
