import { gql } from '@apollo/client'

import { BRAND_DISH_FRAGMENT } from '../fragments'

export default gql`
  query dishQuery($id: ID!) {
    dish(id: $id) {
      ...BrandDishFragment

      ingredientExplanations {
        id
        text
        originField
        ingredientId
        ingredientType
      }
    }
  }
  ${BRAND_DISH_FRAGMENT}
`
