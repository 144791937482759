import React, { memo } from 'react'
import { size, toLower } from 'lodash'
import PropTypes from 'prop-types'

const styles = {
  baseTreeSelectStyles: { width: '100%' },
  stickyTreeNodeStyle: {
    backgroundColor: '#fff5e6',
    fontWeight: 'bold',
  },
  ignoredTreeNodeStyle: {
    backgroundColor: '#a3a3a3',
  },
  searchHighlight: {
    backgroundColor: '#DB65FF',
  },
}

const TreeSelectNodeTitle = ({ title, isSticky, isIgnored, searchValue }) => {
  if (!searchValue) {
    return (
      <div
        style={{
          ...(isSticky && styles.stickyTreeNodeStyle),
          ...(isIgnored && styles.ignoredTreeNodeStyle),
        }}
        className="select-sticky-node"
      >
        {title}
      </div>
    )
  }

  const startIndex = toLower(title)?.indexOf(toLower(searchValue))
  const endIndex = startIndex + size(searchValue)

  return (
    <div
      style={{
        ...(isSticky && styles.stickyTreeNodeStyle),
        ...(isIgnored && styles.ignoredTreeNodeStyle),
      }}
      className="select-sticky-node"
    >
      {startIndex !== 0 && <span>{title.substring(0, startIndex)}</span>}
      <span style={styles.searchHighlight}>
        {title.substring(startIndex, endIndex)}
      </span>
      {endIndex < title.length && (
        <span>{title.substring(endIndex, title.length)}</span>
      )}
    </div>
  )
}

TreeSelectNodeTitle.propTypes = {
  title: PropTypes.string,
  isSticky: PropTypes.bool,
  isIgnored: PropTypes.bool,
  searchValue: PropTypes.string,
}

TreeSelectNodeTitle.defaultProps = {
  title: '',
  isSticky: false,
  isIgnored: false,
  searchValue: null,
}

export default memo(TreeSelectNodeTitle)
