const USER_DEPARTMENT = {
  SCRAPING: 'SCRAPING',
  DEVELOPMENT: 'DEVELOPMENT',
  CURATION: 'CURATION',
  PRECRUITER: 'PRECRUITER',
  RECRUITMENT: 'RECRUITMENT',
  MANAGEMENT: 'MANAGEMENT',
}

export default USER_DEPARTMENT
