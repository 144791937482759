import { gql } from '@apollo/client'

const BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY = gql`
  query brandSuggestedWordsCompletenessCountQuery($brandId: ID!) {
    brandSuggestedWordsCompletenessCount(brandId: $brandId) {
      curatedWordsCount
      qadWordsCount
      wordsToResolveTotal
    }
  }
`

export default BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY
