import { get, isEmpty, join, map, pick, stubFalse } from 'lodash'

const createTreeSelectOptions = (
  options,
  labelProp,
  valueProp,
  shouldDisable = stubFalse,
  extraProps = [],
  keyPath = [],
) =>
  map(options, option => ({
    key: get(option, valueProp),
    keyPath: [...keyPath, get(option, valueProp)],
    title: get(option, labelProp),
    namePath: join(option.namePath, ' / '),
    value: get(option, valueProp),
    children: !isEmpty(option.children)
      ? createTreeSelectOptions(
          option.children,
          labelProp,
          valueProp,
          shouldDisable,
          extraProps,
          [...keyPath, get(option, valueProp)],
        )
      : [],
    disabled: shouldDisable(option),
    ...pick(option, extraProps),
  }))

export default createTreeSelectOptions
