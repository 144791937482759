import { gql } from '@apollo/client'

const UPDATE_CLEANUP_TASK_ASSIGNED_CURATOR_MUTATION = gql`
  mutation updateCleanupTaskAssignedCurator(
    $id: ID!
    $metadataType: String!
    $userId: ID
  ) {
    updateCleanupTaskAssignedCurator(
      id: $id
      metadataType: $metadataType
      userId: $userId
    ) {
      id
      assignedCuratorId
    }
  }
`

export default UPDATE_CLEANUP_TASK_ASSIGNED_CURATOR_MUTATION
