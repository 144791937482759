import { get, map, omit } from 'lodash'
import omitDeep from 'omit-deep-lodash'

export const transformExistingDishToNewDish = ({ dish, brandId }) => {
  const {
    diets,
    allergens,
    dishType,
    courseType,
    menuTitle,
    servicingHours,
    ...savedSimpleDishFields
  } = dish || {}

  const unsavedDish = {
    ...omit(savedSimpleDishFields, [
      'id',
      'currency',
      'brand',
      'dietDescriptors',
      'miscDescriptors',
      'addonDescriptors',
      'nutritionsDescriptors',
      'allergenDescriptors',
      'aiSuggestions',
      'ingredientExplanations',
      'isValidated',
      'isQqaDone',
      'isQaDone',
      'status',
      'isEnabled',
      'miscAndChoiceStepCertainty',
      'curationStepCertainty',
      'fieldsUsedForUnknownWordsHighlights',
      'qaCorrectionCounts',
    ]),
    brandId,
    unsaved: true,
    published: false,
    dietIds: map(diets, 'id'),
    allergenIds: map(allergens, 'id'),
    dishTypeId: get(dishType, 'id'),
    courseTypeId: get(courseType, 'id'),
    menuTitleId: get(menuTitle, 'id'),
    servicingHoursIds: map(servicingHours, 'id'),
  }

  return omitDeep(unsavedDish, ['__typename'])
}
