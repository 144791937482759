import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from '../fragments'

const UPSERT_BRAND_MUTATION = gql`
  mutation upsertBrand($input: UpsertBrandInput!) {
    upsertBrand(input: $input) {
      ...BrandFragment
    }
  }
  ${BRAND_FRAGMENT}
`

export default UPSERT_BRAND_MUTATION
