import { useEffect } from 'react'

const useDocumentTitle = (title, skip) => {
  useEffect(() => {
    if (!skip) {
      document.title = `${title} - Curator-Tool`
    }
  })
}

export default useDocumentTitle
