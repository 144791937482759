import { join } from 'path'

import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { Button, notification } from 'antd'
import { isNil } from 'lodash/fp'

import {
  BRAND_QUERY,
  EXPORTED_BRAND_SUGGESTED_WORDS_SUBSCRIPTION,
  SIMPLE_BRAND_BY_ID_QUERY,
} from '../../brands/graphql'
import { APP_ROUTES, JOB_STATUS } from '../constants'
import { handleMutationError } from '../helpers'

const useExportedBrandSuggestedWordsSubscription = () => {
  const history = useHistory()
  const { brandId } = useParams()
  const { data } = useSubscription(EXPORTED_BRAND_SUGGESTED_WORDS_SUBSCRIPTION)

  const options = useMemo(
    () => ({
      variables: { id: brandId },
      onError: handleMutationError,
      fetchPolicy: 'network-only',
    }),
    [brandId],
  )

  const [fetchBrand] = useLazyQuery(BRAND_QUERY, options)
  const [fetchSimpleBrand] = useLazyQuery(SIMPLE_BRAND_BY_ID_QUERY, options)

  useEffect(() => {
    if (isNil(data?.exportedBrandSuggestedWords)) {
      return
    }

    if (isNil(data?.exportedBrandSuggestedWords?.brandId)) {
      notification.error({
        message: 'Exporting Brand Suggested Words Error',
        description: `No brandId provided`,
        placement: 'topLeft',
      })
    }

    if (
      data?.exportedBrandSuggestedWords?.jobStatus === JOB_STATUS.FAILED ||
      !isNil(data?.exportedBrandSuggestedWords?.reason)
    ) {
      notification.error({
        message: 'Exporting Brand Suggested Words Error',
        description: `Failed to export brand suggested words on ${
          data?.exportedBrandSuggestedWords?.brandName ||
          data?.exportedBrandSuggestedWords?.brandId
        }.
            ${data?.exportedBrandSuggestedWords?.reason}`,
        placement: 'topLeft',
      })
    }

    if (
      brandId !== data?.exportedBrandSuggestedWords?.brandId &&
      data?.exportedBrandSuggestedWords?.jobStatus === JOB_STATUS.SUCCESS
    ) {
      const { exportedBrandSuggestedWords } = data
      const notificationKey = `open${Date.now()}`

      notification.success({
        key: notificationKey,
        message: 'Exporting Brand Suggested Words Finished',
        description: `Successfully exported suggested words on brand ${exportedBrandSuggestedWords.brandName}`,
        btn: (
          <Button
            onClick={() => {
              history.push(
                join(
                  APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
                  exportedBrandSuggestedWords.brandId,
                ),
              )
              notification.close(notificationKey)
            }}
          >
            See the brand
          </Button>
        ),
        placement: 'topLeft',
      })
    }

    if (!isNil(brandId)) {
      return fetchBrand()
    }

    return fetchSimpleBrand({
      variables: {
        id: data.exportedBrandSuggestedWords.brandId,
      },
    })
  }, [brandId, history, fetchBrand, data, fetchSimpleBrand])
}

export default useExportedBrandSuggestedWordsSubscription
