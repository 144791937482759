import React from 'react'
import { notification, Typography } from 'antd'
import { get, isEmpty, map } from 'lodash'

import { suggestionEndpoints } from '../../common/constants'
import { getAiSuggestions } from '../../common/utils'

import { brandLabelLegendSuggestionTransformer } from '../transformers'

/**
 * Calls the Ai servicing for suggesting diet label legends.
 * It should:
 *   - fetch suggestions
 *   - return the suggestions
 *
 * Returns a promise.
 */
export default async function suggestLabelLegends({ brandId }) {
  try {
    // this endpoint gives us suggestions for multiple brands, that's why we destruct here
    const [result] = await getAiSuggestions(suggestionEndpoints.labelLegends, [
      brandLabelLegendSuggestionTransformer.transformBrandToAiRequestDto(
        brandId,
      ),
    ])

    if (!isEmpty(result.messages)) {
      notification.error({
        message: 'An error occured',
        description: map(result.messages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })
      return null
    }

    return {
      dietLabelLegends: get(result, `dietLegend`),
      allergenLabelLegends: get(result, `allergenLegend`),
    }
  } catch (e) {
    notification.error({
      message: 'An error occured',
      description: e.message,
      placement: 'topLeft',
    })
  }
  return null
}
