import { isEmpty, isNil, map, some, tail } from 'lodash'

import findNodesBy from './findNodesBy'

export const getMenuTitleOptions = brandMenuTitleOptions => {
  const transformOption = cMenuTitle => {
    if (isNil(cMenuTitle) || isEmpty(cMenuTitle)) {
      return {}
    }

    return {
      ...cMenuTitle,
      title: cMenuTitle.name,
      value: cMenuTitle.id,
      children: map(cMenuTitle.children, child => transformOption(child)),
    }
  }
  return map(brandMenuTitleOptions, rootMenu => ({
    ...transformOption(rootMenu),
    disabled: true,
  }))
}

export const getMenuTitlesWithParents = (
  selectedMenuTitle,
  brandMenuTitleOptions,
) => {
  if (isNil(selectedMenuTitle)) {
    return []
  }

  // we remove the first element from the list the first element will always be a root menu node
  const menuTitleList = tail([
    ...findNodesBy(brandMenuTitleOptions, node =>
      some(selectedMenuTitle.parents, parentId => node.id === parentId),
    ),
    selectedMenuTitle,
  ])

  return map(
    menuTitleList,
    ({
      id: menuTitleId,
      name: menuTitleName,
      description: menuTitleDescription,
      styledDescription: menuTitleStyledDescription,
      addonDescriptors: menuTitleAddonDescriptors,
      dietDescriptors: menuTitleDietDescriptors,
      allergenDescriptors: menuTitleAllergenDescriptors,
    }) => ({
      menuTitleId,
      menuTitleName,
      menuTitleDescription,
      menuTitleStyledDescription,
      menuTitleAddonDescriptors,
      menuTitleAllergenDescriptors,
      menuTitleDietDescriptors,
    }),
  )
}
