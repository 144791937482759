import { flatten, get, isEmpty, isNil, keys, map, pick } from 'lodash'

import { areSameValue } from '../../core/utils'

import {
  extractChoiceText,
  extractMiscText,
  findNodeBy,
  getMenuTitlesWithParents,
  mergeArrayOfObjects,
} from '../../common/utils'

import {
  findMatchedWordsIndexesFromTextField,
  mapStyledTextIndexesToDish,
} from '../helpers'

export const transformDishToAiRequestDto = (dish, additionalInformation) => {
  const dishToParse = mergeArrayOfObjects(
    map(
      keys(pick(dish, ['name', 'description', 'ingredientsText'])),
      mapStyledTextIndexesToDish(dish),
    ),
  )

  dishToParse.id = dish.id
  dishToParse.dietIds = map(dish.diets, 'id')
  dishToParse.dishTypeId = get(dish, 'dishType.id')
  dishToParse.brandId = get(dish, 'brand.id') || ''
  dishToParse.brandName = get(dish, 'brand.name') || ''
  dishToParse.alternativeBrandName = dish?.alternativeBrandName

  if (dish.menuTitle && !isEmpty(additionalInformation)) {
    const { menuTitleOptions } = additionalInformation

    const selectedMenuTitle = findNodeBy(menuTitleOptions, ({ id }) =>
      areSameValue(id, dish.menuTitle.id),
    )

    const menuTitleList = getMenuTitlesWithParents(
      selectedMenuTitle,
      menuTitleOptions,
    )

    dishToParse.menuTitleDescriptions = map(
      menuTitleList,
      ({ menuTitleId, menuTitleDescription, menuTitleStyledDescription }) => {
        const matchedExtraWords = extractMiscText(menuTitleStyledDescription)
        const matchedChoiceWords = extractChoiceText(menuTitleStyledDescription)
        return {
          id: menuTitleId,
          text: menuTitleDescription || '',
          misc: menuTitleDescription
            ? findMatchedWordsIndexesFromTextField(
                matchedExtraWords,
                menuTitleStyledDescription,
              )
            : [],
          choices: menuTitleDescription
            ? findMatchedWordsIndexesFromTextField(
                matchedChoiceWords,
                menuTitleStyledDescription,
              )
            : [],
        }
      },
    )

    dishToParse.menuTitleAddonDescriptors = flatten(
      map(menuTitleList, ({ menuTitleId, menuTitleAddonDescriptors }) =>
        map(menuTitleAddonDescriptors, descriptor => ({
          id: menuTitleId,
          text: descriptor || '',
        })),
      ),
    )

    dishToParse.menuTitles = map(
      menuTitleList,
      ({ menuTitleId, menuTitleDescription = '', menuTitleName = '' }) => ({
        id: menuTitleId,
        name: menuTitleName,
        description: menuTitleDescription,
      }),
    )
  }

  if (!isNil(dish.addonDescriptors)) {
    dishToParse.addonDescriptors = dish.addonDescriptors.join(' ; ')
  }

  return dishToParse
}

const getMatchType = ingredient => {
  if (ingredient.replaces) {
    return 'replacement'
  }

  return ingredient.matchType === 'synonym' && ingredient.level
    ? `${ingredient.matchType}_level_${ingredient.level}`
    : ingredient.matchType
}

export const transformDishFromAiResponseDto = responseBody =>
  map(
    responseBody,
    ({
      parsedIngredients,
      blacklistedIngredients,
      additionalIngredients,
      explanations,
      messages,
      certainty,
    }) => ({
      mainIngredients: map(
        parsedIngredients,
        ({
          id,
          name,
          isChoice,
          alternativeType,
          overrides,
          replaces,
          parserOutput,
          level,
          mlOutput,
        }) => ({
          id,
          name,
          isChoice,
          alternativeType,
          overrides,
          replaces,
          isSuggestedByAi: true,
          level,
          isSuggestedUsingParser: !isEmpty(parserOutput),
          ...(!isEmpty(parserOutput) && {
            ...pick(parserOutput, [
              'alreadyFoundIn',
              'matchType',
              'originField',
              'posIndex',
              'usedFeature',
              'keyword',
              'word',
            ]),
            matchType: getMatchType({ ...parserOutput, replaces, level }),
          }),
          isSuggestedUsingMl: !isEmpty(mlOutput),
          mlScore: mlOutput?.score,
        }),
      ),
      excludedIngredients: map(
        blacklistedIngredients,
        ({ ingredientId, ingredientName, parserOutput, mlOutput }) => ({
          name: ingredientName,
          id: ingredientId,
          isSuggestedUsingParser: !isEmpty(parserOutput),
          ...(!isEmpty(parserOutput) && {
            ...(!isEmpty(parserOutput) && {
              ...pick(parserOutput, [
                'originField',
                'usedFeature',
                'matchType',
              ]),
              word: parserOutput.negativeSynonym,
            }),
          }),
          isSuggestedUsingMl: !isEmpty(mlOutput),
          mlScore: mlOutput?.score,
        }),
      ),
      additionalIngredients: map(additionalIngredients, ingredient => ({
        id: `${ingredient.id}`,
        name: ingredient.name,
        ingredientType: ingredient.addIngType,
        ...(ingredient.overrides && { overrides: ingredient.overrides }),
      })),
      explanations: map(
        explanations,
        ({ text, ingredientId, originField, type, tokens, ingrType }) => ({
          text,
          ingredientId,
          originField,
          type,
          ingredientType: ingrType,
          tokens: map(tokens, token => ({
            text: token.text,
            normalizedText: token.normalized_text,
            startIdx: token.start_idx,
            endIdx: token.end_idx,
          })),
        }),
      ),
      messages,
      certainty,
    }),
  )
