import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_ROUTES } from '../../common/constants'

import { Reports } from './pages'

const ReportsRouter = () => (
  <Switch>
    <Route exact path={APP_ROUTES.REPORT_ROUTES.Reports} component={Reports} />
  </Switch>
)

export default ReportsRouter
