import { isArray, isEmpty, omit, reduce } from 'lodash'
// TODO test duplication, import, reports
const getNodes =
  (filterFunction, shouldSearchChildrenIfNotFound) => (result, node) => {
    if (filterFunction(node)) {
      if (!shouldSearchChildrenIfNotFound) {
        result.push(node)
        return result
      }

      const nodeToAdd = omit(node, 'children')

      if (isArray(node.children)) {
        const children = reduce(
          node.children,
          getNodes(filterFunction, shouldSearchChildrenIfNotFound),
          [],
        )

        result.push({ ...nodeToAdd, children })
      }

      return result
    }

    if (shouldSearchChildrenIfNotFound) {
      return result
    }

    if (isArray(node.children)) {
      const children = reduce(
        node.children,
        getNodes(filterFunction, shouldSearchChildrenIfNotFound),
        [],
      )

      if (!isEmpty(children)) {
        result.push({ ...node, children })
      }
    }

    return result
  }

const filterTree = (
  tree = [],
  filterFunction,
  shouldSearchChildrenIfNotFound,
) => reduce(tree, getNodes(filterFunction, shouldSearchChildrenIfNotFound), [])

export default filterTree
