import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { Select as AntSelect } from 'antd'
import { get, isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { SpanPropType } from '../../../propTypes'
import { selectFilterOption } from '../../../utils'
import { GridFormItem } from '../../atoms'

const Select = ({
  mode,
  className,
  name,
  defaultValue,
  style,
  options,
  allowClear,
  disabled,
  size,
  placeholder,
  labelProp,
  valueProp,
  optionFilterProp,
  filterOption,
  label,
  labelAbove,
  smallLabel,
  span,
  showSearch,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error: { message } = {} },
  } = useController({
    name,
    defaultValue:
      mode === 'multiple' && isNil(defaultValue) ? [] : defaultValue,
  })

  const optionsBasedOnLabelAndValueProps = useMemo(
    () =>
      map(options, o => ({
        label: get(o, labelProp),
        value: get(o, valueProp),
      })),
    [labelProp, options, valueProp],
  )

  let selectedValue = value

  if (isNil(value)) {
    selectedValue = mode === 'multiple' ? [] : undefined
  }

  const handleChange = useCallback(
    newValue => (isNil(newValue) ? onChange(null) : onChange(newValue)),
    [onChange],
  )

  return (
    <GridFormItem
      style={style}
      validateStatus={invalid ? 'error' : undefined}
      help={message}
      span={span}
      label={label}
      labelAbove={labelAbove}
      smallLabel={smallLabel}
    >
      <AntSelect
        className={className}
        maxTagCount={mode === 'multiple' ? 'responsive' : undefined}
        mode={mode}
        showSearch={showSearch}
        style={style}
        value={selectedValue}
        allowClear={allowClear}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
        optionFilterProp={optionFilterProp}
        filterOption={filterOption}
        onChange={handleChange}
        onBlur={onBlur}
        options={optionsBasedOnLabelAndValueProps}
      />
    </GridFormItem>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  placeholder: PropTypes.string,
  labelProp: PropTypes.string,
  valueProp: PropTypes.string,
  optionFilterProp: PropTypes.string,
  filterOption: PropTypes.func,
  mode: PropTypes.oneOf(['multiple', 'tags']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  smallLabel: PropTypes.bool,
  labelAbove: PropTypes.bool,
  span: SpanPropType,
  showSearch: PropTypes.bool,
}

Select.defaultProps = {
  className: undefined,
  defaultValue: null,
  style: undefined,
  disabled: false,
  allowClear: true,
  labelProp: 'label',
  valueProp: 'value',
  size: 'default',
  placeholder: 'Select...',
  optionFilterProp: 'children',
  filterOption: selectFilterOption,
  mode: undefined,
  label: undefined,
  labelAbove: false,
  span: undefined,
  smallLabel: undefined,
  showSearch: true,
}

export default Select
