import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, notification, Typography } from 'antd'
import { flatten, get, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../../../core/hooks'

import { suggestionEndpoints } from '../../../../common/constants'
import { useAppLoading } from '../../../../common/hooks'
import { setAiSuggestions } from '../../../../common/redux/actions'
import { getAiSuggestions } from '../../../../common/utils'

import { MENU_TITLE } from '../../../../brands/constants'
import { MenuTitleTreeItemPropType } from '../../../propTypes'
import {
  menuTitleTransformer,
  menuTitleVariousTextSuggestionTransformer,
} from '../../../transformers'

const MenuTitleProcessingButton = ({
  ownerId,
  ownerType,
  menuTitleTreeData,
  menuTitlesHighlightWords,
  style,
}) => {
  const dispatch = useDispatch()
  const { isLoading, startLoading, stopLoading } = useAppLoading()

  const menuTitleSuggestions = useShallowEqualSelector(({ aiSuggestions }) =>
    get(aiSuggestions, `brands.${ownerId}.${MENU_TITLE}`),
  )

  const processMenuTitles = useCallback(async () => {
    startLoading()
    const suggestions = await getAiSuggestions(
      suggestionEndpoints.variousText,
      menuTitleVariousTextSuggestionTransformer.transformMenuTitlesToAiRequestDto(
        flatten(map(menuTitleTreeData, 'children')),
      ),
      menuTitleVariousTextSuggestionTransformer.transformMenuTitlesFromAiResponseDto(
        map(menuTitleTreeData, (menuTitleRoot, index) =>
          menuTitleTransformer.transformMenuTitleFromDto(
            menuTitleRoot,
            menuTitlesHighlightWords,
            0,
            `[${index}]`,
          ),
        ),
      ),
    )

    const aiMessages = get(suggestions, 'messages')
    if (!isEmpty(aiMessages)) {
      notification.error({
        message: 'An error occured',
        description: map(aiMessages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })
    } else {
      const owningEntityWithSuggestions = {
        ownerId,
        type: ownerType,
        aiSuggestions: { key: MENU_TITLE, values: suggestions },
      }

      // Store Ai suggestions.
      dispatch(setAiSuggestions(owningEntityWithSuggestions))
    }
    stopLoading()
  }, [
    dispatch,
    menuTitleTreeData,
    menuTitlesHighlightWords,
    ownerId,
    ownerType,
    startLoading,
    stopLoading,
  ])

  return (
    <Button
      loading={isLoading}
      style={style}
      disabled={!isEmpty(menuTitleSuggestions)}
      onClick={processMenuTitles}
    >
      Process menu titles
    </Button>
  )
}

MenuTitleProcessingButton.propTypes = {
  ownerId: PropTypes.string.isRequired,
  ownerType: PropTypes.string.isRequired,
  menuTitleTreeData: PropTypes.arrayOf(MenuTitleTreeItemPropType).isRequired,
  menuTitlesHighlightWords: PropTypes.object,
  style: PropTypes.object,
}

MenuTitleProcessingButton.defaultProps = {
  menuTitlesHighlightWords: {},
  style: {},
}

export default memo(MenuTitleProcessingButton)
