import {
  every,
  filter,
  get,
  isArray,
  isEmpty,
  isUndefined,
  keys,
  map,
  omit,
  pick,
} from 'lodash'

import { mergeArrayOfObjects } from '../../common/utils'

import { DISH_FIELDS } from '../constants'

const clearAiErrorsFromDish = (dish, unsavedDish, aiSuggestions) => {
  const suggestionKeys = keys(omit(dish.aiSuggestions, '__typename'))

  const suggestionsWithErrors = mergeArrayOfObjects(
    map(
      // we must first ensure that we keep the errors on the fields that were not touched
      filter(suggestionKeys, suggestionKey => {
        if (
          every(
            [
              DISH_FIELDS.MAIN_INGREDIENTS,
              DISH_FIELDS.ADDITIONAL_INGREDIENTS,
              DISH_FIELDS.CHOICE_INGREDIENTS,
            ],
            key => isUndefined(get(unsavedDish, key)),
          ) &&
          suggestionKey === DISH_FIELDS.MAIN_INGREDIENTS
        ) {
          return false
        }
        if (
          every(['dietIds', 'allergenIds'], key =>
            isUndefined(get(unsavedDish, key)),
          ) &&
          (suggestionKey === DISH_FIELDS.DIETS ||
            suggestionKey === DISH_FIELDS.ALLERGENS)
        ) {
          return false
        }

        if (
          isUndefined(get(unsavedDish, 'dishTypeId')) &&
          suggestionKey === DISH_FIELDS.DISH_TYPE
        ) {
          return false
        }

        if (
          isUndefined(get(unsavedDish, 'courseTypeId')) &&
          suggestionKey === DISH_FIELDS.COURSE_TYPE
        ) {
          return false
        }

        const suggestion = get(dish.aiSuggestions, `${suggestionKey}`)
        const suggestionIsObject = !isArray(suggestion)
        // we need to test against null as well and has only checks if the field is undefined
        return !isEmpty(
          suggestionIsObject
            ? get(suggestion, 'messages')
            : get(suggestion, `[0].messages`),
        )
      }),
      key => ({ [key]: get(dish.aiSuggestions, key) }),
    ),
  )

  const existingAiSuggestionsClearedOfErrors = !isEmpty(suggestionsWithErrors)
    ? mergeArrayOfObjects(
        map(keys(suggestionsWithErrors), suggestionKey => {
          const suggestion = get(suggestionsWithErrors, suggestionKey)
          const suggestionIsObject = !isArray(suggestion)

          // we need to test against null as well and has only checks if the field is undefined
          if (suggestionIsObject) {
            return { [suggestionKey]: { certainty: 1 } }
          }

          return { [suggestionKey]: [] }
        }),
      )
    : {}

  let keysNotInAiSuggestions = []
  if (!isEmpty(existingAiSuggestionsClearedOfErrors)) {
    keysNotInAiSuggestions = filter(
      keys(existingAiSuggestionsClearedOfErrors),
      key => isUndefined(get(aiSuggestions, key)),
    )
  }

  if (!isEmpty(existingAiSuggestionsClearedOfErrors)) {
    return !isEmpty(aiSuggestions)
      ? {
          ...pick(existingAiSuggestionsClearedOfErrors, keysNotInAiSuggestions),
          ...aiSuggestions,
        }
      : existingAiSuggestionsClearedOfErrors
  }

  return aiSuggestions
}

export default clearAiErrorsFromDish
