import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PrivateRoute } from '../../common/components'
import { APP_ROUTES, PERMISSIONS } from '../../common/constants'

import { UserEditorPage, UserSettingsPage, UsersPage } from './pages'

export default function UserRouter() {
  return (
    <Switch>
      <PrivateRoute
        permissions={[PERMISSIONS.USERS.LIST]}
        exact
        path={APP_ROUTES.USER_ROUTES.Users}
        Component={UsersPage}
      />
      <PrivateRoute
        permissions={[PERMISSIONS.USERS.LIST]}
        exact
        path={APP_ROUTES.USER_ROUTES.UserCreate}
        Component={UserEditorPage}
      />
      <PrivateRoute
        permissions={[PERMISSIONS.USERS.LIST]}
        exact
        path={APP_ROUTES.USER_ROUTES.UserDetails}
        Component={UserEditorPage}
      />
      <Route
        exact
        path={APP_ROUTES.USER_ROUTES.UserSettings}
        component={UserSettingsPage}
      />
    </Switch>
  )
}
