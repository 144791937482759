import React, { useCallback, useMemo } from 'react'
import { Input, Row, Select } from 'antd'
import { keys, map } from 'lodash'

import { GridFormItem } from '../../../../core/components'
import { useQueryParams } from '../../../../core/hooks'

import {
  DEFAULT_PAGINATION_CONFIG,
  SELECT_ALL_OPTION,
} from '../../../../common/constants'

import theme from '../../../../theme'
import {
  CLEANUP_TASK_STATUS,
  DEFAULT_CLEANUP_FILTERS,
} from '../../../constants'

const styles = {
  row: {
    paddingBottom: theme.padding,
  },
  select: {
    width: '100%',
  },
}

const CleanupTaskFilters = () => {
  const {
    status = DEFAULT_CLEANUP_FILTERS.STATUS,
    search = DEFAULT_CLEANUP_FILTERS.SEARCH,
    setQueryParams,
  } = useQueryParams()

  const handleFiltersChange = useCallback(
    filterName => filterValue => {
      setQueryParams({
        [filterName]: filterValue,
        page: DEFAULT_PAGINATION_CONFIG.PAGE,
      })
    },
    [setQueryParams],
  )

  const statusOptions = useMemo(
    () => [
      SELECT_ALL_OPTION,
      ...map(keys(CLEANUP_TASK_STATUS), statusName => ({
        label: CLEANUP_TASK_STATUS[statusName],
        value: CLEANUP_TASK_STATUS[statusName],
      })),
    ],
    [],
  )

  return (
    <>
      <Row gutter={16}>
        <GridFormItem span={20}>
          <Input.Search
            placeholder="Search by name..."
            defaultValue={search}
            onSearch={handleFiltersChange('search')}
          />
        </GridFormItem>
      </Row>
      <Row style={styles.row} gutter={16}>
        <GridFormItem span={12} label="Status">
          <Select
            value={status}
            onChange={handleFiltersChange('status')}
            style={styles.select}
            options={statusOptions}
          />
        </GridFormItem>
      </Row>
    </>
  )
}

export default CleanupTaskFilters
