const BRAND_PENDING_STATUS = Object.freeze({
  MENU_IMPORT_PENDING: 'MENU_IMPORT_PENDING',
  SUGGEST_UNKNOWN_WORDS_PENDING: 'SUGGEST_UNKNOWN_WORDS_PENDING',
  EXPORT_WORDS_PENDING: 'EXPORT_WORDS_PENDING',
  BATCH_CURATION_PENDING: 'BATCH_CURATION_PENDING',
  MISC_AND_CHOICE_PENDING: 'MISC_AND_CHOICE_PENDING',
  DUPLICATE_PENDING: 'DUPLICATE_PENDING',
  MENU_IMPORT_ERROR: 'MENU_IMPORT_ERROR',
  SUGGEST_UNKNOWN_WORDS_ERROR: 'SUGGEST_UNKNOWN_WORDS_ERROR',
  EXPORT_WORDS_ERROR: 'EXPORT_WORDS_ERROR',
  BATCH_CURATION_ERROR: 'BATCH_CURATION_ERROR',
  MISC_AND_CHOICE_ERROR: 'MISC_AND_CHOICE_ERROR',
  DUPLICATE_ERROR: 'DUPLICATE_ERROR',
})

export default BRAND_PENDING_STATUS
