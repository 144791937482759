import { gql } from '@apollo/client'

import { SIMPLE_ENTITY_FRAGMENT } from '../../../common/graphql'

export default gql`
  mutation setDishTypesByMenuTitleMutation($menuTitleId: ID!, $dishTypeId: ID) {
    setDishTypesByMenuTitle(
      menuTitleId: $menuTitleId
      dishTypeId: $dishTypeId
    ) {
      id
      dishType {
        ...SimpleEntityFragment
        courseTypeId
        isIgnored
      }
      courseType {
        ...SimpleEntityFragment
        dishTypeId
        isIgnored
      }
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
`
