import { gql } from '@apollo/client'

export default gql`
  query brandsWithDuplicates($filters: BrandsWithDuplicatesFiltersInput!) {
    brandsWithDuplicates(filters: $filters) {
      id
      name
      duplicates {
        id
        name
      }
    }
  }
`
