import React, { memo } from 'react'
import { Button, Col, Popconfirm, Tooltip, Typography } from 'antd'
import { PropTypes } from 'prop-types'

const BrandResetButton = ({
  onReset,
  disabled,
  isLoading,
  disabledMessage,
  label,
  confirmToltip,
}) => (
  <Col>
    <Popconfirm
      placement="top"
      title={
        <Typography.Text>
          {confirmToltip}
          <br /> Are you sure?
        </Typography.Text>
      }
      onConfirm={onReset}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
    >
      <Tooltip title={disabled ? disabledMessage : undefined}>
        <Button disabled={disabled} type="default" loading={isLoading}>
          {label}
        </Button>
      </Tooltip>
    </Popconfirm>
  </Col>
)

BrandResetButton.propTypes = {
  onReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabledMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  confirmToltip: PropTypes.string.isRequired,
}

BrandResetButton.defaultProps = {
  disabled: false,
  isLoading: false,
  disabledMessage: undefined,
}

export default memo(BrandResetButton)
