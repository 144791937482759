import { gql } from '@apollo/client'

import { BRAND_DISH_FRAGMENT } from '../fragments'

export default gql`
  mutation updateDishTextFieldsMutation($input: UpdateSimpleBrandDishInput!) {
    updateDishTextFields(input: $input) {
      ...BrandDishFragment
    }
  }
  ${BRAND_DISH_FRAGMENT}
`
