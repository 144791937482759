import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { LoadingContext } from '../contexts'

const LoadingProvider = ({ children }) => {
  const [loadingCount, setLoadingCount] = useState(0)

  const startLoading = useCallback(() => {
    setLoadingCount(prevState => prevState + 1)
  }, [])

  const stopLoading = useCallback(() => {
    setLoadingCount(prevState => prevState - 1)
  }, [])

  return (
    <LoadingContext.Provider
      value={{
        loadingCount,
        startLoading,
        stopLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LoadingProvider
