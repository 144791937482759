import { gql } from '@apollo/client'

import { DISHES_SUMMARY_FRAGMENT } from '../../../common/graphql'

import LOCATION_ENTRY_FRAGMENT from './locationEntryFragment'

export default gql`
  fragment SimpleLocationFragment on Location {
    ...LocationEntryFragment
    updatedAt
    createdAt
    dishesSummary {
      ...DishesSummaryFragment
    }
  }
  ${LOCATION_ENTRY_FRAGMENT}
  ${DISHES_SUMMARY_FRAGMENT}
`
