import { join } from 'path'

import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DownOutlined, WarningOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Dropdown, Menu, notification, Tooltip, Typography } from 'antd'
import { includes, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { APP_ROUTES, CURATION_STATUS } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAppLoading } from '../../../../common/hooks'

import {
  DUPLICATE_BRAND_FOR_AI_TESTING_MUTATION,
  DUPLICATE_BRAND_MUTATION,
} from '../../../graphql'

const DuplicateBrandButton = ({
  brandId,
  status,
  disabled,
  originalBrand,
  brandDuplicatedAt,
}) => {
  const history = useHistory()
  const { startLoading, stopLoading } = useAppLoading()
  const [duplicateBrand, { loading: isDuplicationLoading }] = useMutation(
    DUPLICATE_BRAND_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: () =>
        notification.info({
          message: 'Brand duplication started!',
          description:
            'You will receive a notification once the duplication is finished.',
          placement: 'topLeft',
        }),
    },
  )

  const handleNotificationButtonClick = useCallback(
    (duplicateBrandForAiTesting, notificationKey) => {
      history.push(
        join(
          APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
          duplicateBrandForAiTesting?.id,
        ),
      )
      notification.close(notificationKey)
    },
    [history],
  )

  const [duplicateForAiTesting, { loading: isDuplicationForAiTestingLoading }] =
    useMutation(DUPLICATE_BRAND_FOR_AI_TESTING_MUTATION, {
      onError: handleMutationError,
      onCompleted: ({ duplicateBrandForAiTesting = {} }) => {
        const notificationKey = `open${Date.now()}`
        notification.success({
          key: notificationKey,
          message: 'Duplicate brand finished',
          description: `Brand ${duplicateBrandForAiTesting?.name} successfully duplicated`,
          duration: 0,
          btn: (
            <Button
              onClick={() =>
                handleNotificationButtonClick(
                  duplicateBrandForAiTesting,
                  notificationKey,
                )
              }
            >
              See the brand
            </Button>
          ),
          placement: 'topLeft',
        })
      },
    })

  const isLoading = useMemo(
    () => isDuplicationLoading || isDuplicationForAiTestingLoading,
    [isDuplicationForAiTestingLoading, isDuplicationLoading],
  )

  const isDuplicateButtonDisabled = useMemo(
    () => disabled || status === CURATION_STATUS.NEW.value,
    [disabled, status],
  )

  const isDropdownDisabled = useMemo(
    () =>
      isLoading ||
      disabled ||
      includes(
        [
          CURATION_STATUS.NEW.value,
          CURATION_STATUS.SANITY_CHECK.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
          CURATION_STATUS.MISC_AND_CHOICE.value,
        ],
        status,
      ),
    [isLoading, disabled, status],
  )

  const isCurationConfirmationDuplicationDisabled = useMemo(
    () =>
      isLoading ||
      disabled ||
      includes(
        [
          CURATION_STATUS.NEW.value,
          CURATION_STATUS.SANITY_CHECK.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
          CURATION_STATUS.MISC_AND_CHOICE.value,
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
          CURATION_STATUS.LABEL_LEGENDS.value,
          CURATION_STATUS.LABEL_LEGENDS_CONFIRMATION.value,
          CURATION_STATUS.CURATION.value,
        ],
        status,
      ),
    [isLoading, disabled, status],
  )

  const handleDuplicate = useCallback(() => {
    duplicateBrand({
      variables: {
        id: brandId,
      },
    })
  }, [brandId, duplicateBrand])

  const handleDuplicateForAiTesting = useCallback(
    event => {
      duplicateForAiTesting({
        variables: {
          input: {
            id: brandId,
            targetStatus: event.key,
          },
        },
      })
    },
    [brandId, duplicateForAiTesting],
  )

  useEffect(() => {
    if (isLoading) {
      startLoading()
    } else {
      stopLoading()
    }
  }, [isLoading, startLoading, stopLoading])

  const buttonMenu = useMemo(
    () => (
      <Menu onClick={handleDuplicateForAiTesting} disabled={isDropdownDisabled}>
        <Menu.Item key={CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value}>
          Duplicate for Ai testing (MISC AND CHOICE CONFIRMATION)
        </Menu.Item>
        <Menu.Item
          key={CURATION_STATUS.CURATION_CONFIRMATION.value}
          disabled={isCurationConfirmationDuplicationDisabled}
        >
          Duplicate for Ai testing (CURATION CONFIRMATION)
        </Menu.Item>
      </Menu>
    ),
    [
      handleDuplicateForAiTesting,
      isCurationConfirmationDuplicationDisabled,
      isDropdownDisabled,
    ],
  )

  if (!isNil(originalBrand)) {
    return (
      <>
        {new Date(brandDuplicatedAt) < new Date(originalBrand.updatedAt) && (
          <Tooltip title="The original brand of this duplicate has changed">
            <WarningOutlined />
          </Tooltip>
        )}
        <Typography.Text>
          This brand is a duplicate of{' '}
          <Link
            target="_blank"
            to={join(APP_ROUTES.BRAND_ROUTES.BrandsPrefix, originalBrand.id)}
          >
            {originalBrand.name}
          </Link>
        </Typography.Text>
      </>
    )
  }

  if (isLoading) {
    return <Button loading>Duplicating...</Button>
  }

  return (
    <Dropdown.Button
      icon={<DownOutlined />}
      disabled={isDuplicateButtonDisabled}
      overlay={buttonMenu}
      onClick={handleDuplicate}
    >
      Duplicate
    </Dropdown.Button>
  )
}

DuplicateBrandButton.propTypes = {
  brandId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  originalBrand: PropTypes.object,
  brandDuplicatedAt: PropTypes.string,
  status: PropTypes.string,
}

DuplicateBrandButton.defaultProps = {
  disabled: false,
  originalBrand: undefined,
  brandDuplicatedAt: undefined,
  status: undefined,
}

export default memo(DuplicateBrandButton)
