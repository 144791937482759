import { useContext } from 'react'

import { AuthContext } from '../contexts'

const useAuthentication = () => {
  const authData = useContext(AuthContext)
  return authData
}

export default useAuthentication
