import { createContext } from 'react'
import { noop } from 'lodash'

export const defaultState = {
  loadingCount: false,
  startLoading: noop,
  stopLoading: noop,
}

export default createContext(defaultState)
