import React, { useCallback } from 'react'
import { Col, Pagination, Row } from 'antd'
import { chunk, map } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'

import {
  brandSuggestedWordPropType,
  brandSuggestedWordsCompletenessCountPropType,
} from '../../../propTypes'
import { BrandSuggestedWordCard } from '../BrandSuggestedWordCard'

import './brandSuggestedWordsModalBody.css'

const BrandSuggestedWordsModalBody = ({
  brandSuggestedWords,
  brandSuggestedWordsCompletenessCount,
  closeModal,
}) => {
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    brandSuggestedWordsCurrentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    brandSuggestedWordsPageSize = userDefaultPageSize ||
      DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    setQueryParams,
  } = useQueryParams()

  const changePagination = useCallback(
    (page, newPageSize) =>
      setQueryParams({
        brandSuggestedWordsCurrentPage:
          brandSuggestedWordsPageSize !== newPageSize
            ? DEFAULT_PAGINATION_CONFIG.PAGE
            : page,
        brandSuggestedWordsPageSize: newPageSize,
      }),
    [brandSuggestedWordsPageSize, setQueryParams],
  )

  return (
    <>
      {map(chunk(brandSuggestedWords, 6), (rowItems, index) => (
        <Row key={index} gutter={16}>
          {map(rowItems, brandSuggestedWord => (
            <Col span={4} key={brandSuggestedWord.id}>
              <BrandSuggestedWordCard
                closeModal={closeModal}
                brandSuggestedWord={brandSuggestedWord}
              />
            </Col>
          ))}
        </Row>
      ))}

      <Pagination
        showSizeChanger
        onChange={changePagination}
        onShowSizeChange={changePagination}
        current={Number(brandSuggestedWordsCurrentPage)}
        total={brandSuggestedWordsCompletenessCount.wordsToResolveTotal}
        pageSize={Number(brandSuggestedWordsPageSize)}
        pageSizeOptions={DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS}
      />
    </>
  )
}

BrandSuggestedWordsModalBody.propTypes = {
  closeModal: PropTypes.func.isRequired,
  brandSuggestedWords: PropTypes.arrayOf(brandSuggestedWordPropType).isRequired,
  brandSuggestedWordsCompletenessCount:
    brandSuggestedWordsCompletenessCountPropType.isRequired,
}

export default BrandSuggestedWordsModalBody
