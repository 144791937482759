import { get, includes, isNil, omit, pick, size, toString } from 'lodash'

import { extractBoldText, stripHtmlFromText } from '../../common/utils'

import { BRAND_SUGGESTED_WORD_TYPE } from '../constants'

export const toCardDetails = brandSuggestedWord => {
  const { payload, translation } = brandSuggestedWord

  return {
    ...brandSuggestedWord,
    payload: payload && JSON.parse(payload),
    translation: translation && JSON.parse(translation),
  }
}

export const toInitialValues = brandSuggestedWord => {
  const { value, context } = brandSuggestedWord

  const {
    posIndex,
    ingredientId,
    matchType: ingredientKeywordMatchType,
    allergenId,
    dietId,
    languageId,
  } = brandSuggestedWord.payload

  const originalValue = context.substring(posIndex[0], posIndex[1])

  let htmlFormatContext

  if (originalValue === value) {
    const boldedValue = `${context.substring(0, posIndex[0])}${context
      .substring(posIndex[0], posIndex[1])
      .replace(originalValue, '<strong>$&</strong>')}${context.substring(
      posIndex[1],
      size(context),
    )}`

    htmlFormatContext = `<p>${boldedValue}</p>`
  } else {
    htmlFormatContext = `<p>${context.replace(
      value,
      '<strong>$&</strong>',
    )}</p>`
  }

  return {
    ...pick(brandSuggestedWord, [
      'originField',
      'context',
      'type',
      'isContextDependant',
    ]),
    context: htmlFormatContext,
    allergenId,
    dietId,
    ingredientId,
    languageId: !isNil(languageId) ? toString(languageId) : undefined,
    ingredientKeywordMatchType,
  }
}

export const toUpdateArgs = formValues => {
  const convertedContextWithHighlight = formValues.context
  const selectedValueWithHtml = extractBoldText(convertedContextWithHighlight)
  const selectedValue = stripHtmlFromText(get(selectedValueWithHtml, '[0]'))

  let { isContextDependant } = formValues

  if (!isContextDependant) {
    isContextDependant = includes(
      [
        BRAND_SUGGESTED_WORD_TYPE.ALLERGEN_LABEL,
        BRAND_SUGGESTED_WORD_TYPE.DIET_LABEL,
      ],
      formValues.type,
    )
  }

  return omit(
    {
      ...formValues,
      isContextDependant,
      selectedValue,
    },
    ['context', 'originField'],
  )
}
