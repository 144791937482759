import { find, includes, map } from 'lodash'
import * as Yup from 'yup'

import { areSameValue } from '../../core/utils'

import { USER_DEPARTMENT } from '../constants'

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const createUserValidationSchema = departments =>
  Yup.object().shape({
    name: Yup.string().required('This field is required').nullable(),
    email: Yup.string().email().required('This field is required').nullable(),
    personalEmail: Yup.string()
      .email()
      .required('This field is required')
      .nullable(),
    clockifyEmail: Yup.string()
      .email()
      .required('This field is required')
      .nullable(),
    invoiceEmail: Yup.string()
      .email()
      .required('This field is required')
      .nullable(),
    birthdate: Yup.date().required('This field is required').nullable(),
    joinedAt: Yup.date().required('This field is required').nullable(),
    gender: Yup.string().required('This field is required').nullable(),
    extracurricularActivities: Yup.string().nullable(),
    previousWorkExperience: Yup.string().nullable(),
    departmentIds: Yup.array()
      .of(Yup.string())
      .min(1, 'This field is required'),
    qualificationIds: Yup.array().of(Yup.string()).nullable(),
    otherQualification: Yup.string().nullable(),
    country: Yup.string().required('This field is required').nullable(),
    timeZone: Yup.string().required('This field is required').nullable(),
    phone: Yup.string()
      .required('This field is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .nullable(),
    status: Yup.string().required('This field is required').nullable(),
    tier: Yup.string()
      .nullable()
      .when('departmentIds', {
        is: value => {
          const selectedDepartments = map(
            value,
            id =>
              find(departments, department => areSameValue(id, department.id))
                ?.value,
          )

          if (
            includes(selectedDepartments, USER_DEPARTMENT.CURATION) ||
            includes(selectedDepartments, USER_DEPARTMENT.SCRAPING) ||
            includes(selectedDepartments, USER_DEPARTMENT.MANAGEMENT) ||
            includes(selectedDepartments, USER_DEPARTMENT.RECRUITMENT)
          ) {
            return true
          }

          return false
        },
        then: Yup.string()
          .required('This field is required for the selected department')
          .nullable(),
      }),
    resignedAt: Yup.date().nullable(),
    terminatedAt: Yup.date().nullable(),
    lastReviewAt: Yup.date().nullable(),
    terminatedReason: Yup.string().nullable(),
    isActive: Yup.bool().required(),
    seniority: Yup.string().required('This field is required').nullable(),
    roleIds: Yup.array().of(Yup.string()).min(1, 'This field is required'),
    cuisineTypeIds: Yup.array().of(Yup.string()).nullable(),
    languageIds: Yup.array().of(Yup.string()).nullable(),
  })

export default createUserValidationSchema
