import PropTypes from 'prop-types'

const rolePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
})

const userInfoPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  seniority: PropTypes.string,
  isDeleted: PropTypes.bool,
  roles: PropTypes.arrayOf(rolePropType.isRequired),
  permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
  createdAt: PropTypes.string,
  lastLoginDate: PropTypes.string,
})

userInfoPropType.defaultProps = {
  permissions: [],
  roles: [],
  name: undefined,
  seniority: undefined,
  createdAt: undefined,
  lastLoginDate: undefined,
  isActive: undefined,
  isDeleted: undefined,
}

export default userInfoPropType
