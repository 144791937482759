import { join } from 'path'

import { isEmpty, join as lodashJoin, keys, map } from 'lodash'

import { APP_ROUTES } from '../constants'

const getRouteOfDish = (brandId, dishId, filters) => {
  const filtersQueryParts = lodashJoin(
    map(keys(filters), key => `${key}=${filters[key]}`),
    '&',
  )

  return join(
    APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
    brandId,
    `?${
      !isEmpty(filtersQueryParts) ? `${filtersQueryParts}&` : ''
    }selectedDishId=${dishId.toString()}`,
  )
}

export default getRouteOfDish
