import React, { memo, useMemo } from 'react'
import { Typography } from 'antd'
import { get, groupBy, keys, map, some, sortBy } from 'lodash'
import PropTypes from 'prop-types'

import { findNodeByValue } from '../../../../common/utils'

const styles = {
  explanation: {
    marginBottom: 0,
    height: '1.25rem',
  },
  explanationContainer: {
    marginTop: 16,
    marginBottom: 16,
  },
  removedExplanation: {
    color: '#ccc',
  },
}

const INGREDIENT_TYPE_LABELS = {
  choiceIngredients: 'Choice ingredients',
  mainIngredients: 'Main ingredients',
  additionalIngredients: 'Additional ingredients',
}

const DishIngredientExplanations = ({
  ingredientExplanations,
  dishIngredients,
  ingredientsTreeData,
}) => {
  const explanationsWithPath = useMemo(
    () =>
      sortBy(
        map(
          ingredientExplanations,
          ({ id, ingredientId, text, ingredientType }) => ({
            id,
            text,
            ingredientId,
            ingredientType,
            isRemoved: !some(
              dishIngredients,
              dishIngredient => dishIngredient.id === ingredientId,
            ),
            ingredientPath: get(
              findNodeByValue(ingredientsTreeData, ingredientId),
              'namePath',
            ),
          }),
        ),
        'isRemoved',
      ),
    [ingredientExplanations, dishIngredients, ingredientsTreeData],
  )

  const explanationsByIngredientType = groupBy(
    explanationsWithPath,
    'ingredientType',
  )

  return map(keys(explanationsByIngredientType), ingredientType => (
    <div key={ingredientType || 'other'}>
      <Typography.Text strong>
        {INGREDIENT_TYPE_LABELS[ingredientType] || ''}
      </Typography.Text>
      <div style={styles.explanationContainer}>
        {map(
          explanationsByIngredientType[ingredientType],
          ({ id, text, ingredientPath, isRemoved }) => (
            <Typography.Paragraph
              key={id}
              style={{
                ...styles.explanation,
                ...(isRemoved && styles.removedExplanation),
              }}
            >
              {text} - {ingredientPath}
            </Typography.Paragraph>
          ),
        )}
      </div>
    </div>
  ))
}

DishIngredientExplanations.propTypes = {
  ingredientExplanations: PropTypes.arrayOf(PropTypes.object).isRequired,
  dishIngredients: PropTypes.arrayOf(PropTypes.object).isRequired,
  ingredientsTreeData: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(DishIngredientExplanations)
