import React, { useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, Modal, Row, TreeSelect } from 'antd'
import { filter, get, isEmpty, isNil, toString } from 'lodash'
import PropTypes from 'prop-types'

import { handleMutationError } from '../../../../common/helpers'
import { useAppLoading, useCurationMetadata } from '../../../../common/hooks'

import { SET_DISH_TYPES_BY_MENU_TITLE_MUTATION } from '../../../../dishes/graphql'

const styles = {
  modal: { width: '520px' },
  select: { flex: 1 },
  container: { display: 'flex' },
  dropdown: { maxHeight: 400, overflow: 'auto' },
}

const IgnoredDishTypeModal = ({ menuTitle, onCancel, refetchBrand }) => {
  const { dishTypeOptions } = useCurationMetadata()
  const [setDishTypes] = useMutation(SET_DISH_TYPES_BY_MENU_TITLE_MUTATION)
  const { startLoading, stopLoading } = useAppLoading()

  const ignoredDishTypes = useMemo(() => {
    if (isEmpty(dishTypeOptions)) {
      return []
    }

    return filter(dishTypeOptions, 'isIgnored')
  }, [dishTypeOptions])

  const isRevertButtonDisabled = useMemo(
    () => isNil(menuTitle?.dishTypeId),
    [menuTitle?.dishTypeId],
  )

  const handleRevert = useCallback(async () => {
    try {
      startLoading()
      await setDishTypes({
        variables: { menuTitleId: menuTitle.id, dishTypeId: null },
      })
      await refetchBrand()
      onCancel()
    } catch (error) {
      handleMutationError(error)
    }
    stopLoading()
  }, [
    menuTitle?.id,
    onCancel,
    refetchBrand,
    setDishTypes,
    startLoading,
    stopLoading,
  ])

  const handleDishTypeSelect = useCallback(
    async value => {
      if (value === get(ignoredDishTypes, '[0].id')) {
        return
      }

      try {
        startLoading()
        await setDishTypes({
          variables: { menuTitleId: menuTitle.id, dishTypeId: value },
        })
        await refetchBrand()
        onCancel()
      } catch (error) {
        handleMutationError(error)
      }
      stopLoading()
    },
    [
      ignoredDishTypes,
      setDishTypes,
      menuTitle,
      startLoading,
      stopLoading,
      onCancel,
      refetchBrand,
    ],
  )

  if (isNil(menuTitle)) {
    return null
  }

  return (
    <Modal
      title="Set ignored dish type"
      centered
      width={styles.modal.width}
      visible
      footer={null}
      onCancel={onCancel}
    >
      <Row>
        <Col span={24} style={styles.container}>
          <TreeSelect
            style={styles.select}
            showSearch
            treeNodeFilterProp="title"
            defaultValue={toString(menuTitle.dishTypeId)}
            dropdownStyle={styles.dropdown}
            placeholder="Select dish type"
            treeData={ignoredDishTypes}
            onChange={handleDishTypeSelect}
          />
          <Button onClick={handleRevert} disabled={isRevertButtonDisabled}>
            Revert
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

IgnoredDishTypeModal.propTypes = {
  menuTitle: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  refetchBrand: PropTypes.func.isRequired,
}

IgnoredDishTypeModal.defaultProps = {
  menuTitle: null,
}

export default IgnoredDishTypeModal
