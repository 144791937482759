export default Object.freeze({
  SET_MENU_TITLE_NAME: 'SET_MENU_TITLE_NAME',
  SET_MENU_TITLE_DESCRIPTION: 'SET_MENU_TITLE_DESCRIPTION',
  SET_MENU_TITLES: 'SET_MENU_TITLES',
  ADD_MENU_TITLE_TO_PARENT: 'ADD_MENU_TITLE_TO_PARENT',
  DELETE_MENU_TITLE: 'DELETE_MENU_TITLE',
  ADD_PARENT_TO_MENU_TITLE: 'ADD_PARENT_TO_MENU_TITLE',
  SET_EXPANDED_ROW_KEYS: 'SET_EXPANDED_KEYS',
  SET_HIGHLIGHTED_ROW_KEY: 'SET_HIGHLIGHTED_KEY',
})
