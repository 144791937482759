import { join } from 'path'

import React from 'react'
import { useHistory } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import { APP_ROUTES, EVENT_TYPES } from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

const AddLocationToBrand = ({ brandId }) => {
  const history = useHistory()
  const onAddClicked = () => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.BRAND_LOCATION_EVENTS.CREATE_CLICK,
      {
        brandId,
      },
    )
    history.push(
      join(
        APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
        brandId,
        APP_ROUTES.LOCATION_ROUTES.NewLocation,
      ),
    )
  }

  return (
    <Button
      size="small"
      onClick={onAddClicked}
      icon={<PlusOutlined />}
      shape="circle"
    />
  )
}

AddLocationToBrand.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export default AddLocationToBrand
