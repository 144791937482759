import React from 'react'
import { isEmpty, map } from 'lodash'

import { stringIncludes } from '../../core/utils'

import { TreeSelectNodeTitle } from '../components/molecules'

const renderTreeSelectHighlightsForSearch = (options = [], searchValue = '') =>
  map(options, item => {
    const isTitleContainingSearchValue = stringIncludes(item.title, searchValue)

    return {
      ...item,
      ...(isTitleContainingSearchValue
        ? {
            title: (
              <TreeSelectNodeTitle
                title={item.title}
                isSticky={item.isSticky}
                isIgnored={item.isIgnoredItem}
                searchValue={searchValue}
              />
            ),
          }
        : {
            title: (
              <TreeSelectNodeTitle
                title={item.title}
                isSticky={item.isSticky}
                isIgnored={item.isIgnoredItem}
              />
            ),
          }),
      ...(!isEmpty(item.children) && {
        children: renderTreeSelectHighlightsForSearch(
          item.children,
          searchValue,
        ),
      }),
    }
  })

const handleTreeSelectCustomDropdownForSearch = origin => {
  const searchValue = origin?.props?.searchValue

  return {
    ...origin,
    props: {
      ...origin.props,
      options: renderTreeSelectHighlightsForSearch(
        origin.props.options,
        searchValue,
      ),
    },
  }
}

export default handleTreeSelectCustomDropdownForSearch
