import React, { memo, useMemo } from 'react'
import {
  Col,
  Collapse,
  Form,
  Row,
  Select,
  Tooltip,
  TreeSelect,
  Typography,
} from 'antd'
import { get, isEmpty, isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { useDocumentTitle, useQueryParams } from '../../../../core/hooks'
import {
  areSameValue,
  formatDate,
  selectFilterOption,
} from '../../../../core/utils'

import {
  DebouncedTextField,
  DescriptorsTable,
  HighlightingTextField,
  LabelLegends,
  ServicingHoursSelect,
} from '../../../../common/components'
import { useCachedBrand, useCurationMetadata } from '../../../../common/hooks'
import {
  findNodeBy,
  getMenuTitleOptions,
  getMenuTitlesWithParents,
} from '../../../../common/utils'

import { useBrandCurationMetadata } from '../../../../brands/hooks'
import { DISH_FIELDS } from '../../../constants'
import {
  useBrandDish,
  useDishFields,
  usePersistedDishField,
} from '../../../hooks'
import {
  setDishAllergens,
  setDishDescription,
  setDishDiets,
  setDishHighPrice,
  setDishIngredientsText,
  setDishLowPrice,
  setDishMenuTitle,
  setDishName,
  setDishServicingHoursIds,
} from '../../../redux'
import {
  DishLocationAvailabilitySelect,
  DishMenuTitleDetailsRows,
} from '../../molecules'
import { DishCategoriesSection } from '../DishCategoriesSection'
import { DishIngredientsSection } from '../DishIngredientsSection'

import './dishModalBody.css'

const styles = {
  modifiedText: { marginTop: 30 },
  translatedText: { color: '#434343' },
  inputMarginRight: { marginRight: '0.5rem' },
  priceText: { width: '3rem' },
  marginTop: {
    marginTop: '1rem',
  },
  smallMarginTop: {
    marginTop: '0.25rem',
  },
}

const DishModalBody = ({ highlights, disabled }) => {
  const { selectedDishId } = useQueryParams()
  const { dish } = useBrandDish(selectedDishId)
  useDocumentTitle(`Dish | ${get(dish, 'name') || 'New'}`)
  const brand = useCachedBrand()
  const { dietOptions, allergenOptions } = useCurationMetadata()
  const { menuTitleOptions: brandMenuTitleOptions } = useBrandCurationMetadata()

  const { shouldDisableField } = useDishFields(
    dish?.status,
    null,
    null,
    null,
    disabled,
  )
  const {
    servicingHours: brandServicingHours,
    dietLabelLegends,
    allergenLabelLegends,
    locations,
    diets: brandDiets,
  } = brand || {}

  const {
    styledName: persistedStyledName,
    onValueChanged: styledNameOnChange,
  } = usePersistedDishField('styledName', setDishName)

  const { lowPrice: persistedLowPrice, onValueChanged: lowPriceOnChange } =
    usePersistedDishField('lowPrice', setDishLowPrice)

  const { highPrice: persistedHighPrice, onValueChanged: highPriceOnChange } =
    usePersistedDishField('highPrice', setDishHighPrice)

  const {
    servicingHoursIds: persistedServicingHoursIds,
    onValueChanged: servicingHoursOnChange,
  } = usePersistedDishField('servicingHoursIds', setDishServicingHoursIds)

  const {
    styledDescription: persistedStyledDescription,
    onValueChanged: styledDescriptionOnChange,
  } = usePersistedDishField('styledDescription', setDishDescription)

  const {
    styledIngredientsText: persistedStyledIngredientsText,
    onValueChanged: styledIngredientsTextOnChange,
  } = usePersistedDishField('styledIngredientsText', setDishIngredientsText)

  const {
    menuTitleId: persistedMenuTitleId,
    onValueChanged: menuTitleOnChange,
  } = usePersistedDishField('menuTitleId', setDishMenuTitle)

  const { dietIds: persistedDietIds, onValueChanged: dietIdsOnChange } =
    usePersistedDishField('dietIds', setDishDiets)

  const {
    allergenIds: persistedAllergenIds,
    onValueChanged: allergenIdsOnChange,
  } = usePersistedDishField('allergenIds', setDishAllergens)

  const {
    published,
    currency,
    menuTitle,
    name,
    nameTranslation,
    diets,
    allergens,
    lowPrice,
    highPrice,
    servicingHours: dishServicingHours = [],
    description,
    descriptionTranslation,
    ingredientsText,
    ingredientsTextTranslation,
    styledName,
    styledDescription,
    styledIngredientsText,
    updatedAt,
    updatedBy,
    selectedLocations,
    dietDescriptors,
    allergenDescriptors,
    addonDescriptors,
    miscDescriptors,
    nutritionsDescriptors,
    curatedAt,
    qadAt,
    qqadAt,
    curatedBy,
    qadBy,
    qqadBy,
  } = dish

  const selectedMenuTitleId =
    persistedMenuTitleId === null
      ? null
      : persistedMenuTitleId || get(menuTitle, 'id')

  const selectedMenuTitle = useMemo(
    () =>
      selectedMenuTitleId
        ? findNodeBy(brandMenuTitleOptions, ({ id }) =>
            areSameValue(id, selectedMenuTitleId),
          )
        : undefined,

    [brandMenuTitleOptions, selectedMenuTitleId],
  )

  const menuTitleOptions = useMemo(
    () => getMenuTitleOptions(brandMenuTitleOptions),
    [brandMenuTitleOptions],
  )

  const menuTitlesWithParents = useMemo(
    () => getMenuTitlesWithParents(selectedMenuTitle, brandMenuTitleOptions),
    [brandMenuTitleOptions, selectedMenuTitle],
  )

  const dietIds = useMemo(
    () => persistedDietIds || map(diets || brandDiets, 'id'),
    [brandDiets, diets, persistedDietIds],
  )

  const allergenIds = useMemo(
    () => persistedAllergenIds || map(allergens, 'id'),
    [allergens, persistedAllergenIds],
  )

  return (
    <Form className="dish-modal-form">
      <Row gutter={8}>
        <GridFormItem smallLabel label="Name" span={6}>
          <HighlightingTextField
            value={styledName || name}
            newValue={persistedStyledName}
            highlightingList={get(highlights, DISH_FIELDS.NAME)}
            onValueChanged={styledNameOnChange}
            disabled={disabled}
          />
        </GridFormItem>
        {nameTranslation && (
          <Typography.Paragraph style={styles.translatedText}>
            Translated: {nameTranslation}
          </Typography.Paragraph>
        )}
        <GridFormItem
          smallLabel
          span={2}
          label={`Price (Low/High) ${currency || '£'}`}
        >
          <DebouncedTextField
            size="small"
            style={{ ...styles.inputMarginRight, ...styles.priceText }}
            text={lowPrice}
            newText={persistedLowPrice}
            inputType="float"
            onValueChanged={lowPriceOnChange}
            disabled={disabled}
          />
          <DebouncedTextField
            size="small"
            style={{ ...styles.priceText }}
            text={highPrice}
            newText={persistedHighPrice}
            inputType="float"
            onValueChanged={highPriceOnChange}
            disabled={disabled}
          />
        </GridFormItem>
        <GridFormItem smallLabel span={4} label="Serving Time">
          <ServicingHoursSelect
            size="small"
            servicingHours={dishServicingHours}
            newServicingHoursIds={persistedServicingHoursIds}
            onValueChanged={servicingHoursOnChange}
            parentServicingHours={brandServicingHours}
            disabled={disabled}
          />
        </GridFormItem>
        <GridFormItem smallLabel span={3} label="Menu Title">
          <Tooltip
            title={get(
              findNodeBy(menuTitleOptions, ({ id }) =>
                areSameValue(id, selectedMenuTitleId),
              ),
              'namePath',
            )}
          >
            <TreeSelect
              disabled={disabled}
              allowClear
              size="small"
              treeData={menuTitleOptions}
              treeNodeFilterProp="name"
              value={selectedMenuTitleId}
              onChange={menuTitleOnChange}
              dropdownMatchSelectWidth={false}
              showSearch
              placeholder="Assign a Menu Title..."
            />
          </Tooltip>
        </GridFormItem>
        <Tooltip
          title={
            !published
              ? 'Dish needs to be published in order to selected locations'
              : undefined
          }
        >
          <GridFormItem smallLabel span={3} label="Available locations">
            <DishLocationAvailabilitySelect
              size="small"
              disabled={
                !published || shouldDisableField(DISH_FIELDS.SELECTED_LOCATIONS)
              }
              options={locations}
              defaultValue={selectedLocations}
            />
          </GridFormItem>
        </Tooltip>
        <GridFormItem smallLabel span={6} label="Menu Diet Labels">
          <Select
            disabled={shouldDisableField(DISH_FIELDS.DIETS)}
            mode="multiple"
            value={dietIds}
            onChange={dietIdsOnChange}
            allowClear
            optionFilterProp="children"
            filterOption={selectFilterOption}
            size="small"
            options={dietOptions}
          />
        </GridFormItem>
      </Row>
      <Row gutter={16} style={styles.smallMarginTop}>
        <Col span={18}>
          <DishMenuTitleDetailsRows
            menuTitles={menuTitlesWithParents}
            highlights={highlights}
          />
        </Col>

        <GridFormItem smallLabel span={6} label="Allergen Labels">
          <Select
            disabled={shouldDisableField(DISH_FIELDS.ALLERGENS)}
            mode="multiple"
            value={allergenIds}
            onChange={allergenIdsOnChange}
            allowClear
            optionFilterProp="children"
            filterOption={selectFilterOption}
            size="small"
            options={allergenOptions}
          />
        </GridFormItem>
      </Row>

      <DishCategoriesSection dish={dish} disabled={disabled} />
      <Row gutter={16} style={styles.marginTop}>
        <GridFormItem smallLabel label="Description" span={8}>
          <HighlightingTextField
            disabled={disabled}
            value={styledDescription || description}
            newValue={persistedStyledDescription}
            highlightingList={get(highlights, DISH_FIELDS.DESCRIPTION)}
            onValueChanged={styledDescriptionOnChange}
          />
          {descriptionTranslation && (
            <Typography.Paragraph style={styles.translatedText}>
              Translated: {descriptionTranslation}
            </Typography.Paragraph>
          )}
        </GridFormItem>

        <GridFormItem smallLabel label="Ingredients Free Text" span={8}>
          <HighlightingTextField
            disabled={disabled}
            value={styledIngredientsText || ingredientsText}
            newValue={persistedStyledIngredientsText}
            highlightingList={get(highlights, DISH_FIELDS.INGREDIENTS_TEXT)}
            onValueChanged={styledIngredientsTextOnChange}
          />

          {ingredientsTextTranslation && (
            <Typography.Paragraph style={styles.translatedText}>
              Translated: {ingredientsTextTranslation}
            </Typography.Paragraph>
          )}
        </GridFormItem>

        {!isEmpty(dietLabelLegends) && (
          <GridFormItem smallLabel span={4}>
            <Collapse ghost>
              <Collapse.Panel
                header={`Diet label legend (${dietLabelLegends.length})`}
              >
                <LabelLegends
                  labelLegends={dietLabelLegends}
                  options={dietOptions}
                  disabled
                  size="small"
                  showAddButton={false}
                  forEntity="diet"
                />
              </Collapse.Panel>
            </Collapse>
          </GridFormItem>
        )}
        {!isEmpty(allergenLabelLegends) && (
          <GridFormItem smallLabel span={4}>
            <Collapse ghost>
              <Collapse.Panel
                header={`Allergen label legend (${allergenLabelLegends.length})`}
              >
                <LabelLegends
                  labelLegends={allergenLabelLegends}
                  options={allergenOptions}
                  disabled
                  size="small"
                  showAddButton={false}
                  forEntity="allergen"
                />
              </Collapse.Panel>
            </Collapse>
          </GridFormItem>
        )}
      </Row>
      <DescriptorsTable
        dietDescriptors={dietDescriptors}
        addonDescriptors={addonDescriptors}
        miscDescriptors={miscDescriptors}
        highlightWords={highlights}
        allergenDescriptors={allergenDescriptors}
      />
      <DishIngredientsSection disabled={disabled} />
      <DescriptorsTable nutritionsDescriptors={nutritionsDescriptors} />
      {!isNil(updatedAt) && (
        <div style={styles.modifiedText}>
          <Row>
            <Col>
              <Typography.Text>{`Modified: ${formatDate(updatedAt)}${
                updatedBy ? ` by ${updatedBy.email}` : ''
              }`}</Typography.Text>
            </Col>
          </Row>
          {!isNil(curatedAt) && !isNil(curatedAt) && (
            <Row>
              <Col>
                <Typography.Text>{`Curated: ${formatDate(curatedAt)}${
                  curatedBy ? ` by ${curatedBy.email}` : ''
                }`}</Typography.Text>
              </Col>
            </Row>
          )}
          {!isNil(qadAt) && !isNil(qadAt) && (
            <Row>
              <Col>
                <Typography.Text>{`Qad: ${formatDate(qadAt)}${
                  qadBy ? ` by ${qadBy.email}` : ''
                }`}</Typography.Text>
              </Col>
            </Row>
          )}
          {!isNil(qqadAt) && !isNil(qqadAt) && (
            <Row>
              <Col>
                <Typography.Text>{`QQad: ${formatDate(qqadAt)}${
                  qqadBy ? ` by ${qqadBy.email}` : ''
                }`}</Typography.Text>
              </Col>
            </Row>
          )}
        </div>
      )}
    </Form>
  )
}

DishModalBody.propTypes = {
  highlights: PropTypes.object,
  disabled: PropTypes.bool,
}

DishModalBody.defaultProps = {
  highlights: {},
  disabled: false,
}

export default memo(DishModalBody)
