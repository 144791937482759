import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { removeLocation as removeLocationAction } from '../redux'

export default function useUnsavedLocation(locationId) {
  const dispatch = useDispatch()
  const removeLocation = useCallback(
    () => dispatch(removeLocationAction(locationId)),
    [dispatch, locationId],
  )

  const unsavedLocation = useSelector(({ unsaved: { locations } }) =>
    locations.find(r => r.id === locationId),
  )
  const unsavedLocationDishes = useSelector(({ unsaved: { locationDishes } }) =>
    locationDishes.filter(d => d.locationId === locationId),
  )

  const location = useMemo(() => {
    if (!unsavedLocation && !unsavedLocationDishes.length) {
      return null
    }
    return { ...unsavedLocation, id: locationId, dishes: unsavedLocationDishes }
  }, [locationId, unsavedLocation, unsavedLocationDishes])

  return { location, removeLocation }
}
