import React from 'react'
import { MinusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import { DaysOfWeek, TimeSlot } from '../../../../common/components/molecules'
import { servicingHourPropType } from '../../../../common/propTypes'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  removeButton: {
    marginLeft: 5,
  },
}

const WorkScheduleHour = ({ scheduleHour, onValueChanged, disabled }) => {
  const { startDayOfWeek, endDayOfWeek, startTime, endTime } = scheduleHour

  return (
    <div style={styles.container}>
      <DaysOfWeek
        disabled={disabled}
        startDayOfWeek={startDayOfWeek}
        endDayOfWeek={endDayOfWeek}
        onValueChanged={({
          startDayOfWeek: newStartDayOfWeek,
          endDayOfWeek: newEndDayOfWeek,
        }) => {
          onValueChanged({
            ...scheduleHour,
            startDayOfWeek: newStartDayOfWeek,
            endDayOfWeek: newEndDayOfWeek,
          })
        }}
      />
      <TimeSlot
        disabled={disabled}
        openTime={startTime}
        closeTime={endTime}
        onValueChanged={({ openTime: newStartTime, closeTime: newEndTime }) => {
          onValueChanged({
            ...scheduleHour,
            startTime: newStartTime,
            endTime: newEndTime,
          })
        }}
      />
      <Button
        icon={<MinusOutlined />}
        shape="circle"
        style={styles.removeButton}
        disabled={disabled}
        onClick={() => {
          onValueChanged({ ...scheduleHour, deleted: true })
        }}
      />
    </div>
  )
}

WorkScheduleHour.propTypes = {
  scheduleHour: servicingHourPropType.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default WorkScheduleHour
