import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { PrivateRoute } from '../../common/components'
import { APP_ROUTES, PERMISSIONS } from '../../common/constants'
import { usePageView } from '../../common/hooks'

import { AccountRouter } from '../../account/components'
import { AiSettingsRouter } from '../../aiSettings/components'
import { BrandsRouter } from '../../brands/components'
import { BrandLocationList } from '../../brands/components/pages'
import { CleanupTasksRouter } from '../../cleanupTasks/components'
import { IngredientsRerunRouter } from '../../ingredientsRerun/components'
import { ReportsRouter } from '../../reports/components'
import { UserRouter } from '../../users/components'

const MainRouter = () => {
  usePageView()

  return (
    <Switch>
      <Redirect
        exact
        from={APP_ROUTES.BASE_ROUTES.Root}
        to={APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations}
      />
      <Route
        path={APP_ROUTES.ACCOUNT_ROUTES.Account}
        component={AccountRouter}
      />

      <PrivateRoute
        exact
        path={APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations}
        Component={BrandLocationList}
      />
      <PrivateRoute
        path={APP_ROUTES.BRAND_ROUTES.BrandsPrefix}
        Component={BrandsRouter}
      />
      <Route path={APP_ROUTES.USER_ROUTES.Users} component={UserRouter} />
      <PrivateRoute
        exact
        path={APP_ROUTES.REPORT_ROUTES.Reports}
        permissions={[PERMISSIONS.REPORTS.LIST]}
        Component={ReportsRouter}
      />
      <PrivateRoute
        path={APP_ROUTES.CLEANUP_TASK_ROUTES.CleanupTasks}
        permissions={[PERMISSIONS.TASKS.LIST]}
        Component={CleanupTasksRouter}
      />
      <PrivateRoute
        path={APP_ROUTES.AI_SETTINGS_ROUTES.AiSettings}
        permissions={[PERMISSIONS.AI_SETTINGS.LIST]}
        Component={AiSettingsRouter}
      />
      <PrivateRoute
        path={APP_ROUTES.AI_SETTINGS_ROUTES.AiSettings}
        permissions={[PERMISSIONS.AI_SETTINGS.LIST]}
        Component={AiSettingsRouter}
      />
      <PrivateRoute
        path={APP_ROUTES.INGREDIENTS_RERUN_ROUTES.IngredientsRerun}
        permissions={[PERMISSIONS.INGREDIENTS_RERUN.LIST]}
        Component={IngredientsRerunRouter}
      />
      <Redirect
        from="/"
        to={APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations}
      />
    </Switch>
  )
}

export default MainRouter
