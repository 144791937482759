import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import {
  aiSuggestionReducer,
  appReducer,
  unsavedReducer,
} from '../../common/redux/reducers'

const initialState = {
  app: {
    activeNames: {},
    isLoading: false,
  },
  unsaved: {
    locations: [],
    locationDishes: [],
    unsavedMenuTitles: {
      menuTitles: {},
      state: {
        isDirty: false,
        isError: false,
      },
      expandedRowKeys: [],
    },
  },
  aiSuggestions: {},
}

const persistConfig = {
  key: 'app',
  storage,
  blacklist: ['app', 'unsaved'],
  stateReconciler: autoMergeLevel2,
}

const unsavedConfig = {
  key: 'unsaved',
  storage,
  blacklist: ['unsavedMenuTitles'],
  stateReconciler: autoMergeLevel2,
}

const rootReducer = combineReducers({
  app: appReducer,
  unsaved: persistReducer(unsavedConfig, unsavedReducer),
  aiSuggestions: aiSuggestionReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

let enhance = enhancer => enhancer

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  const { composeWithDevTools } = require('redux-devtools-extension')
  enhance = composeWithDevTools
}

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    initialState,
    enhance(applyMiddleware()),
  )
  const persistor = persistStore(store)
  return { store, persistor }
}

export default configureStore
