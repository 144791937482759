import React, { useMemo } from 'react'
import { includes } from 'lodash'
import PropTypes from 'prop-types'

import { CURATION_STATUS } from '../../../../common/constants'

import {
  BRAND_ASSIGNED_USER_ACTIONS,
  BRAND_UNASSIGNED_USER_FILTER_VALUE,
} from '../../../constants'
import { brandPropType } from '../../../propTypes'
import { BrandUserAssignment } from '../../molecules'

const BrandTableAssignation = ({
  brandOrLocation,
  users,
  assignedCuratorFilter,
  assignedQaFilter,
}) => {
  const {
    id,
    assignedCurator,
    assignedQa,
    assignedQQa,
    status,
    assignedUnknownWordsCurator,
    assignedUnknownWordsQa,
  } = brandOrLocation

  const alreadyAssignedUsersDuringUnknownWordsCuration = useMemo(
    () => [assignedUnknownWordsQa],
    [assignedUnknownWordsQa],
  )

  const alreadyAssignedUsersDuringUnknownWordsQa = useMemo(
    () => [assignedUnknownWordsCurator],
    [assignedUnknownWordsCurator],
  )

  const alreadyAssignedUsersDuringCuration = useMemo(
    () => [assignedQQa, assignedQa],
    [assignedQQa, assignedQa],
  )

  const alreadyAssignedUsersDuringQa = useMemo(
    () => [assignedQQa, assignedCurator],
    [assignedQQa, assignedCurator],
  )

  const alreadyAssignedUsersDuringQQa = useMemo(
    () => [assignedCurator, assignedQa],
    [assignedCurator, assignedQa],
  )

  const isUnknownWordsAssignmentShown = useMemo(
    () =>
      includes(
        [
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
        ],
        status,
      ) ||
      assignedCuratorFilter !== BRAND_UNASSIGNED_USER_FILTER_VALUE ||
      assignedQaFilter !== BRAND_UNASSIGNED_USER_FILTER_VALUE,
    [status, assignedCuratorFilter, assignedQaFilter],
  )

  const isQqaAssignmentShown = useMemo(
    () => status === CURATION_STATUS.QQA_CONFIRMATION.value,
    [status],
  )

  return (
    <>
      {isUnknownWordsAssignmentShown && (
        <>
          <BrandUserAssignment
            tooltip="U.W. Curator"
            brandId={id}
            assignedUser={assignedUnknownWordsCurator}
            alreadyAssignedUsers={
              alreadyAssignedUsersDuringUnknownWordsCuration
            }
            availableUsers={users}
            assignedUserAction={
              BRAND_ASSIGNED_USER_ACTIONS.UNKNOWN_WORDS_CURATION
            }
          />

          <BrandUserAssignment
            brandId={id}
            assignedUser={assignedUnknownWordsQa}
            alreadyAssignedUsers={alreadyAssignedUsersDuringUnknownWordsQa}
            availableUsers={users}
            assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.UNKNOWN_WORDS_QA}
          />
        </>
      )}

      <BrandUserAssignment
        brandId={id}
        alreadyAssignedUsers={alreadyAssignedUsersDuringCuration}
        assignedUser={assignedCurator}
        availableUsers={users}
        assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.CURATION}
      />
      <BrandUserAssignment
        brandId={id}
        assignedUser={assignedQa}
        alreadyAssignedUsers={alreadyAssignedUsersDuringQa}
        availableUsers={users}
        assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.QA}
      />
      {isQqaAssignmentShown && (
        <BrandUserAssignment
          brandId={id}
          assignedUser={assignedQQa}
          alreadyAssignedUsers={alreadyAssignedUsersDuringQQa}
          availableUsers={users}
          assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.QQA}
        />
      )}
    </>
  )
}

BrandTableAssignation.propTypes = {
  brandOrLocation: brandPropType.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  assignedCuratorFilter: PropTypes.string,
  assignedQaFilter: PropTypes.string,
}

BrandTableAssignation.defaultProps = {
  assignedCuratorFilter: undefined,
  assignedQaFilter: undefined,
}

export default BrandTableAssignation
