import React, { useMemo } from 'react'
import { Table, Tooltip, Typography } from 'antd'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'

import { DateFromNow } from '../../../../core/components/atoms'
import { formatDate } from '../../../../core/utils'

import { METADATA_TYPES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'
import { joinMetadataTitlesByIds } from '../../../../common/utils'

import { ML_CLEANUP_TASK_REQUEST_STATUS } from '../../../constants'

const styles = {
  Error: {
    color: '#ff4d4f',
  },
}

const MlCleanupTaskRequestsTable = ({ data, loading }) => {
  const { dishTypeOptions, courseTypeOptions } = useCurationMetadata()

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
    }),
    [dishTypeOptions, courseTypeOptions],
  )

  const tableColumns = useMemo(
    () => [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Metadata type',
        dataIndex: 'metadataType',
        key: 'metadataType',
      },
      {
        title: 'Metadata items',
        render: ({ metadataType, dishTypeIdsParam, courseTypeIdsParam }) => {
          if (metadataType === METADATA_TYPES.DISH_TYPES) {
            return joinMetadataTitlesByIds(
              dishTypeIdsParam,
              curationMetadata[metadataType],
            )
          }
          return joinMetadataTitlesByIds(
            courseTypeIdsParam,
            curationMetadata[metadataType],
          )
        },
      },
      {
        title: 'Start date',
        render: ({ startDateParam }) =>
          !isNil(startDateParam) ? formatDate(startDateParam) : '',
      },
      {
        title: 'End date',
        render: ({ endDateParam }) =>
          !isNil(endDateParam) ? formatDate(endDateParam) : '',
      },
      {
        title: 'Created at',
        // eslint-disable-next-line react/prop-types
        render: ({ createdAt }) => <DateFromNow date={createdAt} />,
      },
      {
        title: 'Status',
        // eslint-disable-next-line react/prop-types
        render: ({ status, message }) => {
          if (status === ML_CLEANUP_TASK_REQUEST_STATUS.FAILED) {
            return (
              <Tooltip title={message}>
                <Typography.Text style={styles.Error}>{status}</Typography.Text>
              </Tooltip>
            )
          }

          return status
        },
      },
    ],
    [curationMetadata],
  )

  return (
    <Table
      loading={loading}
      rowKey={record => record.id}
      columns={tableColumns}
      dataSource={data}
      size="small"
    />
  )
}

MlCleanupTaskRequestsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
}

MlCleanupTaskRequestsTable.defaultProps = {
  loading: false,
}

export default MlCleanupTaskRequestsTable
