const USER_QUALIFICATION = {
  SCRAPING: ['SCRAPING_EXPERT', 'SQA'],
  DEVELOPMENT: [
    'AI',
    'SCRAPING',
    'CURATOR',
    'METADATA',
    'AUTOEAT',
    'MOBILE_APP',
  ],
  CURATION: ['NUTRITIONIST_DIETICIAN', 'FOOD_SCIENTIST', 'CHEF', 'OTHER'],
}

export default USER_QUALIFICATION
