import { gql } from '@apollo/client'

export default gql`
  mutation setDishValidated($ids: [ID]!, $value: Boolean!) {
    setDishValidated(ids: $ids, value: $value) {
      id
      isValidated
    }
  }
`
