import React, { useCallback, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

const ImageUpload = ({
  setPreviewImage,
  setIsPreviewingImage,
  imageUrl,
  handleUpload,
  handleDownload,
  handleRemove,
  disabled,
}) => {
  const [fileList, setFileList] = useState([])

  if (imageUrl && isEmpty(fileList)) {
    setFileList([{ uid: '0', status: 'done', url: imageUrl }])
  }

  if (imageUrl === null && !isEmpty(fileList)) {
    setFileList([])
  }

  const handleChange = useCallback(({ list }) => setFileList(list), [])

  const handlePreview = useCallback(() => {
    setPreviewImage(imageUrl)
    setIsPreviewingImage(true)
  }, [setIsPreviewingImage, setPreviewImage, imageUrl])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  )

  return (
    <div>
      <Upload
        disabled={disabled}
        accept="image/*"
        name="image"
        listType="picture-card"
        fileList={fileList || []}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={handleUpload}
        onRemove={handleRemove}
        onDownload={handleDownload}
      >
        {!isEmpty(fileList) ? null : uploadButton}
      </Upload>
    </div>
  )
}

ImageUpload.propTypes = {
  setPreviewImage: PropTypes.func.isRequired,
  setIsPreviewingImage: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  handleUpload: PropTypes.func.isRequired,
  handleDownload: PropTypes.func,
  handleRemove: PropTypes.func,
  disabled: PropTypes.bool,
}

ImageUpload.defaultProps = {
  imageUrl: null,
  handleDownload: undefined,
  handleRemove: undefined,
  disabled: false,
}

export default ImageUpload
