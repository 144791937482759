import { EditorState } from 'draft-js'
import { filter, get, includes, isEmpty, isNil, map, some, uniq } from 'lodash'
import { set as setIn } from 'lodash/fp'
import shortid from 'shortid'

import { EVENT_TYPES } from '../../../common/constants'
import { googleAnalyticsEventsService } from '../../../common/services'
import { flattenTreeStructure } from '../../../common/utils'

import { updateChildrenPath } from '../../utils'
import { UNSAVED_MENU_TITLE_ACTION_TYPES } from '../actionTypes'

const updateMenuTitleProp = (menuTitles, path, propName, value) => {
  googleAnalyticsEventsService.fireEvent(
    EVENT_TYPES.BRAND_EVENTS.MENU_TITLES_CHANGE,
    { input: propName, value },
  )

  return setIn(`${path}.${propName}`, value, menuTitles)
}

const unsavedMenuTitleReducer = (unsavedMenuTitles = {}, action) => {
  switch (action.type) {
    case UNSAVED_MENU_TITLE_ACTION_TYPES.SET_EXPANDED_ROW_KEYS:
      return {
        ...unsavedMenuTitles,
        expandedRowKeys: action.payload,
      }
    case UNSAVED_MENU_TITLE_ACTION_TYPES.SET_HIGHLIGHTED_ROW_KEY:
      return {
        ...unsavedMenuTitles,
        highlightedRowKey: action.payload,
      }
    case UNSAVED_MENU_TITLE_ACTION_TYPES.SET_MENU_TITLES: {
      const { menuTitles, state } = action.payload
      return {
        ...unsavedMenuTitles,
        menuTitles,
        state,
      }
    }
    case UNSAVED_MENU_TITLE_ACTION_TYPES.SET_MENU_TITLE_NAME: {
      const { path, value } = action.payload
      const isExistingError = some(
        flattenTreeStructure(unsavedMenuTitles.menuTitles),
        ({ name, path: cPath }) =>
          cPath === path
            ? isEmpty(value?.getCurrentContent().getPlainText())
            : isEmpty(name?.getCurrentContent().getPlainText()),
      )

      return {
        ...unsavedMenuTitles,
        menuTitles: updateMenuTitleProp(
          unsavedMenuTitles.menuTitles,
          path,
          'name',
          value,
        ),
        state: {
          ...unsavedMenuTitles.state,
          isDirty: true,
          isError:
            isExistingError ||
            (value && isEmpty(value.getCurrentContent().getPlainText())),
        },
      }
    }

    case UNSAVED_MENU_TITLE_ACTION_TYPES.SET_MENU_TITLE_DESCRIPTION: {
      const { path, value } = action.payload
      return {
        ...unsavedMenuTitles,
        menuTitles: updateMenuTitleProp(
          unsavedMenuTitles.menuTitles,
          path,
          'styledDescription',
          value,
        ),
        state: {
          ...unsavedMenuTitles.state,
          isDirty: true,
        },
      }
    }

    case UNSAVED_MENU_TITLE_ACTION_TYPES.ADD_MENU_TITLE_TO_PARENT: {
      const { parentId, parentPath, children, parentDepth } = action.payload
      const newItem = {
        id: shortid.generate(),
        name: EditorState.createEmpty(),
        parentId,
        styledDescription: EditorState.createEmpty(),
        path: `${parentPath ? `${parentPath}.` : ''}children[${
          !isEmpty(children) ? children.length : 0
        }]`,
        depth: parentDepth + 1,
      }

      const parentToUpdate = parentPath
        ? get(unsavedMenuTitles.menuTitles, parentPath)
        : unsavedMenuTitles.menuTitles

      parentToUpdate.children = [...(parentToUpdate.children || []), newItem]
      parentToUpdate.isChildrenDeleted = false

      const updatedMenuTitleDataSource = parentPath
        ? setIn(parentPath, parentToUpdate, unsavedMenuTitles.menuTitles)
        : parentToUpdate

      return {
        ...unsavedMenuTitles,
        menuTitles: updatedMenuTitleDataSource,
        state: {
          ...unsavedMenuTitles.state,
          isDirty: true,
          isError: true,
        },
        expandedRowKeys: uniq([
          ...unsavedMenuTitles.expandedRowKeys,
          `menuTitle-${parentId}`,
        ]),
      }
    }
    case UNSAVED_MENU_TITLE_ACTION_TYPES.DELETE_MENU_TITLE: {
      const { id: idToRemove, path } = action.payload
      const parentPath = `${
        includes(path, '.') ? `${path.substr(0, path.lastIndexOf('.'))}` : ''
      }`
      const parentPathWithChildren = `${
        parentPath ? `${parentPath}.` : ''
      }children`

      const arrayToUpdate = get(
        unsavedMenuTitles.menuTitles,
        parentPathWithChildren,
      )
      const filteredArray = filter(arrayToUpdate, ({ id }) => idToRemove !== id)

      const updatedArray = !isEmpty(filteredArray)
        ? map(filteredArray, (item, index) =>
            !isEmpty(item.children)
              ? {
                  ...item,
                  path: `${parentPathWithChildren}[${index}]`,
                  children: updateChildrenPath(
                    item,
                    `${parentPathWithChildren}[${index}]`,
                  ),
                }
              : {
                  ...item,
                  path: `${parentPathWithChildren}[${index}]`,
                },
          )
        : []
      const newDataSource = setIn(
        parentPathWithChildren,
        updatedArray,
        unsavedMenuTitles.menuTitles,
      )

      const updatedDataSourceWithDeletedChildren = isEmpty(updatedArray)
        ? setIn(
            `${parentPath ? `${parentPath}.` : ''}isChildrenDeleted`,
            true,
            newDataSource,
          )
        : newDataSource

      return {
        ...unsavedMenuTitles,
        menuTitles: updatedDataSourceWithDeletedChildren,
        state: {
          ...unsavedMenuTitles.state,
          isDirty: true,
        },
        expandedRowKeys: filter(
          unsavedMenuTitles.expandedRowKeys,
          rowKey => rowKey !== `menuTitle-${idToRemove}`,
        ),
      }
    }

    case UNSAVED_MENU_TITLE_ACTION_TYPES.ADD_PARENT_TO_MENU_TITLE: {
      const { newId, menuTitle } = action.payload

      const { parentId, children, path, depth } = menuTitle
      const newItem = {
        id: newId,
        name: EditorState.createEmpty(),
        parentId,
        styledDescription: EditorState.createEmpty(),
        path,
        depth,
        children: [
          isNil(children)
            ? {
                ...menuTitle,
                parentId: newId,
                depth: depth + 1,
                path: `${path}.children[0]`,
              }
            : {
                ...menuTitle,
                parentId: newId,
                depth: depth + 1,
                path: `${path}.children[0]`,
                children: updateChildrenPath(menuTitle, `${path}.children[0]`),
              },
        ],
      }

      const updatedMenuTitleDataSource = setIn(
        path,
        newItem,
        unsavedMenuTitles.menuTitles,
      )

      return {
        ...unsavedMenuTitles,
        menuTitles: updatedMenuTitleDataSource,
        state: {
          ...unsavedMenuTitles.state,
          isDirty: true,
          isError: true,
        },
        expandedRowKeys: [
          ...unsavedMenuTitles.expandedRowKeys,
          `menuTitle-${newId}`,
        ],
      }
    }

    default: {
      return unsavedMenuTitles
    }
  }
}

export default unsavedMenuTitleReducer
