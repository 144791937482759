import { createPayloadAction } from '../../../common/utils'

import { UNSAVED_MENU_TITLE_ACTION_TYPES } from '../actionTypes'

export const setMenuTitleName = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.SET_MENU_TITLE_NAME,
)

export const setMenuTitleDescription = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.SET_MENU_TITLE_DESCRIPTION,
)

export const setMenuTitles = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.SET_MENU_TITLES,
)
export const addMenuTitleToParent = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.ADD_MENU_TITLE_TO_PARENT,
)
export const deleteMenuTitle = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.DELETE_MENU_TITLE,
)
export const addParentToMenuTitle = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.ADD_PARENT_TO_MENU_TITLE,
)
export const setExpandedRowKeys = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.SET_EXPANDED_ROW_KEYS,
)
export const setHighlightedRowKey = createPayloadAction(
  UNSAVED_MENU_TITLE_ACTION_TYPES.SET_HIGHLIGHTED_ROW_KEY,
)
