import { gql } from '@apollo/client'

const UPDATE_ML_CLEANUP_CURATION_VALUES_MUTATION = gql`
  mutation updateMlCleanupCurationValues(
    $ids: [ID]!
    $metadataType: String!
    $curationValues: MlCleanupCurationValuesInput!
  ) {
    updateMlCleanupCurationValues(
      ids: $ids
      metadataType: $metadataType
      curationValues: $curationValues
    ) {
      ... on DishTypeMlCleanup {
        id
        otherDishTypeId
        choice
      }

      ... on CourseTypeMlCleanup {
        id
        otherCourseTypeId
        choice
      }
    }
  }
`

export default UPDATE_ML_CLEANUP_CURATION_VALUES_MUTATION
