import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Col, notification, Row, Typography } from 'antd'

import { PageWithHeader } from '../../../../common/components'
import { handleMutationError } from '../../../../common/helpers'

import {
  CREATE_ML_CLEANUP_TASK_MUTATION,
  ML_CLEANUP_TASK_REQUESTS_QUERY,
} from '../../../graphql'
import { mlCleanupTaskValidationSchema } from '../../../schemas'
import { mlCleanupTaskTransformer } from '../../../transformers'
import { MlCleanupTaskImportButton } from '../../molecules'
import { MlCleanupTaskRequestsTable, MlTaskForm } from '../../sections'

const styles = {
  pageWrapper: {
    padding: '1rem',
  },
  tableContainer: {
    marginTop: 16,
  },
}

const CreateMlTask = () => {
  const { formState, handleSubmit, reset, ...formProps } = useForm({
    resolver: yupResolver(mlCleanupTaskValidationSchema),
  })

  const [createMlCleanupTask, { loading }] = useMutation(
    CREATE_ML_CLEANUP_TASK_MUTATION,
    {
      onError: handleMutationError,
      refetchQueries: [{ query: ML_CLEANUP_TASK_REQUESTS_QUERY }],
      onCompleted: async () => {
        reset()
        notification.success({
          message: 'Task created',
          description: `ML task request successfully created`,
          placement: 'topLeft',
        })
      },
    },
  )

  const {
    loading: mlCleanupRequestsLoading,
    data: { mlCleanupTaskRequests = [] } = {},
  } = useQuery(ML_CLEANUP_TASK_REQUESTS_QUERY)

  const handleCreate = useCallback(
    formValues => {
      const input = mlCleanupTaskTransformer.transformTaskToDto(formValues)
      createMlCleanupTask({ variables: { input } })
    },
    [createMlCleanupTask],
  )

  return (
    <FormProvider
      {...formProps}
      reset={reset}
      formState={formState}
      handleSubmit={handleSubmit}
    >
      <PageWithHeader>
        <div style={styles.pageWrapper}>
          <Row>
            <Col span={24}>
              <Typography.Title level={3}>Create ML task</Typography.Title>
            </Col>
          </Row>
          <MlTaskForm />
          <Row>
            <Col span={24}>
              <Button onClick={handleSubmit(handleCreate)} loading={loading}>
                Create
              </Button>
              <MlCleanupTaskImportButton />
            </Col>
          </Row>
          <Row style={styles.tableContainer}>
            <Col span={24}>
              <MlCleanupTaskRequestsTable
                data={mlCleanupTaskRequests}
                loading={mlCleanupRequestsLoading}
              />
            </Col>
          </Row>
        </div>
      </PageWithHeader>
    </FormProvider>
  )
}

export default CreateMlTask
