import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../../../core/hooks'

import { DebouncedRichTextInput } from '../../../../common/components'

import { setMenuTitleName } from '../../../redux'

const MenuTitleNameEditableCell = ({ dataIndex, record, disabled }) => {
  const dispatch = useDispatch()

  const persistedValue = useShallowEqualSelector(({ unsaved }) =>
    get(
      unsaved,
      `unsavedMenuTitles.menuTitles${
        !isEmpty(record.path) ? `.${record.path}` : ''
      }.${dataIndex}`,
    ),
  )

  const save = useCallback(
    value => {
      dispatch(
        setMenuTitleName({
          path: record.path,
          value,
        }),
      )
    },
    [dispatch, record.path],
  )

  const placeholder =
    !disabled &&
    persistedValue &&
    isEmpty(persistedValue.getCurrentContent().getPlainText())
      ? 'Enter a name...'
      : undefined

  return (
    <DebouncedRichTextInput
      defaultValue={record[dataIndex]}
      value={persistedValue}
      onValueChanged={save}
      disabled={disabled}
      placeholder={placeholder}
      validateStatus={
        persistedValue &&
        isEmpty(persistedValue.getCurrentContent().getPlainText())
          ? 'error'
          : undefined
      }
      help={
        persistedValue &&
        isEmpty(persistedValue.getCurrentContent().getPlainText())
          ? 'Name is Required'
          : undefined
      }
      disableRichText
    />
  )
}

MenuTitleNameEditableCell.propTypes = {
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

MenuTitleNameEditableCell.defaultProps = {
  disabled: false,
}

export default memo(MenuTitleNameEditableCell)
