import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Col, Row, Typography } from 'antd'
import { map } from 'lodash'

import { Select } from '../../../../core/components'
import { useDocumentTitle } from '../../../../core/hooks'

import { PageWithHeader, SaveButton } from '../../../../common/components'
import {
  DEFAULT_PAGINATION_CONFIG,
  MENU_ENTRY,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAuthentication } from '../../../../common/hooks'
import { sessionManager } from '../../../../common/services'
import { decodeToken } from '../../../../common/utils'

import { UPDATE_USER_SETTINGS_MUTATION } from '../../../graphql'

import userSettingsPageStyles from './userSettingsPageStyles'

const UserSettingsPage = () => {
  useDocumentTitle('User Settings')
  const {
    userInfo: { id, settings },
    setUserInfo,
  } = useAuthentication()

  const { formState, handleSubmit, reset, ...formProps } = useForm({
    defaultValues: {
      pageSize: settings.pageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    },
  })
  const [updateUserSettings, { loading }] = useMutation(
    UPDATE_USER_SETTINGS_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: ({ updateUserSettings: { accessToken, refreshToken } }) => {
        const user = decodeToken(accessToken)
        user.id = user.id.toString()
        sessionManager.createSession(
          user,
          accessToken,
          refreshToken,
          sessionManager.getGoogleAccessToken(),
        )
        setUserInfo(user)
        reset({ pageSize: user.settings.pageSize })
      },
    },
  )

  const { isDirty } = formState

  const handleSave = useCallback(
    values => {
      updateUserSettings({ variables: { userId: id, settings: values } })
    },
    [id, updateUserSettings],
  )

  const submitForm = useMemo(
    () => handleSubmit(handleSave),
    [handleSave, handleSubmit],
  )

  const renderRightMenu = useCallback(
    () => (
      <SaveButton
        disabled={loading || !isDirty}
        onClick={submitForm}
        loading={loading}
      />
    ),
    [isDirty, loading, submitForm],
  )

  return (
    <FormProvider
      {...formProps}
      reset={reset}
      formState={formState}
      handleSubmit={handleSubmit}
    >
      <PageWithHeader
        menuItem={MENU_ENTRY.USER}
        renderRightMenu={renderRightMenu}
      >
        <Row style={userSettingsPageStyles.pageWrapper}>
          <Col span={24}>
            <Typography.Title level={3}>User settings</Typography.Title>
          </Col>
          <Row>
            <Select
              label="Default Page Size"
              name="pageSize"
              allowClear={false}
              showSearch={false}
              options={map(
                DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS,
                size => ({
                  label: size,
                  value: size,
                }),
              )}
            />
          </Row>
        </Row>
      </PageWithHeader>
    </FormProvider>
  )
}

export default UserSettingsPage
