import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Table, Typography } from 'antd'
import { isEmpty, isNil } from 'lodash'
import memoize from 'memoizee'
import PropTypes from 'prop-types'

import { useQueryParams, useWindowSize } from '../../../../core/hooks'

import {
  DebouncedTextField,
  ServicingHoursSelect,
} from '../../../../common/components'
import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'
import { servicingHourPropType } from '../../../../common/propTypes'
import { formatServicingHours } from '../../../../common/utils'

import { DISH_TABLE_SCROLL_VALUES } from '../../../../dishes/constants'
import { useUnsavedLocation } from '../../../hooks'
import {
  setLocationDishOverridenHighPrice,
  setLocationDishOverridenLowPrice,
  setLocationDishServicingHoursIds,
} from '../../../redux'
import { makeMapStateToLocationDishProp } from '../../../redux/selectors'

import '../../../../common/styles/smallTable.css'
import './locationDishList.css'

const createMapDispatchToProps =
  onValueChanged =>
  (dispatch, { id, locationId }) => ({
    onValueChanged: value =>
      dispatch(onValueChanged({ id, locationId, value })),
  })

const createConnect = (propName, newPropName, onValueChanged) =>
  connect(
    makeMapStateToLocationDishProp(propName, newPropName),
    createMapDispatchToProps(onValueChanged),
    null,
    {
      pure: true,
    },
  )

const PersistedDishLowPrice = createConnect(
  'overridenLowPrice',
  'newText',
  setLocationDishOverridenLowPrice,
)(DebouncedTextField)
const PersistedDishHighPrice = createConnect(
  'overridenHighPrice',
  'newText',
  setLocationDishOverridenHighPrice,
)(DebouncedTextField)
const PersistedDishServicingHours = createConnect(
  'servicingHoursIds',
  'newServicingHoursIds',
  setLocationDishServicingHoursIds,
)(ServicingHoursSelect)

const columnWidths = {
  name: '20%',
  disabled: '2%',
  overridenPrice: '10%',
  servicingHours: '38%',
  menuTitle: '20%',
}

const styles = {
  servicingHour: {
    marginRight: 20,
  },
}

const createColumns = (
  currentPage,
  pageSize,
  locationId,
  parentServicingHours,
  displayOverridenServicingHours,
  disableServicingHours,
) => [
  {
    title: `Brand's dish name`,
    dataIndex: 'name',
    render: (name, _, index) => (
      <>
        <Typography.Text>
          {(currentPage - 1) * pageSize + index + 1}.&nbsp;
        </Typography.Text>
        <Typography.Text>{name}</Typography.Text>
      </>
    ),
    width: columnWidths.name,
    fixed: 'left',
  },
  {
    title: 'Menu Title',
    dataIndex: 'menuTitle',
    width: columnWidths.menuTitle,
    render: ({ name } = {}) => name,
  },
  {
    title: 'Low Price override',
    dataIndex: 'overridenLowPrice',
    render: (overridenLowPrice, { dishId, currency }) => (
      <PersistedDishLowPrice
        id={dishId}
        locationId={locationId}
        text={overridenLowPrice}
        prefix={currency}
        inputType="float"
      />
    ),
    width: columnWidths.overridenPrice,
  },
  {
    title: 'High Price override',
    dataIndex: 'overridenHighPrice',
    render: (overridenHighPrice, { dishId, currency }) => (
      <PersistedDishHighPrice
        id={dishId}
        locationId={locationId}
        text={overridenHighPrice}
        prefix={currency}
        inputType="float"
      />
    ),
    width: columnWidths.overridenPrice,
  },
  {
    title: 'Servicing time',
    dataIndex: 'servicingHours',
    render: (servicingHours = [], { dishId }) => {
      if (displayOverridenServicingHours) {
        return (
          <PersistedDishServicingHours
            id={dishId}
            locationId={locationId}
            parentServicingHours={parentServicingHours}
            servicingHours={servicingHours}
            disabled={disableServicingHours}
          />
        )
      }
      return (
        <>
          {servicingHours.map(formatServicingHours).map(({ id, name }) => (
            <span key={id} style={styles.servicingHour}>
              {name}
            </span>
          ))}
        </>
      )
    },
    width: columnWidths.servicingHours,
  },
]

const memoCreateColumns = memoize(createColumns, { max: 1 })

export default function LocationDishList({
  locationId,
  dishes,
  totalDishCount,
  servicingHours,
  displayOverridenServicingHours,
  disableServicingHours,
}) {
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()
  const { height } = useWindowSize()
  const { location: unsavedLocation } = useUnsavedLocation(locationId)
  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    setQueryParams,
  } = useQueryParams()

  const paginationConfig = useMemo(() => {
    if (isNil(totalDishCount) || !isEmpty(unsavedLocation)) {
      return false
    }

    return {
      current: Number(currentPage),
      pageSize: Number(pageSize),
      total: totalDishCount,
      onChange: page => setQueryParams({ currentPage: page }),
      pageSizeOptions: DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS,
      showSizeChanger: true,
      onShowSizeChange: (_, size) => setQueryParams({ pageSize: size }),
    }
  }, [totalDishCount, unsavedLocation, currentPage, pageSize, setQueryParams])

  const columns = memoCreateColumns(
    currentPage,
    pageSize,
    locationId,
    servicingHours,
    displayOverridenServicingHours,
    disableServicingHours,
  )

  return (
    <Table
      size="small"
      className="location-dishes-table"
      rowKey="dishId"
      dataSource={dishes}
      columns={columns}
      pagination={paginationConfig}
      // for scroll y we compute the maximum space the dish table
      // can fill by substracting the height of the header, table header and pagination
      scroll={{
        y: Number.isNaN(height)
          ? DISH_TABLE_SCROLL_VALUES.HEIGHT
          : height - DISH_TABLE_SCROLL_VALUES.HEIGHT,
      }}
    />
  )
}

LocationDishList.propTypes = {
  locationId: PropTypes.string.isRequired,
  dishes: PropTypes.arrayOf(
    PropTypes.shape({
      dishId: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  totalDishCount: PropTypes.number,
  servicingHours: PropTypes.arrayOf(servicingHourPropType).isRequired,
  displayOverridenServicingHours: PropTypes.bool.isRequired,
  disableServicingHours: PropTypes.bool.isRequired,
}

LocationDishList.defaultProps = {
  totalDishCount: undefined,
}
