import React, { memo, useMemo } from 'react'
import { Tooltip, TreeSelect } from 'antd'
import { get, map } from 'lodash'
import PropTypes from 'prop-types'

import {
  handleFilterTreeSelectNode,
  handleTreeSelectCustomDropdownForSearch,
  renderTreeSelectNodes,
} from '../../../helpers'
import { stylePropType, treeOptionPropType } from '../../../propTypes'
import { findNodeByValue } from '../../../utils'

const DropdownTreeSelect = ({
  treeData,
  stickyTreeNodes,
  selectionId,
  newSelectionId,
  style,
  onValueChanged,
  disabled,
  labelProp,
  className,
  tooltipMessage,
  size,
  allowClear,
  placeholder,
}) => {
  const currentSelection = newSelectionId || selectionId
  const currentSelectionAsString =
    currentSelection && currentSelection.toString()

  const allTreeData = useMemo(
    () => [
      ...map(stickyTreeNodes, node => ({ ...node, isSticky: true })),
      ...treeData,
    ],
    [stickyTreeNodes, treeData],
  )

  const selectedNode = useMemo(
    () => findNodeByValue(allTreeData, currentSelection),
    [allTreeData, currentSelection],
  )

  const displayValue = get(selectedNode, labelProp)

  const tooltipTitle = tooltipMessage || displayValue

  /** For some weird reason when doing a custom render of nodes the title attribute for the selected node is not put.
   * As such we will render a tooltip even if the name is not long enough for elipsis
   */
  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <TreeSelect
          treeNodeLabelProp={labelProp}
          value={displayValue}
          className={className}
          treeDefaultExpandedKeys={[currentSelectionAsString]}
          onChange={value => onValueChanged(value)}
          style={style}
          showSearch
          treeNodeFilterProp="title"
          filterTreeNode={handleFilterTreeSelectNode}
          dropdownMatchSelectWidth={350}
          dropdownRender={handleTreeSelectCustomDropdownForSearch}
          disabled={disabled}
          size={size}
          allowClear={allowClear}
          placeholder={placeholder}
        >
          {renderTreeSelectNodes(allTreeData, stickyTreeNodes)}
        </TreeSelect>
      </div>
    </Tooltip>
  )
}

DropdownTreeSelect.propTypes = {
  treeData: PropTypes.arrayOf(treeOptionPropType).isRequired,
  stickyTreeNodes: PropTypes.arrayOf(treeOptionPropType),
  selectionId: PropTypes.string,
  newSelectionId: PropTypes.string,
  onValueChanged: PropTypes.func.isRequired,
  labelProp: PropTypes.string.isRequired,
  style: stylePropType,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tooltipMessage: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
}

DropdownTreeSelect.defaultProps = {
  stickyTreeNodes: [],
  selectionId: null,
  newSelectionId: null,
  style: null,
  disabled: false,
  className: undefined,
  tooltipMessage: undefined,
  size: undefined,
  allowClear: undefined,
  placeholder: 'Select Item...',
}

export default memo(DropdownTreeSelect)
