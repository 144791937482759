import { useMemo } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { find, get, isEmpty } from 'lodash'

import { useQueryParams } from '../../core/hooks'
import { isStringTrue } from '../../core/utils'

import { DEFAULT_PAGINATION_CONFIG, idNew } from '../../common/constants'
import { useAuthentication, useCachedBrand } from '../../common/hooks'

import { DEFAULT_DISH_LIST_FILTERS } from '../constants'
import { BRAND_DISHES_QUERY, DISH_BY_ID_QUERY } from '../graphql'

import useUnsavedBrandDish from './useUnsavedBrandDish'

/*
 * Hook to get from apollo cache the dish with id
 * Caution: when reading data from apollo cache an observer is created in the background which listens to changes to the
 * any change to the dish list. If used improper this hook will cause unnecesary re-renders as it will read the cache for
 * the list on any change, even if the dish with the specified dishId is not changed
 */
const useBrandDish = dishId => {
  const brand = useCachedBrand()

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    selectedDishId = dishId,
    sortDishesBy,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
  } = useQueryParams()
  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? brand?.status : null),
    [dishIsStatusSameAsBrandFilter, brand?.status],
  )

  const { data: { brandDishes: { rows: dishList } = {} } = {} } = useQuery(
    BRAND_DISHES_QUERY,
    {
      variables: {
        brandId: brand.id,
        paginationFiltersAndSorters: {
          pageSize: Number(pageSize),
          currentPage: Number(currentPage),
          filters: {
            status: statusForDishFiltering,
            servicingHours: servicingHoursFilter,
            published: dishPublishedFilter,
            ignored: dishIgnoredFilter,
            hasErrors: isStringTrue(dishHasErrorsFilter),
            menuTitle: menuTitleId,
            search,
          },
          sortDishesBy,
        },
      },
      fetchPolicy: 'cache-only',
    },
  )

  const unsavedDish = useUnsavedBrandDish(dishId)

  // useApolloClient is used instead of useQuery because, at the time of writing this,
  // the useQuery hook gets the updated cache values with a brief delay, while client.readQuery
  // reads the correct values after immediately a cache update.
  const client = useApolloClient()

  let brandDishFromCache = {}
  if (selectedDishId === idNew) {
    return {
      unsavedDish,
      dish: {},
    }
  }

  brandDishFromCache = get(
    client.readQuery({
      query: DISH_BY_ID_QUERY,
      variables: {
        id: selectedDishId,
      },
    }),
    'dish',
  )

  if (isEmpty(brandDishFromCache)) {
    brandDishFromCache = find(dishList, ({ id }) => id === selectedDishId)
  }

  return {
    unsavedDish,
    dish: brandDishFromCache || {},
  }
}

export default useBrandDish
