import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { HighlightOutlined, TagsOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { includes } from 'lodash'
import PropTypes from 'prop-types'

import { usePrevious } from '../../../../core/hooks'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
} from '../../../../common/constants'
import { useUserHasBrandChangePermission } from '../../../../common/hooks'

import { useEditableCellHotkeyEdit } from '../../../hooks'
import { dishPropType } from '../../../propTypes'
import { DishIngredientExplanationsPopover } from '../DishIngredientExplanationsPopover'

const DishTableActions = ({ disabled, dish, contextToUse }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const { suggestCuration, suggestVariousText } = useContext(contextToUse)
  const { userHasBrandChangePermission: canRetry } =
    useUserHasBrandChangePermission()
  const { isFocusOnRow } = useEditableCellHotkeyEdit(dish.id)

  const {
    mainIngredients = [],
    additionalIngredients = [],
    choiceIngredients = [],
  } = dish

  const dishIngredients = useMemo(
    () => [...mainIngredients, ...additionalIngredients, ...choiceIngredients],
    [additionalIngredients, mainIngredients, choiceIngredients],
  )

  const handleSuggestCuration = useCallback(
    e => {
      e.stopPropagation()
      suggestCuration(dish)
    },
    [dish, suggestCuration],
  )

  const handleSuggestVarious = useCallback(
    e => {
      e.stopPropagation()
      suggestVariousText(dish)
    },
    [dish, suggestVariousText],
  )

  const handlePopoverVisibleChange = useCallback(
    newPopoverVisibleValue => setIsPopoverVisible(newPopoverVisibleValue),
    [],
  )

  useHotkeys(
    [AVAILABLE_HOTKEYS.OPTION_P.hotkey, AVAILABLE_HOTKEYS.ALT_P.hotkey].join(
      ',',
    ),
    () => {
      setIsPopoverVisible(!isPopoverVisible)
    },
    {
      enabled:
        isFocusOnRow &&
        includes(
          [
            CURATION_STATUS.CURATION_CONFIRMATION.value,
            CURATION_STATUS.DONE.value,
            CURATION_STATUS.QA.value,
            CURATION_STATUS.QQA_CONFIRMATION.value,
          ],
          dish.status,
        ),
    },
    [isPopoverVisible, isFocusOnRow, dish],
  )

  const previousIsFocusOnRow = usePrevious(isFocusOnRow)
  useEffect(() => {
    if (previousIsFocusOnRow && !isFocusOnRow && isPopoverVisible) {
      setIsPopoverVisible(false)
    }
  }, [previousIsFocusOnRow, isFocusOnRow, isPopoverVisible])

  if (!canRetry) {
    return null
  }

  return (
    <>
      {includes(
        [
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.DONE.value,
          CURATION_STATUS.QA.value,
          CURATION_STATUS.QQA_CONFIRMATION.value,
        ],
        dish.status,
      ) && (
        <>
          <DishIngredientExplanationsPopover
            dishId={dish.id}
            dishIngredients={dishIngredients}
            onVisibleChange={handlePopoverVisibleChange}
            isVisible={isPopoverVisible}
          />
          <Tooltip placement="topLeft" title="Retry curation">
            <Button
              disabled={disabled}
              icon={<TagsOutlined />}
              shape="circle"
              onClick={handleSuggestCuration}
            />
          </Tooltip>
        </>
      )}
      {includes(
        [
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
          CURATION_STATUS.QA.value,
          CURATION_STATUS.QQA_CONFIRMATION.value,
          CURATION_STATUS.DONE.value,
        ],
        dish.status,
      ) && (
        <Tooltip placement="topLeft" title="Retry misc and choice text">
          <Button
            disabled={disabled}
            icon={<HighlightOutlined />}
            shape="circle"
            onClick={handleSuggestVarious}
          />
        </Tooltip>
      )}
    </>
  )
}

DishTableActions.propTypes = {
  dish: dishPropType.isRequired,
  contextToUse: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

DishTableActions.defaultProps = {
  disabled: false,
}

export default DishTableActions
