import * as Sentry from '@sentry/react'

const initSentry = ({ dsn, environment, beforeSend }) =>
  Sentry.init({
    dsn,
    environment,
    beforeSend,
  })

export default initSentry
