const brandSuggestedWordCardStyles = Object.freeze({
  head: {
    borderBottom: 'none',
  },
  body: {
    padding: '0.75rem',
  },
  redBorder: {
    borderColor: '#e21b1b',
  },
})

export default brandSuggestedWordCardStyles
