import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Tooltip, TreeSelect } from 'antd'
import {
  filter,
  find,
  get,
  isEmpty,
  isNil,
  map,
  some,
  toLower,
  trim,
  uniqBy,
} from 'lodash'
import PropTypes from 'prop-types'

import { areSameValue } from '../../../../core/utils'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
} from '../../../../common/constants'
import {
  filterDuplicatesFromAiTreeData,
  getSuggestedByAiTreeData,
  getTreeIdsUpToLevel,
  handleFilterTreeSelectNode,
  handleTreeSelectCustomDropdownForSearch,
} from '../../../../common/helpers'
import { useCurationMetadata } from '../../../../common/hooks'
import { filterTree, findNodeByValue } from '../../../../common/utils'

import { DISH_FIELDS } from '../../../constants'
import { DishEditableSelectsRowContext } from '../../../contexts'
import { useEditableCellHotkeyEdit } from '../../../hooks'
import { dishPropType } from '../../../propTypes'

const styles = {
  select: { width: '100%' },
  dropdown: { maxHeight: 400, minWidth: 250 },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editDisabled: {
    cursor: 'not-allowed',
  },
}

const EditableCellTreeSelectField = ({
  name,
  value,
  defaultComponent,
  disabled,
  dish,
  brandStatus,
}) => {
  const [editing, setEditing] = useState(false)
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const selectRef = useRef(null)
  const { saveCuration, loading } = useContext(DishEditableSelectsRowContext)
  const { dishTypeOptions, courseTypeOptions } = useCurationMetadata()

  const { forceEditState, isFocusOnRow, setHotkeyEditMode } =
    useEditableCellHotkeyEdit(dish.id, name)

  const isIgnoredOnly = useMemo(
    () => brandStatus === CURATION_STATUS.SANITY_CHECK.value,
    [brandStatus],
  )

  const {
    treeData = [],
    stickyTreeNodes = [],
    treeDefaultExpandedKeys = [],
  } = useMemo(() => {
    const curationMetadata = {
      [DISH_FIELDS.DISH_TYPE]: dishTypeOptions || [],
      [DISH_FIELDS.COURSE_TYPE]: courseTypeOptions || [],
    }

    let selectData
    if (isIgnoredOnly) {
      if (isEmpty(curationMetadata[name])) {
        selectData = []
      }

      selectData = filter(get(curationMetadata, name), 'isIgnored')
    } else {
      selectData = curationMetadata[name]
    }

    const suggestedByAiTreeData = getSuggestedByAiTreeData(
      selectData,
      get(dish, `aiSuggestions[${name}]`) || [],
    )

    return {
      treeData: selectData,
      stickyTreeNodes: filterDuplicatesFromAiTreeData(suggestedByAiTreeData),
      treeDefaultExpandedKeys: getTreeIdsUpToLevel(
        curationMetadata[name],
        1,
        1,
      ),
    }
  }, [dishTypeOptions, courseTypeOptions, name, dish, isIgnoredOnly])

  const treeDataWithStickyNodes = useMemo(
    () =>
      isEmpty(stickyTreeNodes)
        ? treeData
        : [
            ...map(stickyTreeNodes, node => ({
              ...node,
              isSticky: true,
            })),
            ...filterTree(
              treeData,
              ({ value: nodeValue }) =>
                !some(stickyTreeNodes, ({ value: stickyNodeValue }) =>
                  areSameValue(nodeValue, stickyNodeValue),
                ),
              true,
            ),
          ],
    [stickyTreeNodes, treeData],
  )

  const selectedNode = useMemo(
    () => findNodeByValue(treeData, value),
    [treeData, value],
  )

  const ignoredDishTypeKeys = useMemo(() => {
    if (isEmpty(treeData) || !isIgnoredOnly || name !== DISH_FIELDS.DISH_TYPE) {
      return []
    }

    return uniqBy(
      map(treeData, node => ({
        key: trim(node.title)[0],
        id: node.value,
      })),
      'key',
    )
  }, [isIgnoredOnly, name, treeData])

  const handleChange = useCallback(
    newValue => {
      if (isNil(newValue)) {
        return saveCuration(dish, name, null)
      }

      const selectedValue = findNodeByValue(treeData, newValue)
      return saveCuration(dish, name, selectedValue)
    },
    [saveCuration, dish, name, treeData],
  )

  useEffect(() => {
    if (forceEditState) {
      setEditing(true)
    }
  }, [forceEditState])

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      setEditing(true)
    },
    [setEditing],
  )

  useEffect(() => {
    if (!isNil(selectRef.current)) {
      selectRef.current.focus()
    }
  }, [editing])

  const handleBlur = useCallback(() => {
    setEditing(false)
    setHotkeyEditMode(false)
  }, [setEditing, setHotkeyEditMode])

  // hotkey to stop editing
  useHotkeys(
    AVAILABLE_HOTKEYS.ESC.hotkey,
    handleBlur,
    {
      enabled: editing,
      enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
    },
    [editing],
  )

  // set ignored dish type in sanity check step using hotkeys
  useHotkeys(
    map(ignoredDishTypeKeys, 'key').join(',') || 'i',

    event => {
      const dishTypeId = get(
        find(
          ignoredDishTypeKeys,
          ({ key }) => toLower(key) === toLower(event.key),
        ),
        'id',
      )

      if (!isNil(dishTypeId)) {
        const selectedValue = findNodeByValue(treeData, dishTypeId)
        if (!isNil(selectedValue)) {
          saveCuration(dish, name, selectedValue)
        }
      }
    },
    {
      enabled:
        !editing &&
        isFocusOnRow &&
        brandStatus === CURATION_STATUS.SANITY_CHECK.value &&
        name === DISH_FIELDS.DISH_TYPE,
    },
    [
      editing,
      isFocusOnRow,
      dish,
      name,
      brandStatus,
      treeData,
      saveCuration,
      ignoredDishTypeKeys,
    ],
  )

  // remove ignored dish type key
  useHotkeys(
    AVAILABLE_HOTKEYS.X.hotkey,
    () => saveCuration(dish, name, null),
    {
      enabled:
        !editing &&
        isFocusOnRow &&
        brandStatus === CURATION_STATUS.SANITY_CHECK.value &&
        name === DISH_FIELDS.DISH_TYPE,
    },
    [editing, isFocusOnRow, dish, name, brandStatus, treeData, saveCuration],
  )

  useEffect(() => {
    handleBlur()
  }, [handleBlur, loading])

  return (
    <div
      onClick={handleClick}
      role="presentation"
      style={{ ...styles.container, ...(disabled && styles.editDisabled) }}
    >
      {editing && !disabled ? (
        <Tooltip title={dropDownVisible ? null : get(selectedNode, 'namePath')}>
          <TreeSelect
            ref={selectRef}
            style={styles.select}
            showSearch
            allowClear
            defaultValue={get(selectedNode, 'namePath')}
            treeNodeFilterProp="title"
            treeDefaultExpandedKeys={treeDefaultExpandedKeys}
            onDropdownVisibleChange={setDropDownVisible}
            filterTreeNode={handleFilterTreeSelectNode}
            dropdownRender={handleTreeSelectCustomDropdownForSearch}
            dropdownStyle={styles.dropdown}
            dropdownClassName="dark-dropdown-highlights"
            placeholder="Select dish type"
            onChange={handleChange}
            onClick={handleClick}
            onBlur={handleBlur}
            treeData={treeDataWithStickyNodes}
          />
        </Tooltip>
      ) : (
        defaultComponent
      )}
    </div>
  )
}

EditableCellTreeSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultComponent: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  dish: dishPropType.isRequired,
  brandStatus: PropTypes.string,
}

EditableCellTreeSelectField.defaultProps = {
  disabled: false,
  value: null,
  brandStatus: undefined,
}

export default EditableCellTreeSelectField
