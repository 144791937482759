import { useCallback } from 'react'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { pick } from 'lodash'

import { useQueryParams } from '../../core/hooks'

import { handleMutationError } from '../../common/helpers'
import { useAuthentication } from '../../common/hooks'

import { DEFAULT_BRAND_LIST_FILTERS } from '../constants'

const useBrandFilters = () => {
  const { userInfo } = useAuthentication()
  const [brandFilters] = useLocalStorage('brandFilters')

  const {
    status = brandFilters?.status || DEFAULT_BRAND_LIST_FILTERS.STATUS,
    currentPage,
    pageSize,
    searchText,
    cuisineTypes = brandFilters?.cuisineTypes ||
      DEFAULT_BRAND_LIST_FILTERS.CUISINE_TYPES,
    tags = brandFilters?.tags || DEFAULT_BRAND_LIST_FILTERS.TAGS,
    predominantLanguages = brandFilters?.predominantLanguages ||
      DEFAULT_BRAND_LIST_FILTERS.PREDOMINANT_LANGUAGES,
    unknownWordsLanguages = brandFilters?.unknownWordsLanguages ||
      DEFAULT_BRAND_LIST_FILTERS.UNKNOWN_WORDS_LANGUAGES,
    assignedCurator = brandFilters?.assignedCurator ||
      DEFAULT_BRAND_LIST_FILTERS.ASSIGNED_USER,
    assignedQa = brandFilters?.assignedQa ||
      DEFAULT_BRAND_LIST_FILTERS.ASSIGNED_USER,
    qaDetailLevel = brandFilters?.qaDetailLevel ||
      DEFAULT_BRAND_LIST_FILTERS.QA_DETAIL_LEVEL,
    appropriateForUserId = brandFilters?.appropriateForUserId || userInfo.id,
    setQueryParams,
  } = useQueryParams()

  const setBrandFilters = useCallback(
    async value => {
      try {
        await writeStorage('brandFilters', {
          ...brandFilters,
          ...pick(value, [
            'status',
            'cuisineTypes',
            'tags',
            'predominantLanguages',
            'unknownWordsLanguages',
            'assignedCurator',
            'assignedQa',
            'qaDetailLevel',
            'appropriateForUserId',
          ]),
        })
        setQueryParams(value)
      } catch (error) {
        handleMutationError(error)
      }
    },
    [brandFilters, setQueryParams],
  )

  return {
    currentPage,
    pageSize,
    status,
    searchText,
    cuisineTypes,
    tags,
    predominantLanguages,
    unknownWordsLanguages,
    assignedCurator,
    assignedQa,
    qaDetailLevel,
    appropriateForUserId,
    setBrandFilters,
  }
}

export default useBrandFilters
