import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from '../../../brands/graphql'
import { BRAND_DISH_FRAGMENT } from '../../../dishes/graphql'

const COURSE_TYPE_HUMAN_CLEANUP_FRAGMENT = gql`
  fragment CourseTypeHumanCleanupFragment on CourseTypeHumanCleanup {
    id
    brand {
      ...BrandFragment
    }
    dish {
      ...BrandDishFragment
    }
    isCurationDone
    isQaDone
    cleanupCourseTypeId
  }
  ${BRAND_DISH_FRAGMENT}
  ${BRAND_FRAGMENT}
`

export default COURSE_TYPE_HUMAN_CLEANUP_FRAGMENT
