import React, { useMemo } from 'react'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import { servicingHourPropType } from '../../common/propTypes'
import { formatServicingHours } from '../../common/utils'

import { MenuTitleTreeItemPropType } from '../../menuTitles/propTypes'
import { BrandSupportingDataContext } from '../contexts'

const BrandSupportingDataProvider = ({
  children,
  menuTitles,
  servicingHours,
}) => {
  const menuTitleOptions = useMemo(() => menuTitles || [], [menuTitles])

  const servicingHoursOptions = useMemo(
    () => [
      { id: null, name: 'Always' },
      ...map(servicingHours, formatServicingHours),
    ],
    [servicingHours],
  )

  return (
    <BrandSupportingDataContext.Provider
      value={{
        menuTitleOptions,
        servicingHoursOptions,
      }}
    >
      {children}
    </BrandSupportingDataContext.Provider>
  )
}

BrandSupportingDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  menuTitles: PropTypes.arrayOf(MenuTitleTreeItemPropType),
  servicingHours: PropTypes.arrayOf(servicingHourPropType),
}

BrandSupportingDataProvider.defaultProps = {
  menuTitles: [],
  servicingHours: [],
}

export default BrandSupportingDataProvider
