import { CompositeDecorator } from 'draft-js'

class CompositeDecoratorFactory {
  static create(strategy, component) {
    return new CompositeDecorator([
      {
        strategy,
        component,
      },
    ])
  }
}

export default CompositeDecoratorFactory
