import React, { memo } from 'react'
import { Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'

import { formatDate, formatDateFromNow } from '../../../utils'

const DateFromNow = ({ date }) => (
  <Typography.Text>
    <Tooltip title={formatDate(date)}>{formatDateFromNow(date)}</Tooltip>
  </Typography.Text>
)

DateFromNow.propTypes = {
  date: PropTypes.string.isRequired,
}

export default memo(DateFromNow)
