import React, { memo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { isNil } from 'lodash'

import { DiscardButton } from '../../../../common/components'
import { APP_ROUTES, EVENT_TYPES } from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { useUnsavedLocation } from '../../../hooks'

const LocationDiscardButton = () => {
  const { locationId } = useParams()
  const { removeLocation, location } = useUnsavedLocation(locationId)

  if (isNil(locationId)) {
    return (
      <Link to={APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations}>
        <DiscardButton />
      </Link>
    )
  }

  return (
    <DiscardButton
      disabled={!location}
      onClick={() => {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_LOCATION_EVENTS.CANCEL_SAVE,
          {
            parentBrandId: location.brandId,
            locationId: location.id,
          },
        )
        removeLocation()
      }}
    />
  )
}

export default memo(LocationDiscardButton)
