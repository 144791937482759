import PropTypes from 'prop-types'

const dishPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  published: PropTypes.bool,
  lowPrice: PropTypes.number,
  highPrice: PropTypes.number,
  description: PropTypes.string,
  mainIngredients: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired }),
  ),
  brand: PropTypes.shape({ id: PropTypes.string.isRequired }),
})

export default dishPropType
