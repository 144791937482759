import React from 'react'
import { MinusOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'
import update from 'immutability-helper'
import { filter, findIndex, map } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { servicingHourPropType } from '../../../propTypes'
import { DaysOfWeek } from '../DaysOfWeek'
import { TimeSlot } from '../TimeSlot'

const menus = [
  'Breakfast',
  'Lunch',
  'Dinner',
  'Brunch',
  'Sunday Roast',
  'Afternoon Tea',
  'All Day',
  'Unspecified',
]

const createDefaultServicingTime = () => ({
  id: shortid.generate(),
  created: true,
  menu: 'Dinner',
  startDayOfWeek: 0,
  endDayOfWeek: 4,
  startTime: '18:00',
  endTime: '22:00',
})

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  menuDropdown: {
    width: 150,
  },
  removeButton: {
    marginLeft: 5,
  },
}

const ServicingHour = ({ servicingHour, onValueChanged, disabled }) => {
  const { menu, startDayOfWeek, endDayOfWeek, startTime, endTime } =
    servicingHour

  return (
    <div style={styles.container}>
      <Select
        disabled={disabled}
        value={menu}
        style={styles.menuDropdown}
        onSelect={value => {
          onValueChanged({ ...servicingHour, menu: value })
        }}
      >
        {map(menus, m => (
          <Select.Option key={m}>{m}</Select.Option>
        ))}
      </Select>
      <DaysOfWeek
        disabled={disabled}
        startDayOfWeek={startDayOfWeek}
        endDayOfWeek={endDayOfWeek}
        onValueChanged={({
          startDayOfWeek: newStartDayOfWeek,
          endDayOfWeek: newEndDayOfWeek,
        }) => {
          onValueChanged({
            ...servicingHour,
            startDayOfWeek: newStartDayOfWeek,
            endDayOfWeek: newEndDayOfWeek,
          })
        }}
      />
      <TimeSlot
        disabled={disabled}
        openTime={startTime}
        closeTime={endTime}
        onValueChanged={({ openTime: newStartTime, closeTime: newEndTime }) => {
          onValueChanged({
            ...servicingHour,
            startTime: newStartTime,
            endTime: newEndTime,
          })
        }}
      />
      <Button
        icon={<MinusOutlined />}
        shape="circle"
        style={styles.removeButton}
        disabled={disabled}
        onClick={() => {
          onValueChanged({ ...servicingHour, deleted: true })
        }}
      />
    </div>
  )
}

ServicingHour.propTypes = {
  servicingHour: servicingHourPropType.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const ServicingHours = ({
  servicingHours,
  newServicingHours,
  onValueChanged,
  disabled,
  showAddServicingTimeButton,
}) => {
  const nonNullServicingHours = newServicingHours || servicingHours || []
  return (
    <>
      {map(
        filter(nonNullServicingHours, ({ deleted }) => !deleted),
        servicingHour => (
          <ServicingHour
            key={servicingHour.id}
            servicingHour={servicingHour}
            onValueChanged={sh => {
              const { id, created, deleted } = sh
              const index = findIndex(
                nonNullServicingHours,
                ({ id: servicingHourId }) => servicingHourId === id,
              )

              let updatedServicingHours
              // If a new servicing hour was created, but have never been actually saved
              if (created && deleted) {
                updatedServicingHours = update(nonNullServicingHours, {
                  $splice: [[index, 1]],
                })
              } else {
                updatedServicingHours = update(nonNullServicingHours, {
                  [index]: { $set: sh },
                })
              }
              // As the data may come from Apollo (when the brand has working hours in the db and nothing is yet saved
              // in the redux store) then we have __typename properties in the working hours object. We want to omit this
              // property (__typename) as executing the upsert brand mutation with them fails.
              const servicingHoursWithoutTypename = omitDeep(
                updatedServicingHours,
                '__typename',
              )
              onValueChanged(servicingHoursWithoutTypename)
            }}
            disabled={disabled}
          />
        ),
      )}
      {showAddServicingTimeButton && (
        <Button
          disabled={disabled}
          onClick={() => {
            const updatedServicingHours = [
              ...nonNullServicingHours,
              createDefaultServicingTime(),
            ]
            const servicingHoursWithoutTypename = omitDeep(
              updatedServicingHours,
              '__typename',
            )
            onValueChanged(servicingHoursWithoutTypename)
          }}
        >
          Add servicing time
        </Button>
      )}
    </>
  )
}

ServicingHours.propTypes = {
  servicingHours: PropTypes.arrayOf(servicingHourPropType),
  newServicingHours: PropTypes.arrayOf(servicingHourPropType),
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showAddServicingTimeButton: PropTypes.bool,
}

ServicingHours.defaultProps = {
  newServicingHours: null,
  servicingHours: null,
  showAddServicingTimeButton: true,
  disabled: false,
}

export default ServicingHours
