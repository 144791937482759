import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { TreeSelect, Typography } from 'antd'
import { filter, get, isEmpty, isNil, keys, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { useOutsideClick, usePrevious } from '../../../../core/hooks'
import { areSameValue } from '../../../../core/utils'

import { AVAILABLE_HOTKEYS } from '../../../../common/constants'
import { handleTreeSelectCustomDropdownForSearch } from '../../../../common/helpers'
import { useCurationMetadata } from '../../../../common/hooks'

import { DISH_FIELDS, INGREDIENTS } from '../../../constants'
import { DishEditableSelectsRowContext } from '../../../contexts'
import { useEditableCellHotkeyEdit } from '../../../hooks'
import { dishPropType } from '../../../propTypes'
import { dishIngredientForDisplayTransformer } from '../../../transformers'
import { MetadataTag } from '../../molecules/MetadataTag'
import { DishIngredientsPopover } from '../DishIngredientsPopover'

const styles = {
  select: { width: '100%' },
  dropdown: { maxHeight: 400, minWidth: 250 },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  popoverButton: {
    marginLeft: 6,
  },
  editDisabled: {
    cursor: 'not-allowed',
  },
}

const INGREDIENT_LABELS = {
  [DISH_FIELDS.MAIN_INGREDIENTS]: 'Main ingredients:',
  [DISH_FIELDS.ADDITIONAL_INGREDIENTS]: 'Additional ingredients:',
  [DISH_FIELDS.CHOICE_INGREDIENTS]: 'Choice ingredients:',
}

const EditableCellIngredientsSelectField = ({
  value,
  defaultComponent,
  disabled,
  aiSuggestions,
  dish,
  name,
  areMlIngredientsEnabled,
}) => {
  const [editing, setEditing] = useState(false)
  const [changes, setChanges] = useState({})
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [selectInFocus, setSelectInFocus] = useState(false)
  const [selectedInputIndex, setSelectedInputIndex] = useState(0)
  const inputRef = useRef([])
  const { saveCuration } = useContext(DishEditableSelectsRowContext)
  const { ingredientOptions = [] } = useCurationMetadata()
  const {
    isFocusOnRow,
    forceEditState,
    isFocusOnCell,
    setHotkeyEditMode,
    setIsPopoverModeEnabled,
  } = useEditableCellHotkeyEdit(dish.id, name)

  const popoverIngredients = useMemo(
    () => [
      ...map(value[DISH_FIELDS.MAIN_INGREDIENTS], ingredient =>
        dishIngredientForDisplayTransformer.transformMainOrChoiceIngredientForDisplay(
          ingredient,
        ),
      ),
      ...map(value[DISH_FIELDS.CHOICE_INGREDIENTS], ingredient =>
        dishIngredientForDisplayTransformer.transformMainOrChoiceIngredientForDisplay(
          ingredient,
        ),
      ),
    ],
    [value],
  )

  const handlePopoverVisibleChange = useCallback(
    newPopoverVisibleValue => {
      setPopoverVisible(newPopoverVisibleValue)
      setIsPopoverModeEnabled(newPopoverVisibleValue)
    },
    [setIsPopoverModeEnabled],
  )

  const handleSave = useCallback(async () => {
    if (selectInFocus || !editing) {
      return
    }

    setEditing(false)
    setHotkeyEditMode(false)
    if (isEmpty(changes)) {
      return
    }

    handlePopoverVisibleChange(false)
    await saveCuration(dish, INGREDIENTS, changes)

    setChanges({})
  }, [
    selectInFocus,
    editing,
    setHotkeyEditMode,
    changes,
    handlePopoverVisibleChange,
    saveCuration,
    dish,
  ])

  const dishIngredientsSelectRef = useRef(null)
  useOutsideClick(dishIngredientsSelectRef, handleSave)

  const handleChange = useCallback(
    (field, newValue) => {
      const existingIngredients = filter(get(value, field), ({ id }) =>
        some(newValue, newId => areSameValue(newId, id)),
      )
      const newIngredientIds = filter(newValue, newId => {
        const isNew = !some(get(value, field), ({ id }) =>
          areSameValue(newId, id),
        )
        const otherFields = filter(
          keys(value),
          fieldName => fieldName !== field,
        )
        const isAlreadyUsed = some(
          otherFields,
          fieldName =>
            some(value[fieldName], ({ id }) => areSameValue(newId, id)) ||
            some(changes[fieldName], ({ id }) => areSameValue(newId, id)),
        )

        return isNew && !isAlreadyUsed
      })

      setChanges({
        ...changes,
        [field]: [
          ...existingIngredients,
          ...map(newIngredientIds, id => ({
            id,
          })),
        ],
      })
    },
    [value, changes],
  )

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      setEditing(true)
    },
    [setEditing],
  )

  useEffect(() => {
    if (forceEditState) {
      setEditing(forceEditState)
    }
  }, [forceEditState])

  useEffect(() => {
    if (editing && !isEmpty(inputRef.current)) {
      inputRef.current[0]?.focus()
      setSelectedInputIndex(0)
    }
  }, [editing])

  // hotkey to stop editing
  useHotkeys(
    AVAILABLE_HOTKEYS.ESC.hotkey,
    () => {
      setSelectInFocus(false)
      handleSave()
    },
    {
      enabled: editing,
      enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
    },
    [editing],
  )

  // hotkey to open popover
  useHotkeys(
    [AVAILABLE_HOTKEYS.P.hotkey, AVAILABLE_HOTKEYS.SHIFT_P.hotkey].join(','),
    event => {
      if (
        (name === DISH_FIELDS.CHOICE_INGREDIENTS && !event.shiftKey) ||
        (name === INGREDIENTS && event.shiftKey)
      ) {
        return
      }

      handlePopoverVisibleChange(!popoverVisible)
    },
    {
      enabled: isFocusOnRow && !editing && !isEmpty(popoverIngredients),
    },
    [
      popoverVisible,
      isFocusOnRow,
      !editing,
      name,
      popoverIngredients,
      handlePopoverVisibleChange,
    ],
  )

  const previousIsFocusOnRow = usePrevious(isFocusOnRow)
  useEffect(() => {
    if (previousIsFocusOnRow && !isFocusOnRow && popoverVisible) {
      handlePopoverVisibleChange(false)
    }
  }, [
    previousIsFocusOnRow,
    isFocusOnRow,
    popoverVisible,
    handlePopoverVisibleChange,
  ])

  // key to cycle between ingredient inputs
  useHotkeys(
    AVAILABLE_HOTKEYS.TAB.hotkey,
    event => {
      const newSelectIndex =
        selectedInputIndex < inputRef.current.length - 1
          ? selectedInputIndex + 1
          : 0
      if (!isNil(inputRef.current[newSelectIndex])) {
        inputRef.current[newSelectIndex].focus()
        setSelectedInputIndex(newSelectIndex)
        event.preventDefault()
      }
    },
    {
      enabled: isFocusOnCell && editing,
      enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
    },
    [selectedInputIndex, isFocusOnCell, editing],
  )

  return (
    <div ref={dishIngredientsSelectRef} style={styles.wrapper}>
      <div
        onClick={handleClick}
        role="presentation"
        style={{ ...styles.container, ...(disabled && styles.editDisabled) }}
      >
        {(!editing || disabled) && defaultComponent}
        {editing &&
          !disabled &&
          map(keys(value), (field, index) => (
            <div key={field}>
              <Typography.Text>{INGREDIENT_LABELS[field]}</Typography.Text>
              <br />
              <TreeSelect
                ref={el => {
                  inputRef.current[index] = el
                }}
                tagRender={MetadataTag}
                multiple
                style={styles.select}
                showSearch
                onFocus={() => setSelectInFocus(true)}
                onBlur={() => setSelectInFocus(false)}
                getPopupContainer={() => dishIngredientsSelectRef.current}
                defaultValue={map(value[field], 'id')}
                dropdownRender={handleTreeSelectCustomDropdownForSearch}
                dropdownClassName="dark-dropdown-highlights"
                treeNodeFilterProp="title"
                dropdownStyle={styles.dropdown}
                treeData={ingredientOptions}
                onChange={newValue => handleChange(field, newValue)}
                onClick={handleClick}
              />
            </div>
          ))}
      </div>
      {!isEmpty(popoverIngredients) && (
        <DishIngredientsPopover
          dishId={dish.id}
          onVisibleChange={handlePopoverVisibleChange}
          visible={popoverVisible}
          ingredients={popoverIngredients}
          aiSuggestions={aiSuggestions}
          fieldName={name}
          status={dish.status}
          areMlIngredientsEnabled={areMlIngredientsEnabled}
        />
      )}
    </div>
  )
}

EditableCellIngredientsSelectField.propTypes = {
  defaultComponent: PropTypes.node.isRequired,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  aiSuggestions: PropTypes.object,
  dish: dishPropType.isRequired,
  name: PropTypes.string.isRequired,
  areMlIngredientsEnabled: PropTypes.bool,
}

EditableCellIngredientsSelectField.defaultProps = {
  disabled: false,
  value: {},
  aiSuggestions: null,
  areMlIngredientsEnabled: false,
}

export default EditableCellIngredientsSelectField
