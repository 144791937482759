import { gql } from '@apollo/client'

const COMPETENCIES_QUERY = gql`
  query competencies {
    competencies {
      id
      value
      label
    }
  }
`

export default COMPETENCIES_QUERY
