import {
  filter,
  get,
  initial,
  isEmpty,
  isNil,
  keys,
  map,
  pick,
  some,
} from 'lodash'

import { areSameValue } from '../../core/utils'

import {
  extractChoiceText,
  extractMiscText,
  findNodeBy,
  findNodesBy,
  mergeArrayOfObjects,
} from '../../common/utils'

import {
  findMatchedWordsIndexesFromTextField,
  mapStyledTextIndexesToDish,
} from '../helpers'

export const transformDishToAiRequestDto = (dish, additionalInformation) => {
  const dishTextFields = mergeArrayOfObjects(
    map(
      keys(pick(dish, ['name', 'description', 'ingredientsText'])),
      mapStyledTextIndexesToDish(dish),
    ),
  )

  let menuTitleList = []

  if (!isNil(dish.menuTitle) && !isEmpty(additionalInformation)) {
    const { menuTitleOptions } = additionalInformation

    const selectedMenuTitle = findNodeBy(menuTitleOptions, ({ id }) =>
      areSameValue(id, dish.menuTitle.id),
    )

    menuTitleList = filter(
      [
        selectedMenuTitle,
        ...findNodesBy(menuTitleOptions, node =>
          some(
            initial(selectedMenuTitle.parents),
            parentId => node.id === parentId,
          ),
        ),
      ],
      ({ parentId }) => !isNil(parentId),
    )

    // this means that the menu title is actually a child of the root menu title and we don't want to send its parentId
    if (menuTitleList.length === 1) {
      menuTitleList[0] = {
        ...menuTitleList[0],
        parentId: null,
      }
    }
  }

  return {
    id: dish.id,
    ...dishTextFields,
    dietDescriptors: dish.dietDescriptors || [],
    allergensDescriptors: dish.allergenDescriptors || [],
    brand: {
      id: get(additionalInformation, 'brand.id'),
      name: get(additionalInformation, 'brand.name'),
    },
    alternativeBrandName: dish?.alternativeBrandName,
    menuTitles: map(
      menuTitleList,
      ({
        id,
        name,
        description,
        styledDescription,
        parentId,
        dietDescriptors,
        allergenDescriptors,
      }) => {
        const matchedExtraWords = extractMiscText(styledDescription)
        const matchedChoiceWords = extractChoiceText(styledDescription)

        const menuTitleDescription = {
          text: description || '',
          misc: findMatchedWordsIndexesFromTextField(
            matchedExtraWords,
            styledDescription,
          ),
          choices: findMatchedWordsIndexesFromTextField(
            matchedChoiceWords,
            styledDescription,
          ),
        }

        return {
          id,
          name,
          description: menuTitleDescription,
          parentId,
          dietDescriptors: dietDescriptors || [],
          allergensDescriptors: allergenDescriptors || [],
        }
      },
    ),
  }
}
