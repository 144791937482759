import { gql } from '@apollo/client'

import { SIMPLE_ENTITY_FRAGMENT } from '../../../common/graphql'

export default gql`
  fragment LocationEntryFragment on Location {
    ...SimpleEntityFragment
    status
    web
    incomplete
    address
    brandId
  }
  ${SIMPLE_ENTITY_FRAGMENT}
`
