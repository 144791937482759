import React, { useMemo } from 'react'
import { Typography } from 'antd'
import {
  filter,
  get,
  groupBy,
  isEmpty,
  isNil,
  join,
  keys,
  map,
  reduce,
} from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'

import { INGREDIENT_USED_FEATURE_ENUM_TYPE } from '../../../constants'

const UsedFeatureIngredients = ({ dishIngredientsWithPath }) => {
  const ingredientsWithAlreadyFound = useMemo(
    () =>
      reduce(
        dishIngredientsWithPath,
        (acc, ingredient) => [
          ...acc,
          ingredient,
          ...map(
            filter(
              ingredient.alreadyFoundIn,
              alreadyFoundIngredient =>
                !isNil(alreadyFoundIngredient.usedFeature),
            ),
            alreadyFoundIngredient => ({
              ...alreadyFoundIngredient,
              name: ingredient.name,
            }),
          ),
        ],
        [],
      ),
    [dishIngredientsWithPath],
  )

  const ingredientsGroupedByUsedFeature = useMemo(
    () =>
      groupBy(
        map(
          filter(
            ingredientsWithAlreadyFound,
            ({ usedFeature }) =>
              !isNil(usedFeature) &&
              usedFeature !==
                INGREDIENT_USED_FEATURE_ENUM_TYPE.INGREDIENT_MATCHER,
          ),
          ({ id, name, usedFeature }) => ({ id, name, usedFeature }),
        ),
        'usedFeature',
      ),
    [ingredientsWithAlreadyFound],
  )

  if (isEmpty(ingredientsGroupedByUsedFeature)) {
    return null
  }

  return map(keys(ingredientsGroupedByUsedFeature), usedFeature => (
    <GridFormItem
      key={usedFeature}
      label={`Suggested Ingredients based on ${usedFeature}:`}
    >
      <Typography.Text>
        {join(
          map(get(ingredientsGroupedByUsedFeature, usedFeature), 'name'),
          ', ',
        )}
      </Typography.Text>
    </GridFormItem>
  ))
}

UsedFeatureIngredients.propTypes = {
  dishIngredientsWithPath: PropTypes.arrayOf(PropTypes.object),
}

UsedFeatureIngredients.defaultProps = {
  dishIngredientsWithPath: [],
}

export default UsedFeatureIngredients
