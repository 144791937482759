import React, { useCallback } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, notification, Upload as AntUpload } from 'antd'
import PropTypes from 'prop-types'

const styles = {
  uploadButton: {
    marginLeft: '0.75rem',
  },
}

const JsonFileUploadButton = ({ onUpload, text, disabled, loading }) => {
  const readJsonContents = useCallback(
    file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onloadend = ({ target }) => {
          if (target.error) {
            reject(target.error)
          }
          try {
            resolve(JSON.parse(target.result))
          } catch (e) {
            reject(new Error('File does not contain a valid JSON format'))
          }
        }
      }),
    [],
  )

  const handleCustomRequest = useCallback(
    async ({ file }) => {
      try {
        const contents = await readJsonContents(file)
        await onUpload(contents, file.name)
      } catch (error) {
        notification.error({
          message: error,
        })
      }
    },
    [onUpload, readJsonContents],
  )

  return (
    <AntUpload
      disabled={disabled}
      accept=".json"
      showUploadList={false}
      customRequest={handleCustomRequest}
    >
      <Button disabled={disabled} style={styles.uploadButton} loading={loading}>
        {text}
        <UploadOutlined />
      </Button>
    </AntUpload>
  )
}

JsonFileUploadButton.propTypes = {
  onUpload: PropTypes.func.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

JsonFileUploadButton.defaultProps = {
  text: '',
  disabled: false,
  loading: false,
}

export default JsonFileUploadButton
