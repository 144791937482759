import React from 'react'
import { Row, Select } from 'antd'

import { GridFormItem } from '../../../../core/components'

import { DebouncedTextField } from '../../../../common/components'

import { usePersistedLocationField } from '../../../hooks'
import { locationPropType } from '../../../propTypes'
import {
  setLocationAddress,
  setLocationLatitude,
  setLocationLongitude,
  setLocationZip,
} from '../../../redux'

const defaultCity = 'London'
const cities = [{ label: defaultCity, value: defaultCity }]
const defaultCountry = 'England'
const countries = [{ label: defaultCountry, value: defaultCountry }]

const LocationEntryAddress = ({ location }) => {
  const { id: locationId, address, latitude, longitude, zipCode } = location
  const { address: persistedAddress, onValueChanged: addressOnChange } =
    usePersistedLocationField(locationId, 'address', setLocationAddress)

  const { latitude: persistedLatitude, onValueChanged: latitudeOnChange } =
    usePersistedLocationField(locationId, 'latitude', setLocationLatitude)
  const { longitude: persistedLongitude, onValueChanged: longitudeOnChange } =
    usePersistedLocationField(locationId, 'longitude', setLocationLongitude)

  const { zipCode: persistedZipCode, onValueChanged: zipCodeOnChange } =
    usePersistedLocationField(locationId, 'zipCode', setLocationZip)
  return (
    <Row gutter={16}>
      <GridFormItem span={8} label="Street address">
        <DebouncedTextField
          text={address}
          newText={persistedAddress}
          onValueChanged={addressOnChange}
        />
      </GridFormItem>
      <GridFormItem span={2} label="City">
        <Select options={cities} value={defaultCity} disabled />
      </GridFormItem>
      <GridFormItem span={2} label="Country">
        <Select options={countries} value={defaultCountry} disabled />
      </GridFormItem>
      <GridFormItem span={2} label="Zip code">
        <DebouncedTextField
          text={zipCode}
          newText={persistedZipCode}
          onValueChanged={zipCodeOnChange}
        />
      </GridFormItem>
      <GridFormItem span={3} label="Latitude">
        <DebouncedTextField
          text={latitude}
          newText={persistedLatitude}
          onValueChanged={latitudeOnChange}
          inputType="float"
        />
      </GridFormItem>
      <GridFormItem span={3} label="Longitude">
        <DebouncedTextField
          text={longitude}
          newText={persistedLongitude}
          onValueChanged={longitudeOnChange}
          inputType="float"
        />
      </GridFormItem>
    </Row>
  )
}

LocationEntryAddress.propTypes = {
  location: locationPropType,
}

LocationEntryAddress.defaultProps = {
  location: {},
}

export default LocationEntryAddress
