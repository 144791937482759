import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Collapse, Row, Typography } from 'antd'
import { filter, get, isEmpty, isNil, keys, map } from 'lodash'
import PropTypes from 'prop-types'

import { HighlightingTextField } from '../../../../common/components'
import { mergeArrayOfObjects } from '../../../../common/utils'

import './dietDescriptorCollapsible.css'

const styles = {
  spacing: {
    margin: '0 0.5rem',
  },
}

const DietDescriptorCollapse = ({
  highlights,
  value,
  prefix,
  collapsedByDefault,
}) => {
  const [isCollapsed, toggleCollapse] = useState('1')

  useEffect(() => {
    toggleCollapse(collapsedByDefault ? '0' : '1')
  }, [collapsedByDefault])

  const mappedDietDescriptorValuesToHighlightKeys = useMemo(
    () =>
      mergeArrayOfObjects(
        map(value, (dietDescriptor, index) => {
          const parsedDietDescriptor = JSON.parse(dietDescriptor)
          const dietDescriptorKeys = keys(parsedDietDescriptor)

          return mergeArrayOfObjects(
            map(
              filter(
                dietDescriptorKeys,
                key => !isEmpty(get(parsedDietDescriptor, key)),
              ),
              dietDescriptorKey => ({
                [`${prefix}:${dietDescriptorKey}:${index}`]: get(
                  parsedDietDescriptor,
                  dietDescriptorKey,
                ),
              }),
            ),
          )
        }),
      ),
    [prefix, value],
  )

  const highlightingKeys = keys(mappedDietDescriptorValuesToHighlightKeys)

  return (
    <>
      <Row>
        {map(
          filter(
            highlightingKeys,
            highlightKey => !isNil(get(highlights, highlightKey)),
          ),
          (highlightKey, index) => (
            <Fragment key={highlightKey}>
              <HighlightingTextField
                value={get(
                  mappedDietDescriptorValuesToHighlightKeys,
                  highlightKey,
                )}
                highlightingList={get(highlights, highlightKey) || []}
                disabled
              />
              {index + 1 !== highlightingKeys.length && (
                <Typography.Text style={styles.spacing}> </Typography.Text>
              )}
            </Fragment>
          ),
        )}
      </Row>
      <div
        aria-hidden="true"
        onClick={e => {
          toggleCollapse(isCollapsed === '0' ? '1' : '0')
          e.stopPropagation()
        }}
      >
        <Collapse className="diet-collapse" ghost activeKey={[isCollapsed]}>
          <Collapse.Panel key="1" header="{ ... }">
            <Row>
              {map(highlightingKeys, (highlightKey, index) => (
                <Fragment key={highlightKey}>
                  <HighlightingTextField
                    value={get(
                      mappedDietDescriptorValuesToHighlightKeys,
                      highlightKey,
                    )}
                    highlightingList={get(highlights, highlightKey) || []}
                    disabled
                  />
                  {index + 1 !== highlightingKeys.length && (
                    <Typography.Text style={styles.spacing}> </Typography.Text>
                  )}
                </Fragment>
              ))}
            </Row>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  )
}

DietDescriptorCollapse.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  collapsedByDefault: PropTypes.bool,
  highlights: PropTypes.object,
  prefix: PropTypes.string,
}

DietDescriptorCollapse.defaultProps = {
  collapsedByDefault: false,
  highlights: {},
  prefix: 'dietDescriptors',
}

export default DietDescriptorCollapse
