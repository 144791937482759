const { REACT_APP_AI_SERVICE_AUTHORIZATION_TOKEN } = window._env_

const aiReportsEndpoints = {
  generateReport: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/reports/generate`,
  availableReports: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/reports`,
}

export const sendUserReportRequest = async request => {
  const response = await fetch(`${aiReportsEndpoints.generateReport}`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      authorization: `Bearer ${REACT_APP_AI_SERVICE_AUTHORIZATION_TOKEN}`,
      'content-type': 'application/json',
    },
  })

  const responseBody = await response.json()
  if (!response.ok) {
    throw new Error(JSON.stringify(responseBody))
  }

  return responseBody
}

export const getAvailableReports = async () => {
  const response = await fetch(`${aiReportsEndpoints.availableReports}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${REACT_APP_AI_SERVICE_AUTHORIZATION_TOKEN}`,
    },
  })

  const responseBody = await response.json()
  if (!response.ok) {
    throw new Error('Failed to get availalbe reports')
  }

  return responseBody
}
