import React, { memo, useCallback, useContext, useMemo, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from '@apollo/client'
import { Col, Radio, Row, Spin, Tooltip, TreeSelect, Typography } from 'antd'
import { includes, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { HOTKEYS_ENABLED_TAGS } from '../../../../common/constants'
import {
  handleMutationError,
  handleTreeSelectCustomDropdownForSearch,
} from '../../../../common/helpers'
import { transformTree } from '../../../../common/transformers'
import { findNodeByValue } from '../../../../common/utils'

import { AVAILABLE_HOTKEYS, ML_CLEANUP_TASK_CHOICE } from '../../../constants'
import { CleanupTableFocusActionsContext } from '../../../contexts'
import { UPDATE_ML_CLEANUP_CURATION_VALUES_MUTATION } from '../../../graphql'
import { cleanupTasksMetadataTypePropType } from '../../../propTypes'

const styles = {
  radioGroup: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
  text: {
    fontSize: '14px',
  },
}

const MlCleanupTaskSelect = ({
  id,
  metadataType,
  choice,
  currentValue,
  suggestedValue,
  otherValue,
  otherFieldName,
  treeData,
  disabled,
}) => {
  const selectRef = useRef(null)
  const { selectedRowKey, hotkeyEditMode, setHotkeyEditMode } = useContext(
    CleanupTableFocusActionsContext,
  )
  const [change, { loading }] = useMutation(
    UPDATE_ML_CLEANUP_CURATION_VALUES_MUTATION,
    {
      onError: handleMutationError,
    },
  )

  const suggestedNode = useMemo(
    () => findNodeByValue(treeData, suggestedValue),
    [treeData, suggestedValue],
  )
  const currentNode = useMemo(
    () => findNodeByValue(treeData, currentValue),
    [treeData, currentValue],
  )
  const treeDataWithDisabledValues = useMemo(
    () =>
      transformTree(treeData, node => ({
        ...node,
        disabled: includes(
          [suggestedNode.value, currentNode.value],
          node.value,
        ),
      })),
    [currentNode.value, suggestedNode.value, treeData],
  )

  const handleOtherChange = useCallback(
    async newValue => {
      await change({
        variables: {
          ids: [id],
          curationValues: {
            choice: ML_CLEANUP_TASK_CHOICE.OTHER,
            [otherFieldName]: newValue,
          },
          metadataType,
        },
      })
      setHotkeyEditMode(false)
    },
    [change, otherFieldName, id, metadataType, setHotkeyEditMode],
  )

  const handleChoiceChange = useCallback(
    async radio => {
      await change({
        variables: {
          ids: [id],
          curationValues: {
            choice: radio.target.value,
          },
          metadataType,
        },
      })
    },
    [change, id, metadataType],
  )

  const handleFocusSelect = useCallback(() => {
    selectRef.current.focus()
    setHotkeyEditMode(true)
  }, [setHotkeyEditMode])

  const handleFocusBlur = useCallback(() => {
    selectRef.current.blur()
    setHotkeyEditMode(false)
  }, [setHotkeyEditMode])

  useHotkeys(
    AVAILABLE_HOTKEYS.C.hotkey,
    () => {
      handleChoiceChange({ target: { value: ML_CLEANUP_TASK_CHOICE.CURRENT } })
    },
    {
      enabled: !hotkeyEditMode && id === selectedRowKey && !disabled,
    },
    [hotkeyEditMode, id, selectedRowKey, disabled, handleChoiceChange],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.M.hotkey,
    () => {
      handleChoiceChange({
        target: { value: ML_CLEANUP_TASK_CHOICE.SUGGESTED },
      })
    },
    {
      enabled: !hotkeyEditMode && id === selectedRowKey && !disabled,
    },
    [hotkeyEditMode, id, selectedRowKey, disabled, handleChoiceChange],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.O.hotkey,
    () => {
      handleChoiceChange({ target: { value: ML_CLEANUP_TASK_CHOICE.OTHER } })
    },
    {
      enabled:
        !hotkeyEditMode &&
        id === selectedRowKey &&
        !disabled &&
        !isNil(otherValue),
    },
    [
      hotkeyEditMode,
      id,
      selectedRowKey,
      disabled,
      otherValue,
      handleChoiceChange,
    ],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.ENTER.hotkey,
    handleFocusSelect,
    {
      enabled: !hotkeyEditMode && id === selectedRowKey && !disabled,
    },
    [hotkeyEditMode, id, selectedRowKey, disabled],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.ESC.hotkey,
    handleFocusBlur,
    {
      enabled: id === selectedRowKey,
      enableOnTags: HOTKEYS_ENABLED_TAGS,
    },
    [id, selectedRowKey],
  )

  if (loading) {
    return <Spin size="small" />
  }
  return (
    <Radio.Group
      onChange={handleChoiceChange}
      value={choice}
      style={styles.radioGroup}
    >
      <Row>
        <Col span={10}>
          <Radio disabled={disabled} value={ML_CLEANUP_TASK_CHOICE.CURRENT}>
            Current
          </Radio>
        </Col>
        <Col span={14}>
          <Tooltip title={currentNode?.namePath}>
            <Typography.Text style={styles.text}>
              {currentNode?.title}
            </Typography.Text>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Radio disabled={disabled} value={ML_CLEANUP_TASK_CHOICE.SUGGESTED}>
            ML suggested
          </Radio>
        </Col>
        <Col span={14}>
          <Tooltip title={suggestedNode?.namePath}>
            <Typography.Text style={styles.text}>
              {suggestedNode?.title}
            </Typography.Text>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Radio
            disabled={disabled || isNil(otherValue)}
            value={ML_CLEANUP_TASK_CHOICE.OTHER}
          >
            Other
          </Radio>
        </Col>
        <Col span={14}>
          <TreeSelect
            ref={selectRef}
            style={styles.select}
            value={otherValue}
            onChange={handleOtherChange}
            treeData={treeDataWithDisabledValues}
            disabled={disabled}
            treeNodeFilterProp="title"
            dropdownRender={handleTreeSelectCustomDropdownForSearch}
            showSearch
          />
        </Col>
      </Row>
    </Radio.Group>
  )
}

MlCleanupTaskSelect.propTypes = {
  id: PropTypes.string.isRequired,
  choice: PropTypes.string,
  currentValue: PropTypes.string,
  otherValue: PropTypes.string,
  suggestedValue: PropTypes.string,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  otherFieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  treeData: PropTypes.object,
}

MlCleanupTaskSelect.defaultProps = {
  choice: undefined,
  currentValue: undefined,
  otherValue: undefined,
  suggestedValue: undefined,
  disabled: false,
  treeData: undefined,
}

export default memo(MlCleanupTaskSelect)
