import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { PropTypes } from 'prop-types'

import theme from '../../../../theme'
import { HEADER_HEIGHT } from '../../../constants'
import { setActiveMenuItem } from '../../../redux/actions'
import { HeaderNavigation } from '../HeaderNavigation'

const styles = {
  header: {
    zIndex: 100,
    position: 'fixed',
    width: '100%',
    height: HEADER_HEIGHT,
    padding: 0,
    display: 'flex',
    lineHeight: '46px',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  content: {
    marginTop: HEADER_HEIGHT,
  },
}

const PageWithHeader = ({ menuItem, children, renderRightMenu, padding }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (menuItem) {
      dispatch(setActiveMenuItem(menuItem))
    }
  }, [menuItem, dispatch])

  return (
    <Layout>
      <Layout.Header style={styles.header}>
        <HeaderNavigation renderRightMenu={renderRightMenu} />
      </Layout.Header>
      <Layout.Content
        style={{
          ...styles.content,
          ...(padding ? { padding: theme.padding } : {}),
        }}
      >
        {children}
      </Layout.Content>
    </Layout>
  )
}

PageWithHeader.propTypes = {
  menuItem: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderRightMenu: PropTypes.func,
  padding: PropTypes.bool,
}

PageWithHeader.defaultProps = {
  menuItem: undefined,
  renderRightMenu: undefined,
  padding: false,
}

export default PageWithHeader
