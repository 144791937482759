import { gql } from '@apollo/client'

const INGREDIENT_EXPLANATIONS_QUERY = gql`
  query ingredientExplanationsQuery($dishId: ID!) {
    ingredientExplanations(dishId: $dishId) {
      id
      text
      originField
      ingredientId
      ingredientType
    }
  }
`

export default INGREDIENT_EXPLANATIONS_QUERY
