import { gql } from '@apollo/client'

const INGREDIENTS_RERUN_SESSION_FRAGMENT = gql`
  fragment IngredientsRerunSessionFragment on IngredientsRerunSession {
    id
    status
    errorMessage
    createdAt
    canceledAt
    completedAt
    readyForReviewAt
    canceledBy {
      id
      name
    }
    createdBy {
      id
      name
    }
  }
`
export default INGREDIENTS_RERUN_SESSION_FRAGMENT
