import { gql } from '@apollo/client'

const QA_BRAND_CORRECTION_COUNTS_FRAGMENT = gql`
  fragment QaBrandCorrectionCountsFragment on QaBrandCorrectionCounts {
    menuTitles
    cuisineTypeId
    locationTypeId
    allergenLabelLegend
    dietLabelLegend
    languageIds
  }
`

export default QA_BRAND_CORRECTION_COUNTS_FRAGMENT
