import { gql } from '@apollo/client'

const AI_SETTING_FRAGMENT = gql`
  fragment AiSettingFragment on AiSetting {
    id
    name
    label
    isEnabled
  }
`
export default AI_SETTING_FRAGMENT
