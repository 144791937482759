export default Object.freeze({
  DOWN: {
    hotkey: 'down',
    description:
      'Activate the dish list navigation for first use / Select the next dish/brand in the list / Navigate inside a select dropdown',
  },
  UP: {
    hotkey: 'up',
    description:
      'Select the previous dish/brand in the list / Navigate inside a select dropdown',
  },
  LEFT: {
    hotkey: 'left',
    description:
      'Select the next dish field of the selected dish / Collapse an option when navigating inside a tree select',
  },
  RIGHT: {
    hotkey: 'right',
    description:
      'Select the previous dish field of the selected dish / Expand an option when navigating inside a tree select',
  },
  CMD_LEFT: {
    hotkey: 'cmd+left',
    description: 'Go to previous page',
  },
  CMD_RIGHT: {
    hotkey: 'cmd+right',
    description: 'Go to next page',
  },
  CTRL_LEFT: {
    hotkey: 'ctrl+left',
    description: 'Go to previous page',
  },
  CTRL_RIGHT: {
    hotkey: 'ctrl+right',
    description: 'Go to next page',
  },
  ALT_LEFT: {
    hotkey: 'alt+left',
    description:
      'Go to previous menu title (if a menu title is selected in the dish filters)',
  },
  ALT_RIGHT: {
    hotkey: 'alt+right',
    description:
      'Go to next menu title (if a menu title is selected in the dish filters)',
  },
  OPTION_LEFT: {
    hotkey: 'option+left',
    description:
      'Go to previous menu title (if a menu title is selected in the dish filters)',
  },
  OPTION_RIGHT: {
    hotkey: 'option+right',
    description:
      'Go to next menu title (if a menu title is selected in the dish filters)',
  },
  SHIFT_DOWN: {
    hotkey: 'shift+down',
    description: 'Go to end of list',
  },
  SHIFT_UP: {
    hotkey: 'shift+up',
    description: 'Go to start of list',
  },
  SHIFT_LEFT: {
    hotkey: 'shift+left',
    description: 'Go to start of row',
  },
  SHIFT_RIGHT: {
    hotkey: 'shift+right',
    description: 'Go to end of row',
  },
  P: {
    hotkey: 'p',
    description: 'Open/Close ingredient popover for selected dish',
  },
  SPACE: {
    hotkey: 'space',
    description: 'Accept/Reject ingredient popover discrepency',
  },
  SHIFT_P: {
    hotkey: 'shift+p',
    description: 'Open/Close choice ingredient popover for selected dish',
  },
  S: {
    hotkey: 's',
    description: 'Validate / Publish / QA done the selected dish / brand',
  },
  OPTION_P: {
    hotkey: 'option+p',
    description: 'Open/Close ingredient explanations popover for selected dish',
  },
  ALT_P: {
    hotkey: 'alt+p',
    description: 'Open/Close ingredient explanations popover for selected dish',
  },
  SHIFT_S: {
    hotkey: 'shift+s',
    description: 'Validate all dishes/brands from current page',
  },
  SHIFT_C: {
    hotkey: 'shift+c',
    description: 'Start/Confirm batch curation',
  },
  CMD_C: {
    hotkey: 'cmd+c',
    description: 'Copy to clipboard the link to the selected dish',
  },
  CTRL_C: {
    hotkey: 'ctrl+c',
    description: 'Copy to clipboard the link to the selected dish',
  },
  ENTER: {
    hotkey: 'enter',
    description: 'Start editing the selected cell',
  },
  ESC: {
    hotkey: 'esc',
    description: 'Stop/Save editing of the selected cell',
  },
  X: {
    hotkey: 'x',
    description: 'Clear ignored dish type on selected row',
  },
  C: {
    hotkey: 'c',
    description:
      'Clear the misc and choice from the selected cell (only applies to text fields) / Select radio button Current for the current focused row (During ML cleanup task)',
  },
  TAB: {
    hotkey: 'tab',
    description:
      'Cycle between ingredients select when an editable ingredient cell is in focus',
  },
  CMD_I: {
    hotkey: 'cmd+i',
    description: 'Mark text as choice text when a text field is in edit mode',
  },
  CMD_U: {
    hotkey: 'cmd+u',
    description: 'Mark text as misc text when a text field is in edit mode',
  },
  CTRL_I: {
    hotkey: 'ctrl+i',
    description: 'Mark text as choice text when a text field is in edit mode',
  },
  CTRL_U: {
    hotkey: 'ctrl+u',
    description: 'Mark text as misc text when a text field is in edit mode',
  },
  SHIFT_L: {
    hotkey: 'shift+l',
    description: 'Suggest label legends',
  },
  SHIFT_U: {
    hotkey: 'shift+u',
    description: 'Suggest/Open unknown words',
  },
  L: {
    hotkey: 'L',
    description: 'Open/close the hotkey legend',
  },
  O: {
    hotkey: 'O',
    description: 'Select radio button Other for the current focused row',
  },
  M: {
    hotkey: 'M',
    description: 'Select radio button ML for the current focused row',
  },
  CMD_D: {
    hotkey: 'cmd+d',
    description: 'Mark the task as curation done',
  },
  CTRL_D: {
    hotkey: 'ctrl+d',
    description: 'Mark the task as curation done',
  },
  R: {
    hotkey: 'r',
    description: 'Mark the dish/brand for batch operation',
  },
  SHIFT_R: {
    hotkey: 'shift+r',
    description: 'Mark all dishes/brands for batch operation',
  },
  CMD_R: {
    hotkey: 'cmd+r',
    description: 'Start editing for batch operation',
  },
  CTRL_R: {
    hotkey: 'ctrl+r',
    description: 'Start editing for batch operation',
  },
})
