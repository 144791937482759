import { filter, flatten, forEach, isEmpty, map } from 'lodash'

import { areSameValue } from '../../core/utils'

import { CURATION_CERTAINTY_TRESHOLD } from '../../common/constants'
import { variousTextStringTransformer } from '../../common/transformers'
import {
  containsMiscOrChoiceText,
  findNodeBy,
  replaceNewLineAndSpace,
  replaceTextFragments,
} from '../../common/utils'

import { MENU_TITLE_STATUS_TYPE } from '../constants'

export const transformMenuTitlesToAiRequestDto = (menuTitles, result = []) => {
  if (isEmpty(menuTitles)) {
    return result
  }

  const filteredMenuTitles = map(
    filter(
      menuTitles,
      ({ styledDescription, description }) =>
        description && !containsMiscOrChoiceText(styledDescription),
    ),
    ({ id, description }) => ({ id, description }),
  )

  return transformMenuTitlesToAiRequestDto(
    flatten(map(menuTitles, ({ children }) => children || [])),
    [...result, ...filteredMenuTitles],
  )
}

export const transformMenuTitlesFromAiResponseDto =
  menuTitles => responseBody => ({
    messages: flatten(
      map(
        filter(responseBody, ({ messages }) => !isEmpty(messages)),
        'messages',
      ),
    ),
    predictions: map(
      filter(
        responseBody,
        ({ description }) =>
          !isEmpty(description?.misc) || !isEmpty(description?.choices),
      ),
      menuTitleSuggestion => {
        let variousTextParts = []
        let fragmentsToReplace = []

        forEach(['misc', 'choices'], type => {
          forEach(menuTitleSuggestion.description[type], ({ index, score }) => {
            const word = menuTitleSuggestion.description.text.substring(
              index[0],
              index[1],
            )
            variousTextParts = [
              ...variousTextParts,
              {
                part: word,
                score,
                type,
              },
            ]
            fragmentsToReplace = [
              ...fragmentsToReplace,
              {
                index,
                word:
                  type === 'misc'
                    ? variousTextStringTransformer.toMiscText(word)
                    : variousTextStringTransformer.toChoiceText(word),
              },
            ]
          })
        })

        const menuTitle = findNodeBy(menuTitles, ({ id }) =>
          areSameValue(id, menuTitleSuggestion.id),
        )

        return {
          id: menuTitleSuggestion.id,
          path: menuTitle.path,
          parents: menuTitle.parents,
          styledDescription: `<p>${replaceNewLineAndSpace(
            replaceTextFragments(
              menuTitleSuggestion.description.text,
              fragmentsToReplace,
            ),
          )}</p>`,
          name: menuTitle.name,
          description: menuTitle.description,
          status:
            menuTitleSuggestion.certainty < CURATION_CERTAINTY_TRESHOLD
              ? MENU_TITLE_STATUS_TYPE.PENDING
              : MENU_TITLE_STATUS_TYPE.AI_CONFIRMED,
          score: menuTitleSuggestion.description.score,
          certainty: menuTitleSuggestion.certainty,
          segments: variousTextParts,
        }
      },
    ),
  })
