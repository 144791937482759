import React, { memo, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm, Tooltip } from 'antd'
import { truncate } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import { EVENT_TYPES, idNew } from '../../../../common/constants'
import { LoadingContext } from '../../../../common/contexts'
import { handleMutationError } from '../../../../common/helpers'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DELETE_DISH_MUTATION } from '../../../graphql'
import { removeUnsavedDish } from '../../../redux'

const DeleteDishButton = ({ name, style, size, refetchBrand, disabled }) => {
  const dispatch = useDispatch()
  const { brandId } = useParams()
  const { selectedDishId, setQueryParams } = useQueryParams()
  const { startLoading, stopLoading } = useContext(LoadingContext)

  const [deleteDish, { loading }] = useMutation(DELETE_DISH_MUTATION, {
    onError: handleMutationError,
  })

  const confirmDelete = useCallback(async () => {
    startLoading()
    if (selectedDishId !== idNew) {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.DELETE_CONFIRM,
        { brandId },
      )
      await deleteDish({ variables: { id: selectedDishId } })
    }
    dispatch(removeUnsavedDish(selectedDishId))
    setQueryParams({ selectedDishId: null })
    if (refetchBrand) {
      refetchBrand()
    }

    stopLoading()
  }, [
    startLoading,
    refetchBrand,
    selectedDishId,
    dispatch,
    setQueryParams,
    stopLoading,
    brandId,
    deleteDish,
  ])

  return (
    <Popconfirm
      placement="left"
      title={name ? `Delete "${truncate(name)}" dish?` : 'Delete new dish?'}
      onConfirm={confirmDelete}
      onCancel={() =>
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_DISH_EVENTS.DELETE_CANCEL,
        )
      }
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title={disabled ? 'Brand is changed' : undefined}>
        <Button
          size={size}
          icon={<DeleteOutlined />}
          style={style}
          disabled={loading || disabled}
          onClick={() =>
            googleAnalyticsEventsService.fireEvent(
              EVENT_TYPES.BRAND_DISH_EVENTS.DELETE_CLICK,
            )
          }
        />
      </Tooltip>
    </Popconfirm>
  )
}

DeleteDishButton.propTypes = {
  refetchBrand: PropTypes.func,
  name: PropTypes.string,
  style: PropTypes.shape({}),
  size: PropTypes.string,
  disabled: PropTypes.bool,
}

DeleteDishButton.defaultProps = {
  name: undefined,
  style: undefined,
  size: undefined,
  refetchBrand: undefined,
  disabled: false,
}

export default memo(DeleteDishButton)
