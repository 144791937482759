import { gql } from '@apollo/client'

const DUPLICATE_BRAND_FOR_AI_TESTING_MUTATION = gql`
  mutation duplicateBrandForAiTesting($input: DuplicateForAiTestingInput!) {
    duplicateBrandForAiTesting(input: $input) {
      id
      name
    }
  }
`

export default DUPLICATE_BRAND_FOR_AI_TESTING_MUTATION
