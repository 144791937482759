import { gql } from '@apollo/client'

const ML_CLEANUP_TASK_REQUEST_FRAGMENT = gql`
  fragment MlCleanupTaskRequestFragment on MlCleanupTaskRequest {
    id
    status
  }
`

export default ML_CLEANUP_TASK_REQUEST_FRAGMENT
