import { gql } from '@apollo/client'

import { USER_REPORT_REQUEST_FRAGMENT } from '../fragments'

export const CREATE_USER_REPORT_REQUEST_MUTATION = gql`
  mutation createUserReportRequestMutation($input: UserReportRequestInput!) {
    createUserReportRequest(input: $input) {
      ...UserReportRequestFragment
    }
  }
  ${USER_REPORT_REQUEST_FRAGMENT}
`
