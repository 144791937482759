import React, { useCallback } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Col, Row, Tooltip, TreeSelect } from 'antd'
import { get, isEmpty, isNil, join, keys, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { treeOptionPropType } from '../../../../common/propTypes'
import { findNodeByValue } from '../../../../common/utils'

import { SpanPropType } from '../../../propTypes'
import { GridFormItem } from '../../atoms'
import { TreeNodeList } from '../TreeNodeList'

const styles = {
  hiddenDropdown: {
    display: 'none',
  },
  selectedValues: {
    marginRight: '1rem',
    marginBottom: 0,
  },
  noMarginBottom: { marginBottom: 0 },
}

const MultipleTreeSelect = ({
  name,
  style,
  className,
  treeData,
  disabled,
  size,
  placeholder,
  labelProp,
  filterProp,
  onlyRemovable,
  label,
  span,
  newValueSpan,
}) => {
  const { control, errors, watch, clearErrors } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })

  const handleOnChange = useCallback(
    (index, onChange) => value => {
      // normally we should clear errors on every change
      // but due to a bug in the current version of react hook form we need to make sure we only clear errors
      // as long as we have values
      // we can't update the version of react hook form as the new versions have a buggy deep clone implementation
      // that breaks the RichTextInput and every draftjs component used in conjunction with react hook form
      // fix for the error - https://github.com/react-hook-form/react-hook-form/issues/3482
      // file that needs to change - https://github.com/react-hook-form/react-hook-form/blob/master/src/utils/cloneObject.ts
      if (fields.length !== 1 || !isNil(value)) {
        clearErrors()
      }
      if (isNil(value)) {
        remove(index)
      } else {
        onChange(value)
      }
    },
    [clearErrors, fields.length, remove],
  )

  const addNewItem = useCallback(
    value => {
      if (!some(watch(name), ({ value: existingId }) => value === existingId)) {
        append({ value })
      }
    },
    [append, watch, name],
  )

  /*
   * Default values for field array can only be set using the defaultValues on the useForm
   */
  return (
    <GridFormItem span={span} label={label} style={styles.noMarginBottom}>
      <Row>
        {map(fields, (item, index) => (
          <Controller
            key={`${name}-${item.id}`}
            control={control}
            defaultValue={item.value}
            name={`${name}[${index}].value`}
            render={({ field: { onChange, onBlur, value } }) => {
              const {
                title,
                value: nodeValue,
                key,
              } = findNodeByValue(treeData, value)
              const errorsForElement = get(errors, `${name}[${index}]`)
              return (
                <Tooltip title={title}>
                  <GridFormItem
                    style={styles.selectedValues}
                    validateStatus={
                      !isEmpty(errorsForElement) ? 'error' : undefined
                    }
                    help={join(
                      map(keys(errorsForElement), errorKey =>
                        get(errorsForElement, `${errorKey}.message`),
                      ),
                      ';\n',
                    )}
                  >
                    <TreeSelect
                      disabled={disabled}
                      size={size}
                      dropdownStyle={
                        onlyRemovable ? styles.hiddenDropdown : undefined
                      }
                      className={className}
                      treeData={treeData}
                      treeNodeLabelProp={labelProp}
                      style={{ ...(style || {}) }}
                      value={nodeValue}
                      treeDefaultExpandedKeys={[key]}
                      allowClear
                      showSearch
                      treeNodeFilterProp={filterProp}
                      onChange={handleOnChange(index, onChange)}
                      onBlur={onBlur}
                      dropdownMatchSelectWidth={300}
                    >
                      <TreeNodeList treeData={treeData} />
                    </TreeSelect>
                  </GridFormItem>
                </Tooltip>
              )
            }}
          />
        ))}
        {!onlyRemovable && (
          <Col span={newValueSpan}>
            <TreeSelect
              size={size}
              treeData={treeData}
              treeNodeLabelProp={labelProp}
              placeholder={placeholder}
              value={null}
              showSearch
              treeNodeFilterProp={filterProp}
              disabled={disabled}
              onChange={addNewItem}
            >
              <TreeNodeList treeData={treeData} />
            </TreeSelect>
          </Col>
        )}
      </Row>
    </GridFormItem>
  )
}

MultipleTreeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  treeData: PropTypes.arrayOf(treeOptionPropType).isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  labelProp: PropTypes.string,
  filterProp: PropTypes.string,
  onlyRemovable: PropTypes.bool,
  label: PropTypes.string,
  span: SpanPropType,
  newValueSpan: SpanPropType,
}

MultipleTreeSelect.defaultProps = {
  style: undefined,
  className: undefined,
  disabled: false,
  size: 'default',
  placeholder: 'Select or search',
  labelProp: 'path',
  filterProp: 'title',
  onlyRemovable: false,
  label: undefined,
  span: undefined,
  newValueSpan: 4,
}

export default MultipleTreeSelect
