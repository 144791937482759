import { gql } from '@apollo/client'

import { BRAND_DISH_FRAGMENT } from '../fragments'

export default gql`
  mutation upsertBrandDish(
    $input: UpsertBrandDishInput!
    $isDishListAction: Boolean
  ) {
    upsertBrandDish(input: $input, isDishListAction: $isDishListAction) {
      ...BrandDishFragment

      ingredientExplanations {
        id
        text
        originField
        ingredientId
        ingredientType
      }
    }
  }
  ${BRAND_DISH_FRAGMENT}
`
