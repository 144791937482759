import { filter, find } from 'lodash'

import { EVENT_TYPES } from '../../../common/constants'
import { googleAnalyticsEventsService } from '../../../common/services'

import { UNSAVED_LOCATION_ACTION_TYPES } from '../actionTypes'

const updateLocationProp = (locations, locationId, propName, propValue) => {
  const existingLocation = find(locations, ({ id }) => id === locationId)

  if (existingLocation) {
    return locations.map(location =>
      location !== existingLocation
        ? location
        : {
            ...location,
            [propName]: propValue,
          },
    )
  }

  googleAnalyticsEventsService.fireEvent(
    EVENT_TYPES.BRAND_LOCATION_EVENTS.INPUT_CHANGE,
    { input: propName },
  )

  return [...locations, { id: locationId, [propName]: propValue }]
}

const unsavedLocationReducer = (locations = [], action) => {
  switch (action.type) {
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_NAME: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'name',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LOCATION_TYPE_ID: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'locationTypeId',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LOCATION_CUISINE_TYPE_ID: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'cuisineTypeId',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_EMAIL: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'email',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_PHONE: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'phone',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_WEB: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'web',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_ADDRESS: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'address',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_ZIP_CODE: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'zipCode',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LATITUDE: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'latitude',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LONGITUDE: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'longitude',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_WORKING_HOURS: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'workingHours',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_SERVICING_HOURS: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'servicingHours',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_INCOMPLETE: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'incomplete',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_MENU_SOURCE: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'menuSource',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_MENU_LINK: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'menuLink',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_SERVICING_HOURS_OVERRIDEN: {
      return updateLocationProp(
        locations,
        action.payload.locationId,
        'servicingHoursOverriden',
        action.payload.value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.REMOVE_LOCATION: {
      return filter(locations, ({ id }) => id !== action.payload)
    }
    default: {
      return locations
    }
  }
}

export default unsavedLocationReducer
