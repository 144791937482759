import React from 'react'
import { Row, Typography } from 'antd'

import { GridFormItem } from '../../../../core/components'

import { DebouncedTextField } from '../../../../common/components'

import { usePersistedLocationField } from '../../../hooks'
import { locationPropType } from '../../../propTypes'
import {
  setLocationIncomplete,
  setLocationMenuLink,
  setLocationMenuSource,
} from '../../../redux'
import { OverridableCheckbox } from '../../molecules'

const LocationEntryMenuAndStatus = ({ location }) => {
  const { id: locationId, status, incomplete, menuSource, menuLink } = location

  const {
    incomplete: persistedIncomplete,
    onValueChanged: incompleteOnChange,
  } = usePersistedLocationField(locationId, 'incomplete', setLocationIncomplete)
  const { menuLink: persistedMenuLink, onValueChanged: menuLinkOnChange } =
    usePersistedLocationField(locationId, 'menuLink', setLocationMenuLink)
  const {
    menuSource: persistedMenuSource,
    onValueChanged: menuSourceOnChange,
  } = usePersistedLocationField(locationId, 'menuSource', setLocationMenuSource)

  return (
    <Row gutter={16} align="bottom">
      <GridFormItem span={4} label="Status">
        <Typography.Text>{status}</Typography.Text>
      </GridFormItem>
      <GridFormItem span={3} label="Menu source">
        <DebouncedTextField
          text={menuSource}
          newText={persistedMenuSource}
          onValueChanged={menuSourceOnChange}
        />
      </GridFormItem>
      <GridFormItem span={10} label="Menu link">
        <DebouncedTextField
          text={menuLink}
          newText={persistedMenuLink}
          onValueChanged={menuLinkOnChange}
        />
      </GridFormItem>
      <GridFormItem span={4} labelAbove={false} label="Incomplete Menu">
        <OverridableCheckbox
          checked={incomplete}
          newChecked={persistedIncomplete}
          onValueChanged={incompleteOnChange}
        />
      </GridFormItem>
    </Row>
  )
}

LocationEntryMenuAndStatus.propTypes = {
  location: locationPropType,
}

LocationEntryMenuAndStatus.defaultProps = {
  location: {},
}

export default LocationEntryMenuAndStatus
