import React, { memo } from 'react'
import { Col, Row, Typography } from 'antd'
import { filter, get, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'

import { HighlightingTextField } from '../../../../common/components'

const styles = {
  menuTitleContainer: {
    lineHeight: 1.2,
    fontSize: 14,
    alignItems: 'top',
    display: 'flex',
    marginBottom: '1rem',
    b: {
      marginRight: '0.5rem',
    },
  },
  menuTitleAddonDescriptors: {
    item: {
      marginRight: '0.5rem',
    },
    container: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
    },
  },
}

const DishMenuTitleDetailsRows = ({ menuTitles, highlights }) =>
  map(
    filter(
      menuTitles,
      ({
        menuTitleDescription,
        menuTitleStyledDescription,
        menuTitleAddonDescriptors,
      }) =>
        menuTitleDescription ||
        (menuTitleStyledDescription &&
          menuTitleStyledDescription !== '<p></p>') ||
        !isEmpty(menuTitleAddonDescriptors),
    ),
    ({
      menuTitleId,
      menuTitleName,
      menuTitleDescription,
      menuTitleStyledDescription,
      menuTitleAddonDescriptors,
    }) => (
      <Row key={menuTitleId}>
        <Col span={12} style={styles.menuTitleContainer}>
          <Typography.Text strong style={styles.menuTitleContainer.b}>
            {`${menuTitleName}${
              menuTitleStyledDescription || menuTitleDescription ? ': ' : ''
            }`}
          </Typography.Text>

          <div>
            <HighlightingTextField
              value={menuTitleStyledDescription || menuTitleDescription}
              highlightingList={
                get(highlights, `menuTitleDescriptionId_${menuTitleId}`) || []
              }
              disabled
            />
          </div>
        </Col>
        <Col span={12} style={styles.menuTitleContainer}>
          {!isEmpty(menuTitleAddonDescriptors) && (
            <>
              <Typography.Text strong style={styles.menuTitleContainer.b}>
                Addon Descriptors:{' '}
              </Typography.Text>
              <div style={styles.menuTitleAddonDescriptors.container}>
                {map(menuTitleAddonDescriptors, (descriptor, index) => [
                  <div
                    key={`${menuTitleId}-${index}`}
                    style={styles.menuTitleAddonDescriptors.item}
                  >
                    <HighlightingTextField
                      value={descriptor}
                      highlightingList={
                        get(
                          highlights,
                          `menuTitleAddonDescriptorId_${menuTitleId}_Index_${index}`,
                        ) || []
                      }
                      disabled
                    />
                  </div>,
                  ...(index !== menuTitleAddonDescriptors.length - 1
                    ? [
                        <Typography.Text
                          key={`semicolon-${index}`}
                          style={styles.menuTitleAddonDescriptors.item}
                        >
                          ;
                        </Typography.Text>,
                      ]
                    : []),
                ])}
              </div>
            </>
          )}
        </Col>
      </Row>
    ),
  )

DishMenuTitleDetailsRows.propTypes = {
  menuTitles: PropTypes.arrayOf(
    PropTypes.shape({
      menuTitleId: PropTypes.string.isRequired,
      menuTitleName: PropTypes.string.isRequired,
      menuTitleDescription: PropTypes.string,
      menuTitleStyledDescription: PropTypes.string,
      menuTitleAddonDescriptors: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  highlights: PropTypes.object,
}

export default memo(DishMenuTitleDetailsRows)
