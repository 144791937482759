import lscache from 'lscache'

const LS_KEY_USER = 'user'
const LS_KEY_ACCESS_TOKEN = 'accessToken'
const LS_KEY_REFRESH_TOKEN = 'refreshToken'
const LS_KEY_GOOGLE_ACCESS_TOKEN = 'googleAccessToken'

const ACCESS_TOKEN_EXPIRATION_TIME = 60 * 24 * 7
const REFRESH_TOKEN_EXPIRATION_TIME = 60 * 24 * 100
const GOOGLE_ACCESS_TOKEN_EXPIRATION_TIME = 60

export const createSession = (
  user,
  accessToken,
  refreshToken,
  googleAccessToken,
) => {
  lscache.set(LS_KEY_USER, user, ACCESS_TOKEN_EXPIRATION_TIME)
  lscache.set(LS_KEY_ACCESS_TOKEN, accessToken, ACCESS_TOKEN_EXPIRATION_TIME)

  if (refreshToken) {
    lscache.set(
      LS_KEY_REFRESH_TOKEN,
      refreshToken,
      REFRESH_TOKEN_EXPIRATION_TIME,
    )
  }

  if (googleAccessToken) {
    lscache.set(
      LS_KEY_GOOGLE_ACCESS_TOKEN,
      googleAccessToken,
      GOOGLE_ACCESS_TOKEN_EXPIRATION_TIME,
    )
  }
}

export const getAccessToken = () => lscache.get(LS_KEY_ACCESS_TOKEN)
export const getRefreshToken = () => lscache.get(LS_KEY_REFRESH_TOKEN)

export const getGoogleAccessToken = () =>
  lscache.get(LS_KEY_GOOGLE_ACCESS_TOKEN)

export const getUser = () => lscache.get(LS_KEY_USER)

export const destroySession = () => {
  lscache.flush()
}
