import { createPayloadAction } from '../../../common/utils'

import { UNSAVED_LOCATION_ACTION_TYPES } from '../actionTypes'

export const setLocationName = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_NAME,
)
export const setLocationTypeId = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LOCATION_TYPE_ID,
)
export const setCuisineTypeId = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LOCATION_CUISINE_TYPE_ID,
)
export const setLocationEmail = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_EMAIL,
)
export const setLocationPhone = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_PHONE,
)
export const setLocationWebsite = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_WEB,
)
export const setLocationAddress = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_ADDRESS,
)
export const setLocationZip = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_ZIP_CODE,
)
export const setLocationLatitude = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LATITUDE,
)
export const setLocationLongitude = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_LONGITUDE,
)
export const setLocationWorkingHours = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_WORKING_HOURS,
)
export const setLocationServicingHours = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_SERVICING_HOURS,
)
export const setLocationIncomplete = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_INCOMPLETE,
)
export const setLocationMenuSource = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_MENU_SOURCE,
)
export const setLocationMenuLink = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_MENU_LINK,
)
export const setLocationServicingHoursOverriden = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.SET_LOCATION_SERVICING_HOURS_OVERRIDEN,
)
export const removeLocation = createPayloadAction(
  UNSAVED_LOCATION_ACTION_TYPES.REMOVE_LOCATION,
)
