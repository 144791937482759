import { isNil } from 'lodash'

const formatDishPrice = dish => {
  const dishLowPrice = isNil(dish.lowPrice)
    ? ''
    : `${dish.currency}${dish.lowPrice}`
  const dishHighPrice = isNil(dish.highPrice)
    ? ''
    : `${dish.currency}${dish.highPrice}`

  return dishLowPrice === dishHighPrice
    ? dishLowPrice
    : `${dishLowPrice}${
        !isNil(dish.lowPrice) && !isNil(dish.highPrice) ? ' / ' : ''
      }${dishHighPrice}`
}

export default formatDishPrice
