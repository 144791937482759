import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import { Col, notification, Row, Typography } from 'antd'
import { map } from 'lodash'
import shortid from 'shortid'

import { Checkbox, Spinner } from '../../../../core/components'
import { useDocumentTitle } from '../../../../core/hooks'

import { PageWithHeader, SaveButton } from '../../../../common/components'
import { MENU_ENTRY } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'

import {
  AI_SETTINGS_QUERY,
  UPDATE_AI_SETTINGS_MUTATION,
} from '../../../graphql'
import { aiSettingsTransformer } from '../../../transformers'

const styles = {
  pageWrapper: { padding: '1rem', paddingBottom: 0 },
}

const AiSettings = () => {
  useDocumentTitle('Ai settings')

  const { data = {}, error, loading } = useQuery(AI_SETTINGS_QUERY)
  const { aiSettings } = data

  const { formState, handleSubmit, reset, ...formProps } = useForm()
  const { isDirty } = formState

  const [updateAiSettings, { loading: isUpdateLoading }] = useMutation(
    UPDATE_AI_SETTINGS_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: result => {
        reset(
          aiSettingsTransformer.transformToFormValues(result?.updateAiSettings),
        )
        notification.success({
          message: 'Updated',
          description: `Ai settings successfully updated`,
          placement: 'topLeft',
        })
      },
    },
  )

  const handleSave = useCallback(
    formValues =>
      updateAiSettings({
        variables: {
          input: aiSettingsTransformer.transformToSaveArgs(formValues),
        },
      }),
    [updateAiSettings],
  )

  const submitForm = useMemo(
    () => handleSubmit(handleSave),
    [handleSave, handleSubmit],
  )

  const settingsFields = useMemo(
    () =>
      map(aiSettings, setting => (
        <Row key={shortid.generate()}>
          <Checkbox
            label={setting.label}
            name={setting.name}
            defaultValue={setting.isEnabled}
          />
        </Row>
      )),
    [aiSettings],
  )

  const renderRightMenu = useCallback(
    () => (
      <SaveButton
        disabled={isUpdateLoading || !isDirty}
        onClick={submitForm}
        loading={isUpdateLoading}
      />
    ),
    [isDirty, isUpdateLoading, submitForm],
  )

  if (error) {
    return <p>Failed to get ai settings</p>
  }

  if (loading) {
    return <Spinner size="large" />
  }

  return (
    <FormProvider
      {...formProps}
      reset={reset}
      formState={formState}
      handleSubmit={handleSubmit}
    >
      <PageWithHeader
        menuItem={MENU_ENTRY.AI_SETTINGS}
        renderRightMenu={renderRightMenu}
      >
        <Row style={styles.pageWrapper}>
          <Col span={24}>
            <Typography.Title level={3}>User settings</Typography.Title>
          </Col>
          {settingsFields}
        </Row>
      </PageWithHeader>
    </FormProvider>
  )
}

export default AiSettings
