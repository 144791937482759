import React from 'react'
import { Button } from 'antd'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

const styles = {
  discardButton: { marginRight: 10 },
}

const DiscardButton = ({ disabled, onClick, isLoading }) => (
  <Button
    loading={isLoading}
    disabled={disabled}
    onClick={onClick}
    style={styles.discardButton}
  >
    Cancel
  </Button>
)

DiscardButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

DiscardButton.defaultProps = {
  isLoading: false,
  disabled: false,
  onClick: noop,
}

export default DiscardButton
