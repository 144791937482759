import { gql } from '@apollo/client'

const QUALIFICATIONS_QUERY = gql`
  query qualifications {
    qualifications {
      id
      value
      label
    }
  }
`

export default QUALIFICATIONS_QUERY
