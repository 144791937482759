import React, { memo, useMemo } from 'react'
import { Typography } from 'antd'
import { filter, flatten, get, isEmpty, isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { useQueryParams } from '../../../../core/hooks'

import { useCurationMetadata } from '../../../../common/hooks'
import { findNodeByValue } from '../../../../common/utils'

import { DISH_FIELDS } from '../../../constants'
import { useBrandDish, usePersistedDishField } from '../../../hooks'
import {
  setDishAdditionalIngredients,
  setDishChoiceIngredients,
  setDishMainIngredients,
} from '../../../redux'
import {
  DishExcludedIngredients,
  DishIngredientExplanations,
  UsedFeatureIngredients,
} from '../../molecules'
import { DishIngredientsTypeSection } from '../DishIngredientsTypeSection'

const styles = {
  overridenRow: {
    marginBottom: 0,
    height: '1.25rem',
  },
  overridenIngredient: {
    textDecoration: 'line-through',
  },

  marginTop: { marginTop: '1rem' },
}

const DishIngredientsSection = ({ disabled }) => {
  const { selectedDishId } = useQueryParams()
  const { dish } = useBrandDish(selectedDishId)
  const {
    mainIngredients = [],
    additionalIngredients = [],
    excludedIngredients = [],
    ingredientExplanations = [],
    choiceIngredients = [],
  } = dish

  const { ingredientOptions } = useCurationMetadata()

  const { excludedIngredients: persistedExcludedIngredients } =
    usePersistedDishField('excludedIngredients')

  const { mainIngredients: persistedMainIngredients } = usePersistedDishField(
    'mainIngredients',
    setDishMainIngredients,
  )
  const { additionalIngredients: persistedAdditionalIngredients } =
    usePersistedDishField('additionalIngredients', setDishAdditionalIngredients)

  const { choiceIngredients: persistedChoiceIngredients } =
    usePersistedDishField('choiceIngredients', setDishChoiceIngredients)

  const overridenIngredients = useMemo(
    () => [
      ...map(
        filter(
          persistedMainIngredients || mainIngredients,
          ({ overrides }) => !isEmpty(overrides),
        ),
        ({ id, overrides }) => ({
          word: get(findNodeByValue(ingredientOptions, id), 'namePath'),
          overriden: overrides,
        }),
      ),
      ...map(
        filter(
          persistedAdditionalIngredients || additionalIngredients,
          ({ overrides }) => !isEmpty(overrides),
        ),
        ({ id, overrides }) => ({
          word: get(findNodeByValue(ingredientOptions, id), 'namePath'),
          overriden: overrides,
        }),
      ),
    ],
    [
      persistedMainIngredients,
      mainIngredients,
      persistedAdditionalIngredients,
      additionalIngredients,
      ingredientOptions,
    ],
  )

  const replacedIngredients = useMemo(
    () =>
      map(
        filter(
          [
            ...(persistedMainIngredients || mainIngredients || []),
            ...(persistedChoiceIngredients || choiceIngredients || []),
          ],
          ({ replaces }) => !isNil(replaces),
        ),
        ({ id, replaces }) => ({
          replaces: get(
            findNodeByValue(ingredientOptions, replaces),
            'namePath',
          ),
          replacement: get(findNodeByValue(ingredientOptions, id), 'namePath'),
        }),
      ),
    [
      persistedMainIngredients,
      mainIngredients,
      persistedChoiceIngredients,
      choiceIngredients,
      ingredientOptions,
    ],
  )

  const dishIngredientsWithPath = useMemo(
    () => [
      ...map(
        persistedAdditionalIngredients || additionalIngredients,
        (additionalIngredient, index) => ({
          ...additionalIngredient,
          type: DISH_FIELDS.ADDITIONAL_INGREDIENTS,
          path: `${[index]}`,
        }),
      ),
      ...map(
        persistedMainIngredients || mainIngredients,
        (mainIngredient, index) => ({
          ...mainIngredient,
          type: DISH_FIELDS.MAIN_INGREDIENTS,
          path: `[${index}]`,
        }),
      ),
      ...map(
        persistedChoiceIngredients || choiceIngredients,
        (choiceIngredient, index) => ({
          ...choiceIngredient,
          type: DISH_FIELDS.CHOICE_INGREDIENTS,
          path: `[${index}]`,
        }),
      ),
    ],
    [
      persistedAdditionalIngredients,
      additionalIngredients,
      persistedMainIngredients,
      mainIngredients,
      persistedChoiceIngredients,
      choiceIngredients,
    ],
  )

  return (
    <>
      <DishIngredientsTypeSection
        dishIngredientsField={DISH_FIELDS.MAIN_INGREDIENTS}
        disabled={disabled}
      />
      <DishIngredientsTypeSection
        dishIngredientsField={DISH_FIELDS.ADDITIONAL_INGREDIENTS}
        disabled={disabled}
      />
      <DishIngredientsTypeSection
        dishIngredientsField={DISH_FIELDS.CHOICE_INGREDIENTS}
        disabled={disabled}
      />

      {(!isEmpty(persistedExcludedIngredients) ||
        !isEmpty(excludedIngredients)) && (
        <GridFormItem
          style={styles.marginTop}
          label="Excluded Ingredients based on Negative synonyms"
        >
          {map(
            persistedExcludedIngredients || excludedIngredients,
            ({
              id,
              ingredientId,
              word,
              isSuggestedUsingParser,
              isSuggestedUsingMl,
            }) => (
              <DishExcludedIngredients
                key={`excludedIngredient-${id}-${ingredientId}-${word}`}
                id={ingredientId}
                word={word}
                isMlIngredient={!isSuggestedUsingParser && isSuggestedUsingMl}
                ingredientsTreeData={ingredientOptions}
              />
            ),
          )}
        </GridFormItem>
      )}

      {!isEmpty(ingredientExplanations) && (
        <GridFormItem style={styles.marginTop} label="Ingredient explanations">
          <DishIngredientExplanations
            ingredientExplanations={ingredientExplanations}
            dishIngredients={dishIngredientsWithPath}
            ingredientsTreeData={ingredientOptions}
          />
        </GridFormItem>
      )}

      {!isEmpty(overridenIngredients) && (
        <GridFormItem style={styles.marginTop} label="Overriden Ingredients">
          {map(overridenIngredients, ({ word: ingredientWord, overriden }) =>
            flatten(
              map(overriden, ({ word }) => (
                <Typography.Paragraph
                  key={`overridenRow-${word}-${ingredientWord}`}
                  style={styles.overridenRow}
                >
                  <Typography.Text style={styles.overridenIngredient}>
                    {word}
                  </Typography.Text>{' '}
                  - {ingredientWord}
                </Typography.Paragraph>
              )),
            ),
          )}
        </GridFormItem>
      )}
      {!isEmpty(replacedIngredients) && (
        <GridFormItem style={styles.marginTop} label="Ingredient Replacements:">
          {map(replacedIngredients, ({ replaces, replacement }) => (
            <Typography.Paragraph
              key={`replacementRow-${replaces}-${replacement}`}
              style={styles.overridenRow}
            >
              <Typography.Text style={styles.overridenIngredient}>
                {replaces}
              </Typography.Text>{' '}
              - {replacement}
            </Typography.Paragraph>
          ))}
        </GridFormItem>
      )}
      <UsedFeatureIngredients
        dishIngredientsWithPath={dishIngredientsWithPath}
      />
    </>
  )
}

DishIngredientsSection.propTypes = {
  disabled: PropTypes.bool,
}

DishIngredientsSection.defaultProps = {
  disabled: false,
}

export default memo(DishIngredientsSection)
