import { values } from 'lodash'
import PropTypes from 'prop-types'

import { LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE } from '../constants'

const labelLegendPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  dietId: PropTypes.string,
  allergenId: PropTypes.string,
  label: PropTypes.string,
  isSuggestedByAi: PropTypes.bool,
  isChoice: PropTypes.bool,
  matchType: PropTypes.string,
  conflictResolutionType: PropTypes.oneOf([
    values(LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE),
  ]),
})

export default labelLegendPropType
