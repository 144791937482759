import React from 'react'
import { Col, Row, Typography } from 'antd'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'

import theme from '../../../../theme'

const styles = {
  counterRow: {
    borderTop: '1px solid #e8e8e8',
    margin: 0,
    padding: theme.padding,
  },
  legend: {
    paddingLeft: '0.5rem',
  },
}

const DishStatusCounter = ({ dishSummary }) => (
  <Row gutter={8} style={styles.counterRow}>
    <Col style={styles.legend}>
      <Typography.Text strong>Dishes:</Typography.Text>
    </Col>
    <Col>
      <Typography.Text>{dishSummary.publishedCount} published</Typography.Text>
    </Col>
    <Col>
      <Typography.Text>|</Typography.Text>
    </Col>
    <Col>
      <Typography.Text>{dishSummary.pendingCount} pending</Typography.Text>
    </Col>
    <Col>
      <Typography.Text>|</Typography.Text>
    </Col>
    <Col>
      <Typography.Text>
        {dishSummary.automaticIgnoredCount} ai ignored
      </Typography.Text>
    </Col>
    <Col>
      <Typography.Text>|</Typography.Text>
    </Col>
    <Col>
      <Typography.Text>
        {dishSummary.manualIgnoredCount} manual ignored
      </Typography.Text>
    </Col>
    <Col>
      <Typography.Text>|</Typography.Text>
    </Col>
    {!isNil(dishSummary.qaDoneCount) && (
      <Col>
        <Typography.Text>{dishSummary.qaDoneCount} Qa done</Typography.Text>
      </Col>
    )}
    {!isNil(dishSummary.qqaDoneCount) && (
      <Col>
        <Typography.Text>{dishSummary.qqaDoneCount} QQa done</Typography.Text>
      </Col>
    )}
    {!isNil(dishSummary.validatedDishesCount) && (
      <>
        <Col>
          <Typography.Text>|</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>
            {dishSummary.validatedDishesCount} validated dishes
          </Typography.Text>
        </Col>
      </>
    )}
    {!isNil(dishSummary.uncertainDishesCount) && (
      <>
        <Col>
          <Typography.Text>|</Typography.Text>
        </Col>
        <Col>
          <Typography.Text>
            {dishSummary.uncertainDishesCount} uncertain dishes
          </Typography.Text>
        </Col>
      </>
    )}
  </Row>
)

DishStatusCounter.propTypes = {
  dishSummary: PropTypes.shape({
    publishedCount: PropTypes.number.isRequired,
    pendingCount: PropTypes.number.isRequired,
    automaticIgnoredCount: PropTypes.number.isRequired,
    manualIgnoredCount: PropTypes.number.isRequired,
    qaDoneCount: PropTypes.number,
    qqaDoneCount: PropTypes.number,
    uncertainDishesCount: PropTypes.number,
    validatedDishesCount: PropTypes.number,
  }),
}

DishStatusCounter.defaultProps = {
  dishSummary: {},
}

export default DishStatusCounter
