import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm, Spin } from 'antd'
import { toString, truncate } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import {
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAuthentication } from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DELETE_LOCATION_MUTATION } from '../../../../locations/graphql'
import { DEFAULT_BRAND_LIST_FILTERS } from '../../../constants'

const DeleteLocationButton = ({ id, brandId, name, refetchBrands }) => {
  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    status = DEFAULT_BRAND_LIST_FILTERS.STATUS,
    searchText,
  } = useQueryParams()
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const [deleteLocation, { loading }] = useMutation(DELETE_LOCATION_MUTATION, {
    update: () =>
      refetchBrands({
        variables: {
          offset:
            (page - 1) *
            (userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE),
          limit: userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
          filters: {
            status,
            searchText: toString(searchText),
          },
        },
      }),
    onError: handleMutationError,
  })

  return loading ? (
    <Spin size="small" />
  ) : (
    <Popconfirm
      placement="left"
      title={`Delete "${truncate(name)}" location?`}
      onConfirm={() => {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_LOCATION_EVENTS.DELETE_CLICK,
          { brandId },
        )
        deleteLocation({ variables: { id } })
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button size="small" icon={<DeleteOutlined />} shape="circle" />
    </Popconfirm>
  )
}

DeleteLocationButton.propTypes = {
  id: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  refetchBrands: PropTypes.func,
}

DeleteLocationButton.defaultProps = {
  refetchBrands: undefined,
}

export default DeleteLocationButton
