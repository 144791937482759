import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row } from 'antd'
import { isNil, keys, map } from 'lodash'
import moment from 'moment'

import {
  DatePicker,
  Input,
  Select,
  TreeSelect,
} from '../../../../core/components'

import { METADATA_TYPES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

const AVAILABLE_METADATA_TYPES = {
  [METADATA_TYPES.DISH_TYPES]: 'Dish type',
  [METADATA_TYPES.COURSE_TYPES]: 'Course type',
  [METADATA_TYPES.CUISINE_TYPES]: 'Cuisine type',
  [METADATA_TYPES.LOCATION_TYPES]: 'Location type',
}

const styles = {
  select: {
    margin: '0.25rem 0',
    width: '100%',
  },
}

const ReviewTaskForm = () => {
  const {
    dishTypeOptions,
    courseTypeOptions,
    locationTypeOptions,
    cuisineTypeOptions,
  } = useCurationMetadata()

  const { watch } = useFormContext()
  const selectedMetadataType = watch('metadataType')

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.LOCATION_TYPES]: locationTypeOptions,
      [METADATA_TYPES.CUISINE_TYPES]: cuisineTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
    }),
    [
      courseTypeOptions,
      cuisineTypeOptions,
      dishTypeOptions,
      locationTypeOptions,
    ],
  )

  return (
    <>
      <Row>
        <Select
          style={styles.select}
          span={6}
          placeholder="Metadata type"
          name="metadataType"
          allowClear={false}
          showSearch={false}
          options={map(keys(AVAILABLE_METADATA_TYPES), metadataType => ({
            label: AVAILABLE_METADATA_TYPES[metadataType],
            value: metadataType,
          }))}
        />
      </Row>
      <Row>
        <TreeSelect
          style={styles.select}
          span={6}
          disabled={isNil(selectedMetadataType)}
          placeholder={AVAILABLE_METADATA_TYPES[selectedMetadataType]}
          allowClear={false}
          name="metadataId"
          treeData={curationMetadata[selectedMetadataType]}
        />
      </Row>
      {selectedMetadataType === METADATA_TYPES.DISH_TYPES && (
        <Row>
          <Input
            style={styles.select}
            span={6}
            placeholder="keywords"
            name="keywords"
          />
        </Row>
      )}
      <Row>
        <DatePicker
          span={6}
          style={styles.select}
          format="YYYY-MM-DD HH:mm"
          showTime={{
            defaultValue: moment('00:00', 'HH:mm'),
            format: 'HH:mm',
          }}
          placeholder="Start date"
          name="startDate"
          convertFunction={moment.utc}
        />
      </Row>
      <Row>
        <DatePicker
          span={6}
          style={styles.select}
          format="YYYY-MM-DD HH:mm"
          showTime={{
            defaultValue: moment('00:00', 'HH:mm'),
            format: 'HH:mm',
          }}
          placeholder="End date"
          name="endDate"
          convertFunction={moment.utc}
        />
      </Row>
    </>
  )
}

export default ReviewTaskForm
