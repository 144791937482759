import React, { useMemo } from 'react'
import {
  deburr,
  find,
  groupBy,
  keys,
  map,
  mapValues,
  some,
  toLower,
} from 'lodash'
import PropTypes from 'prop-types'

import { BRAND_MATCH_TYPE } from '../../../../common/constants'

const styles = {
  decorated: {
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_EXACT]: {
      backgroundColor: '#434343',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_ALREADY_FOUND]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_EXACT]: {
      backgroundColor: '#434343',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_ALREADY_FOUND]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_EXACT_CHOICE]: {
      backgroundColor: '#044d02',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_ALREADY_FOUND_CHOICE]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_EXACT_CHOICE]: {
      backgroundColor: '#044d02',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_ALREADY_FOUND_CHOICE]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    noCategory: { backgroundColor: 'red' },
  },
}

const MenuTitleHighlightDecorator = ({ children, highlightingList }) => {
  const { text } = children[0].props
  const groupedWordsByMatchType = useMemo(
    () =>
      mapValues(groupBy(highlightingList, 'matchType'), wordList =>
        map(wordList, ({ word, override }) => ({
          word: toLower(word),
          override,
        })),
      ),
    [highlightingList],
  )

  const decoratedStyle = find(keys(groupedWordsByMatchType), key =>
    some(
      groupedWordsByMatchType[key],
      ({ word }) => deburr(toLower(word)) === deburr(toLower(text)),
    ),
  )

  return <span style={styles.decorated[decoratedStyle]}>{children}</span>
}

MenuTitleHighlightDecorator.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  highlightingList: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string.isRequired,
      matchType: PropTypes.string,
    }),
  ).isRequired,
}

export default MenuTitleHighlightDecorator
