import { map } from 'lodash'

export const transformBrandToAiRequestDto = brandId => ({
  brandId,
})

export const transformDishFromAiResponseDto = responseBody =>
  map(responseBody, ({ result, messages }) => ({
    messages,
    result: map(result, cuisineType => ({
      ...cuisineType,
      // Make sure IDs are passed as strings, not as numbers.
      cuisineTypeId: `${cuisineType.cuisineTypeId}`,
      // Remove all decimals except 2
      score: parseFloat((cuisineType.score * 100).toFixed(2), 10),
    })),
  }))
