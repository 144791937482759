import React, { useCallback, useMemo } from 'react'
import { get } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Card } from 'antd'
import { isNil, map, uniq } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams, useShallowEqualSelector } from '../../../../core/hooks'
import { areSameValue } from '../../../../core/utils'

import {
  BRAND_ENTITY_LINK_ORIGIN_TYPE,
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import {
  useAuthentication,
  useCachedBrand,
  useUserHasBrandChangePermission,
} from '../../../../common/hooks'
import { findNodeBy } from '../../../../common/utils'

import {
  setExpandedRowKeys,
  setHighlightedRowKey,
} from '../../../../menuTitles/redux'
import {
  BRAND_SUGGESTED_WORDS_ORIGIN_FIELD_NAMES,
  DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS,
} from '../../../constants'
import {
  BRAND_SUGGESTED_WORDS,
  BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
  CONFIRM_BRAND_SUGGESTED_WORD_MUTATION,
  DELETE_NOT_EXPORTED_BRAND_SUGGESTED_WORD,
} from '../../../graphql'
import { removeBrandSuggestedWord } from '../../../graphql/helpers'
import { brandSuggestedWordPropType } from '../../../propTypes'
import { brandSuggestedWordTransformer } from '../../../transformers'
import { BrandSuggestedWordForm } from '../BrandSuggestedWordForm'

import brandSuggestedWordCardStyles from './brandSuggestedWordCardStyles'

const BrandSuggestedWordCard = ({ closeModal, brandSuggestedWord }) => {
  const dispatch = useDispatch()
  const { brandId } = useParams()
  const { status: brandStatus } = useCachedBrand()

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    selectedDishId,
    brandSuggestedWordsCurrentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    brandSuggestedWordsPageSize = userDefaultPageSize ||
      DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    sortUnknownWordsBy = DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS.SORT_BY,
    setQueryParams,
  } = useQueryParams()

  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const { menuTitles, expandedRowKeys } = useShallowEqualSelector(state =>
    get(state, 'unsaved.unsavedMenuTitles'),
  )

  const [confirm, { loading: isConfirmLoading }] = useMutation(
    CONFIRM_BRAND_SUGGESTED_WORD_MUTATION,
    {
      refetchQueries: [
        {
          query: BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
          variables: { brandId },
        },
        {
          query: BRAND_SUGGESTED_WORDS,
          variables: {
            brandId,
            sortBy: sortUnknownWordsBy,
            pagination: {
              currentPage: Number(brandSuggestedWordsCurrentPage),
              pageSize: Number(brandSuggestedWordsPageSize),
            },
          },
        },
      ],
      onError: handleMutationError,
    },
  )

  const [deleteNotExportedWord, { loading: isDeleteNotExportedWordLoading }] =
    useMutation(DELETE_NOT_EXPORTED_BRAND_SUGGESTED_WORD, {
      refetchQueries: [
        {
          query: BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
          variables: { brandId },
        },
      ],
      onError: handleMutationError,
      update: removeBrandSuggestedWord(
        brandSuggestedWord.id,
        brandId,
        sortUnknownWordsBy,
        brandSuggestedWordsCurrentPage,
        brandSuggestedWordsPageSize,
      ),
    })

  const isConfirmed = useMemo(() => {
    if (
      brandStatus ===
        CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value &&
      brandSuggestedWord.isCurationDone
    ) {
      return true
    }

    if (
      brandStatus === CURATION_STATUS.UNKNOWN_WORDS_QA.value &&
      brandSuggestedWord.isQaDone
    ) {
      return true
    }

    return false
  }, [
    brandStatus,
    brandSuggestedWord.isCurationDone,
    brandSuggestedWord.isQaDone,
  ])

  const isDisabled = useMemo(() => {
    if (!userHasBrandChangePermission) {
      return true
    }

    if (brandSuggestedWord.isExported) {
      return true
    }

    return false
  }, [brandSuggestedWord.isExported, userHasBrandChangePermission])

  const isQaOperation = useMemo(
    () => brandStatus === CURATION_STATUS.UNKNOWN_WORDS_QA.value,
    [brandStatus],
  )

  const wordOriginField = useMemo(
    () =>
      get(
        BRAND_SUGGESTED_WORDS_ORIGIN_FIELD_NAMES,
        brandSuggestedWord.originField,
      ),
    [brandSuggestedWord.originField],
  )

  const originLink = useMemo(() => {
    if (!isNil(selectedDishId)) {
      return null
    }

    if (!isNil(brandSuggestedWord.dish)) {
      return {
        idToOpen: brandSuggestedWord.dish.id,
        type: BRAND_ENTITY_LINK_ORIGIN_TYPE.DISH,
      }
    }

    if (!isNil(brandSuggestedWord.menuTitle)) {
      return {
        idToOpen: brandSuggestedWord.menuTitle?.id,
        type: BRAND_ENTITY_LINK_ORIGIN_TYPE.MENU_TITLE,
      }
    }

    return null
  }, [brandSuggestedWord.dish, brandSuggestedWord.menuTitle, selectedDishId])

  const handleOpenDishModal = useCallback(
    () => setQueryParams({ selectedDishId: originLink.idToOpen }),
    [originLink, setQueryParams],
  )

  const openMenuTitleTreeAtId = useCallback(() => {
    const menuTitleToOpen = findNodeBy(menuTitles, ({ id }) =>
      areSameValue(id, originLink?.idToOpen),
    )

    if (!isNil(menuTitleToOpen)) {
      dispatch(
        setExpandedRowKeys(
          uniq([
            ...expandedRowKeys,
            ...map(
              [...menuTitleToOpen.parents, menuTitleToOpen.id],
              menuTitleId => `menuTitle-${menuTitleId}`,
            ),
          ]),
        ),
      )
      dispatch(setHighlightedRowKey(`menuTitle-${menuTitleToOpen.id}`))
      closeModal()
    }
  }, [closeModal, dispatch, expandedRowKeys, menuTitles, originLink?.idToOpen])

  const cardExtra = useMemo(() => {
    if (isNil(originLink)) {
      return null
    }

    return originLink.type === BRAND_ENTITY_LINK_ORIGIN_TYPE.DISH ? (
      <LinkOutlined onClick={handleOpenDishModal} />
    ) : (
      <LinkOutlined onClick={openMenuTitleTreeAtId} />
    )
  }, [handleOpenDishModal, openMenuTitleTreeAtId, originLink])

  const handleConfirmClick = useCallback(
    formValues => {
      confirm({
        variables: {
          id: brandSuggestedWord.id,
          isConfirmed: !isConfirmed,
          isQa: isQaOperation,
          data: brandSuggestedWordTransformer.toUpdateArgs(formValues),
        },
      })
    },
    [brandSuggestedWord.id, confirm, isConfirmed, isQaOperation],
  )

  const handleDeleteClick = useCallback(
    () =>
      deleteNotExportedWord({
        variables: { id: brandSuggestedWord.id },
      }),
    [deleteNotExportedWord, brandSuggestedWord.id],
  )

  return (
    <Card
      loading={isConfirmLoading || isDeleteNotExportedWordLoading}
      extra={cardExtra}
      type="inner"
      title={wordOriginField}
      style={!isDisabled ? brandSuggestedWordCardStyles.redBorder : undefined}
      headStyle={brandSuggestedWordCardStyles.head}
      bodyStyle={brandSuggestedWordCardStyles.body}
    >
      <BrandSuggestedWordForm
        isDisabled={isDisabled}
        isConfirmed={isConfirmed}
        brandSuggestedWord={brandSuggestedWord}
        onDelete={handleDeleteClick}
        onConfirm={handleConfirmClick}
      />
    </Card>
  )
}

BrandSuggestedWordCard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  brandSuggestedWord: brandSuggestedWordPropType.isRequired,
}

export default BrandSuggestedWordCard
