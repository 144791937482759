import { filter, flatten, get, isArray, isNil, keys, map, omit } from 'lodash'

const getDishAiSuggestionMessages = dish =>
  flatten(
    map(keys(omit(dish.aiSuggestions, '__typename')), aiSuggestionKey => {
      const suggestions = get(dish.aiSuggestions, `${aiSuggestionKey}`)

      const suggestionMessages = isArray(suggestions)
        ? filter(
            flatten(map(suggestions, 'messages')),
            messages => !isNil(messages),
          )
        : get(suggestions, 'messages')

      return suggestionMessages || []
    }),
  )

export default getDishAiSuggestionMessages
