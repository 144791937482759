import React from 'react'
import { Space, Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'

const BrandDishCounts = ({ dishesSummary }) => (
  <Space>
    <Tooltip title="published">
      <Typography.Text type="success">
        {dishesSummary.publishedCount}
      </Typography.Text>
    </Tooltip>
    <Typography.Text>|</Typography.Text>
    <Tooltip title="Unpublished">
      <Typography.Text type="danger">
        {dishesSummary.pendingCount}
      </Typography.Text>
    </Tooltip>
    <Typography.Text>|</Typography.Text>
    <Tooltip title="Ignored">
      <Typography.Text disabled>
        {dishesSummary.automaticIgnoredCount + dishesSummary.manualIgnoredCount}
      </Typography.Text>
    </Tooltip>
  </Space>
)

BrandDishCounts.propTypes = {
  dishesSummary: PropTypes.shape({
    publishedCount: PropTypes.number.isRequired,
    pendingCount: PropTypes.number.isRequired,
    automaticIgnoredCount: PropTypes.number.isRequired,
    manualIgnoredCount: PropTypes.number.isRequired,
    qaDoneCount: PropTypes.number,
    qqaDoneCount: PropTypes.number,
    uncertainDishesCount: PropTypes.number,
    validatedDishesCount: PropTypes.number,
  }),
}

BrandDishCounts.defaultProps = {
  dishesSummary: {},
}
export default BrandDishCounts
