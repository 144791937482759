import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons'
import Editor from '@draft-js-plugins/editor'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import { EditorState, RichUtils } from 'draft-js'
import { decode } from 'he'
import { debounce, includes, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { usePrevious } from '../../../../core/hooks'

import { getStyledTextFromContentState } from '../../../helpers'
import { stylePropType } from '../../../propTypes'

import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import 'draft-js/dist/Draft.css'

const styles = {
  editor: {
    input: {
      margin: 0,
    },
    errorToolBarButton: {
      color: 'red',
    },
    focusedContainer: {
      borderColor: '#40a9ff',
      outline: 0,
      boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
    },
  },
}

const DebouncedRichTextInput = ({
  defaultValue,
  value,
  onValueChanged: onValueChangedProp,
  disabled,
  style,
  placeholder,
  toolbar,
  validateStatus,
  help,
  disableRichText,
}) => {
  const isMounting = useRef(true)
  const plugins = useMemo(
    () => (toolbar ? createInlineToolbarPlugin() : null),
    [toolbar],
  )
  const editorRef = useRef(null)
  const [isFocused, setFocus] = useState(false)

  const focusEditor = useCallback(() => {
    editorRef.current.focus()
    setFocus(true)
  }, [editorRef])

  const toggleFocusOff = useCallback(() => {
    setFocus(false)
    editorRef.current.blur()
  }, [])

  useEffect(() => {
    isMounting.current = false
  }, [])

  const previousDefaultValue = usePrevious(defaultValue)
  const previousValue = usePrevious(value)
  const [text, setText] = useState(defaultValue)
  const debouncedOnChange = useMemo(
    () =>
      debounce(changedValue => {
        onValueChangedProp(changedValue)
      }, 300),
    [onValueChangedProp],
  )

  const onChange = useCallback(changedValue => {
    if (!isMounting.current) {
      setText(changedValue)
    }
  }, [])

  const handleKeyCommand = useCallback(
    command => {
      // inline formatting key commands handles bold, italic, code, underline
      const newValue = RichUtils.handleKeyCommand(text, command)

      if (newValue && includes(['italic', 'underline'], command)) {
        setText(newValue)
        return 'handled'
      }

      return 'not-handled'
    },
    [text],
  )

  useEffect(() => {
    if (
      previousDefaultValue &&
      defaultValue &&
      decode(
        getStyledTextFromContentState(previousDefaultValue.getCurrentContent()),
      ) !==
        decode(getStyledTextFromContentState(defaultValue.getCurrentContent()))
    ) {
      setText(defaultValue)
    } else if (
      defaultValue &&
      text &&
      decode(
        getStyledTextFromContentState(defaultValue.getCurrentContent()),
      ) !== decode(getStyledTextFromContentState(text.getCurrentContent()))
    ) {
      debouncedOnChange(text)
    }
  }, [previousDefaultValue, defaultValue, text, debouncedOnChange])

  const handleReturn = useCallback(() => {
    onChange(RichUtils.insertSoftNewline(text))
    return 'handled'
  }, [text, onChange])

  useEffect(() => {
    if (!isNil(previousValue) && isNil(value)) {
      setText(defaultValue)
    }
  }, [previousDefaultValue, defaultValue, previousValue, value])

  return (
    <GridFormItem validateStatus={validateStatus} help={help}>
      <div
        aria-hidden="true"
        onClick={!disabled ? focusEditor : undefined}
        style={{
          ...styles.editor.input,
          ...(isFocused ? styles.editor.focusedContainer : {}),
          ...(style || {}),
        }}
        className={
          disabled
            ? 'ant-input textarea ant-input-disabled'
            : 'ant-input textarea'
        }
      >
        <Editor
          ref={editorRef}
          readOnly={disabled}
          editorState={text}
          placeholder={placeholder}
          handleReturn={handleReturn}
          onChange={onChange}
          onBlur={() => {
            toggleFocusOff()
          }}
          plugins={plugins ? [plugins] : undefined}
          handleKeyCommand={!disableRichText ? handleKeyCommand : undefined}
        />

        {!disabled && !disableRichText && plugins && (
          <plugins.InlineToolbar>
            {externalProps => (
              <>
                <UnderlineButton {...externalProps} />
                <ItalicButton {...externalProps} />
              </>
            )}
          </plugins.InlineToolbar>
        )}
      </div>
    </GridFormItem>
  )
}

DebouncedRichTextInput.propTypes = {
  onValueChanged: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  style: stylePropType,
  placeholder: PropTypes.string,
  toolbar: PropTypes.bool,
  validateStatus: PropTypes.string,
  help: PropTypes.string,
  disableRichText: PropTypes.bool,
}

DebouncedRichTextInput.defaultProps = {
  defaultValue: EditorState.createEmpty(),
  value: null,
  disabled: false,
  style: undefined,
  placeholder: undefined,
  toolbar: true,
  validateStatus: undefined,
  help: undefined,
  disableRichText: false,
}

export default DebouncedRichTextInput
