import { gql } from '@apollo/client'

const REFRESH_ACCESS_TOKEN_MUTATION = gql`
  mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`

export default REFRESH_ACCESS_TOKEN_MUTATION
