import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip, Typography } from 'antd'
import { includes, isNil, map, size } from 'lodash'

import { formatDate, formatDateFromNow } from '../../../../core/utils'

import { CURATION_STATUS } from '../../../../common/constants'
import { getRouteOfBrand, getRouteOfLocation } from '../../../../common/helpers'
import { showCommaAtIndex } from '../../../../common/utils'

import { BRAND_TAGS } from '../../../constants'
import { BrandCorrectionsCount } from '../../molecules'
import { BrandDishCounts } from '../../molecules/BrandDishCounts'
import { BrandTableActions } from '../BrandTableActions'
import { BrandTableActivityStatus } from '../BrandTableActivityStatus'
import { BrandTableAssignation } from '../BrandTableAssignation'

const styles = {
  longTextMaxWidth: { width: 200 },
  pendingStatusContainer: { display: 'flex', alignItems: 'center' },
  highlightBlue: {
    color: '#1890ff',
  },
}

const createBrandLocationTableColumns = (
  userPermissions,
  users,
  assignedCurator,
  assignedQa,
  refetchBrands,
  tags,
) => [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '150px',
    fixed: 'left',
    render: (text, brandOrLocation) => {
      const isBrand = isNil(brandOrLocation.brandId)

      const route = isBrand
        ? getRouteOfBrand(brandOrLocation.id)
        : getRouteOfLocation(brandOrLocation.brandId, brandOrLocation.id)

      return (
        <Link to={route}>{`${text}${
          isBrand ? ` (${size(brandOrLocation.locations)})` : ''
        }`}</Link>
      )
    },
  },
  {
    title: 'Activity',
    dataIndex: 'pendingStatus',
    width: '150px',
    render: (_, brandOrLocation) => (
      <BrandTableActivityStatus brandOrLocation={brandOrLocation} />
    ),
  },
  {
    title: 'Status',
    width: '80px',
    dataIndex: 'status',
    render: status => (
      <Typography.Text>{CURATION_STATUS[status].label}</Typography.Text>
    ),
  },
  ...(includes(tags, BRAND_TAGS.IS_PROBLEMATIC)
    ? [
        {
          title: 'Problem reason',
          width: '80px',
          dataIndex: 'problemReason',
          render: problemReason => (
            <Typography.Text>{problemReason}</Typography.Text>
          ),
        },
      ]
    : []),
  {
    title: 'Assigned',
    width: '150px',
    render: (_, brandOrLocation) =>
      !brandOrLocation.brandId && (
        <BrandTableAssignation
          brandOrLocation={brandOrLocation}
          users={users}
          assignedCuratorFilter={assignedCurator}
          assignedQaFilter={assignedQa}
        />
      ),
  },
  {
    title: 'Dish Summary',
    width: '115px',
    render: (_, { dishesSummary }) => (
      <BrandDishCounts dishesSummary={dishesSummary} />
    ),
  },
  ...(!isNil(assignedCurator) || !isNil(assignedQa)
    ? [
        {
          title: 'QA Corrections',
          width: '150px',
          render: (_, { qaAggregatedCorrectionCounts }) => (
            <BrandCorrectionsCount corrections={qaAggregatedCorrectionCounts} />
          ),
        },
      ]
    : []),
  {
    title: 'U.W.',
    width: '80px',
    render: (_, { brandId, suggestedWordsCompletenessCounts }) =>
      !brandId && (
        <>
          <Tooltip title="Curated words">
            <Typography.Paragraph>
              {suggestedWordsCompletenessCounts?.curatedWordsCount} /{' '}
              {suggestedWordsCompletenessCounts?.wordsToResolveTotal}
            </Typography.Paragraph>
          </Tooltip>
          <Tooltip title="Qad words">
            <Typography.Paragraph>
              {suggestedWordsCompletenessCounts?.qadWordsCount} /{' '}
              {suggestedWordsCompletenessCounts?.wordsToResolveTotal}
            </Typography.Paragraph>
          </Tooltip>
        </>
      ),
  },
  {
    title: 'Cuisine',
    dataIndex: 'cuisineType',
    width: '150px',
    render: cuisineType => (
      <Typography.Text>{cuisineType?.name}</Typography.Text>
    ),
  },
  {
    title: 'Predominant languages',
    width: '150px',
    dataIndex: 'languages',
    render: languages =>
      map(languages, (language, index) => (
        <Fragment key={language.id}>
          <Typography.Text
            style={language.isSuggestedByAi ? styles.highlightBlue : null}
          >
            {language.name}
            {showCommaAtIndex(languages, index)}
          </Typography.Text>
        </Fragment>
      )),
  },
  {
    title: 'Unknown word languages',
    width: '150px',
    dataIndex: 'notExportedBrandSuggestedWordsMostUsedLanguages',
    render: notExportedBrandSuggestedWordsMostUsedLanguages =>
      map(
        notExportedBrandSuggestedWordsMostUsedLanguages,
        (language, index) => (
          <Fragment key={language.id}>
            <Typography.Text>
              {language.name} ({language.count})
              {showCommaAtIndex(
                notExportedBrandSuggestedWordsMostUsedLanguages,
                index,
              )}
            </Typography.Text>
          </Fragment>
        ),
      ),
  },

  {
    title: 'Actions',
    width: '80px',
    render: brandOrLocation => (
      <BrandTableActions
        brandOrLocation={brandOrLocation}
        userPermissions={userPermissions}
        refetchBrands={refetchBrands}
      />
    ),
  },
  {
    title: 'Added at',
    dataIndex: 'createdAt',
    width: '100px',
    render: createdAt => (
      <Typography.Text>
        <Tooltip title={formatDate(createdAt)}>
          {formatDateFromNow(createdAt)}
        </Tooltip>
      </Typography.Text>
    ),
  },
]

export default createBrandLocationTableColumns
