import React, { useCallback, useMemo } from 'react'
import { Col, Row, Select, Space, Typography } from 'antd'

import { useQueryParams } from '../../../../core/hooks'

import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { createSelectOptionsFromObject } from '../../../../common/helpers'
import { useCachedBrand } from '../../../../common/hooks'

import {
  BRAND_SUGGESTED_WORDS_SORT_OPTIONS,
  DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS,
} from '../../../constants'
import { brandSuggestedWordsCompletenessCountPropType } from '../../../propTypes'

import UNKNOWN_WORDS_MODAL_HEADER_STYLES from './brandSuggestedWordsModalHeaderStyles'

import './brandSuggestedWordsModalHeader.css'

const BrandSuggestedWordsModalHeader = ({
  brandSuggestedWordsCompletenessCount,
}) => {
  const brand = useCachedBrand()

  const {
    sortUnknownWordsBy = DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS.SORT_BY,
    setQueryParams,
  } = useQueryParams()

  const sortByOnChange = useCallback(
    sortByValue =>
      setQueryParams({
        sortUnknownWordsBy: sortByValue,
        brandSuggestedWordsCurrentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
      }),
    [setQueryParams],
  )

  const brandSuggestedWordsSortOptions = useMemo(
    () => createSelectOptionsFromObject(BRAND_SUGGESTED_WORDS_SORT_OPTIONS),
    [],
  )

  return (
    <Row type="flex" justify="space-between">
      <Col span={12}>
        <Space>
          <Typography.Text>
            <Space>
              Curated Unknown words
              {brandSuggestedWordsCompletenessCount?.curatedWordsCount} /
              {brandSuggestedWordsCompletenessCount?.wordsToResolveTotal}
            </Space>
          </Typography.Text>
          <Typography.Text>
            <Space>
              Qad Unknown words
              {brandSuggestedWordsCompletenessCount?.qadWordsCount} /
              {brandSuggestedWordsCompletenessCount?.wordsToResolveTotal}
            </Space>
          </Typography.Text>
          <Typography.Link
            style={UNKNOWN_WORDS_MODAL_HEADER_STYLES.menuLink}
            href={brand.menuLink}
            target="blank"
          >
            {brand.name}
          </Typography.Link>
        </Space>
      </Col>
      <Col span={12} style={UNKNOWN_WORDS_MODAL_HEADER_STYLES.justifyFlexEnd}>
        <Typography.Text style={UNKNOWN_WORDS_MODAL_HEADER_STYLES.margins}>
          Sort by:
          <Select
            size="small"
            showSearch
            style={UNKNOWN_WORDS_MODAL_HEADER_STYLES.select}
            value={sortUnknownWordsBy}
            onChange={sortByOnChange}
            options={brandSuggestedWordsSortOptions}
          />
        </Typography.Text>
      </Col>
    </Row>
  )
}

BrandSuggestedWordsModalHeader.propTypes = {
  brandSuggestedWordsCompletenessCount:
    brandSuggestedWordsCompletenessCountPropType.isRequired,
}

export default BrandSuggestedWordsModalHeader
