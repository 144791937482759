const BRAND_PENDING_STATUS_LABELS = Object.freeze({
  MENU_IMPORT_PENDING: 'Menu Importing',
  SUGGEST_UNKNOWN_WORDS_PENDING: 'Suggesting U.W',
  EXPORT_WORDS_PENDING: 'Exporting words',
  DUPLICATE_PENDING: 'Duplication in Progress',
  BATCH_CURATION_PENDING: 'Curation Processing',
  MISC_AND_CHOICE_PENDING: 'Misc And Choice Processing',
  MENU_IMPORT_ERROR: 'Menu Import Error',
  SUGGEST_UNKNOWN_WORDS_ERROR: 'Suggesting U.W Error',
  EXPORT_WORDS_ERROR: 'Exporting words error',
  BATCH_CURATION_ERROR: 'Curation Error',
  MISC_AND_CHOICE_ERROR: 'Misc & Choice Error',
  DUPLICATE_ERROR: 'Duplication Error',
  MENU_IMPORT_DONE: 'Menu Importing Done',
  MISC_AND_CHOICE_DONE: 'Suggesting Misc And Choice Done',
  CURATION_DONE: 'Suggesting Curation Done',
  SUGGEST_UNKNOWN_WORDS_DONE: 'Suggesting U.W Done',
  EXPORT_WORDS_DONE: 'Exporting words Done',
})

export default BRAND_PENDING_STATUS_LABELS
