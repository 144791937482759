import {
  AI_INGREDIENT_MATCH_TYPE,
  AI_SUGGESTIONS_HIGHLIGHT_COLORS,
  ALTERNATIVE_MATCH_CLASSNAME,
  HIGHLIGHT_PREFIX,
} from '../../common/constants'

import { ADDITIONAL_INGREDIENT_TYPES } from '../constants'
import { dishIngredientUtils } from '../utils'

export const transformMainOrChoiceIngredientForDisplay =
  mainOrChoiceIngredient => {
    const ingredient = {
      ...mainOrChoiceIngredient,
      priority: mainOrChoiceIngredient.originField
        ? dishIngredientUtils.getPriorityFromOriginOfIngredient(
            mainOrChoiceIngredient.originField,
          )
        : 5,
    }
    if (mainOrChoiceIngredient.alternativeType) {
      ingredient.highlight = ALTERNATIVE_MATCH_CLASSNAME
    } else if (
      mainOrChoiceIngredient.isSuggestedUsingMl &&
      !mainOrChoiceIngredient.isSuggestedUsingParser
    ) {
      ingredient.highlight = AI_INGREDIENT_MATCH_TYPE.ML_SUGGESTED
    } else {
      ingredient.highlight = mainOrChoiceIngredient.matchType
    }

    ingredient.highlightClassName = ingredient.isSuggestedByAi
      ? `${HIGHLIGHT_PREFIX}-${AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE} ${ingredient.highlight}`
      : undefined

    return ingredient
  }

export const transformAdditionIngredientForDisplay = additionalIngredient => {
  const ingredient = { ...additionalIngredient }
  if (!additionalIngredient.isManuallyAdded) {
    if (ingredient.ingredientType === ADDITIONAL_INGREDIENT_TYPES.DEFINITE) {
      ingredient.highlight = AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE
      ingredient.highlightClassName = `${HIGHLIGHT_PREFIX}-${AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE}`
    } else if (
      ingredient.ingredientType === ADDITIONAL_INGREDIENT_TYPES.PROBABLE
    ) {
      ingredient.highlight = AI_SUGGESTIONS_HIGHLIGHT_COLORS.ORANGE
      ingredient.highlightClassName = `${HIGHLIGHT_PREFIX}-${AI_SUGGESTIONS_HIGHLIGHT_COLORS.ORANGE}`
    }
  }
  return ingredient
}
