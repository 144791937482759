import { join } from 'path'

import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { notification } from 'antd'
import { attempt, get, isEmpty, isNil, isNull, map, noop, some } from 'lodash'
import { PropTypes } from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import { SaveButton } from '../../../../common/components'
import {
  APP_ROUTES,
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAppLoading, useAuthentication } from '../../../../common/hooks'
import { setActiveLocationName } from '../../../../common/redux/actions'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { locationIdNew } from '../../../constants'
import {
  LOCATION_BY_ID_QUERY,
  UPSERT_LOCATION_DISH_MUTATION,
  UPSERT_LOCATION_MUTATION,
} from '../../../graphql'
import {
  transformToLocationDishSaveArgs,
  transformToSaveLocationArgs,
} from '../../../graphql/transformers'
import { useUnsavedLocation } from '../../../hooks'

const LocationSaveButton = ({ locationId, refetchLocation }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { brandId } = useParams()

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    menuTitle,
    search,
  } = useQueryParams()

  const { data: { location: cachedLocation } = {} } = useQuery(
    LOCATION_BY_ID_QUERY,
    {
      variables: {
        id: locationId,
        paginationFiltersAndSorters: {
          pageSize: Number(pageSize),
          currentPage: Number(currentPage),
          filters: {
            menuTitle,
            search,
          },
        },
      },
      fetchPolicy: 'cache-only',
    },
  )

  const { removeLocation, location: unsavedLocation } =
    useUnsavedLocation(locationId)

  const { startLoading, stopLoading, isLoading } = useAppLoading()

  const [upsertLocation] = useMutation(UPSERT_LOCATION_MUTATION, {
    onError: handleMutationError,
    onCompleted: ({ upsertLocation: { name } }) =>
      dispatch(setActiveLocationName(name)),
  })

  const [upsertLocationDish] = useMutation(UPSERT_LOCATION_DISH_MUTATION, {
    onError: handleMutationError,
  })

  const handleUpsertLocation = useCallback(async () => {
    const promises = []

    const { servicingHoursOverriden: cachedServicingHoursOverriden } =
      cachedLocation || {}

    const { id, dishes, ...locationData } = unsavedLocation
    const { servicingHoursOverriden } = locationData

    if (!isEmpty(locationData)) {
      const locationInput = transformToSaveLocationArgs({
        id,
        brandId,
        ...locationData,
      })
      promises.push(
        upsertLocation({ variables: { input: { ...locationInput } } }),
      )
    }

    // Don't persist the servicing hours if the user unchecks "Override servicing hours from brand"
    // after selecting servicing hours for a location dish
    // TODO change to send only 2 requests one with location data, one with dishes
    const locationDishesPromises = map(dishes, dish => {
      const dishInput = transformToLocationDishSaveArgs(dish, {
        skipServicingHours: isNil(servicingHoursOverriden)
          ? cachedServicingHoursOverriden === false
          : servicingHoursOverriden === false,
      })
      return upsertLocationDish({
        variables: { input: { locationId: id, brandId, ...dishInput } },
      })
    })

    try {
      const response = await Promise.all([
        ...promises,
        ...locationDishesPromises,
      ])
      const upsertedLocationId = get(response, '[0].data.upsertLocation.id')
      removeLocation(id)
      if (locationIdNew === locationId && !isNil(upsertedLocationId)) {
        history.push(
          join(
            APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
            brandId,
            APP_ROUTES.LOCATION_ROUTES.LocationsPrefix,
            upsertedLocationId,
          ),
        )
      } else {
        attempt(refetchLocation)
      }
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error.message,
        placement: 'topLeft',
      })
    }
  }, [
    cachedLocation,
    unsavedLocation,
    brandId,
    upsertLocation,
    upsertLocationDish,
    removeLocation,
    locationId,
    history,
    refetchLocation,
  ])

  const handleSaveClick = useCallback(async () => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.BRAND_LOCATION_EVENTS.SAVE_CLICK,
      {
        parentBrandId: brandId,
      },
    )
    startLoading()
    await handleUpsertLocation()
    stopLoading()
  }, [brandId, startLoading, handleUpsertLocation, stopLoading])

  let saveButtonDisabled = false

  if (!unsavedLocation || isLoading) {
    saveButtonDisabled = true
  } else if (unsavedLocation) {
    if (unsavedLocation.name === undefined) {
      if (get(cachedLocation, 'id') === unsavedLocation.id) {
        saveButtonDisabled = false
      } else {
        saveButtonDisabled = true
      }
    } else if (unsavedLocation.name === '') {
      saveButtonDisabled = true
    }
  }
  if (
    some(
      get(unsavedLocation, 'dishes'),
      ({ overridenLowPrice, overridenHighPrice }, index) =>
        (overridenLowPrice &&
          overridenLowPrice >
            (isNull(overridenHighPrice)
              ? Number.MAX_SAFE_INTEGER
              : overridenHighPrice ??
                get(
                  cachedLocation,
                  `dishes.rows[${index}].overridenHighPrice`,
                ) ??
                Number.MAX_SAFE_INTEGER)) ||
        (overridenHighPrice &&
          overridenHighPrice <
            (isNull(overridenLowPrice)
              ? Number.MIN_SAFE_INTEGER
              : overridenLowPrice ??
                get(
                  cachedLocation,
                  `dishes.rows[${index}].overridenLowPrice`,
                ) ??
                Number.MIN_SAFE_INTEGER)),
    )
  ) {
    saveButtonDisabled = true
  }

  return <SaveButton disabled={saveButtonDisabled} onClick={handleSaveClick} />
}

LocationSaveButton.propTypes = {
  locationId: PropTypes.string.isRequired,
  refetchLocation: PropTypes.func,
}

LocationSaveButton.defaultProps = {
  refetchLocation: noop,
}

export default memo(LocationSaveButton)
