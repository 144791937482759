const defaultColumnWidth = 280

const locationColumnWidths = {
  name: defaultColumnWidth,
  locationMetadata: defaultColumnWidth,
  phone: defaultColumnWidth,
  email: defaultColumnWidth,
  website: defaultColumnWidth,
  status: defaultColumnWidth,
  address: defaultColumnWidth,
  city: defaultColumnWidth,
  country: defaultColumnWidth,
  zip: 130,
  latitude: 130,
  longitude: 130,
  menuSource: defaultColumnWidth,
  menuLink: defaultColumnWidth,
  internalComment: defaultColumnWidth,
  incompleteMenu: defaultColumnWidth,
  openingHours: 790,
  servicingHours: 580,
  menuTitle: 580,
  updated: 360,
}

export default locationColumnWidths
