import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import { EVENT_TYPES, idNew } from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { dishPropType } from '../../../propTypes'
import { duplicateDish } from '../../../redux'

const DuplicateDishButton = ({
  style,
  dish,
  tooltipDisabledMessage,
  size,
  disabled,
}) => {
  const dispatch = useDispatch()
  const { brandId } = useParams()
  const { setQueryParams } = useQueryParams()

  const handleClick = useCallback(() => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.BRAND_DISH_EVENTS.DUPLICATE_DISH_CLICK,
      { from: dish.id, brandId },
    )
    dispatch(duplicateDish({ dish, brandId }))

    setQueryParams({ selectedDishId: idNew })
  }, [dish, brandId, dispatch, setQueryParams])

  return (
    <Tooltip title={tooltipDisabledMessage} placement="bottom">
      <Button
        icon={<PlusSquareOutlined />}
        size={size}
        style={style}
        onClick={disabled ? null : handleClick}
        disabled={disabled}
      />
    </Tooltip>
  )
}

DuplicateDishButton.propTypes = {
  style: PropTypes.shape({}),
  dish: dishPropType.isRequired,
  tooltipDisabledMessage: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
}

DuplicateDishButton.defaultProps = {
  style: undefined,
  tooltipDisabledMessage: undefined,
  size: undefined,
  disabled: false,
}

export default memo(DuplicateDishButton)
