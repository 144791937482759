import { gql } from '@apollo/client'

import { USER_MANAGEMENT_FRAGMENT } from '../fragments'

export default gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserManagementFragment
    }
  }
  ${USER_MANAGEMENT_FRAGMENT}
`
