import { gql } from '@apollo/client'

import {
  SERVICING_HOUR_FRAGMENT,
  SIMPLE_ENTITY_FRAGMENT,
} from '../../../common/graphql'

export default gql`
  fragment LocationDishFragment on LocationDish {
    dishId
    locationId
    name
    disabled
    overridenLowPrice
    overridenHighPrice
    currency
    menuTitle {
      ...SimpleEntityFragment
    }
    servicingHours {
      ...ServicingHourFragment
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
  ${SERVICING_HOUR_FRAGMENT}
`
