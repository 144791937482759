import { isEmpty, size } from 'lodash'

const findNodeBy = (tree = [], filterFunction) => {
  let stack = []
  let node

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < size(tree); i++) {
    stack.push(tree[i])

    while (size(stack) > 0) {
      node = stack.pop()

      if (filterFunction(node)) {
        return node
      }

      if (!isEmpty(node.children)) {
        stack = [...stack, ...node.children]
      }
    }
  }

  return null
}

export default findNodeBy
