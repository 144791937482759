import PropTypes from 'prop-types'

const brandSuggestedWordPropTypeShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string,
  originalValue: PropTypes.string.isRequired,
  labelLegendMatchType: PropTypes.string,
  context: PropTypes.string.isRequired,
  payload: PropTypes.string,
  translation: PropTypes.string,
  isExported: PropTypes.bool.isRequired,
  isCurationDone: PropTypes.bool.isRequired,
  isQaDone: PropTypes.bool.isRequired,
  originField: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

const brandSuggestedWordPropType = PropTypes.shape(
  brandSuggestedWordPropTypeShape,
)

export default brandSuggestedWordPropType
