import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Col, notification, Popconfirm, Tooltip } from 'antd'
import { includes, isEmpty, isNil, map } from 'lodash'
import { PropTypes } from 'prop-types'

import {
  useDeepCompareEffect,
  usePrevious,
  useQueryParams,
} from '../../../../core/hooks'
import { isStringTrue } from '../../../../core/utils'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useUserHasBrandChangePermission } from '../../../../common/hooks'

import { DEFAULT_DISH_LIST_FILTERS } from '../../../../dishes/constants'
import {
  CONFIRM_STATUS_MUTATION,
  CONFIRM_STATUS_VALIDATION_QUERY,
  GET_BATCH_AI_SUGGESTIONS_FOR_STATUS_MUTATION,
  RESET_STATUS_MUTATION,
  SUGGEST_UNKNOWN_WORDS_MUTATION,
} from '../../../graphql'
import { BrandResetButton } from '../../atoms'
import { BrandValidationModal } from '../../sections'
import { LabelLegendSuggestButton } from '../LabelLegendSuggestButton'

const BrandStatusButtons = ({
  brand,
  dishesCount,
  disabled,
  onlySuggestions,
  size,
  disabledMessage,
}) => {
  const { id: brandId, status } = brand

  const {
    userHasBrandChangePermission,
    isUserQaOfBrand,
    userHasAdminPermissions,
  } = useUserHasBrandChangePermission(brand)

  const [isPopConfirmVisible, setIsPopConfirmVisible] = useState(false)

  const {
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
  } = useQueryParams()

  const isCurrentStatusForSuggestions = useMemo(
    () =>
      includes(
        [
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.LABEL_LEGENDS.value,
          CURATION_STATUS.MISC_AND_CHOICE.value,
          CURATION_STATUS.CURATION.value,
        ],
        status,
      ),
    [status],
  )
  const isResetCurationButtonShown = useMemo(
    () =>
      includes(
        [
          CURATION_STATUS.MISC_AND_CHOICE.value,
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
          CURATION_STATUS.LABEL_LEGENDS.value,
          CURATION_STATUS.LABEL_LEGENDS_CONFIRMATION.value,
          CURATION_STATUS.CURATION.value,
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.QQA_CONFIRMATION.value,
          CURATION_STATUS.QA.value,
          CURATION_STATUS.DONE.value,
        ],
        status,
      ),
    [status],
  )

  const [
    getValidationErrors,
    {
      data: { confirmStatusErrorMessages } = {},
      loading: isErrorMessagesLoading,
      error: errorMessagesFetchError,
    },
  ] = useLazyQuery(CONFIRM_STATUS_VALIDATION_QUERY, {
    fetchPolicy: 'network-only',
  })

  const [getBatchAiSuggestions, { loading: getSuggestionsLoading }] =
    useMutation(GET_BATCH_AI_SUGGESTIONS_FOR_STATUS_MUTATION, {
      onError: handleMutationError,
      update: (cache, { data: { getBatchAiSuggestionsForStatus } = {} }) => {
        cache.modify({
          id: cache.identify(
            getBatchAiSuggestionsForStatus
              ? {
                  id: getBatchAiSuggestionsForStatus.id,
                  __typename: onlySuggestions ? 'SimpleBrand' : 'Brand',
                }
              : {},
          ),
          fields: {
            pendingStatus(cachedPendingStatus) {
              return (
                getBatchAiSuggestionsForStatus?.pendingStatus ||
                cachedPendingStatus
              )
            },
            status(cachedStatus) {
              return getBatchAiSuggestionsForStatus?.status || cachedStatus
            },
          },
        })
      },
    })

  const [suggestUnknownWords, { loading: isSuggestUnknownWordsLoading }] =
    useMutation(SUGGEST_UNKNOWN_WORDS_MUTATION, {
      variables: { brandId, isStepAdvancing: true },
      onError: handleMutationError,
      update: (cache, { data: { suggestUnknownWords: result } = {} }) => {
        cache.modify({
          id: cache.identify(
            result
              ? {
                  id: result.id,
                  __typename: onlySuggestions ? 'SimpleBrand' : 'Brand',
                }
              : {},
          ),
          fields: {
            pendingStatus(cachedPendingStatus) {
              return result?.pendingStatus || cachedPendingStatus
            },
            status(cachedStatus) {
              return result?.status || cachedStatus
            },
          },
        })
      },
    })

  const [confirmStatus, { loading: confirmLoading }] = useMutation(
    CONFIRM_STATUS_MUTATION,
    { onError: handleMutationError },
  )

  const [resetStatus, { loading: resetLoading }] = useMutation(
    RESET_STATUS_MUTATION,
    {
      onError: handleMutationError,
    },
  )

  const validationMessages = useMemo(() => {
    const unvalidatedDishesValidationMessages = map(
      confirmStatusErrorMessages?.unvalidatedDishes,
      ({ id }) => ({
        dishId: id,
        messages: [
          {
            level: 'Error',
            message: 'Dish is not confirmed yet or has dish/course type errors',
          },
        ],
      }),
    )

    const nonQaDoneDishesValidationMessages = map(
      confirmStatusErrorMessages?.nonQaDoneDishes,
      ({ id }) => ({
        dishId: id,
        messages: [
          {
            level: 'Error',
            message: 'Dish is not marked as QA done',
          },
        ],
      }),
    )

    const nonQqaDoneDishesValidationMessages = map(
      confirmStatusErrorMessages?.nonQqaDoneDishes,
      ({ id }) => ({
        dishId: id,
        messages: [
          {
            level: 'Error',
            message: 'Dish is not marked as QA done',
          },
        ],
      }),
    )

    return [
      ...(confirmStatusErrorMessages?.aiDishErrorMessages || []),
      ...unvalidatedDishesValidationMessages,
      ...nonQaDoneDishesValidationMessages,
      ...nonQqaDoneDishesValidationMessages,
    ]
  }, [confirmStatusErrorMessages])

  const { areStatusButtonsDisabled, reason, isResetDisabled } = useMemo(() => {
    if (!userHasBrandChangePermission) {
      return {
        isResetDisabled: true,
        areStatusButtonsDisabled: true,
        reason:
          'The status can only be changed by an assigned user or an admin.',
      }
    }

    if (disabled && disabledMessage) {
      return {
        isResetDisabled: true,
        areStatusButtonsDisabled: true,
        reason: disabledMessage,
      }
    }

    if (isUserQaOfBrand && !userHasAdminPermissions) {
      return {
        isResetDisabled: true,
        areStatusButtonsDisabled: false,
        reason: 'Qa users cannot reset the brand.',
      }
    }

    return {
      isResetDisabled: false,
      areStatusButtonsDisabled: false,
      confirmationDisabled: false,
    }
  }, [
    userHasBrandChangePermission,
    disabled,
    disabledMessage,
    isUserQaOfBrand,
    userHasAdminPermissions,
  ])

  const handleStatusChange = useCallback(
    (retryForErrors = false) => {
      let overridingStep = status

      if (retryForErrors) {
        overridingStep =
          overridingStep === CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value
            ? CURATION_STATUS.MISC_AND_CHOICE.value
            : overridingStep
        overridingStep =
          overridingStep === CURATION_STATUS.CURATION_CONFIRMATION.value
            ? CURATION_STATUS.CURATION.value
            : overridingStep
      }

      if (overridingStep === CURATION_STATUS.UNKNOWN_WORDS_CURATION.value) {
        return suggestUnknownWords()
      }

      if (
        overridingStep === CURATION_STATUS.MISC_AND_CHOICE.value ||
        overridingStep === CURATION_STATUS.CURATION.value
      ) {
        return getBatchAiSuggestions({
          variables: {
            input: {
              id: brandId,
              status: overridingStep,
              retryForErrors,
            },
          },
        })
      }
      return getValidationErrors({
        variables: { input: { id: brandId, status: overridingStep } },
      })
    },
    [
      status,
      getValidationErrors,
      brandId,
      suggestUnknownWords,
      getBatchAiSuggestions,
    ],
  )

  const handlePopConfirm = useCallback(
    () => handleStatusChange(),
    [handleStatusChange],
  )
  const handleCurationReset = useCallback(() => {
    resetStatus({
      variables: {
        input: {
          id: brandId,
          targetStatus: CURATION_STATUS.MISC_AND_CHOICE.value,
        },
      },
    })
  }, [resetStatus, brandId])
  const handleQaReset = useCallback(() => {
    resetStatus({
      variables: {
        input: {
          id: brandId,
          targetStatus: CURATION_STATUS.QA.value,
        },
      },
    })
  }, [resetStatus, brandId])
  const handleQQaReset = useCallback(() => {
    resetStatus({
      variables: {
        input: {
          id: brandId,
          targetStatus: CURATION_STATUS.QQA_CONFIRMATION.value,
        },
      },
    })
  }, [resetStatus, brandId])

  const prevIsErrorMessagesLoading = usePrevious(isErrorMessagesLoading)

  useDeepCompareEffect(() => {
    // in order to ensure we don't trigger the confirmation without clicking on the button we have
    // to check if the query was trigger by looking at the current and previous loading flag
    if (
      isEmpty(validationMessages) &&
      isNil(errorMessagesFetchError) &&
      prevIsErrorMessagesLoading &&
      !isErrorMessagesLoading
    ) {
      confirmStatus({
        variables: {
          input: {
            id: brandId,
            status,
          },
        },
      })
    }
  }, [
    errorMessagesFetchError,
    validationMessages,
    prevIsErrorMessagesLoading,
    isErrorMessagesLoading,
    confirmStatus,
    brandId,
    status,
  ])

  const statusButtons = useMemo(() => {
    if (isCurrentStatusForSuggestions) {
      if (includes([CURATION_STATUS.LABEL_LEGENDS.value], status)) {
        return <LabelLegendSuggestButton disabled={areStatusButtonsDisabled} />
      }

      return (
        <Tooltip title={areStatusButtonsDisabled ? reason : undefined}>
          <Button
            size={size}
            disabled={areStatusButtonsDisabled}
            loading={getSuggestionsLoading || isSuggestUnknownWordsLoading}
            type="primary"
            onClick={() => handleStatusChange()}
          >
            {size === 'small'
              ? CURATION_STATUS[status].buttonShortLabel
              : CURATION_STATUS[status].buttonLabel}
          </Button>
        </Tooltip>
      )
    }

    if (onlySuggestions) {
      return null
    }
    return (
      <>
        <Col>
          <Popconfirm
            placement="top"
            title="Are you sure you want to confirm?"
            onConfirm={handlePopConfirm}
            okText="Yes"
            cancelText="No"
            disabled={areStatusButtonsDisabled}
            visible={isPopConfirmVisible}
            onVisibleChange={setIsPopConfirmVisible}
          >
            <Tooltip title={areStatusButtonsDisabled ? reason : undefined}>
              <Button
                size={size}
                type="default"
                disabled={areStatusButtonsDisabled}
                loading={confirmLoading}
              >
                {size === 'small'
                  ? CURATION_STATUS[status].buttonShortLabel
                  : CURATION_STATUS[status].buttonLabel}
              </Button>
            </Tooltip>
          </Popconfirm>
        </Col>
        {dishesCount > 0 && isStringTrue(dishHasErrorsFilter) && (
          <Col>
            <Button
              size={size}
              type="primary"
              disabled={areStatusButtonsDisabled}
              loading={confirmLoading}
              onClick={() => handleStatusChange(true)}
            >
              Retry suggestion for dishes with errors
            </Button>
          </Col>
        )}
      </>
    )
  }, [
    isCurrentStatusForSuggestions,
    onlySuggestions,
    handlePopConfirm,
    areStatusButtonsDisabled,
    isPopConfirmVisible,
    reason,
    size,
    confirmLoading,
    status,
    dishesCount,
    dishHasErrorsFilter,
    getSuggestionsLoading,
    isSuggestUnknownWordsLoading,
    handleStatusChange,
  ])

  useEffect(() => {
    if (!isNil(errorMessagesFetchError)) {
      notification.error({
        message: 'Confirmation validation failed to fetch',
        description: errorMessagesFetchError.message,
        placement: 'topLeft',
      })
    }
  }, [errorMessagesFetchError])

  useHotkeys(
    AVAILABLE_HOTKEYS.SHIFT_C.hotkey,
    async () => {
      if (isCurrentStatusForSuggestions) {
        return handleStatusChange()
      }

      return setIsPopConfirmVisible(true)
    },
    {
      enabled:
        !onlySuggestions &&
        !areStatusButtonsDisabled &&
        status !== CURATION_STATUS.DONE.value,
    },
    [
      onlySuggestions,
      areStatusButtonsDisabled,
      status,
      handleStatusChange,
      setIsPopConfirmVisible,
      isCurrentStatusForSuggestions,
    ],
  )

  return (
    <>
      {isResetCurationButtonShown && !onlySuggestions && (
        <>
          <BrandResetButton
            label="Reset curation"
            confirmToltip="This will delete all curation data from this brand."
            isLoading={resetLoading}
            disabled={isResetDisabled}
            disabledMessage={reason}
            onReset={handleCurationReset}
          />
          {status === CURATION_STATUS.DONE.value && (
            <BrandResetButton
              label="Reset to QA"
              confirmToltip="This will take the brand and dishes back to QA."
              isLoading={resetLoading}
              disabled={isResetDisabled}
              disabledMessage={reason}
              onReset={handleQaReset}
            />
          )}
          {status === CURATION_STATUS.DONE.value && (
            <BrandResetButton
              label="Reset to QQA"
              confirmToltip="This will take the brand and dishes back to QQA"
              isLoading={resetLoading}
              disabled={isResetDisabled}
              disabledMessage={reason}
              onReset={handleQQaReset}
            />
          )}
        </>
      )}
      {status !== CURATION_STATUS.DONE.value && statusButtons}
      <BrandValidationModal errors={validationMessages} />
    </>
  )
}

BrandStatusButtons.propTypes = {
  brand: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onlySuggestions: PropTypes.bool,
  dishesCount: PropTypes.number,
  size: PropTypes.string,
  disabledMessage: PropTypes.string,
}

BrandStatusButtons.defaultProps = {
  disabled: false,
  onlySuggestions: false,
  dishesCount: undefined,
  size: undefined,
  disabledMessage: undefined,
}

export default memo(BrandStatusButtons)
