import React, { useCallback, useEffect, useMemo } from 'react'
import { MinusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, Row, Select } from 'antd'
import { isEmpty, some } from 'lodash'
import PropTypes from 'prop-types'

import { usePrevious } from '../../../../core/hooks'
import { areSameValue } from '../../../../core/utils'

import { getFilterOptions } from '../../../../common/helpers'

import { USER_COMPETENCY_TYPE } from '../../../constants'

const styles = {
  container: {
    marginBottom: 5,
  },
  select: {
    width: '100%',
  },
}

const CompetencyRow = ({
  competency,
  competencyOptions,
  onValueChanged,
  disabled,
}) => {
  const { competencyId, type, isActive } = competency

  const competencyTypeOptions = useMemo(
    () => getFilterOptions(USER_COMPETENCY_TYPE),
    [],
  )

  const handleOnDelete = useCallback(() => {
    onValueChanged({ ...competency, deleted: true })
  }, [competency, onValueChanged])

  const handleOnCompetencyIdChange = useCallback(
    value => {
      onValueChanged({ ...competency, competencyId: value })
    },
    [competency, onValueChanged],
  )

  const handleOnTypeChange = useCallback(
    value => {
      onValueChanged({ ...competency, type: value })
    },
    [competency, onValueChanged],
  )

  const handleOnIsActiveChange = useCallback(
    e => {
      onValueChanged({ ...competency, isActive: e.target.checked })
    },
    [competency, onValueChanged],
  )

  const previousCompetencyOptions = usePrevious(competencyOptions)

  useEffect(() => {
    if (
      !isEmpty(previousCompetencyOptions) &&
      !some(competencyOptions, ({ value }) => areSameValue(competencyId, value))
    ) {
      onValueChanged({ ...competency, deleted: true })
    }
  }, [
    previousCompetencyOptions,
    competencyOptions,
    competencyId,
    onValueChanged,
    competency,
  ])

  return (
    <Row gutter={[8, 6]} style={styles.container}>
      <Col span={9}>
        <Select
          style={styles.select}
          value={competencyId}
          placeholder="Competency"
          onChange={handleOnCompetencyIdChange}
          options={competencyOptions}
          disabled={disabled}
        />
      </Col>

      <Col span={9}>
        <Select
          style={styles.select}
          value={type}
          placeholder="Type"
          onChange={handleOnTypeChange}
          options={competencyTypeOptions}
          disabled={disabled}
        />
      </Col>

      <Col span={4}>
        <Form.Item label="Is Active">
          <Checkbox
            onChange={handleOnIsActiveChange}
            checked={isActive}
            disabled={disabled}
          />
        </Form.Item>
      </Col>

      <Col span={2}>
        <Button
          icon={<MinusOutlined />}
          shape="circle"
          onClick={handleOnDelete}
          disabled={disabled}
        />
      </Col>
    </Row>
  )
}

CompetencyRow.propTypes = {
  competency: PropTypes.object.isRequired,
  competencyOptions: PropTypes.arrayOf(PropTypes.object),
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CompetencyRow.defaultProps = {
  competencyOptions: [],
  disabled: false,
}

export default CompetencyRow
