import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { WarningOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Menu, Row, Space, Typography } from 'antd'
import {
  filter,
  get,
  groupBy,
  includes,
  isEmpty,
  isNil,
  map,
  reduce,
} from 'lodash'

import { Spinner } from '../../../../core/components'
import {
  useDeepCompareEffect,
  useDocumentTitle,
  usePrevious,
  useQueryParams,
  useShallowEqualSelector,
} from '../../../../core/hooks'
import { areSameValue, changeFavicon } from '../../../../core/utils'

import { PageWithHeader } from '../../../../common/components'
import {
  StyledPageHeader,
  StyledPageTitle,
} from '../../../../common/components/styled'
import {
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
  MENU_ENTRY,
  ORIGIN_PLACES,
} from '../../../../common/constants'
import { USERS_WITH_KNOWLEDGE_QUERY } from '../../../../common/graphql'
import { handleMutationError } from '../../../../common/helpers'
import {
  useAppLoading,
  useMlIngredientsEnabled,
  useUserHasBrandChangePermission,
} from '../../../../common/hooks'
import { setActiveBrandName } from '../../../../common/redux/actions'
import { googleAnalyticsEventsService } from '../../../../common/services'
import { findNodeBy } from '../../../../common/utils'

import { DishFilters, DishList } from '../../../../dishes/components'
import {
  AddDishButton,
  DishListHotkeysLegend,
} from '../../../../dishes/components/molecules'
import { DISH_IGNORED_FILTER, DISH_SORT_BY } from '../../../../dishes/constants'
import {
  MenuTitleProcessingButton,
  MenuTitleTable,
} from '../../../../menuTitles/components'
import theme from '../../../../theme'
import { BRAND_PENDING_STATUS } from '../../../constants'
import {
  BRAND_QUERY,
  DELETE_IMPORTED_MENU_ON_BRAND_MUTATION,
  DISHES_SUMMARY_QUERY,
  DISHES_WITH_NO_MENU_TITLE_SUMMARY_QUERY,
} from '../../../graphql'
import { BrandSupportingDataProvider } from '../../../providers'
import { brandEntryValidationSchema } from '../../../schemas'
import { brandTransformer } from '../../../transformers'
import {
  BrandDiscardButton,
  BrandSaveButton,
  BrandStatusButtons,
  DishStatusCounter,
  LabelLegendSuggestButton,
  MenuImportButton,
} from '../../molecules'
import {
  BrandEntry,
  BrandPendingModal,
  IgnoredDishTypeModal,
} from '../../sections'

const styles = {
  container: {
    padding: theme.padding,
    paddingTop: 0,
  },
  processMenuTitlesButton: {
    marginLeft: '10px',
  },
  hotkeyLegendContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 24px',
  },
  warningIcon: {
    color: '#faad14',
  },
  warningText: {
    color: '#faad14',
    marginLeft: 6,
  },
  statusButtonsRow: {
    padding: '0px 1rem 1rem',
    marginLeft: 0,
    marginRight: 0,
  },
}

const BrandDetails = () => {
  const [menuTitleIdForIgnoredDishType, setMenuTitleIdForIgnoredDishType] =
    useState(null)

  const dispatch = useDispatch()
  const { brandId, dishId } = useParams()

  const { formState, ...restFormMethods } = useForm({
    resolver: yupResolver(brandEntryValidationSchema),
  })

  const { startLoading, stopLoading } = useAppLoading()
  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const { setQueryParams } = useQueryParams()

  const areMlIngredientsEnabled = useMlIngredientsEnabled()

  const {
    state: { isDirty: isMenuTitlesDirty },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  // In order to avoid an additional brand query when modifying the dish list we will not use network only
  // anymore
  const {
    loading: isBrandLoading,
    error: brandError,
    data: brandData = {},
    refetch: refetchBrand,
  } = useQuery(BRAND_QUERY, {
    variables: { id: brandId },
    onCompleted: ({ brand: { name } }) => dispatch(setActiveBrandName(name)),
    onError: handleMutationError,
  })

  const { data: dishesWithNoMenuTitleSummaryData = {} } = useQuery(
    DISHES_WITH_NO_MENU_TITLE_SUMMARY_QUERY,
    {
      variables: { brandId },
      onError: handleMutationError,
    },
  )

  const { data: dishesSummaryData = {} } = useQuery(DISHES_SUMMARY_QUERY, {
    variables: { brandId },
    onError: handleMutationError,
  })

  const { dishesWithNoMenuTitleSummary = {} } = dishesWithNoMenuTitleSummaryData
  const { dishesSummary = {} } = dishesSummaryData

  const { brand: { menuTitles = [], qaCorrectionCounts, ...brand } = {} } =
    brandData

  const {
    servicingHours,
    dietLabelLegends,
    allergenLabelLegends,
    diets,
    pendingStatus,
    status: brandStatus,
    isPartOfCleanupTask,
  } = brand

  const { data: { users } = {}, loading: usersWithKnowledgeLoading } = useQuery(
    USERS_WITH_KNOWLEDGE_QUERY,
    {
      variables: {
        filters: {
          isActive: true,
        },
      },
      onError: handleMutationError,
    },
  )

  useDocumentTitle(
    `Brand ${
      get(brandData, 'brand.name') ? ` | ${get(brandData, 'brand.name')}` : ''
    }`,
    dishId,
  )

  // TODO find a way to move the deletion of a menu into the menu respective section
  const [deleteMenuFromBrand] = useMutation(
    DELETE_IMPORTED_MENU_ON_BRAND_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: () =>
        setQueryParams({
          currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
          menuTitleId: null,
        }),
    },
  )

  const deleteMenu = useCallback(
    async menuId => {
      startLoading()
      setQueryParams({ currentPage: DEFAULT_PAGINATION_CONFIG.PAGE })
      await deleteMenuFromBrand({ variables: { menuId } })
      refetchBrand()
      stopLoading()
    },
    [
      startLoading,
      setQueryParams,
      deleteMenuFromBrand,
      refetchBrand,
      stopLoading,
    ],
  )

  const highlightsFromLabels = useMemo(
    () => [
      ...map(
        reduce(
          filter(dietLabelLegends, 'isSuggestedByAi'),
          (acc, { highlights, isChoice }) => [
            ...acc,
            ...map(highlights, highlight => ({ ...highlight, isChoice })),
          ],
          [],
        ),
        highlight => ({
          ...highlight,
          matchType: `dietLabelLegend_${highlight.matchType}${
            highlight.isChoice ? '_choice' : ''
          }}`,
          originField: highlight.originField,
        }),
      ),
      ...map(
        reduce(
          filter(allergenLabelLegends, 'isSuggestedByAi'),
          (acc, { highlights, isChoice }) => [
            ...acc,
            ...map(highlights, highlight => ({ ...highlight, isChoice })),
          ],
          [],
        ),
        highlight => ({
          ...highlight,
          matchType: `allergenLabelLegend_${highlight.matchType}${
            highlight.isChoice ? '_choice' : ''
          }`,
          originField: highlight.originField,
        }),
      ),
    ],
    [dietLabelLegends, allergenLabelLegends],
  )

  const menuTitlesHighlightWords = useMemo(
    () => groupBy(filter(highlightsFromLabels, 'menuTitleId'), 'menuTitleId'),
    [highlightsFromLabels],
  )

  const menuTitleForIgnoredDishType = useMemo(
    () =>
      findNodeBy(menuTitles, ({ id: nodeId }) =>
        areSameValue(menuTitleIdForIgnoredDishType, nodeId),
      ),
    [menuTitleIdForIgnoredDishType, menuTitles],
  )

  const isSuggestLabelLegendsButtonDisabled = useMemo(
    () =>
      !userHasBrandChangePermission ||
      !includes(
        [
          CURATION_STATUS.LABEL_LEGENDS_CONFIRMATION.value,
          CURATION_STATUS.LABEL_LEGENDS.value,
        ],
        brandStatus,
      ),
    [userHasBrandChangePermission, brandStatus],
  )

  const isBrandDirty = useMemo(
    () => formState.isDirty || isMenuTitlesDirty,
    [formState.isDirty, isMenuTitlesDirty],
  )

  useEffect(() => {
    if (!isEmpty(brandData) && isBrandLoading) {
      startLoading()
    }

    if (!isBrandLoading) {
      stopLoading()
    }
  }, [brandData, isBrandLoading, startLoading, stopLoading])

  const previousPendingStatus = usePrevious(pendingStatus)
  useEffect(() => {
    if (!previousPendingStatus && pendingStatus) {
      if (
        includes(
          [
            BRAND_PENDING_STATUS.MENU_IMPORT_ERROR,
            BRAND_PENDING_STATUS.BATCH_CURATION_ERROR,
            BRAND_PENDING_STATUS.DUPLICATE_ERROR,
            BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_ERROR,
            BRAND_PENDING_STATUS.EXPORT_WORDS_ERROR,
          ],
          pendingStatus,
        )
      ) {
        changeFavicon('/favicon-circle-yellow.svg', 'image/svg+xml')
      } else {
        changeFavicon('/favicon-circle-red.svg', 'image/svg+xml')
      }
    }

    if (
      includes(
        [
          BRAND_PENDING_STATUS.MENU_IMPORT_PENDING,
          BRAND_PENDING_STATUS.BATCH_CURATION_PENDING,
          BRAND_PENDING_STATUS.MISC_AND_CHOICE_PENDING,
          BRAND_PENDING_STATUS.DUPLICATE_PENDING,
          BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_PENDING,
          BRAND_PENDING_STATUS.EXPORT_WORDS_PENDING,
        ],
        previousPendingStatus,
      ) &&
      includes(
        [
          BRAND_PENDING_STATUS.MENU_IMPORT_ERROR,
          BRAND_PENDING_STATUS.BATCH_CURATION_ERROR,
          BRAND_PENDING_STATUS.DUPLICATE_ERROR,
          BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_ERROR,
          BRAND_PENDING_STATUS.EXPORT_WORDS_ERROR,
        ],
        pendingStatus,
      )
    ) {
      changeFavicon('/favicon-circle-yellow.svg', 'image/svg+xml')
    }

    if (previousPendingStatus && !pendingStatus) {
      changeFavicon('/favicon-circle-green.svg', 'image/svg+xml')
    }
  }, [previousPendingStatus, pendingStatus])

  useDeepCompareEffect(() => {
    if (!isEmpty(brand) && !isBrandLoading) {
      restFormMethods.reset(
        {
          ...brandTransformer.transformBrandFromDto(brand),
          locationTypeId: get(brand, 'locationType.id'),
          cuisineTypeId: get(brand, 'cuisineType.id'),
        },
        { isDirty: false },
      )
    }
  }, [brandData.brand, isBrandLoading, restFormMethods.reset])

  const previousBrandStatus = usePrevious(brandStatus)
  useEffect(() => {
    if (!isNil(brandStatus) && brandStatus !== previousBrandStatus) {
      if (
        includes(
          [
            CURATION_STATUS.SANITY_CHECK.value,
            CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
            CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
            CURATION_STATUS.UNKNOWN_WORDS_QA.value,
          ],
          brandStatus,
        )
      ) {
        return setQueryParams(
          {
            isStatusSameAsBrand: true,
            sortDishesBy: DISH_SORT_BY.ID,
          },
          true,
        )
      }

      if (
        includes(
          [
            CURATION_STATUS.MISC_AND_CHOICE.value,
            CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
            CURATION_STATUS.LABEL_LEGENDS.value,
            CURATION_STATUS.LABEL_LEGENDS_CONFIRMATION.value,
          ],
          brandStatus,
        )
      ) {
        return setQueryParams(
          {
            isStatusSameAsBrand: true,
            ignored: DISH_IGNORED_FILTER.NOT_IGNORED.value,
            sortDishesBy: DISH_SORT_BY.CERTAINTY,
          },
          true,
        )
      }

      if (
        includes(
          [
            CURATION_STATUS.CURATION.value,
            CURATION_STATUS.CURATION_CONFIRMATION.value,
            CURATION_STATUS.QA.value,
            CURATION_STATUS.QQA_CONFIRMATION.value,
          ],
          brandStatus,
        )
      ) {
        return setQueryParams(
          {
            isStatusSameAsBrand: true,
            ignored: null,
            sortDishesBy: DISH_SORT_BY.CERTAINTY,
          },
          true,
        )
      }

      setQueryParams(
        {
          isStatusSameAsBrand: true,
          ignored: DISH_IGNORED_FILTER.NOT_IGNORED.value,
          sortDishesBy: DISH_SORT_BY.QA_CORRECTIONS,
        },
        true,
      )
    }
  }, [brandStatus, previousBrandStatus, setQueryParams])

  const rightMenu = useCallback(
    () => (
      <Menu.Item>
        <BrandDiscardButton />
        <BrandSaveButton brandId={brandId} />
      </Menu.Item>
    ),
    [brandId],
  )

  const { disabledCurationButtons, disabledCurationButtonsMessage } =
    useMemo(() => {
      let disabledMessage
      if (isPartOfCleanupTask) {
        disabledMessage = 'This brand is part of a cleanup task.'
      } else if (isBrandDirty) {
        disabledMessage = 'Brand or menu titles contain modifications'
      }

      return {
        disabledCurationButtons: isBrandDirty || isPartOfCleanupTask,
        disabledCurationButtonsMessage: disabledMessage,
      }
    }, [isBrandDirty, isPartOfCleanupTask])

  if (isEmpty(brandData) && (isBrandLoading || usersWithKnowledgeLoading)) {
    return <Spinner size="large" />
  }

  return (
    <BrandSupportingDataProvider
      menuTitles={menuTitles}
      servicingHours={servicingHours}
    >
      <FormProvider {...restFormMethods} formState={formState}>
        <PageWithHeader menuItem={MENU_ENTRY.BRAND} renderRightMenu={rightMenu}>
          {brandError ? (
            <Typography.Paragraph>{brandError.message}</Typography.Paragraph>
          ) : (
            <>
              <StyledPageHeader>
                <StyledPageTitle>{brand.name}</StyledPageTitle>
                <Space>
                  <MenuImportButton disabled={!userHasBrandChangePermission} />
                  <MenuTitleProcessingButton
                    ownerId={brandId}
                    ownerType="brands"
                    menuTitleTreeData={menuTitles}
                    menuTitlesHighlightWords={menuTitlesHighlightWords}
                    style={styles.processMenuTitlesButton}
                  />
                </Space>
              </StyledPageHeader>

              <form style={styles.container}>
                {isPartOfCleanupTask && (
                  <Row>
                    <Col span={12}>
                      <WarningOutlined style={styles.warningIcon} />
                      <Typography.Text style={styles.warningText}>
                        This brand is part of a cleanup task
                      </Typography.Text>
                    </Col>
                  </Row>
                )}
                {!areMlIngredientsEnabled && (
                  <Row>
                    <Col span={12}>
                      <WarningOutlined style={styles.warningIcon} />
                      <Typography.Text style={styles.warningText}>
                        ML ingredient suggestions are disabled
                      </Typography.Text>
                    </Col>
                  </Row>
                )}
                <BrandEntry
                  brand={brand}
                  users={users}
                  qaCorrectionCounts={qaCorrectionCounts}
                />
                <Row gutter={16} justify="end">
                  <Col span={13}>
                    <LabelLegendSuggestButton
                      disabled={isSuggestLabelLegendsButtonDisabled}
                    />
                  </Col>
                </Row>
              </form>
              <MenuTitleTable
                brandStatus={brandStatus}
                ownerId={brandId}
                ownerType="brands"
                menuTitles={menuTitles}
                menuTitlesHighlightWords={menuTitlesHighlightWords}
                deleteMenu={deleteMenu}
                setIgnoredDishType={setMenuTitleIdForIgnoredDishType}
                isQaCorrected={qaCorrectionCounts?.menuTitles > 0}
              />
              <Row>
                <Col span={18}>
                  <DishStatusCounter dishSummary={dishesSummary} />
                </Col>
                <Col span={6} style={styles.hotkeyLegendContainer}>
                  <DishListHotkeysLegend brandStatus={brandStatus} />
                </Col>
              </Row>
              <DishFilters
                brandStatus={brandStatus}
                isBrandDirty={isBrandDirty}
                config={{
                  filterByStatus: true,
                  filterByServicingHours: true,
                  sortBy: true,
                  filterByMenuTitles: true,
                  filterBySearch: true,
                  filterByIgnored: true,
                  filterByPublished: true,
                  filterByErrors: true,
                  addNewDish: true,
                }}
                dishesWithNoMenuTitleSummary={dishesWithNoMenuTitleSummary}
              />
              <Row gutter={16} style={styles.statusButtonsRow}>
                <BrandStatusButtons
                  brand={brand}
                  brandId={brandId}
                  disabled={disabledCurationButtons}
                  disabledMessage={disabledCurationButtonsMessage}
                />
                {!isBrandDirty && (
                  <Col>
                    <AddDishButton
                      disabled={
                        !userHasBrandChangePermission || isPartOfCleanupTask
                      }
                      dietIds={map(diets, 'id')}
                      track={() =>
                        googleAnalyticsEventsService.fireEvent(
                          EVENT_TYPES.BRAND_DISH_EVENTS.ADD_CLICK,
                          {
                            from: ORIGIN_PLACES.DISH_LIST,
                          },
                        )
                      }
                    />
                  </Col>
                )}
              </Row>
              <DishList
                refetchBrand={refetchBrand}
                isBrandDirty={isBrandDirty}
                brandId={brandId}
                menuTitles={menuTitles}
                brandStatus={brandStatus}
                dishesWithNoMenuTitleSummary={dishesWithNoMenuTitleSummary}
                highlightsFromLabels={highlightsFromLabels}
              />
              <BrandPendingModal pendingStatus={pendingStatus} />
              <IgnoredDishTypeModal
                menuTitle={menuTitleForIgnoredDishType}
                onCancel={() => setMenuTitleIdForIgnoredDishType(null)}
                refetchBrand={refetchBrand}
              />
            </>
          )}
        </PageWithHeader>
      </FormProvider>
    </BrandSupportingDataProvider>
  )
}

export default BrandDetails
