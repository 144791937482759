import React, { useCallback, useMemo } from 'react'
import { Checkbox, Input, Row, Select } from 'antd'
import { capitalize, includes, isNil, keys, map, omit, pick } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { useQueryParams } from '../../../../core/hooks'
import { isStringTrue } from '../../../../core/utils'

import {
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
  PERMISSIONS,
  SELECT_ALL_OPTION,
} from '../../../../common/constants'
import { getFilterOptions } from '../../../../common/helpers'
import { useUserHasPermissions } from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { useBrandCurationMetadata } from '../../../../brands/hooks'
import {
  DEFAULT_DISH_LIST_FILTERS,
  DISH_IGNORED_FILTER,
  DISH_PUBLISHED_FILTER,
  DISH_SORT_BY,
} from '../../../constants'
import { DishMenuTitleFilter } from '../../molecules'

const { Option } = Select

const styles = {
  filtersRow: {
    padding: '0px 1rem 1rem',
    marginLeft: 0,
    marginRight: 0,
  },
}

const DishFilters = ({
  isBrandDirty,
  config,
  brandStatus,
  dishesWithNoMenuTitleSummary,
}) => {
  const isQa = useUserHasPermissions([PERMISSIONS.DISHES.UPDATE_QA_CURATION])

  const {
    sortDishesBy = isQa
      ? DISH_SORT_BY.QA
      : DEFAULT_DISH_LIST_FILTERS.SORT_DISHES_BY,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours = DEFAULT_DISH_LIST_FILTERS.SERVICING_HOURS,
    search = DEFAULT_DISH_LIST_FILTERS.SEARCH,
    setQueryParams,
  } = useQueryParams()

  const publishedOptions = useMemo(
    () => [
      SELECT_ALL_OPTION,
      ...getFilterOptions(DISH_PUBLISHED_FILTER, 'label', 'value'),
    ],
    [],
  )
  const ignoredOptions = useMemo(
    () => [
      SELECT_ALL_OPTION,
      ...getFilterOptions(DISH_IGNORED_FILTER, 'label', 'value'),
    ],
    [],
  )

  const { servicingHoursOptions } = useBrandCurationMetadata()

  const setDishStatusSameAsBrand = useCallback(
    ({ target: { checked } }) => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_isStatusSameAsBrand`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        isStatusSameAsBrand: checked,
      })
    },
    [setQueryParams],
  )

  const setDishHasErrors = useCallback(
    ({ target: { checked } }) => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_hasErrors`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        hasErrors: checked,
      })
    },
    [setQueryParams],
  )

  const setDishServingHoursFilter = useCallback(
    value => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_servicing_hours`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        servicingHours: value,
      })
    },
    [setQueryParams],
  )

  const setSortDishesBy = useCallback(
    value => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_sort_dishes_by`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        sortDishesBy: value,
      })
    },
    [setQueryParams],
  )

  const setDishesSearch = useCallback(
    value => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_search`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        search: value,
      })
    },
    [setQueryParams],
  )

  const setDishesIgnored = useCallback(
    value => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_ignored`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        ignored: value,
      })
    },
    [setQueryParams],
  )

  const setDishesPublished = useCallback(
    value => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_published`,
      )
      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        published: value,
      })
    },
    [setQueryParams],
  )

  const {
    filterByStatus,
    filterByServicingHours,
    sortBy,
    filterByMenuTitles,
    filterBySearch,
    filterByIgnored,
    filterByPublished,
    filterByErrors,
  } = config

  // we need to filter the options to take advantage of the simplified query for dishes
  const sortByOptions = useMemo(() => {
    if (
      (isStringTrue(dishIsStatusSameAsBrandFilter) ||
        isStringTrue(dishHasErrorsFilter)) &&
      includes(
        [
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
          CURATION_STATUS.CURATION_CONFIRMATION.value,
        ],
        brandStatus,
      )
    ) {
      return pick(DISH_SORT_BY, 'CERTAINTY')
    }
    return isQa ? DISH_SORT_BY : omit(DISH_SORT_BY, 'QA')
  }, [dishIsStatusSameAsBrandFilter, dishHasErrorsFilter, brandStatus, isQa])
  return (
    <>
      <Row gutter={16} style={styles.filtersRow}>
        {filterByStatus && (
          <GridFormItem span={2} label="Brand status">
            <Checkbox
              disabled={isBrandDirty}
              checked={isStringTrue(dishIsStatusSameAsBrandFilter)}
              onChange={setDishStatusSameAsBrand}
            />
          </GridFormItem>
        )}

        {filterByErrors && (
          <GridFormItem span={2} label="With errors">
            <Checkbox
              disabled={isBrandDirty}
              checked={isStringTrue(dishHasErrorsFilter)}
              onChange={setDishHasErrors}
            />
          </GridFormItem>
        )}

        {filterByIgnored && (
          <GridFormItem span={3} label="Ignored">
            <Select
              disabled={isBrandDirty}
              value={dishIgnoredFilter}
              onSelect={setDishesIgnored}
              options={ignoredOptions}
            />
          </GridFormItem>
        )}

        {filterByPublished && (
          <GridFormItem span={3} label="Published">
            <Select
              disabled={isBrandDirty}
              value={dishPublishedFilter}
              onSelect={setDishesPublished}
              options={publishedOptions}
            />
          </GridFormItem>
        )}

        {filterByServicingHours && (
          <GridFormItem span={3} label="Serving Hours">
            <Select
              disabled={isBrandDirty}
              value={servicingHours}
              onSelect={setDishServingHoursFilter}
            >
              {map(servicingHoursOptions, ({ id, name }) => (
                <Option key={`servicing-hours-option-${id}`} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </GridFormItem>
        )}
        {filterByMenuTitles && (
          <GridFormItem span={7} label="Menu titles">
            <DishMenuTitleFilter
              isBrandDirty={isBrandDirty}
              dishesWithNoMenuTitleSummary={dishesWithNoMenuTitleSummary}
            />
          </GridFormItem>
        )}
        {sortBy && (
          <GridFormItem span={2} label="Sort By">
            <Select
              disabled={isBrandDirty}
              value={sortDishesBy}
              onChange={setSortDishesBy}
            >
              {map(keys(sortByOptions), key => (
                <Option key={key} value={sortByOptions[key]}>
                  {isNil(sortByOptions[key])
                    ? capitalize(key)
                    : sortByOptions[key]}
                </Option>
              ))}
            </Select>
          </GridFormItem>
        )}
      </Row>
      <Row gutter={[16, 16]} style={styles.filtersRow}>
        {filterBySearch && (
          <GridFormItem span={7} label="Keyword search">
            <Input.Search
              placeholder="Search for dishes by keyword..."
              disabled={isBrandDirty}
              defaultValue={search}
              onSearch={setDishesSearch}
            />
          </GridFormItem>
        )}
      </Row>
    </>
  )
}

DishFilters.propTypes = {
  isBrandDirty: PropTypes.bool,
  config: PropTypes.shape({
    filterByStatus: PropTypes.bool,
    filterByIgnored: PropTypes.bool,
    filterByPublished: PropTypes.bool,
    filterByErrors: PropTypes.bool,
    filterByServicingHours: PropTypes.bool,
    sortBy: PropTypes.bool,
    filterByMenuTitles: PropTypes.bool,
    filterBySearch: PropTypes.bool,
  }),
  brandStatus: PropTypes.string.isRequired,
  dishesWithNoMenuTitleSummary: PropTypes.object,
}

DishFilters.defaultProps = {
  isBrandDirty: false,
  config: {},
  dishesWithNoMenuTitleSummary: {},
}

export default DishFilters
