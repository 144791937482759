import React, { memo, useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Row, TreeSelect } from 'antd'
import {
  findIndex,
  isEmpty,
  isNil,
  map,
  size as _size,
  sortBy,
  toString,
} from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'
import { areSameValue } from '../../../../core/utils'

import {
  AVAILABLE_HOTKEYS,
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
} from '../../../../common/constants'
import {
  getTreeIdsUpToLevel,
  handleFilterTreeSelectNode,
  handleTreeSelectCustomDropdownForSearch,
} from '../../../../common/helpers'
import { googleAnalyticsEventsService } from '../../../../common/services'
import { flattenTreeStructure } from '../../../../common/utils'

import { useBrandCurationMetadata } from '../../../../brands/hooks'
import { DEFAULT_DISH_LIST_FILTERS } from '../../../constants'

const styles = {
  treeSelect: {
    width: '100%',
  },
}

const DishMenuTitleFilter = ({
  brandIsDirty,
  size,
  dishesWithNoMenuTitleSummary,
}) => {
  const { menuTitleId = DEFAULT_DISH_LIST_FILTERS.MENU_TITLE, setQueryParams } =
    useQueryParams()

  const { menuTitleOptions: brandMenuTitleOptions } = useBrandCurationMetadata()

  const menuTitleOptions = useMemo(() => {
    const addUsageCount = cMenuTitle => {
      if (isNil(cMenuTitle) || isEmpty(cMenuTitle)) {
        return {}
      }

      return {
        ...cMenuTitle,
        title: `${cMenuTitle.name}${
          !isNil(cMenuTitle.publishedDishCount) &&
          !isNil(cMenuTitle.pendingDishCount) &&
          !isNil(cMenuTitle.automaticIgnoredDishCount) &&
          !isNil(cMenuTitle.manualIgnoredDishCount)
            ? ` (${cMenuTitle.publishedDishCount} - ${cMenuTitle.pendingDishCount} - ${cMenuTitle.automaticIgnoredDishCount} - ${cMenuTitle.manualIgnoredDishCount})`
            : ''
        }`,
        value: cMenuTitle.id,
        isIgnoredItem:
          !cMenuTitle.publishedDishCount &&
          !cMenuTitle.pendingDishCount &&
          (cMenuTitle.automaticIgnoredDishCount > 0 ||
            cMenuTitle.manualIgnoredDishCount > 0),
        children: map(
          sortBy(
            cMenuTitle.children,
            child =>
              !child.publishedDishCount &&
              !child.pendingDishCount &&
              (child.automaticIgnoredDishCount > 0 ||
                child.manualIgnoredDishCount > 0),
          ),
          child => addUsageCount(child),
        ),
      }
    }
    return [
      {
        title: `No menu title ${
          !isNil(dishesWithNoMenuTitleSummary.publishedCount) &&
          !isNil(dishesWithNoMenuTitleSummary.pendingCount) &&
          !isNil(dishesWithNoMenuTitleSummary.automaticIgnoredCount) &&
          !isNil(dishesWithNoMenuTitleSummary.manualIgnoredCount)
            ? ` (${dishesWithNoMenuTitleSummary.publishedCount} - ${dishesWithNoMenuTitleSummary.pendingCount} - ${dishesWithNoMenuTitleSummary.automaticIgnoredCount} - ${dishesWithNoMenuTitleSummary.manualIgnoredCount})`
            : ''
        }`,
        value: 0,
      },
      ...map(brandMenuTitleOptions, rootMenu => addUsageCount(rootMenu)),
    ]
  }, [brandMenuTitleOptions, dishesWithNoMenuTitleSummary])

  const treeDefaultExpandedKeys = useMemo(
    () => getTreeIdsUpToLevel(menuTitleOptions, 1, 1),
    [menuTitleOptions],
  )

  const menuTitleIdsForNavigation = useMemo(
    () => map(flattenTreeStructure(menuTitleOptions), 'id'),
    [menuTitleOptions],
  )

  const { nextMenuTitleId, prevMenuTitleId } = useMemo(() => {
    const currentIndex = findIndex(menuTitleIdsForNavigation, id =>
      areSameValue(id, menuTitleId),
    )

    if (
      currentIndex === -1 ||
      isNil(menuTitleId) ||
      isEmpty(menuTitleIdsForNavigation)
    ) {
      return {
        nextMenuTitleId: null,
        prevMenuTitleId: null,
      }
    }

    return {
      nextMenuTitleId:
        menuTitleIdsForNavigation[
          currentIndex === _size(menuTitleIdsForNavigation) - 1
            ? null
            : currentIndex + 1
        ],
      prevMenuTitleId:
        menuTitleIdsForNavigation[currentIndex === 0 ? null : currentIndex - 1],
    }
  }, [menuTitleIdsForNavigation, menuTitleId])

  const setDishMenuTitleFilter = useCallback(
    value => {
      googleAnalyticsEventsService.fireEvent(
        `${EVENT_TYPES.BRAND_DISH_EVENTS.FILTER_CHANGE}_menu_title`,
      )

      setQueryParams({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        menuTitleId: value,
      })
    },
    [setQueryParams],
  )

  const navigateMenuTitles = useCallback(
    isNext => {
      if (isNext && !isNil(nextMenuTitleId)) {
        return setDishMenuTitleFilter(nextMenuTitleId)
      }

      if (!isNext && !isNil(prevMenuTitleId)) {
        return setDishMenuTitleFilter(prevMenuTitleId)
      }

      if (!isNil(menuTitleIdsForNavigation[0])) {
        return setDishMenuTitleFilter(menuTitleIdsForNavigation[0])
      }

      return null
    },
    [
      menuTitleIdsForNavigation,
      nextMenuTitleId,
      prevMenuTitleId,
      setDishMenuTitleFilter,
    ],
  )

  const navigateForward = useCallback(
    () => navigateMenuTitles(true),
    [navigateMenuTitles],
  )

  const navigateBackward = useCallback(
    () => navigateMenuTitles(false),
    [navigateMenuTitles],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.ALT_RIGHT.hotkey,
    e => {
      e.preventDefault()
      navigateMenuTitles(true)
    },
    [navigateMenuTitles],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.ALT_LEFT.hotkey,
    e => {
      e.preventDefault()
      navigateMenuTitles(false)
    },
    [navigateMenuTitles],
  )

  return (
    <Row justify="space-between">
      <Col flex="0">
        <Button
          icon={<LeftOutlined />}
          disabled={isNil(prevMenuTitleId)}
          onClick={navigateBackward}
          size={size}
        />
      </Col>
      <Col flex="1">
        <TreeSelect
          dropdownMatchSelectWidth={false}
          disabled={brandIsDirty}
          style={styles.treeSelect}
          treeDefaultExpandedKeys={treeDefaultExpandedKeys}
          treeData={menuTitleOptions}
          value={menuTitleId && toString(menuTitleId)}
          onChange={setDishMenuTitleFilter}
          showSearch
          allowClear
          placeholder="Filter by Menu Titles..."
          treeNodeFilterProp="title"
          filterTreeNode={handleFilterTreeSelectNode}
          dropdownRender={handleTreeSelectCustomDropdownForSearch}
          size={size}
        />
      </Col>
      <Col flex="0">
        <Button
          icon={<RightOutlined />}
          disabled={isNil(nextMenuTitleId)}
          onClick={navigateForward}
          size={size}
        />
      </Col>
    </Row>
  )
}

DishMenuTitleFilter.propTypes = {
  brandIsDirty: PropTypes.bool,
  size: PropTypes.string,
  dishesWithNoMenuTitleSummary: PropTypes.object,
}

DishMenuTitleFilter.defaultProps = {
  brandIsDirty: false,
  size: undefined,
  dishesWithNoMenuTitleSummary: {},
}

export default memo(DishMenuTitleFilter)
