import {
  filter,
  get,
  includes,
  isEmpty,
  isNil,
  keys,
  map,
  maxBy,
  omit,
  reduce,
  some,
} from 'lodash'

import { isStringTrue } from '../../core/utils'

import {
  CURATION_CERTAINTY_TRESHOLD,
  CURATION_STATUS,
  PERMISSIONS,
} from '../../common/constants'

import {
  DISH_ACTIONS,
  DISH_AI_CURATION_FIELDS,
  DISH_FIELD_QA_CORRECTION_PATH,
  DISH_FIELD_SUGGESTION_PATH,
  DISH_FIELDS,
  DISH_MENU_TITLE_ADDON_DESCRIPTORS,
  DISH_MENU_TITLE_ALLERGEN_DESCRIPTORS,
  DISH_MENU_TITLE_DESCRIPTION,
  DISH_MENU_TITLE_DIET_DESCRIPTORS,
  INGREDIENTS,
} from '../constants'

const dishFields = [
  {
    id: DISH_FIELDS.NAME,
    title: 'Name',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
    fixed: 'left',
    width: '230px',
  },
  {
    id: DISH_FIELDS.STATUS,
    title: 'Status',
    width: '100px',
  },
  {
    id: DISH_FIELDS.CERTAINTY,
    title: 'Certainty',
    width: '100px',
  },
  {
    id: DISH_FIELDS.PRICE,
    title: 'Price',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
    width: '80px',
  },
  {
    id: DISH_FIELDS.SERVICING_HOURS,
    title: 'Servicing time',
    onlyInModal: true,
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
    width: 150,
  },
  {
    id: DISH_FIELDS.MENU_TITLE,
    title: 'Menu title',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
    width: '150px',
  },
  {
    id: DISH_FIELDS.DIETS,
    title: 'Diets',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '120px',
  },
  {
    id: DISH_FIELDS.DIET_DESCRIPTORS,
    title: 'Diet descriptors',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '170px',
  },
  {
    id: DISH_FIELDS.DISH_TYPE,
    title: 'Dish type',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '120px',
  },
  {
    id: DISH_FIELDS.COURSE_TYPE,
    title: 'Course type',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '120px',
  },
  {
    id: DISH_MENU_TITLE_DESCRIPTION,
    title: 'Menu title descriptions',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '250px',
  },
  {
    id: DISH_FIELDS.DESCRIPTION,
    title: 'Description',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
    width: '300px',
  },
  {
    id: DISH_FIELDS.INGREDIENTS_TEXT,
    title: 'Ingredients free text',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
    width: '250px',
  },
  {
    id: INGREDIENTS,
    title: 'Main and additional ingredients',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '300px',
  },
  {
    id: DISH_FIELDS.MAIN_INGREDIENTS,
    title: 'Main ingredients',
    onlyInModal: true,
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
  },
  {
    id: DISH_FIELDS.ADDITIONAL_INGREDIENTS,
    title: 'Additional ingredients',
    onlyInModal: true,
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
  },
  {
    id: DISH_FIELDS.CHOICE_INGREDIENTS,
    title: 'Choice ingredients',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '300px',
  },
  {
    id: DISH_MENU_TITLE_ADDON_DESCRIPTORS,
    title: 'Menu title addon descriptors',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '160px',
  },
  {
    id: DISH_FIELDS.ADDON_DESCRIPTORS,
    title: 'Addon descriptors',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '160px',
  },
  {
    id: DISH_FIELDS.SELECTED_LOCATIONS,
    onlyInModal: true,
    title: 'Location availability',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_DATA_ENTRY],
  },
  {
    id: DISH_FIELDS.ALLERGENS,
    title: 'Allergens',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '110px',
  },
  {
    id: DISH_FIELDS.ALLERGEN_DESCRIPTORS,
    title: 'Allergen descriptors',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '200px',
  },
  {
    id: DISH_MENU_TITLE_ALLERGEN_DESCRIPTORS,
    title: 'Menu title Allergen descriptors',
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '200px',
  },
  {
    id: DISH_ACTIONS,
    requiredPermissions: [PERMISSIONS.DISHES.UPSERT_CURATION],
    width: '120px',
  },
]

const dishFieldsForMiscAndChoice = [
  DISH_FIELDS.NAME,
  DISH_FIELDS.MENU_TITLE,
  DISH_FIELDS.DESCRIPTION,
  DISH_FIELDS.INGREDIENTS_TEXT,
]
const hideableFields = [
  DISH_FIELDS.INGREDIENTS_TEXT,
  DISH_FIELDS.SERVICING_HOURS,
  DISH_FIELDS.DIET_DESCRIPTORS,
  DISH_FIELDS.ADDON_DESCRIPTORS,
  DISH_FIELDS.ALLERGEN_DESCRIPTORS,
]
const hideableDishMenuTitleFields = [
  DISH_MENU_TITLE_DESCRIPTION,
  DISH_MENU_TITLE_ADDON_DESCRIPTORS,
  DISH_MENU_TITLE_ALLERGEN_DESCRIPTORS,
  DISH_MENU_TITLE_DIET_DESCRIPTORS,
]

export const getDishFieldsBasedOnUserPermission = (
  userPermissions,
  brandStatus,
  dishIsStatusSameAsBrandFilter,
  dishHasErrorsFilter,
  brandDishes,
) => {
  const containsHideableFields = reduce(
    hideableFields,
    (acc, field) => ({
      ...acc,
      [field]: some(brandDishes, brandDish => !isEmpty(brandDish[field])),
    }),
    {},
  )
  const containsHideableDishMenuTitleFields = reduce(
    hideableDishMenuTitleFields,
    (acc, field) => ({
      ...acc,
      [field]: some(brandDishes, brandDish =>
        some(
          brandDish.menuTitleParents,
          menuTitle => !isEmpty(menuTitle[field]),
        ),
      ),
    }),
    {},
  )
  return map(
    filter(dishFields, field => {
      if (
        field.id === DISH_ACTIONS &&
        includes(
          [
            CURATION_STATUS.MISC_AND_CHOICE.value,
            CURATION_STATUS.CURATION.value,
          ],
          brandStatus,
        )
      ) {
        return false
      }

      if (
        (includes(hideableFields, field.id) &&
          !containsHideableFields[field.id]) ||
        (includes(hideableDishMenuTitleFields, field.id) &&
          !containsHideableDishMenuTitleFields[field.id])
      ) {
        return false
      }

      if (
        field.id === DISH_FIELDS.STATUS &&
        isStringTrue(dishIsStatusSameAsBrandFilter)
      ) {
        return false
      }

      if (
        isStringTrue(dishIsStatusSameAsBrandFilter) &&
        !isStringTrue(dishHasErrorsFilter) &&
        includes(
          [
            CURATION_STATUS.MISC_AND_CHOICE.value,
            CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
          ],
          brandStatus,
        )
      ) {
        if (
          some(dishFieldsForMiscAndChoice, dishField => dishField === field.id)
        ) {
          return true
        }

        return false
      }

      return true
    }),
    ({ requiredPermissions, ...columns }) => ({
      ...columns,
      isEditable: some(requiredPermissions, requiredPermission =>
        includes(userPermissions, requiredPermission),
      ),
    }),
  )
}

export const computeDishCellHighlight = (dish, field, brandStatus) => {
  if (
    includes(
      [
        CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
        CURATION_STATUS.CURATION_CONFIRMATION.value,
        CURATION_STATUS.QA.value,
        CURATION_STATUS.QQA_CONFIRMATION.value,
      ],
      brandStatus,
    ) &&
    includes(
      [
        DISH_FIELDS.NAME,
        DISH_FIELDS.DESCRIPTION,
        DISH_FIELDS.INGREDIENTS_TEXT,
        'id',
      ],
      field,
    )
  ) {
    const aiSuggestion = get(dish, DISH_FIELD_SUGGESTION_PATH[field])
    const messages = get(
      dish,
      `${DISH_FIELD_SUGGESTION_PATH.variousText}.messages`,
    )

    if (!isEmpty(messages)) {
      return 'cell-error'
    }

    if (includes(dish.fieldsUsedForUnknownWordsHighlights, field)) {
      return 'cell-low-certainty'
    }

    if (isNil(aiSuggestion)) {
      return null
    }

    if (
      !isNil(aiSuggestion.score) &&
      Math.trunc(aiSuggestion.score * 100) <= CURATION_CERTAINTY_TRESHOLD
    ) {
      return 'cell-low-certainty'
    }

    return null
  }

  if (brandStatus === CURATION_STATUS.CURATION_CONFIRMATION.value) {
    const hasMessages = some(
      keys(
        omit(DISH_FIELD_SUGGESTION_PATH, [
          'variousText',
          DISH_FIELDS.NAME,
          DISH_FIELDS.DESCRIPTION,
          DISH_FIELDS.INGREDIENTS_TEXT,
        ]),
      ),
      // we need to test against null as well and has only checks if the field is undefined
      fieldKey =>
        !isEmpty(
          get(dish, `${DISH_FIELD_SUGGESTION_PATH[fieldKey]}.messages`),
        ) ||
        !isEmpty(
          get(dish, `${DISH_FIELD_SUGGESTION_PATH[fieldKey]}[0].messages`),
        ),
    )
    if (hasMessages) {
      return 'cell-error'
    }
  }

  if (
    includes(
      [
        CURATION_STATUS.QA.value,
        CURATION_STATUS.QQA_CONFIRMATION.value,
        CURATION_STATUS.CURATION_CONFIRMATION.value,
      ],
      brandStatus,
    )
  ) {
    if (includes(DISH_AI_CURATION_FIELDS, field)) {
      const aiSuggestion = get(dish, DISH_FIELD_SUGGESTION_PATH[field])

      if (
        isEmpty(aiSuggestion) &&
        includes([DISH_FIELDS.DISH_TYPE, DISH_FIELDS.COURSE_TYPE], field)
      ) {
        return 'cell-low-certainty'
      }

      const { certainty } = !isNil(get(aiSuggestion, 'certainty'))
        ? aiSuggestion
        : maxBy(aiSuggestion, 'score') || {}

      if (isNil(certainty)) {
        return null
      }

      // TODO certainty is at present a value between 0 and 1 while the score is between 0 and 100. we should settle upon a way to use these types of values
      if (Math.trunc(certainty * 100) <= CURATION_CERTAINTY_TRESHOLD) {
        return 'cell-low-certainty'
      }
    }
  }

  if (brandStatus === CURATION_STATUS.DONE.value) {
    let correctionCount
    if (field === INGREDIENTS) {
      correctionCount =
        get(dish, 'qaCorrectionCounts.mainIngredientIds') ||
        get(dish, 'qaCorrectionCounts.additionalIngredientIds')
    } else {
      correctionCount = get(dish, DISH_FIELD_QA_CORRECTION_PATH[field])
    }

    if (!isNil(correctionCount) && correctionCount > 0) {
      return 'cell-qa-correction'
    }
  }

  return null
}
