import React, { memo, useCallback, useContext, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Tooltip } from 'antd'
import {
  every,
  filter,
  get,
  includes,
  isEmpty,
  isNil,
  isNull,
  map,
  some,
  toLower,
} from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import {
  CURATION_STATUS,
  EVENT_TYPES,
  ORIGIN_PLACES,
  PERMISSIONS,
} from '../../../../common/constants'
import { LoadingContext } from '../../../../common/contexts'
import {
  useAuthentication,
  useCachedBrand,
  useCurationMetadata,
} from '../../../../common/hooks'
import { removeUnsavedAiSuggestionsForOwner } from '../../../../common/redux/actions'
import { googleAnalyticsEventsService } from '../../../../common/services'

import {
  useBrandDish,
  useSaveBrandDish,
  useUnsavedBrandDish,
} from '../../../hooks'
import { removeUnsavedDish } from '../../../redux'
import {
  AddDishButton,
  DeleteDishButton,
  DishAiSuggestionsActionsButton,
  PublishCheckbox,
} from '../../molecules'
import { DishModalErrors } from '../../molecules/DishModalErrors'

import './dishModalHeader.css'

const dishModalStyles = {
  aiActions: { marginLeft: 15 },
  rightActionsWrapper: { marginLeft: 'auto' },
  rightAction: { marginLeft: 10 },
  addButton: {
    margin: 0,
    width: '85px',
  },
  publishCheckbox: {
    margin: 'auto',
    marginLeft: 30,
    marginRight: 0,
  },
  wrapper: {
    display: 'flex',
  },
  addButtonContainer: {
    display: 'inline-block',
  },
}

const DishModalHeader = ({
  refetchDish,
  refetchBrand,
  disabled,
  aiSuggestionMessages,
}) => {
  const { loadingCount } = useContext(LoadingContext)
  const dispatch = useDispatch()
  const { brandId } = useParams()
  const { selectedDishId: dishId, setQueryParams } = useQueryParams()
  const { dish } = useBrandDish(dishId)
  const { published, name: originalName } = dish
  const unsavedDish = useUnsavedBrandDish(dishId)
  const { dietOptions } = useCurationMetadata()
  const brand = useCachedBrand()
  const {
    userInfo: { permissions },
  } = useAuthentication()

  const { diets: brandDiets, status: brandStatus } = brand || {}

  const { save, isLoading: isSaveLoading } = useSaveBrandDish({
    brandId,
    dishId,
    originalDish: dish,
  })

  const shouldShowButtonBasedOnPermission = useMemo(
    () => includes(permissions, PERMISSIONS.DISHES.UPSERT_CURATION),
    [permissions],
  )

  const {
    isSaveDisabled,
    isAddButtonDisabled,
    message,
    isSuggestDisabled,
    suggestDisabledMessage,
  } = useMemo(() => {
    if (disabled) {
      return {
        isSaveDisabled: true,
        isAddButtonDisabled: true,
        message: 'Brand has changes',
        isSuggestDisabled: true,
        suggestDisabledMessage: 'Brand has changes',
      }
    }

    if (isSaveLoading) {
      return {
        isSaveDisabled: true,
        isAddButtonDisabled: true,
        isSuggestDisabled: true,
      }
    }

    if (isEmpty(unsavedDish) || loadingCount > 0) {
      return {
        isSaveDisabled: true,
        isAddButtonDisabled: false,
        message: 'App loading or no changes detected',
      }
    }

    if (unsavedDish.dietIds) {
      const dietNames = map(
        filter(dietOptions, ({ id }) => includes(unsavedDish.dietIds, id)),
        ({ name }) => toLower(name),
      )

      const restrictedCombinations = ['vegan', 'vegetarian']
      const restrictSave = every(
        restrictedCombinations,
        restrictedDietCombinationPart =>
          includes(dietNames, restrictedDietCombinationPart),
      )

      if (restrictSave) {
        return {
          isSaveDisabled: restrictSave,
          isAddButtonDisabled: true,
          message: "Can't save dish that have both vegan and vegetarian diet",
          isSuggestDisabled: true,
          suggestDisabledMessage:
            "Can't save suggestions for a dish that has both vegan and vegetarian diet",
        }
      }
    }

    if (!isNull(unsavedDish.lowPrice) || !isNull(unsavedDish.highPrice)) {
      if (
        (isNil(unsavedDish.lowPrice ?? dish.lowPrice) ||
          isNull(unsavedDish.lowPrice)) &&
        !isNil(unsavedDish.highPrice ?? dish.highPrice)
      ) {
        return {
          isSaveDisabled: true,
          isAddButtonDisabled: true,
          message:
            "Can't save dish if you only provided a high price without a low price",
        }
      }

      if (
        (!isNil(unsavedDish.highPrice ?? dish.highPrice) &&
          unsavedDish.lowPrice &&
          unsavedDish.lowPrice >
            (isNull(unsavedDish.highPrice)
              ? Number.MAX_SAFE_INTEGER
              : unsavedDish.highPrice ?? dish.highPrice)) ||
        (unsavedDish.highPrice &&
          unsavedDish.highPrice <
            (isNull(unsavedDish.lowPrice)
              ? 0
              : unsavedDish.lowPrice ?? dish.lowPrice))
      ) {
        return {
          isSaveDisabled: true,
          isAddButtonDisabled: true,
          message: "Can't save dish if low price is bigger than high price",
          isSuggestDisabled: true,
          suggestDisabledMessage:
            "Can't save dish if low price is bigger than high price",
        }
      }
    }

    if (
      some(
        get(unsavedDish, 'selectedLocations'),
        ({ overridenLowPrice, overridenHighPrice }, index) =>
          (Number(overridenHighPrice) &&
            isNil(unsavedDish.lowPrice ?? dish.lowPrice)) ||
          (Number(overridenLowPrice) &&
            Number(overridenLowPrice) >
              (isNull(overridenHighPrice)
                ? Number.MAX_SAFE_INTEGER
                : Number(overridenHighPrice) ||
                  Number(
                    get(dish, `selectedLocations[${index}].overridenHighPrice`),
                  ) ||
                  Number.MAX_SAFE_INTEGER)) ||
          (Number(overridenHighPrice) &&
            Number(overridenHighPrice) <
              (Number(overridenLowPrice) ||
                get(dish, `selectedLocations[${index}].overridenLowPrice`))),
      )
    ) {
      return {
        isSaveDisabled: true,
        isAddButtonDisabled: true,
        message:
          "Can't save dish location overrides if low price is bigger than high price",
        isSuggestDisabled: true,
        suggestDisabledMessage:
          "Can't save dish location overrides if low price is bigger than high price",
      }
    }

    if (unsavedDish.name === undefined) {
      if (originalName) {
        return {
          isSaveDisabled: false,
          isSuggestDisabled: true,
          suggestDisabledMessage:
            "Can't get suggestions for a dish with no name.",
        }
      }

      return {
        isSaveDisabled: true,
        isAddButtonDisabled: true,
        message: "Can't save dish if no name was provided",
        isSuggestDisabled: true,
        suggestDisabledMessage: "Can't save dish if no name was provided",
      }
    }

    if (unsavedDish.name === '') {
      return {
        isSaveDisabled: true,
        isAddButtonDisabled: true,
        message: "Can't save dish if no name was provided",
        isSuggestDisabled: true,
        suggestDisabledMessage: "Can't save dish if no name was provided",
      }
    }

    return {}
  }, [
    disabled,
    isSaveLoading,
    unsavedDish,
    loadingCount,
    dietOptions,
    dish,
    originalName,
  ])

  const manualSave = useCallback(
    eventType => {
      if (!isEmpty(unsavedDish)) {
        googleAnalyticsEventsService.fireEvent(eventType)

        save({
          modifiedDish: unsavedDish,
          callback: upsertedDish => {
            if (!isEmpty(dish)) {
              dispatch(
                removeUnsavedAiSuggestionsForOwner({
                  ownerId: dishId,
                  type: 'dishes',
                }),
              )
            } else {
              setQueryParams({ selectedDishId: upsertedDish.id })
            }

            if (
              !isNil(unsavedDish, 'menuTitleId') ||
              !isNil(unsavedDish, 'dishTypeId') ||
              !isNil(unsavedDish, 'courseTypeId')
            ) {
              refetchBrand()
            }

            dispatch(removeUnsavedDish(dishId))
          },
        })
      }
    },
    [dish, dishId, dispatch, refetchBrand, save, setQueryParams, unsavedDish],
  )

  const saveClick = useCallback(async () => {
    manualSave(EVENT_TYPES.BRAND_DISH_EVENTS.SAVE_CLICK)
  }, [manualSave])

  useHotkeys(
    'command+shift+s, ctrl+shift+s',
    () => {
      manualSave(EVENT_TYPES.BRAND_DISH_EVENTS.SAVE_HOTKEYS)
      return false
    },
    {},
    [manualSave],
  )

  return (
    <div style={dishModalStyles.wrapper}>
      {shouldShowButtonBasedOnPermission && (
        <div style={dishModalStyles.aiActions}>
          <DishAiSuggestionsActionsButton
            brandStatus={brandStatus}
            refetchBrand={refetchBrand}
            isSuggestDisabled={isSuggestDisabled}
            suggestDisabledMessage={suggestDisabledMessage}
          />
        </div>
      )}
      {!isEmpty(aiSuggestionMessages) && (
        <DishModalErrors errors={aiSuggestionMessages} />
      )}
      <div style={dishModalStyles.rightActionsWrapper}>
        {shouldShowButtonBasedOnPermission && (
          <PublishCheckbox
            dish={dish}
            disabled={dish?.status !== CURATION_STATUS.DONE.value || disabled}
            refetchDish={refetchDish}
            refetchBrand={refetchBrand}
            unsaved={!isEmpty(unsavedDish)}
            checked={published}
            style={{ ...dishModalStyles.publishCheckbox }}
            track={() =>
              googleAnalyticsEventsService.fireEvent(
                EVENT_TYPES.BRAND_DISH_EVENTS.PUBLISH_CLICK,
                {
                  from: ORIGIN_PLACES.DISH_MODAL,
                },
              )
            }
          >
            Published
          </PublishCheckbox>
        )}

        <Tooltip
          title={isSaveDisabled ? message : undefined}
          placement="bottom"
        >
          <Button
            size="small"
            type="primary"
            loading={loadingCount > 0}
            disabled={isSaveDisabled}
            onClick={saveClick}
            style={dishModalStyles.rightAction}
            placement="bottom"
          >
            Save
          </Button>
        </Tooltip>
        <DeleteDishButton
          size="small"
          disabled={disabled}
          brandId={brandId}
          name={originalName}
          style={dishModalStyles.rightAction}
          refetchBrand={refetchBrand}
        />

        <Tooltip
          title={isAddButtonDisabled ? message : undefined}
          placement="bottom"
        >
          <div style={dishModalStyles.addButtonContainer}>
            <AddDishButton
              size="small"
              disabled={isAddButtonDisabled}
              style={{
                ...dishModalStyles.addButton,
                ...dishModalStyles.rightAction,
              }}
              brandId={brandId}
              dietIds={map(brandDiets, 'id')}
              track={() =>
                googleAnalyticsEventsService.fireEvent(
                  EVENT_TYPES.BRAND_DISH_EVENTS.ADD_CLICK,
                  {
                    from: ORIGIN_PLACES.DISH_MODAL,
                  },
                )
              }
            />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

DishModalHeader.propTypes = {
  refetchDish: PropTypes.func,
  refetchBrand: PropTypes.func,
  disabled: PropTypes.bool,
  aiSuggestionMessages: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.oneOf(['Error', 'Warning']).isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
}

DishModalHeader.defaultProps = {
  refetchDish: undefined,
  refetchBrand: undefined,
  disabled: false,
  aiSuggestionMessages: [],
}

export default memo(DishModalHeader)
