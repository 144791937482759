import { useContext } from 'react'

import { DishTableFocusActionsContext } from '../contexts'

const useEditableCellHotkeyEdit = (dishId, fieldName) => {
  const {
    selectedRowKey,
    selectedCellKey,
    hotkeyEditMode,
    setHotkeyEditMode,
    isPopoverModeEnabled,
    setIsPopoverModeEnabled,
  } = useContext(DishTableFocusActionsContext)

  return {
    forceEditState:
      dishId === selectedRowKey &&
      selectedCellKey === fieldName &&
      hotkeyEditMode,
    isFocusOnCell: dishId === selectedRowKey && selectedCellKey === fieldName,
    isFocusOnRow: dishId === selectedRowKey,
    setHotkeyEditMode,
    isPopoverModeEnabled,
    setIsPopoverModeEnabled,
  }
}

export default useEditableCellHotkeyEdit
