import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import PropTypes from 'prop-types'

import { JsonFileUploadButton } from '../../../../common/components'
import { handleMutationError } from '../../../../common/helpers'

import { IMPORT_ML_CLEANUP_TASK_MUTATION } from '../../../graphql'
import { mlCleanupTaskTransformer } from '../../../transformers'

const MlCleanupTaskImportButton = ({ disabled }) => {
  const [importCleanupTask, { loading }] = useMutation(
    IMPORT_ML_CLEANUP_TASK_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: async ({ importMlCleanupTask: result }) => {
        notification.success({
          message: 'Task created',
          description: `Ml cleanup task ${result.name} successfully created`,
          placement: 'topLeft',
        })
      },
    },
  )

  const handleUpload = useCallback(
    async jsonFileContents => {
      await importCleanupTask({
        variables: {
          input:
            mlCleanupTaskTransformer.transformTaskJsonToDto(jsonFileContents),
        },
      })
    },
    [importCleanupTask],
  )

  return (
    <JsonFileUploadButton
      disabled={disabled}
      loading={loading}
      text="Import cleanup task from file"
      onUpload={handleUpload}
    />
  )
}

MlCleanupTaskImportButton.propTypes = {
  disabled: PropTypes.bool,
}

MlCleanupTaskImportButton.defaultProps = {
  disabled: false,
}

export default MlCleanupTaskImportButton
