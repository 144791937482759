import { gql } from '@apollo/client'

import { SIMPLE_USER_FRAGMENT } from '../../../common/graphql'

const ASSIGN_USER_TO_BRAND_MUTATION = gql`
  mutation assignUserToBrandMutation(
    $id: ID!
    $userId: ID
    $action: BrandAssignedUserActionEnumType!
  ) {
    assignUserToBrand(id: $id, userId: $userId, action: $action) {
      simpleBrand {
        id

        assignedCurator {
          ...SimpleUserFragment
        }

        assignedQa {
          ...SimpleUserFragment
        }

        assignedQQa {
          ...SimpleUserFragment
        }

        assignedUnknownWordsCurator {
          ...SimpleUserFragment
        }

        assignedUnknownWordsQa {
          ...SimpleUserFragment
        }
      }
      brand {
        id

        assignedCurator {
          ...SimpleUserFragment
        }

        assignedQa {
          ...SimpleUserFragment
        }

        assignedQQa {
          ...SimpleUserFragment
        }

        assignedUnknownWordsCurator {
          ...SimpleUserFragment
        }

        assignedUnknownWordsQa {
          ...SimpleUserFragment
        }
      }
    }
  }
  ${SIMPLE_USER_FRAGMENT}
`

export default ASSIGN_USER_TO_BRAND_MUTATION
