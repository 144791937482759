import { join } from 'path'

import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { Button, notification } from 'antd'
import { isNil } from 'lodash'

import { useQueryParams } from '../../core/hooks'
import { isStringTrue } from '../../core/utils'

import {
  BRAND_QUERY,
  MENU_IMPORTED_SUBSCRIPTION,
  SIMPLE_BRAND_BY_ID_QUERY,
} from '../../brands/graphql'
import { DEFAULT_DISH_LIST_FILTERS } from '../../dishes/constants'
import { BRAND_DISHES_QUERY } from '../../dishes/graphql'
import { APP_ROUTES, DEFAULT_PAGINATION_CONFIG, JOB_STATUS } from '../constants'
import { handleMutationError } from '../helpers'

import useAuthentication from './useAuthentication'
import useCachedBrand from './useCachedBrand'

const useMenuImportSubscription = () => {
  const history = useHistory()
  const { brandId } = useParams()
  const brand = useCachedBrand()
  const { data: importData } = useSubscription(MENU_IMPORTED_SUBSCRIPTION)

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    sortDishesBy = DEFAULT_DISH_LIST_FILTERS.SORT_DISHES_BY,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
  } = useQueryParams()

  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? brand?.status : null),
    [brand?.status, dishIsStatusSameAsBrandFilter],
  )

  const options = useMemo(
    () => ({
      variables: { id: brandId },
      onError: handleMutationError,
      fetchPolicy: 'network-only',
    }),
    [brandId],
  )

  const dishFetchOptions = useMemo(
    () => ({
      variables: {
        brandId,
        paginationFiltersAndSorters: {
          pageSize: Number(pageSize),
          currentPage: Number(currentPage),
          filters: {
            status: statusForDishFiltering,
            servicingHours: servicingHoursFilter,
            published: dishPublishedFilter,
            ignored: dishIgnoredFilter,
            hasErrors: isStringTrue(dishHasErrorsFilter),
            menuTitle: menuTitleId,
            search,
          },
          sortDishesBy,
        },
      },
      onError: handleMutationError,
      fetchPolicy: 'network-only',
    }),
    [
      brandId,
      currentPage,
      dishHasErrorsFilter,
      dishIgnoredFilter,
      dishPublishedFilter,
      menuTitleId,
      pageSize,
      search,
      servicingHoursFilter,
      sortDishesBy,
      statusForDishFiltering,
    ],
  )

  const [fetchBrand] = useLazyQuery(BRAND_QUERY, options)
  const [fetchSimpleBrand] = useLazyQuery(SIMPLE_BRAND_BY_ID_QUERY, options)
  const [fetchBrandDishes] = useLazyQuery(BRAND_DISHES_QUERY, dishFetchOptions)

  useEffect(() => {
    if (isNil(importData?.menuImported)) {
      return
    }

    if (isNil(importData?.menuImported?.brandId)) {
      notification.error({
        message: 'Import menu Error',
        description: `No brandId provided`,
        placement: 'topLeft',
      })
    }

    if (importData?.menuImported?.jobStatus === JOB_STATUS.FAILED) {
      notification.error({
        message: 'Import menu Error',
        description: `Menu ${importData.menuImported.menuUrl} could not be imported on brand ${importData.menuImported.brandName}.
            ${importData.menuImported.reason}`,
        placement: 'topLeft',
      })
    }

    if (
      brandId !== importData.menuImported.brandId &&
      importData?.menuImported?.jobStatus === JOB_STATUS.SUCCESS
    ) {
      const notificationKey = `open${Date.now()}`

      return notification.success({
        key: notificationKey,
        message: 'Import menu Finished',
        description: `Menu ${importData.menuImported.menuUrl} imported successfully on brand ${importData.menuImported.brandName}`,
        duration: 0,
        btn: (
          <Button
            onClick={() => {
              history.push(
                join(
                  APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
                  importData.menuImported.brandId,
                ),
              )
              notification.close(notificationKey)
            }}
          >
            See the brand
          </Button>
        ),
        placement: 'topLeft',
      })
    }

    if (!isNil(brandId)) {
      fetchBrandDishes()
      return fetchBrand()
    }

    return fetchSimpleBrand({
      variables: {
        id: importData.menuImported.brandId,
      },
    })
  }, [
    brandId,
    history,
    importData,
    fetchBrand,
    fetchSimpleBrand,
    fetchBrandDishes,
  ])
}

export default useMenuImportSubscription
