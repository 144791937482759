import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Col, Row, Typography } from 'antd'

import { GridFormItem, Spinner } from '../../../../core/components'
import { useDocumentTitle, useQueryParams } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import { MutationCheckbox } from '../../../../common/components/molecules'
import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'

import { CLEANUP_TASK_TYPES, DEFAULT_CLEANUP_FILTERS } from '../../../constants'
import {
  CLEANUP_TASK_BATCH_QUERY,
  ML_CLEANUPS_QUERY,
  UPDATE_CLEANUP_TASK_CURATION_DONE_MUTATION,
  UPDATE_CLEANUP_TASK_QA_DONE_MUTATION,
} from '../../../graphql'
import { useUserHasCleanupTaskChangePermission } from '../../../hooks'
import { CleanupTableFocusActionsProvider } from '../../../providers'
import { mlCleanupTransformer } from '../../../transformers'
import { CleanupTaskHotkeysLegend } from '../../molecules'
import {
  CleanupTaskFilters,
  CleanupTaskPagination,
  MlCleanupTaskTable,
} from '../../sections'

const styles = {
  title: {
    marginTop: 10,
  },
  subtitle: {
    color: '#434343',
  },
  pageWrapper: { padding: '1rem', paddingBottom: 0 },
  hotkeysLegendContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '1rem',
    justifyContent: 'flex-end',
  },
}

const MlCleanupTask = () => {
  useDocumentTitle('ML cleanup tasks')
  const { id, metadataType } = useParams()
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    status = DEFAULT_CLEANUP_FILTERS.STATUS,
    search = DEFAULT_CLEANUP_FILTERS.SEARCH,
  } = useQueryParams()

  const {
    error: batchError,
    loading: batchLoading,
    data: { cleanupTaskBatch } = {},
  } = useQuery(CLEANUP_TASK_BATCH_QUERY, {
    variables: {
      id,
      metadataType,
    },
  })

  const { isCurationDone, isQaDone, group } = cleanupTaskBatch || {}
  const {
    userHasTaskChangePermission,
    isUserCuratorOfTask,
    isUserQaOfTask,
    userCanEditAnyTask,
  } = useUserHasCleanupTaskChangePermission(cleanupTaskBatch)

  const checkboxAdditionalInfo = useMemo(
    () => ({
      id,
      metadataType,
    }),
    [id, metadataType],
  )

  const isQaDoneCheckboxDisabled = useMemo(
    () => !(userCanEditAnyTask || isUserQaOfTask) || !isCurationDone,
    [isCurationDone, isUserQaOfTask, userCanEditAnyTask],
  )
  const isCurationDoneCheckboxDisabled = useMemo(
    () => !(userCanEditAnyTask || isUserCuratorOfTask) || isQaDone,
    [isQaDone, isUserCuratorOfTask, userCanEditAnyTask],
  )

  const {
    error,
    loading,
    data: { mlCleanups } = {},
  } = useQuery(ML_CLEANUPS_QUERY, {
    variables: {
      taskId: id,
      currentPage: Number(page),
      metadataType,
      pageSize: Number(pageSize),
      filters: {
        status,
        search,
      },
    },
  })
  const { count } = mlCleanups || {}
  const cleanups = useMemo(
    () => mlCleanupTransformer.transformToMlCleanups(mlCleanups?.cleanups),
    [mlCleanups],
  )

  if (!cleanupTaskBatch || batchLoading) {
    return <Spinner size="large" />
  }

  if (error || batchError) {
    return <Typography.Paragraph>Failed to get dishes</Typography.Paragraph>
  }

  return (
    <PageWithHeader>
      <CleanupTableFocusActionsProvider
        type={CLEANUP_TASK_TYPES.ML}
        taskId={id}
        cleanups={cleanups}
        cleanupsLoading={loading}
        metadataType={metadataType}
        disabled={!useUserHasCleanupTaskChangePermission}
        tableSelector=".ml-cleanup-table .ant-table-body table"
        rowsSelector=".ml-cleanup-table .ant-table-body table tbody > tr:not(.ant-table-measure-row)"
        scrollContainerSelector=".ml-cleanup-table .ant-table-body"
        isCurationDone={isCurationDone}
        isQaDone={isQaDone}
      >
        <div style={styles.pageWrapper}>
          <Row>
            <Col span={24}>
              <Typography.Text style={styles.subtitle}>
                Category Clean up / {metadataType} ML / {cleanupTaskBatch.name}
              </Typography.Text>
              <Typography.Title level={3} style={styles.title}>
                {group.name}
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <CleanupTaskFilters />
            </Col>
            <Col span={16}>
              <Row>
                <GridFormItem span={4} label="Curation Done">
                  <MutationCheckbox
                    mutation={UPDATE_CLEANUP_TASK_CURATION_DONE_MUTATION}
                    checked={isCurationDone}
                    mutationAdditionalInfo={checkboxAdditionalInfo}
                    disabled={isCurationDoneCheckboxDisabled}
                  />
                </GridFormItem>
                <GridFormItem span={4} label="QA Done">
                  <MutationCheckbox
                    mutation={UPDATE_CLEANUP_TASK_QA_DONE_MUTATION}
                    checked={isQaDone}
                    mutationAdditionalInfo={checkboxAdditionalInfo}
                    disabled={isQaDoneCheckboxDisabled}
                  />
                </GridFormItem>
              </Row>
            </Col>
            <Col span={2} style={styles.hotkeysLegendContainer}>
              <CleanupTaskHotkeysLegend taskType={CLEANUP_TASK_TYPES.ML} />
            </Col>
          </Row>
        </div>
        <MlCleanupTaskTable
          data={cleanups}
          loading={loading}
          taskId={id}
          metadataType={metadataType}
          disabled={!userHasTaskChangePermission}
          isTaskCurationDone={isCurationDone}
          isTaskQaDone={isQaDone}
        />
        <CleanupTaskPagination
          metadataType={metadataType}
          total={count}
          isTaskCurationDone={isCurationDone}
          disabled={!userHasTaskChangePermission}
        />
      </CleanupTableFocusActionsProvider>
    </PageWithHeader>
  )
}

export default MlCleanupTask
