import { gql } from '@apollo/client'

import { USER_MANAGEMENT_FRAGMENT } from '../fragments'

const LOGGED_IN_USER_QUERY = gql`
  query loggedInUser {
    loggedInUser {
      ...UserManagementFragment
    }
  }
  ${USER_MANAGEMENT_FRAGMENT}
`

export default LOGGED_IN_USER_QUERY
