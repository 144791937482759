import { notification } from 'antd'
import { forEach, isNil, keys } from 'lodash'

import { suggestionEndpoints } from '../../common/constants'
import { getAiSuggestions } from '../../common/utils'

import { dishTranslateTransformer } from '../transformers'

export default async function translateDish(dish) {
  try {
    const [translations] = await getAiSuggestions(
      suggestionEndpoints.translateDish,
      [dishTranslateTransformer.transformDishToAiRequestDto(dish)],
    )

    const result = {}
    forEach(keys(translations), fieldName => {
      const originalValue = dish[fieldName]
      const translatedValue = translations[fieldName]

      if (isNil(originalValue)) {
        return
      }

      result[fieldName] =
        translatedValue !== originalValue ? translatedValue : ''
    })
    return result
  } catch (e) {
    notification.error({
      message: 'An error occured',
      description: e.message,
      placement: 'topLeft',
    })
    return {}
  }
}
