export const ID = 'id'
export const NAME = 'name'
export const DIETS = 'diets'
export const ALLERGENS = 'allergens'
export const STATUS = 'status'
export const CERTAINTY = 'certainty'
export const LOW_PRICE = 'lowPrice'
export const HIGH_PRICE = 'highPrice'
export const PRICE = 'price'
export const SERVICING_HOURS = 'servicingHours'
export const COURSE_TYPE = 'courseType'
export const MENU_TITLE = 'menuTitle'
export const DISH_TYPE = 'dishType'
export const DESCRIPTION = 'description'
export const MAIN_INGREDIENTS = 'mainIngredients'
export const EXCLUDED_INGREDIENTS = 'excludedIngredients'
export const ADDITIONAL_INGREDIENTS = 'additionalIngredients'
export const CHOICE_INGREDIENTS = 'choiceIngredients'
export const DIET_DESCRIPTORS = 'dietDescriptors'
export const ALLERGEN_DESCRIPTORS = 'allergenDescriptors'
export const ADDON_DESCRIPTORS = 'addonDescriptors'
export const INGREDIENTS = 'INGREDIENTS'
export const DISH_MENU_TITLE_DESCRIPTION = 'menuTitleDescription'
export const DISH_MENU_TITLE_ADDON_DESCRIPTORS = 'menuTitleAddonDescriptors'
export const DISH_MENU_TITLE_DIET_DESCRIPTORS = 'menuTitleDietDescriptors'
export const DISH_MENU_TITLE_ALLERGEN_DESCRIPTORS =
  'menuTitleAllergenDescriptors'
export const DISH_ACTIONS = 'actions'

// Needed for aiSuggestion highlight
export const INGREDIENTS_TEXT = 'ingredientsText'

export const SELECTED_LOCATIONS = 'selectedLocations'

export const DISH_FIELDS = Object.freeze({
  ID,
  NAME,
  DIETS,
  STATUS,
  CERTAINTY,
  ALLERGENS,
  LOW_PRICE,
  HIGH_PRICE,
  PRICE,
  INGREDIENTS_TEXT,
  SELECTED_LOCATIONS,
  SERVICING_HOURS,
  COURSE_TYPE,
  MENU_TITLE,
  DISH_TYPE,
  DESCRIPTION,
  MAIN_INGREDIENTS,
  EXCLUDED_INGREDIENTS,
  ADDITIONAL_INGREDIENTS,
  CHOICE_INGREDIENTS,
  DIET_DESCRIPTORS,
  ALLERGEN_DESCRIPTORS,
  ADDON_DESCRIPTORS,
})

export const DISH_INGREDIENT_FIELDS = Object.freeze([
  MAIN_INGREDIENTS,
  ADDITIONAL_INGREDIENTS,
  CHOICE_INGREDIENTS,
])

export const ADDITIONAL_INGREDIENT_TYPES = {
  MANUAL: 'MANUAL',
  DEFINITE: 'DEFINITE',
  PROBABLE: 'PROBABLE',
}

export const DISH_FIELD_SUGGESTION_PATH = Object.freeze({
  variousText: 'aiSuggestions.miscAndChoice',
  [DISH_FIELDS.NAME]: 'aiSuggestions.miscAndChoice.name',
  [DISH_FIELDS.DESCRIPTION]: 'aiSuggestions.miscAndChoice.description',
  [DISH_FIELDS.INGREDIENTS_TEXT]: 'aiSuggestions.miscAndChoice.ingredientsText',
  [DISH_FIELDS.DISH_TYPE]: 'aiSuggestions.dishType',
  [DISH_FIELDS.COURSE_TYPE]: 'aiSuggestions.courseType',
  [INGREDIENTS]: 'aiSuggestions.mainIngredients',
  [DISH_FIELDS.CHOICE_INGREDIENTS]: 'aiSuggestions.mainIngredients',
  [DISH_FIELDS.ALLERGENS]: 'aiSuggestions.allergens',
  [DISH_FIELDS.DIETS]: 'aiSuggestions.diets',
})

export const DISH_FIELD_QA_CORRECTION_PATH = Object.freeze({
  [DISH_FIELDS.NAME]: 'qaCorrectionCounts.styledName',
  [DISH_FIELDS.DESCRIPTION]: 'qaCorrectionCounts.styledDescription',
  [DISH_FIELDS.INGREDIENTS_TEXT]: 'qaCorrectionCounts.styledIngredientsText',
  [DISH_FIELDS.DISH_TYPE]: 'qaCorrectionCounts.dishTypeId',
  [DISH_FIELDS.COURSE_TYPE]: 'qaCorrectionCounts.courseTypeId',
  [DISH_FIELDS.CHOICE_INGREDIENTS]: 'qaCorrectionCounts.choiceIngredientIds',
  [DISH_FIELDS.ALLERGENS]: 'qaCorrectionCounts.allergenIds',
  [DISH_FIELDS.DIETS]: 'qaCorrectionCounts.dietIds',
})

export const DISH_AI_CURATION_FIELDS = [
  DISH_FIELDS.DISH_TYPE,
  DISH_FIELDS.COURSE_TYPE,
  DISH_FIELDS.ALLERGENS,
  DISH_FIELDS.DIETS,
  INGREDIENTS,
  DISH_FIELDS.CHOICE_INGREDIENTS,
]
