import { useEffect, useRef } from 'react'
import { every, isEqual } from 'lodash'

export default function useDeepCompareEffect(callback, dependencies) {
  const isFirstRun = useRef(true)
  const previousDependencies = useRef(dependencies)

  useEffect(() => {
    const areDependenciesTheSame = every(
      previousDependencies.current,
      (item, index) => isEqual(item, dependencies[index]),
    )

    if (isFirstRun.current || !areDependenciesTheSame) {
      callback()
    }
    isFirstRun.current = false
    previousDependencies.current = dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
