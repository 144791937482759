import { filter, get, isNil, map } from 'lodash'

import { findNodeByValue } from '../utils'

const getNodesFromAiSuggestions = (treeData, aiSuggestions) =>
  filter(
    map(aiSuggestions, ({ dishTypeId, courseTypeId, cuisineTypeId }) =>
      findNodeByValue(treeData, dishTypeId || courseTypeId || cuisineTypeId),
    ),
    node => !isNil(node),
  )

const getSuggestedByAiTreeData = (metadata, aiSuggestions = []) =>
  isNil(get(aiSuggestions, '[0].messages'))
    ? map(
        getNodesFromAiSuggestions(metadata, aiSuggestions),
        (node, index) => ({
          ...node,
          path: `${node.namePath} (${aiSuggestions[index].score}%)`,
          title: `${node.title} (${aiSuggestions[index].score}%)`,
        }),
      )
    : []

export default getSuggestedByAiTreeData
