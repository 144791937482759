import React, { forwardRef } from 'react'
import { Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'

const TypographyLinkWrapper = forwardRef((props, ref) => (
  <Tooltip title={props.children?.length > 30 ? props.children : undefined}>
    <Typography.Link ellipsis ref={ref} {...props}>
      {props.children}
    </Typography.Link>
  </Tooltip>
))

TypographyLinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TypographyLinkWrapper
