import React from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from 'antd'

import { useDocumentTitle } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import { MENU_ENTRY } from '../../../../common/constants'
import { USERS_WITH_KNOWLEDGE_QUERY } from '../../../../common/graphql'

import { BrandLocationTable } from '../../sections'
import { BrandLocationListFilter } from '../../sections/BrandLocationListFilter'

const BrandLocationList = () => {
  useDocumentTitle('Brands and Locations')
  const {
    data: { users } = {},
    loading: usersWithKnowledgeLoading,
    error: usersWithKnowledgeError,
  } = useQuery(USERS_WITH_KNOWLEDGE_QUERY, {
    variables: {
      filters: {
        isActive: true,
      },
    },
    fetchPolicy: 'network-only',
  })

  if (usersWithKnowledgeLoading) {
    return <Typography.Paragraph>Users are loading</Typography.Paragraph>
  }

  if (usersWithKnowledgeError) {
    return (
      <Typography.Paragraph>
        {usersWithKnowledgeError.message}
      </Typography.Paragraph>
    )
  }

  return (
    <PageWithHeader menuItem={MENU_ENTRY.BRANDS_AND_LOCATIONS} padding>
      <BrandLocationListFilter users={users} />
      <BrandLocationTable users={users} />
    </PageWithHeader>
  )
}

export default BrandLocationList
