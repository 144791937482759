import React from 'react'
import { Tooltip, Typography } from 'antd'
import { get, includes, isEmpty, isNil, join, map, some, toLower } from 'lodash'

import { areSameValue } from '../../core/utils'

import { HighlightingTextField, ReadMore } from '../../common/components'
import {
  AI_SUGGESTIONS_HIGHLIGHT_COLORS,
  CURATION_CERTAINTY_TRESHOLD,
  CURATION_STATUS,
} from '../../common/constants'
import { getHighlightListFromArrayForDescriptor } from '../../common/helpers'
import {
  findNodeBy,
  formatDescriptors,
  formatServicingHours,
} from '../../common/utils'

import {
  DietDescriptorCollapse,
  DishAiHighlightingFieldValue,
  DishMenuTitleDetailsCell,
  DishTableIngredientsCell,
  EditableCellHighlightingTextField,
  EditableCellSelectField,
  EditableCellTreeSelectField,
} from '../components/molecules'
import {
  DishTableActions,
  EditableCellIngredientsSelectField,
} from '../components/sections'
import {
  DIET_ABBREVIATIONS,
  DISH_ACTIONS,
  DISH_FIELDS,
  DISH_MENU_TITLE_ADDON_DESCRIPTORS,
  DISH_MENU_TITLE_ALLERGEN_DESCRIPTORS,
  DISH_MENU_TITLE_DESCRIPTION,
  DISH_MENU_TITLE_DIET_DESCRIPTORS,
  DISH_MENU_TITLE_FIELD_TYPE,
  INGREDIENTS,
} from '../constants'
import { DishEditableSelectsRowContext } from '../contexts'
import { formatDishPrice } from '../helpers'
import { dishFieldsUtils } from '../utils'

const textFieldStyle = { marginTop: '30px' }

const MAPPED_TREE_CHILDREN_DEPTH = {
  1: '··',
  2: '·',
  3: '',
}

const computeDishTableCellContent = ({
  dish,
  id,
  menuTitleOptions,
  dishesHighlightWords,
  brandStatus,
  isBrandDirty,
  userHasBrandChangePermission,
  index,
  areMlIngredientsEnabled,
}) => {
  const className = dishFieldsUtils.computeDishCellHighlight(
    dish,
    id,
    brandStatus,
  )
  const highlights = get(dishesHighlightWords, dish.id)
  const editableClassName = `${className || ''} cell-editable`
  const textEditableClassName = `${className || ''} cell-text-editable`

  const curationStepCertainty =
    (isNil(dish.curationStepCertainty) ? 1 : dish.curationStepCertainty) * 100
  const miscAndChoiceStepCertainty =
    (isNil(dish.miscAndChoiceStepCertainty)
      ? 1
      : dish.miscAndChoiceStepCertainty) * 100

  const curationDisabled =
    !userHasBrandChangePermission || isBrandDirty || !dish.isEnabled
  const editableTextDisabled =
    curationDisabled ||
    !includes(
      [
        CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
        CURATION_STATUS.CURATION_CONFIRMATION.value,
        CURATION_STATUS.QA.value,
        CURATION_STATUS.QQA_CONFIRMATION.value,
        CURATION_STATUS.DONE.value,
      ],
      brandStatus,
    )
  const dishTypeCourseTypeDisabled =
    curationDisabled ||
    !includes(
      [
        CURATION_STATUS.SANITY_CHECK.value,
        CURATION_STATUS.CURATION_CONFIRMATION.value,
        CURATION_STATUS.QA.value,
        CURATION_STATUS.QQA_CONFIRMATION.value,
        CURATION_STATUS.DONE.value,
      ],
      brandStatus,
    )
  // we disable the course type editing if the dish is ignored
  const courseTypeDisabled = get(dish, 'dishType.isIgnored')
  const dietsAllergensIngredientsDisabled =
    curationDisabled ||
    !includes(
      [
        CURATION_STATUS.CURATION_CONFIRMATION.value,
        CURATION_STATUS.QA.value,
        CURATION_STATUS.QQA_CONFIRMATION.value,
        CURATION_STATUS.DONE.value,
      ],
      brandStatus,
    )

  switch (id) {
    case DISH_FIELDS.NAME:
      return {
        props: {
          className: `${textEditableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellHighlightingTextField
            dishId={dish.id}
            dish={dish}
            value={dish.styledName || dish.name}
            highlights={get(highlights, DISH_FIELDS.NAME) || []}
            disabled={editableTextDisabled}
            name={DISH_FIELDS.NAME}
            contextToUse={DishEditableSelectsRowContext}
            inputStyle={index === 0 ? textFieldStyle : undefined}
          />
        ),
      }
    case DISH_FIELDS.STATUS:
      return {
        props: {
          className,
        },
        children: CURATION_STATUS[dish.status].label,
      }
    case DISH_FIELDS.CERTAINTY:
      return curationStepCertainty <= CURATION_CERTAINTY_TRESHOLD ||
        miscAndChoiceStepCertainty <= CURATION_CERTAINTY_TRESHOLD
        ? 'LOW'
        : 'HIGH'
    case DISH_FIELDS.DIETS:
      return {
        props: {
          className: `${editableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellSelectField
            dishId={dish.id}
            dish={dish}
            name={id}
            defaultValue={dish.diets}
            disabled={dietsAllergensIngredientsDisabled}
            defaultComponent={join(
              map(dish.diets, ({ name }) =>
                get(DIET_ABBREVIATIONS, toLower(name), name),
              ),
              ', ',
            )}
          />
        ),
      }
    case DISH_FIELDS.ALLERGENS:
      return {
        props: {
          className: `${editableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellSelectField
            dishId={dish.id}
            dish={dish}
            name={id}
            defaultValue={dish.allergens}
            disabled={dietsAllergensIngredientsDisabled}
            defaultComponent={join(map(dish.allergens, 'name'), ', ')}
          />
        ),
      }
    case DISH_FIELDS.PRICE:
      return {
        props: {
          className,
        },
        children: formatDishPrice(dish),
      }
    case DISH_FIELDS.SERVICING_HOURS:
      return {
        props: {
          className,
        },
        children: join(
          map(map(dish.servicingHours, formatServicingHours), 'name'),
          ' ',
        ),
      }
    case DISH_FIELDS.MENU_TITLE:
      return {
        props: {
          className,
        },
        children: get(dish, 'menuTitle.id') ? (
          <Tooltip
            title={get(
              findNodeBy(menuTitleOptions, ({ id: menuTitleId }) =>
                areSameValue(menuTitleId, get(dish, 'menuTitle.id')),
              ),
              'namePath',
            )}
          >
            {get(dish, 'menuTitle.name')}
          </Tooltip>
        ) : (
          ''
        ),
      }
    case DISH_FIELDS.COURSE_TYPE:
      return {
        props: {
          className: `${editableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellTreeSelectField
            dishId={dish.id}
            dish={dish}
            name={id}
            value={dish.courseType ? dish.courseType.id : ''}
            disabled={courseTypeDisabled || dishTypeCourseTypeDisabled}
            brandStatus={brandStatus}
            defaultComponent={
              dish.courseType ? (
                <DishAiHighlightingFieldValue
                  highlight={
                    some(
                      get(dish, 'aiSuggestions.courseType'),
                      ({ courseTypeId: suggestedId }) =>
                        suggestedId === dish.courseType.id,
                    )
                      ? AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE
                      : AI_SUGGESTIONS_HIGHLIGHT_COLORS.NONE
                  }
                >
                  <Typography.Text strong>
                    {MAPPED_TREE_CHILDREN_DEPTH[dish.courseType.level]}
                  </Typography.Text>{' '}
                  {dish.courseType.name}
                </DishAiHighlightingFieldValue>
              ) : (
                ''
              )
            }
          />
        ),
      }
    case DISH_FIELDS.DISH_TYPE:
      return {
        props: {
          className: `${editableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellTreeSelectField
            dishId={dish.id}
            dish={dish}
            name={id}
            value={dish.dishType ? dish.dishType.id : ''}
            disabled={dishTypeCourseTypeDisabled}
            brandStatus={brandStatus}
            defaultComponent={
              dish.dishType ? (
                <DishAiHighlightingFieldValue
                  highlight={
                    some(
                      get(dish, 'aiSuggestions.dishType'),
                      ({ dishTypeId: suggestedId }) =>
                        suggestedId === dish.dishType.id,
                    )
                      ? AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE
                      : AI_SUGGESTIONS_HIGHLIGHT_COLORS.NONE
                  }
                >
                  <Typography.Text strong>
                    {MAPPED_TREE_CHILDREN_DEPTH[dish.dishType.level]}
                  </Typography.Text>{' '}
                  {dish.dishType.name}
                </DishAiHighlightingFieldValue>
              ) : (
                ''
              )
            }
          />
        ),
      }
    case DISH_FIELDS.DESCRIPTION:
      return {
        props: {
          className: `${textEditableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellHighlightingTextField
            dishId={dish.id}
            dish={dish}
            value={dish.styledDescription || dish.description}
            highlights={get(highlights, DISH_FIELDS.DESCRIPTION) || []}
            disabled={editableTextDisabled}
            name={DISH_FIELDS.DESCRIPTION}
            contextToUse={DishEditableSelectsRowContext}
            inputStyle={index === 0 ? textFieldStyle : undefined}
          />
        ),
      }
    case DISH_FIELDS.INGREDIENTS_TEXT:
      return {
        props: {
          className: `${textEditableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellHighlightingTextField
            dishId={dish.id}
            dish={dish}
            value={dish.styledIngredientsText || dish.ingredientsText}
            highlights={get(highlights, DISH_FIELDS.INGREDIENTS_TEXT) || []}
            disabled={editableTextDisabled}
            name={DISH_FIELDS.INGREDIENTS_TEXT}
            contextToUse={DishEditableSelectsRowContext}
            inputStyle={index === 0 ? textFieldStyle : undefined}
          />
        ),
      }
    case INGREDIENTS:
      return {
        props: {
          className: `${editableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellIngredientsSelectField
            dishId={dish.id}
            dish={dish}
            name={id}
            value={{
              [DISH_FIELDS.MAIN_INGREDIENTS]: dish.mainIngredients,
              [DISH_FIELDS.ADDITIONAL_INGREDIENTS]: dish.additionalIngredients,
            }}
            disabled={dietsAllergensIngredientsDisabled}
            defaultComponent={
              <DishTableIngredientsCell
                ingredients={{
                  [DISH_FIELDS.MAIN_INGREDIENTS]: dish.mainIngredients,
                  [DISH_FIELDS.ADDITIONAL_INGREDIENTS]:
                    dish.additionalIngredients,
                }}
              />
            }
            areMlIngredientsEnabled={areMlIngredientsEnabled}
          />
        ),
      }
    case DISH_FIELDS.CHOICE_INGREDIENTS:
      return {
        props: {
          className: `${editableClassName || ''} field-${id}`,
        },
        children: (
          <EditableCellIngredientsSelectField
            dishId={dish.id}
            dish={dish}
            name={id}
            value={{
              [DISH_FIELDS.CHOICE_INGREDIENTS]: dish.choiceIngredients,
            }}
            disabled={dietsAllergensIngredientsDisabled}
            defaultComponent={
              <DishTableIngredientsCell
                ingredients={{
                  [DISH_FIELDS.CHOICE_INGREDIENTS]: dish.choiceIngredients,
                }}
                ingredientForPopover={DISH_FIELDS.CHOICE_INGREDIENTS}
              />
            }
            areMlIngredientsEnabled={areMlIngredientsEnabled}
          />
        ),
      }
    case DISH_FIELDS.ADDON_DESCRIPTORS:
      return {
        props: {
          className,
        },
        children: (
          <ReadMore
            maxHeight={170}
            component={
              <HighlightingTextField
                value={formatDescriptors(dish[DISH_FIELDS.ADDON_DESCRIPTORS])}
                highlightingList={
                  getHighlightListFromArrayForDescriptor(
                    highlights,
                    'addonDescriptors',
                  ) || []
                }
                disabled
              />
            }
          />
        ),
      }
    case DISH_FIELDS.DIET_DESCRIPTORS: {
      return {
        props: {
          className,
        },
        children: !isEmpty(dish.dietDescriptors) ? (
          <ReadMore
            maxHeight={170}
            component={
              <DietDescriptorCollapse
                collapsedByDefault={
                  brandStatus === CURATION_STATUS.CURATION_CONFIRMATION.value
                }
                value={dish.dietDescriptors}
                highlights={highlights}
              />
            }
          />
        ) : null,
      }
    }
    case DISH_FIELDS.ALLERGEN_DESCRIPTORS:
      return {
        props: {
          className,
        },
        children: (
          <ReadMore
            maxHeight={170}
            component={
              <HighlightingTextField
                value={formatDescriptors(
                  get(dish, DISH_FIELDS.ALLERGEN_DESCRIPTORS),
                )}
                highlightingList={
                  getHighlightListFromArrayForDescriptor(
                    highlights,
                    'allergenDescriptors',
                  ) || []
                }
                disabled
              />
            }
          />
        ),
      }
    case DISH_MENU_TITLE_DESCRIPTION:
      return {
        props: {
          className,
        },
        children: (
          <DishMenuTitleDetailsCell
            menuTitles={dish.menuTitleParents}
            highlights={highlights}
            highlightPlaceholder="menuTitleDescriptionId_{id}"
          />
        ),
      }
    case DISH_MENU_TITLE_ADDON_DESCRIPTORS:
      return {
        props: {
          className,
        },
        children: (
          <DishMenuTitleDetailsCell
            menuTitles={dish.menuTitleParents}
            highlights={highlights}
            highlightPlaceholder="menuTitleAddonDescriptorId_{id}"
            fieldName="menuTitleAddonDescriptors"
            fieldType={DISH_MENU_TITLE_FIELD_TYPE.SIMPLE_DESCRIPTOR}
          />
        ),
      }
    case DISH_MENU_TITLE_ALLERGEN_DESCRIPTORS:
      return {
        props: {
          className,
        },
        children: (
          <DishMenuTitleDetailsCell
            menuTitles={dish.menuTitleParents}
            highlights={highlights}
            highlightPlaceholder="{id}:allergenDescriptors"
            fieldName="menuTitleAllergenDescriptors"
            fieldType={DISH_MENU_TITLE_FIELD_TYPE.SIMPLE_DESCRIPTOR}
          />
        ),
      }
    case DISH_MENU_TITLE_DIET_DESCRIPTORS:
      return {
        props: {
          className,
        },
        children: (
          <DishMenuTitleDetailsCell
            menuTitles={dish.menuTitleParents}
            highlights={highlights}
            highlightPlaceholder="{id}:dietDescriptors"
            fieldName="menuTitleDietDescriptors"
            fieldType={DISH_MENU_TITLE_FIELD_TYPE.JSON_DESCRIPTOR}
          />
        ),
      }
    case DISH_ACTIONS:
      return {
        props: {
          className,
        },
        children: (
          <DishTableActions
            disabled={dietsAllergensIngredientsDisabled}
            dish={dish}
            contextToUse={DishEditableSelectsRowContext}
          />
        ),
      }
    default:
      return null
  }
}

export default computeDishTableCellContent
