import React, { memo, useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd'
import { includes } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAuthentication, useCachedBrand } from '../../../../common/hooks'

import { DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS } from '../../../constants'
import {
  BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
  SUGGEST_UNKNOWN_WORDS_MUTATION,
} from '../../../graphql'
import { BrandSuggestedWordsModal } from '../BrandSuggestedWordsModal'

const BrandSuggestedWordsButtons = ({ brandId, disabled }) => {
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    isBrandSuggestedWordsModalShown = DEFAULT_BRAND_SUGGESTED_WORDS_QUERY_PARAMS.SHOW_MODAL,
    setQueryParams,
  } = useQueryParams()

  const { status: brandStatus } = useCachedBrand(brandId)

  const {
    loading: isBrandSuggestedWordsCompletenessCountLoading,
    data: { brandSuggestedWordsCompletenessCount } = {
      brandSuggestedWordsCompletenessCount: {
        curatedWordsCount: 0,
        qadWordsCount: 0,
        wordsToResolveTotal: 0,
      },
    },
  } = useQuery(BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY, {
    variables: { brandId },
    onError: handleMutationError,
  })

  const [suggestUnknownWords, { loading: isSuggestUnknownWordsLoading }] =
    useMutation(SUGGEST_UNKNOWN_WORDS_MUTATION, {
      variables: { brandId },
      onError: handleMutationError,
      update: (cache, { data: { suggestUnknownWords: result } = {} }) => {
        cache.modify({
          id: cache.identify(
            result
              ? {
                  id: result.id,
                  __typename: 'Brand',
                }
              : {},
          ),
          fields: {
            pendingStatus(cachedPendingStatus) {
              return result?.pendingStatus || cachedPendingStatus
            },
            status(cachedStatus) {
              return result?.status || cachedStatus
            },
          },
        })
      },
    })

  const isWordCurationCompleted = useMemo(
    () =>
      !includes(
        [
          CURATION_STATUS.SANITY_CHECK.value,
          CURATION_STATUS.NEW.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
        ],
        brandStatus,
      ),
    [brandStatus],
  )

  const findButtonText = useMemo(() => {
    if (
      isBrandSuggestedWordsCompletenessCountLoading ||
      isSuggestUnknownWordsLoading
    ) {
      return 'Loading...'
    }

    return 'Find U.W'
  }, [
    isBrandSuggestedWordsCompletenessCountLoading,
    isSuggestUnknownWordsLoading,
  ])

  const isModalDisabled = useMemo(
    () => brandSuggestedWordsCompletenessCount.wordsToResolveTotal === 0,
    [brandSuggestedWordsCompletenessCount.wordsToResolveTotal],
  )

  const openModal = useCallback(
    () =>
      setQueryParams({
        isBrandSuggestedWordsModalShown: true,
        brandSuggestedWordsCurrentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        brandSuggestedWordsPageSize:
          userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
      }),
    [setQueryParams, userDefaultPageSize],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.SHIFT_U.hotkey,
    brandSuggestedWordsCompletenessCount?.suggestedWordsCount === 0 &&
      !isWordCurationCompleted
      ? suggestUnknownWords
      : openModal,
    {
      enabled: !disabled,
    },
    [
      disabled,
      brandSuggestedWordsCompletenessCount?.suggestedWordsCount,
      isWordCurationCompleted,
    ],
  )

  return (
    <Col span={8}>
      <Row>
        <Col span={24}>
          <Typography.Paragraph>
            Unknown Words{' '}
            <Space>
              <Tooltip title="Curated words">
                (
                <Typography.Text>
                  {brandSuggestedWordsCompletenessCount?.curatedWordsCount} /{' '}
                  {brandSuggestedWordsCompletenessCount?.wordsToResolveTotal}
                </Typography.Text>
                )
              </Tooltip>
              <Tooltip title="Qad words">
                (
                <Typography.Text>
                  {brandSuggestedWordsCompletenessCount?.qadWordsCount} /{' '}
                  {brandSuggestedWordsCompletenessCount?.wordsToResolveTotal}
                </Typography.Text>
                )
              </Tooltip>
            </Space>
          </Typography.Paragraph>
        </Col>
        <Space>
          <Button
            disabled={isModalDisabled}
            loading={
              isBrandSuggestedWordsCompletenessCountLoading ||
              isSuggestUnknownWordsLoading
            }
            onClick={openModal}
          >
            Open U.W. Modal
          </Button>
          <Button
            disabled={disabled}
            loading={
              isBrandSuggestedWordsCompletenessCountLoading ||
              isSuggestUnknownWordsLoading
            }
            onClick={suggestUnknownWords}
          >
            {findButtonText}
          </Button>
        </Space>

        {isBrandSuggestedWordsModalShown && (
          <BrandSuggestedWordsModal
            brandSuggestedWordsCompletenessCount={
              brandSuggestedWordsCompletenessCount
            }
          />
        )}
      </Row>
    </Col>
  )
}

BrandSuggestedWordsButtons.propTypes = {
  brandId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

BrandSuggestedWordsButtons.defaultProps = {
  disabled: false,
}

export default memo(BrandSuggestedWordsButtons)
