import { filter, flatten, isEmpty, map } from 'lodash'

const flattenTreeStructure = (treeData = []) => [
  ...treeData,
  ...flatten(
    map(
      filter(treeData, ({ children }) => !isEmpty(children)),
      ({ children }) => flattenTreeStructure(children),
    ),
  ),
]

export default flattenTreeStructure
