import { gql } from '@apollo/client'

import { SIMPLE_USER_FRAGMENT } from '../../../common/graphql'

const CONFIRM_STATUS_MUTATION = gql`
  mutation confirmStatus($input: ConfirmStatusInput!) {
    confirmStatus(input: $input) {
      id
      status
      pendingStatus
      updatedAt

      updatedBy {
        ...SimpleUserFragment
      }

      assignedUnknownWordsCurator {
        ...SimpleUserFragment
      }

      assignedUnknownWordsQa {
        ...SimpleUserFragment
      }

      assignedCurator {
        ...SimpleUserFragment
      }

      assignedQa {
        ...SimpleUserFragment
      }

      assignedQQa {
        ...SimpleUserFragment
      }
    }
  }
  ${SIMPLE_USER_FRAGMENT}
`

export default CONFIRM_STATUS_MUTATION
