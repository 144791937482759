import React, { useMemo } from 'react'
import { LinkOutlined } from '@ant-design/icons'
import { Button, Col, Row, Tooltip, Typography } from 'antd'
import { includes, isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { CURATION_STATUS, PERMISSIONS } from '../../../../common/constants'

import { BRAND_PENDING_STATUS } from '../../../constants'
import { brandPropType } from '../../../propTypes'
import {
  AddLocationToBrand,
  BrandStatusButtons,
  DeleteBrandButton,
  DeleteLocationButton,
} from '../../molecules'

const BrandTableActions = ({
  brandOrLocation,
  userPermissions,
  refetchBrands,
}) => {
  const {
    id,
    status,
    pendingStatus,
    unknownWordsCount,
    name,
    locations,
    brandId,
    web,
  } = brandOrLocation

  const isBrand = useMemo(() => isNil(brandId), [brandId])

  const isSuggestionDisabled = useMemo(
    () =>
      includes(
        [
          BRAND_PENDING_STATUS.BATCH_CURATION_PENDING,
          BRAND_PENDING_STATUS.MISC_AND_CHOICE_PENDING,
          BRAND_PENDING_STATUS.DUPLICATE_PENDING,
          BRAND_PENDING_STATUS.MENU_IMPORT_PENDING,
          BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_PENDING,
          BRAND_PENDING_STATUS.EXPORT_WORDS_PENDING,
        ],
        pendingStatus,
      ),
    [pendingStatus],
  )

  const disableMessage = useMemo(
    () => (isSuggestionDisabled ? 'Brand has a process pending' : null),
    [isSuggestionDisabled],
  )

  const isBrandStatusButtonsShown = useMemo(
    () =>
      includes(
        [CURATION_STATUS.MISC_AND_CHOICE.value, CURATION_STATUS.CURATION.value],
        status,
      ),
    [status],
  )

  const isDeletionOfBrandAllowed = useMemo(
    () =>
      includes(userPermissions, PERMISSIONS.BRANDS.DELETE) &&
      isEmpty(locations),
    [locations, userPermissions],
  )

  const isDeletionOfLocationAllowed = useMemo(
    () => includes(userPermissions, PERMISSIONS.LOCATIONS.DELETE) && brandId,
    [brandId, userPermissions],
  )

  if (isBrand) {
    return (
      <Row gutter={[16, 16]}>
        {isBrandStatusButtonsShown && (
          <Col>
            <BrandStatusButtons
              brand={brandOrLocation}
              size="small"
              onlySuggestions
              disabled={isSuggestionDisabled}
              disabledMessage={disableMessage}
              unknownWordsCount={unknownWordsCount}
            />
          </Col>
        )}
        <Col span={8}>
          <AddLocationToBrand brandId={id} />
        </Col>
        {isDeletionOfBrandAllowed && (
          <Col span={8}>
            <DeleteBrandButton id={id} name={name} />
          </Col>
        )}
        {web && (
          <Col span={8}>
            <Tooltip title={web}>
              <Typography.Link href={web} target="_blank">
                <Button size="small" icon={<LinkOutlined />} shape="circle" />
              </Typography.Link>
            </Tooltip>
          </Col>
        )}
      </Row>
    )
  }

  return (
    <Row gutter={[16, 16]}>
      {isDeletionOfLocationAllowed && (
        <Col span={8}>
          <DeleteLocationButton
            refetchBrands={refetchBrands}
            id={id}
            name={name}
            brandId={brandId}
          />
        </Col>
      )}
      {web && (
        <Col span={8}>
          <Tooltip title={web}>
            <Typography.Link href={web} target="_blank">
              <Button size="small" icon={<LinkOutlined />} shape="circle" />
            </Typography.Link>
          </Tooltip>
        </Col>
      )}
    </Row>
  )
}

BrandTableActions.propTypes = {
  brandOrLocation: brandPropType.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  refetchBrands: PropTypes.func.isRequired,
}

export default BrandTableActions
