const CURATION_STATUS = Object.freeze({
  NEW: {
    nextStep: 'SANITY_CHECK',
    value: 'NEW',
    label: 'New',
    shortLabel: 'New',
  },
  SANITY_CHECK: {
    nextStep: 'UNKNOWN_WORDS_CURATION',
    value: 'SANITY_CHECK',
    label: 'Sanity check',
    buttonLabel: 'Confirm sanity check',
    buttonShortLabel: 'Conf. S. check',
  },
  UNKNOWN_WORDS_CURATION: {
    nextStep: 'UNKNOWN_WORDS_CURATION_CONFIRMATION',
    value: 'UNKNOWN_WORDS_CURATION',
    label: 'Unknown words curation',
    buttonLabel: 'Suggest unknown words curation',
    buttonShortLabel: 'U.W. ctrn.',
  },
  UNKNOWN_WORDS_CURATION_CONFIRMATION: {
    nextStep: 'UNKNOWN_WORDS_QA',
    value: 'UNKNOWN_WORDS_CURATION_CONFIRMATION',
    label: 'Unknown words curation confirmation',
    buttonLabel: 'Confirm unknown words curation',
    buttonShortLabel: 'Conf. U.W. crtn.',
  },
  UNKNOWN_WORDS_QA: {
    nextStep: 'MISC_AND_CHOICE',
    value: 'UNKNOWN_WORDS_QA',
    label: 'Unknown words qa',
    buttonLabel: 'Confirm unknown words qa',
    buttonShortLabel: 'Conf. U.W. qa',
  },
  MISC_AND_CHOICE: {
    nextStep: 'MISC_AND_CHOICE_CONFIRMATION',
    value: 'MISC_AND_CHOICE',
    label: 'Misc and choice',
    buttonLabel: 'Misc and choice',
    buttonShortLabel: 'M&C',
  },
  MISC_AND_CHOICE_CONFIRMATION: {
    nextStep: 'LABEL_LEGENDS',
    value: 'MISC_AND_CHOICE_CONFIRMATION',
    label: 'Confirm misc and choice',
    buttonLabel: 'Confirm misc and choice',
    buttonShortLabel: 'Conf. M&C',
  },
  LABEL_LEGENDS: {
    nextStep: 'LABEL_LEGENDS_CONFIRMATION',
    value: 'LABEL_LEGENDS',
    label: 'Label legends',
    buttonLabel: 'Label legends',
    buttonShortLabel: 'Label legends',
  },
  LABEL_LEGENDS_CONFIRMATION: {
    nextStep: 'CURATION',
    value: 'LABEL_LEGENDS_CONFIRMATION',
    label: 'Label legends confirmation',
    buttonLabel: 'Confirm label legends curation',
    buttonShortLabel: 'Conf. Label Legends crtn.',
  },
  CURATION: {
    nextStep: 'CURATION_CONFIRMATION',
    value: 'CURATION',
    label: 'Curation',
    buttonLabel: 'Curation',
    buttonShortLabel: 'Crtn.',
  },
  CURATION_CONFIRMATION: {
    nextStep: 'QA',
    value: 'CURATION_CONFIRMATION',
    label: 'Curation confirmation',
    buttonLabel: 'Confirm curation',
    buttonShortLabel: 'Conf. crtn.',
  },
  QA: {
    nextStep: 'DONE',
    value: 'QA',
    label: 'Qa',
    buttonLabel: 'Confirm QA',
    buttonShortLabel: 'Conf. QA',
  },
  QQA_CONFIRMATION: {
    nextStep: 'DONE',
    value: 'QQA_CONFIRMATION',
    label: 'QQa confirmation',
    buttonLabel: 'Confirm QQA',
    buttonShortLabel: 'Conf. QQA',
  },
  DONE: {
    value: 'DONE',
    label: 'Done',
  },
})

export default CURATION_STATUS
