import { gql } from '@apollo/client'

const LOGIN_WITH_GOOGLE_ID_TOKEN_MUTATION = gql`
  mutation loginWithGoogleIdToken($googleIdToken: String!) {
    loginWithGoogleIdToken(googleIdToken: $googleIdToken) {
      accessToken
      refreshToken
    }
  }
`

export default LOGIN_WITH_GOOGLE_ID_TOKEN_MUTATION
