import { gql } from '@apollo/client'

import {
  COURSE_TYPE_ML_CLEANUP_FRAGMENT,
  DISH_TYPE_ML_CLEANUP_FRAGMENT,
} from '../fragments'

const ML_CLEANUPS_QUERY = gql`
  query mlCleanups(
    $taskId: ID!
    $currentPage: Int!
    $pageSize: Int!
    $metadataType: String!
    $filters: MlCleanupsFiltersInput!
  ) {
    mlCleanups(
      taskId: $taskId
      currentPage: $currentPage
      pageSize: $pageSize
      metadataType: $metadataType
      filters: $filters
    ) {
      count
      cleanups {
        ... on DishTypeMlCleanup {
          ...DishTypeMlCleanupFragment
        }

        ... on CourseTypeMlCleanup {
          ...CourseTypeMlCleanupFragment
        }
      }
    }
  }
  ${DISH_TYPE_ML_CLEANUP_FRAGMENT}
  ${COURSE_TYPE_ML_CLEANUP_FRAGMENT}
`

export default ML_CLEANUPS_QUERY
