import { map, omit } from 'lodash'

export const transformMenuToDto = menuJson => {
  const { id, url, dishes, menuTitles, fullAnnotations } = menuJson

  return {
    id,
    url,
    fullAnnotation: fullAnnotations,
    dishes: map(dishes, dish =>
      omit(
        {
          ...dish,
          ingredientsText: dish.ingredients,
          dietDescriptors: dish.diets,
          addonDescriptors: dish.addons,
          nutritionsDescriptors: dish.nutritions,
          allergenDescriptors: dish.allergens,
          miscDescriptors: dish.miscInfo,
        },
        [
          'ingredients',
          'diets',
          'addons',
          'nutritions',
          'allergens',
          'miscInfo',
          'hash',
        ],
      ),
    ),
    menuTitles: map(menuTitles, menuTitle =>
      omit(
        {
          ...menuTitle,
          name: menuTitle.title,
          dietDescriptors: menuTitle.diets,
          addonDescriptors: menuTitle.addons,
          nutritionsDescriptors: menuTitle.nutritions,
          allergenDescriptors: menuTitle.allergens,
          miscDescriptors: menuTitle.miscInfo,
        },
        [
          'title',
          'diets',
          'addons',
          'nutritions',
          'allergens',
          'miscInfo',
          'hash',
        ],
      ),
    ),
  }
}
