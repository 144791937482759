import React, { useMemo } from 'react'
import { Col, Row, Table, Tooltip } from 'antd'
import { isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { formatDate } from '../../../../core/utils'

import { INGREDIENTS_RERUN_STATUS } from '../../../constants'

const styles = {
  tableContainer: {
    marginTop: 12,
  },
}

const IngredientsRerunTable = ({
  data,
  total,
  page,
  loading,
  onPaginationChange,
  pageSize,
  onPageSizeChange,
}) => {
  const tableColumns = useMemo(
    () => [
      {
        title: 'Id',
        width: 50,
        render: ({ id }) => id,
      },
      {
        title: 'Status',
        width: 100,
        render: ({ status, errorMessage }) => {
          if (status === INGREDIENTS_RERUN_STATUS.FAILED) {
            return <Tooltip title={errorMessage}>{status}</Tooltip>
          }

          return status
        },
      },
      {
        title: 'Started by',
        width: 100,
        render: ({ createdBy }) => {
          if (isEmpty(createdBy)) {
            return 'Cron job'
          }

          return createdBy.name
        },
      },
      {
        title: 'Started at',
        width: 100,
        render: ({ createdAt }) => formatDate(createdAt),
      },
      {
        title: 'Canceled by',
        width: 100,
        render: ({ canceledBy }) => {
          if (isEmpty(canceledBy)) {
            return '-'
          }

          return canceledBy.name
        },
      },
      {
        title: 'Ready for review at',
        width: 100,
        render: ({ readyForReviewAt }) => {
          if (isEmpty(readyForReviewAt)) {
            return '-'
          }

          return formatDate(readyForReviewAt)
        },
      },
      {
        title: 'Completed/Canceled at',
        width: 100,
        render: ({ canceledAt, completedAt }) => {
          if (!isNil(canceledAt)) {
            return formatDate(canceledAt)
          }

          if (!isNil(completedAt)) {
            return formatDate(completedAt)
          }

          return '-'
        },
      },
    ],
    [],
  )

  const paginationConfig = useMemo(
    () => ({
      current: page,
      total,
      pageSize,
      onChange: onPaginationChange,
      onShowSizeChange: onPageSizeChange,
    }),
    [onPageSizeChange, onPaginationChange, page, pageSize, total],
  )

  return (
    <Row style={styles.tableContainer}>
      <Col span={24}>
        <Table
          loading={loading}
          rowKey={record => record.id}
          columns={tableColumns}
          childrenColumnName="batches"
          dataSource={data}
          pagination={paginationConfig}
          size="middle"
        />
      </Col>
    </Row>
  )
}

IngredientsRerunTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number,
  loading: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
}

IngredientsRerunTable.defaultProps = {
  total: undefined,
  loading: false,
  onPaginationChange: undefined,
  onPageSizeChange: undefined,
}

export default IngredientsRerunTable
