import React, { useCallback, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ReadOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip } from 'antd'
import { filter, isEmpty, map, trim } from 'lodash'
import PropTypes from 'prop-types'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
} from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import './DishListHotkeysLegend.css'

const styles = {
  modal: { width: '900px' },
}

const DishListHotkeysLegend = ({ brandStatus }) => {
  const [visible, setVisible] = useState(false)
  const { dishTypeOptions } = useCurationMetadata()
  const isMac = useMemo(
    () => navigator.platform.toUpperCase().indexOf('MAC') >= 0,
    [],
  )

  const ignoredDishTypes = useMemo(() => {
    if (isEmpty(dishTypeOptions)) {
      return []
    }

    return filter(dishTypeOptions, 'isIgnored')
  }, [dishTypeOptions])

  const tableData = useMemo(() => {
    const commonHotkeys = [
      AVAILABLE_HOTKEYS.L,
      ...(isMac
        ? [AVAILABLE_HOTKEYS.CMD_LEFT, AVAILABLE_HOTKEYS.CMD_RIGHT]
        : [AVAILABLE_HOTKEYS.CTRL_LEFT, AVAILABLE_HOTKEYS.CTRL_RIGHT]),
      ...(isMac
        ? [AVAILABLE_HOTKEYS.OPTION_LEFT, AVAILABLE_HOTKEYS.OPTION_RIGHT]
        : [AVAILABLE_HOTKEYS.ALT_LEFT, AVAILABLE_HOTKEYS.ALT_RIGHT]),
      AVAILABLE_HOTKEYS.SHIFT_C,
    ]

    switch (brandStatus) {
      case CURATION_STATUS.SANITY_CHECK.value:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.DOWN,
          AVAILABLE_HOTKEYS.UP,
          AVAILABLE_HOTKEYS.X,
          ...map(ignoredDishTypes, dishType => ({
            hotkey: trim(dishType.title)[0],
            description: `Set the dish type of the selected row as "${dishType.title}"`,
          })),
          ...(isMac ? [AVAILABLE_HOTKEYS.CMD_C] : [AVAILABLE_HOTKEYS.CTRL_C]),
        ]
      case CURATION_STATUS.UNKNOWN_WORDS_CURATION.value:
      case CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value:
      case CURATION_STATUS.UNKNOWN_WORDS_QA.value:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.DOWN,
          AVAILABLE_HOTKEYS.UP,
          AVAILABLE_HOTKEYS.X,
          AVAILABLE_HOTKEYS.SHIFT_U,
        ]
      case CURATION_STATUS.MISC_AND_CHOICE.value:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.DOWN,
          AVAILABLE_HOTKEYS.UP,
          AVAILABLE_HOTKEYS.X,
          ...map(ignoredDishTypes, dishType => ({
            hotkey: trim(dishType.title)[0],
            description: `Set the dish type of the selected row as "${dishType.title}"`,
          })),
          ...(isMac ? [AVAILABLE_HOTKEYS.CMD_C] : [AVAILABLE_HOTKEYS.CTRL_C]),
          AVAILABLE_HOTKEYS.SHIFT_U,
        ]
      case CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.UP,
          AVAILABLE_HOTKEYS.DOWN,
          AVAILABLE_HOTKEYS.LEFT,
          AVAILABLE_HOTKEYS.RIGHT,
          AVAILABLE_HOTKEYS.SHIFT_DOWN,
          AVAILABLE_HOTKEYS.SHIFT_UP,
          AVAILABLE_HOTKEYS.SHIFT_LEFT,
          AVAILABLE_HOTKEYS.SHIFT_RIGHT,
          AVAILABLE_HOTKEYS.ENTER,
          AVAILABLE_HOTKEYS.ESC,
          ...(isMac
            ? [AVAILABLE_HOTKEYS.CMD_I, AVAILABLE_HOTKEYS.CMD_U]
            : [AVAILABLE_HOTKEYS.CTRL_I, AVAILABLE_HOTKEYS.CTRL_U]),
          ...(isMac ? [AVAILABLE_HOTKEYS.CMD_C] : [AVAILABLE_HOTKEYS.CTRL_C]),
          AVAILABLE_HOTKEYS.C,
        ]
      case CURATION_STATUS.CURATION_CONFIRMATION.value:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.UP,
          AVAILABLE_HOTKEYS.DOWN,
          AVAILABLE_HOTKEYS.LEFT,
          AVAILABLE_HOTKEYS.RIGHT,
          AVAILABLE_HOTKEYS.SHIFT_DOWN,
          AVAILABLE_HOTKEYS.SHIFT_UP,
          AVAILABLE_HOTKEYS.SHIFT_LEFT,
          AVAILABLE_HOTKEYS.SHIFT_RIGHT,
          AVAILABLE_HOTKEYS.ENTER,
          AVAILABLE_HOTKEYS.ESC,
          ...(isMac
            ? [AVAILABLE_HOTKEYS.CMD_I, AVAILABLE_HOTKEYS.CMD_U]
            : [AVAILABLE_HOTKEYS.CTRL_I, AVAILABLE_HOTKEYS.CTRL_U]),
          ...(isMac ? [AVAILABLE_HOTKEYS.CMD_C] : [AVAILABLE_HOTKEYS.CTRL_C]),
          AVAILABLE_HOTKEYS.P,
          AVAILABLE_HOTKEYS.SHIFT_P,
          ...(isMac ? [AVAILABLE_HOTKEYS.OPTION_P] : [AVAILABLE_HOTKEYS.ALT_P]),
          AVAILABLE_HOTKEYS.TAB,
          AVAILABLE_HOTKEYS.S,
          AVAILABLE_HOTKEYS.SHIFT_S,
          AVAILABLE_HOTKEYS.C,
          AVAILABLE_HOTKEYS.SHIFT_L,
        ]
      case CURATION_STATUS.QA.value:
      case CURATION_STATUS.QQA_CONFIRMATION.value:
      case CURATION_STATUS.DONE.value:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.UP,
          AVAILABLE_HOTKEYS.DOWN,
          AVAILABLE_HOTKEYS.LEFT,
          AVAILABLE_HOTKEYS.RIGHT,
          AVAILABLE_HOTKEYS.SHIFT_DOWN,
          AVAILABLE_HOTKEYS.SHIFT_UP,
          AVAILABLE_HOTKEYS.SHIFT_LEFT,
          AVAILABLE_HOTKEYS.SHIFT_RIGHT,
          AVAILABLE_HOTKEYS.ENTER,
          AVAILABLE_HOTKEYS.ESC,
          AVAILABLE_HOTKEYS.P,
          AVAILABLE_HOTKEYS.SHIFT_P,
          ...(isMac ? [AVAILABLE_HOTKEYS.OPTION_P] : [AVAILABLE_HOTKEYS.ALT_P]),
          AVAILABLE_HOTKEYS.TAB,
          AVAILABLE_HOTKEYS.S,
          AVAILABLE_HOTKEYS.SHIFT_S,
          AVAILABLE_HOTKEYS.SHIFT_L,
        ]
      default:
        return [...commonHotkeys]
    }
  }, [isMac, brandStatus, ignoredDishTypes])

  const popoverContent = useMemo(() => {
    const columns = [
      {
        title: 'Key',
        dataIndex: 'hotkey',
        key: 'hotkey',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
    ]

    return (
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={false}
      />
    )
  }, [tableData])

  const handleCancel = useCallback(() => setVisible(false), [])
  const toggleModal = useCallback(() => setVisible(!visible), [visible])

  // hotkey to open/close the legend
  useHotkeys(AVAILABLE_HOTKEYS.L.hotkey, toggleModal, [toggleModal])

  return (
    <>
      <Modal
        title="Dish list navigation hotkeys"
        footer={null}
        wrapClassName="dishes-list-hotkeys-legend"
        visible={visible}
        width={styles.modal.width}
        onCancel={handleCancel}
      >
        {popoverContent}
      </Modal>
      <Tooltip title="Dish list navigation hotkeys">
        <Button icon={<ReadOutlined />} shape="circle" onClick={toggleModal} />
      </Tooltip>
    </>
  )
}

DishListHotkeysLegend.propTypes = {
  brandStatus: PropTypes.string.isRequired,
}

export default DishListHotkeysLegend
