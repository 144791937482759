import { gql } from '@apollo/client'

import {
  ALLERGEN_LABEL_LEGEND_FRAGMENT,
  DIET_LABEL_LEGEND_FRAGMENT,
  SERVICING_HOUR_FRAGMENT,
  SIMPLE_USER_FRAGMENT,
} from '../../../common/graphql'

import QA_BRAND_CORRECTION_COUNTS_FRAGMENT from './brandCorrectionsFragment'
import BRAND_ENTRY_FRAGMENT from './brandEntryFragment'

const BRAND_FRAGMENT = gql`
  fragment BrandFragment on Brand {
    ...BrandEntryFragment
    updatedAt
    createdAt
    duplicatedAt
    lastImportedAt
    pendingStatus
    requiresQaAttention
    qaDetailLevel
    requiresCuratorAttention
    isPartOfCleanupTask
    problemReason
    problemComment
    tags

    languages {
      id
      brandId
      name
      position
      isSuggestedByAi
    }

    originalBrand {
      id
      name
      updatedAt
    }

    locations {
      ...SimpleEntityFragment
    }

    diets {
      ...SimpleEntityFragment
    }

    servicingHours {
      ...ServicingHourFragment
    }

    dietLabelLegends {
      ...DietLabelLegendFragment
    }

    allergenLabelLegends {
      ...AllergenLabelLegendFragment
    }

    updatedBy {
      ...SimpleUserFragment
    }

    lastImportedBy {
      ...SimpleUserFragment
    }

    menus {
      autoEatId
      url
    }

    latestMenuInfo {
      importedDishesCount
      fullAnnotation
    }

    assignedUnknownWordsCurator {
      ...SimpleUserFragment
    }

    assignedUnknownWordsQa {
      ...SimpleUserFragment
    }

    assignedCurator {
      ...SimpleUserFragment
    }

    assignedQa {
      ...SimpleUserFragment
    }

    assignedQQa {
      ...SimpleUserFragment
    }

    qaCorrectionCounts {
      ...QaBrandCorrectionCountsFragment
    }

    curatedAt
    qadAt
    qqadAt
    curatedBy {
      ...SimpleUserFragment
    }
    qadBy {
      ...SimpleUserFragment
    }
    qqadBy {
      ...SimpleUserFragment
    }
  }
  ${SIMPLE_USER_FRAGMENT}
  ${SERVICING_HOUR_FRAGMENT}
  ${DIET_LABEL_LEGEND_FRAGMENT}
  ${ALLERGEN_LABEL_LEGEND_FRAGMENT}
  ${BRAND_ENTRY_FRAGMENT}
  ${QA_BRAND_CORRECTION_COUNTS_FRAGMENT}
`

export default BRAND_FRAGMENT
