import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from 'antd'

import theme from '../../../../theme'
import { locationIdNew } from '../../../constants'
import { locationPropType } from '../../../propTypes'
import {
  removeLocation,
  setCuisineTypeId,
  setLocationMenuLink,
  setLocationMenuSource,
  setLocationTypeId,
  setLocationWebsite,
} from '../../../redux'
import { LocationEntryAddress } from '../LocationEntryAddress'
import { LocationEntryDetails } from '../LocationEntryDetails'
import { LocationEntryHoursAndMenuTitles } from '../LocationEntryHoursAndMenuTitles'
import { LocationEntryMenuAndStatus } from '../LocationEntryMenuAndStatus'

const styles = {
  container: {
    padding: theme.padding,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
}

const LocationEntry = ({ location }) => {
  const {
    id: locationId,
    web,
    menuSource,
    menuLink,
    brand: { locationType: brandLocationType, cuisineType: brandCuisineType },
  } = location || {}

  const dispatch = useDispatch()

  useEffect(() => {
    if (locationId !== locationIdNew) {
      return
    }

    // Persist parent brand fields as unsaved location field changes.
    if (brandLocationType) {
      dispatch(
        setLocationTypeId({
          locationId: locationIdNew,
          value: brandLocationType.id,
        }),
      )
    }
    if (brandCuisineType) {
      dispatch(
        setCuisineTypeId({
          locationId: locationIdNew,
          value: brandCuisineType.id,
        }),
      )
    }

    if (web) {
      dispatch(setLocationWebsite({ locationId: locationIdNew, value: web }))
    }

    if (menuSource) {
      dispatch(
        setLocationMenuSource({ locationId: locationIdNew, value: menuSource }),
      )
    }

    if (menuLink) {
      dispatch(
        setLocationMenuLink({ locationId: locationIdNew, value: menuLink }),
      )
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (locationId === locationIdNew) {
        dispatch(removeLocation(locationId))
      }
    }
  }, [
    dispatch,
    brandCuisineType,
    brandLocationType,
    locationId,
    menuLink,
    menuSource,
    web,
  ])

  return (
    <div style={styles.container}>
      <LocationEntryDetails location={location} />
      <Divider style={styles.divider} />
      <LocationEntryAddress location={location} />
      <Divider style={styles.divider} />
      <LocationEntryMenuAndStatus location={location} />
      <Divider style={styles.divider} />
      <LocationEntryHoursAndMenuTitles location={location} />
    </div>
  )
}

LocationEntry.propTypes = {
  location: locationPropType,
}

LocationEntry.defaultProps = {
  location: null,
}

export default LocationEntry
