import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { Card, Col, Input, Row } from 'antd'
import { filter, find, includes, map, reduce, upperFirst, values } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Checkbox,
  DatePicker,
  GridFormItem,
  Input as FormInput,
  Select,
  TreeSelect,
} from '../../../../core/components'
import { areSameValue, formatDate } from '../../../../core/utils'

import {
  COUNTRIES,
  TIME_ZONES,
  USER_SENIORITY_LEVELS,
} from '../../../../common/constants'
import { getFilterOptions } from '../../../../common/helpers'
import {
  useAuthentication,
  useCurationMetadata,
} from '../../../../common/hooks'
import { userInfoPropType } from '../../../../common/propTypes'

import {
  USER_GENDER,
  USER_QUALIFICATION_MAP,
  USER_STATUS,
  USER_TIER,
  userIdNew,
} from '../../../constants'
import { ROLES_QUERY } from '../../../graphql'
import {
  useUserFormDisabledFields,
  useUserFormResetRules,
} from '../../../hooks'
import {
  UserCompetencies,
  UserEducation,
  UserWorkSchedule,
} from '../../molecules'

const styles = {
  cardContainer: { padding: '50px 0', display: 'flex' },
  centeredRow: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  card: { width: '60%' },
  datePicker: {
    width: '100%',
  },
}

const UserEditorForm = ({
  user,
  competencies,
  qualifications,
  departments,
  isReadOnly,
}) => {
  const { id, createdAt, lastLoginDate } = user || {}
  const { userInfo: loggedInUser } = useAuthentication()
  const { cuisineTypeOptions, languageOptions } = useCurationMetadata()
  const userId = user ? id : userIdNew

  const { data: { roles } = {} } = useQuery(ROLES_QUERY)

  const { watch } = useFormContext()
  const selectedStatus = watch('status')

  const roleOptions = useMemo(
    () =>
      map(roles, ({ id: roleId, name: roleName }) => ({
        name: upperFirst(roleName),
        id: roleId,
      })),
    [roles],
  )
  const countryOptions = useMemo(
    () => map(COUNTRIES, country => ({ label: country, value: country })),
    [],
  )
  const timeZoneOptions = useMemo(
    () => map(TIME_ZONES, timeZone => ({ label: timeZone, value: timeZone })),
    [],
  )
  const genderOptions = useMemo(() => getFilterOptions(USER_GENDER), [])
  const statusOptions = useMemo(() => getFilterOptions(USER_STATUS), [])
  const tierOptions = useMemo(() => getFilterOptions(USER_TIER), [])

  const selectedDepartmentIds = watch('departmentIds')
  const selectedDepartmentValues = useMemo(
    () =>
      map(selectedDepartmentIds, selectedDepartmentId => {
        const department = find(departments, ({ id: departmentId }) =>
          areSameValue(departmentId, selectedDepartmentId),
        )
        return department?.value
      }),
    [selectedDepartmentIds, departments],
  )
  const departmentOptions = useMemo(
    () => getFilterOptions(departments, 'label', 'id'),
    [departments],
  )

  const selectedQualificationIds = watch('qualificationIds')
  const selectedQualificationValues = useMemo(
    () =>
      map(selectedQualificationIds, selectedQualificationId => {
        const qualification = find(qualifications, ({ id: qualificationId }) =>
          areSameValue(qualificationId, selectedQualificationId),
        )
        return qualification?.value
      }),
    [selectedQualificationIds, qualifications],
  )
  const qualificationOptions = useMemo(
    () =>
      reduce(
        selectedDepartmentValues,
        (result, departmentValue) => [
          ...result,
          ...getFilterOptions(
            filter(qualifications, ({ value }) =>
              includes(USER_QUALIFICATION_MAP[departmentValue], value),
            ),
            'label',
            'id',
          ),
        ],
        [],
      ),
    [selectedDepartmentValues, qualifications],
  )

  const {
    isActiveFieldDisabled,
    isUserQualificationFieldDisabled,
    areCuisineAndLanguageFieldsDisabled,
    isWorkExperienceFieldDisabled,
    isTierFieldDisabled,
    isOtherQualificationFieldDisabled,
  } = useUserFormDisabledFields({
    isReadOnly,
    selectedDepartmentValues,
    selectedQualificationValues,
    qualificationOptions,
    loggedInUser,
    userId,
  })

  useUserFormResetRules({
    selectedDepartmentValues,
    selectedQualificationIds,
    selectedQualificationValues,
    qualificationOptions,
  })

  const handleDisableFutureDates = useCallback(
    current => current > moment(),
    [],
  )

  return (
    <div style={styles.cardContainer}>
      <div style={styles.centeredRow}>
        <Card style={styles.card}>
          <form>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row gutter={16}>
                  <FormInput
                    labelAbove
                    span={10}
                    label="Name: "
                    name="name"
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isNameGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <Select
                    span={12}
                    labelAbove
                    label="Roles: "
                    allowClear={false}
                    labelProp="name"
                    valueProp="id"
                    name="roleIds"
                    options={roleOptions}
                    mode="multiple"
                    placeholder="Select Roles..."
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Checkbox
                disabled={isActiveFieldDisabled}
                labelAbove
                span={12}
                label="Enable user: "
                name="isActive"
              />
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Select
                    span={10}
                    labelAbove
                    label="Gender"
                    placeholder="Select gender"
                    name="gender"
                    options={genderOptions}
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isGenderGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <DatePicker
                    span={10}
                    style={styles.datePicker}
                    format="YYYY-MM-DD"
                    labelAbove
                    label="Birthdate"
                    placeholder="Birthdate"
                    name="birthdate"
                    showToday={false}
                    disabledDate={handleDisableFutureDates}
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isBirthdateGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <FormInput
                    labelAbove
                    span={10}
                    label="Work email: "
                    name="email"
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isEmailGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <FormInput
                    labelAbove
                    span={12}
                    label="Personal email: "
                    name="personalEmail"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <FormInput
                    labelAbove
                    span={12}
                    label="Invoice email: "
                    name="invoiceEmail"
                    disabled={isReadOnly}
                  />
                  <FormInput
                    labelAbove
                    span={12}
                    label="Clockify email: "
                    name="clockifyEmail"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <FormInput
                    labelAbove
                    span={10}
                    label="Phone:"
                    name="phone"
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isPhoneGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Select
                    span={10}
                    labelAbove
                    label="Country"
                    placeholder="Select country"
                    name="country"
                    options={countryOptions}
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isCountryGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <Select
                    span={10}
                    labelAbove
                    label="Time zone"
                    placeholder="Select time zone"
                    name="timeZone"
                    options={timeZoneOptions}
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isTimeZoneGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <UserEducation
                  education={user?.education}
                  disabled={isReadOnly}
                />
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <FormInput
                    labelAbove
                    span={10}
                    label="Education extracurricular activities:"
                    name="extracurricularActivities"
                    autoSize
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isEducationGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <FormInput
                    labelAbove
                    span={10}
                    label="Previous work experience:"
                    name="previousWorkExperience"
                    disabled={isWorkExperienceFieldDisabled}
                    autoSize
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isPreviousWorkExperienceGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Select
                    span={22}
                    labelAbove
                    label="Department"
                    placeholder="Select department"
                    mode="multiple"
                    name="departmentIds"
                    disabled={isReadOnly}
                    options={departmentOptions}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isDepartmentGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Col span={22}>
                    <UserCompetencies
                      competencies={competencies}
                      userCompetencies={user?.competencies}
                      selectedDepartments={selectedDepartmentValues}
                      disabled={isReadOnly}
                    />
                  </Col>
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isCompetencyGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Select
                    span={12}
                    labelAbove
                    label="Qualification"
                    placeholder="Select qualification"
                    mode="multiple"
                    name="qualificationIds"
                    disabled={isUserQualificationFieldDisabled}
                    options={qualificationOptions}
                  />
                  <FormInput
                    labelAbove
                    span={10}
                    label="Other qualification "
                    name="otherQualification"
                    disabled={isOtherQualificationFieldDisabled}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isQualificationGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <TreeSelect
                    span={22}
                    labelAbove
                    label="Cuisine knowledge: "
                    allowClear={false}
                    name="cuisineTypeIds"
                    treeData={cuisineTypeOptions}
                    disabled={areCuisineAndLanguageFieldsDisabled}
                    multi
                    placeholder="Select Cuisine Types..."
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isCuisineKnowlegeGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Select
                span={24}
                labelAbove
                label="Seniority"
                placeholder="Select seniority..."
                name="seniority"
                options={map(values(USER_SENIORITY_LEVELS), level => ({
                  value: level,
                  label: level,
                }))}
                disabled={isReadOnly}
              />
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Select
                    span={22}
                    labelAbove
                    label="Languages"
                    placeholder="Select Languages..."
                    mode="multiple"
                    name="languageIds"
                    disabled={areCuisineAndLanguageFieldsDisabled}
                    options={languageOptions}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isLanguageGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16} align="bottom">
                  <Col span={22}>
                    <UserWorkSchedule
                      workSchedule={user?.workSchedule}
                      disabled={isReadOnly}
                    />
                  </Col>
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isWorkScheduleGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={16}>
                  <Select
                    span={10}
                    labelAbove
                    label="Status"
                    placeholder="Select status"
                    name="status"
                    options={statusOptions}
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isStatusGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <DatePicker
                    span={10}
                    style={styles.datePicker}
                    format="YYYY-MM-DD"
                    labelAbove
                    label="Joined at"
                    placeholder="Joined at"
                    disabledDate={handleDisableFutureDates}
                    name="joinedAt"
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isJoinedAtGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              {selectedStatus === 'RESIGNED' && (
                <DatePicker
                  span={12}
                  style={styles.datePicker}
                  format="YYYY-MM-DD"
                  labelAbove
                  label="Resigned at"
                  placeholder="Resigned at"
                  disabledDate={handleDisableFutureDates}
                  name="resignedAt"
                  disabled={isReadOnly}
                />
              )}
              {selectedStatus === 'TERMINATED' && (
                <Col span={24}>
                  <Row gutter={16}>
                    <DatePicker
                      span={12}
                      style={styles.datePicker}
                      format="YYYY-MM-DD"
                      labelAbove
                      label="Terminated at"
                      placeholder="Terminated at"
                      disabledDate={handleDisableFutureDates}
                      name="terminatedAt"
                      disabled={isReadOnly}
                    />
                    <FormInput
                      labelAbove
                      span={12}
                      label="Terminated reason:"
                      name="terminatedReason"
                      disabled={isReadOnly}
                    />
                  </Row>
                </Col>
              )}
              <Col span={24}>
                <Row gutter={16}>
                  <Select
                    span={10}
                    labelAbove
                    label="Tier"
                    placeholder="Select tier"
                    name="tier"
                    disabled={isTierFieldDisabled}
                    options={tierOptions}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isTierGraphRelevant"
                    disabled={isReadOnly}
                  />
                  <DatePicker
                    span={10}
                    style={styles.datePicker}
                    format="YYYY-MM-DD"
                    labelAbove
                    label="Last tier review at"
                    placeholder="Last tier review at"
                    disabledDate={handleDisableFutureDates}
                    name="lastReviewAt"
                    disabled={isReadOnly}
                  />
                  <Checkbox
                    labelAbove
                    span={2}
                    label="G.R"
                    name="isLastReviewAtGraphRelevant"
                    disabled={isReadOnly}
                  />
                </Row>
              </Col>
              {userId !== userIdNew && (
                <Col span={24}>
                  <Row gutter={16}>
                    <GridFormItem span={12} label="Last Login Data:">
                      <Input
                        disabled
                        value={
                          lastLoginDate ? formatDate(lastLoginDate) : 'N/A'
                        }
                      />
                    </GridFormItem>
                    <GridFormItem span={12} label="User create at:">
                      <Input
                        disabled
                        value={createdAt ? formatDate(createdAt) : 'N/A'}
                      />
                    </GridFormItem>
                  </Row>
                </Col>
              )}
            </Row>
          </form>
        </Card>
      </div>
    </div>
  )
}

UserEditorForm.propTypes = {
  user: userInfoPropType,
  departments: PropTypes.arrayOf(PropTypes.object),
  competencies: PropTypes.arrayOf(PropTypes.object),
  qualifications: PropTypes.arrayOf(PropTypes.object),
  isReadOnly: PropTypes.bool,
}

UserEditorForm.defaultProps = {
  user: undefined,
  departments: [],
  competencies: [],
  qualifications: [],
  isReadOnly: false,
}

export default UserEditorForm
