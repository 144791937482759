import React from 'react'
import { Col, Form, Typography } from 'antd'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

import { SpanPropType } from '../../../propTypes'

import './gridFormItem.css'

const styles = {
  margin: 0,
}

const GridFormItem = ({
  span,
  style,
  className,
  validateStatus,
  help,
  labelAbove,
  label,
  children,
  onClick,
  onMouseEnter,
  onFocus,
  onMouseLeave,
  smallLabel,
}) => {
  let classNames = `${className || ''}`

  if (smallLabel) {
    classNames = `${className || ''} smallLabel`
  }

  return span ? (
    <Col
      span={span}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
    >
      <Form.Item
        style={{ ...styles, ...style }}
        className={classNames}
        validateStatus={validateStatus}
        help={help}
        label={
          label ? (
            <Typography.Text ellipsis>{label}</Typography.Text>
          ) : undefined
        }
        labelCol={labelAbove ? { span: 24 } : undefined}
      >
        {children}
      </Form.Item>
    </Col>
  ) : (
    <Form.Item
      onClick={onClick}
      className={classNames}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      style={{ ...styles, ...style }}
      validateStatus={validateStatus}
      help={help}
      label={
        label ? <Typography.Text ellipsis>{label}</Typography.Text> : undefined
      }
      labelCol={labelAbove ? { span: 24 } : undefined}
    >
      {children}
    </Form.Item>
  )
}

GridFormItem.propTypes = {
  span: SpanPropType,
  className: PropTypes.string,
  labelAbove: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  validateStatus: PropTypes.string,
  help: PropTypes.string,
  // The purpose of these events is to make it work with pop confirm components
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseLeave: PropTypes.func,
  smallLabel: PropTypes.bool,
}

GridFormItem.defaultProps = {
  span: undefined,
  className: undefined,
  labelAbove: true,
  label: undefined,
  style: undefined,
  validateStatus: undefined,
  help: undefined,
  onClick: noop,
  onMouseEnter: noop,
  onFocus: noop,
  onMouseLeave: noop,
  smallLabel: false,
}

export default GridFormItem
