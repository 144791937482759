import { gql } from '@apollo/client'

import {
  QA_DISH_CORRECTION_COUNTS_FRAMGNET,
  SERVICING_HOUR_FRAGMENT,
  SIMPLE_ENTITY_FRAGMENT,
  SIMPLE_USER_FRAGMENT,
} from '../../../common/graphql'

const BRAND_DISH_FRAGMENT = gql`
  fragment BrandDishFragment on BrandDish {
    id
    name
    published
    lowPrice
    highPrice
    currency
    description
    ingredientsText
    styledName
    styledDescription
    styledIngredientsText
    updatedAt
    dietDescriptors
    addonDescriptors
    miscDescriptors
    nutritionsDescriptors
    allergenDescriptors
    nameTranslation
    descriptionTranslation
    ingredientsTextTranslation
    status
    isValidated
    isQqaDone
    isQaDone
    isEnabled
    curationStepCertainty
    miscAndChoiceStepCertainty
    fieldsUsedForUnknownWordsHighlights
    alternativeBrandName

    qaCorrectionCounts {
      ...QaDishCorrectionCountsFragment
    }

    brand {
      ...SimpleEntityFragment
    }

    servicingHours {
      ...ServicingHourFragment
    }

    menuTitle {
      ...SimpleEntityFragment
      description
      styledDescription
    }

    selectedLocations {
      locationId
      dishId
      name
      disabled
      overridenLowPrice
      overridenHighPrice
    }

    diets {
      id
      name
      usedAiSuggestionMethod
    }

    allergens {
      id
      name
      usedAiSuggestionMethod
    }

    dishType {
      ...SimpleEntityFragment
      courseTypeId
      isIgnored
    }

    aiSuggestions {
      mainIngredients {
        certainty
        messages {
          level
          message
        }
      }
      dishType {
        dishTypeId
        score
        certainty
        name
        matchType
        definiteResult
        messages {
          level
          message
        }
      }
      courseType {
        courseTypeId
        score
        certainty
        name
        matchType
        definiteResult
        messages {
          level
          message
        }
      }
      diets {
        certainty
        predictions {
          dietId
          label
          occurrences {
            dishId
            menuTitleId
            word
            posIndex
            score
            originField
          }
        }
        messages {
          level
          message
        }
      }
      allergens {
        certainty
        predictions {
          allergenId
          label
          occurrences {
            dishId
            menuTitleId
            word
            posIndex
            score
            originField
          }
        }
        messages {
          level
          message
        }
      }

      miscAndChoice {
        certainty
        messages {
          level
          message
        }
        name {
          text
          score
        }
        description {
          text
          score
        }
        ingredientsText {
          text
          score
        }
      }
    }

    courseType {
      ...SimpleEntityFragment
      dishTypeId
      isIgnored
    }

    updatedBy {
      ...SimpleUserFragment
    }

    mainIngredients {
      ...SimpleEntityFragment
      dishId
      originField
      word
      matchType
      isSuggestedByAi
      alternativeType
      replaces
      usedFeature
      posIndex
      isSuggestedUsingMl
      isSuggestedUsingParser
      mlScore
      isDiscrepancyAccepted
      keyword

      overrides {
        word
        originField
        posIndex
      }

      alreadyFoundIn {
        word
        originField
        posIndex
        matchType
        usedFeature
      }
    }
    additionalIngredients {
      ...SimpleEntityFragment
      dishId
      ingredientType
      isManuallyAdded

      overrides {
        word
        originField
      }
    }

    excludedIngredients {
      id
      ingredientId
      dishId
      word
      originField
      isSuggestedUsingMl
      isSuggestedUsingParser
      mlScore
      matchType
    }

    choiceIngredients {
      ...SimpleEntityFragment
      dishId
      originField
      word
      matchType
      alternativeType
      isSuggestedByAi
      replaces
      usedFeature
      posIndex
      isSuggestedUsingMl
      isSuggestedUsingParser
      mlScore
      isDiscrepancyAccepted
      keyword

      alreadyFoundIn {
        word
        originField
        posIndex
        matchType
        usedFeature
      }
    }

    curatedAt
    qadAt
    qqadAt
    curatedBy {
      ...SimpleUserFragment
    }
    qadBy {
      ...SimpleUserFragment
    }
    qqadBy {
      ...SimpleUserFragment
    }
  }
  ${SERVICING_HOUR_FRAGMENT}
  ${SIMPLE_ENTITY_FRAGMENT}
  ${SIMPLE_USER_FRAGMENT}
  ${QA_DISH_CORRECTION_COUNTS_FRAMGNET}
`

export default BRAND_DISH_FRAGMENT
