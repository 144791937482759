import { join } from 'path'

import React from 'react'
import { Link } from 'react-router-dom'
import { Row, TreeSelect, Typography } from 'antd'

import { GridFormItem } from '../../../../core/components'
import { formatDate } from '../../../../core/utils'

import { DebouncedTextField } from '../../../../common/components'
import { APP_ROUTES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import { locationColumnWidths } from '../../../constants'
import { usePersistedLocationField } from '../../../hooks'
import { locationPropType } from '../../../propTypes'
import {
  setCuisineTypeId,
  setLocationEmail,
  setLocationName,
  setLocationPhone,
  setLocationTypeId,
  setLocationWebsite,
} from '../../../redux'

const styles = {
  locationMetadata: {
    width: locationColumnWidths.locationMetadata,
  },
  updatedText: {
    lineHeight: '32px',
  },
}

const LocationEntryDetails = ({ location }) => {
  const {
    id: locationId,
    locationType,
    cuisineType,
    name,
    phone,
    email,
    web,
    brand: {
      id: brandId,
      name: brandName,
      locationType: brandLocationType,
      cuisineType: brandCuisineType,
    },
    updatedAt,
    updatedBy,
  } = location
  const { locationTypeOptions, cuisineTypeOptions } = useCurationMetadata()
  const { name: persistedName, onValueChanged: nameOnChange } =
    usePersistedLocationField(locationId, 'name', setLocationName)

  const {
    cuisineTypeId: persistedCuisineTypeId,
    onValueChanged: cuisineTypeIdOnChange,
  } = usePersistedLocationField(locationId, 'cuisineTypeId', setCuisineTypeId)
  const { email: persistedEmail, onValueChanged: emailOnChange } =
    usePersistedLocationField(locationId, 'email', setLocationEmail)

  const { phone: persistedPhone, onValueChanged: phoneOnChange } =
    usePersistedLocationField(locationId, 'phone', setLocationPhone)

  const {
    locationTypeId: persistedLocationTypeId,
    onValueChanged: locationTypeIdOnChange,
  } = usePersistedLocationField(locationId, 'locationTypeId', setLocationTypeId)
  const { web: persistedWeb, onValueChanged: webOnChange } =
    usePersistedLocationField(locationId, 'web', setLocationWebsite)

  const { id: locationTypeId } = locationType || brandLocationType || {}
  const { id: cuisineTypeId } = cuisineType || brandCuisineType || {}

  return (
    <Row gutter={[16, 16]}>
      <GridFormItem span={4} label="Part of Brand">
        <Link to={join(APP_ROUTES.BRAND_ROUTES.BrandsPrefix, brandId)}>
          {brandName}
        </Link>
      </GridFormItem>

      <GridFormItem span={4} label="Location name">
        <DebouncedTextField
          text={name}
          newText={persistedName}
          onValueChanged={nameOnChange}
        />
      </GridFormItem>
      <GridFormItem span={4} label="Type">
        <TreeSelect
          value={persistedLocationTypeId || locationTypeId}
          onChange={locationTypeIdOnChange}
          treeData={locationTypeOptions}
        />
      </GridFormItem>
      <GridFormItem span={4} label="Cuisine type">
        <TreeSelect
          treeData={cuisineTypeOptions}
          value={persistedCuisineTypeId || cuisineTypeId}
          onChange={cuisineTypeIdOnChange}
        />
      </GridFormItem>
      <GridFormItem span={4} label="Phone (booking)">
        <DebouncedTextField
          text={phone}
          newText={persistedPhone}
          onValueChanged={phoneOnChange}
        />
      </GridFormItem>
      <GridFormItem span={4} label="Email (booking)">
        <DebouncedTextField
          text={email}
          newText={persistedEmail}
          onValueChanged={emailOnChange}
        />
      </GridFormItem>
      <GridFormItem span={7} label="Website">
        <DebouncedTextField
          text={web}
          newText={persistedWeb}
          onValueChanged={webOnChange}
        />
      </GridFormItem>
      {updatedAt && (
        <GridFormItem span={6} label="Updated">
          <Typography.Text style={styles.updatedText}>{`${formatDate(
            updatedAt,
          )}${updatedBy ? ` - ${updatedBy.email}` : ''}`}</Typography.Text>
        </GridFormItem>
      )}
    </Row>
  )
}

LocationEntryDetails.propTypes = {
  location: locationPropType,
}

LocationEntryDetails.defaultProps = {
  location: {},
}

export default LocationEntryDetails
