import React, { memo, useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { WarningOutlined } from '@ant-design/icons'
import { Button, Col, Row, Tooltip } from 'antd'
import { get, isNil, some } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components/atoms'
import { useShallowEqualSelector } from '../../../../core/hooks'
import { areSameValue } from '../../../../core/utils'

import { StickyNodesTreeSelect } from '../../../../common/components'
import { EVENT_TYPES } from '../../../../common/constants'
import {
  getSuggestedByAiTreeData,
  handleMutationError,
} from '../../../../common/helpers'
import { useAppLoading } from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'
import { findNodeByValue } from '../../../../common/utils'

import { useUpsertBrand } from '../../../hooks'
import { suggestCuisineType } from '../../../services'
import { brandTransformer } from '../../../transformers'

const styles = {
  select: { width: '100%' },
  dropdown: { maxHeight: 400, minWidth: 250 },
  errorProneIcon: {
    marginRight: 6,
    marginBottom: 9,
    float: 'right',
  },
}

const BrandCuisineTypeSelect = ({
  defaultValue,
  disabled,
  options,
  aiSuggestions,
  isQaCorrected,
  isErrorProne,
}) => {
  const { brandId } = useParams()
  const { startLoading, stopLoading, isLoading } = useAppLoading()
  const upsertBrand = useUpsertBrand({ brandId })

  const {
    state: { isDirty: isMenuTitlesDirty },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const {
    field: { onChange, onBlur, value },
    formState: { isDirty, isSubmitting },
  } = useController({ name: 'cuisineTypeId', defaultValue })

  const stickyNodes = useMemo(
    () => getSuggestedByAiTreeData(options, aiSuggestions?.cuisineType || []),
    [options, aiSuggestions],
  )

  const isSuggestedByAi = useMemo(
    () =>
      some(aiSuggestions?.cuisineType, aiSuggestion =>
        areSameValue(aiSuggestion.cuisineTypeId, defaultValue),
      ),
    [aiSuggestions, defaultValue],
  )

  const selectHighlight = useMemo(() => {
    if (isQaCorrected) {
      return 'highlight-red'
    }
    if (isSuggestedByAi) {
      return 'highlight-blue'
    }

    return null
  }, [isSuggestedByAi, isQaCorrected])

  const handleOnSuggest = useCallback(async () => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.BRAND_EVENTS.SUGGEST_CUISINE_TYPE_CLICK,
    )
    startLoading()
    const result = await suggestCuisineType({ brandId })

    if (!isNil(result)) {
      try {
        await upsertBrand(
          brandTransformer.transformToSaveBrandArgs(
            { id: brandId, cuisineTypeId: result.cuisineTypeId },
            result.aiSuggestions,
          ),
        )
      } catch (error) {
        handleMutationError(error)
      }
    }

    stopLoading()
  }, [brandId, startLoading, stopLoading, upsertBrand])

  const handleOnChange = useCallback(
    newValue => onChange(isNil(newValue) ? null : newValue),
    [onChange],
  )

  const selectedNode = useMemo(
    () => findNodeByValue(options, value),
    [options, value],
  )

  return (
    <Row justify="space-between" align="bottom">
      {isErrorProne && (
        <Col span={2}>
          <Tooltip title="This cusine type has been marked as error prone">
            <WarningOutlined style={styles.errorProneIcon} />
          </Tooltip>
        </Col>
      )}
      <Col span={isErrorProne ? 16 : 18}>
        <GridFormItem style={styles.select} label="Cuisine Type" labelAbove>
          <Tooltip title={selectedNode?.namePath}>
            <StickyNodesTreeSelect
              className={selectHighlight}
              style={styles.select}
              value={value}
              placeholder="Select..."
              treeNodeFilterProp="title"
              stickyNodes={stickyNodes}
              dropdownStyle={styles.dropdown}
              disabled={disabled}
              onChange={handleOnChange}
              onBlur={onBlur}
              treeData={options}
              size="medium"
            />
          </Tooltip>
        </GridFormItem>
      </Col>
      <Col span={6}>
        <Button
          loading={isSubmitting || isLoading}
          // we disable the cusine type suggestion for now
          disabled={
            true || !brandId || disabled || isDirty || isMenuTitlesDirty
          }
          onClick={handleOnSuggest}
        >
          Suggest
        </Button>
      </Col>
    </Row>
  )
}

BrandCuisineTypeSelect.propTypes = {
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  aiSuggestions: PropTypes.object,
  isQaCorrected: PropTypes.bool,
  isErrorProne: PropTypes.bool,
}

BrandCuisineTypeSelect.defaultProps = {
  defaultValue: undefined,
  options: [],
  disabled: false,
  aiSuggestions: undefined,
  isQaCorrected: false,
  isErrorProne: false,
}

export default memo(BrandCuisineTypeSelect)
