import React, { useCallback, useMemo } from 'react'
import { Row, Select } from 'antd'
import { isNil, keys, map, toString } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { useQueryParams } from '../../../../core/hooks'
import { selectFilterOption } from '../../../../core/utils'

import {
  DEFAULT_PAGINATION_CONFIG,
  SELECT_ALL_OPTION,
} from '../../../../common/constants'

import theme from '../../../../theme'
import {
  CLEANUP_TASK_STATUS,
  CLEANUP_TASK_TYPES,
  DEFAULT_CLEANUP_TASKS_FILTERS,
} from '../../../constants'

const styles = {
  row: {
    paddingBottom: theme.padding,
  },
  select: {
    width: '100%',
  },
}

const CleanupTasksFilters = ({ users }) => {
  const {
    status = DEFAULT_CLEANUP_TASKS_FILTERS.STATUS,
    type = DEFAULT_CLEANUP_TASKS_FILTERS.TYPE,
    assignedUserId = DEFAULT_CLEANUP_TASKS_FILTERS.ASSIGNED_USER,
    setQueryParams,
  } = useQueryParams()

  const usersOptions = useMemo(
    () => map(users, ({ id, name }) => ({ label: name, value: id })),
    [users],
  )

  const assignedUser = useMemo(
    () => (isNil(assignedUserId) ? null : toString(assignedUserId)),
    [assignedUserId],
  )

  const typeOptions = useMemo(
    () => [
      SELECT_ALL_OPTION,
      ...map(keys(CLEANUP_TASK_TYPES), typeName => ({
        label: CLEANUP_TASK_TYPES[typeName],
        value: typeName,
      })),
    ],
    [],
  )

  const statusOptions = useMemo(
    () => [
      SELECT_ALL_OPTION,
      ...map(keys(CLEANUP_TASK_STATUS), statusName => ({
        label: CLEANUP_TASK_STATUS[statusName],
        value: CLEANUP_TASK_STATUS[statusName],
      })),
    ],
    [],
  )

  const handleFiltersChange = useCallback(
    filterName => filterValue => {
      setQueryParams({
        [filterName]: filterValue,
        page: DEFAULT_PAGINATION_CONFIG.PAGE,
      })
    },
    [setQueryParams],
  )

  return (
    <Row style={styles.row} gutter={16}>
      <GridFormItem span={3} label="Assigned">
        <Select
          showSearch
          filterOption={selectFilterOption}
          allowClear
          value={assignedUser}
          placeholder="Filter by User..."
          onChange={handleFiltersChange('assignedUserId')}
          style={styles.select}
          options={usersOptions}
        />
      </GridFormItem>
      <GridFormItem span={3} label="Task type">
        <Select
          value={type}
          onChange={handleFiltersChange('type')}
          style={styles.select}
          options={typeOptions}
        />
      </GridFormItem>
      <GridFormItem span={3} label="Status">
        <Select
          value={status}
          onChange={handleFiltersChange('status')}
          style={styles.select}
          options={statusOptions}
        />
      </GridFormItem>
    </Row>
  )
}

CleanupTasksFilters.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
}

CleanupTasksFilters.defaultProps = {
  users: [],
}

export default CleanupTasksFilters
