export const AI_SUGGESTIONS_HIGHLIGHT_COLORS = Object.freeze({
  NONE: 'none',
  BLUE: 'blue',
  ORANGE: 'orange',
  YELLOW: 'yellow',
})

export const HIGHLIGHT_PREFIX = 'highlight'

export const ALTERNATIVE_MATCH_CLASSNAME = 'alternativeTypeMatch'
