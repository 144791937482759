import React, { memo } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'
import update from 'immutability-helper'
import { filter, find, includes, isEmpty, map, uniq } from 'lodash'
import PropTypes from 'prop-types'

import { idNew } from '../../../constants'
import { servicingHourPropType } from '../../../propTypes'
import { formatServicingHours } from '../../../utils'

const styles = {
  nonFirstDropdown: {
    marginLeft: 5,
  },
  addButton: {
    marginLeft: 5,
  },
  dropdown: {
    width: '200px',
  },
}

const AlwaysServicingHourId = 'always'
const AlwaysServicingHour = { id: AlwaysServicingHourId, name: 'Always' }

const ServicingHoursSelect = ({
  parentServicingHours,
  servicingHours,
  newServicingHoursIds,
  onValueChanged,
  disabled,
  size,
}) => {
  const servicingHoursIds = map(servicingHours, 'id')
  const dishServicingHoursIds = uniq(newServicingHoursIds || servicingHoursIds)
  const allServicingHours = map(parentServicingHours, formatServicingHours)
  const availableServicingHours = filter(
    allServicingHours,
    ({ id }) => !includes(dishServicingHoursIds, id),
  )
  const availableServicingHoursWithAlways = [
    AlwaysServicingHour,
    ...availableServicingHours,
  ]
  const addButtonVisible =
    !isEmpty(availableServicingHours) && !includes(dishServicingHoursIds, idNew)

  if (!isEmpty(dishServicingHoursIds)) {
    return (
      <>
        {map(dishServicingHoursIds, (servicingHourId, index) => {
          const selectedServicingHour = find(
            allServicingHours,
            ({ id }) => id === servicingHourId,
          )
          // "Always" should be visible only in the first dropdown
          const servicingHoursInDropdown = filter(
            index
              ? [...availableServicingHours, selectedServicingHour]
              : [...availableServicingHoursWithAlways, selectedServicingHour],
            Boolean,
          )

          return (
            <Select
              size={size}
              allowClear
              key={servicingHourId}
              defaultValue={selectedServicingHour && servicingHourId}
              placeholder="Select time..."
              style={
                index
                  ? { ...styles.nonFirstDropdown, ...styles.dropdown }
                  : { ...styles.dropdown }
              }
              onChange={value => {
                if (value === AlwaysServicingHourId) {
                  // Remove every selection if "Always" is selected in the first dropdown
                  return onValueChanged([])
                }

                const newDishServicingHours = value
                  ? update(dishServicingHoursIds, { [index]: { $set: value } })
                  : update(dishServicingHoursIds, { $splice: [[index, 1]] }) // "Clear" button was clicked
                return onValueChanged(newDishServicingHours)
              }}
              disabled={disabled}
            >
              {map(servicingHoursInDropdown, ({ id, name }) => (
                <Select.Option key={id}>{name}</Select.Option>
              ))}
            </Select>
          )
        })}
        {addButtonVisible && (
          <Button
            size="small"
            icon={<PlusOutlined />}
            shape="circle"
            style={styles.addButton}
            onClick={() => onValueChanged([...dishServicingHoursIds, idNew])}
            disabled={disabled}
          />
        )}
      </>
    )
  }

  return (
    <Select
      size={size}
      defaultValue={AlwaysServicingHourId}
      style={styles.dropdown}
      onSelect={value => {
        if (value !== AlwaysServicingHourId) {
          onValueChanged([value])
        }
      }}
      disabled={disabled}
    >
      {map(availableServicingHoursWithAlways, ({ id, name }) => (
        <Select.Option key={id}>{name}</Select.Option>
      ))}
    </Select>
  )
}

ServicingHoursSelect.propTypes = {
  parentServicingHours: PropTypes.arrayOf(servicingHourPropType).isRequired,
  servicingHours: PropTypes.arrayOf(servicingHourPropType).isRequired,
  newServicingHoursIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
}

ServicingHoursSelect.defaultProps = {
  newServicingHoursIds: null,
  disabled: false,
  size: undefined,
}

export default memo(ServicingHoursSelect)
