import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { get, size, some } from 'lodash'

import { MutationCheckbox, ReadMore } from '../../common/components/molecules'
import { METADATA_TYPES } from '../../common/constants'
import { getRouteOfBrand, getRouteOfDish } from '../../common/helpers'
import { formatDescriptors } from '../../common/utils'

import { DishMenuTitleDetailsCell } from '../../dishes/components/molecules'
import { formatDishPrice } from '../../dishes/helpers'
import {
  CleanupBatchSelectCheckbox,
  CleanupTreeSelect,
} from '../components/molecules'
import { CLEANUP_METADATA_FIELDS } from '../constants'
import {
  UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION,
  UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION,
  UPDATE_HUMAN_CLEANUP_QA_DONE_MUTATION,
} from '../graphql'

const METADATA_CLEANUP_TITLES = {
  [METADATA_TYPES.INGREDIENTS]: 'New Ingredients',
  [METADATA_TYPES.DISH_TYPES]: 'New Dish type',
  [METADATA_TYPES.COURSE_TYPES]: 'New Course type',
  [METADATA_TYPES.LOCATION_TYPES]: 'New Location type',
  [METADATA_TYPES.CUISINE_TYPES]: 'New Cuisine type',
}

const styles = {
  select: {
    width: '100%',
  },
}

const computeCleanupTaskTableColumns = ({
  allItemIds,
  areAllItemsCurationDone,
  areAllItemsQaDone,
  curationMetadata,
  disabled,
  handleSelectAll,
  isDishMetadata,
  isTaskCurationDone,
  isTaskQaDone,
  metadataType,
  onItemSelect,
  selectedItems,
  taskId,
  isMenuTitleDescriptorsShown,
  isDishDescriptorsShown,
}) => [
  {
    title: !isTaskCurationDone ? (
      <MutationCheckbox
        ids={allItemIds}
        checked={areAllItemsCurationDone}
        mutation={UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION}
        mutationAdditionalInfo={{ metadataType, taskId }}
        tooltip="Set all curation status"
        disabled={disabled}
      />
    ) : (
      <MutationCheckbox
        ids={allItemIds}
        checked={areAllItemsQaDone}
        mutation={UPDATE_HUMAN_CLEANUP_QA_DONE_MUTATION}
        mutationAdditionalInfo={{ metadataType, taskId }}
        tooltip="Set all qa status"
        disabled={disabled || isTaskQaDone}
      />
    ),
    width: '40px',
    render: ({ id, isCurationDone, isQaDone }) =>
      !isTaskCurationDone ? (
        <MutationCheckbox
          ids={[id]}
          checked={isCurationDone}
          mutation={UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION}
          mutationAdditionalInfo={{ metadataType, taskId }}
          tooltip="Set curation status"
          disabled={disabled}
        />
      ) : (
        <MutationCheckbox
          ids={[id]}
          checked={isQaDone}
          mutation={UPDATE_HUMAN_CLEANUP_QA_DONE_MUTATION}
          mutationAdditionalInfo={{ metadataType, taskId }}
          tooltip="Set QA status"
          disabled={disabled || isTaskQaDone}
        />
      ),
  },
  ...(!isDishMetadata
    ? [
        {
          title: 'Brand name',
          width: '100px',
          render: ({ brand }) => {
            const route = getRouteOfBrand(brand.id)
            return (
              <Link target="_blank" to={route}>
                {brand.name}
              </Link>
            )
          },
        },
      ]
    : [
        {
          title: 'Dish name',
          width: '150px',
          render: ({ brand, dish }) => {
            const route = getRouteOfDish(brand.id, dish.id)

            return (
              <Link
                target="_blank"
                to={route}
                style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {dish.name}
              </Link>
            )
          },
        },
        {
          title: 'Dish description',
          width: '100px',
          render: ({ dish }) => dish.description,
        },
        {
          title: 'ingredients text',
          width: '100px',
          render: ({ dish }) => dish.ingredientsText,
        },
        {
          title: 'Price',
          width: '50px',
          render: ({ dish }) => formatDishPrice(dish),
        },
        {
          title: 'Menu title',
          width: '100px',
          render: ({ dish }) => dish?.menuTitle?.name,
        },
        {
          title: 'Menu title description',
          width: '100px',
          render: ({ dish }) => (
            <DishMenuTitleDetailsCell menuTitles={dish.menuTitleParents} />
          ),
        },
        ...(isMenuTitleDescriptorsShown
          ? [
              {
                title: 'Menu Title Addon Descriptors',
                width: '100px',
                render: ({ dish }) => (
                  <DishMenuTitleDetailsCell
                    menuTitles={dish.menuTitleParents}
                    fieldName="menuTitleAddonDescriptors"
                  />
                ),
              },
            ]
          : []),
        ...(isDishDescriptorsShown
          ? [
              {
                title: 'Dish Addon Descriptors',
                width: '100px',
                render: ({ dish }) => (
                  <ReadMore
                    maxHeight={170}
                    component={
                      <Typography.Text>
                        {formatDescriptors(dish?.addonDescriptors)}
                      </Typography.Text>
                    }
                  />
                ),
              },
            ]
          : []),
      ]),
  {
    title: (
      <CleanupBatchSelectCheckbox
        isSelectAll
        checked={size(allItemIds) === size(selectedItems)}
        onChange={handleSelectAll}
        disabled={disabled}
      />
    ),
    width: '30px',
    render: ({ id }) => (
      <CleanupBatchSelectCheckbox
        id={id}
        checked={some(selectedItems, selectedItem => selectedItem === id)}
        onChange={() => onItemSelect(id)}
        disabled={disabled}
      />
    ),
  },
  {
    title: get(METADATA_CLEANUP_TITLES, metadataType),
    width: '130px',
    render: cleanup => {
      if (metadataType === METADATA_TYPES.INGREDIENTS) {
        return (
          <CleanupTreeSelect
            style={styles.select}
            ids={[cleanup.id]}
            multiple
            defaultValue={cleanup.cleanupIngredientIds}
            fieldName={get(CLEANUP_METADATA_FIELDS, metadataType)}
            metadataType={metadataType}
            mutation={UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION}
            treeData={get(curationMetadata, metadataType)}
            disabled={disabled}
            isTaskCurationDone={isTaskCurationDone}
          />
        )
      }

      const cleanupValues = {
        [METADATA_TYPES.DISH_TYPES]:
          cleanup?.cleanupDishTypeId || cleanup?.dish?.dishType?.id,
        [METADATA_TYPES.COURSE_TYPES]:
          cleanup?.cleanupCourseTypeId || cleanup?.dish?.courseType?.id,
        [METADATA_TYPES.LOCATION_TYPES]:
          cleanup?.cleanupLocationTypeId || cleanup?.brand?.locationType?.id,
        [METADATA_TYPES.CUISINE_TYPES]:
          cleanup?.cleanupCuisineTypeId || cleanup?.brand?.cuisineType?.id,
      }

      const currentValue = get(cleanupValues, metadataType)

      return (
        <CleanupTreeSelect
          style={styles.select}
          ids={[cleanup.id]}
          defaultValue={currentValue}
          fieldName={get(CLEANUP_METADATA_FIELDS, metadataType)}
          metadataType={metadataType}
          mutation={UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION}
          treeData={get(curationMetadata, metadataType)}
          disabled={disabled}
          isTaskCurationDone={isTaskCurationDone}
        />
      )
    },
  },
  ...(isDishMetadata
    ? [
        {
          title: 'Brand name',
          width: '100px',
          render: ({ brand }) => {
            const route = getRouteOfBrand(brand.id)
            return (
              <Link target="_blank" to={route}>
                {brand.name}
              </Link>
            )
          },
        },
      ]
    : []),
]

export default computeCleanupTaskTableColumns
