import { gql } from '@apollo/client'

import { AI_SETTING_FRAGMENT } from '../fragments'

const AI_SETTINGS_QUERY = gql`
  query aiSettingsQuery {
    aiSettings {
      ...AiSettingFragment
    }
  }
  ${AI_SETTING_FRAGMENT}
`

export default AI_SETTINGS_QUERY
