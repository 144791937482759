import { filter, find, map } from 'lodash'

import {
  UNSAVED_LOCATION_ACTION_TYPES,
  UNSAVED_LOCATION_DISHES_ACTION_TYPES,
} from '../actionTypes'

const updateLocationDishProp = (
  locationDishes,
  dishId,
  locationId,
  propName,
  propValue,
) => {
  const existingLocationDish = find(
    locationDishes,
    ld => ld.id === dishId && ld.locationId === locationId,
  )
  if (existingLocationDish) {
    return map(locationDishes, ld =>
      ld === existingLocationDish
        ? { ...existingLocationDish, [propName]: propValue }
        : ld,
    )
  }

  return [...locationDishes, { id: dishId, locationId, [propName]: propValue }]
}

const unsavedLocationDishesReducer = (locationDishes = [], action) => {
  switch (action.type) {
    case UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_ENABLED: {
      const { id, locationId, value } = action.payload
      return updateLocationDishProp(
        locationDishes,
        id,
        locationId,
        'enabled',
        value,
      )
    }
    case UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_MIN_OVERRIDEN_PRICE: {
      const { id, locationId, value } = action.payload
      return updateLocationDishProp(
        locationDishes,
        id,
        locationId,
        'overridenLowPrice',
        value,
      )
    }
    case UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_MAX_OVERRIDEN_PRICE: {
      const { id, locationId, value } = action.payload
      return updateLocationDishProp(
        locationDishes,
        id,
        locationId,
        'overridenHighPrice',
        value,
      )
    }
    case UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_SERVICING_HOURS_IDS: {
      const { id, locationId, value } = action.payload
      return updateLocationDishProp(
        locationDishes,
        id,
        locationId,
        'servicingHoursIds',
        value,
      )
    }
    case UNSAVED_LOCATION_ACTION_TYPES.REMOVE_LOCATION: {
      return filter(
        locationDishes,
        ({ locationId: dishLocationId }) => dishLocationId !== action.payload,
      )
    }
    default: {
      return locationDishes
    }
  }
}

export default unsavedLocationDishesReducer
