import { gql } from '@apollo/client'

import { BRAND_SUGGESTED_WORD_FRAGMENT } from '../fragments'

const BRAND_SUGGESTED_WORDS_QUERY = gql`
  query brandSuggestedWordsQuery(
    $brandId: ID!
    $pagination: PaginationInput!
    $sortBy: BrandSuggestedWordsSortOptionEnumType!
  ) {
    brandSuggestedWords(
      brandId: $brandId
      pagination: $pagination
      sortBy: $sortBy
    ) {
      ...BrandSuggestedWordFragment
    }
  }
  ${BRAND_SUGGESTED_WORD_FRAGMENT}
`

export default BRAND_SUGGESTED_WORDS_QUERY
