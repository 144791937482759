import { EVENT_TYPES } from '../../../common/constants'
import { googleAnalyticsEventsService } from '../../../common/services'

import { SET_DISH_FIELD_ACTION_TYPES } from '../actionTypes'

const updateDish = ({ state, id, propName, propValue = null }) => {
  googleAnalyticsEventsService.fireEvent(
    EVENT_TYPES.BRAND_DISH_EVENTS.INPUT_CHANGE,
    { input: propName },
  )

  return {
    ...state,
    [id]: {
      ...state[id],
      [propName]: propValue,
    },
  }
}

const updateDishProp = ({ state, payload, propName }) => {
  const { id, value: propValue, ...rest } = payload
  return updateDish({ state, id, ...rest, propName, propValue })
}

export default function setDishFieldReducer(state = {}, action) {
  switch (action.type) {
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_NAME: {
      const { text, styledText } = action.payload.value
      const updatedState = updateDishProp({
        state,
        payload: {
          ...action.payload,
          value: text,
        },
        propName: 'name',
      })
      return updateDishProp({
        state: updatedState,
        payload: {
          ...action.payload,
          value: styledText,
        },
        propName: 'styledName',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_DIET_IDS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'dietIds',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_ALLERGEN: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'allergenIds',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_LOW_PRICE: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'lowPrice',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_HIGH_PRICE: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'highPrice',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_DISH_TYPE_ID: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'dishTypeId',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_COURSE_TYPE_ID: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'courseTypeId',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_MAIN_INGREDIENTS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'mainIngredients',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_ADDITIONAL_INGREDIENTS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'additionalIngredients',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_INGREDIENTS_TEXT: {
      const { text, styledText } = action.payload.value
      const updatedState = updateDishProp({
        state,
        payload: {
          ...action.payload,
          value: text,
        },
        propName: 'ingredientsText',
      })
      return updateDishProp({
        state: updatedState,
        payload: {
          ...action.payload,
          value: styledText,
        },
        propName: 'styledIngredientsText',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_MENU_TITLE_ID: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'menuTitleId',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_DESCRIPTION: {
      const { text, styledText } = action.payload.value
      const updatedState = updateDishProp({
        state,
        payload: {
          ...action.payload,
          value: text,
        },
        propName: 'description',
      })
      return updateDishProp({
        state: updatedState,
        payload: {
          ...action.payload,
          value: styledText,
        },
        propName: 'styledDescription',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_SERVICING_HOURS_IDS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'servicingHoursIds',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_EXCLUDED_INGREDIENTS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'excludedIngredients',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_CHOICE_INGREDIENTS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'choiceIngredients',
      })
    }
    case SET_DISH_FIELD_ACTION_TYPES.SET_DISH_SELECTED_LOCATIONS: {
      return updateDishProp({
        state,
        payload: action.payload,
        propName: 'selectedLocations',
      })
    }
    default: {
      return state
    }
  }
}
