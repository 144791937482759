import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

const StyledPageTitle = ({ children }) => (
  <Typography.Title
    level={3}
    style={{
      marginBottom: 0,
      textTransform: 'capitalize',
      fontSize: '24px',
    }}
  >
    {children}
  </Typography.Title>
)

StyledPageTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StyledPageTitle
