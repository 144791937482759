import React from 'react'
import { TreeSelect } from 'antd'
import { map, some } from 'lodash'

import { TreeSelectNodeTitle } from '../components/molecules/TreeSelectNodeTitle'

const renderTreeSelectNodes = (treeData = [], stickyTreeNodes = []) =>
  map(treeData, node => {
    const nodeElement = (
      <TreeSelect.TreeNode
        key={node.id}
        value={node.value}
        disabled={node.disabled}
        title={
          <TreeSelectNodeTitle title={node.title} isSticky={node.isSticky} />
        }
      >
        {renderTreeSelectNodes(node.children, stickyTreeNodes)}
      </TreeSelect.TreeNode>
    )

    return (
      (node.isSticky || !some(stickyTreeNodes, ({ id }) => id === node.id)) &&
      nodeElement
    )
  })

export default renderTreeSelectNodes
