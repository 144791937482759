import { join } from 'path'

import { APP_ROUTES } from '../constants'

const getRouteOfLocation = (brandId, locationId) =>
  join(
    APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
    brandId,
    APP_ROUTES.LOCATION_ROUTES.LocationsPrefix,
    locationId,
  )

export default getRouteOfLocation
