import React, { memo, useCallback, useContext } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Checkbox } from 'antd'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

import { stylePropType } from '../../../../common/propTypes'

import { AVAILABLE_HOTKEYS } from '../../../constants'
import { CleanupTableFocusActionsContext } from '../../../contexts'

const CleanupBatchSelectCheckbox = ({
  id,
  checked,
  style,
  onChange,
  isSelectAll,
  disabled,
}) => {
  const { selectedRowKey, hotkeyEditMode } = useContext(
    CleanupTableFocusActionsContext,
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.R.hotkey,
    () => onChange(!checked),
    {
      enabled: !hotkeyEditMode && selectedRowKey === id && !disabled,
    },
    [selectedRowKey, hotkeyEditMode, onChange, id, checked, disabled],
  )
  useHotkeys(
    AVAILABLE_HOTKEYS.SHIFT_R.hotkey,
    () => onChange(!checked),
    {
      enabled: !hotkeyEditMode && isSelectAll && !disabled,
    },
    [onChange, isSelectAll, hotkeyEditMode, checked, disabled],
  )

  const handleChange = useCallback(
    ({ target: { checked: newValue } }) => onChange(newValue),
    [onChange],
  )

  return (
    <Checkbox
      className="big-checkbox"
      style={style}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  )
}

CleanupBatchSelectCheckbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  style: stylePropType,
  onChange: PropTypes.func,
  isSelectAll: PropTypes.bool,
  disabled: PropTypes.bool,
}

CleanupBatchSelectCheckbox.defaultProps = {
  id: undefined,
  checked: false,
  style: undefined,
  isSelectAll: false,
  onChange: noop,
  disabled: false,
}

export default memo(CleanupBatchSelectCheckbox)
