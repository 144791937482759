import { gql } from '@apollo/client'

const DELETE_BRAND_MUTATION = gql`
  mutation deleteBrand($id: ID!) {
    deleteBrand(id: $id) {
      success
    }
  }
`

export default DELETE_BRAND_MUTATION
