import { gql } from '@apollo/client'

import { USER_MANAGEMENT_FRAGMENT } from '../fragments'

export default gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      ...UserManagementFragment
    }
  }
  ${USER_MANAGEMENT_FRAGMENT}
`
