const BRAND_SUGGESTED_WORD_TYPE_OPTIONS = Object.freeze({
  UNKNOWN: 'Unknown',
  PREPARATION_METHOD: 'Preparation Method',
  COMMON_ENGLISH_WORD: 'Common English Word',
  COMMON_FOREIGN_WORD: 'Common Foreign Word',
  WHOLE_DISH_NAME: 'Whole Dish Name',
  NAME: 'Name',
  NOT_RELEVANT: 'Not Relevant',
  NEW_INGREDIENT: 'New Ingredient',
  EXISTING_INGREDIENT: 'Existing Ingredient',
  DIET_LABEL: 'Diet Label Legend',
  ALLERGEN_LABEL: 'Allergen Label Legend',
})

export default BRAND_SUGGESTED_WORD_TYPE_OPTIONS
