import { flatten, map } from 'lodash'

const getTreeIdsUpToLevel = (nodes, currentLevel, maxLevel) => {
  if (currentLevel === maxLevel) {
    return map(nodes, 'id')
  }

  return flatten(
    map(nodes, node => [
      node.id,
      ...getTreeIdsUpToLevel(node.children, currentLevel + 1, maxLevel),
    ]),
  )
}

export default getTreeIdsUpToLevel
