import React, { useContext, useMemo } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

import { useUserHasBrandChangePermission } from '../../../../common/hooks'

import { DishPopoverTableFocusActionsContext } from '../../../contexts'
import { DishIngredientsPopoverIngredient } from '../DishIngredientsPopoverIngredient'
import { DishIngredientsPopoverMenuText } from '../DishIngredientsPopoverMenuText'

const DishIngredientsPopoverTable = ({
  data,
  fieldName,
  dishId,
  isIngredientsDiscrepancyCheckboxVisible,
}) => {
  const { selectedRowKey } = useContext(DishPopoverTableFocusActionsContext)
  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const columns = useMemo(
    () => [
      {
        title: 'Menu text',
        dataIndex: 'menuText',
        key: 'menuText',
        render: (
          _,
          { menuText, highlight, isSuggestedUsingMl, isSuggestedUsingParser },
        ) => (
          <DishIngredientsPopoverMenuText
            menuText={menuText}
            highlight={highlight}
            isSuggestedUsingParser={isSuggestedUsingParser}
            isSuggestedUsingMl={isSuggestedUsingMl}
          />
        ),
      },
      {
        title: 'Ingredient',
        dataIndex: 'name',
        key: 'name',
        render: (
          _,
          {
            name,
            highlight,
            isDiscrepancyAccepted,
            isSuggestedUsingMl,
            isSuggestedUsingParser,
            id,
          },
        ) => (
          <DishIngredientsPopoverIngredient
            id={id}
            name={name}
            dishId={dishId}
            fieldName={fieldName}
            highlight={highlight}
            isDiscrepancyAccepted={isDiscrepancyAccepted}
            isSuggestedUsingMl={isSuggestedUsingMl}
            isSuggestedUsingParser={isSuggestedUsingParser}
            isCheckboxDisabled={!userHasBrandChangePermission}
            isCheckboxVisible={isIngredientsDiscrepancyCheckboxVisible}
            isSelected={id === selectedRowKey}
          />
        ),
      },
    ],
    [
      dishId,
      fieldName,
      isIngredientsDiscrepancyCheckboxVisible,
      selectedRowKey,
      userHasBrandChangePermission,
    ],
  )

  return (
    <Table
      rowKey={({ id }) => id}
      className="dish-popover-table"
      dataSource={data}
      columns={columns}
      size="small"
      pagination={false}
    />
  )
}

DishIngredientsPopoverTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fieldName: PropTypes.string.isRequired,
  dishId: PropTypes.string.isRequired,
  isIngredientsDiscrepancyCheckboxVisible: PropTypes.bool,
}

DishIngredientsPopoverTable.defaultProps = {
  data: [],
  isIngredientsDiscrepancyCheckboxVisible: false,
}

export default DishIngredientsPopoverTable
