import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { LinkOutlined, MinusOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Select } from 'antd'
import { get, isNil, map, uniq } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams, useShallowEqualSelector } from '../../../../core/hooks'
import { areSameValue } from '../../../../core/utils'

import { LABEL_LEGENDS_MATCH_TYPE } from '../../../../brands/constants'
import {
  setExpandedRowKeys,
  setHighlightedRowKey,
} from '../../../../menuTitles/redux'
import {
  BRAND_ENTITY_LINK_ORIGIN_TYPE,
  LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE,
} from '../../../constants'
import { labelLegendPropType } from '../../../propTypes'
import { findNodeBy } from '../../../utils'

const styles = {
  legendDropdown: {
    width: '100%',
  },
  matchType: {
    color: '#cccecf',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}

const LabelLegend = ({
  labelLegend,
  options,
  onValueChanged,
  disabled,
  size,
  valueProp,
  labelProp,
  forEntity,
}) => {
  const dispatch = useDispatch()
  const { selectedDishId, setQueryParams } = useQueryParams()
  const legendIdKeyName = `${forEntity}Id`

  const {
    [legendIdKeyName]: legendId,
    label,
    isSuggestedByAi,
    highlights,
    conflictResolutionType,
    isChoice,
    matchType,
  } = labelLegend

  const { menuTitles, expandedRowKeys } = useShallowEqualSelector(state =>
    get(state, 'unsaved.unsavedMenuTitles'),
  )

  // We need to ensure that we don't change the filters and trigger a refetch of the whole brand
  const highlightLink = useMemo(() => {
    if (!isNil(selectedDishId)) {
      return null
    }

    if (!isNil(get(highlights, '[0].dishId'))) {
      return {
        idToOpen: get(highlights, '[0].dishId'),
        type: BRAND_ENTITY_LINK_ORIGIN_TYPE.DISH,
      }
    }

    if (!isNil(get(highlights, '[0].menuTitleId'))) {
      return {
        idToOpen: get(highlights, '[0].menuTitleId'),
        type: BRAND_ENTITY_LINK_ORIGIN_TYPE.MENU_TITLE,
      }
    }

    return null
  }, [highlights, selectedDishId])

  const mappedOptions = useMemo(
    () =>
      map(options, option => ({
        label: get(option, labelProp),
        value: get(option, valueProp),
      })),
    [labelProp, options, valueProp],
  )

  const className = useMemo(() => {
    if (
      conflictResolutionType ===
      LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE.UNKNOWN_WORDS
    ) {
      return 'highlight-red'
    }

    if (isChoice) {
      return 'highlight-green'
    }

    if (isSuggestedByAi) {
      return 'highlight-blue'
    }

    return null
  }, [conflictResolutionType, isChoice, isSuggestedByAi])

  const deleteLabelLegend = useCallback(() => {
    onValueChanged({ ...labelLegend, deleted: true })
  }, [labelLegend, onValueChanged])

  const handleOnSelect = useCallback(
    value => {
      onValueChanged({ ...labelLegend, [legendIdKeyName]: value })
    },
    [labelLegend, legendIdKeyName, onValueChanged],
  )

  const handleLabelOnChange = useCallback(
    e => {
      onValueChanged({ ...labelLegend, label: e.target.value })
    },
    [labelLegend, onValueChanged],
  )

  const handleOpenDishModal = useCallback(
    () => setQueryParams({ selectedDishId: highlightLink.idToOpen }),
    [highlightLink, setQueryParams],
  )

  const openMenuTitleTreeAtId = useCallback(() => {
    const menuTitleToOpen = findNodeBy(menuTitles, ({ id }) =>
      areSameValue(id, get(highlightLink, 'idToOpen')),
    )

    if (!isNil(menuTitleToOpen)) {
      dispatch(
        setExpandedRowKeys(
          uniq([
            ...expandedRowKeys,
            ...map(
              [...menuTitleToOpen.parents, menuTitleToOpen.id],
              menuTitleId => `menuTitle-${menuTitleId}`,
            ),
          ]),
        ),
      )
      dispatch(setHighlightedRowKey(`menuTitle-${menuTitleToOpen.id}`))
    }
  }, [dispatch, expandedRowKeys, highlightLink, menuTitles])

  return (
    <Row gutter={[8, 6]}>
      <Col span={4} style={styles.matchType}>
        {LABEL_LEGENDS_MATCH_TYPE[matchType] || ''}
      </Col>
      <Col span={8}>
        <Input
          size={size}
          disabled={disabled}
          value={label}
          className={className}
          placeholder="Legend label..."
          onChange={handleLabelOnChange}
        />
      </Col>
      <Col span={8}>
        <Select
          size={size}
          style={styles.legendDropdown}
          disabled={disabled}
          value={legendId}
          className={className}
          onSelect={handleOnSelect}
          options={mappedOptions}
        >
          {}
        </Select>
      </Col>
      {!disabled && (
        <Col span={2}>
          <Button
            size={size}
            icon={<MinusOutlined />}
            shape="circle"
            onClick={deleteLabelLegend}
          />
        </Col>
      )}
      {highlightLink &&
        (highlightLink.type === BRAND_ENTITY_LINK_ORIGIN_TYPE.DISH ? (
          <Col span={2}>
            <Button
              size={size}
              onClick={handleOpenDishModal}
              icon={<LinkOutlined />}
              shape="circle"
            />
          </Col>
        ) : (
          <Col span={2}>
            <Button
              size={size}
              icon={<LinkOutlined />}
              shape="circle"
              onClick={openMenuTitleTreeAtId}
            />
          </Col>
        ))}
    </Row>
  )
}

LabelLegend.propTypes = {
  forEntity: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelLegend: labelLegendPropType.isRequired,
  size: PropTypes.string,
  onValueChanged: PropTypes.func,
  disabled: PropTypes.bool,
  valueProp: PropTypes.string,
  labelProp: PropTypes.string,
}

LabelLegend.defaultProps = {
  onValueChanged: undefined,
  size: undefined,
  disabled: false,
  valueProp: 'value',
  labelProp: 'label',
}

export default LabelLegend
