import {
  filter,
  flatten,
  forEach,
  get,
  groupBy,
  includes,
  isEmpty,
  isNil,
  map,
  omit,
  reduce,
  sortBy,
} from 'lodash'

import { AI_INGREDIENT_MATCH_TYPE } from '../../common/constants'

/**
 * Function that gets a list of ingredients that were suggested by the ai (saved or not)
 * and the fields in which to highlight
 * @returns list of words grouped by field
 */
export default function extractHighlightListFromAiIngredients(
  aiIngredients,
  fields,
  additionalIngredients,
) {
  let groupedHighlightList = {}

  if (aiIngredients) {
    let aiSuggestedWords = []
    const fieldsToSearchIn = {
      ...fields,
    }

    const getStartIndexOfIngredient = (word, field, searchStartIndex = 0) => {
      if (isNil(fieldsToSearchIn[field])) {
        return null
      }

      const start = fieldsToSearchIn[field].indexOf(word, searchStartIndex)

      if (start > -1) {
        // Mark aleady used parts of text with XXXx so that the same word gets a diffrent index
        fieldsToSearchIn[field] = `${fieldsToSearchIn[field].substring(
          0,
          start,
        )}${'X'.repeat(word.length)}${fieldsToSearchIn[field].substring(
          start + word.length,
          fieldsToSearchIn[field].length,
        )}`
        return start
      }
      return null
    }

    // STEP 1. Process all base ingredients and overrides ingredients
    forEach(
      filter(
        aiIngredients,
        ({ matchType }) =>
          !includes(
            [
              AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE,
              AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD,
            ],
            matchType,
          ),
      ),
      aiIngredient => {
        if (fields[aiIngredient.originField]) {
          let aiSuggestedWord
          if (aiIngredient.posIndex) {
            aiSuggestedWord = fields[aiIngredient.originField].substring(
              aiIngredient.posIndex[0],
              aiIngredient.posIndex[1],
            )
          } else if (!aiIngredient.posIndex && aiIngredient.word) {
            aiSuggestedWord = aiIngredient.word
          }

          if (aiSuggestedWord) {
            const ingredientHasOverrides = !isEmpty(aiIngredient.overrides)
            const startIndex = isNil(get(aiIngredient.posIndex, '0'))
              ? getStartIndexOfIngredient(
                  aiSuggestedWord,
                  aiIngredient.originField,
                )
              : get(aiIngredient.posIndex, '0')

            aiSuggestedWords.push({
              word: aiSuggestedWord,
              matchType: aiIngredient.matchType,
              ...(aiIngredient.alternativeType
                ? { alternativeType: aiIngredient.alternativeType }
                : {}),
              originField: aiIngredient.originField,
              override: ingredientHasOverrides,
              startIndex,
            })

            if (ingredientHasOverrides) {
              forEach(aiIngredient.overrides, overridenIngredient => {
                let overridenWord
                if (
                  overridenIngredient.posIndex &&
                  fields[overridenIngredient.originField]
                ) {
                  overridenWord = fields[
                    overridenIngredient.originField
                  ].substring(
                    overridenIngredient.posIndex[0],
                    overridenIngredient.posIndex[1],
                  )
                } else if (overridenIngredient.word) {
                  overridenWord = overridenIngredient.word
                }
                if (overridenWord) {
                  const overridenStartIndex = isNil(
                    get(overridenIngredient.posIndex, '0'),
                  )
                    ? getStartIndexOfIngredient(
                        overridenWord,
                        overridenIngredient.originField,
                      )
                    : get(overridenIngredient.posIndex, '0')

                  aiSuggestedWords.push({
                    word: overridenWord,
                    originField: overridenIngredient.originField,
                    matchType: AI_INGREDIENT_MATCH_TYPE.OVERRIDES,
                    override: true,
                    startIndex: overridenStartIndex,
                  })
                }
              })
            }

            if (!isEmpty(aiIngredient.alreadyFoundIn)) {
              forEach(
                filter(
                  aiIngredient.alreadyFoundIn,
                  ({ matchType }) =>
                    !includes(
                      [
                        AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE,
                        AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD,
                      ],
                      matchType,
                    ),
                ),
                alreadyFoundIngredient => {
                  let alreadyFoundWord
                  if (alreadyFoundIngredient.posIndex) {
                    if (isNil(alreadyFoundIngredient.originField)) {
                      return
                    }

                    if (isNil(fields[alreadyFoundIngredient.originField])) {
                      return
                    }
                    alreadyFoundWord = fields[
                      alreadyFoundIngredient.originField
                    ].substring(
                      alreadyFoundIngredient.posIndex[0],
                      alreadyFoundIngredient.posIndex[1],
                    )
                  } else if (alreadyFoundIngredient.word) {
                    alreadyFoundWord = alreadyFoundIngredient.word
                  }

                  const alreadyFoundStartIndex = isNil(
                    get(alreadyFoundIngredient.posIndex, '0'),
                  )
                    ? getStartIndexOfIngredient(
                        alreadyFoundWord,
                        alreadyFoundIngredient.originField,
                      )
                    : get(alreadyFoundIngredient.posIndex, '0')

                  aiSuggestedWords.push({
                    word: alreadyFoundWord,
                    matchType: AI_INGREDIENT_MATCH_TYPE.ALREADY_FOUND,
                    originField: alreadyFoundIngredient.originField,
                    startIndex: alreadyFoundStartIndex,
                  })
                },
              )
            }
          }
        }
      },
    )

    // Step 2 Add overrides from additional ingredients
    if (!isEmpty(additionalIngredients)) {
      const overridesList = flatten(
        map(additionalIngredients, ({ overrides }) =>
          map(overrides, override =>
            omit(
              {
                ...override,
                matchType: AI_INGREDIENT_MATCH_TYPE.OVERRIDES,
                override: true,
                startIndex: getStartIndexOfIngredient(
                  override.word,
                  override.originField,
                ),
              },
              '__typename',
            ),
          ),
        ),
      )
      aiSuggestedWords = [...aiSuggestedWords, ...overridesList]
    }

    // STEP 3. Add already found phrases
    const ingredientsWithPhrases = reduce(
      aiIngredients,
      (acc, ingredient) => [
        ...acc,
        ...(includes(
          [
            AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE,
            AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD,
          ],
          ingredient.matchType,
        )
          ? [ingredient]
          : []),
        ...filter(ingredient.alreadyFoundIn, ({ matchType }) =>
          includes(
            [
              AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE,
              AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD,
            ],
            matchType,
          ),
        ),
      ],
      [],
    )

    // STEP 4. Proccess phrases
    forEach(ingredientsWithPhrases, phrase => {
      if (fields[phrase.originField]) {
        let phraseWord
        if (phrase.posIndex) {
          phraseWord = fields[phrase.originField].substring(
            phrase.posIndex[0],
            phrase.posIndex[1],
          )
        } else if (phrase.word) {
          phraseWord = phrase.word
        }

        if (isNil(phraseWord)) {
          return
        }

        const phraseStartIndex = isNil(get(phrase.posIndex, '0'))
          ? getStartIndexOfIngredient(phraseWord, phrase.originField)
          : get(phrase.posIndex, '0')
        const phraseEndIndex = phraseStartIndex + phraseWord.length

        const baseIngredientsToSearch = filter(
          map(aiSuggestedWords, (ingredient, index) => ({
            ...ingredient,
            index,
          })),
          ({ originField, startIndex }) => {
            if (originField !== phrase.originField) {
              return false
            }

            if (
              startIndex >= phraseStartIndex &&
              startIndex <= phraseEndIndex
            ) {
              return true
            }

            return false
          },
        )

        const phraseEnd = phraseWord.length - 1
        const baseIngredients = sortBy(
          reduce(
            baseIngredientsToSearch,
            (acc, ingredient) => {
              if (phraseWord.indexOf(ingredient.word) !== -1) {
                return [
                  ...acc,
                  {
                    index: ingredient.index,
                    start: phraseWord.indexOf(ingredient.word),
                    end:
                      phraseWord.indexOf(ingredient.word) +
                      ingredient.word.length -
                      1,
                  },
                ]
              }

              return acc
            },
            [],
          ),
          ['start'],
        )

        let position = 0
        const phraseSegments = reduce(
          baseIngredients,
          (acc, { start, end, index }, currentIndex) => {
            const newAcc = [...acc]
            if (start - position !== 0) {
              newAcc.push({
                start: position,
                end: start - 1,
                type: 'rest',
              })
            }
            newAcc.push({
              start,
              end,
              index,
              type: 'base',
            })
            if (
              currentIndex === baseIngredients.length - 1 &&
              end !== phraseEnd
            ) {
              newAcc.push({
                start: end + 1,
                end: phraseEnd,
                type: 'rest',
              })
            }
            position = end
            return newAcc
          },
          [],
        )

        const ingredientHasOverrides = !isEmpty(phrase.overrides)
        if (isEmpty(phraseSegments)) {
          aiSuggestedWords.push({ ...phrase, override: ingredientHasOverrides })
        } else {
          forEach(phraseSegments, segment => {
            let phraseType
            if (segment.start === 0 && segment.end !== phraseEnd) {
              phraseType = `${phrase.matchType}Start`
            } else if (segment.start !== 0 && segment.end === phraseEnd) {
              phraseType = `${phrase.matchType}End`
            } else {
              phraseType = `${phrase.matchType}Middle`
            }

            if (segment.type === 'base') {
              aiSuggestedWords[segment.index].matchType = `${
                aiSuggestedWords[segment.index].matchType || ''
              }${phraseType}`
            } else {
              const restWord = phraseWord.substring(
                segment.start,
                segment.end + 1,
              )
              const startIndex = getStartIndexOfIngredient(
                restWord,
                phrase.originField,
                phraseStartIndex,
              )
              aiSuggestedWords.push({
                word: restWord,
                originField: phrase.originField,
                matchType: phraseType,
                startIndex,
                override: ingredientHasOverrides,
              })
            }
          })
        }

        if (ingredientHasOverrides) {
          forEach(phrase.overrides, overridenIngredient => {
            let overridenWord

            if (
              overridenIngredient.posIndex &&
              fields[overridenIngredient.originField]
            ) {
              overridenWord = fields[overridenIngredient.originField].substring(
                overridenIngredient.posIndex[0],
                overridenIngredient.posIndex[1],
              )
            } else if (overridenIngredient.word) {
              overridenWord = overridenIngredient.word
            }

            if (overridenWord) {
              const overridenStartIndex = isNil(
                get(overridenIngredient.posIndex, '0'),
              )
                ? getStartIndexOfIngredient(
                    overridenWord,
                    overridenIngredient.originField,
                  )
                : get(overridenIngredient.posIndex, '0')

              aiSuggestedWords.push({
                word: overridenWord,
                originField: overridenIngredient.originField,
                matchType: AI_INGREDIENT_MATCH_TYPE.OVERRIDES,
                override: true,
                startIndex: overridenStartIndex,
              })
            }
          })
        }
      }
    })
    groupedHighlightList = groupBy(aiSuggestedWords, 'originField')
  }

  return groupedHighlightList
}
