import React, { useCallback } from 'react'
import { TreeSelect } from 'antd'
import { map, some } from 'lodash'
import PropTypes from 'prop-types'

import { treeOptionPropType } from '../../../../common/propTypes'

import { TreeNodeTitle } from '../../atoms'

export default function TreeNodeList({ treeData, stickyTreeNodes }) {
  const renderNodeList = useCallback(
    () =>
      map(treeData, node => {
        const nodeElement = (
          <TreeSelect.TreeNode
            key={node.id}
            value={node.value}
            disabled={node.disabled}
            title={
              <TreeNodeTitle title={node.title} isSticky={node.isSticky} />
            }
          >
            {renderNodeList(node.children, stickyTreeNodes)}
          </TreeSelect.TreeNode>
        )

        return (
          (node.isSticky ||
            !some(stickyTreeNodes, ({ id }) => id === node.id)) &&
          nodeElement
        )
      }),
    [stickyTreeNodes, treeData],
  )
  return renderNodeList()
}

TreeNodeList.propTypes = {
  treeData: PropTypes.arrayOf(treeOptionPropType).isRequired,
  stickyTreeNodes: PropTypes.arrayOf(treeOptionPropType),
}

TreeNodeList.defaultProps = {
  stickyTreeNodes: [],
}
