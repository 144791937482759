import { gql } from '@apollo/client'

import { INGREDIENTS_RERUN_SESSION_FRAGMENT } from '../fragments'

const INGREDIENTS_RERUN_SESSIONS_QUERY = gql`
  query ingredientsRerunSessionsQuery($currentPage: Int!, $pageSize: Int!) {
    ingredientsRerunSessions(currentPage: $currentPage, pageSize: $pageSize) {
      count
      sessions {
        ...IngredientsRerunSessionFragment
      }
    }
  }
  ${INGREDIENTS_RERUN_SESSION_FRAGMENT}
`

export default INGREDIENTS_RERUN_SESSIONS_QUERY
