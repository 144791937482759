import { createPayloadAction } from '../../../common/utils'

import { UNSAVED_LOCATION_DISHES_ACTION_TYPES } from '../actionTypes'

export const setLocationDishOverridenLowPrice = createPayloadAction(
  UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_MIN_OVERRIDEN_PRICE,
)
export const setLocationDishOverridenHighPrice = createPayloadAction(
  UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_MAX_OVERRIDEN_PRICE,
)
export const setLocationDishServicingHoursIds = createPayloadAction(
  UNSAVED_LOCATION_DISHES_ACTION_TYPES.SET_LOCATION_DISH_SERVICING_HOURS_IDS,
)
