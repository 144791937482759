const DISH_SORT_BY = Object.freeze({
  ID: 'Id',
  CERTAINTY: 'Certainty',
  DIET_LABELS: 'Diet Labels',
  ALLERGEN_LABELS: 'Allergen Labels',
  QA: 'QA',
  QA_CORRECTIONS: 'QA Corrections',
})

export default DISH_SORT_BY
