import React, { useMemo } from 'react'
import { Col, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { Input, TreeSelect } from '../../../../core/components'

import { useCurationMetadata } from '../../../../common/hooks'

import { brandPropType } from '../../../propTypes'
import { brandTransformer } from '../../../transformers'
import { BrandCuisineTypeSelect } from '../../molecules'

const BrandCuration = ({ brand, isDisabled, qaCorrectionCounts }) => {
  const { locationTypeOptions, cuisineTypeOptions } = useCurationMetadata()

  const { name, web, locationType, cuisineType, aiSuggestions } = useMemo(
    () => brandTransformer.toBrandCuration(brand),
    [brand],
  )

  return (
    <Row gutter={16}>
      <Input
        span={6}
        label="Brand Name"
        placeholder="Brand name..."
        defaultValue={name}
        name="name"
        labelAbove
        disabled={isDisabled}
      />
      <TreeSelect
        className={
          qaCorrectionCounts?.locationTypeId > 0 ? 'highlight-red' : undefined
        }
        span={6}
        defaultValue={get(locationType, 'id')}
        name="locationTypeId"
        treeData={locationTypeOptions}
        disabled={isDisabled}
        label="Type"
        labelAbove
      />
      <Col span={6}>
        <BrandCuisineTypeSelect
          defaultValue={cuisineType?.id}
          disabled={isDisabled}
          options={cuisineTypeOptions}
          aiSuggestions={aiSuggestions}
          isQaCorrected={qaCorrectionCounts?.cuisineTypeId > 0}
          isErrorProne={cuisineType?.isErrorProne}
        />
      </Col>
      <Input
        span={6}
        defaultValue={web}
        name="web"
        placeholder="Website address..."
        label="Website"
        labelAbove
        disabled={isDisabled}
      />
    </Row>
  )
}

BrandCuration.propTypes = {
  brand: brandPropType.isRequired,
  qaCorrectionCounts: PropTypes.object,
  isDisabled: PropTypes.bool,
}

BrandCuration.defaultProps = {
  isDisabled: false,
  qaCorrectionCounts: undefined,
}

export default BrandCuration
