const AVAILABLE_HOTKEYS = Object.freeze({
  DOWN: {
    hotkey: 'down',
    description:
      'Activate the dish list navigation for first use / Select the next dish/brand in the list / Navigate inside a select dropdown',
  },
  UP: {
    hotkey: 'up',
    description:
      'Select the previous dish/brand in the list / Navigate inside a select dropdown',
  },
  CMD_LEFT: {
    hotkey: 'cmd+left',
    description: 'Go to previous page',
  },
  CMD_RIGHT: {
    hotkey: 'cmd+right',
    description: 'Go to next page',
  },
  CTRL_LEFT: {
    hotkey: 'ctrl+left',
    description: 'Go to previous page',
  },
  CTRL_RIGHT: {
    hotkey: 'ctrl+right',
    description: 'Go to next page',
  },
  SHIFT_DOWN: {
    hotkey: 'shift+down',
    description: 'Go to end of list',
  },
  SHIFT_UP: {
    hotkey: 'shift+up',
    description: 'Go to start of list',
  },
  S: {
    hotkey: 's',
    description: 'Mark the selected dish/brand as QA Done or Curation Done',
  },
  SHIFT_S: {
    hotkey: 'shift+s',
    description: 'Mark all dishes/brands as QA Done or Curation Done',
  },
  ENTER: {
    hotkey: 'enter',
    description: 'Start editing the selected row',
  },
  ESC: {
    hotkey: 'esc',
    description: 'Stop/Save editing of the selected cell',
  },
  C: {
    hotkey: 'c',
    description: 'Select radio button Current for the current focused row',
  },
  L: {
    hotkey: 'L',
    description: 'Open/close the hotkey legend',
  },
  O: {
    hotkey: 'O',
    description: 'Select radio button Other for the current focused row',
  },
  M: {
    hotkey: 'M',
    description: 'Select radio button ML for the current focused row',
  },
  CMD_D: {
    hotkey: 'cmd+d',
    description: 'Mark the task as Curation/Qa done',
  },
  CTRL_D: {
    hotkey: 'ctrl+d',
    description: 'Mark the task as Curation/Qa done',
  },
  R: {
    hotkey: 'r',
    description: 'Mark the dish/brand for batch operation',
  },
  SHIFT_R: {
    hotkey: 'shift+r',
    description: 'Mark all dishes/brands for batch operation',
  },
  CMD_R: {
    hotkey: 'cmd+r',
    description: 'Start editing for batch operation',
  },
  CTRL_R: {
    hotkey: 'ctrl+r',
    description: 'Start editing for batch operation',
  },
})

export default AVAILABLE_HOTKEYS
