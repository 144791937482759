import { CompositeDecorator, ContentState, EditorState } from 'draft-js'
import { get, groupBy, isEmpty, isNil, map, omit } from 'lodash'

import {
  getContentStateFromText,
  getStyledTextFromContentState,
} from '../../common/helpers'
import { clearMiscAndChoice, stripHtmlFromText } from '../../common/utils'

import { MenuTitleHighlightDecorator } from '../components/atoms'
import { MENU_TITLE_STATUS_TYPE } from '../constants'
import { handleMenuTitleHighlightStrategy } from '../utils'

export const transformMenuTitleDtoToDiscardDto = ({
  id,
  name,
  children,
  parentId,
  menuId,
  description,
  styledDescription,
  status,
}) => {
  let transformedItem = {
    id,
    name,
    parentId,
    menuId,
    description,
    styledDescription,
    status,
  }

  if (status === MENU_TITLE_STATUS_TYPE.PENDING) {
    transformedItem = {
      ...transformedItem,
      styledDescription: clearMiscAndChoice(styledDescription),
      status: null,
    }
  }
  transformedItem.children = isNil(children)
    ? undefined
    : map(children, item => transformMenuTitleDtoToDiscardDto(item))

  return transformedItem
}

export const transformMenuTitleToDto = ({
  id,
  name,
  styledDescription,
  children,
  parentId,
  menuId,
  status,
  isChildrenDeleted,
}) => {
  const changedName = name.getCurrentContent().getPlainText()
  const changedStyledDescription = getStyledTextFromContentState(
    styledDescription.getCurrentContent(),
  )

  const transformedItem = {
    id,
    parentId,
    name: changedName,
    description: stripHtmlFromText(changedStyledDescription) || '',
    styledDescription: changedStyledDescription,
    menuId,
    status:
      status === MENU_TITLE_STATUS_TYPE.PENDING
        ? MENU_TITLE_STATUS_TYPE.AI_CONFIRMED
        : status,
  }

  if (isChildrenDeleted) {
    transformedItem.children = []
  } else {
    transformedItem.children = isNil(children)
      ? undefined
      : map(children, item => transformMenuTitleToDto(item))
  }

  return transformedItem
}

export const transformMenuTitleFromDto = (
  menuTitle,
  highlightWordsGroupedByMenuTitleId,
  index,
  indexPath = '',
) => {
  const {
    description: highlightingListDescription,
    name: highlightingListTitle,
  } = groupBy(
    get(highlightWordsGroupedByMenuTitleId, menuTitle.id),
    'originField',
  )

  const menuTitleHighlightDecorator = isEmpty(highlightingListDescription)
    ? undefined
    : new CompositeDecorator([
        {
          strategy: handleMenuTitleHighlightStrategy(
            highlightingListDescription,
          ),
          component: MenuTitleHighlightDecorator,
          props: {
            highlightingList: highlightingListDescription,
          },
        },
      ])

  const menuTitleHighlightDecoratorTitle = isEmpty(highlightingListTitle)
    ? undefined
    : new CompositeDecorator([
        {
          strategy: handleMenuTitleHighlightStrategy(highlightingListTitle),
          component: MenuTitleHighlightDecorator,
          props: {
            highlightingList: highlightingListTitle,
          },
        },
      ])

  const { name, styledDescription, children, depth } = menuTitle

  return {
    ...omit(menuTitle, [
      '__typename',
      'publishedDishCount',
      'pendingDishCount',
      'ignoredDishCount',
    ]),
    name: EditorState.createWithContent(
      ContentState.createFromText(name),
      menuTitleHighlightDecoratorTitle,
    ),
    styledDescription:
      styledDescription && styledDescription !== '<p></p>'
        ? EditorState.createWithContent(
            getContentStateFromText(styledDescription),
            menuTitleHighlightDecorator,
          )
        : EditorState.createEmpty(),
    path: depth > 0 ? `${indexPath}[${index}]` : indexPath,
    children: !isEmpty(menuTitle.children)
      ? map(children, (child, childIndex) =>
          transformMenuTitleFromDto(
            child,
            highlightWordsGroupedByMenuTitleId,
            childIndex,
            depth > 0
              ? `${indexPath}[${index}].children`
              : `${indexPath}.children`,
          ),
        )
      : undefined,
    isSuggestedByAi: false,
    isChildrenDeleted: false,
  }
}
