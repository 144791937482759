import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const StyledPageHeader = ({ isSticky, children }) => {
  const stickyStyle = useMemo(
    () => (isSticky ? { position: 'sticky', top: 0, zIndex: 999 } : {}),
    [isSticky],
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '1rem',
        ...stickyStyle,
      }}
    >
      {children}
    </div>
  )
}

StyledPageHeader.propTypes = {
  isSticky: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

StyledPageHeader.defaultProps = {
  isSticky: false,
}

export default StyledPageHeader
