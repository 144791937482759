import { isEmpty, reduce, some } from 'lodash'

import { areSameValue } from '../../core/utils'

import { filterTree, findNodeByValue } from '../utils'

const filterDuplicatesFromAiTreeData = treeData =>
  reduce(
    treeData,
    (result, node) => {
      const nodesToRemove = reduce(
        treeData,
        (resultToRemove, nodeToCheck) => {
          if (areSameValue(nodeToCheck.value, node.value)) {
            return resultToRemove
          }

          if (!isEmpty(findNodeByValue(node.children, nodeToCheck.value))) {
            return [...resultToRemove, nodeToCheck.value]
          }

          return resultToRemove
        },
        [],
      )

      if (isEmpty(nodesToRemove)) {
        return [...result, node]
      }

      return [
        ...result,
        {
          ...node,
          children: filterTree(
            node.children,
            ({ value }) =>
              !some(nodesToRemove, valueToRemove =>
                areSameValue(value, valueToRemove),
              ),
          ),
        },
      ]
    },
    [],
  )

export default filterDuplicatesFromAiTreeData
