import { gql } from '@apollo/client'

const DELETE_CLEANUP_TASK_GROUP_MUTATION = gql`
  mutation deleteCleanupTaskGroup($id: ID!, $metadataType: String!) {
    deleteCleanupTaskGroup(id: $id, metadataType: $metadataType) {
      success
    }
  }
`

export default DELETE_CLEANUP_TASK_GROUP_MUTATION
