import React from 'react'
import { notification, Typography } from 'antd'
import { find, get, isEmpty, map } from 'lodash'

import { suggestionEndpoints } from '../../common/constants'
import { getAiSuggestions } from '../../common/utils'

import { brandCuisineTypeSuggestionTransformer } from '../transformers'

/**
 * Calls the Ai servicing for suggesting cuisine type
 * It should:
 *   - fetch suggestions
 *   - return the suggestions
 *
 * Returns a promise.
 */
export default async function suggestCuisineType({ brandId }) {
  try {
    // this endpoint gives us suggestions for multiple brands, that's why we destruct here
    const [result] = await getAiSuggestions(
      suggestionEndpoints.cuisineType,
      [
        brandCuisineTypeSuggestionTransformer.transformBrandToAiRequestDto(
          brandId,
        ),
      ],
      brandCuisineTypeSuggestionTransformer.transformDishFromAiResponseDto,
    )

    if (!isEmpty(result.messages)) {
      notification.error({
        message: 'An error occured',
        description: map(result.messages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })
      return null
    }

    const suggestions = get(result, 'result')
    const highestScoreFromSuggestions = Math.max(...map(suggestions, 'score'))
    const cuisineTypeId = get(
      find(
        suggestions,
        suggestion => suggestion.score === highestScoreFromSuggestions,
      ),
      'id',
    )

    return {
      cuisineTypeId,
      aiSuggestions: {
        cuisineType: suggestions,
      },
    }
  } catch (e) {
    notification.error({
      message: 'An error occured',
      description: e.message,
      placement: 'topLeft',
    })
    return null
  }
}
