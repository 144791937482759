import React from 'react'

import { DishEditableVariousTextRowProvider } from '../../../providers'

// eslint-disable-next-line react/prop-types
const DishEditableVariousTextRow = ({ index, ...props }) => (
  <DishEditableVariousTextRowProvider rowProps={props} />
)

export default DishEditableVariousTextRow
