import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Typography } from 'antd'
import update from 'immutability-helper'
import { filter, findIndex, map } from 'lodash'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { servicingHourPropType } from '../../../../common/propTypes'

import { WorkScheduleHour } from '../WorkScheduleHour'

const createDefaultWorkScheduleHour = () => ({
  id: shortid.generate(),
  created: true,
  startDayOfWeek: 0,
  endDayOfWeek: 4,
  startTime: '09:00',
  endTime: '17:00',
})

const UserWorkSchedule = ({ workSchedule, disabled }) => {
  const { control } = useFormContext()

  const handleChange = useCallback((onChange, existingValue, newValue) => {
    const { id, created, deleted } = newValue
    const index = findIndex(
      existingValue,
      ({ id: scheduleHourId }) => scheduleHourId === id,
    )

    let updatedWorkScheduleHours
    if (created && deleted) {
      updatedWorkScheduleHours = update(existingValue, {
        $splice: [[index, 1]],
      })
    } else {
      updatedWorkScheduleHours = update(existingValue, {
        [index]: {
          $set: { ...newValue, ...(!newValue.created && { updated: true }) },
        },
      })
    }

    onChange(updatedWorkScheduleHours)
  }, [])

  const handleAdd = useCallback(
    (onChange, existingValue) =>
      onChange([...existingValue, createDefaultWorkScheduleHour()]),
    [],
  )

  return (
    <Controller
      control={control}
      defaultValue={workSchedule}
      name="workSchedule"
      render={({ field: { onChange, value } }) => (
        <>
          <Typography.Paragraph>Work schedule</Typography.Paragraph>

          {map(
            filter(value, ({ deleted }) => !deleted),
            scheduleHour => (
              <WorkScheduleHour
                key={scheduleHour.id}
                scheduleHour={scheduleHour}
                onValueChanged={newValue =>
                  handleChange(onChange, value, newValue)
                }
                disabled={disabled}
              />
            ),
          )}

          <Button
            onClick={() => handleAdd(onChange, value)}
            disabled={disabled}
          >
            Add work schedule
          </Button>
        </>
      )}
    />
  )
}

UserWorkSchedule.propTypes = {
  workSchedule: PropTypes.arrayOf(servicingHourPropType),
  disabled: PropTypes.bool,
}

UserWorkSchedule.defaultProps = {
  workSchedule: [],
  disabled: false,
}

export default UserWorkSchedule
