import React, { useCallback, useMemo } from 'react'
import { Tag } from 'antd'
import PropTypes from 'prop-types'

import { shouldEnableCurationOnMetadata } from '../../../../common/utils'

const MetadataTag = ({ label, closable, onClose }) => {
  const isAllowedToRemove = useMemo(
    () => (closable ? true : !shouldEnableCurationOnMetadata({ name: label })),
    [closable, label],
  )

  const onPreventMouseDown = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={isAllowedToRemove}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  )
}

MetadataTag.propTypes = {
  label: PropTypes.string.isRequired,
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MetadataTag
