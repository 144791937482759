import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1001,
  },
}

const Spinner = ({ size }) => (
  <div style={styles.overlay}>
    <Spin size={size} />
  </div>
)

Spinner.propTypes = {
  size: PropTypes.string,
}

Spinner.defaultProps = {
  size: 'small',
}

export default Spinner
