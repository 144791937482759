import { gql } from '@apollo/client'

const SIMPLE_USER_FRAGMENT = gql`
  fragment SimpleUserFragment on UpdatedByUser {
    id
    name
    email
  }
`

export default SIMPLE_USER_FRAGMENT
