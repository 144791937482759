import { gql } from '@apollo/client'

const REPORT_REQUEST_DOWNLOAD_URL_QUERY = gql`
  query reportRequestDownloadUrl($id: ID!) {
    reportRequestDownloadUrl(id: $id) {
      url
    }
  }
`

export default REPORT_REQUEST_DOWNLOAD_URL_QUERY
