import * as Yup from 'yup'

const mlCleanupTaskValidationSchema = Yup.object().shape({
  metadataType: Yup.string().required('Metadata type is required.').nullable(),
  metadataIds: Yup.array()
    .of(Yup.string())
    .required('At least one metadata item is required.')
    .nullable(),
  startDate: Yup.date().required('Start date is required.').nullable(),
  endDate: Yup.date().required('End date is required.').nullable(),
})

export default mlCleanupTaskValidationSchema
