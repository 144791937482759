import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_ROUTES } from '../../common/constants'

import { IngredientsRerun } from './pages'

const IngredientsRerunRouter = () => (
  <Switch>
    <Route
      exact
      path={APP_ROUTES.INGREDIENTS_RERUN_ROUTES.IngredientsRerun}
      component={IngredientsRerun}
    />
  </Switch>
)

export default IngredientsRerunRouter
