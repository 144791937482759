import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Select, Spin, Tooltip, Typography } from 'antd'
import { filter, includes, isNil, map, some, uniq } from 'lodash'
import PropTypes from 'prop-types'

import { selectFilterOption } from '../../../../core/utils'

import {
  PERMISSIONS,
  USER_SENIORITY_LEVELS,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import {
  useAuthentication,
  useUserHasPermissions,
  useUserHasRoles,
} from '../../../../common/hooks'

import { BRAND_ASSIGNED_USER_ACTIONS } from '../../../constants'
import { ASSIGN_USER_TO_BRAND_MUTATION } from '../../../graphql'

const styles = {
  select: { width: 100 },
  dropdown: { maxHeight: 400, minWidth: 180 },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 8,
    cursor: 'pointer',
  },
  editDisabled: {
    cursor: 'not-allowed',
  },
  actionSelect: {
    marginTop: 5,
    width: 100,
  },
}

const BrandUserAssignment = ({
  brandId,
  assignedUser,
  alreadyAssignedUsers,
  assignedUserAction,
  availableUsers,
  tooltip,
  disabled,
}) => {
  const [editing, setEditing] = useState(false)

  const userCanAssignMultiple = useUserHasPermissions([
    PERMISSIONS.BRANDS.ASSIGN_USERS,
  ])

  const selectRef = useRef(null)

  const { userInfo = {} } = useAuthentication()
  const { id: currentUserId } = userInfo
  const isUserCurator = useUserHasRoles(['curator'])
  const isUserQa = useUserHasRoles(['qa'])

  const [assignUser, { loading }] = useMutation(ASSIGN_USER_TO_BRAND_MUTATION)

  const alreadyAssignedUsersToCheck = useMemo(
    () => filter(alreadyAssignedUsers, user => !isNil(user)),
    [alreadyAssignedUsers],
  )

  const isEditingDisabled = useMemo(
    () =>
      disabled ||
      (!userCanAssignMultiple &&
        includes(
          [BRAND_ASSIGNED_USER_ACTIONS.QA, BRAND_ASSIGNED_USER_ACTIONS.QQA],
          assignedUserAction,
        ) &&
        isUserCurator &&
        !isUserQa),
    [
      disabled,
      userCanAssignMultiple,
      assignedUserAction,
      isUserCurator,
      isUserQa,
    ],
  )

  const optionsToShow = useMemo(() => {
    let users

    if (assignedUserAction === BRAND_ASSIGNED_USER_ACTIONS.QQA) {
      users = filter(
        availableUsers,
        user =>
          user.isActive &&
          some(user.roles, ({ name }) => includes(['qa', 'admin'], name)) &&
          user.seniority === USER_SENIORITY_LEVELS.SENIOR,
      )
    } else if (assignedUserAction === BRAND_ASSIGNED_USER_ACTIONS.QA) {
      users = filter(
        availableUsers,
        user =>
          user.isActive &&
          some(user.roles, ({ name }) => includes(['qa', 'admin'], name)),
      )
    } else {
      users = filter(availableUsers, 'isActive')
    }

    if (userCanAssignMultiple) {
      return map(
        filter(
          users,
          ({ id }) =>
            !some(
              alreadyAssignedUsersToCheck,
              userAlreadyAssigned => id === userAlreadyAssigned.id,
            ),
        ),
        user => ({
          label: user.name || user.email,
          value: user.id,
        }),
      )
    }

    const allowedUsers = uniq([
      currentUserId,
      ...(!isNil(assignedUser?.id) ? [assignedUser?.id] : []),
    ])

    return map(
      filter(
        users,
        ({ id }) =>
          includes(allowedUsers, id) &&
          !some(
            alreadyAssignedUsersToCheck,
            userAlreadyAssigned => id === userAlreadyAssigned.id,
          ),
      ),
      user => ({
        label: user.name || user.email,
        value: user.id,
      }),
    )
  }, [
    assignedUser,
    assignedUserAction,
    availableUsers,
    currentUserId,
    alreadyAssignedUsersToCheck,
    userCanAssignMultiple,
  ])

  useEffect(() => {
    if (!isNil(selectRef.current)) {
      selectRef.current.focus()
    }
  }, [editing])

  const handleClick = useCallback(
    e => {
      e.stopPropagation()

      if (isEditingDisabled) {
        return
      }

      setEditing(true)
    },
    [setEditing, isEditingDisabled],
  )

  const handleChange = useCallback(
    async value => {
      try {
        await assignUser({
          variables: {
            id: brandId,
            userId: isNil(value) ? null : value,
            action: assignedUserAction,
          },
        })
      } catch (e) {
        handleMutationError(e)
      }
      setEditing(false)
    },
    [assignUser, assignedUserAction, brandId],
  )

  const handleBlur = useCallback(() => {
    setEditing(false)
  }, [])

  if (loading) {
    return <Spin size="small" />
  }

  return (
    <Tooltip title={tooltip}>
      <div
        role="presentation"
        style={{
          ...styles.container,
          ...(isEditingDisabled && styles.editDisabled),
        }}
        onClick={handleClick}
      >
        {editing && !isEditingDisabled && (
          <Select
            showSearch
            size="small"
            allowClear
            ref={selectRef}
            optionFilterProp="children"
            onChange={handleChange}
            onBlur={handleBlur}
            options={optionsToShow}
            value={assignedUser?.id}
            style={styles.select}
            dropdownStyle={styles.dropdown}
            filterOption={selectFilterOption}
          />
        )}
        {!editing && (
          <Typography.Text>
            {assignedUser?.name || assignedUser?.email || 'unassigned'}
          </Typography.Text>
        )}
      </div>
    </Tooltip>
  )
}

BrandUserAssignment.propTypes = {
  brandId: PropTypes.string.isRequired,
  assignedUser: PropTypes.object,
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  assignedUserAction: PropTypes.string,
  disabled: PropTypes.bool,
  alreadyAssignedUsers: PropTypes.arrayOf(PropTypes.object),
  tooltip: PropTypes.string,
}

BrandUserAssignment.defaultProps = {
  assignedUser: undefined,
  assignedUserAction: undefined,
  disabled: false,
  availableUsers: [],
  alreadyAssignedUsers: [],
  tooltip: undefined,
}

export default BrandUserAssignment
