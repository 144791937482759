import { gql } from '@apollo/client'

import { CLEANUP_TASK_BATCH_FRAGMENT } from '../fragments'

const CLEANUP_TASK_BATCH_QUERY = gql`
  query cleanupTaskBatch($metadataType: String!, $id: ID!) {
    cleanupTaskBatch(metadataType: $metadataType, id: $id) {
      ...CleanupTaskBatchFragment
      group {
        id
        name
      }
    }
  }
  ${CLEANUP_TASK_BATCH_FRAGMENT}
`

export default CLEANUP_TASK_BATCH_QUERY
