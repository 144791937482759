import React, { memo, useEffect, useMemo } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Button, Popover, Tooltip, Typography } from 'antd'
import PropTypes from 'prop-types'

import { handleMutationError } from '../../../../common/helpers'
import { useCurationMetadata } from '../../../../common/hooks'

import { INGREDIENT_EXPLANATIONS_QUERY } from '../../../graphql'
import { DishIngredientExplanations } from '../../molecules'

const styles = {
  popoverButton: {
    marginLeft: 0,
  },
}

const DishIngredientExplanationsPopover = ({
  isVisible,
  dishId,
  onVisibleChange,
  dishIngredients,
}) => {
  const { ingredientOptions } = useCurationMetadata()

  const [
    fetchExplanations,
    { data: { ingredientExplanations } = {}, loading, error },
  ] = useLazyQuery(INGREDIENT_EXPLANATIONS_QUERY, {
    variables: { dishId },
    onError: handleMutationError,
  })

  useEffect(() => {
    if (isVisible) {
      fetchExplanations()
    }
  }, [fetchExplanations, isVisible])

  const popoverContent = useMemo(() => {
    if (error) {
      return <Typography.Paragraph>{error}</Typography.Paragraph>
    }

    return (
      <div>
        <Typography.Paragraph strong>
          Ingredient explanations
        </Typography.Paragraph>
        <DishIngredientExplanations
          ingredientExplanations={ingredientExplanations}
          dishIngredients={dishIngredients}
          ingredientsTreeData={ingredientOptions}
        />
      </div>
    )
  }, [dishIngredients, error, ingredientExplanations, ingredientOptions])

  if (loading) {
    return null
  }

  return (
    <Popover
      visible={isVisible}
      content={popoverContent}
      overlayClassName="dishes-tables-ingredient-explanation-popover"
      getPopupContainer={() => document.body}
      placement="topRight"
      /* hidden ant design prop which forces to popover/tooltip to open at placement */
      autoAdjustOverflow={false}
      onVisibleChange={onVisibleChange}
      trigger="click"
    >
      <Tooltip placement="topLeft" title="Ingredient explanations">
        <Button
          style={styles.popoverButton}
          icon={<MenuOutlined />}
          shape="circle"
        />
      </Tooltip>
    </Popover>
  )
}

DishIngredientExplanationsPopover.propTypes = {
  dishId: PropTypes.string.isRequired,
  dishIngredients: PropTypes.arrayOf(PropTypes.object).isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
}

DishIngredientExplanationsPopover.defaultProps = {
  isVisible: false,
}

export default memo(DishIngredientExplanationsPopover)
