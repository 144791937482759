import { gql } from '@apollo/client'

const CLEANUP_TASK_BATCH_FRAGMENT = gql`
  fragment CleanupTaskBatchFragment on CleanupTaskBatch {
    id
    name
    totalCount
    doneCount
    qaCount
    isCurationDone
    isQaDone
    assignedCuratorId
    assignedQaId
    updatedAt
    createdAt
    group {
      id
      type
      blockedById
    }
  }
`

export default CLEANUP_TASK_BATCH_FRAGMENT
