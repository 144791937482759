import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import { find, get, isNil, some } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { areSameValue } from '../../../../core/utils'

import { StickyNodesTreeSelect } from '../../../../common/components'
import {
  AI_SUGGESTIONS_HIGHLIGHT_COLORS,
  HIGHLIGHT_PREFIX,
} from '../../../../common/constants'
import {
  filterDuplicatesFromAiTreeData,
  getSuggestedByAiTreeData,
} from '../../../../common/helpers'
import { useCachedBrand, useCurationMetadata } from '../../../../common/hooks'
import { findNodeByValue } from '../../../../common/utils'

import { DISH_CATEGORIES_MATCH_TYPE, DISH_FIELDS } from '../../../constants'
import { useDishFields, usePersistedDishField } from '../../../hooks'
import { dishPropType } from '../../../propTypes'
import { setDishCourseType, setDishDishType } from '../../../redux'

const styles = {
  selectedBrandCuisineType: {
    lineHeight: 1.8,
    fontSize: 14,
    marginTop: '2rem',
  },
  matchType: {
    color: '#cccecf',
    backgroundColor: 'transparent',
  },
  marginTop: {
    marginTop: '1rem',
  },
}

const DishCategoriesSection = ({ dish, disabled }) => {
  const brand = useCachedBrand()
  const { dishType, courseType, aiSuggestions: savedAiSuggestions } = dish

  const { dishType: suggestedDishTypes, courseType: suggestedCourseTypes } =
    savedAiSuggestions || {}

  const { shouldDisableField } = useDishFields(
    dish?.status,
    null,
    null,
    null,
    disabled,
  )
  const { dishTypeOptions, courseTypeOptions } = useCurationMetadata()
  const { id: dishTypeId } = dishType || {}
  const { id: courseTypeId } = courseType || {}

  const { dishTypeId: persistedDishTypeId, onValueChanged: dishTypeOnChange } =
    usePersistedDishField('dishTypeId', setDishDishType)

  const {
    courseTypeId: persistedCourseTypeId,
    onValueChanged: courseTypeOnChange,
  } = usePersistedDishField('courseTypeId', setDishCourseType)

  const courseTypeSelectedId =
    persistedCourseTypeId === null
      ? null
      : persistedCourseTypeId || courseTypeId
  const dishTypeSelectedId =
    persistedDishTypeId === null ? null : persistedDishTypeId || dishTypeId

  const {
    dishTypeMatchType,
    courseTypeMatchType,
    isCourseTypeSuggestedByAi,
    isDishTypeSuggestedByAi,
  } = useMemo(() => {
    let matchTypeForDishType
    let matchTypeForCourseType
    let courseTypeSuggestedByAi
    let dishTypeSuggestedByAi

    if (!isNil(dishTypeSelectedId)) {
      const aiSuggestedDishType = find(
        suggestedDishTypes,
        ({ dishTypeId: aiId }) => areSameValue(aiId, dishTypeSelectedId),
      )

      if (!isNil(aiSuggestedDishType)) {
        matchTypeForDishType = aiSuggestedDishType.matchType
        dishTypeSuggestedByAi = true
      } else {
        dishTypeSuggestedByAi = false
      }
    }

    if (!isNil(courseTypeSelectedId)) {
      const aiSuggestedCourseType = find(
        suggestedCourseTypes,
        ({ courseTypeId: aiId }) => areSameValue(aiId, courseTypeSelectedId),
      )

      if (!isNil(aiSuggestedCourseType)) {
        matchTypeForCourseType = aiSuggestedCourseType.matchType
        courseTypeSuggestedByAi = true
      } else {
        courseTypeSuggestedByAi = false
      }
    }

    return {
      dishTypeMatchType: matchTypeForDishType,
      courseTypeMatchType: matchTypeForCourseType,
      isCourseTypeSuggestedByAi: courseTypeSuggestedByAi,
      isDishTypeSuggestedByAi: dishTypeSuggestedByAi,
    }
  }, [
    courseTypeSelectedId,
    suggestedCourseTypes,
    dishTypeSelectedId,
    suggestedDishTypes,
  ])

  const suggestedByAiDishTypesTreeData = useMemo(
    () =>
      filterDuplicatesFromAiTreeData(
        getSuggestedByAiTreeData(dishTypeOptions, suggestedDishTypes),
      ),
    [dishTypeOptions, suggestedDishTypes],
  )

  const suggestedByAiCourseTypesTreeData = useMemo(
    () =>
      filterDuplicatesFromAiTreeData(
        getSuggestedByAiTreeData(courseTypeOptions, suggestedCourseTypes),
      ),
    [courseTypeOptions, suggestedCourseTypes],
  )

  const handleDishTypeChange = useCallback(
    newDishTypeId => {
      const sameAsCourseTypeId = get(
        findNodeByValue(dishTypeOptions, newDishTypeId),
        'courseTypeId',
      )
      if (sameAsCourseTypeId) {
        const courseTypeNewSelectionId = get(
          findNodeByValue(courseTypeOptions, sameAsCourseTypeId),
          'value',
        )
        courseTypeOnChange(courseTypeNewSelectionId)
      }
      dishTypeOnChange(newDishTypeId)
    },
    [courseTypeOnChange, courseTypeOptions, dishTypeOnChange, dishTypeOptions],
  )

  const handleCourseTypeChange = useCallback(
    newCourseTypeId => {
      const sameAsDishTypeId = get(
        findNodeByValue(courseTypeOptions, newCourseTypeId),
        'dishTypeId',
      )
      if (sameAsDishTypeId) {
        const dishTypeNewSelectionId = get(
          findNodeByValue(dishTypeOptions, sameAsDishTypeId),
          'value',
        )
        dishTypeOnChange(dishTypeNewSelectionId)
      }
      courseTypeOnChange(newCourseTypeId)
    },
    [courseTypeOnChange, courseTypeOptions, dishTypeOnChange, dishTypeOptions],
  )

  useEffect(() => {
    const sameAsCourseTypeId = get(
      findNodeByValue(dishTypeOptions, dishTypeSelectedId),
      'courseTypeId',
    )

    if (sameAsCourseTypeId && isNil(courseTypeSelectedId)) {
      const courseTypeNewSelectionId = get(
        findNodeByValue(courseTypeOptions, sameAsCourseTypeId),
        'value',
      )
      courseTypeOnChange(courseTypeNewSelectionId)
    }
  }, [
    courseTypeOnChange,
    courseTypeOptions,
    courseTypeSelectedId,
    dishTypeOptions,
    dishTypeSelectedId,
  ])

  return (
    <Row gutter={16} style={styles.marginTop}>
      <GridFormItem
        span={8}
        smallLabel
        label={
          <Typography.Text>
            Dish Type
            {dishTypeMatchType && (
              <>
                /{' '}
                <Typography.Text style={styles.matchType}>
                  {DISH_CATEGORIES_MATCH_TYPE[dishTypeMatchType]}
                </Typography.Text>
              </>
            )}
          </Typography.Text>
        }
      >
        <StickyNodesTreeSelect
          treeData={dishTypeOptions}
          className={
            (isDishTypeSuggestedByAi && dishTypeId === dishTypeSelectedId) ||
            some(
              suggestedByAiDishTypesTreeData,
              node => node.id === persistedDishTypeId,
            )
              ? `${HIGHLIGHT_PREFIX}-${AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE}`
              : undefined
          }
          stickyNodes={suggestedByAiDishTypesTreeData}
          fieldNames={{ label: 'namePath' }}
          value={dishTypeSelectedId}
          onChange={handleDishTypeChange}
          disabled={shouldDisableField(DISH_FIELDS.DISH_TYPE)}
        />
      </GridFormItem>
      <GridFormItem
        span={8}
        smallLabel
        label={
          <Typography.Text>
            Course Type
            {courseTypeMatchType && (
              <>
                /{' '}
                <Typography.Text style={styles.matchType}>
                  {DISH_CATEGORIES_MATCH_TYPE[courseTypeMatchType]}
                </Typography.Text>
              </>
            )}
          </Typography.Text>
        }
      >
        <StickyNodesTreeSelect
          treeData={courseTypeOptions}
          className={
            (isCourseTypeSuggestedByAi &&
              courseTypeId === courseTypeSelectedId) ||
            some(
              suggestedByAiCourseTypesTreeData,
              node => node.id === persistedCourseTypeId,
            )
              ? `${HIGHLIGHT_PREFIX}-${AI_SUGGESTIONS_HIGHLIGHT_COLORS.BLUE}`
              : undefined
          }
          stickyNodes={suggestedByAiCourseTypesTreeData}
          fieldNames={{ label: 'namePath' }}
          value={courseTypeSelectedId}
          onChange={handleCourseTypeChange}
          disabled={
            dishType?.isIgnored || shouldDisableField(DISH_FIELDS.COURSE_TYPE)
          }
        />
      </GridFormItem>
      <Col span={8}>
        {brand.cuisineType && (
          <Typography.Paragraph style={styles.selectedBrandCuisineType}>
            Brand Cuisine type: {brand.cuisineType.name}
          </Typography.Paragraph>
        )}
      </Col>
    </Row>
  )
}
DishCategoriesSection.propTypes = {
  dish: dishPropType.isRequired,
  disabled: PropTypes.bool,
}

DishCategoriesSection.defaultProps = {
  disabled: false,
}

export default memo(DishCategoriesSection)
