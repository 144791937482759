import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Card, notification, Typography } from 'antd'
import { isNil } from 'lodash'

import { Input } from '../../../core/components'
import { useDocumentTitle } from '../../../core/hooks'

import { useAuthentication } from '../../../common/hooks'
import { sessionManager } from '../../../common/services'
import { decodeToken } from '../../../common/utils'

const styles = {
  loginContainer: { height: '100vh', display: 'flex' },
  loginRow: {
    justifyContent: 'center',
    alignSelf: 'center',
    display: 'flex',
    width: '100%',
  },
  loginCard: { width: '30%', textAlign: 'center' },
}

const TokenLoginPage = () => {
  useDocumentTitle('Login')
  const { setUserInfo } = useAuthentication()

  const { formState, handleSubmit, reset, ...formProps } = useForm()
  const { isDirty } = formState

  const handleLogin = useCallback(
    ({ accessToken }) => {
      const user = decodeToken(accessToken)

      if (isNil(user)) {
        notification.open({
          message: 'Login failed',
          description: 'Failed to decode access token',
          type: 'error',
        })
      }
      user.id = user.id.toString()

      sessionManager.createSession(user, accessToken)

      setUserInfo(user)
      reset()
    },
    [setUserInfo, reset],
  )

  const submitForm = useMemo(
    () => handleSubmit(handleLogin),
    [handleLogin, handleSubmit],
  )

  return (
    <FormProvider
      {...formProps}
      reset={reset}
      formState={formState}
      handleSubmit={handleSubmit}
    >
      <div style={styles.loginContainer}>
        <div style={styles.loginRow}>
          <Card style={styles.loginCard}>
            <Typography.Title level={2}>
              Curator Tool - FoodStyles
              <Input
                name="accessToken"
                label="Access token"
                placeholder="Access token"
              />
              <Button disabled={!isDirty} onClick={submitForm}>
                Login
              </Button>
            </Typography.Title>
          </Card>
        </div>
      </div>
    </FormProvider>
  )
}

export default TokenLoginPage
