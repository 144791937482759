import React, { memo } from 'react'
import { Button, Tooltip } from 'antd'
import { isNil } from 'lodash'
import { PropTypes } from 'prop-types'

const SaveButton = ({ disabled, onClick, loading, errorMessage }) => {
  let displayText = 'Save'

  if (loading) {
    displayText = 'Saving'
  } else if (disabled && isNil(errorMessage)) {
    displayText = 'Saved'
  } else if (!isNil(errorMessage)) {
    displayText = 'Save failed'
  }

  const button = (
    <Button
      type="primary"
      disabled={disabled || loading}
      onClick={onClick}
      loading={loading}
    >
      {displayText}
    </Button>
  )
  return errorMessage ? (
    <Tooltip title={errorMessage}>{button}</Tooltip>
  ) : (
    button
  )
}

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

SaveButton.defaultProps = {
  disabled: false,
  loading: false,
  errorMessage: undefined,
}

export default memo(SaveButton)
