import { aiMainIngredientOriginField } from '../constants'

export const getPriorityFromOriginOfIngredient = originField => {
  switch (originField) {
    case aiMainIngredientOriginField.NAME:
      return 1
    case aiMainIngredientOriginField.DESCRIPTION:
      return 3
    case aiMainIngredientOriginField.INGREDIENTS_TEXT:
      return 4
    default:
      return 2
  }
}
