import { gql } from '@apollo/client'

import {
  DISHES_SUMMARY_FRAGMENT,
  SERVICING_HOUR_FRAGMENT,
  SIMPLE_USER_FRAGMENT,
} from '../../../common/graphql'

import { BRAND_ENTRY_FRAGMENT } from '../../../brands/graphql'

import LOCATION_DISH_FRAGMENT from './locationDishFragment'
import LOCATION_ENTRY_FRAGMENT from './locationEntryFragment'

export default gql`
  fragment LocationFragment on Location {
    ...LocationEntryFragment
    menuSource
    menuLink
    zipCode
    email
    phone
    latitude
    longitude
    openingHours
    updatedAt
    servicingHoursOverriden

    updatedBy {
      ...SimpleUserFragment
    }

    locationType {
      ...SimpleEntityFragment
    }

    cuisineType {
      ...SimpleEntityFragment
    }

    dishesSummary {
      ...DishesSummaryFragment
    }

    dishes {
      rows {
        ...LocationDishFragment
      }
      count
    }

    servicingHours {
      ...ServicingHourFragment
    }

    brand {
      ...BrandEntryFragment
    }

    workingHours {
      disabled
      dayOfWeek
      timeSlots {
        openTime
        closeTime
        kitchenCloseTime
      }
      isDefault
    }
  }
  ${LOCATION_ENTRY_FRAGMENT}
  ${SIMPLE_USER_FRAGMENT}
  ${LOCATION_DISH_FRAGMENT}
  ${SERVICING_HOUR_FRAGMENT}
  ${BRAND_ENTRY_FRAGMENT}
  ${DISHES_SUMMARY_FRAGMENT}
`
