import React from 'react'
import { Space, Tooltip, Typography } from 'antd'
import { includes, isNil } from 'lodash'

import {
  formatDateFromNow,
  formatDateWithoutYear,
} from '../../../../core/utils'

import { CURATION_STATUS } from '../../../../common/constants'

import {
  BRAND_PENDING_STATUS,
  BRAND_PENDING_STATUS_LABELS,
} from '../../../constants'
import { brandPropType } from '../../../propTypes'

const BrandTableActivityStatus = ({ brandOrLocation }) => {
  const { brandId, pendingStatus, status, updatedAt, lastImportedAt } =
    brandOrLocation

  if (brandId) {
    return null
  }

  if (
    includes(
      [
        BRAND_PENDING_STATUS.MENU_IMPORT_ERROR,
        BRAND_PENDING_STATUS.BATCH_CURATION_ERROR,
        BRAND_PENDING_STATUS.DUPLICATE_ERROR,
        BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_ERROR,
        BRAND_PENDING_STATUS.EXPORT_WORDS_ERROR,
      ],
      pendingStatus,
    )
  ) {
    return (
      <Space>
        <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
          <circle cx="15" cy="15" r="10" fill="#ff9f00" />
        </svg>
        <div>
          <Typography.Paragraph style={{ margin: 0 }}>
            {BRAND_PENDING_STATUS_LABELS[pendingStatus]}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>
            {!isNil(lastImportedAt) && lastImportedAt > updatedAt ? (
              <Tooltip title={formatDateWithoutYear(lastImportedAt)}>
                {formatDateFromNow(lastImportedAt)}
              </Tooltip>
            ) : (
              <Tooltip title={formatDateWithoutYear(updatedAt)}>
                {formatDateFromNow(updatedAt)}
              </Tooltip>
            )}
          </Typography.Paragraph>
        </div>
      </Space>
    )
  }
  if (
    includes(
      [
        BRAND_PENDING_STATUS.MENU_IMPORT_PENDING,
        BRAND_PENDING_STATUS.BATCH_CURATION_PENDING,
        BRAND_PENDING_STATUS.MISC_AND_CHOICE_PENDING,
        BRAND_PENDING_STATUS.DUPLICATE_PENDING,
        BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_PENDING,
        BRAND_PENDING_STATUS.EXPORT_WORDS_PENDING,
      ],
      pendingStatus,
    )
  ) {
    return (
      <Space>
        <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
          <circle cx="15" cy="15" r="10" fill="#e21b1b" />
        </svg>
        <div>
          <Typography.Text>
            {BRAND_PENDING_STATUS_LABELS[pendingStatus]}...
          </Typography.Text>
        </div>
      </Space>
    )
  }

  let doneOperation =
    !isNil(lastImportedAt) && lastImportedAt > updatedAt
      ? BRAND_PENDING_STATUS_LABELS.MENU_IMPORT_DONE
      : undefined

  if (status === CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value) {
    doneOperation = BRAND_PENDING_STATUS_LABELS.MISC_AND_CHOICE_DONE
  } else if (status === CURATION_STATUS.CURATION_CONFIRMATION.value) {
    doneOperation = BRAND_PENDING_STATUS_LABELS.CURATION_DONE
  }

  return (
    <Space>
      <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30">
        <circle cx="15" cy="15" r="10" fill="#49c634" />
      </svg>
      <div>
        <Typography.Paragraph style={{ margin: 0 }}>
          {doneOperation ?? 'Done'}
        </Typography.Paragraph>
        <Typography.Paragraph style={{ margin: 0 }}>
          {!isNil(lastImportedAt) && lastImportedAt > updatedAt ? (
            <Tooltip title={formatDateWithoutYear(lastImportedAt)}>
              {formatDateFromNow(lastImportedAt)}
            </Tooltip>
          ) : (
            <Tooltip title={formatDateWithoutYear(updatedAt)}>
              {formatDateFromNow(updatedAt)}
            </Tooltip>
          )}
        </Typography.Paragraph>
      </div>
    </Space>
  )
}

BrandTableActivityStatus.propTypes = {
  brandOrLocation: brandPropType.isRequired,
}

export default BrandTableActivityStatus
