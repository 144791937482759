import { gql } from '@apollo/client'

import CLEANUP_TASK_BATCH_FRAGMENT from './cleanupTaskBatchFragment'

const CLEANUP_TASK_FRAGMENT = gql`
  fragment CleanupTaskFragment on CleanupTask {
    id
    name
    type
    updatedAt
    createdAt
    batches {
      ...CleanupTaskBatchFragment
    }
    createdBy {
      id
      name
    }
    blockedById
  }
  ${CLEANUP_TASK_BATCH_FRAGMENT}
`

export default CLEANUP_TASK_FRAGMENT
