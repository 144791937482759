import update from 'immutability-helper'
import { get, isNil, omit } from 'lodash'

const aiSuggestionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_AI_SUGGESTIONS': {
      const { ownerId, aiSuggestions, type } = action.payload

      if (!get(state, type)) {
        return update(state, {
          $set: {
            [type]: {
              [ownerId]: { [aiSuggestions.key]: aiSuggestions.values },
            },
          },
        })
      }

      const existingOwner = get(state, `${type}.${ownerId}`)

      if (isNil(existingOwner)) {
        return update(state, {
          [type]: {
            $set: {
              ...state[type],
              [ownerId]: { [aiSuggestions.key]: aiSuggestions.values },
            },
          },
        })
      }

      return update(state, {
        [type]: {
          [ownerId]: {
            $set: {
              ...existingOwner,
              [aiSuggestions.key]: aiSuggestions.values,
            },
          },
        },
      })
    }

    case 'REMOVE_UNSAVED_AI_SUGGESTION_FOR_OWNER': {
      const { ownerId, type } = action.payload
      return update(state, { [type]: { $set: omit(state[type], ownerId) } })
    }

    default: {
      return state
    }
  }
}

export default aiSuggestionReducer
