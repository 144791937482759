import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DeleteOutlined } from '@ant-design/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Space, Typography } from 'antd'
import { find, get, includes, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'

import {
  Checkbox,
  RichTextInput,
  Select,
  TreeSelect,
} from '../../../../core/components'

import { PERMISSIONS } from '../../../../common/constants'
import { createSelectOptionsFromObject } from '../../../../common/helpers'
import {
  useCurationMetadata,
  useUserHasPermissions,
} from '../../../../common/hooks'
import { compositeDecoratorFactory } from '../../../../common/utils'

import {
  BRAND_SUGGESTED_WORD_INGREDIENT_KEYWORD_MATCH_TYPE_VALUES,
  BRAND_SUGGESTED_WORD_TYPE,
  BRAND_SUGGESTED_WORD_TYPE_OPTIONS,
  LABEL_LEGENDS_MATCH_TYPE,
} from '../../../constants'
import { brandSuggestedWordPropType } from '../../../propTypes'
import { brandSuggestedWordValidationSchema } from '../../../schemas'
import { originalTextStrategyHandler } from '../../../services'
import { brandSuggestedWordTransformer } from '../../../transformers'
import { OriginalTextDecorator } from '../../atoms'

import brandSuggestedWordFormStyles from './brandSuggestedWordFormStyles'

const BrandSuggestedWordForm = ({
  brandSuggestedWord,
  isDisabled,
  isConfirmed,
  onDelete,
  onConfirm,
}) => {
  const hasPermissionToDelete = useUserHasPermissions(
    PERMISSIONS.BRANDS.DELETE_SUGGESTED_WORD,
  )

  const { allergenOptions, dietOptions, ingredientOptions, languageOptions } =
    useCurationMetadata()

  const brandSuggestedWordCardDetails = useMemo(
    () => brandSuggestedWordTransformer.toCardDetails(brandSuggestedWord),
    [brandSuggestedWord],
  )

  const {
    context,
    payload,
    translation,
    labelLegendMatchType,
    qaCorrectionCounts,
  } = brandSuggestedWordCardDetails

  const { posIndex } = payload

  const initialValues = useMemo(
    () =>
      brandSuggestedWordTransformer.toInitialValues(
        brandSuggestedWordCardDetails,
      ),
    [brandSuggestedWordCardDetails],
  )

  const {
    formState: { isDirty, errors, isSubmitting, ...restOfFormState },
    handleSubmit,
    watch,
    reset,
    ...restFormMethods
  } = useForm({
    resolver: yupResolver(
      brandSuggestedWordValidationSchema(context, posIndex),
    ),
    defaultValues: initialValues,
    mode: 'onChange',
  })

  const typeValue = watch('type')

  const compositeDecorator = useMemo(
    () =>
      compositeDecoratorFactory.create(
        originalTextStrategyHandler(posIndex),
        OriginalTextDecorator,
      ),
    [posIndex],
  )

  const initialLanguage = useMemo(
    () => find(languageOptions, lang => lang.id === initialValues.languageId),
    [initialValues.languageId, languageOptions],
  )

  const brandSuggestedWordTypeOptions = useMemo(
    () => createSelectOptionsFromObject(BRAND_SUGGESTED_WORD_TYPE_OPTIONS),
    [],
  )

  const isLanguageSelectShown = useMemo(
    () =>
      includes(
        [
          BRAND_SUGGESTED_WORD_TYPE.NEW_INGREDIENT,
          BRAND_SUGGESTED_WORD_TYPE.EXISTING_INGREDIENT,
          BRAND_SUGGESTED_WORD_TYPE.COMMON_FOREIGN_WORD,
        ],
        typeValue,
      ),
    [typeValue],
  )

  const isIngredientSelectsShown = useMemo(
    () =>
      includes(
        [
          BRAND_SUGGESTED_WORD_TYPE.NEW_INGREDIENT,
          BRAND_SUGGESTED_WORD_TYPE.EXISTING_INGREDIENT,
        ],
        typeValue,
      ),
    [typeValue],
  )

  const isIngredientMatchTypeShown = useMemo(
    () =>
      includes(
        [
          BRAND_SUGGESTED_WORD_TYPE.NEW_INGREDIENT,
          BRAND_SUGGESTED_WORD_TYPE.EXISTING_INGREDIENT,
        ],
        typeValue,
      ),
    [typeValue],
  )

  const isAllergenSelectShown = useMemo(
    () => typeValue === BRAND_SUGGESTED_WORD_TYPE.ALLERGEN_LABEL,
    [typeValue],
  )

  const isDietSelectShown = useMemo(
    () => typeValue === BRAND_SUGGESTED_WORD_TYPE.DIET_LABEL,
    [typeValue],
  )

  const ingredientKeywordMatchTypeOptions = useMemo(
    () =>
      map(BRAND_SUGGESTED_WORD_INGREDIENT_KEYWORD_MATCH_TYPE_VALUES, value => ({
        value,
        label: value,
      })),
    [],
  )

  const confirmButtonText = useMemo(() => {
    if (isDisabled) {
      return 'Confirmed'
    }

    return isConfirmed ? 'Unconfirm' : 'Confirm'
  }, [isDisabled, isConfirmed])

  const isFormDisabled = useMemo(
    () => isDisabled || isConfirmed,
    [isDisabled, isConfirmed],
  )

  const handleReset = useCallback(() => reset(), [reset])

  return (
    <FormProvider
      {...restFormMethods}
      formState={{ isDirty, errors, isSubmitting, ...restOfFormState }}
      watch={watch}
      handleSubmit={handleSubmit}
    >
      <form>
        <RichTextInput
          className={
            qaCorrectionCounts?.value > 0 ? 'highlight-red' : undefined
          }
          name="context"
          disabled={isFormDisabled}
          toolbarItems={['bold']}
          onlySelection
          decorator={compositeDecorator}
          maxEditorHeight={90}
        />
        {!isEmpty(translation) && !isEmpty(initialLanguage) && (
          <Typography.Text style={brandSuggestedWordFormStyles.translation}>
            ({initialLanguage.name} - {translation.text})
          </Typography.Text>
        )}
        <Select
          className={qaCorrectionCounts?.type > 0 ? 'highlight-red' : undefined}
          allowClear={false}
          disabled={isFormDisabled}
          name="type"
          size="small"
          options={brandSuggestedWordTypeOptions}
          style={brandSuggestedWordFormStyles.select}
        />
        <Checkbox
          label="Context dependant"
          name="isContextDependant"
          disabled={
            isFormDisabled || brandSuggestedWord.isContextDependantFromAi
          }
        />
        {isAllergenSelectShown && (
          <Select
            className={
              qaCorrectionCounts?.allergenId > 0 ? 'highlight-red' : undefined
            }
            allowClear={false}
            disabled={isFormDisabled}
            name="allergenId"
            size="small"
            options={allergenOptions}
            style={brandSuggestedWordFormStyles.select}
            labelAbove
            smallLabel
            label={
              labelLegendMatchType && (
                <Typography.Text>
                  Allergen Label/{' '}
                  <Typography.Text
                    style={brandSuggestedWordFormStyles.matchType}
                  >
                    {get(LABEL_LEGENDS_MATCH_TYPE, labelLegendMatchType)}
                  </Typography.Text>
                </Typography.Text>
              )
            }
          />
        )}
        {isDietSelectShown && (
          <Select
            allowClear={false}
            className={
              qaCorrectionCounts?.dietId > 0 ? 'highlight-red' : undefined
            }
            disabled={isFormDisabled}
            name="dietId"
            size="small"
            options={dietOptions}
            style={brandSuggestedWordFormStyles.select}
            labelAbove
            smallLabel
            label={
              labelLegendMatchType && (
                <Typography.Text>
                  Diet Label/{' '}
                  <Typography.Text
                    style={brandSuggestedWordFormStyles.matchType}
                  >
                    {get(LABEL_LEGENDS_MATCH_TYPE, labelLegendMatchType)}
                  </Typography.Text>
                </Typography.Text>
              )
            }
          />
        )}
        {isIngredientSelectsShown && (
          <TreeSelect
            allowClear={false}
            className={
              qaCorrectionCounts?.ingredientId > 0 ? 'highlight-red' : undefined
            }
            disabled={isFormDisabled}
            name="ingredientId"
            treeData={ingredientOptions}
            size="small"
            style={brandSuggestedWordFormStyles.select}
          />
        )}
        {isIngredientMatchTypeShown && (
          <Select
            className={
              qaCorrectionCounts?.ingredientKeywordMatchType > 0
                ? 'highlight-red'
                : undefined
            }
            disabled={isFormDisabled}
            allowClear={false}
            name="ingredientKeywordMatchType"
            size="small"
            options={ingredientKeywordMatchTypeOptions}
            style={brandSuggestedWordFormStyles.select}
          />
        )}
        {isLanguageSelectShown && (
          <Select
            defaultValue={initialLanguage?.id}
            className={
              qaCorrectionCounts?.languageId > 0 ? 'highlight-red' : undefined
            }
            disabled={isFormDisabled}
            allowClear={false}
            name="languageId"
            size="small"
            options={languageOptions}
            placeholder="Select a language..."
            style={brandSuggestedWordFormStyles.select}
          />
        )}
        <Space direction="vertical" style={{ width: '100%' }}>
          {!isDisabled && !isConfirmed && (
            <Space>
              <Button
                loading={isSubmitting}
                disabled={isDisabled || !isEmpty(errors)}
                onClick={handleSubmit(onConfirm)}
              >
                {confirmButtonText}
              </Button>
              <Button disabled={!isDirty} onClick={handleReset}>
                Cancel
              </Button>
            </Space>
          )}

          {!isDisabled && isConfirmed && (
            <Button
              loading={isSubmitting}
              disabled={isDisabled || !isEmpty(errors)}
              onClick={handleSubmit(onConfirm)}
            >
              {confirmButtonText}
            </Button>
          )}

          {hasPermissionToDelete && (
            <Button
              loading={isSubmitting}
              disabled={isDisabled || !isEmpty(errors)}
              onClick={onDelete}
              icon={<DeleteOutlined />}
            >
              Delete word
            </Button>
          )}
        </Space>
      </form>
    </FormProvider>
  )
}

BrandSuggestedWordForm.propTypes = {
  brandSuggestedWord: brandSuggestedWordPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isConfirmed: PropTypes.bool,
}

BrandSuggestedWordForm.defaultProps = {
  isDisabled: false,
  isConfirmed: false,
}

export default BrandSuggestedWordForm
