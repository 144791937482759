import React, { useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { EnterOutlined } from '@ant-design/icons'
import { Col, Pagination, Row } from 'antd'
import { get, isEmpty, isNil, join } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import {
  DEFAULT_PAGINATION_CONFIG,
  METADATA_TYPES,
} from '../../../../common/constants'
import {
  useAuthentication,
  useCurationMetadata,
} from '../../../../common/hooks'

import { AVAILABLE_HOTKEYS, CLEANUP_METADATA_FIELDS } from '../../../constants'
import { UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION } from '../../../graphql'
import { cleanupTasksMetadataTypePropType } from '../../../propTypes'
import { CleanupTreeSelect } from '../../molecules'

const styles = {
  selectAll: {
    width: 200,
    marginRight: 8,
  },
  selectAllIcon: {
    marginRight: 8,
    fontSize: 24,
    color: '#434343',
  },
  paginationContainer: { display: 'flex', justifyContent: 'flex-end' },
  cleanupListFooter: { padding: 8 },
}

const CleanupTaskPagination = ({
  total,
  metadataType,
  selectedItems,
  onBatchOperationComplete,
  disabled,
  isTaskCurationDone,
}) => {
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()
  const {
    ingredientOptions,
    dishTypeOptions,
    courseTypeOptions,
    locationTypeOptions,
    cuisineTypeOptions,
  } = useCurationMetadata()
  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    setQueryParams,
  } = useQueryParams()

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.INGREDIENTS]: ingredientOptions,
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.LOCATION_TYPES]: locationTypeOptions,
      [METADATA_TYPES.CUISINE_TYPES]: cuisineTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
    }),
    [
      ingredientOptions,
      courseTypeOptions,
      cuisineTypeOptions,
      dishTypeOptions,
      locationTypeOptions,
    ],
  )

  const handlePaginationChange = useCallback(
    (newPage, size) =>
      setQueryParams({
        page: size !== pageSize ? DEFAULT_PAGINATION_CONFIG.PAGE : newPage,
        pageSize: size,
      }),
    [pageSize, setQueryParams],
  )

  useHotkeys(
    join(
      [AVAILABLE_HOTKEYS.CMD_RIGHT.hotkey, AVAILABLE_HOTKEYS.CTRL_RIGHT.hotkey],
      ', ',
    ),
    event => {
      event.preventDefault()
      const nextPage = Number(page) + 1
      if (nextPage > Math.ceil(total / pageSize)) {
        return
      }

      handlePaginationChange(nextPage)
    },
    [total, pageSize, handlePaginationChange, page],
  )

  useHotkeys(
    join(
      [AVAILABLE_HOTKEYS.CMD_LEFT.hotkey, AVAILABLE_HOTKEYS.CTRL_LEFT.hotkey],
      ', ',
    ),
    event => {
      event.preventDefault()
      const prevPage = Number(page) - 1
      if (prevPage < 1) {
        return
      }

      handlePaginationChange(prevPage)
    },
    [page, handlePaginationChange],
  )

  return (
    <Row justify="end" style={styles.cleanupListFooter}>
      <Col span={8} style={styles.paginationContainer}>
        {!isNil(onBatchOperationComplete) && (
          <>
            <EnterOutlined rotate="90" style={styles.selectAllIcon} />
            <CleanupTreeSelect
              ids={selectedItems}
              disabled={disabled || isEmpty(selectedItems)}
              metadataType={metadataType}
              mutation={UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION}
              fieldName={get(CLEANUP_METADATA_FIELDS, metadataType)}
              defaultValue={null}
              multiple={metadataType === METADATA_TYPES.INGREDIENTS}
              style={styles.selectAll}
              onComplete={onBatchOperationComplete}
              treeData={curationMetadata[metadataType]}
              isBatchSelect
              isTaskCurationDone={isTaskCurationDone}
              showSubmitButton={metadataType === METADATA_TYPES.INGREDIENTS}
            />
          </>
        )}

        <Pagination
          current={Number(page)}
          pageSize={pageSize}
          total={total}
          onChange={handlePaginationChange}
          pageSizeOptions={DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS}
          showSizeChanger
          onShowSizeChange={handlePaginationChange}
          size="small"
        />
      </Col>
    </Row>
  )
}

CleanupTaskPagination.propTypes = {
  total: PropTypes.number,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onBatchOperationComplete: PropTypes.func,
  disabled: PropTypes.bool,
  isTaskCurationDone: PropTypes.bool,
}

CleanupTaskPagination.defaultProps = {
  total: undefined,
  selectedItems: [],
  disabled: false,
  isTaskCurationDone: false,
  onBatchOperationComplete: undefined,
}

export default CleanupTaskPagination
