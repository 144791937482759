import { isEmpty, isNil } from 'lodash'

const miscRegex = /<u>(.*?)<\/u>/g
const choiceRegex = /<em>(.*?)<\/em>/g
const boldRegex = /<strong>(.*?)<\/strong>/g

export const extractMiscText = text =>
  (text || '').replace('/\n/g', ' ').match(miscRegex)
export const extractBoldText = text =>
  (text || '').replaceAll('\n', '<br>').match(boldRegex)
export const extractChoiceText = text =>
  (text || '').replace('/\n/g', ' ').match(choiceRegex)

export const containsMiscOrChoiceText = text =>
  !isEmpty(extractChoiceText(text)) || !isEmpty(extractMiscText(text))

export const clearMiscAndChoice = styledText =>
  isNil(styledText)
    ? styledText
    : styledText
        .replaceAll('<u>', '')
        .replaceAll('</u>', '')
        .replaceAll('<em>', '')
        .replaceAll('</em>', '')
