const BRAND_MATCH_TYPE = Object.freeze({
  DIET_LABEL_LEGEND_EXACT: 'dietLabelLegend_exact',
  DIET_LABEL_LEGEND_ALREADY_FOUND: 'dietLabelLegend_alreadyFound',
  ALLERGEN_LABEL_LEGEND_EXACT: 'allergenLabelLegend_exact',
  ALLERGEN_LABEL_LEGEND_ALREADY_FOUND: 'allergenLabelLegend_alreadyFound',
  DIET_LABEL_LEGEND_EXACT_CHOICE: 'dietLabelLegend_exact_choice',
  DIET_LABEL_LEGEND_ALREADY_FOUND_CHOICE: 'dietLabelLegend_alreadyFound_choice',
  ALLERGEN_LABEL_LEGEND_EXACT_CHOICE: 'allergenLabelLegend_exact_choice',
  ALLERGEN_LABEL_LEGEND_ALREADY_FOUND_CHOICE:
    'allergenLabelLegend_alreadyFound_choice',
})

export default BRAND_MATCH_TYPE
