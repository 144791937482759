import { gql } from '@apollo/client'

export default gql`
  fragment BrandMenuTitleFragment on BrandMenuTitleNode {
    id
    name
    parentId
    menuId
    parents
    path
    depth
    children {
      id
      name
      parentId
      menuId
      status
      description
      styledDescription
      dietDescriptors
      addonDescriptors
      miscDescriptors
      nutritionsDescriptors
      allergenDescriptors
      publishedDishCount
      pendingDishCount
      automaticIgnoredDishCount
      manualIgnoredDishCount
      dishTypeId
      parents
      path
      depth
      children {
        id
        name
        parentId
        menuId
        status
        description
        styledDescription
        dietDescriptors
        addonDescriptors
        miscDescriptors
        nutritionsDescriptors
        allergenDescriptors
        publishedDishCount
        pendingDishCount
        automaticIgnoredDishCount
        manualIgnoredDishCount
        dishTypeId
        parents
        path
        depth
        children {
          id
          name
          parentId
          menuId
          status
          description
          styledDescription
          dietDescriptors
          addonDescriptors
          miscDescriptors
          nutritionsDescriptors
          allergenDescriptors
          publishedDishCount
          pendingDishCount
          automaticIgnoredDishCount
          manualIgnoredDishCount
          dishTypeId
          parents
          path
          depth
          children {
            id
            name
            parentId
            menuId
            status
            description
            styledDescription
            dietDescriptors
            addonDescriptors
            miscDescriptors
            nutritionsDescriptors
            allergenDescriptors
            publishedDishCount
            pendingDishCount
            automaticIgnoredDishCount
            manualIgnoredDishCount
            dishTypeId
            parents
            path
            depth
            children {
              id
              name
              parentId
              menuId
              description
              styledDescription
              dietDescriptors
              addonDescriptors
              miscDescriptors
              nutritionsDescriptors
              allergenDescriptors
              publishedDishCount
              pendingDishCount
              automaticIgnoredDishCount
              manualIgnoredDishCount
              dishTypeId
              parents
              path
              depth
            }
          }
        }
      }
    }
  }
`
