import { gql } from '@apollo/client'

import { AI_SETTING_FRAGMENT } from '../fragments'

const UPDATE_AI_SETTINGS_MUTATION = gql`
  mutation updateAiSettingsMutation($input: [AiSettingInput!]!) {
    updateAiSettings(input: $input) {
      ...AiSettingFragment
    }
  }
  ${AI_SETTING_FRAGMENT}
`
export default UPDATE_AI_SETTINGS_MUTATION
