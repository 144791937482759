import React from 'react'
import { Col, Row } from 'antd'

import { GridFormItem } from '../../../../core/components'

import { ServicingHours } from '../../../../common/components'

import { locationColumnWidths } from '../../../constants'
import { usePersistedLocationField } from '../../../hooks'
import { locationPropType } from '../../../propTypes'
import {
  setLocationServicingHours,
  setLocationServicingHoursOverriden,
  setLocationWorkingHours,
} from '../../../redux'
import { OverridableCheckbox, WorkingHours } from '../../molecules'

const LocationEntryHoursAndMenuTitles = ({ location }) => {
  const {
    id: locationId,
    workingHours,
    servicingHours,
    servicingHoursOverriden,
  } = location

  const {
    servicingHours: persistedServicingHours,
    onValueChanged: servicingHoursOnChange,
  } = usePersistedLocationField(
    locationId,
    'servicingHours',
    setLocationServicingHours,
  )
  const {
    servicingHoursOverriden: persistedServicingHoursOverriden,
    onValueChanged: servicingHoursOverridenOnChange,
  } = usePersistedLocationField(
    locationId,
    'servicingHoursOverriden',
    setLocationServicingHoursOverriden,
  )

  const {
    workingHours: persistedWorkingHours,
    onValueChanged: workingHoursOnChange,
  } = usePersistedLocationField(
    locationId,
    'workingHours',
    setLocationWorkingHours,
  )

  return (
    <Row gutter={16}>
      <GridFormItem
        span={12}
        label="Opening hours"
        width={locationColumnWidths.openingHours}
      >
        <WorkingHours
          workingHours={workingHours}
          newWorkingHours={persistedWorkingHours}
          onValueChanged={workingHoursOnChange}
        />
      </GridFormItem>
      <GridFormItem
        span={12}
        label="Servicing hours"
        width={locationColumnWidths.servicingHours}
      >
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Col span={24}>
              <OverridableCheckbox
                checked={servicingHoursOverriden}
                newChecked={persistedServicingHoursOverriden}
                onValueChanged={servicingHoursOverridenOnChange}
                text="Override servicing hours from Brand"
              />
            </Col>
            <ServicingHours
              servicingHours={servicingHours}
              newServicingHours={persistedServicingHours}
              onValueChanged={servicingHoursOnChange}
              disabled={
                persistedServicingHoursOverriden === false ||
                servicingHoursOverriden === false
              }
              showAddServicingTimeButton={
                persistedServicingHoursOverriden === true ||
                servicingHoursOverriden === true
              }
            />
          </Col>
        </Row>
      </GridFormItem>
    </Row>
  )
}

LocationEntryHoursAndMenuTitles.propTypes = {
  location: locationPropType,
}

LocationEntryHoursAndMenuTitles.defaultProps = {
  location: {},
}

export default LocationEntryHoursAndMenuTitles
