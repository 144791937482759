import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from '../../../brands/graphql'

const LOCATION_TYPE_HUMAN_CLEANUP_FRAGMENT = gql`
  fragment LocationTypeHumanCleanupFragment on LocationTypeHumanCleanup {
    id
    brand {
      ...BrandFragment
    }
    isCurationDone
    isQaDone
    cleanupLocationTypeId
  }
  ${BRAND_FRAGMENT}
`

export default LOCATION_TYPE_HUMAN_CLEANUP_FRAGMENT
