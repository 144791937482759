import React, { memo } from 'react'
import PropTypes from 'prop-types'

const styles = {
  stickyTreeNodeStyle: {
    backgroundColor: '#fff5e6',
    fontWeight: 'bold',
  },
  ellipsedTitle: {
    width: '250px',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '-5px',
  },
}

const TreeNodeTitle = ({ title, isSticky }) => (
  <div
    style={{
      ...styles.ellipsedTitle,
      ...(isSticky ? styles.stickyTreeNodeStyle : {}),
    }}
  >
    {title}
  </div>
)

TreeNodeTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isSticky: PropTypes.bool,
}

TreeNodeTitle.defaultProps = {
  isSticky: false,
}

export default memo(TreeNodeTitle)
