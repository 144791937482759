import { gql } from '@apollo/client'

const LANGUAGE_FRAGMENT = gql`
  fragment LanguageFragment on Language {
    id
    name
    code
  }
`

export default LANGUAGE_FRAGMENT
