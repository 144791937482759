import { forEach, toLower } from 'lodash'

import { findWordIndexInDraftJsText } from '../../common/helpers'

export const handleMenuTitleHighlightStrategy =
  words => (contentBlock, callback) => {
    const inputText = toLower(contentBlock.getText())

    forEach(words, word => {
      let startIndex = 0
      let start

      while (start > -1) {
        callback(start, start + word.word.length)
        startIndex = start + word.word.length

        start = findWordIndexInDraftJsText(inputText, word.word, startIndex)
      }
    })
  }
