import { gql } from '@apollo/client'

import { SIMPLE_ENTITY_FRAGMENT } from '../fragments'

const USERS_WITH_KNOWLEDGE_QUERY = gql`
  query users($filters: UserFiltersInput) {
    users(filters: $filters) {
      id
      name
      email
      cuisineTypes {
        ...SimpleEntityFragment
      }
      languages {
        ...SimpleEntityFragment
      }
      roles {
        id
        name
      }
      isActive
      seniority
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
`

export default USERS_WITH_KNOWLEDGE_QUERY
