import { isNil, map } from 'lodash'

export const updateChildrenPath = (menuTitle, path) =>
  map(menuTitle.children, (child, index) => ({
    ...child,
    path: `${path}.children[${index}]`,
    children: isNil(child.children)
      ? undefined
      : updateChildrenPath(child, `${path}.children[${index}]`),
  }))
