import React, { memo, useMemo } from 'react'
import { Button, Typography } from 'antd'
import { get, isEmpty, isNil, map, sortBy } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { useQueryParams } from '../../../../core/hooks'

import { EVENT_TYPES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DISH_FIELDS } from '../../../constants'
import {
  useBrandDish,
  useDishFields,
  usePersistedDishField,
} from '../../../hooks'
import {
  setDishAdditionalIngredients,
  setDishChoiceIngredients,
  setDishMainIngredients,
} from '../../../redux'
import { dishIngredientForDisplayTransformer } from '../../../transformers'
import { DishModalIngredientsSelect } from '../../molecules'

const styles = {
  marginTop: { marginTop: '1rem' },
}

const options = {
  [DISH_FIELDS.MAIN_INGREDIENTS]: {
    fieldName: 'mainIngredients',
    displayTransformer:
      dishIngredientForDisplayTransformer.transformMainOrChoiceIngredientForDisplay,
    action: setDishMainIngredients,
    label: 'Main Ingredients',
  },
  [DISH_FIELDS.ADDITIONAL_INGREDIENTS]: {
    fieldName: 'additionalIngredients',
    displayTransformer:
      dishIngredientForDisplayTransformer.transformAdditionIngredientForDisplay,
    action: setDishAdditionalIngredients,
    label: 'Additional Ingredients',
  },
  [DISH_FIELDS.CHOICE_INGREDIENTS]: {
    fieldName: 'choiceIngredients',
    displayTransformer:
      dishIngredientForDisplayTransformer.transformMainOrChoiceIngredientForDisplay,
    action: setDishChoiceIngredients,
    label: 'Choice Ingredients',
  },
}

const DishIngredientsTypeSection = ({ dishIngredientsField, disabled }) => {
  const { selectedDishId } = useQueryParams()
  const { dish } = useBrandDish(selectedDishId)

  const { fieldName, displayTransformer, action, label } =
    options[dishIngredientsField] || {}

  const dishIngredients = get(dish, fieldName)

  const { shouldDisableField } = useDishFields(
    dish?.status,
    null,
    null,
    null,
    disabled,
  )

  const { ingredientOptions, flattenedIngredientOptions } =
    useCurationMetadata()

  const { [fieldName]: persistedIngredients, onValueChanged: onChangeAction } =
    usePersistedDishField(fieldName, action)

  const savedIngredientsForDisplay = useMemo(
    () => sortBy(map(dishIngredients, displayTransformer), 'priority'),
    [dishIngredients, displayTransformer],
  )

  const persistedIngredientsForDisplay = useMemo(
    () =>
      !isNil(persistedIngredients)
        ? sortBy(map(persistedIngredients, displayTransformer), 'priority')
        : null,
    [displayTransformer, persistedIngredients],
  )

  const ingredientsLabel = useMemo(() => {
    if (shouldDisableField(dishIngredientsField)) {
      return !isEmpty(savedIngredientsForDisplay) ? label : null
    }

    return !isEmpty(persistedIngredientsForDisplay) ||
      (!persistedIngredientsForDisplay &&
        !isEmpty(savedIngredientsForDisplay)) ? (
      <>
        <Typography.Text>{label}</Typography.Text>
        <Button
          type="link"
          onClick={() => {
            googleAnalyticsEventsService.fireEvent(
              EVENT_TYPES.BRAND_DISH_EVENTS.REMOVE_ALL_INGREDIENTS,
              {
                for: dishIngredientsField,
              },
            )
            onChangeAction([])
          }}
        >
          × Remove all
        </Button>
      </>
    ) : (
      label
    )
  }, [
    dishIngredientsField,
    onChangeAction,
    label,
    persistedIngredientsForDisplay,
    savedIngredientsForDisplay,
    shouldDisableField,
  ])

  return (
    ingredientsLabel && (
      <GridFormItem
        smallLabel
        style={styles.marginTop}
        label={ingredientsLabel}
      >
        <DishModalIngredientsSelect
          size="small"
          treeData={ingredientOptions}
          flattenedTreeData={flattenedIngredientOptions}
          ingredients={savedIngredientsForDisplay}
          newIngredients={persistedIngredientsForDisplay}
          onValueChanged={onChangeAction}
          disabled={shouldDisableField(dishIngredientsField)}
          showWord
        />
      </GridFormItem>
    )
  )
}

DishIngredientsTypeSection.propTypes = {
  dishIngredientsField: PropTypes.oneOf([
    DISH_FIELDS.MAIN_INGREDIENTS,
    DISH_FIELDS.ADDITIONAL_INGREDIENTS,
    DISH_FIELDS.CHOICE_INGREDIENTS,
  ]).isRequired,
  disabled: PropTypes.bool,
}

DishIngredientsTypeSection.defaultProps = {
  disabled: false,
}

export default memo(DishIngredientsTypeSection)
