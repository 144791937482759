import { join } from 'path'

import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_ROUTES } from '../../common/constants'

import { LocationDetails, NewLocation } from './pages'

export default function LocationsRouter() {
  return (
    <Switch>
      <Route
        exact
        path={join(
          APP_ROUTES.BRAND_ROUTES.BrandsWithValidation,
          APP_ROUTES.LOCATION_ROUTES.NewLocation,
        )}
        component={NewLocation}
      />
      <Route
        exact
        path={join(
          APP_ROUTES.BRAND_ROUTES.BrandsWithValidation,
          APP_ROUTES.LOCATION_ROUTES.LocationsWithValidation,
        )}
        component={LocationDetails}
      />
    </Switch>
  )
}
