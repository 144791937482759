import PropTypes from 'prop-types'

const MenuTitleTreeItemPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parentId: PropTypes.string,
  description: PropTypes.string,
  styledDescription: PropTypes.oneOfType([PropTypes.string]),
  dietDescriptors: PropTypes.arrayOf(PropTypes.object),
  addonDescriptors: PropTypes.arrayOf(PropTypes.string),
  miscDescriptors: PropTypes.arrayOf(PropTypes.string),
  nutritionsDescriptors: PropTypes.arrayOf(PropTypes.string),
  allergenDescriptors: PropTypes.arrayOf(PropTypes.object),
  publishedCount: PropTypes.number,
  pendingCount: PropTypes.number,
  automaticIgnoredDishCount: PropTypes.number,
  manualIgnoredDishCount: PropTypes.number,
})

MenuTitleTreeItemPropType.defaultProps = {
  parentId: null,
  description: '',
  styledDescription: '',
  dietDescriptors: [],
  addonDescriptors: [],
  miscDescriptors: [],
  nutritionsDescriptors: [],
  allergenDescriptors: [],
  publishedCount: undefined,
  pendingCount: undefined,
  automaticIgnoredDishCount: undefined,
  manualIgnoredDishCount: undefined,
}

MenuTitleTreeItemPropType.children = PropTypes.arrayOf(
  MenuTitleTreeItemPropType,
)

export default MenuTitleTreeItemPropType
