import { gql } from '@apollo/client'

const UPDATE_CLEANUP_TASK_ASSIGNED_QA_MUTATION = gql`
  mutation updateCleanupTaskAssignedQa(
    $id: ID!
    $metadataType: String!
    $userId: ID
  ) {
    updateCleanupTaskAssignedQa(
      id: $id
      metadataType: $metadataType
      userId: $userId
    ) {
      id
      assignedQaId
    }
  }
`

export default UPDATE_CLEANUP_TASK_ASSIGNED_QA_MUTATION
