const BRAND_SUGGESTED_WORDS_SORT_OPTIONS = Object.freeze({
  APPEARANCE: 'Appearance',
  FOREIGN_WORDS: 'Foreign words',
  DISH_NAME: 'Dish Name',
  DISH_DESCRIPTION: 'Dish Description',
  DISH_INGREDIENTS_TEXT: 'Dish Ingredients Text',
  MENU_TITLE_NAME: 'Menu Title Name',
  MENU_TITLE_DESCRIPTION: 'Menu Title Description',
  UNKNOWN: 'Unknown',
  PREPARATION_METHOD: 'Preparetion Method',
  COMMON_ENGLISH_WORD: 'Common English Word',
  COMMON_FOREIGN_WORD: 'Common Foreign Word',
  WHOLE_DISH_NAME: 'Whole Dish Name',
  NAME: 'Name',
  NOT_RELEVANT: 'Not Relevant',
  CONTEXT_DEPENDANT: 'Context dependant',
  NEW_INGREDIENT: 'New Ingredient',
  EXISTING_INGREDIENT: 'Existing Ingredient',
  DIET_LABEL: 'Diet Label',
  ALLERGEN_LABEL: 'Allergen Label',
  BLOCKERS: 'Blockers',
})

export default BRAND_SUGGESTED_WORDS_SORT_OPTIONS
