import { createContext } from 'react'
import { noop } from 'lodash'

export const defaultState = {
  selectedRowKey: null,
  selectedCellKey: null,
  hotkeyEditMode: false,
  isPopoverModeEnabled: false,
  setIsPopoverModeEnabled: noop,
  setHotkeyEditMode: noop,
}

export default createContext(defaultState)
