import { gql } from '@apollo/client'

const CLEANUP_TASKS_SUMMARY_QUERY = gql`
  query cleanupTasksSummary {
    cleanupTasksSummary {
      dishTypes
      courseTypes
      cuisineTypes
      locationTypes
    }
  }
`

export default CLEANUP_TASKS_SUMMARY_QUERY
