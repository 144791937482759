import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'

import { idNew } from '../../../../common/constants'

import { addUnsavedDish as addUnsavedDishAction } from '../../../redux'

const AddDishButton = ({
  dietIds,
  style,
  track,
  size,
  disabled,
  disabledMessage,
}) => {
  const dispatch = useDispatch()
  const { setQueryParams } = useQueryParams()
  const { brandId } = useParams()

  const handleClick = useCallback(async () => {
    track()
    dispatch(addUnsavedDishAction({ dishId: idNew, brandId, dietIds }))
    setQueryParams({ selectedDishId: idNew })
  }, [track, dispatch, brandId, dietIds, setQueryParams])

  return (
    <Tooltip title={disabled ? disabledMessage : undefined}>
      <Button
        disabled={disabled}
        size={size}
        style={style}
        onClick={handleClick}
      >
        Add dish
      </Button>
    </Tooltip>
  )
}

AddDishButton.propTypes = {
  style: PropTypes.shape({}),
  track: PropTypes.func.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  dietIds: PropTypes.arrayOf(PropTypes.string),
  disabledMessage: PropTypes.string,
}

AddDishButton.defaultProps = {
  style: undefined,
  size: undefined,
  disabled: false,
  disabledMessage: undefined,
  dietIds: [],
}

export default memo(AddDishButton)
