const BRAND_SUGGESTED_WORD_TYPE = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  PREPARATION_METHOD: 'PREPARATION_METHOD',
  COMMON_ENGLISH_WORD: 'COMMON_ENGLISH_WORD',
  COMMON_FOREIGN_WORD: 'COMMON_FOREIGN_WORD',
  WHOLE_DISH_NAME: 'WHOLE_DISH_NAME',
  NAME: 'NAME',
  NOT_RELEVANT: 'NOT_RELEVANT',
  NEW_INGREDIENT: 'NEW_INGREDIENT',
  EXISTING_INGREDIENT: 'EXISTING_INGREDIENT',
  DIET_LABEL: 'DIET_LABEL',
  ALLERGEN_LABEL: 'ALLERGEN_LABEL',
})

export default BRAND_SUGGESTED_WORD_TYPE
