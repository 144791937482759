import { gql } from '@apollo/client'

import { DISHES_SUMMARY_FRAGMENT } from '../../../common/graphql'

const DISHES_WITH_NO_MENU_TITLE_SUMMARY_QUERY = gql`
  query dishesWithNoMenuTitleSummaryQuery($brandId: ID!) {
    dishesWithNoMenuTitleSummary(brandId: $brandId) {
      ...DishesSummaryFragment
    }
  }
  ${DISHES_SUMMARY_FRAGMENT}
`

export default DISHES_WITH_NO_MENU_TITLE_SUMMARY_QUERY
