import { gql } from '@apollo/client'

export default gql`
  mutation updateUserSettingsMutation($userId: ID!, $settings: JSON!) {
    updateUserSettings(userId: $userId, settings: $settings) {
      accessToken
      refreshToken
    }
  }
`
