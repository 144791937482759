import React, { useCallback, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Col, notification, Pagination, Row, Typography } from 'antd'
import { get, isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams } from '../../../../core/hooks'
import { areSameValue, isStringTrue } from '../../../../core/utils'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
} from '../../../../common/constants'
import {
  useAuthentication,
  useUserHasBrandChangePermission,
} from '../../../../common/hooks'
import { LoadingProvider } from '../../../../common/providers'
import { findNodeBy, getMenuTitlesWithParents } from '../../../../common/utils'

import { MenuTitleTreeItemPropType } from '../../../../menuTitles/propTypes'
import { DEFAULT_DISH_LIST_FILTERS, DISH_SORT_BY } from '../../../constants'
import { BRAND_DISHES_QUERY } from '../../../graphql'
import { groupHighlightingWordByDishId } from '../../../helpers'
import { DishTableFocusActionsProvider } from '../../../providers'
import { DishMenuTitleFilter } from '../../molecules'
import { DishEditableVariousTextTable } from '../DishEditableVariousTextTable'
import { DishModal } from '../DishModal'
import { DishTable } from '../DishTable'

import './dishList.css'

const styles = {
  dishesListStyles: { borderTop: '1px solid #e8e8e8' },
  paginationContainer: { display: 'flex', justifyContent: 'flex-end' },
  dishesListFooter: { padding: 8 },
}

const DishList = ({
  isBrandDirty,
  highlightsFromLabels,
  refetchBrand,
  menuTitles,
  brandStatus,
  dishesWithNoMenuTitleSummary,
}) => {
  const { brandId } = useParams()

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    selectedDishId,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    sortDishesBy = DEFAULT_DISH_LIST_FILTERS.SORT_DISHES_BY,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
    setQueryParams,
  } = useQueryParams()

  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? brandStatus : null),
    [dishIsStatusSameAsBrandFilter, brandStatus],
  )
  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const {
    data: { brandDishes: { rows: dishList, count: totalDishCount } = {} } = {},
    loading,
    error,
  } = useQuery(BRAND_DISHES_QUERY, {
    variables: {
      brandId,
      paginationFiltersAndSorters: {
        pageSize: Number(pageSize),
        currentPage: Number(currentPage),
        filters: {
          status: statusForDishFiltering,
          servicingHours: servicingHoursFilter,
          published: dishPublishedFilter,
          ignored: dishIgnoredFilter,
          hasErrors: isStringTrue(dishHasErrorsFilter),
          menuTitle: menuTitleId,
          search,
        },
        sortDishesBy,
      },
    },
  })

  const isDishesSimple = useMemo(
    () =>
      sortDishesBy === DISH_SORT_BY.CERTAINTY &&
      (isStringTrue(dishIsStatusSameAsBrandFilter) ||
        isStringTrue(dishHasErrorsFilter)) &&
      CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value === brandStatus,
    [
      brandStatus,
      dishHasErrorsFilter,
      dishIsStatusSameAsBrandFilter,
      sortDishesBy,
    ],
  )

  const dishesWithMenuTitleParents = useMemo(
    () =>
      map(dishList, dish => {
        const selectedMenuTitle = dish.menuTitle
          ? findNodeBy(menuTitles, ({ id }) =>
              areSameValue(id, dish.menuTitle.id),
            )
          : undefined
        return {
          ...dish,
          menuTitleParents: getMenuTitlesWithParents(
            selectedMenuTitle,
            menuTitles,
          ),
        }
      }),
    [dishList, menuTitles],
  )

  const dishesHighlightWords = useMemo(() => {
    try {
      return groupHighlightingWordByDishId(
        dishesWithMenuTitleParents,
        isDishesSimple,
        highlightsFromLabels,
      )
    } catch (extractingWordError) {
      notification.error({
        title: 'An error occured',
        description: `${extractingWordError.message}. Please retry curation to get correct indexes and origin fields`,
        placement: 'topLeft',
      })
    }
  }, [dishesWithMenuTitleParents, highlightsFromLabels, isDishesSimple])

  const handleChangePagination = useCallback(
    (page, size) =>
      setQueryParams({
        currentPage: size !== pageSize ? DEFAULT_PAGINATION_CONFIG.PAGE : page,
        pageSize: size,
      }),
    [pageSize, setQueryParams],
  )

  const dishTableForStatus = useMemo(
    () => ({
      [CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value]: (
        <DishEditableVariousTextTable
          dishesHighlightWords={dishesHighlightWords}
          brandStatus={brandStatus}
          disabled={!userHasBrandChangePermission || isBrandDirty}
          dishes={dishesWithMenuTitleParents}
        />
      ),
    }),
    [
      dishesHighlightWords,
      brandStatus,
      isBrandDirty,
      dishesWithMenuTitleParents,
      userHasBrandChangePermission,
    ],
  )

  const paginationConfig = useMemo(() => {
    if (
      isBrandDirty ||
      isNil(totalDishCount) ||
      isNil(handleChangePagination)
    ) {
      return false
    }

    return {
      current: Number(currentPage),
      pageSize: Number(pageSize),
      total: totalDishCount,
      onChange: handleChangePagination,
      pageSizeOptions: DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS,
      showSizeChanger: true,
      onShowSizeChange: handleChangePagination,
      size: 'small',
    }
  }, [
    isBrandDirty,
    totalDishCount,
    currentPage,
    pageSize,
    handleChangePagination,
  ])

  useHotkeys(
    [
      AVAILABLE_HOTKEYS.CMD_RIGHT.hotkey,
      AVAILABLE_HOTKEYS.CTRL_RIGHT.hotkey,
    ].join(','),
    event => {
      if (!paginationConfig) {
        return
      }

      event.preventDefault()
      const nextPage = paginationConfig.current + 1
      if (
        nextPage > Math.ceil(paginationConfig.total / paginationConfig.pageSize)
      ) {
        return
      }

      handleChangePagination(nextPage)
    },
    [paginationConfig],
  )

  useHotkeys(
    [
      AVAILABLE_HOTKEYS.CMD_LEFT.hotkey,
      AVAILABLE_HOTKEYS.CTRL_LEFT.hotkey,
    ].join(','),
    event => {
      if (!paginationConfig) {
        return
      }

      event.preventDefault()
      const prevPage = paginationConfig.current - 1
      if (prevPage < 1) {
        return
      }

      handleChangePagination(prevPage)
    },
    [paginationConfig],
  )

  if (error) {
    return <Typography.Paragraph>{error.message}</Typography.Paragraph>
  }

  return (
    <>
      <Row className="dish-table-wrapper" style={styles.dishesListStyles}>
        <DishTableFocusActionsProvider
          dishes={dishesWithMenuTitleParents}
          brandId={brandId}
          dishesLoading={loading}
          isBrandDirty={isBrandDirty}
          brandStatus={brandStatus}
        >
          {selectedDishId && (
            <LoadingProvider>
              <DishModal
                disabled={!userHasBrandChangePermission || isBrandDirty}
                highlights={get(dishesHighlightWords, selectedDishId)}
                refetchBrand={refetchBrand}
              />
            </LoadingProvider>
          )}
          {(isStringTrue(dishIsStatusSameAsBrandFilter) ||
            isStringTrue(dishHasErrorsFilter)) &&
          !isNil(get(dishTableForStatus, brandStatus)) ? (
            get(dishTableForStatus, brandStatus)
          ) : (
            <DishTable
              loading={loading}
              dishes={dishesWithMenuTitleParents}
              dishesHighlightWords={dishesHighlightWords}
              brandStatus={brandStatus}
              refetchBrand={refetchBrand}
              dishIsStatusSameAsBrandFilter={dishIsStatusSameAsBrandFilter}
              dishHasErrorsFilter={dishHasErrorsFilter}
            />
          )}
        </DishTableFocusActionsProvider>
      </Row>
      <Row justify="end" style={styles.dishesListFooter}>
        <Col span={8}>
          <DishMenuTitleFilter
            size="small"
            dishesWithNoMenuTitleSummary={dishesWithNoMenuTitleSummary}
          />
        </Col>
        <Col span={8} style={styles.paginationContainer}>
          {paginationConfig && <Pagination {...paginationConfig} />}
        </Col>
      </Row>
    </>
  )
}

DishList.propTypes = {
  isBrandDirty: PropTypes.bool,
  refetchBrand: PropTypes.func,
  highlightsFromLabels: PropTypes.object,
  brandStatus: PropTypes.string.isRequired,
  menuTitles: PropTypes.arrayOf(MenuTitleTreeItemPropType),
  dishesWithNoMenuTitleSummary: PropTypes.object,
}

DishList.defaultProps = {
  isBrandDirty: false,
  refetchBrand: undefined,
  highlightsFromLabels: {},
  dishesWithNoMenuTitleSummary: {},
  menuTitles: [],
}

export default DishList
