import {
  filter,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  omit,
  pick,
} from 'lodash'
import omitDeep from 'omit-deep-lodash'

import { areSameValue } from '../../core/utils'

import { idNew } from '../../common/constants'

import { ADDITIONAL_INGREDIENT_TYPES } from '../constants'
import { clearAiErrorsFromDish } from '../helpers'

export const transformDishToDto = (
  unsavedDish,
  aiSuggestionsForDish,
  brandId,
  dish,
) => {
  const preparedDish = omit(unsavedDish, [
    'id',
    'updatedBy',
    'updatedAt',
    'published',
    'unsaved',
    'dietIds',
    'allergenIds',
  ])

  if (!isEmpty(dish)) {
    preparedDish.id = dish.id
  }

  const { excludedIngredients, ...aiSuggestions } = aiSuggestionsForDish || {}

  preparedDish.brandId = brandId

  preparedDish.aiSuggestions = clearAiErrorsFromDish(
    dish,
    unsavedDish,
    aiSuggestions,
  )

  if (
    !isNil(aiSuggestions) &&
    !isNil(get(preparedDish, 'aiSuggestions.diets'))
  ) {
    preparedDish.aiSuggestions.diets.predictions = map(
      preparedDish.aiSuggestions.diets.predictions,
      suggestion =>
        pick(suggestion, [
          'id',
          'label',
          'score',
          'occurrences',
          'usedSuggestionMethod',
        ]),
    )
  }

  if (
    !isNil(aiSuggestions) &&
    !isNil(get(preparedDish, 'aiSuggestions.allergens'))
  ) {
    preparedDish.aiSuggestions.allergens.predictions = map(
      preparedDish.aiSuggestions.allergens.predictions,
      suggestion =>
        pick(suggestion, [
          'id',
          'label',
          'score',
          'occurrences',
          'usedSuggestionMethod',
        ]),
    )
  }

  if (
    !isNil(get(preparedDish, 'aiSuggestions.miscAndChoice')) &&
    !isNil(aiSuggestions)
  ) {
    preparedDish.aiSuggestions.miscAndChoice = pick(
      preparedDish.aiSuggestions.miscAndChoice,
      ['name', 'description', 'ingredientsText', 'certainty'],
    )
  }

  if (!isNil(preparedDish.servicingHoursIds)) {
    preparedDish.servicingHoursIds = filter(
      preparedDish.servicingHoursIds,
      servicingHourId => servicingHourId !== idNew,
    )
  }

  if (!isEmpty(preparedDish.mainIngredients)) {
    let mainIngredientToSave
    preparedDish.mainIngredients = map(
      preparedDish.mainIngredients,
      ingredient => {
        const fieldsToPick = [
          'id',
          'originField',
          'word',
          'matchType',
          'isSuggestedByAi',
          'alternativeType',
          'overrides',
          'replaces',
          'usedFeature',
          'isSuggestedUsingMl',
          'isSuggestedUsingParser',
          'mlScore',
          'keyword',
          'isDiscrepancyAccepted',
        ]

        mainIngredientToSave = pick(ingredient, fieldsToPick)

        if (mainIngredientToSave.overrides) {
          mainIngredientToSave.overrides = map(
            mainIngredientToSave.overrides,
            override => pick(override, ['word', 'originField']),
          )
        }

        if (mainIngredientToSave.alreadyFoundIn) {
          mainIngredientToSave.alreadyFoundIn = map(
            mainIngredientToSave.alreadyFoundIn,
            alreadyFound => pick(alreadyFound, ['word', 'originField']),
          )
        }

        return mainIngredientToSave
      },
    )
  }

  if (!isEmpty(preparedDish.additionalIngredients)) {
    let additionalIngredientToSave
    preparedDish.additionalIngredients = map(
      preparedDish.additionalIngredients,
      ingredient => {
        additionalIngredientToSave = pick(ingredient, [
          'id',
          'ingredientType',
          'isManuallyAdded',
          'overrides',
        ])

        if (isNil(additionalIngredientToSave.ingredientType)) {
          additionalIngredientToSave.ingredientType =
            ADDITIONAL_INGREDIENT_TYPES.PROBABLE
        }
        if (isNil(additionalIngredientToSave.isManuallyAdded)) {
          additionalIngredientToSave.isManuallyAdded = true
        }

        return omitDeep(additionalIngredientToSave, ['__typename'])
      },
    )
  }

  if (!isEmpty(preparedDish.choiceIngredients)) {
    let choiceIngredientToSave
    preparedDish.choiceIngredients = map(
      preparedDish.choiceIngredients,
      ingredient => {
        choiceIngredientToSave = pick(ingredient, [
          'id',
          'originField',
          'word',
          'matchType',
          'alternativeType',
          'isSuggestedByAi',
          'alreadyFoundIn',
          'replaces',
          'usedFeature',
          'isSuggestedUsingMl',
          'isSuggestedUsingParser',
          'mlScore',
          'keyword',
          'isDiscrepancyAccepted',
        ])

        if (choiceIngredientToSave.alreadyFoundIn) {
          choiceIngredientToSave.alreadyFoundIn = map(
            choiceIngredientToSave.alreadyFoundIn,
            override => pick(override, ['word', 'originField']),
          )
        }

        return choiceIngredientToSave
      },
    )
  }

  if (preparedDish.excludedIngredients) {
    preparedDish.excludedIngredients = map(
      preparedDish.excludedIngredients,
      ingredient =>
        pick(ingredient, [
          'id',
          'word',
          'originField',
          'isSuggestedUsingMl',
          'isSuggestedUsingParser',
          'mlScore',
          'matchType',
        ]),
    )
  }

  // filter the excluded ingredients out if they are no longer contained in the dish text
  if (
    !isEmpty(preparedDish.excludedIngredients) &&
    (preparedDish.name ||
      preparedDish.description ||
      preparedDish.ingredientsText)
  ) {
    preparedDish.excludedIngredients = filter(
      preparedDish.excludedIngredients,
      ingredient =>
        // if the origin field was not modified we will include automatically the excluded ingredient
        (ingredient.isSuggestedUsingParser &&
          !preparedDish[ingredient.originField]) ||
        includes(preparedDish[ingredient.originField], ingredient.word),
    )
  }

  if (unsavedDish.dietIds) {
    preparedDish.diets = map(unsavedDish.dietIds, dietId => {
      const aiSuggestionDiet = find(
        aiSuggestions?.diets?.predictions,
        ({ id }) => areSameValue(id, dietId),
      )

      const dishExistingDiet = find(dish.diets, ({ id }) =>
        areSameValue(id, dietId),
      )

      return {
        id: dietId,
        usedAiSuggestionMethod:
          aiSuggestionDiet?.usedSuggestionMethod ??
          dishExistingDiet?.usedAiSuggestionMethod,
      }
    })
  }

  if (unsavedDish.allergenIds) {
    preparedDish.allergens = map(unsavedDish.allergenIds, allergenId => {
      const aiSuggestionAllergen = find(
        aiSuggestions?.allergens?.predictions,
        ({ id }) => areSameValue(id, allergenId),
      )

      const dishExistingAllergen = find(dish.allergens, ({ id }) =>
        areSameValue(id, allergenId),
      )

      return {
        id: allergenId,
        usedAiSuggestionMethod:
          aiSuggestionAllergen?.usedSuggestionMethod ??
          dishExistingAllergen?.usedAiSuggestionMethod,
      }
    })
  }

  if (preparedDish.selectedLocations) {
    preparedDish.selectedLocations = map(
      preparedDish.selectedLocations,
      ({ locationId, overridenLowPrice, overridenHighPrice, disabled }) => ({
        locationId: Number(locationId),
        overridenLowPrice: parseFloat(overridenLowPrice),
        overridenHighPrice: parseFloat(overridenHighPrice),
        disabled,
      }),
    )
  }
  const { id: preparedDishId, ...transformedDish } = preparedDish

  return Number.isNaN(Number(preparedDishId))
    ? { ...transformedDish }
    : { id: preparedDishId, ...transformedDish }
}
