import replaceNbspFromText from './replaceNbspFromText'

const stripHtmlFromText = (text = '') => {
  // We need to replace all <br/> with new lines such that the indexes are corectly extracted otherwise the texts will not match
  const matchedTextWithNewLinesIntact = text
    .replaceAll('<br/>', '\n')
    .replaceAll('<br>', '\n')
    .replaceAll('</br>', '\n')
    .replaceAll('</ br>', '\n')
    .replaceAll('<br />', '\n')
  const doc = new DOMParser().parseFromString(
    matchedTextWithNewLinesIntact,
    'text/html',
  )
  return replaceNbspFromText(doc.body.textContent)
}

export default stripHtmlFromText
