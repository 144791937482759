import { createPayloadAction } from '../../../common/utils'

import { SET_DISH_FIELD_ACTION_TYPES } from '../actionTypes'

export const setDishName = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_NAME,
)
export const setDishDiets = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_DIET_IDS,
)
export const setDishAllergens = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_ALLERGEN,
)
export const setDishLowPrice = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_LOW_PRICE,
)
export const setDishHighPrice = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_HIGH_PRICE,
)
export const setDishDishType = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_DISH_TYPE_ID,
)
export const setDishCourseType = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_COURSE_TYPE_ID,
)
export const setDishMainIngredients = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_MAIN_INGREDIENTS,
)
export const setDishIngredientsText = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_INGREDIENTS_TEXT,
)
export const setDishMenuTitle = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_MENU_TITLE_ID,
)
export const setDishDescription = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_DESCRIPTION,
)

export const setDishAdditionalIngredients = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_ADDITIONAL_INGREDIENTS,
)

export const setDishServicingHoursIds = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_SERVICING_HOURS_IDS,
)

export const setDishExcludedIngredients = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_EXCLUDED_INGREDIENTS,
)

export const setDishChoiceIngredients = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_CHOICE_INGREDIENTS,
)

export const setDishSelectedLocations = createPayloadAction(
  SET_DISH_FIELD_ACTION_TYPES.SET_DISH_SELECTED_LOCATIONS,
)
