import { gql } from '@apollo/client'

export default gql`
  query confirmStatusValidationQuery($input: ConfirmStatusInput!) {
    confirmStatusErrorMessages(input: $input) {
      aiDishErrorMessages {
        dishId
        messages {
          level
          message
        }
      }
      unvalidatedDishes {
        id
        name
      }
      nonQaDoneDishes {
        id
        name
      }

      nonQqaDoneDishes {
        id
        name
      }
    }
  }
`
