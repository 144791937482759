import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_ROUTES } from '../../common/constants'

import { AiSettings } from './pages'

const AiSettingsRouter = () => (
  <Switch>
    <Route
      exact
      path={APP_ROUTES.AI_SETTINGS_ROUTES.AiSettings}
      component={AiSettings}
    />
  </Switch>
)

export default AiSettingsRouter
