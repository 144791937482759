import { isEmpty, map } from 'lodash'

const getNodes = transformFunction => node => {
  const transformedNode = transformFunction(node)

  if (!isEmpty(transformedNode.children)) {
    const children = map(transformedNode.children, getNodes(transformFunction))
    return { ...transformedNode, children }
  }

  return transformedNode
}

const transformTree = (tree = [], transformFunction) =>
  map(tree, getNodes(transformFunction))

export default transformTree
