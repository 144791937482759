import { omit } from 'lodash'
import reduceReducers from 'reduce-reducers'

import { newDishTransformer } from '../../transformers'
import { UNSAVED_DISH_ACTION_TYPES } from '../actionTypes'

import setDishFieldReducer from './setDishFieldReducer'

function unsavedDishReducer(state = {}, action) {
  switch (action.type) {
    case UNSAVED_DISH_ACTION_TYPES.ADD_UNSAVED_DISH: {
      const { brandId, dietIds, dishId } = action.payload

      return {
        ...state,
        [dishId]: {
          id: dishId,
          brandId,
          dietIds,
          unsaved: true,
          published: false,
        },
      }
    }

    case UNSAVED_DISH_ACTION_TYPES.DUPLICATE_DISH: {
      const { dish, brandId } = action.payload
      return {
        ...state,
        new: newDishTransformer.transformExistingDishToNewDish({
          dish,
          brandId,
        }),
      }
    }

    case UNSAVED_DISH_ACTION_TYPES.REMOVE_UNSAVED_DISH: {
      return omit(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default reduceReducers(unsavedDishReducer, setDishFieldReducer)
