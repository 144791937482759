import { METADATA_TYPES } from '../../common/constants'

const CLEANUP_METADATA_FIELDS = Object.freeze({
  [METADATA_TYPES.DISH_TYPES]: 'cleanupDishTypeId',
  [METADATA_TYPES.COURSE_TYPES]: 'cleanupCourseTypeId',
  [METADATA_TYPES.LOCATION_TYPES]: 'cleanupLocationTypeId',
  [METADATA_TYPES.CUISINE_TYPES]: 'cleanupCuisineTypeId',
  [METADATA_TYPES.INGREDIENTS]: 'cleanupIngredientIds',
})

export default CLEANUP_METADATA_FIELDS
