import { gql } from '@apollo/client'

const QA_BRAND_AGGREGATED_CORRECTION_COUNTS_FRAGMENT = gql`
  fragment QaBrandAggregatedCorrectionCountsFragment on QaBrandAggregatedCorrectionCounts {
    styledName
    styledDescription
    styledIngredientsText
    dishTypeId
    courseTypeId
    mainIngredientIds
    additionalIngredientIds
    choiceIngredientIds
    cuisineTypeId
    locationTypeId
    languageIds
    allergenLabelLegend
    dietLabelLegend
    menuTitles
    dietIds
    allergenIds
    value
    type
    ingredientMatchType
    languageId
    allergenId
    dietId
    ingredientId
  }
`

export default QA_BRAND_AGGREGATED_CORRECTION_COUNTS_FRAGMENT
