import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

import { useDocumentTitle } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import { idNew, MENU_ENTRY } from '../../../../common/constants'
import { setActiveBrandName } from '../../../../common/redux/actions'

import { brandEntryValidationSchema } from '../../../schemas'
import { BrandDiscardButton, BrandSaveButton } from '../../molecules'
import { BrandEntry } from '../../sections'

export default function NewBrand() {
  useDocumentTitle('Create Brand')
  const dispatch = useDispatch()
  const formMethods = useForm({
    resolver: yupResolver(brandEntryValidationSchema),
  })

  useEffect(() => {
    dispatch(setActiveBrandName('<Unsaved brand>'))
  }, [dispatch])

  return (
    <FormProvider {...formMethods}>
      <PageWithHeader
        menuItem={MENU_ENTRY.BRAND}
        renderRightMenu={() => (
          <>
            <BrandDiscardButton />
            <BrandSaveButton brandId={idNew} />
          </>
        )}
      >
        <form>
          <BrandEntry brand={{ id: idNew }} />
        </form>
      </PageWithHeader>
    </FormProvider>
  )
}
