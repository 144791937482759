import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { get } from 'lodash'

import { Spinner } from '../../../../core/components'
import { useDocumentTitle, useQueryParams } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import {
  DEFAULT_PAGINATION_CONFIG,
  MENU_ENTRY,
} from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'
import { setActiveLocationName } from '../../../../common/redux/actions'

import { BrandSupportingDataProvider } from '../../../../brands/providers'
import { DishFilters } from '../../../../dishes/components'
import { LOCATION_BY_ID_QUERY } from '../../../graphql'
import { usePersistedLocationField } from '../../../hooks'
import { LocationDiscardButton, LocationSaveButton } from '../../molecules'
import { LocationDishList, LocationEntry } from '../../sections'

export default function LocationDetails() {
  const { locationId, brandId } = useParams()
  const dispatch = useDispatch()
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    menuTitle,
    search,
  } = useQueryParams()

  const {
    loading: isLocationLoading,
    error: locationError,
    data: locationData,
    refetch: refetchLocation,
  } = useQuery(LOCATION_BY_ID_QUERY, {
    variables: {
      id: locationId,
      paginationFiltersAndSorters: {
        pageSize: Number(pageSize),
        currentPage: Number(currentPage),
        filters: {
          menuTitle,
          search,
        },
      },
    },
    onCompleted: ({ location: { name } }) =>
      dispatch(setActiveLocationName(name)),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  useDocumentTitle(
    `Location ${
      get(locationData, 'location.name')
        ? ` | ${get(locationData, 'location.name')}`
        : ''
    }`,
  )

  const {
    location: {
      dishes: { rows: dishRows, count: totalDishCount },
      ...location
    } = { dishes: {} },
  } = locationData || {}
  const {
    servicingHoursOverriden,
    servicingHours,
    brand: { menuTitles } = {},
  } = location

  const { servicingHours: persistedServicingHours } = usePersistedLocationField(
    locationId,
    'servicingHours',
  )
  const { servicingHoursOverriden: persistedServicingHoursOverriden } =
    usePersistedLocationField(locationId, 'servicingHoursOverriden')

  if (isLocationLoading) {
    return <Spinner size="large" />
  }

  if (locationError) {
    return <p>Error occurred while fetching location.</p>
  }

  return (
    <BrandSupportingDataProvider menuTitles={menuTitles}>
      <PageWithHeader
        menuItem={MENU_ENTRY.LOCATION}
        renderRightMenu={() => (
          <>
            <LocationDiscardButton />
            <LocationSaveButton
              brandId={brandId}
              locationId={locationId}
              refetchLocation={refetchLocation}
            />
          </>
        )}
      >
        <>
          <LocationEntry location={location} />
          <DishFilters
            config={{
              filterByMenuTitles: true,
              filterBySearch: true,
              filterByIgnored: true,
              filterByPublished: true,
            }}
          />
          <LocationDishList
            locationId={locationId}
            dishes={dishRows}
            totalDishCount={totalDishCount}
            servicingHours={servicingHours}
            displayOverridenServicingHours={servicingHoursOverriden}
            disableServicingHours={
              persistedServicingHoursOverriden === false ||
              !!persistedServicingHours ||
              servicingHoursOverriden === false
            }
          />
        </>
      </PageWithHeader>
    </BrandSupportingDataProvider>
  )
}
