import React, { useCallback, useMemo } from 'react'
import { Tooltip, TreeSelect } from 'antd'
import { isEmpty, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { areSameValue } from '../../../../core/utils'

import { handleTreeSelectCustomDropdownForSearch } from '../../../helpers'
import { treeOptionPropType } from '../../../propTypes'
import { filterTree, findNodeByValue } from '../../../utils'

const StickyNodesTreeSelect = ({
  value,
  onChange,
  placeholder,
  treeData,
  stickyNodes,
  disabled,
  className,
  allowClear,
  size,
  style,
  dropdownStyle,
  onBlur,
  dropdownClassName,
  defaultValue,
  treeDefaultExpandedKeys,
}) => {
  const treeDataWithStickyNodes = useMemo(
    () =>
      isEmpty(stickyNodes)
        ? treeData
        : [
            ...map(stickyNodes, node => ({
              ...node,
              isSticky: true,
            })),
            ...filterTree(
              treeData,
              ({ value: nodeValue }) =>
                !some(stickyNodes, ({ value: stickyNodeValue }) =>
                  areSameValue(nodeValue, stickyNodeValue),
                ),
              true,
            ),
          ],
    [stickyNodes, treeData],
  )

  const selectedNode = useMemo(
    () => findNodeByValue(treeData, value),
    [treeData, value],
  )

  const handleOnChange = useCallback(val => onChange(val), [onChange])

  return (
    <Tooltip title={selectedNode?.namePath}>
      <TreeSelect
        showSearch
        className={className}
        style={style}
        dropdownStyle={dropdownStyle}
        dropdownRender={handleTreeSelectCustomDropdownForSearch}
        treeNodeFilterProp="title"
        treeData={treeDataWithStickyNodes}
        value={value}
        onChange={handleOnChange}
        onBlur={onBlur}
        placeholder={placeholder}
        dropdownMatchSelectWidth={300}
        disabled={disabled}
        size={size}
        allowClear={allowClear}
        dropdownClassName={dropdownClassName}
        defaultValue={defaultValue}
        treeDefaultExpandedKeys={treeDefaultExpandedKeys}
      />
    </Tooltip>
  )
}

StickyNodesTreeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  treeData: PropTypes.arrayOf(treeOptionPropType),
  stickyNodes: PropTypes.any,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  dropdownStyle: PropTypes.object,
  onBlur: PropTypes.func,
  dropdownClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  treeDefaultExpandedKeys: PropTypes.arrayOf(PropTypes.string),
}

StickyNodesTreeSelect.defaultProps = {
  value: undefined,
  placeholder: 'Please select...',
  treeData: [],
  stickyNodes: [],
  size: 'small',
  allowClear: true,
  className: undefined,
  style: undefined,
  dropdownStyle: undefined,
  onBlur: undefined,
  disabled: false,
  dropdownClassName: undefined,
  defaultValue: undefined,
  treeDefaultExpandedKeys: [],
}

export default StickyNodesTreeSelect
