import { isNil, map, pick } from 'lodash'

export const toEditorFields = user => ({
  ...pick(user, [
    'name',
    'email',
    'isActive',
    'seniority',
    'personalEmail',
    'clockifyEmail',
    'invoiceEmail',
    'birthdate',
    'joinedAt',
    'gender',
    'extracurricularActivities',
    'country',
    'timeZone',
    'phone',
    'previousWorkExperience',
    'status',
    'tier',
    'lastReviewAt',
    'resignedAt',
    'terminatedAt',
    'terminatedReason',
    'otherQualification',
  ]),
  isNameGraphRelevant: !isNil(user?.graphRelevantFields?.isNameGraphRelevant)
    ? user?.graphRelevantFields?.isNameGraphRelevant
    : true,
  isBirthdateGraphRelevant: !isNil(
    user?.graphRelevantFields?.isBirthdateGraphRelevant,
  )
    ? user?.graphRelevantFields?.isBirthdateGraphRelevant
    : false,
  isGenderGraphRelevant: !isNil(
    user?.graphRelevantFields?.isGenderGraphRelevant,
  )
    ? user?.graphRelevantFields?.isGenderGraphRelevant
    : false,
  isEducationGraphRelevant: !isNil(
    user?.graphRelevantFields?.isEducationGraphRelevant,
  )
    ? user?.graphRelevantFields?.isEducationGraphRelevant
    : false,
  isDepartmentGraphRelevant: !isNil(
    user?.graphRelevantFields?.isDepartmentGraphRelevant,
  )
    ? user?.graphRelevantFields?.isDepartmentGraphRelevant
    : true,
  isQualificationGraphRelevant: !isNil(
    user?.graphRelevantFields?.isQualificationGraphRelevant,
  )
    ? user?.graphRelevantFields?.isQualificationGraphRelevant
    : true,
  isCompetencyGraphRelevant: !isNil(
    user?.graphRelevantFields?.isCompetencyGraphRelevant,
  )
    ? user?.graphRelevantFields?.isCompetencyGraphRelevant
    : true,
  isCuisineKnowlegeGraphRelevant: !isNil(
    user?.graphRelevantFields?.isCuisineKnowlegeGraphRelevant,
  )
    ? user?.graphRelevantFields?.isCuisineKnowlegeGraphRelevant
    : true,
  isLanguageGraphRelevant: !isNil(
    user?.graphRelevantFields?.isLanguageGraphRelevant,
  )
    ? user?.graphRelevantFields?.isLanguageGraphRelevant
    : true,
  isCountryGraphRelevant: !isNil(
    user?.graphRelevantFields?.isCountryGraphRelevant,
  )
    ? user?.graphRelevantFields?.isCountryGraphRelevant
    : true,
  isTimeZoneGraphRelevant: !isNil(
    user?.graphRelevantFields?.isTimeZoneGraphRelevant,
  )
    ? user?.graphRelevantFields?.isTimeZoneGraphRelevant
    : true,
  isWorkScheduleGraphRelevant: !isNil(
    user?.graphRelevantFields?.isWorkScheduleGraphRelevant,
  )
    ? user?.graphRelevantFields?.isWorkScheduleGraphRelevant
    : false,
  isPhoneGraphRelevant: !isNil(user?.graphRelevantFields?.isPhoneGraphRelevant)
    ? user?.graphRelevantFields?.isPhoneGraphRelevant
    : false,
  isEmailGraphRelevant: !isNil(user?.graphRelevantFields?.isEmailGraphRelevant)
    ? user?.graphRelevantFields?.isEmailGraphRelevant
    : false,
  isJoinedAtGraphRelevant: !isNil(
    user?.graphRelevantFields?.isJoinedAtGraphRelevant,
  )
    ? user?.graphRelevantFields?.isJoinedAtGraphRelevant
    : false,
  isPreviousWorkExperienceGraphRelevant: !isNil(
    user?.graphRelevantFields?.isPreviousWorkExperienceGraphRelevant,
  )
    ? user?.graphRelevantFields?.isPreviousWorkExperienceGraphRelevant
    : false,
  isStatusGraphRelevant: !isNil(
    user?.graphRelevantFields?.isStatusGraphRelevant,
  )
    ? user?.graphRelevantFields?.isStatusGraphRelevant
    : true,
  isTierGraphRelevant: !isNil(user?.graphRelevantFields?.isTierGraphRelevant)
    ? user?.graphRelevantFields?.isTierGraphRelevant
    : true,
  isLastReviewAtGraphRelevant: !isNil(
    user?.graphRelevantFields?.isLastReviewAtGraphRelevant,
  )
    ? user?.graphRelevantFields?.isLastReviewAtGraphRelevant
    : false,
  roleIds: map(user.roles, 'id'),
  cuisineTypeIds: map(user.cuisineTypes, 'id'),
  languageIds: map(user.languages, 'id'),
  departmentIds: map(user.departments, 'id'),
  qualificationIds: map(user.qualifications, 'id'),
  competencies: map(user.competencies, competency =>
    pick(competency, ['id', 'competencyId', 'isActive', 'type']),
  ),
  workSchedule: map(user.workSchedule, schedule =>
    pick(schedule, [
      'id',
      'startDayOfWeek',
      'endDayOfWeek',
      'startTime',
      'endTime',
    ]),
  ),
  education: map(user.education, education =>
    pick(education, ['id', 'schoolName', 'degree', 'fieldOfStudy']),
  ),
})

export const toSaveArgs = user => ({
  ...pick(user, [
    'name',
    'email',
    'isActive',
    'seniority',
    'personalEmail',
    'clockifyEmail',
    'invoiceEmail',
    'birthdate',
    'joinedAt',
    'gender',
    'extracurricularActivities',
    'departmentIds',
    'qualificationIds',
    'country',
    'timeZone',
    'phone',
    'previousWorkExperience',
    'status',
    'tier',
    'lastReviewAt',
    'resignedAt',
    'terminatedAt',
    'terminatedReason',
    'otherQualification',
    'roleIds',
    'cuisineTypeIds',
    'languageIds',
  ]),
  competencies: map(user.competencies, competency =>
    pick(competency, [
      'id',
      'competencyId',
      'isActive',
      'type',
      'created',
      'deleted',
      'updated',
    ]),
  ),
  workSchedule: map(user.workSchedule, schedule =>
    pick(schedule, [
      'id',
      'startDayOfWeek',
      'endDayOfWeek',
      'startTime',
      'endTime',
      'created',
      'deleted',
      'updated',
    ]),
  ),
  education: map(user.education, education =>
    pick(education, [
      'id',
      'schoolName',
      'degree',
      'fieldOfStudy',
      'created',
      'deleted',
      'updated',
    ]),
  ),
  graphRelevantFields: pick(user, [
    'isNameGraphRelevant',
    'isBirthdateGraphRelevant',
    'isGenderGraphRelevant',
    'isEducationGraphRelevant',
    'isDepartmentGraphRelevant',
    'isQualificationGraphRelevant',
    'isCompetencyGraphRelevant',
    'isCuisineKnowlegeGraphRelevant',
    'isLanguageGraphRelevant',
    'isCountryGraphRelevant',
    'isTimeZoneGraphRelevant',
    'isWorkScheduleGraphRelevant',
    'isPhoneGraphRelevant',
    'isEmailGraphRelevant',
    'isJoinedAtGraphRelevant',
    'isPreviousWorkExperienceGraphRelevant',
    'isStatusGraphRelevant',
    'isTierGraphRelevant',
    'isLastReviewAtGraphRelevant',
  ]),
})
