import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Spin, Table } from 'antd'
import { filter, includes, isNil, some, sumBy, toLower } from 'lodash'
import PropTypes from 'prop-types'

import { DateFromNow } from '../../../../core/components/atoms'

import { METADATA_TYPES, PERMISSIONS } from '../../../../common/constants'
import { useUserHasPermissions } from '../../../../common/hooks'
import { userInfoPropType } from '../../../../common/propTypes'

import {
  UPDATE_CLEANUP_TASK_ASSIGNED_CURATOR_MUTATION,
  UPDATE_CLEANUP_TASK_ASSIGNED_QA_MUTATION,
} from '../../../graphql'
import {
  cleanupTaskPropType,
  cleanupTasksMetadataTypePropType,
} from '../../../propTypes'
import {
  CleanupTaskDeleteButton,
  CleanupTaskUserAsignment,
} from '../../molecules'

const CleanupTasksTable = ({
  data,
  total,
  page,
  metadataType,
  loading,
  onPaginationChange,
  pageSize,
  onPageSizeChange,
  users,
}) => {
  const isUserAllowedToDelete = useUserHasPermissions([
    PERMISSIONS.TASKS.DELETE,
  ])

  const isUserAllowedToAssign = useUserHasPermissions([
    PERMISSIONS.TASKS.ASSIGN_USER,
  ])

  const paginationConfig = useMemo(
    () => ({
      current: page,
      total,
      pageSize,
      onChange: onPaginationChange,
      onShowSizeChange: onPageSizeChange,
    }),
    [onPageSizeChange, onPaginationChange, page, pageSize, total],
  )

  const qaUsers = useMemo(
    () => filter(users, user => some(user.roles, ({ name }) => name === 'qa')),
    [users],
  )

  const tableColumns = useMemo(
    () => [
      {
        title: 'Name',
        width: '200px',
        render: ({
          id,
          name,
          batches,
          group: { blockedById, type = 'review' } = {},
        }) => {
          if (isNil(batches) && isNil(blockedById)) {
            return (
              <Link to={`/cleanupTasks/${toLower(type)}/${metadataType}/${id}`}>
                {name}
              </Link>
            )
          }

          return `${!isNil(blockedById) ? 'Blocked' : ''} ${name}`
        },
      },
      {
        title: 'Assigned curator',
        width: '150px',
        render: ({ id, batches, assignedCuratorId }) => {
          if (isNil(batches)) {
            return (
              <CleanupTaskUserAsignment
                taskId={id}
                metadataType={metadataType}
                assignedUserId={assignedCuratorId}
                mutation={UPDATE_CLEANUP_TASK_ASSIGNED_CURATOR_MUTATION}
                availableUsers={users}
                disabled={!isUserAllowedToAssign}
              />
            )
          }

          return null
        },
      },
      {
        title: 'Assigned QA',
        width: '150px',
        render: ({ id, batches, assignedQaId }) => {
          if (isNil(batches)) {
            return (
              <CleanupTaskUserAsignment
                taskId={id}
                metadataType={metadataType}
                assignedUserId={assignedQaId}
                mutation={UPDATE_CLEANUP_TASK_ASSIGNED_QA_MUTATION}
                availableUsers={qaUsers}
                disabled={!isUserAllowedToAssign}
              />
            )
          }

          return null
        },
      },
      {
        title: 'Task type',
        width: '80px',
        dataIndex: 'type',
      },
      {
        title: includes(
          [METADATA_TYPES.LOCATION_TYPES, METADATA_TYPES.CUISINE_TYPES],
          metadataType,
        )
          ? 'Brands Total'
          : 'Dishes total',
        width: 100,
        render: ({ totalCount, batches }) => {
          if (isNil(batches)) {
            return totalCount
          }

          return sumBy(batches, 'totalCount')
        },
      },
      {
        title: 'Curation done',
        width: '80px',
        render: ({ doneCount, batches }) => {
          if (isNil(batches)) {
            return doneCount
          }

          return sumBy(batches, 'doneCount')
        },
      },
      {
        title: 'Qa done',
        width: '80px',
        render: ({ qaCount, batches }) => {
          if (isNil(batches)) {
            return qaCount
          }

          return sumBy(batches, 'qaCount')
        },
      },
      {
        title: 'Updated',
        dataIndex: 'updatedAt',
        width: '150px',
        render: updatedAt => <DateFromNow date={updatedAt} />,
      },
      {
        title: 'Created by',
        width: 100,
        render: ({ createdBy }) => createdBy?.name,
      },
      {
        title: '',
        width: 20,
        // eslint-disable-next-line react/prop-types
        render: ({ id, batches }) => {
          if (isNil(batches)) {
            return null
          }

          return (
            <CleanupTaskDeleteButton
              taskId={id}
              metadataType={metadataType}
              disabled={!isUserAllowedToDelete}
            />
          )
        },
      },
    ],
    [
      qaUsers,
      isUserAllowedToDelete,
      isUserAllowedToAssign,
      metadataType,
      users,
    ],
  )

  if (loading) {
    return <Spin />
  }

  return (
    <Table
      rowKey="id"
      columns={tableColumns}
      childrenColumnName="batches"
      dataSource={data}
      pagination={paginationConfig}
      size="middle"
    />
  )
}

CleanupTasksTable.propTypes = {
  data: PropTypes.arrayOf(cleanupTaskPropType).isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number,
  loading: PropTypes.bool,
  pageSize: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  onPageSizeChange: PropTypes.func,
  users: PropTypes.arrayOf(userInfoPropType),
}

CleanupTasksTable.defaultProps = {
  total: undefined,
  loading: false,
  onPaginationChange: undefined,
  onPageSizeChange: undefined,
  users: [],
}

export default CleanupTasksTable
