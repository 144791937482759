import { gql } from '@apollo/client'

import { USER_MANAGEMENT_FRAGMENT } from '../fragments'

const USERS_QUERY = gql`
  query users {
    users {
      ...UserManagementFragment
    }
  }
  ${USER_MANAGEMENT_FRAGMENT}
`

export default USERS_QUERY
