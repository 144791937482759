import React, { memo, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Button, Col, Row, Select, Tag } from 'antd'
import { find, get, isNil, map, pick } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components/atoms'
import { useShallowEqualSelector } from '../../../../core/hooks'
import { selectFilterOption } from '../../../../core/utils'

import { EVENT_TYPES } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAppLoading } from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { useUpsertBrand } from '../../../hooks'
import { suggestLanguages } from '../../../services'
import { brandTransformer } from '../../../transformers'

const styles = {
  select: { width: '100%' },
}

const BrandLanguageSelect = ({
  brandLanguages,
  options,
  disabled,
  isQaCorrected,
}) => {
  const { brandId } = useParams()
  const { startLoading, stopLoading, isLoading } = useAppLoading()
  const upsertBrand = useUpsertBrand({ brandId })

  const {
    state: { isDirty: isMenuTitlesDirty },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const {
    field: { onChange, onBlur, value },
    formState: { isDirty, isSubmitting },
  } = useController({ name: 'languages', defaultValue: brandLanguages })
  const languageIds = map(value, 'id')

  const handleOnSuggest = useCallback(async () => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.BRAND_EVENTS.SUGGEST_LANGUAGES_CLICK,
    )
    startLoading()
    const result = await suggestLanguages({ brandId, languageOptions: options })

    if (!isNil(result)) {
      try {
        await upsertBrand(
          brandTransformer.transformToSaveBrandArgs(
            { id: brandId, languages: result.languages },
            result.aiSuggestions,
          ),
        )
      } catch (error) {
        handleMutationError(error)
      }
    }

    stopLoading()
  }, [brandId, options, startLoading, stopLoading, upsertBrand])

  const handleOnChange = useCallback(
    newValues => {
      const dataToChange = map(newValues, newValue => {
        const existingValue = find(
          brandLanguages,
          language => language.id === newValue,
        )

        if (!isNil(existingValue)) {
          return pick(existingValue, ['id', 'isSuggestedByAi'])
        }

        return {
          id: newValue,
        }
      })

      onChange(dataToChange)
    },
    [brandLanguages, onChange],
  )

  const handleTagRender = useCallback((tag, existingValues) => {
    const { onClose, label, value: tagValue } = tag
    const selectedValue = find(existingValues, option => option.id === tagValue)

    return (
      <Tag
        onClose={onClose}
        closable
        className={selectedValue?.isSuggestedByAi ? 'highlight-blue' : null}
      >
        {label}
      </Tag>
    )
  }, [])

  return (
    <Row justify="space-between" align="bottom">
      <Col span={18}>
        <GridFormItem style={styles.select} label="Languages" labelAbove>
          <Select
            className={isQaCorrected ? 'highlight-red' : null}
            value={languageIds}
            placeholder="Select Languages..."
            mode="multiple"
            options={options}
            onBlur={onBlur}
            tagRender={tag => handleTagRender(tag, value)}
            onChange={handleOnChange}
            disabled={disabled}
            optionFilterProp="children"
            filterOption={selectFilterOption}
          />
        </GridFormItem>
      </Col>
      <Col span={6}>
        <Button
          loading={isSubmitting || isLoading}
          disabled={!brandId || disabled || isDirty || isMenuTitlesDirty}
          onClick={handleOnSuggest}
        >
          Suggest
        </Button>
      </Col>
    </Row>
  )
}
BrandLanguageSelect.propTypes = {
  brandLanguages: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  isQaCorrected: PropTypes.bool,
}

BrandLanguageSelect.defaultProps = {
  brandLanguages: undefined,
  options: [],
  disabled: false,
  isQaCorrected: false,
}

export default memo(BrandLanguageSelect)
