import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons'
import Editor from '@draft-js-plugins/editor'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import { notification } from 'antd'
import { CompositeDecorator, EditorState, Modifier, RichUtils } from 'draft-js'
import {
  debounce,
  filter,
  find,
  forEach,
  groupBy,
  includes,
  isEqual,
  isNil,
  keys,
  map,
  mapValues,
  reduce,
  size,
  some,
  toLower,
  trim,
} from 'lodash'
import PropTypes from 'prop-types'

import { usePrevious } from '../../../../core/hooks'

import { AI_INGREDIENT_MATCH_TYPE, BRAND_MATCH_TYPE } from '../../../constants'
import {
  findWordIndexInDraftJsText,
  getContentStateFromText,
  getStyledTextFromContentState,
} from '../../../helpers'
import { mergeArrayOfObjects, stripHtmlFromText } from '../../../utils'

import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import './highlightTextField.css'

const styles = {
  editor: {
    input: {
      minHeight: 0,
      height: 'unset',
      padding: '0px 0.5rem',
    },
  },
  decorated: {
    [AI_INGREDIENT_MATCH_TYPE.EXACT]: {
      backgroundColor: 'rgba(0, 255, 0, .5)',
    },
    [AI_INGREDIENT_MATCH_TYPE.NEAR]: {
      backgroundColor: 'rgba(0, 255, 255, .8)',
    },
    [AI_INGREDIENT_MATCH_TYPE.SYNONYM_LEVEL_1]: {
      backgroundColor: 'rgba(195, 0, 255, .2)',
    },
    [AI_INGREDIENT_MATCH_TYPE.SYNONYM_LEVEL_2]: {
      backgroundColor: 'rgba(195, 0, 255, .4)',
    },
    [AI_INGREDIENT_MATCH_TYPE.SYNONYM_LEVEL_3]: {
      backgroundColor: '#DB65FF',
    },
    [AI_INGREDIENT_MATCH_TYPE.SYNONYM]: {
      backgroundColor: 'rgba(195, 0, 255, .2)',
    },
    [AI_INGREDIENT_MATCH_TYPE.VARIETY_LEVEL_1]: {
      backgroundColor: 'rgba(195, 0, 255, .2)',
    },
    [AI_INGREDIENT_MATCH_TYPE.VARIETY_LEVEL_2]: {
      backgroundColor: 'rgba(195, 0, 255, .4)',
    },
    [AI_INGREDIENT_MATCH_TYPE.VARIETY_LEVEL_3]: {
      backgroundColor: '#DB65FF',
    },
    [AI_INGREDIENT_MATCH_TYPE.VARIETY]: {
      backgroundColor: 'rgba(195, 0, 255, .2)',
    },
    [AI_INGREDIENT_MATCH_TYPE.ALREADY_FOUND]: {
      backgroundColor: 'rgba(211, 211, 211, .8)',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_EXACT]: {
      backgroundColor: '#434343',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_ALREADY_FOUND]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_EXACT]: {
      backgroundColor: '#434343',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_ALREADY_FOUND]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_EXACT_CHOICE]: {
      backgroundColor: '#044d02',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.DIET_LABEL_LEGEND_ALREADY_FOUND_CHOICE]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_EXACT_CHOICE]: {
      backgroundColor: '#044d02',
      color: '#ffffff',
    },
    [BRAND_MATCH_TYPE.ALLERGEN_LABEL_LEGEND_ALREADY_FOUND_CHOICE]: {
      backgroundColor: '#979797',
      color: '#ffffff',
    },
    [AI_INGREDIENT_MATCH_TYPE.NEGATIVE_SYNONYM]: {
      textDecoration: 'line-through',
    },
    [AI_INGREDIENT_MATCH_TYPE.COMMON_NEGATIVE_SYNONYM]: {
      textDecoration: 'line-through',
    },
    [AI_INGREDIENT_MATCH_TYPE.OVERRIDES]: {
      border: '1px solid black',
    },

    [AI_INGREDIENT_MATCH_TYPE.MIDDLE_PAIRING_PHRASE]: {
      border: '1px dashed black',
      borderRight: 'none',
      borderLeft: 'none',
    },

    [`${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}Start`]: {
      border: '1px dashed black',
      borderRight: 'none',
    },

    [`${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}End`]: {
      border: '1px dashed black',
      borderLeft: 'none',
    },

    [AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE]: {
      border: '1px dashed black',
    },

    [AI_INGREDIENT_MATCH_TYPE.MIDDLE_REFERENCING_WORD]: {
      border: '1px dashed black',
    },

    [`${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}Start`]: {
      border: '1px dashed black',
      borderRight: 'none',
    },

    [`${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}End`]: {
      border: '1px dashed black',
      borderLeft: 'none',
    },

    [AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD]: {
      border: '1px dashed black',
    },

    alternativeTypeMatch: { backgroundColor: '#ADA9FC' },

    labelOccurrence: { backgroundColor: '#eaff8f' },

    [AI_INGREDIENT_MATCH_TYPE.REPLACEMENT]: {
      backgroundColor: '#D6A2BC',
    },
  },
}

styles.decorated = {
  ...styles.decorated,
  ...mergeArrayOfObjects([
    ...map(keys(styles.decorated), key => ({
      [`${key}${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}Start`]: {
        ...styles.decorated[key],
        border: '1px dashed black',
        borderRight: 'none',
      },
      [`${key}${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}Start`]: {
        ...styles.decorated[key],
        border: '1px dashed black',
        borderRight: 'none',
      },
    })),

    ...map(keys(styles.decorated), key => ({
      [`${key}${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}Middle`]: {
        ...styles.decorated[key],
        border: '1px dashed black',
        borderRight: 'none',
        borderLeft: 'none',
      },
      [`${key}${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}Middle`]: {
        ...styles.decorated[key],
        border: '1px dashed black',
      },
    })),

    ...map(keys(styles.decorated), key => ({
      [`${key}${AI_INGREDIENT_MATCH_TYPE.PAIRING_PHRASE}End`]: {
        ...styles.decorated[key],
        border: '1px dashed black',
        borderLeft: 'none',
      },
      [`${key}${AI_INGREDIENT_MATCH_TYPE.REFERENCING_WORD}End`]: {
        ...styles.decorated[key],
        border: '1px dashed black',
        borderLeft: 'none',
      },
    })),
  ]),
}

const Decorated = ({ children, highlightingList, textBlocksWithIndexes }) => {
  const { text, start } = children[0].props

  const groupedWordsByMatchType = useMemo(
    () =>
      mapValues(groupBy(highlightingList, 'matchType'), wordList =>
        map(wordList, ({ word, override, startIndex }) => ({
          word: toLower(trim(word)),
          override,
          startIndex,
        })),
      ),
    [highlightingList],
  )

  const groupedWordsByAlternativeType = useMemo(
    () =>
      mapValues(
        groupBy(
          map(
            filter(highlightingList, ({ alternativeType }) => alternativeType),
            highlightingWord => ({
              ...highlightingWord,
              alternativeType: 'alternativeTypeMatch',
            }),
          ),
          'alternativeType',
        ),
        wordList =>
          map(wordList, ({ word, override, startIndex }) => ({
            word: toLower(word),
            override,
            startIndex,
          })),
      ),
    [highlightingList],
  )

  const wordsToSearch = {
    ...groupedWordsByAlternativeType,
    ...groupedWordsByMatchType,
  }
  const wordsKeysWithPriority = [
    ...keys(groupedWordsByAlternativeType),
    ...keys(groupedWordsByMatchType),
  ]

  const decoratedStyle = find(wordsKeysWithPriority, key =>
    some(wordsToSearch[key], ({ word, startIndex }) =>
      !isNil(startIndex) && size(textBlocksWithIndexes) === 1
        ? findWordIndexInDraftJsText(text, word) !== -1 && startIndex === start
        : findWordIndexInDraftJsText(text, word) !== -1,
    ),
  )

  const isOverride = some(
    wordsToSearch[decoratedStyle],
    ({ word, override }) =>
      findWordIndexInDraftJsText(text, word) !== -1 && override,
  )

  let styleToApply = styles.decorated[decoratedStyle]

  if (isOverride) {
    styleToApply = {
      ...styleToApply,
      ...styles.decorated[AI_INGREDIENT_MATCH_TYPE.OVERRIDES],
    }
  }

  return <span style={styleToApply}>{children}</span>
}

Decorated.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  highlightingList: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string.isRequired,
      matchType: PropTypes.string,
      override: PropTypes.bool,
    }),
  ).isRequired,
  textBlocksWithIndexes: PropTypes.arrayOf(
    PropTypes.shape({
      startIndex: PropTypes.number,
      endIndex: PropTypes.number,
      text: PropTypes.string,
    }),
  ).isRequired,
}

const handleAiStrategy =
  (words, textBlocksWithIndexes) => (contentBlock, callback) => {
    if (words) {
      let inputText = contentBlock.getText()

      const currentBlock = find(
        textBlocksWithIndexes,
        block => block.text === inputText,
      )

      const wordsWithPriority = filter([
        ...filter(
          words,
          word => word.matchType !== AI_INGREDIENT_MATCH_TYPE.ALREADY_FOUND,
        ),
        ...filter(
          words,
          word => word.matchType === AI_INGREDIENT_MATCH_TYPE.ALREADY_FOUND,
        ),
      ])

      forEach(
        // in case we receive the input text split in multiple blocks
        // we filter out words that are not part of the current block
        filter(wordsWithPriority, word => {
          if (size(textBlocksWithIndexes) === 1 || isNil(word.startIndex)) {
            return true
          }

          return (
            word.startIndex >= currentBlock?.startIndex &&
            word.startIndex < currentBlock?.endIndex
          )
        }),
        word => {
          const formattedWord = toLower(
            includes(toLower(word.originField), 'descriptor')
              ? trim(word.word)
              : word.word,
          )

          const start = findWordIndexInDraftJsText(inputText, formattedWord)

          if (start > -1) {
            callback(start, start + formattedWord.length)
            // Mark aleady used parts of text with XXXx so that the same word gets a diffrent index
            inputText = `${inputText.substring(0, start)}${'X'.repeat(
              formattedWord.length,
            )}${inputText.substring(
              start + formattedWord.length,
              inputText.length,
            )}`
          }
        },
      )
    }
  }

// TODO figure out a way to not create the editor state on every change
const HighlightingTextField = ({
  value,
  newValue,
  highlightingList,
  disabled,
  disabledWithInput,
  onValueChanged,
  maxLength,
  onBlur,
  style,
}) => {
  // needed to put an plugin on each instance of this component.
  // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/FAQ.md#can-i-use-the-same-plugin-for-multiple-plugin-editors
  const [plugins] = useState(() => createInlineToolbarPlugin())

  // in case we haven't provided a value that is not HTML we will use the text
  const contentState = useMemo(() => {
    if (newValue || value) {
      return getContentStateFromText(newValue || value)
    }
    return null
  }, [value, newValue])

  const textBlocksWithIndexes = useMemo(
    () =>
      reduce(
        contentState?.getBlocksAsArray(),
        (acc, block, index) => {
          const blockText = block.getText()
          return [
            ...acc,
            {
              text: blockText,
              startIndex: index === 0 ? 0 : acc[index - 1].endIndex + 1,
              endIndex:
                index === 0
                  ? size(blockText) - 1
                  : acc[index - 1].endIndex + size(blockText),
            },
          ]
        },
        [],
      ),
    [contentState],
  )

  // we will have a new decorator each time indexes are updated
  const highlightDecorator = useMemo(
    () =>
      new CompositeDecorator([
        {
          strategy: handleAiStrategy(highlightingList, textBlocksWithIndexes),
          component: Decorated,
          props: {
            highlightingList,
            textBlocksWithIndexes,
          },
        },
      ]),
    [highlightingList, textBlocksWithIndexes],
  )

  const [editorState, setEditorState] = useState(
    contentState
      ? EditorState.createWithContent(contentState, highlightDecorator)
      : EditorState.createEmpty(highlightDecorator),
  )

  const prevHighlightingList = usePrevious(highlightingList)
  const prevContentState = usePrevious(contentState)
  const prevNewValue = usePrevious(newValue)

  const editorRef = useRef(null)
  const selection = useRef(null)

  const focusEditor = useCallback(() => {
    editorRef.current.focus()
  }, [editorRef])

  useEffect(() => {
    if (!isEqual(prevHighlightingList, highlightingList) && contentState) {
      if (disabled) {
        setEditorState(
          EditorState.createWithContent(contentState, highlightDecorator),
        )
      } else {
        let newBlockKey
        const blockMap = contentState.getBlockMap()

        blockMap.forEach(contentBlock => {
          newBlockKey = contentBlock.getKey()
        })
        // This sets a new SelectionState with the old selection's offsets
        // and the new editor state block keys

        const updateSelection = selection.current.merge({
          anchorKey: newBlockKey,
          // We need to also set the focus key and offset, otherwise once pressing backspace draftjs crashes
          focusKey: newBlockKey,
        })
        const newEditorStateWithSelection = EditorState.create({
          currentContent: contentState,
          decorator: highlightDecorator,
          selection: updateSelection,
        })
        setEditorState(newEditorStateWithSelection)
      }
    }
  }, [
    highlightDecorator,
    prevHighlightingList,
    highlightingList,
    editorState,
    contentState,
    disabled,
  ])

  // update the state when a new text has been passed
  useEffect(() => {
    if (
      prevContentState !== contentState ||
      (newValue && prevNewValue !== newValue)
    ) {
      if (newValue) {
        if (disabled) {
          setEditorState(
            EditorState.createWithContent(contentState, highlightDecorator),
          )
        } else {
          // Here, we need to get the new block keys for the content, as they're
          // necessary to maintain the selection
          let newBlockKey
          const newContentState = getContentStateFromText(newValue)
          const blockMap = newContentState.getBlockMap()

          blockMap.forEach(contentBlock => {
            newBlockKey = contentBlock.getKey()
          })
          // This sets a new SelectionState with the old selection's offsets
          // and the new editor state block keys
          const updateSelection = selection.current.merge({
            anchorKey: newBlockKey,
            focusKey: newBlockKey,
            hasFocus: true,
          })
          // this is the only way in which we can create an editor state to not lose any of 3 main parts

          const newEditorStateWithSelection = EditorState.create({
            currentContent: newContentState,
            decorator: highlightDecorator,
            selection: updateSelection,
          })
          setEditorState(newEditorStateWithSelection)
        }
      } else {
        const newState = contentState
          ? EditorState.createWithContent(contentState, highlightDecorator)
          : EditorState.createEmpty(highlightDecorator)
        setEditorState(newState)
      }
    }
  }, [
    editorState,
    highlightDecorator,
    prevContentState,
    contentState,
    newValue,
    disabled,
    prevNewValue,
  ])

  const debouncedOnChange = useMemo(
    () =>
      debounce(newContentState => {
        const styledText = getStyledTextFromContentState(newContentState)

        onValueChanged({
          text: stripHtmlFromText(styledText),
          styledText,
        })
      }, 300),
    [onValueChanged],
  )

  const onChange = useCallback(
    newState => {
      const currentContentState = editorState.getCurrentContent()
      const newContentState = newState.getCurrentContent()
      // This gets the selection (including cursor) the editor had before, so when updating, it's preserved
      selection.current = newState.getSelection()
      // we only want to propagate a new value when there was actually a change in content, not in focus or anything else
      if (
        currentContentState !== newContentState &&
        editorState.getSelection() !== newState.getSelection
      ) {
        debouncedOnChange(newContentState)
      }
      setEditorState(newState)
    },
    [editorState, debouncedOnChange],
  )

  const handleKeyCommand = useCallback(
    command => {
      const newEditorState =
        RichUtils.handleKeyCommand(editorState, command) || editorState
      if (isNil(newEditorState)) {
        notification.error(
          'An error occured for hotkeys, please report to bugreports, with your last known activity',
        )
      } else {
        selection.current = newEditorState.getSelection()

        if (newEditorState && includes(['italic', 'underline'], command)) {
          const newContentState = newEditorState.getCurrentContent()
          debouncedOnChange(newContentState)
          setEditorState(newEditorState)
          return 'handled'
        }
      }

      return 'not-handled'
    },
    [debouncedOnChange, editorState],
  )

  const checkMaxLength = useCallback(
    chars => {
      if (maxLength) {
        const totalLength =
          editorState.getCurrentContent().getPlainText().length + chars.length
        return totalLength > maxLength
      }
      return false
    },
    [maxLength, editorState],
  )

  const handlePastedContent = useCallback(
    text => {
      if (isNil(text)) {
        return 'handled'
      }
      // strip text of special control characters and styled content that may result from pasting
      const newText = text
        // eslint-disable-next-line no-control-regex
        .replace(/[\x00-\x08\x0E-\x1F]| +/g, ' ')
        .trim()
      const newContent = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        newText,
      )
      const newState = EditorState.push(
        editorState,
        newContent,
        'insert-characters',
      )
      onChange(newState)

      return 'handled'
    },
    [editorState, onChange],
  )

  useEffect(() => {
    focusEditor()
  }, [focusEditor])

  // Default behaviour for draftjs is to insert <p> tags on newline.
  // This makes it difficult to keep new lines when giving them to the AI service.
  // As such we are replacing the default behaviour to include </br> when enter is detected
  const handleReturn = useCallback(() => {
    onChange(RichUtils.insertSoftNewline(editorState))
    return 'handled'
  }, [editorState, onChange])

  return (
    <>
      <div
        key="text_holder"
        aria-hidden="true"
        onClick={!disabled ? focusEditor : undefined}
        style={{
          ...(!disabled || disabledWithInput ? styles.editor.input : {}),
          ...style,
        }}
        className={
          !disabled || disabledWithInput
            ? 'ant-input textarea richTextContainer'
            : 'disabledRichInput'
        }
      >
        <Editor
          key="text"
          ref={editorRef}
          readOnly={disabled}
          editorState={editorState}
          onChange={onChange}
          plugins={[plugins]}
          onBlur={onBlur}
          handleBeforeInput={checkMaxLength}
          handlePastedText={handlePastedContent}
          handleReturn={handleReturn}
          handleKeyCommand={handleKeyCommand}
        />
        {!disabled && (
          <plugins.InlineToolbar>
            {externalProps => (
              <>
                <UnderlineButton {...externalProps} />
                <ItalicButton {...externalProps} />
              </>
            )}
          </plugins.InlineToolbar>
        )}
      </div>
    </>
  )
}

HighlightingTextField.propTypes = {
  value: PropTypes.string,
  highlightingList: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string.isRequired,
      matchType: PropTypes.string,
    }),
  ),
  onValueChanged: PropTypes.func,
  disabled: PropTypes.bool,
  disabledWithInput: PropTypes.bool,
  newValue: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  style: PropTypes.object,
}

HighlightingTextField.defaultProps = {
  onValueChanged: undefined,
  value: undefined,
  disabled: false,
  disabledWithInput: false,
  highlightingList: [],
  newValue: undefined,
  maxLength: undefined,
  onBlur: undefined,
  style: {},
}

export default memo(HighlightingTextField)
