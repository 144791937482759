import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row, Typography } from 'antd'

import { useDocumentTitle, useQueryParams } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import {
  DEFAULT_PAGINATION_CONFIG,
  MENU_ENTRY,
} from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'

import { INGREDIENTS_RERUN_SESSIONS_QUERY } from '../../../graphql'
import {
  IngredientRerunActionButtons,
  IngredientsRerunTable,
} from '../../sections'

const styles = {
  pageWrapper: { padding: '1rem', paddingBottom: 0 },
}

const IngredientsRerun = () => {
  useDocumentTitle('Ingredients rerun')
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    setQueryParams,
  } = useQueryParams()

  const {
    error,
    loading,
    data: { ingredientsRerunSessions } = {},
  } = useQuery(INGREDIENTS_RERUN_SESSIONS_QUERY, {
    variables: {
      currentPage: Number(page),
      pageSize: Number(pageSize),
    },
    fetchPolicy: 'network-only',
  })
  const { count, sessions = [] } = ingredientsRerunSessions || {}

  const handlePaginationChange = useCallback(
    newPage => {
      setQueryParams({ page: newPage })
    },
    [setQueryParams],
  )

  const handlePageSizeChange = useCallback(
    (_, size) => {
      setQueryParams({ page: DEFAULT_PAGINATION_CONFIG.PAGE, pageSize: size })
    },
    [setQueryParams],
  )

  if (error) {
    return <p>Failed to get sessions </p>
  }

  return (
    <PageWithHeader menuItem={MENU_ENTRY.INGREDIENTS_RERUN}>
      <div style={styles.pageWrapper}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>Ingredients rerun</Typography.Title>
          </Col>
        </Row>
        <IngredientRerunActionButtons />
        <IngredientsRerunTable
          data={sessions}
          total={count}
          loading={loading}
          onPaginationChange={handlePaginationChange}
          onPageSizeChange={handlePageSizeChange}
          page={page}
          pageSize={pageSize}
        />
      </div>
    </PageWithHeader>
  )
}

export default IngredientsRerun
