import { gql } from '@apollo/client'

import { USER_MANAGEMENT_FRAGMENT } from '../fragments'

export default gql`
  mutation updateUser($id: ID!, $data: UserInput!) {
    updateUser(id: $id, data: $data) {
      ...UserManagementFragment
    }
  }
  ${USER_MANAGEMENT_FRAGMENT}
`
