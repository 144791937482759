import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Select, Spin, Typography } from 'antd'
import { find, isNil, isUndefined, map } from 'lodash'
import PropTypes from 'prop-types'

import { selectFilterOption } from '../../../../core/utils'

import { handleMutationError } from '../../../../common/helpers'

import { cleanupTasksMetadataTypePropType } from '../../../propTypes'

const styles = {
  dropdown: { maxHeight: 400, minWidth: 180 },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 8,
    cursor: 'pointer',
  },
  editDisabled: {
    cursor: 'not-allowed',
  },
  actionSelect: {
    marginTop: 5,
    width: 100,
  },
}

const CleanupTaskUserAsignment = ({
  taskId,
  metadataType,
  assignedUserId,
  availableUsers,
  mutation,
  disabled,
}) => {
  const [editing, setEditing] = useState(false)
  const [assignedUser, setAssignedUser] = useState(null)
  const selectRef = useRef(null)

  const [assignUser, { loading }] = useMutation(mutation, {
    onError: handleMutationError,
  })

  const optionsToShow = useMemo(
    () =>
      map(availableUsers, user => ({
        label: user.name || user.email,
        value: user.id,
      })),
    [availableUsers],
  )

  useEffect(() => {
    if (!isNil(selectRef.current)) {
      selectRef.current.focus()
    }
  }, [editing])

  useEffect(() => {
    let user = null

    if (!isUndefined(assignedUserId)) {
      user = find(availableUsers, ({ id }) => id === assignedUserId)
    }

    setAssignedUser(user)
  }, [assignedUserId, availableUsers])

  const handleClick = useCallback(
    e => {
      e.stopPropagation()

      if (disabled) {
        return
      }

      setEditing(true)
    },
    [setEditing, disabled],
  )

  const handleChange = useCallback(
    async value => {
      await assignUser({
        variables: {
          id: taskId,
          userId: isNil(value) ? null : value,
          metadataType,
        },
      })

      setEditing(false)
    },
    [assignUser, metadataType, taskId],
  )

  const handleBlur = useCallback(() => {
    setEditing(false)
  }, [])

  if (loading) {
    return <Spin size="small" />
  }

  return (
    <div
      role="presentation"
      style={{ ...styles.container, ...(disabled && styles.editDisabled) }}
      onClick={handleClick}
    >
      {editing && !disabled && (
        <Select
          showSearch
          size="small"
          allowClear
          ref={selectRef}
          optionFilterProp="children"
          onChange={handleChange}
          onBlur={handleBlur}
          options={optionsToShow}
          value={assignedUser?.id}
          dropdownStyle={styles.dropdown}
          filterOption={selectFilterOption}
        />
      )}
      {!editing && (
        <Typography.Text>
          {assignedUser?.name || assignedUser?.email || 'unassigned'}
        </Typography.Text>
      )}
    </div>
  )
}

CleanupTaskUserAsignment.propTypes = {
  taskId: PropTypes.string.isRequired,
  assignedUserId: PropTypes.object,
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  mutation: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
}

CleanupTaskUserAsignment.defaultProps = {
  assignedUserId: undefined,
  disabled: false,
  availableUsers: [],
}

export default CleanupTaskUserAsignment
