import React, { useCallback } from 'react'
import { Select } from 'antd'
import { findIndex, map } from 'lodash'
import PropTypes from 'prop-types'

import { SHORT_WEEKDAYS } from '../../../constants'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  menuDropdown: {
    width: 150,
  },
  dayDropdown: {
    width: 80,
    marginLeft: 5,
  },
  timeDropdown: {
    width: 100,
    marginLeft: 5,
  },
  removeButton: {
    marginLeft: 5,
  },
}

const DayOfWeekDropdown = ({ disabled, selectedDayOfWeek, onValueChanged }) => {
  const handleSelect = useCallback(
    value => onValueChanged(findIndex(SHORT_WEEKDAYS, d => d === value)),
    [onValueChanged],
  )

  return (
    <Select
      disabled={disabled}
      value={SHORT_WEEKDAYS[selectedDayOfWeek]}
      onSelect={handleSelect}
      style={styles.dayDropdown}
    >
      {map(SHORT_WEEKDAYS, d => (
        <Select.Option key={d}>{d}</Select.Option>
      ))}
    </Select>
  )
}

DayOfWeekDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  selectedDayOfWeek: PropTypes.number.isRequired,
  onValueChanged: PropTypes.func.isRequired,
}

export default DayOfWeekDropdown
