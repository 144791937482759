import { gql } from '@apollo/client'

const SEND_BRAND_TO_QA_MUTATION = gql`
  mutation sendBrandToQa($id: ID!) {
    sendBrandToQa(id: $id) {
      id
      requiresQaAttention
      requiresCuratorAttention
    }
  }
`

export default SEND_BRAND_TO_QA_MUTATION
