import React from 'react'
import { notification, Typography } from 'antd'
import {
  filter,
  find,
  get,
  groupBy,
  isEmpty,
  map,
  reverse,
  sortBy,
} from 'lodash'

import {
  CURATION_STATUS,
  EVENT_TYPES,
  suggestionEndpoints,
} from '../../common/constants'
import { googleAnalyticsEventsService } from '../../common/services'
import { getAiSuggestions } from '../../common/utils'

import { COURSE_TYPE } from '../constants'
import { dishCourseTypeSuggestionTransformer } from '../transformers'

export default async function suggestCourseType({
  dish,
  additionalInformation,
}) {
  try {
    const [suggestions] = await getAiSuggestions(
      suggestionEndpoints.courseTypes,
      [
        dishCourseTypeSuggestionTransformer.transformDishToAiRequestDto(
          dish,
          additionalInformation,
        ),
      ],
      dishCourseTypeSuggestionTransformer.transformDishFromAiResponseDto,
    )

    const aiMessages = get(suggestions, 'messages')
    if (!isEmpty(aiMessages)) {
      notification.error({
        message: 'An error occured while suggesting course type',
        description: map(aiMessages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })
      return null
    }

    if (isEmpty(suggestions)) {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.NO_SUGGESTIONS,
        { for: COURSE_TYPE },
      )
      notification.warning({
        message: 'No suggestions.',
        description: 'The Ai could not suggest any course types for this dish',
        placement: 'topLeft',
      })
      return null
    }

    const highestScoreFromSuggestions = Math.max(...map(suggestions, 'score'))

    let courseTypeId = get(
      find(
        suggestions,
        suggestion => suggestion.score === highestScoreFromSuggestions,
      ),
      'id',
    )

    const {
      brand: { status },
      dishTypeOptions,
    } = additionalInformation

    if (
      status === CURATION_STATUS.CURATION_CONFIRMATION.value &&
      !isEmpty(dishTypeOptions)
    ) {
      const groupedIgnoredCourseTypes = groupBy(
        filter(dishTypeOptions, 'isIgnored'),
        'courseTypeId',
      )
      const suggestionsSortedByScoreDescending = reverse(
        sortBy(suggestions, 'score'),
      )

      courseTypeId =
        get(
          find(suggestionsSortedByScoreDescending, suggestion =>
            isEmpty(get(groupedIgnoredCourseTypes, suggestion.id)),
          ),
          'id',
        ) || null
    }

    return {
      aiSuggestions: suggestions,
      courseTypeId,
    }
  } catch (e) {
    notification.error({
      message: 'An error occured while suggesting course type',
      description: e.message,
      placement: 'topLeft',
    })
    return null
  }
}
