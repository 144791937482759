import { gql } from '@apollo/client'

import {
  SIMPLE_ENTITY_FRAGMENT,
  SIMPLE_USER_FRAGMENT,
} from '../../../common/graphql'

const BRAND_SUGGESTED_WORD_FRAGMENT = gql`
  fragment BrandSuggestedWordFragment on BrandSuggestedWord {
    id
    value
    originalValue
    context
    payload
    translation
    labelLegendMatchType
    type
    originField
    isContextDependant
    isContextDependantFromAi
    isExported
    isCurationDone
    isQaDone
    curatedAt
    type
    brandId

    qaCorrectionCounts {
      value
      type
      ingredientKeywordMatchType
      languageId
      allergenId
      dietId
      ingredientId
    }

    dish {
      ...SimpleEntityFragment
    }
    menuTitle {
      ...SimpleEntityFragment
    }
    curatedBy {
      ...SimpleUserFragment
    }
    qadBy {
      ...SimpleUserFragment
    }
    updatedBy {
      ...SimpleUserFragment
    }
  }
  ${SIMPLE_USER_FRAGMENT}
  ${SIMPLE_ENTITY_FRAGMENT}
`

export default BRAND_SUGGESTED_WORD_FRAGMENT
