import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { notification } from 'antd'
import { isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'

import { SaveButton } from '../../../../common/components'
import { APP_ROUTES, EVENT_TYPES } from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { userIdNew } from '../../../constants'
import { CREATE_USER_MUTATION, UPDATE_USER_MUTATION } from '../../../graphql'
import { userTransformer } from '../../../transformers'

const onSubmit = async (user, createUser, updateUser) => {
  if (user.id !== userIdNew) {
    await updateUser({
      variables: {
        id: user.id,
        data: userTransformer.toSaveArgs(user),
      },
    })
  } else {
    await createUser({
      variables: {
        data: userTransformer.toSaveArgs(user),
      },
    })
  }
}

const UserSaveButtonComponent = ({ userId }) => {
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    errors,
  } = useFormContext()

  const history = useHistory()

  const [createUser, { isLoading: isCreateLoading }] = useMutation(
    CREATE_USER_MUTATION,
    {
      onCompleted: ({ createUser: { id, name } }) => {
        notification.open({
          message: 'User created.',
          description: `${name} has been succesfully created.`,
          type: 'success',
        })

        history.push(`${APP_ROUTES.USER_ROUTES.Users}/${id}`)
      },

      onError: ({ message }) => {
        notification.open({
          message: 'User creation failed.',
          description: message,
          type: 'error',
        })
      },
    },
  )

  const [updateUser, { isLoading: isUpdateLoading }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted({ updateUser: { name } }) {
        notification.open({
          message: 'User updated.',
          description: `${name} has been sucesfully updated.`,
          type: 'success',
        })
      },

      onError({ message }) {
        notification.open({
          message: 'User update failed.',
          description: message,
          type: 'error',
        })
      },
    },
  )

  const handleSaveClick = useCallback(
    async values => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_EVENTS.SAVE_CLICK,
      )

      const userToSave = {
        id: userId,
        ...values,
      }

      await onSubmit(userToSave, createUser, updateUser)
    },
    [userId, createUser, updateUser],
  )

  return (
    <SaveButton
      loading={isSubmitting || isCreateLoading || isUpdateLoading}
      disabled={!isDirty || !isEmpty(errors)}
      onClick={handleSubmit(handleSaveClick)}
    />
  )
}

UserSaveButtonComponent.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default memo(UserSaveButtonComponent)
