import PropTypes from 'prop-types'

const cleanupTaskPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  batches: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    totalCount: PropTypes.number,
    doneCount: PropTypes.number,
    qaCount: PropTypes.string,
    isCurationDone: PropTypes.bool,
    isQaDone: PropTypes.bool,
    assignedCuratorId: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }),
})
export default cleanupTaskPropType
