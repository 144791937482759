import PropTypes from 'prop-types'

const locationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  brandId: PropTypes.string,
  locationTypeId: PropTypes.string,
  cuisineTypeId: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  incomplete: PropTypes.bool,
  address: PropTypes.string,
  zipCode: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  web: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  menuSource: PropTypes.string,
  menuLink: PropTypes.string,
})

export default locationPropType
