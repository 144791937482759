import { gql } from '@apollo/client'

import { ML_CLEANUP_TASK_REQUEST_FRAGMENT } from '../fragments'

const CREATE_ML_CLEANUP_TASK_MUTATION = gql`
  mutation createMlCleanupTaskMutation($input: MlCleanupTaskInput!) {
    createMlCleanupTask(input: $input) {
      ...MlCleanupTaskRequestFragment
    }
  }
  ${ML_CLEANUP_TASK_REQUEST_FRAGMENT}
`

export default CREATE_ML_CLEANUP_TASK_MUTATION
