export default Object.freeze({
  PUBLISHED: {
    label: 'Published',
    value: 'PUBLISHED',
  },
  UNPUBLISHED: {
    label: 'Unpublished',
    value: 'UNPUBLISHED',
  },
})
