import { isEmpty, map } from 'lodash'

import { areSameValue } from '../../core/utils'

import { findNodeBy, getMenuTitlesWithParents } from '../../common/utils'

export const transformToMlCleanups = data =>
  map(data, cleanup => {
    if (isEmpty(cleanup.dish) || isEmpty(cleanup.brand)) {
      return cleanup
    }

    const selectedMenuTitle = cleanup.dish.menuTitle
      ? findNodeBy(cleanup.brand.menuTitles, ({ id }) =>
          areSameValue(id, cleanup.dish.menuTitle.id),
        )
      : undefined

    return {
      ...cleanup,
      dish: {
        ...cleanup.dish,
        menuTitleParents: getMenuTitlesWithParents(
          selectedMenuTitle,
          cleanup.brand.menuTitles,
        ),
      },
    }
  })
