import React, { useCallback, useMemo, useState } from 'react'
import { ReadOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Modal, Table, Tooltip, Typography } from 'antd'
import { isEmpty, isNil } from 'lodash'

import { formatDate } from '../../../../core/utils'

import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'

import { CLEANUP_TASK_HISTORY_QUERY } from '../../../graphql'

import './CleanupTaskHistoryModal.css'

const styles = {
  modal: { width: '1100px' },
}

const CleanupTaskHistoryModal = () => {
  const [visible, setVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(null)

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const { data: { cleanupTaskHistory } = {}, error } = useQuery(
    CLEANUP_TASK_HISTORY_QUERY,
    {
      variables: {
        currentPage,
        pageSize: Number(pageSize || userDefaultPageSize),
      },
    },
  )
  const { count, tasks = [] } = cleanupTaskHistory || {}

  const paginationConfig = useMemo(() => {
    if (isNil(count)) {
      return false
    }

    return {
      current: currentPage,
      pageSize: Number(pageSize || userDefaultPageSize),
      total: count,
      onChange: newPage => setCurrentPage(newPage),
      onShowSizeChange: (_, size) => setPageSize(size),
      pageSizeOptions: DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS,
    }
  }, [count, currentPage, pageSize, userDefaultPageSize])

  const modalContent = useMemo(() => {
    const columns = [
      {
        title: 'Name',
        width: 170,
        render: ({ name }) => name,
      },
      {
        title: 'Type',
        width: 30,
        dataIndex: 'type',
        name: 'type',
      },
      {
        title: 'Metadata type',
        width: 30,
        dataIndex: 'metadataType',
        name: 'metadataType',
      },
      {
        title: 'Created by',
        width: 100,
        render: ({ createdBy }) => {
          if (isEmpty(createdBy)) {
            return ''
          }

          return createdBy.name
        },
      },
      {
        title: 'Created at',
        width: 100,
        render: ({ createdAt }) => formatDate(createdAt),
      },
      {
        title: 'Curated at',
        width: 100,
        render: ({ curatedAt }) => formatDate(curatedAt),
      },
      {
        title: 'Qad at',
        width: 100,
        render: ({ qadAt }) => formatDate(qadAt),
      },
    ]

    if (error) {
      return <Typography.Paragraph>Failed to get history</Typography.Paragraph>
    }

    return (
      <Table
        dataSource={tasks}
        columns={columns}
        size="small"
        pagination={paginationConfig}
      />
    )
  }, [error, paginationConfig, tasks])

  const handleCancel = useCallback(() => setVisible(false), [])
  const toggleModal = useCallback(() => setVisible(!visible), [visible])

  return (
    <>
      <Modal
        title="Cleanup tasks history"
        footer={null}
        wrapClassName="cleanup-task-history-modal"
        visible={visible}
        width={styles.modal.width}
        onCancel={handleCancel}
      >
        {modalContent}
      </Modal>
      <Tooltip title="Cleanup task history">
        <Button icon={<ReadOutlined />} shape="circle" onClick={toggleModal} />
      </Tooltip>
    </>
  )
}

export default CleanupTaskHistoryModal
