import React, { useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { notification } from 'antd'
import { every, get, isEmpty, some } from 'lodash'
import PropTypes from 'prop-types'

import { APP_ROUTES } from '../../constants'
import { useAuthentication } from '../../hooks'

const PrivateRoute = ({ Component, location, permissions, ...rest }) => {
  const { userInfo, isRefreshTokenAvailable } = useAuthentication()
  const [wasLoggedIn, setWasLoggedIn] = useState(false)

  if (!get(userInfo, 'email') && isRefreshTokenAvailable) {
    return null
  }

  if (get(userInfo, 'email')) {
    if (wasLoggedIn === false) {
      setWasLoggedIn(true)
    }

    if (
      isEmpty(permissions) ||
      every(permissions, permission =>
        some(
          userInfo.permissions,
          userPermission => userPermission === permission,
        ),
      )
    ) {
      return <Route {...rest} component={Component} location={location} />
    }

    notification.error({
      message: "You don't have the necessary rights to view that page",
    })
    return (
      <Redirect
        to={{
          pathname: APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations,
        }}
      />
    )
  }

  // If the user logged out, the default route should be used after login
  if (wasLoggedIn) {
    return <Redirect to={{ pathname: APP_ROUTES.ACCOUNT_ROUTES.Login }} />
  }

  return (
    <Redirect
      to={{
        pathname: APP_ROUTES.ACCOUNT_ROUTES.Login,
        state: { from: location },
      }}
    />
  )
}

PrivateRoute.propTypes = {
  Component: PropTypes.any.isRequired,
  location: PropTypes.shape({}),
  permissions: PropTypes.arrayOf(PropTypes.string),
}

PrivateRoute.defaultProps = {
  permissions: undefined,
  location: undefined,
}

export default PrivateRoute
