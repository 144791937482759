const METADATA_TYPES = {
  INGREDIENTS: 'INGREDIENTS',
  DISH_TYPES: 'DISH_TYPES',
  LOCATION_TYPES: 'LOCATION_TYPES',
  CUISINE_TYPES: 'CUISINE_TYPES',
  COURSE_TYPES: 'COURSE_TYPES',
  ALLERGENS: 'ALLERGENS',
}

export default METADATA_TYPES
