import { gql } from '@apollo/client'

export default gql`
  mutation setDishQaDone($ids: [ID]!, $value: Boolean!) {
    setDishQaDone(ids: $ids, value: $value) {
      id
      isQaDone
    }
  }
`
