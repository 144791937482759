import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, Checkbox, Table } from 'antd'
import { join, map, upperFirst } from 'lodash'

import { useDocumentTitle } from '../../../../core/hooks'
import { formatDate } from '../../../../core/utils'

import { PageWithHeader } from '../../../../common/components'
import {
  APP_ROUTES,
  EVENT_TYPES,
  MENU_ENTRY,
} from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { USERS_QUERY } from '../../../graphql'
import { UsersTableActionColumn } from '../../molecules'

const UsersPage = () => {
  useDocumentTitle('Users')
  const history = useHistory()

  const { data: { users } = {}, loading: isLoadingUsers } = useQuery(
    USERS_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  )

  const handleAddUser = useCallback(() => {
    googleAnalyticsEventsService.fireEvent(EVENT_TYPES.USER_EVENTS.NEW_CLICK)
    history.push(APP_ROUTES.USER_ROUTES.UserCreate)
  }, [history])

  const usersTableColumns = useMemo(
    () => [
      {
        title: 'User ID',
        dataIndex: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        render: roles =>
          join(
            map(roles, ({ name }) => upperFirst(name)),
            ', ',
          ),
      },
      {
        title: 'Cuisine Knowledge',
        dataIndex: 'cuisineTypes',
        render: cuisineTypes => join(map(cuisineTypes, 'name'), ', '),
      },
      {
        title: 'Known Languages',
        dataIndex: 'languages',
        render: languages => join(map(languages, 'name'), ', '),
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Seniority',
        dataIndex: 'seniority',
      },
      {
        title: 'Latest login',
        dataIndex: 'lastLoginDate',
        align: 'center',
        render: date => (date ? formatDate(date) : 'N/A'),
      },
      {
        title: 'Enabled',
        dataIndex: 'isActive',
        align: 'center',
        render: isActive => <Checkbox checked={isActive} disabled />,
      },
      {
        align: 'right',
        render: user => <UsersTableActionColumn user={user} />,
      },
    ],
    [],
  )

  return (
    <>
      <PageWithHeader
        menuItem={MENU_ENTRY.USERS}
        renderRightMenu={() => (
          <Button onClick={handleAddUser}>Add user</Button>
        )}
      >
        <Table
          loading={isLoadingUsers}
          rowKey="id"
          columns={usersTableColumns}
          dataSource={users}
          pagination={false}
        />
      </PageWithHeader>
    </>
  )
}

export default UsersPage
