import { filter, flatten, includes, isEmpty, map } from 'lodash'

const getAllIdsFromTreeToLevel = (nodes, currentLevel, maxLevel) => {
  if (currentLevel >= maxLevel || isEmpty(nodes)) {
    return []
  }

  return flatten(
    map(
      filter(
        nodes,
        node => !node.disabled && node.value && !includes(node.value, 'all'),
      ),
      node => [
        node.value,
        ...getAllIdsFromTreeToLevel(node.children, currentLevel + 1, maxLevel),
      ],
    ),
  )
}

export default getAllIdsFromTreeToLevel
