import React, { useMemo } from 'react'
import { WarningTwoTone } from '@ant-design/icons'
import { Col, Row, Tooltip, Typography } from 'antd'
import { get, groupBy, isEmpty, map, mapValues, uniqBy } from 'lodash'
import PropTypes from 'prop-types'

const styles = {
  whiteText: {
    color: 'white',
  },
}

const DishModalErrors = ({ errors }) => {
  const errorsGroupedByLevel = useMemo(
    () =>
      mapValues(groupBy(errors, 'level'), messageList =>
        uniqBy(messageList, 'message'),
      ),
    [errors],
  )

  return (
    <Row align="middle" gutter={[16]}>
      {!isEmpty(get(errorsGroupedByLevel, 'Warning')) && (
        <Col>
          <Tooltip
            placement="bottom"
            title={map(
              get(errorsGroupedByLevel, 'Warning'),
              ({ message }, index) => (
                <Typography.Paragraph style={styles.whiteText} key={index}>
                  {message}
                </Typography.Paragraph>
              ),
            )}
            color="#ffa940"
          >
            <WarningTwoTone twoToneColor="#ffa940" />
          </Tooltip>
        </Col>
      )}
      {!isEmpty(get(errorsGroupedByLevel, 'Error')) && (
        <Col>
          <Tooltip
            placement="bottom"
            title={map(
              get(errorsGroupedByLevel, 'Error'),
              ({ message }, index) => (
                <Typography.Paragraph style={styles.whiteText} key={index}>
                  {message}
                </Typography.Paragraph>
              ),
            )}
            color="#f5222d"
          >
            <WarningTwoTone twoToneColor="#f5222d" />
          </Tooltip>
        </Col>
      )}
    </Row>
  )
}

DishModalErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.oneOf(['Error', 'Warning']).isRequired,
      message: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default DishModalErrors
