import React, { useCallback, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ReadOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { AVAILABLE_HOTKEYS, CLEANUP_TASK_TYPES } from '../../../constants'

import './CleanupTaskHotkeysLegend.css'

const styles = {
  modal: { width: '900px' },
}

const CleanupTaskHotkeysLegend = ({ taskType }) => {
  const [visible, setVisible] = useState(false)
  const isMac = useMemo(
    () => navigator.platform.toUpperCase().indexOf('MAC') >= 0,
    [],
  )

  const tableData = useMemo(() => {
    const commonHotkeys = [
      AVAILABLE_HOTKEYS.L,
      ...(isMac
        ? [AVAILABLE_HOTKEYS.CMD_LEFT, AVAILABLE_HOTKEYS.CMD_RIGHT]
        : [AVAILABLE_HOTKEYS.CTRL_LEFT, AVAILABLE_HOTKEYS.CTRL_RIGHT]),
      AVAILABLE_HOTKEYS.UP,
      AVAILABLE_HOTKEYS.DOWN,
      AVAILABLE_HOTKEYS.SHIFT_DOWN,
      AVAILABLE_HOTKEYS.SHIFT_UP,
      AVAILABLE_HOTKEYS.ENTER,
      AVAILABLE_HOTKEYS.ESC,
      AVAILABLE_HOTKEYS.S,
      AVAILABLE_HOTKEYS.SHIFT_S,
      ...(isMac ? [AVAILABLE_HOTKEYS.CMD_D] : [AVAILABLE_HOTKEYS.CTRL_D]),
    ]

    switch (taskType) {
      case CLEANUP_TASK_TYPES.REVIEW:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.R,
          AVAILABLE_HOTKEYS.SHIFT_R,
          ...(isMac ? [AVAILABLE_HOTKEYS.CMD_R] : [AVAILABLE_HOTKEYS.CTRL_R]),
        ]
      case CLEANUP_TASK_TYPES.ML:
        return [
          ...commonHotkeys,
          AVAILABLE_HOTKEYS.C,
          AVAILABLE_HOTKEYS.M,
          AVAILABLE_HOTKEYS.O,
        ]
      default:
        return [...commonHotkeys]
    }
  }, [isMac, taskType])

  const popoverContent = useMemo(() => {
    const columns = [
      {
        title: 'Key',
        dataIndex: 'hotkey',
        key: 'hotkey',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
    ]

    return (
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={false}
      />
    )
  }, [tableData])

  const handleCancel = useCallback(() => setVisible(false), [])
  const toggleModal = useCallback(() => setVisible(!visible), [visible])

  // hotkey to open/close the legend
  useHotkeys(AVAILABLE_HOTKEYS.L.hotkey, toggleModal, [toggleModal])

  return (
    <>
      <Modal
        title="Dish list navigation hotkeys"
        footer={null}
        wrapClassName="task-list-hotkeys-legend"
        visible={visible}
        width={styles.modal.width}
        onCancel={handleCancel}
      >
        {popoverContent}
      </Modal>
      <Tooltip title="Task list navigation hotkeys">
        <Button icon={<ReadOutlined />} shape="circle" onClick={toggleModal} />
      </Tooltip>
    </>
  )
}

CleanupTaskHotkeysLegend.propTypes = {
  taskType: PropTypes.string.isRequired,
}

export default CleanupTaskHotkeysLegend
