import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { filter, includes, map, some } from 'lodash'

import { usePrevious } from '../../core/hooks'
import { areArraysIdentical, areSameValue } from '../../core/utils'

import { USER_DEPARTMENT, USER_OTHER_QUALIFICATION_VALUE } from '../constants'

const useUserFormResetRules = ({
  selectedDepartmentValues,
  selectedQualificationIds,
  selectedQualificationValues,
  qualificationOptions,
}) => {
  const { setValue } = useFormContext()

  const previousSelectedDepartmentValues = usePrevious(selectedDepartmentValues)
  const previousSelectedQualificationValues = usePrevious(
    selectedQualificationValues,
  )
  const availableQualificationIds = useMemo(
    () => map(qualificationOptions, 'value'),
    [qualificationOptions],
  )

  useEffect(() => {
    if (
      !areArraysIdentical(
        previousSelectedQualificationValues,
        selectedQualificationValues,
      )
    ) {
      if (
        !includes(selectedQualificationValues, USER_OTHER_QUALIFICATION_VALUE)
      ) {
        setValue('otherQualification', null)
      }
    }
  }, [
    previousSelectedQualificationValues,
    selectedQualificationValues,
    setValue,
  ])

  useEffect(() => {
    if (
      !areArraysIdentical(
        previousSelectedDepartmentValues,
        selectedDepartmentValues,
      )
    ) {
      if (
        !includes(selectedDepartmentValues, USER_DEPARTMENT.CURATION) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.SCRAPING) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.MANAGEMENT) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.RECRUITMENT)
      ) {
        setValue('tier', null)
      }

      if (!includes(selectedDepartmentValues, USER_DEPARTMENT.CURATION)) {
        setValue('languageIds', [])
        setValue('cuisineTypeIds', [])
      }

      if (
        !includes(selectedDepartmentValues, USER_DEPARTMENT.CURATION) &&
        !includes(selectedDepartmentValues, USER_DEPARTMENT.SCRAPING)
      ) {
        setValue('previousWorkExperience', null)
      }

      if (
        some(
          selectedQualificationIds,
          id =>
            !some(availableQualificationIds, availableId =>
              areSameValue(id, availableId),
            ),
        )
      ) {
        setValue(
          'qualificationIds',
          filter(selectedQualificationIds, id =>
            some(availableQualificationIds, availableId =>
              areSameValue(id, availableId),
            ),
          ),
        )
      }
    }
  }, [
    availableQualificationIds,
    previousSelectedDepartmentValues,
    selectedDepartmentValues,
    selectedQualificationIds,
    selectedQualificationValues,
    setValue,
  ])
}

export default useUserFormResetRules
