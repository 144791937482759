import PropTypes from 'prop-types'

import { METADATA_TYPES } from '../../common/constants'

const cleanupTasksMetadataTypePropType = PropTypes.oneOf([
  METADATA_TYPES.DISH_TYPES,
  METADATA_TYPES.COURSE_TYPES,
  METADATA_TYPES.CUISINE_TYPES,
  METADATA_TYPES.LOCATION_TYPES,
])
export default cleanupTasksMetadataTypePropType
