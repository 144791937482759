import React from 'react'

import { DishEditableSelectsRowProvider } from '../../../providers'

// eslint-disable-next-line react/prop-types
const DishEditableSelectsRow = ({ index, ...props }) => (
  <DishEditableSelectsRowProvider rowProps={props} />
)

export default DishEditableSelectsRow
