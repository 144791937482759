import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from '@apollo/client'
import { Checkbox, Spin } from 'antd'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'

import { AVAILABLE_HOTKEYS } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'

import { DISH_FIELDS, DISH_ML_INGREDIENT_TYPE } from '../../../constants'
import {
  DISH_BY_ID_QUERY,
  SET_DISH_INGREDIENT_DISCREPANCY_ACCEPTED_MUTATION,
} from '../../../graphql'
import { DishAiHighlightingFieldValue } from '../DishAiHighlightingFieldValue'

const styles = {
  discrepancyCheckbox: {
    marginRight: 6,
  },
  loader: {
    marginRight: 6,
    fontSize: 13.5,
  },
}

const DishIngredientsPopoverIngredient = ({
  id,
  name,
  fieldName,
  dishId,
  highlight,
  isDiscrepancyAccepted,
  isSuggestedUsingMl,
  isSuggestedUsingParser,
  isCheckboxVisible,
  isCheckboxDisabled,
  isSelected,
}) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)
  const [setDiscrepancyAccepted, { loading: isSetDiscrepancyAcceptedLoading }] =
    useMutation(SET_DISH_INGREDIENT_DISCREPANCY_ACCEPTED_MUTATION, {
      onError: handleMutationError,
      refetchQueries: [
        {
          query: DISH_BY_ID_QUERY,
          variables: { id: dishId },
        },
      ],
    })

  useHotkeys(
    AVAILABLE_HOTKEYS.SPACE.hotkey,
    e => {
      e.preventDefault()
      setDiscrepancyAccepted({
        variables: {
          id,
          dishId,
          value: !isDiscrepancyAccepted,
          ingredientType:
            fieldName === DISH_FIELDS.CHOICE_INGREDIENTS
              ? DISH_ML_INGREDIENT_TYPE.CHOICE_INGREDIENT
              : DISH_ML_INGREDIENT_TYPE.MAIN_INGREDIENT,
        },
      })
    },
    {
      enabled: isSelected && isSuggestedUsingMl !== isSuggestedUsingParser,
    },
    [
      isSelected,
      dishId,
      fieldName,
      id,
      isDiscrepancyAccepted,
      isSuggestedUsingMl,
      isSuggestedUsingParser,
      setDiscrepancyAccepted,
    ],
  )

  const isIngredientsDiscrepancyCheckboxVisible = useMemo(
    () => isCheckboxVisible && isSuggestedUsingMl !== isSuggestedUsingParser,
    [isCheckboxVisible, isSuggestedUsingMl, isSuggestedUsingParser],
  )

  const handleIngredientDiscrepancyClick = useCallback(
    ({ target: { checked: value } }) =>
      setDiscrepancyAccepted({
        variables: {
          id,
          dishId,
          value,
          ingredientType:
            fieldName === DISH_FIELDS.CHOICE_INGREDIENTS
              ? DISH_ML_INGREDIENT_TYPE.CHOICE_INGREDIENT
              : DISH_ML_INGREDIENT_TYPE.MAIN_INGREDIENT,
        },
      }),
    [dishId, fieldName, id, setDiscrepancyAccepted],
  )

  useEffect(() => {
    if (isSetDiscrepancyAcceptedLoading) {
      setIsLoaderVisible(true)
    }
  }, [isSetDiscrepancyAcceptedLoading])

  useEffect(() => setIsLoaderVisible(false), [isDiscrepancyAccepted])

  if (isNil(name)) {
    return ''
  }

  return (
    <>
      {isIngredientsDiscrepancyCheckboxVisible && !isLoaderVisible && (
        <Checkbox
          disabled={isCheckboxDisabled}
          style={styles.discrepancyCheckbox}
          checked={isDiscrepancyAccepted}
          onChange={handleIngredientDiscrepancyClick}
        />
      )}
      {isIngredientsDiscrepancyCheckboxVisible && isLoaderVisible && (
        <Spin style={styles.loader} size="small" />
      )}
      {!isNil(highlight) ? (
        <DishAiHighlightingFieldValue
          highlight={highlight}
          strikethroughIngredient={!isDiscrepancyAccepted}
        >
          {name}
        </DishAiHighlightingFieldValue>
      ) : (
        name
      )}
    </>
  )
}

DishIngredientsPopoverIngredient.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  dishId: PropTypes.number.isRequired,
  highlight: PropTypes.string.isRequired,
  isCheckboxVisible: PropTypes.bool,
  isDiscrepancyAccepted: PropTypes.bool,
  isSuggestedUsingMl: PropTypes.bool,
  isSuggestedUsingParser: PropTypes.bool,
  isSelected: PropTypes.bool,
  isCheckboxDisabled: PropTypes.bool,
}

DishIngredientsPopoverIngredient.defaultProps = {
  isDiscrepancyAccepted: false,
  isSuggestedUsingMl: false,
  isSuggestedUsingParser: false,
  isCheckboxVisible: false,
  isSelected: false,
  isCheckboxDisabled: false,
}

export default DishIngredientsPopoverIngredient
