const brandSuggestedWordFormStyles = Object.freeze({
  select: {
    margin: '0.25rem 0',
    width: '100%',
  },
  translation: {
    color: '#1b9201',
  },
  matchType: {
    color: '#cccecf',
  },
})

export default brandSuggestedWordFormStyles
