import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { Button } from 'antd'
import PropTypes from 'prop-types'

import { handleMutationError } from '../../../../common/helpers'

import { BRAND_ASSIGNED_USER_ACTIONS } from '../../../constants'
import {
  SEND_BRAND_TO_CURATOR_MUTATION,
  SEND_BRAND_TO_QA_MUTATION,
} from '../../../graphql'

const BrandQaCurationAttentionButton = ({
  brandId,
  requiresQaAttention,
  requiresCuratorAttention,
  action,
  disabled,
}) => {
  const [sendToQa, { loading: sendToQaLoading }] = useMutation(
    SEND_BRAND_TO_QA_MUTATION,
  )
  const [sendToCurator, { loading: sendToCuratorLoading }] = useMutation(
    SEND_BRAND_TO_CURATOR_MUTATION,
  )

  const handleClick = useCallback(async () => {
    try {
      if (action === BRAND_ASSIGNED_USER_ACTIONS.QA) {
        await sendToCurator({
          variables: {
            id: brandId,
          },
        })
      } else {
        await sendToQa({
          variables: {
            id: brandId,
          },
        })
      }
    } catch (e) {
      handleMutationError(e)
    }
  }, [action, brandId, sendToCurator, sendToQa])

  return (
    <>
      {action === BRAND_ASSIGNED_USER_ACTIONS.QA && !requiresCuratorAttention && (
        <Button
          danger={requiresQaAttention}
          onClick={handleClick}
          disabled={disabled}
          loading={sendToCuratorLoading}
        >
          Send back to curator
        </Button>
      )}
      {action === BRAND_ASSIGNED_USER_ACTIONS.CURATION && !requiresQaAttention && (
        <Button
          danger={requiresCuratorAttention}
          onClick={handleClick}
          disabled={disabled}
          loading={sendToQaLoading}
        >
          Send back to QA
        </Button>
      )}
    </>
  )
}

BrandQaCurationAttentionButton.propTypes = {
  brandId: PropTypes.string.isRequired,
  action: PropTypes.string,
  disabled: PropTypes.bool,
  requiresQaAttention: PropTypes.bool,
  requiresCuratorAttention: PropTypes.bool,
}

BrandQaCurationAttentionButton.defaultProps = {
  action: undefined,
  disabled: false,
  requiresQaAttention: false,
  requiresCuratorAttention: false,
}

export default BrandQaCurationAttentionButton
