import { useEffect, useRef } from 'react'

/** Generic hook that allows retrival of previous values from a prop.
 * Useful to use with useEffect to compare props with previous values. */
export default function usePrevious(value) {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
