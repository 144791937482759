import { join } from 'path'

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { APP_ROUTES } from '../../common/constants'

import { LocationsRouter } from '../../locations/components'

import { BrandDetails, NewBrand } from './pages'

const BrandsRouter = () => (
  <Switch>
    <Route exact path={APP_ROUTES.BRAND_ROUTES.NewBrand} component={NewBrand} />
    <Route
      exact
      path={APP_ROUTES.BRAND_ROUTES.BrandsWithValidation}
      component={BrandDetails}
    />
    <Route
      path={join(
        APP_ROUTES.BRAND_ROUTES.BrandsWithValidation,
        APP_ROUTES.LOCATION_ROUTES.LocationsPrefix,
      )}
      component={LocationsRouter}
    />
    <Redirect
      from="/"
      to={APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations}
    />
  </Switch>
)

export default BrandsRouter
