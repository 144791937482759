import { filter, includes, join, map } from 'lodash'

import flattenTreeStructure from './flattenTreeStructure'

const joinMetadataTitlesByIds = (ids, metadata) =>
  join(
    map(
      filter(flattenTreeStructure(metadata), ({ id }) => includes(ids, id)),
      'title',
    ),
    ', ',
  )

export default joinMetadataTitlesByIds
