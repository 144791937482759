import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { deleteFromStorage } from '@rehooks/local-storage'
import { Menu, Typography } from 'antd'
import {
  filter,
  get,
  groupBy,
  includes,
  isEmpty,
  isNil,
  keys,
  map,
  omit,
  some,
} from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../../../core/hooks'

import { getAvailableReports } from '../../../../reports/services'
import { APP_ROUTES, MENU_ENTRY, PERMISSIONS } from '../../../constants'
import { handleMutationError } from '../../../helpers'
import {
  useAuthentication,
  useBrandBatchAiSuggestionsSubscription,
  useDuplicatedBrandSubscription,
  useExportedBrandSuggestedWordsSubscription,
  useMenuImportSubscription,
  useSuggestedWordsSubscription,
  useUserHasRoles,
} from '../../../hooks'
import { setActiveMenuItem, setAvailableReports } from '../../../redux/actions'
import { sessionManager } from '../../../services'

const styles = {
  leftMenu: {
    display: 'flex',
    width: '100%',
  },
  rightMenu: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    lineHeight: 'none',
  },
  logoutButton: {
    margin: 10,
  },
  titleWrapper: {
    background: '#fff',
    padding: '0 20px',
    whiteSpace: 'nowrap',
    cursor: 'default',
    borderBottom: '1px solid #e8e8e8',
  },
}

const HeaderNavigation = ({ renderRightMenu }) => {
  const dispatch = useDispatch()
  const {
    activeMenuItem,
    activeLocationName,
    activeUserEmail,
    availableReports,
  } = useShallowEqualSelector(
    ({
      app: {
        activeMenuItem: menuItem,
        activeNames: { brand, location, user },
        availableReports: reports,
      },
    }) => ({
      activeMenuItem: menuItem,
      activeBrandName: brand,
      activeLocationName: location,
      activeUserEmail: user,
      availableReports: reports,
    }),
  )

  const { userInfo, setUserInfo } = useAuthentication()
  const userIsLearner = useUserHasRoles(['learner'])
  const history = useHistory()

  useMenuImportSubscription()
  useBrandBatchAiSuggestionsSubscription()
  useDuplicatedBrandSubscription()
  useSuggestedWordsSubscription()
  useExportedBrandSuggestedWordsSubscription()

  const userProfilePath = useMemo(
    () => `${APP_ROUTES.USER_ROUTES.Users}/${userInfo?.id}`,
    [userInfo?.id],
  )

  const handleClick = useCallback(
    ({ key }) => {
      dispatch(setActiveMenuItem(key))
    },
    [dispatch],
  )

  const renderNavigationTabs = useCallback(
    (navigationTabs, userPermissions) =>
      map(
        filter(
          navigationTabs,
          ({ permissions }) =>
            !permissions ||
            some(userPermissions, userRole => includes(permissions, userRole)),
        ),
        ({ link, name, subMenuItems }) =>
          !isEmpty(subMenuItems) ? (
            <Menu.SubMenu key={name} title={name}>
              {renderNavigationTabs(subMenuItems, userPermissions)}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={name}>
              <Link to={link}>{name}</Link>
            </Menu.Item>
          ),
      ),
    [],
  )

  const onLogout = useCallback(() => {
    sessionManager.destroySession()
    deleteFromStorage('brandFilters')
    setUserInfo()
  }, [setUserInfo])

  useEffect(() => {
    const fetchAvailableReports = async () => {
      try {
        const availableReportsResult = await getAvailableReports()
        dispatch(setAvailableReports(availableReportsResult))
      } catch (e) {
        handleMutationError(e)
      }
    }
    fetchAvailableReports()
  }, [dispatch])

  const navigationTabs = useMemo(() => {
    const defaultTabs = [
      {
        name: MENU_ENTRY.BRANDS_AND_LOCATIONS,
        link: APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations,
      },
      {
        name: 'Misc',
        subMenuItems: [
          {
            name: MENU_ENTRY.USERS,
            link: APP_ROUTES.USER_ROUTES.Users,
            permissions: [PERMISSIONS.USERS.UPDATE],
          },
          {
            name: 'Cleanup Tasks',
            permissions: [PERMISSIONS.TASKS.LIST],
            subMenuItems: [
              {
                name: MENU_ENTRY.CREATE_CLEANUP_REVIEW_TASK,
                link: `${APP_ROUTES.CLEANUP_TASK_ROUTES.CleanupTasks}/review/create`,
                permissions: [PERMISSIONS.TASKS.CREATE],
              },
              {
                name: MENU_ENTRY.CREATE_CLEANUP_ML_TASK,
                link: `${APP_ROUTES.CLEANUP_TASK_ROUTES.CleanupTasks}/ml/create`,
                permissions: [PERMISSIONS.TASKS.CREATE],
              },
              {
                name: MENU_ENTRY.VIEW_CLEANUP_TASKS,
                link: APP_ROUTES.CLEANUP_TASK_ROUTES.CleanupTasks,
              },
            ],
          },
          {
            name: MENU_ENTRY.AI_SETTINGS,
            link: APP_ROUTES.AI_SETTINGS_ROUTES.AiSettings,
            permissions: [PERMISSIONS.AI_SETTINGS.LIST],
          },
          {
            name: MENU_ENTRY.INGREDIENTS_RERUN,
            link: APP_ROUTES.INGREDIENTS_RERUN_ROUTES.IngredientsRerun,
            permissions: [PERMISSIONS.INGREDIENTS_RERUN.LIST],
          },
        ],
      },
    ]

    if (!isEmpty(availableReports)) {
      // learner users can only view the menu autocheck report
      const reports = filter(
        availableReports,
        report => !userIsLearner || report.name === 'menu_check',
      )

      // some sub menus don't have a category and we need to display them in a similar way as to entries without children
      const groupedAvailableReportsByCategory = omit(
        groupBy(reports, 'category'),
        'undefined',
      )
      const availableReportsWithoutCategory = filter(reports, ({ category }) =>
        isNil(category),
      )

      defaultTabs[1].subMenuItems.push({
        name: MENU_ENTRY.REPORTS,
        permissions: [PERMISSIONS.REPORTS.LIST],
        subMenuItems: [
          ...map(keys(groupedAvailableReportsByCategory), category => ({
            name: category,
            subMenuItems: map(
              get(groupedAvailableReportsByCategory, category),
              ({ name, displayName }) => ({
                name: displayName,
                link: `/reports/${name}`,
              }),
            ),
          })),
          ...map(availableReportsWithoutCategory, ({ name, displayName }) => ({
            name: displayName,
            link: `/reports/${name}`,
          })),
        ],
      })
    }

    return defaultTabs
  }, [availableReports, userIsLearner])

  const showLocationName =
    activeMenuItem === MENU_ENTRY.LOCATION && activeLocationName

  const showUserEmail = activeMenuItem === MENU_ENTRY.USER && activeUserEmail

  return (
    <>
      <Typography.Text strong style={styles.titleWrapper}>
        <i>Food Styles Curator</i>
      </Typography.Text>
      <Menu
        theme="light"
        style={styles.leftMenu}
        onClick={handleClick}
        selectedKeys={[activeMenuItem]}
        mode="horizontal"
      >
        {showLocationName && (
          <Menu.Item key={MENU_ENTRY.LOCATION}>{activeLocationName}</Menu.Item>
        )}
        {showUserEmail && (
          <Menu.Item key={MENU_ENTRY.USER}>{activeUserEmail}</Menu.Item>
        )}
        {renderNavigationTabs(navigationTabs, userInfo.permissions, history)}
      </Menu>
      <Menu mode="horizontal" style={styles.rightMenu}>
        {renderRightMenu && (
          <Menu.Item key="rightMenu">{renderRightMenu()}</Menu.Item>
        )}
        <Menu.SubMenu key="userLinks" title={<UserOutlined />}>
          <Menu.Item key="settings">
            <Link to={APP_ROUTES.USER_ROUTES.UserSettings}>
              <SettingOutlined /> Settings
            </Link>
          </Menu.Item>
          <Menu.Item key="settings">
            <Link to={userProfilePath}>
              <UserOutlined /> Profile
            </Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={onLogout}>
            <Typography.Text type="danger">
              <LogoutOutlined />
              Logout
            </Typography.Text>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </>
  )
}

HeaderNavigation.propTypes = {
  renderRightMenu: PropTypes.func,
}

HeaderNavigation.defaultProps = {
  renderRightMenu: undefined,
}

export default HeaderNavigation
