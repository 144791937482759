import { gql } from '@apollo/client'

import { CLEANUP_TASK_FRAGMENT } from '../fragments'

const CREATE_REVIEW_CLEANUP_TASK_MUTATION = gql`
  mutation createReviewCleanupTaskMutation($input: ReviewCleanupTaskInput!) {
    createReviewCleanupTask(input: $input) {
      ...CleanupTaskFragment
    }
  }
  ${CLEANUP_TASK_FRAGMENT}
`

export default CREATE_REVIEW_CLEANUP_TASK_MUTATION
