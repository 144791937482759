import React from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import { filter, get, includes, isEmpty, isEqual, map } from 'lodash'

import { TypographyLinkWrapper } from '../../../../core/components'
import { isStringTrue } from '../../../../core/utils'

import {
  CURATION_STATUS,
  EVENT_TYPES,
  ORIGIN_PLACES,
} from '../../../../common/constants'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DISH_FIELDS } from '../../../constants'
import {
  SET_DISH_QA_DONE_MUTATION,
  SET_DISH_QQA_DONE_MUTATION,
  SET_DISH_VALIDATED_MUTATION,
} from '../../../graphql'
import { getDishAiSuggestionMessages } from '../../../helpers'
import { computeDishTableCellContent } from '../../../services'
import { dishFieldsUtils } from '../../../utils'
import { DishCheckbox, DishModalErrors, PublishCheckbox } from '../../molecules'

const checkAllColumn = (
  brandStatus,
  dishIds,
  dishIsStatusSameAsBrandFilter,
  dishHasErrorsFilter,
  dishesOnPageValidated,
  dishesOnPageValidatedWithQaDone,
  dishesOnPageValidatedWithQqaDone,
  userHasBrandChangePermission,
) => {
  if (brandStatus === CURATION_STATUS.QA.value) {
    return (
      <DishCheckbox
        ids={dishIds}
        checked={dishesOnPageValidatedWithQaDone}
        mutation={SET_DISH_QA_DONE_MUTATION}
        tooltip="Set QA status for all dishes on page"
        disabled={!userHasBrandChangePermission}
      />
    )
  }

  if (brandStatus === CURATION_STATUS.QQA_CONFIRMATION.value) {
    return (
      <DishCheckbox
        ids={dishIds}
        checked={dishesOnPageValidatedWithQqaDone}
        mutation={SET_DISH_QQA_DONE_MUTATION}
        tooltip="Set QQA status for all dishes on page"
        disabled={!userHasBrandChangePermission}
      />
    )
  }

  if (
    (brandStatus === CURATION_STATUS.CURATION_CONFIRMATION.value &&
      isStringTrue(dishIsStatusSameAsBrandFilter)) ||
    isStringTrue(dishHasErrorsFilter)
  ) {
    return (
      <DishCheckbox
        ids={dishIds}
        checked={dishesOnPageValidated}
        mutation={SET_DISH_VALIDATED_MUTATION}
        tooltip="Validate all dishes on page"
        disabled={!userHasBrandChangePermission}
      />
    )
  }

  return ''
}

const createDishTableColumns = (
  dishFields,
  brandStatus,
  shouldShowButtonBasedOnPermission,
  dishIsStatusSameAsBrandFilter,
  dishHasErrorsFilter,
  refetchBrand,
  dishTypeOptions,
  courseTypeOptions,
  menuTitleOptions,
  dishesHighlightWords,
  isDirty,
  isMenuTitlesDirty,
  dishIds,
  dishesOnPageValidated,
  dishesOnPageValidatedWithQaDone,
  dishesOnPageValidatedWithQqaDone,
  userHasBrandChangePermission,
  areMlIngredientsEnabled,
) => [
  ...(!includes(
    [
      CURATION_STATUS.MISC_AND_CHOICE.value,
      CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
    ],
    brandStatus,
  )
    ? [
        {
          key: 'validate/publish',
          title: checkAllColumn(
            brandStatus,
            dishIds,
            dishIsStatusSameAsBrandFilter,
            dishHasErrorsFilter,
            dishesOnPageValidated,
            dishesOnPageValidatedWithQaDone,
            dishesOnPageValidatedWithQqaDone,
            userHasBrandChangePermission,
          ),
          width:
            brandStatus === CURATION_STATUS.QQA_CONFIRMATION.value ? 100 : 50,
          fixed: 'left',
          shouldCellUpdate: (prev, next) => !isEqual(prev, next),
          render: (_, dish) => {
            const shouldCurateDish = dish.status !== CURATION_STATUS.DONE.value
            const aiMessages = get(dish, 'aiSuggestions.miscAndChoice.messages')

            let idColumnContent
            if (brandStatus === CURATION_STATUS.QA.value) {
              return (
                <DishCheckbox
                  ids={[dish.id]}
                  checked={dish.isQaDone}
                  mutation={SET_DISH_QA_DONE_MUTATION}
                  tooltip="Set QA status for dish"
                  disabled={!userHasBrandChangePermission || !dish.isEnabled}
                />
              )
            }

            if (brandStatus === CURATION_STATUS.QQA_CONFIRMATION.value) {
              return (
                <div>
                  <DishCheckbox
                    ids={[dish.id]}
                    checked={dish.isQqaDone}
                    mutation={SET_DISH_QQA_DONE_MUTATION}
                    tooltip="Set QQA status for dish"
                    disabled={!userHasBrandChangePermission || !dish.isEnabled}
                  />
                  <Tooltip title="Dish QA status">
                    <Checkbox
                      style={{ marginLeft: 6 }}
                      className="big-checkbox"
                      disabled
                      checked={dish.isQaDone}
                    />
                  </Tooltip>
                </div>
              )
            }

            if (
              shouldShowButtonBasedOnPermission &&
              ((isStringTrue(dishIsStatusSameAsBrandFilter) &&
                !isStringTrue(dishHasErrorsFilter)) ||
                brandStatus === CURATION_STATUS.DONE.value)
            ) {
              idColumnContent = (
                <PublishCheckbox
                  dish={dish}
                  big
                  unsaved={dish.unsaved}
                  checked={dish.published}
                  disabled={shouldCurateDish || !dish.isEnabled}
                  tooltip="Set dish published status"
                  refetchBrand={refetchBrand}
                  track={() =>
                    googleAnalyticsEventsService.fireEvent(
                      EVENT_TYPES.BRAND_DISH_EVENTS.PUBLISH_CLICK,
                      {
                        from: ORIGIN_PLACES.DISH_LIST,
                      },
                    )
                  }
                />
              )
            }

            if (
              (isStringTrue(dishIsStatusSameAsBrandFilter) ||
                isStringTrue(dishHasErrorsFilter)) &&
              brandStatus === CURATION_STATUS.CURATION_CONFIRMATION.value
            ) {
              idColumnContent = (
                <DishCheckbox
                  ids={[dish.id]}
                  checked={dish.isValidated}
                  mutation={SET_DISH_VALIDATED_MUTATION}
                  tooltip="Validate dish"
                  disabled={!userHasBrandChangePermission}
                />
              )
            }

            return {
              props: {
                className: dishFieldsUtils.computeDishCellHighlight(
                  dish,
                  'id',
                  brandStatus,
                ),
              },
              children: isEmpty(aiMessages) ? (
                idColumnContent
              ) : (
                <DishModalErrors errors={aiMessages} />
              ),
            }
          },
        },
      ]
    : []),
  ...map(
    filter(dishFields, ({ onlyInModal }) => !onlyInModal),
    field => ({
      key: field.id,
      dataIndex: field.id,
      title: field.title,
      fixed: !includes(
        [
          CURATION_STATUS.MISC_AND_CHOICE.value,
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
        ],
        brandStatus,
      )
        ? field.fixed
        : undefined,
      width: field.width,
      shouldCellUpdate: (prev, next) => !isEqual(prev, next),
      render: (_, dish, index) =>
        computeDishTableCellContent({
          dish,
          index,
          id: field.id,
          dishTypeOptions,
          courseTypeOptions,
          menuTitleOptions,
          dishesHighlightWords,
          brandStatus,
          isBrandDirty: isDirty || isMenuTitlesDirty,
          userHasBrandChangePermission,
          areMlIngredientsEnabled,
        }),
    }),
  ),
  {
    key: DISH_FIELDS.ID,
    title: 'Dish ID',
    width: '100px',
    shouldCellUpdate: (prev, next) => !isEqual(prev, next),
    render: (_, dish) => {
      const aiSuggestionMessages = getDishAiSuggestionMessages(dish)

      return {
        props: {
          className: dishFieldsUtils.computeDishCellHighlight(
            dish,
            'id',
            brandStatus,
          ),
        },
        children: isEmpty(aiSuggestionMessages) ? (
          <Link
            to={location => ({
              ...location,
              search: `${
                isEmpty(location.search) ? '' : `${location.search}&`
              }selectedDishId=${dish.id}`,
            })}
            component={TypographyLinkWrapper}
          >
            {dish.id}
          </Link>
        ) : (
          <Row gutter={[16]}>
            <Col span={6}>
              <DishModalErrors errors={aiSuggestionMessages} />
            </Col>
            <Col span={18}>
              <Link
                to={location => ({
                  ...location,
                  search: `${
                    isEmpty(location.search) ? '' : `${location.search}&`
                  }selectedDishId=${dish.id}`,
                })}
                component={TypographyLinkWrapper}
              >
                {dish.id}
              </Link>
            </Col>
          </Row>
        ),
      }
    },
  },
]

export default createDishTableColumns
