import { locationIdNew } from '../../constants'

const transformToSaveLocationArgs = ({ id, ...brandOrLocationData }) => {
  const isNew = id.includes(locationIdNew)
  const brandOrLocationInput = isNew
    ? brandOrLocationData
    : { id, ...brandOrLocationData }

  const { servicingHours, dietLabelLegends, workingHours } = brandOrLocationData

  if (servicingHours && servicingHours.length) {
    const servicingHoursToSave = servicingHours.map(
      ({ id: servicingHourId, created, ...rest }) =>
        created ? { ...rest } : { id: servicingHourId, ...rest },
    )

    brandOrLocationInput.servicingHours = servicingHoursToSave
  }

  if (dietLabelLegends && dietLabelLegends.length) {
    const dietLabelLegendsToSave = dietLabelLegends.map(
      ({ id: dietLabelLegendId, created, ...rest }) =>
        created ? { ...rest } : { id: dietLabelLegendId, ...rest },
    )

    brandOrLocationInput.dietLabelLegends = dietLabelLegendsToSave
  }

  if (workingHours) {
    brandOrLocationInput.workingHours = workingHours.map(
      ({ dayOfWeek, timeSlots, disabled }) => ({
        dayOfWeek,
        timeSlots,
        disabled,
      }),
    )
  }

  return brandOrLocationInput
}

export default transformToSaveLocationArgs
