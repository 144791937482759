import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ClearOutlined } from '@ant-design/icons'
import { Button, Col, Row, Tooltip } from 'antd'
import { EditorState } from 'draft-js'
import { get, isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../../../core/hooks'

import { DebouncedRichTextInput } from '../../../../common/components'
import {
  getContentStateFromText,
  getStyledTextFromContentState,
} from '../../../../common/helpers'
import {
  clearMiscAndChoice,
  containsMiscOrChoiceText,
} from '../../../../common/utils'

import { setMenuTitleDescription } from '../../../redux'

const styles = {
  descriptionContainer: {
    maxWidth: '700px',
  },
}

const MenuTitleDescriptionEditableCell = ({ dataIndex, record }) => {
  const dispatch = useDispatch()

  const persistedValue = useShallowEqualSelector(({ unsaved }) =>
    get(
      unsaved,
      `unsavedMenuTitles.menuTitles${
        !isEmpty(record.path) ? `.${record.path}` : ''
      }.${dataIndex}`,
    ),
  )

  const descriptionText = useMemo(
    () =>
      getStyledTextFromContentState(
        (persistedValue || get(record, dataIndex)).getCurrentContent(),
      ),
    [dataIndex, persistedValue, record],
  )

  const hasMiscAndChoice = useMemo(
    () => containsMiscOrChoiceText(descriptionText),
    [descriptionText],
  )

  const disabled = isNil(record.parentId)

  const save = useCallback(
    newState => {
      dispatch(
        setMenuTitleDescription({
          path: record.path,
          value: newState,
        }),
      )
    },
    [dispatch, record.path],
  )

  const handleClearMiscAndChoiceText = useCallback(() => {
    const clearedStyledValue = clearMiscAndChoice(descriptionText)
    save(
      EditorState.createWithContent(
        getContentStateFromText(clearedStyledValue),
      ),
    )
  }, [descriptionText, save])

  const placeholder =
    !disabled && isEmpty(persistedValue.getCurrentContent().getPlainText())
      ? 'Enter a description...'
      : undefined

  return (
    <Row gutter={16} align="middle">
      {hasMiscAndChoice && (
        <Tooltip title="Clear misc&choice text">
          <Button
            shape="circle"
            onClick={handleClearMiscAndChoiceText}
            icon={<ClearOutlined />}
          />
        </Tooltip>
      )}
      <Col span={22} style={styles.descriptionContainer}>
        <DebouncedRichTextInput
          defaultValue={record[dataIndex]}
          value={persistedValue}
          onValueChanged={save}
          disabled={disabled}
          placeholder={placeholder}
        />
      </Col>
    </Row>
  )
}

MenuTitleDescriptionEditableCell.propTypes = {
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
}

export default memo(MenuTitleDescriptionEditableCell)
