import { map, some } from 'lodash'
import shortid from 'shortid'

import { LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE } from '../../common/constants'

export const transformBrandToAiRequestDto = brandId => ({
  brandId,
})

export const transformAiSuggestionsToCuratorDto = (
  dietLabelLegends,
  dietLabelLegendSuggestions,
  allergenLabelLegends,
  allergenLabelLegendSuggestions,
) => ({
  dietLabelLegends: [
    ...map(dietLabelLegends, dietLabelLegend => ({
      ...dietLabelLegend,
      deleted:
        // If the label was previously added manually we deleted it and keep the ai suggested instance
        (dietLabelLegend.isSuggestedByAi &&
          !dietLabelLegend.conflictResolutionType ===
            LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE.UNKNOWN_WORDS) ||
        some(
          dietLabelLegendSuggestions,
          ({ label }) => label === dietLabelLegend.label,
        ),
    })),
    ...map(
      dietLabelLegendSuggestions,
      ({ occurrences, id: dietId, isSuggestedFromConflicting, ...rest }) => ({
        ...rest,
        conflictResolutionType: isSuggestedFromConflicting
          ? LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE.AI_CONFLICT
          : undefined,
        id: shortid.generate(),
        dietId: dietId.toString(),
        highlights: map(occurrences, ({ posIndex, ...highlight }, index) => ({
          matchType: index === 0 ? 'exact' : 'alreadyFound',
          ...highlight,
        })),
        isSuggestedByAi: true,
        created: true,
      }),
    ),
  ],

  allergenLabelLegends: [
    ...map(allergenLabelLegends, allergenLabelLegend => ({
      ...allergenLabelLegend,
      deleted:
        (allergenLabelLegend.isSuggestedByAi &&
          !allergenLabelLegend.conflictResolutionType ===
            LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE.UNKNOWN_WORDS) ||
        some(
          allergenLabelLegendSuggestions,
          ({ label }) => label === allergenLabelLegend.label,
        ),
    })),
    ...map(
      allergenLabelLegendSuggestions,
      ({
        occurrences,
        id: allergenId,
        isSuggestedFromConflicting,
        ...rest
      }) => ({
        ...rest,
        conflictResolutionType: isSuggestedFromConflicting
          ? LABEL_LEGEND_CONFLICT_RESOLUTION_TYPE.AI_CONFLICT
          : undefined,
        id: shortid.generate(),
        allergenId: allergenId.toString(),
        highlights: map(occurrences, ({ posIndex, ...highlight }, index) => ({
          matchType: index === 0 ? 'exact' : 'alreadyFound',
          ...highlight,
        })),
        isSuggestedByAi: true,
        created: true,
      }),
    ),
  ],
})
