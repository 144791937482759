import React, { useEffect, useMemo, useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import { includes, map } from 'lodash'
import PropTypes from 'prop-types'

import { CURATION_STATUS } from '../../../../common/constants'

import { DishPopoverTableFocusActionsProvider } from '../../../providers'
import { DishIngredientsPopoverTable } from '../../molecules'

import './DishIngredientsPopover.css'

const styles = {
  ingredientsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  popoverButton: {
    marginLeft: 6,
  },
  discrepancyCheckbox: {
    marginRight: 6,
  },
}

const DishIngredientsPopover = ({
  dishId,
  visible,
  onVisibleChange,
  ingredients,
  disabled,
  fieldName,
  status,
  areMlIngredientsEnabled,
}) => {
  const [isVisible, setIsVisible] = useState()

  const tableData = useMemo(
    () => [
      ...map(ingredients, ingredient => ({
        id: ingredient.id,
        menuText: ingredient.word,
        highlight: ingredient.highlight,
        name: ingredient.name,
        isSuggestedUsingMl: ingredient.isSuggestedUsingMl,
        isSuggestedUsingParser: ingredient.isSuggestedUsingParser,
        isDiscrepancyAccepted: ingredient.isDiscrepancyAccepted,
      })),
    ],
    [ingredients],
  )

  const isIngredientsDiscrepancyCheckboxVisible = useMemo(
    () =>
      areMlIngredientsEnabled &&
      includes(
        [
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.QA.value,
          CURATION_STATUS.QQA_CONFIRMATION.value,
        ],
        status,
      ),
    [status, areMlIngredientsEnabled],
  )

  const popoverContent = useMemo(() => {
    if (!isVisible) {
      return null
    }

    return (
      <DishPopoverTableFocusActionsProvider>
        <DishIngredientsPopoverTable
          data={tableData}
          fieldName={fieldName}
          dishId={dishId}
          isIngredientsDiscrepancyCheckboxVisible={
            isIngredientsDiscrepancyCheckboxVisible
          }
        />
      </DishPopoverTableFocusActionsProvider>
    )
  }, [
    dishId,
    fieldName,
    isIngredientsDiscrepancyCheckboxVisible,
    tableData,
    isVisible,
  ])

  useEffect(() => setIsVisible(visible), [visible])

  return (
    <div className="dish-table-ingredients-popover">
      <Popover
        visible={isVisible}
        content={popoverContent}
        overlayClassName="dishes-tables-ingredients-popover"
        getPopupContainer={() => document.body}
        placement="topLeft"
        /* hidden ant design prop which forces to popover/tooltip to open at placement */
        autoAdjustOverflow={false}
        onVisibleChange={onVisibleChange}
        trigger="click"
      >
        <Button
          style={styles.popoverButton}
          icon={<MenuOutlined />}
          shape="circle"
          disabled={disabled}
        />
      </Popover>
    </div>
  )
}

DishIngredientsPopover.propTypes = {
  dishId: PropTypes.string.isRequired,
  ingredients: PropTypes.arrayOf(PropTypes.object).isRequired,
  onVisibleChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  areMlIngredientsEnabled: PropTypes.bool,
}

DishIngredientsPopover.defaultProps = {
  visible: false,
  disabled: false,
  areMlIngredientsEnabled: false,
}

export default DishIngredientsPopover
