import { gql } from '@apollo/client'

const UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION = gql`
  mutation updateHumanCleanupCurationDone(
    $ids: [ID]!
    $taskId: ID!
    $metadataType: String!
    $value: Boolean!
  ) {
    updateHumanCleanupCurationDone(
      ids: $ids
      taskId: $taskId
      metadataType: $metadataType
      value: $value
    ) {
      ... on IngredientHumanCleanup {
        id
        isCurationDone
      }

      ... on DishTypeHumanCleanup {
        id
        isCurationDone
      }

      ... on CourseTypeHumanCleanup {
        id
        isCurationDone
      }

      ... on CuisineTypeHumanCleanup {
        id
        isCurationDone
      }

      ... on LocationTypeHumanCleanup {
        id
        isCurationDone
      }
    }
  }
`

export default UPDATE_HUMAN_CLEANUP_CURATION_DONE_MUTATION
