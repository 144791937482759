import React from 'react'
import { Spin } from 'antd'
import PropTypes from 'prop-types'

const styles = {
  loadingOverlay: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const DishTableEditableRowLoader = ({ height, loading }) => {
  if (!loading) {
    return null
  }

  return (
    <div style={{ ...styles.loadingOverlay, height }}>
      <Spin />
    </div>
  )
}

DishTableEditableRowLoader.propTypes = {
  loading: PropTypes.bool,
  height: PropTypes.number,
}
DishTableEditableRowLoader.defaultProps = {
  loading: false,
  height: 0,
}

export default DishTableEditableRowLoader
