import React from 'react'
import { Link } from 'react-router-dom'

import { MutationCheckbox } from '../../common/components/molecules'
import { METADATA_TYPES } from '../../common/constants'
import { getRouteOfBrand, getRouteOfDish } from '../../common/helpers'

import { DishMenuTitleDetailsCell } from '../../dishes/components/molecules'
import { formatDishPrice } from '../../dishes/helpers'
import { MlCleanupTaskSelect } from '../components/molecules'
import { ML_CLEANUP_OTHER_FIELDS } from '../constants'
import {
  UPDATE_ML_CLEANUP_CURATION_DONE_MUTATION,
  UPDATE_ML_CLEANUP_QA_DONE_MUTATION,
} from '../graphql'

const METADATA_CLEANUP_TITLES = {
  [METADATA_TYPES.DISH_TYPES]: 'Dish type',
  [METADATA_TYPES.COURSE_TYPES]: 'Course type',
}

const computeMlCleanupTaskTableColumns = ({
  allItemIds,
  areAllItemsCurationDone,
  areAllItemsQaDone,
  curationMetadata,
  disabled,
  hideIngredientsTextColumn,
  isTaskCurationDone,
  isTaskQaDone,
  metadataType,
  taskId,
}) => [
  {
    title: !isTaskCurationDone ? (
      <MutationCheckbox
        ids={allItemIds}
        checked={areAllItemsCurationDone}
        mutation={UPDATE_ML_CLEANUP_CURATION_DONE_MUTATION}
        mutationAdditionalInfo={{ metadataType, taskId }}
        tooltip="Set all curation status"
        disabled={disabled}
      />
    ) : (
      <MutationCheckbox
        ids={allItemIds}
        checked={areAllItemsQaDone}
        mutation={UPDATE_ML_CLEANUP_QA_DONE_MUTATION}
        mutationAdditionalInfo={{ metadataType, taskId }}
        tooltip="Set all qa status"
        disabled={disabled || isTaskQaDone}
      />
    ),
    width: '20px',
    render: ({ id, isCurationDone, isQaDone }) =>
      !isTaskCurationDone ? (
        <MutationCheckbox
          ids={[id]}
          checked={isCurationDone}
          mutation={UPDATE_ML_CLEANUP_CURATION_DONE_MUTATION}
          mutationAdditionalInfo={{ metadataType, taskId }}
          tooltip="Set curation status"
          disabled={disabled}
        />
      ) : (
        <MutationCheckbox
          ids={[id]}
          checked={isQaDone}
          mutation={UPDATE_ML_CLEANUP_QA_DONE_MUTATION}
          mutationAdditionalInfo={{ metadataType, taskId }}
          tooltip="Set QA status"
          disabled={disabled || isTaskQaDone}
        />
      ),
  },
  {
    title: 'Dish name',
    width: '80px',
    render: ({ brand, dish }) => {
      const route = getRouteOfDish(brand.id, dish.id)

      return (
        <Link
          target="_blank"
          to={route}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {dish.name}
        </Link>
      )
    },
  },
  {
    title: 'Dish description',
    width: '100px',
    render: ({ dish }) => dish.description,
  },
  ...(hideIngredientsTextColumn
    ? [
        {
          title: 'ingredients text',
          width: '100px',
          render: ({ dish }) => dish.ingredientsText,
        },
      ]
    : []),
  {
    title: 'Price',
    width: '50px',
    render: ({ dish }) => formatDishPrice(dish),
  },
  {
    title: 'Menu title',
    width: '75px',
    render: ({ dish }) => dish?.menuTitle?.name,
  },
  {
    title: 'Menu title description',
    width: '75px',
    render: ({ dish }) => (
      <DishMenuTitleDetailsCell menuTitles={dish.menuTitleParents} />
    ),
  },
  {
    title: METADATA_CLEANUP_TITLES[metadataType],
    width: '150px',
    render: cleanup => {
      let currentValue
      let suggestedValue
      let otherValue

      if (metadataType === METADATA_TYPES.DISH_TYPES) {
        currentValue = cleanup?.currentDishTypeId
        suggestedValue = cleanup?.suggestedDishTypeId
        otherValue = cleanup?.otherDishTypeId
      } else if (metadataType === METADATA_TYPES.COURSE_TYPES) {
        currentValue = cleanup?.currentCourseTypeId
        suggestedValue = cleanup?.suggestedCourseTypeId
        otherValue = cleanup?.otherCourseTypeId
      }

      return (
        <MlCleanupTaskSelect
          id={cleanup.id}
          metadataType={metadataType}
          treeData={curationMetadata[metadataType]}
          disabled={disabled}
          currentValue={currentValue}
          suggestedValue={suggestedValue}
          otherValue={otherValue}
          choice={cleanup.choice}
          otherFieldName={ML_CLEANUP_OTHER_FIELDS[metadataType]}
        />
      )
    },
  },
  {
    title: 'Brand name',
    width: '75px',
    render: ({ brand }) => {
      const route = getRouteOfBrand(brand.id)
      return (
        <Link target="_blank" to={route}>
          {brand.name}
        </Link>
      )
    },
  },
]

export default computeMlCleanupTaskTableColumns
