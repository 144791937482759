const TIME_ZONES = [
  '(GMT -12:00) Eniwetok, Kwajalein',
  '(GMT -11:00) Midway Island, Samoa',
  '(GMT -10:00) Hawaii',
  '(GMT -9:30) Taiohae',
  '(GMT -9:00) Alaska',
  '(GMT -8:00) Pacific Time (US &amp; Canada)',
  '(GMT -7:00) Mountain Time (US &amp; Canada)',
  '(GMT -6:00) Central Time (US &amp; Canada), Mexico City',
  '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima',
  '(GMT -4:30) Caracas',
  '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  '(GMT -3:30) Newfoundland',
  '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
  '(GMT -2:00) Mid-Atlantic',
  '(GMT -1:00) Azores, Cape Verde Islands',
  '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
  '(GMT +2:00) Kaliningrad, South Africa',
  '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  '(GMT +3:30) Tehran',
  '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
  '(GMT +4:30) Kabul',
  '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
  '(GMT +5:45) Kathmandu, Pokhara',
  '(GMT +6:00) Almaty, Dhaka, Colombo',
  '(GMT +6:30) Yangon, Mandalay',
  '(GMT +7:00) Bangkok, Hanoi, Jakarta',
  '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
  '(GMT +8:45) Eucla',
  '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  '(GMT +9:30) Adelaide, Darwin',
  '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  '(GMT +10:30) Lord Howe Island',
  '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  '(GMT +11:30) Norfolk Island',
  '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  '(GMT +12:45) Chatham Islands',
  '(GMT +13:00) Apia, Nukualofa',
  '(GMT +14:00) Line Islands, Tokelau',
]

export default TIME_ZONES
