import { join } from 'path'

import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { Button, notification } from 'antd'
import { isNil } from 'lodash'

import {
  BRAND_DUPLICATED_SUBSCRIPTION,
  BRAND_QUERY,
  SIMPLE_BRAND_BY_ID_QUERY,
} from '../../brands/graphql'
import { APP_ROUTES, JOB_STATUS } from '../constants'
import { handleMutationError } from '../helpers'

const useDuplicatedBrandSubscription = () => {
  const history = useHistory()
  const { brandId } = useParams()

  const { data } = useSubscription(BRAND_DUPLICATED_SUBSCRIPTION)

  const options = useMemo(
    () => ({
      variables: { id: brandId },
      onError: handleMutationError,
      fetchPolicy: 'network-only',
    }),
    [brandId],
  )

  const [fetchBrand] = useLazyQuery(BRAND_QUERY, options)
  const [fetchSimpleBrand] = useLazyQuery(SIMPLE_BRAND_BY_ID_QUERY, options)

  useEffect(() => {
    if (isNil(data?.brandDuplicated)) {
      return
    }

    if (data?.brandDuplicated?.jobStatus === JOB_STATUS.FAILED) {
      return notification.error({
        message: 'Brand Duplication Error',
        description: `Brand duplication failed for ${data.brandDuplicated?.brand?.name}.
              ${data.brandDuplicated.reason}`,
        placement: 'topLeft',
      })
    }

    if (
      brandId !== data.brandDuplicated?.brand?.id &&
      data?.brandDuplicated?.jobStatus === JOB_STATUS.SUCCESS
    ) {
      const notificationKey = `open${Date.now()}`
      const { brandDuplicated } = data

      notification.success({
        key: notificationKey,
        message: 'Duplicate brand Finished',
        description: `Brand ${brandDuplicated.brand?.name} successfully duplicated`,
        duration: 0,
        btn: (
          <Button
            onClick={() => {
              history.push(
                join(
                  APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
                  brandDuplicated?.brand?.id,
                ),
              )
              notification.close(notificationKey)
            }}
          >
            See the Duplicate
          </Button>
        ),
        placement: 'topLeft',
      })
    }

    if (brandId === data.brandDuplicated?.brand?.id) {
      return fetchBrand()
    }

    return fetchSimpleBrand({
      variables: {
        id: data.brandDuplicated.brand.id,
      },
    })
  }, [brandId, data, fetchSimpleBrand, history, fetchBrand])
}

export default useDuplicatedBrandSubscription
