const BRAND_TAG_OPTIONS = Object.freeze({
  IS_PROBLEMATIC: {
    value: 'IS_PROBLEMATIC',
    label: 'is problematic',
    excludedBy: ['IS_NOT_PROBLEMATIC'],
  },
  IS_CHAIN: {
    value: 'IS_CHAIN',
    label: 'is chain',
  },
  IS_TEST: {
    value: 'IS_TEST',
    label: 'is test',
    excludedBy: ['IS_NOT_TEST'],
  },
  IS_FAKE: {
    value: 'IS_FAKE',
    label: 'is fake',
    excludedBy: ['IS_NOT_FAKE'],
  },
  IS_TRAINING: {
    value: 'IS_TRAINING',
    label: 'is training',
    excludedBy: ['IS_NOT_TRAINING'],
  },
  EXCLUDE_FROM_REPORTS: {
    value: 'EXCLUDE_FROM_REPORTS',
    label: 'exclude from reports',
  },
})

export default BRAND_TAG_OPTIONS
