import React from 'react'
import GoogleLogin from 'react-google-login'
import { notification } from 'antd'
import PropTypes from 'prop-types'

const onFailure = () => {
  notification.error({
    message: 'Login with google failed.',
  })
}

const GoogleLoginButton = ({ onSuccess }) => {
  const clientId = window._env_.REACT_APP_GOOGLE_CLIENT_ID

  return (
    <GoogleLogin
      style={{ width: '100%' }}
      clientId={clientId}
      buttonText="Login with your Foodstyles account"
      onSuccess={onSuccess}
      onFailure={onFailure}
    />
  )
}

GoogleLoginButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default GoogleLoginButton
