import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import useUnsavedLocation from './useUnsavedLocation'

/**
 * Hook that exposes the unsaved field and also the posibility to change it.
 * It can be used to replace the persisted components
 * @param locationId - the id of the location
 * @param propName - the name of the field/property we are interested in
 * @param onValueChangedAction - the redux action used to perform the changes
 */

export default function usePersistedLocationField(
  locationId,
  propName,
  onValueChangedAction,
) {
  const dispatch = useDispatch()
  const { location: unsavedLocation } = useUnsavedLocation(locationId)

  const onValueChanged = useCallback(
    value => {
      if (onValueChangedAction) {
        dispatch(onValueChangedAction({ locationId, value }))
      }
    },
    [dispatch, locationId, onValueChangedAction],
  )
  const { [propName]: result } = unsavedLocation || {}
  return { [propName]: result, onValueChanged }
}
