import { gql } from '@apollo/client'

const LABEL_LEGEND_FRAGMENT = gql`
  fragment LabelLegendFragment on LabelLegend {
    id
    label
    highlights {
      dishId
      menuTitleId
      originField
      word
      matchType
      score
    }
    isSuggestedByAi
    conflictResolutionType
    matchType
    isChoice
  }
`

export default LABEL_LEGEND_FRAGMENT
