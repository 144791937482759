import React, { useCallback } from 'react'
import { Row, Typography } from 'antd'
import { filter, get, isEmpty, map, replace, values } from 'lodash'
import PropTypes from 'prop-types'

import { HighlightingTextField, ReadMore } from '../../../../common/components'
import { getHighlightListFromArrayForDescriptor } from '../../../../common/helpers'
import { formatDescriptors } from '../../../../common/utils'

import { DISH_MENU_TITLE_FIELD_TYPE } from '../../../constants'
import { DietDescriptorCollapse } from '../DietDescriptorCollapse'

const DishMenuTitleDetailsCell = ({
  menuTitles,
  highlights,
  fieldName,
  highlightPlaceholder,
  fieldType,
}) => {
  const renderedDetail = useCallback(
    menuTitle => {
      if (fieldType === DISH_MENU_TITLE_FIELD_TYPE.SIMPLE_DESCRIPTOR) {
        return (
          <HighlightingTextField
            key={`${menuTitle.menuTitleId}-simple-descriptor`}
            value={formatDescriptors(get(menuTitle, fieldName))}
            highlightingList={
              getHighlightListFromArrayForDescriptor(
                highlights,
                replace(highlightPlaceholder, '{id}', menuTitle.menuTitleId),
              ) || []
            }
            disabled
          />
        )
      }

      if (fieldType === DISH_MENU_TITLE_FIELD_TYPE.JSON_DESCRIPTOR) {
        return (
          <DietDescriptorCollapse
            key={`${menuTitle.menuTitleId}-json-descriptor`}
            value={menuTitle.menuTitleDietDescriptors}
            prefix="{id}:dietDescriptors"
            highlights={
              getHighlightListFromArrayForDescriptor(
                highlights,
                replace(highlightPlaceholder, '{id}', menuTitle.menuTitleId),
              ) || []
            }
          />
        )
      }

      return (
        <HighlightingTextField
          key={`${menuTitle.menuTitleId}-text`}
          value={
            menuTitle.menuTitleStyledDescription ||
            menuTitle.menuTitleDescription
          }
          highlightingList={
            get(
              highlights,
              replace(highlightPlaceholder, '{id}', menuTitle.menuTitleId),
            ) || []
          }
          disabled
        />
      )
    },
    [fieldName, fieldType, highlightPlaceholder, highlights],
  )

  return (
    <ReadMore
      maxHeight={170}
      component={
        <>
          {map(
            filter(
              menuTitles,
              menuTitle => !isEmpty(get(menuTitle, fieldName)),
            ),
            menuTitle => (
              <Row key={menuTitle.menuTitleId}>
                <Typography.Text strong>
                  {menuTitle.menuTitleName}:
                </Typography.Text>
                {renderedDetail(menuTitle)}
              </Row>
            ),
          )}
        </>
      }
    />
  )
}

DishMenuTitleDetailsCell.propTypes = {
  menuTitles: PropTypes.arrayOf(
    PropTypes.shape({
      menuTitleId: PropTypes.string.isRequired,
      menuTitleName: PropTypes.string.isRequired,
      menuTitleDescription: PropTypes.string,
      menuTitleStyledDescription: PropTypes.string,
      menuTitleAddonDescriptors: PropTypes.arrayOf(PropTypes.string),
      menuTitleAllergenDescriptors: PropTypes.arrayOf(PropTypes.string),
      menuTitleDietDescriptors: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  highlights: PropTypes.object,
  fieldName: PropTypes.string,
  highlightPlaceholder: PropTypes.string.isRequired,
  fieldType: PropTypes.oneOf(values(DISH_MENU_TITLE_FIELD_TYPE)),
}

DishMenuTitleDetailsCell.defaultProps = {
  highlights: {},
  fieldName: 'menuTitleDescription',
  fieldType: DISH_MENU_TITLE_FIELD_TYPE.TEXT,
}

export default DishMenuTitleDetailsCell
