import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Modal, Typography } from 'antd'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { Spinner } from '../../../../core/components'
import { useQueryParams } from '../../../../core/hooks'

import {
  CURATION_STATUS,
  EVENT_TYPES,
  idNew,
} from '../../../../common/constants'
import { LoadingContext } from '../../../../common/contexts'
import { removeUnsavedAiSuggestionsForOwner } from '../../../../common/redux/actions'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DISH_BY_ID_QUERY } from '../../../graphql'
import { getDishAiSuggestionMessages } from '../../../helpers'
import { useUnsavedBrandDish } from '../../../hooks'
import { removeUnsavedDish } from '../../../redux'
import { DishModalBody } from '../DishModalBody'
import { DishModalHeader } from '../DishModalHeader'

import './dishModal.css'

const styles = {
  modal: { overflowY: 'initial', top: '20px', marginLeft: '20px' },
  modalBody: { overflowY: 'auto', height: 'calc(100vh - 65px - 40px)' },
  closeIcon: {
    backgroundColor: '#FFF',
    borderRadius: '1rem',
    fontSize: '32px',
    position: 'absolute',
    right: '-1rem',
    top: '-1rem',
  },
}

const DishModal = ({ highlights, refetchBrand, disabled }) => {
  const dispatch = useDispatch()
  const { loadingCount } = useContext(LoadingContext)
  const { selectedDishId: dishId, setQueryParams } = useQueryParams()
  const unsavedDish = useUnsavedBrandDish(dishId)

  const [
    getDish,
    { data: { dish } = { dish: {} }, loading: isDishLoading, error: dishError },
  ] = useLazyQuery(DISH_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
  })

  const fetchDish = useCallback(
    () =>
      getDish({
        variables: {
          id: dishId,
        },
      }),
    [dishId, getDish],
  )

  const isDisabled = disabled || (!isEmpty(dish) && !dish.isEnabled)

  const onCancel = useCallback(() => {
    Modal.confirm({
      title: 'Do you want to discard all changes?',
      content:
        'If you close the modal all changes made to the dish will be lost',
      onOk() {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_DISH_EVENTS.MODAL_CLOSE,
          { closedWithChanges: true },
        )
        dispatch(removeUnsavedDish(dishId))
        dispatch(
          removeUnsavedAiSuggestionsForOwner({
            ownerId: dishId,
            type: 'dishes',
          }),
        )
        setQueryParams({ selectedDishId: null })
      },
    })
  }, [dishId, dispatch, setQueryParams])

  useEffect(() => {
    if (dishId !== idNew) {
      fetchDish()
    }
  }, [dishId, fetchDish])

  const aiSuggestionMessages = useMemo(
    () => getDishAiSuggestionMessages(dish),
    [dish],
  )

  if (loadingCount > 0 || isDishLoading) {
    return (
      <Modal
        closeIcon={<CloseCircleOutlined style={styles.closeIcon} />}
        width="calc(100vw - 40px)"
        style={styles.modal}
        visible
        title="Loading..."
        bodyStyle={styles.modalBody}
        footer={null}
      >
        <Spinner size="large" />
      </Modal>
    )
  }

  if (dishError) {
    return (
      <Modal
        closeIcon={<CloseCircleOutlined style={styles.closeIcon} />}
        width="50vw"
        style={styles.modal}
        title="Error"
        visible
        onCancel={() => setQueryParams({ selectedDishId: null })}
        onOk={() => setQueryParams({ selectedDishId: null })}
        bodyStyle={styles.modalBody}
      >
        <Typography.Paragraph>
          There was an error while opening this dish, please close the modal and
          try again.
        </Typography.Paragraph>
      </Modal>
    )
  }

  let wrapClassName = 'dish-modal '

  if (!isEmpty(aiSuggestionMessages)) {
    wrapClassName += 'dish-error-modal'
  } else if (dishId === idNew) {
    wrapClassName += 'dish-not-finished-modal'
  } else {
    wrapClassName +=
      dish?.status === CURATION_STATUS.DONE.value
        ? 'dish-not-finished-modal'
        : 'dish-finished-curating'
    wrapClassName =
      get(dish, 'courseType.isIgnored') || get(dish, 'dishType.isIgnored')
        ? ' dish-modal dish-ignored-modal'
        : wrapClassName
  }

  return (
    <Modal
      closeIcon={<CloseCircleOutlined style={styles.closeIcon} />}
      width="calc(100vw - 40px)"
      style={styles.modal}
      wrapClassName={wrapClassName}
      title={
        <DishModalHeader
          disabled={isDisabled}
          aiSuggestionMessages={aiSuggestionMessages}
          refetchDish={fetchDish}
          refetchBrand={refetchBrand}
        />
      }
      visible
      footer={null}
      onCancel={
        !isEmpty(unsavedDish)
          ? onCancel
          : () => {
              googleAnalyticsEventsService.fireEvent(
                EVENT_TYPES.BRAND_DISH_EVENTS.MODAL_CLOSE,
                {
                  closedWithChanges: false,
                },
              )
              dispatch(
                removeUnsavedAiSuggestionsForOwner({
                  ownerId: dishId,
                  type: 'dishes',
                }),
              )
              setQueryParams({ selectedDishId: null })
            }
      }
      bodyStyle={styles.modalBody}
    >
      <DishModalBody disabled={isDisabled} highlights={highlights} />
    </Modal>
  )
}

DishModal.propTypes = {
  highlights: PropTypes.object,
  refetchBrand: PropTypes.func,
  disabled: PropTypes.bool,
}

DishModal.defaultProps = {
  highlights: {},
  refetchBrand: undefined,
  disabled: false,
}

export default memo(DishModal)
