import { combineReducers } from 'redux'

import { unsavedDishReducer } from '../../../dishes/redux'
import {
  unsavedLocationDishesReducer,
  unsavedLocationReducer,
} from '../../../locations/redux'
import { unsavedMenuTitleReducer } from '../../../menuTitles/redux'

const unsavedReducer = combineReducers({
  locations: unsavedLocationReducer,
  dish: unsavedDishReducer,
  locationDishes: unsavedLocationDishesReducer,
  unsavedMenuTitles: unsavedMenuTitleReducer,
})

export default unsavedReducer
