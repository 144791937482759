import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'

import { useQueryParams } from '../../core/hooks'

import useUnsavedBrandDish from './useUnsavedBrandDish'

/**
 * Hook that exposes the unsaved field and also the posibility to change it.
 * It can be used to replace the persisted components
 * @param propName - the name of the field/property we are interested in
 * @param onValueChangedAction - the redux action used to perform the changes
 */
export default function usePersistedDishField(propName, onValueChangedAction) {
  const dispatch = useDispatch()
  const { selectedDishId } = useQueryParams()
  const unsavedDish = useUnsavedBrandDish(selectedDishId)

  const onValueChanged = useCallback(
    value => {
      if (onValueChangedAction) {
        dispatch(
          onValueChangedAction({
            value: typeof value === 'undefined' ? null : value,
            id: selectedDishId,
          }),
        )
      }
    },
    [dispatch, selectedDishId, onValueChangedAction],
  )

  return { [propName]: get(unsavedDish, propName), onValueChanged }
}
