import React, { memo, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import { Button } from 'antd'
import { get, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams, useShallowEqualSelector } from '../../../../core/hooks'
import { isStringTrue } from '../../../../core/utils'

import {
  AVAILABLE_HOTKEYS,
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import {
  useAppLoading,
  useAuthentication,
  useCachedBrand,
} from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DEFAULT_DISH_LIST_FILTERS } from '../../../../dishes/constants'
import { BRAND_DISHES_QUERY } from '../../../../dishes/graphql'
import { useUpsertBrand } from '../../../hooks'
import { suggestLabelLegends } from '../../../services'
import {
  brandLabelLegendSuggestionTransformer,
  brandTransformer,
} from '../../../transformers'

const LabelLegendSuggestButton = ({ disabled }) => {
  const { id: brandId, status } = useCachedBrand()
  const { startLoading, stopLoading, isLoading } = useAppLoading()
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    sortDishesBy,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
  } = useQueryParams()

  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? status : null),
    [dishIsStatusSameAsBrandFilter, status],
  )

  const upsertBrand = useUpsertBrand({
    brandId,
    refetchQueries: [
      {
        query: BRAND_DISHES_QUERY,
        variables: {
          brandId,
          paginationFiltersAndSorters: {
            pageSize: Number(pageSize),
            currentPage: Number(currentPage),
            filters: {
              status: statusForDishFiltering,
              servicingHours: servicingHoursFilter,
              published: dishPublishedFilter,
              ignored: dishIgnoredFilter,
              hasErrors: isStringTrue(dishHasErrorsFilter),
              menuTitle: menuTitleId,
              search,
            },
            sortDishesBy,
          },
        },
      },
    ],
  })

  const {
    state: { isDirty: isMenuTitlesDirty },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useFormContext()

  const handleOnSuggest = useCallback(
    async values => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_EVENTS.SUGGEST_LEGENDS_CLICK,
      )
      startLoading()
      const suggestions = await suggestLabelLegends({ brandId })

      if (!isNil(suggestions)) {
        try {
          const { dietLabelLegends, allergenLabelLegends } =
            brandLabelLegendSuggestionTransformer.transformAiSuggestionsToCuratorDto(
              values.dietLabelLegends,
              suggestions.dietLabelLegends,
              values.allergenLabelLegends,
              suggestions.allergenLabelLegends,
            )

          await upsertBrand({
            ...brandTransformer.transformToSaveBrandArgs(
              {
                ...values,
                id: brandId,
                dietLabelLegends,
                allergenLabelLegends,
              },
              suggestions,
            ),
            status:
              status === CURATION_STATUS.LABEL_LEGENDS.value
                ? CURATION_STATUS.LABEL_LEGENDS.value
                : null,
          })
        } catch (error) {
          handleMutationError(error)
        }
      }

      stopLoading()
    },
    [brandId, startLoading, status, stopLoading, upsertBrand],
  )

  useHotkeys(
    AVAILABLE_HOTKEYS.SHIFT_L.hotkey,
    handleSubmit(handleOnSuggest),
    {
      enabled: !disabled && !isDirty && !isMenuTitlesDirty,
    },
    [disabled, isDirty, isMenuTitlesDirty],
  )

  return (
    <Button
      loading={isSubmitting || isLoading}
      disabled={disabled || isDirty || isMenuTitlesDirty}
      onClick={handleSubmit(handleOnSuggest)}
    >
      Suggest Label Legends
    </Button>
  )
}

LabelLegendSuggestButton.propTypes = {
  disabled: PropTypes.bool,
}

LabelLegendSuggestButton.defaultProps = {
  disabled: false,
}

export default memo(LabelLegendSuggestButton)
