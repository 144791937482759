import { gql } from '@apollo/client'

const SET_DISH_INGREDIENT_DISCREPANCY_ACCEPTED_MUTATION = gql`
  mutation setDishIngredientDiscrepancyAccepted(
    $id: ID!
    $dishId: ID!
    $ingredientType: String!
    $value: Boolean!
  ) {
    setDishIngredientDiscrepancyAccepted(
      id: $id
      dishId: $dishId
      ingredientType: $ingredientType
      value: $value
    )
  }
`
export default SET_DISH_INGREDIENT_DISCREPANCY_ACCEPTED_MUTATION
