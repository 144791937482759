const USERS = Object.freeze({
  CREATE: 'users.create',
  UPDATE: 'users.update',
  DELETE: 'users.delete',
  GET: 'users.get',
  GET_LOGGED_IN: 'users.get_logged_in',
  LIST: 'users.list',
})

const BRANDS = Object.freeze({
  UPSERT: 'brands.upsert',
  SET_QA_DETAIL_LEVEL: 'brands.set_qa_detail_level',
  UPDATE_TAGS: 'brands.update_tags',
  DELETE: 'brands.delete',
  DUPLICATE: 'brands.duplicate',
  GET: 'brands.get',
  LIST: 'brands.list',
  DELETE_SUGGESTED_WORD: 'brands.delete_suggested_word',
  ASSIGN_USER: 'brands.assign_user',
  ASSIGN_USERS: 'brands.assign_users',
})

const DISHES = Object.freeze({
  UPSERT_DATA_ENTRY: 'dishes.upsert_data_entry',
  UPSERT_CURATION: 'dishes.upsert_curation',
  PUBLISH: 'dishes.publish',
  UNPUBLISH: 'dishes.unpublish',
  DELETE: 'dishes.delete',
  GET: 'dishes.get',
  UPDATE_QA_CURATION: 'dishes.update_qa_curation',
})

const LOCATIONS = Object.freeze({
  UPSERT: 'locations.upsert',
  DELETE: 'locations.delete',
  GET: 'locations.get',
})

const METADATA = Object.freeze({
  CREATE: 'metadata.create',
  RENAME: 'metadata.rename',
  UNLINK: 'metadata.unlink',
  UPDATE: 'metadata.update',
  DELETE: 'metadata.delete',
  GET: 'metadata.get',
  LIST: 'metadata.list',
})

const REPORTS = Object.freeze({
  CREATE: 'reports.create',
  LIST: 'reports.list',
})

const TASKS = Object.freeze({
  LIST: 'tasks.list',
  CREATE: 'tasks.create',
  DELETE: 'tasks.delete',
  ASSIGN_USER: 'tasks.assign_user',
})

const AI_SETTINGS = Object.freeze({
  LIST: 'aiSettings.list',
  UPDATE: 'aiSettings.update',
  GET: 'aiSettings.get',
})

const INGREDIENTS_RERUN = Object.freeze({
  LIST: 'ingredientsRerun.list',
  CREATE: 'ingredientsRerun.create',
})

const PERMISSIONS = {
  USERS,
  BRANDS,
  DISHES,
  LOCATIONS,
  METADATA,
  REPORTS,
  TASKS,
  AI_SETTINGS,
  INGREDIENTS_RERUN,
}

export default PERMISSIONS
