export default Object.freeze({
  modal: {
    overflowY: 'initial',
    top: '20px',
    marginLeft: '20px',
    width: 'calc(100vw - 40px)',
  },
  modalBody: { overflowY: 'auto', height: 'calc(100vh - 65px - 40px)' },
  closeIcon: {
    backgroundColor: '#FFF',
    borderRadius: '1rem',
    fontSize: '32px',
    position: 'absolute',
    right: '-1rem',
    top: '-1rem',
  },
})
