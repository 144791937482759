import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { DatePicker as AntDatePicker } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import { SpanPropType } from '../../../propTypes'
import { GridFormItem } from '../../atoms'

const DatePicker = ({
  name,
  defaultValue,
  format,
  style,
  allowClear,
  disabled,
  size,
  placeholder,
  showTime,
  showToday,
  label,
  labelAbove,
  smallLabel,
  convertFunction,
  disabledDate,
  span,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error: { message } = {} },
  } = useController({
    name,
    defaultValue,
  })

  const handleChange = useCallback(
    (date, dateString) => {
      if (!isNil(convertFunction)) {
        return onChange(convertFunction(dateString))
      }

      return onChange(date)
    },
    [onChange, convertFunction],
  )

  const valueToShow = useMemo(
    () => (isNil(value) ? value : moment(value)),
    [value],
  )

  return (
    <GridFormItem
      style={style}
      validateStatus={invalid ? 'error' : undefined}
      help={message}
      span={span}
      label={label}
      labelAbove={labelAbove}
      smallLabel={smallLabel}
    >
      <AntDatePicker
        style={style}
        allowClear={allowClear}
        disabled={disabled}
        size={size}
        format={format}
        showTime={showTime}
        showToday={showToday}
        value={valueToShow}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabledDate={disabledDate}
      />
    </GridFormItem>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  smallLabel: PropTypes.bool,
  labelAbove: PropTypes.bool,
  span: SpanPropType,
  showTime: PropTypes.object,
  showToday: PropTypes.bool,
  format: PropTypes.string,
  convertFunction: PropTypes.func,
  disabledDate: PropTypes.func,
}

DatePicker.defaultProps = {
  defaultValue: null,
  style: undefined,
  disabled: false,
  allowClear: true,
  size: 'default',
  placeholder: 'Select...',
  label: undefined,
  labelAbove: false,
  span: undefined,
  smallLabel: undefined,
  showTime: undefined,
  showToday: false,
  format: undefined,
  convertFunction: undefined,
  disabledDate: undefined,
}

export default DatePicker
