import React, { useCallback, useMemo } from 'react'
import { Button, Checkbox, Table, Tooltip } from 'antd'
import { find, forEach, get, isEmpty, isNil, map } from 'lodash'
import { PropTypes } from 'prop-types'

import { useQueryParams } from '../../../core/hooks'
import { areSameValue, formatDate } from '../../../core/utils'

import { DEFAULT_PAGINATION_CONFIG } from '../../../common/constants'
import { useAuthentication } from '../../../common/hooks'
import { findNodeByValue } from '../../../common/utils'

import { REPORT_PARAM_TYPES, REPORT_REQUEST_STATUS } from '../../constants'
import { isSelectDataTree } from '../../helpers'

const styles = {
  downloadLink: {
    padding: 0,
  },
  reportsTable: {
    marginTop: 30,
  },
  failedStatus: {
    color: 'red',
  },
}

const ReportsTable = ({
  loading,
  reports,
  reportConfig,
  handleDownload,
  curationMetadata,
  totalCount,
}) => {
  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()
  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    setQueryParams,
  } = useQueryParams()

  const tableColumns = useMemo(() => {
    if (!reportConfig) {
      return []
    }

    const dynamicColumns = map(reportConfig.parameters, param => ({
      key: param.name,
      title: param.displayName,
      render: (_, { params }) => {
        const value =
          param.type === REPORT_PARAM_TYPES.BOOLEAN
            ? params[param.name] || false
            : params[param.name]

        if (isNil(value)) {
          return ''
        }

        if (param.type === REPORT_PARAM_TYPES.DATE) {
          return formatDate(value)
        }

        if (param.type === REPORT_PARAM_TYPES.CASCADER_SELECT) {
          const paramOptions = get(curationMetadata, param.valueType) || []
          let values = ''
          forEach(value, (id, index) => {
            const node = findNodeByValue(paramOptions, id)
            values += `${node?.label || id}${
              index < value.length - 1 ? ' -> ' : ''
            }`
          })

          return values
        }

        if (
          param.type === REPORT_PARAM_TYPES.SINGLE_SELECT ||
          param.type === REPORT_PARAM_TYPES.MULTI_SELECT
        ) {
          let paramOptions
          if (param.valueType) {
            paramOptions = get(curationMetadata, param.valueType) || []
          } else if (!isEmpty(param.values)) {
            paramOptions = param.values
          } else {
            paramOptions = []
          }

          const valueToDisplay = map(
            param.type === REPORT_PARAM_TYPES.SINGLE_SELECT ? [value] : value,
            id => {
              if (!isSelectDataTree(paramOptions)) {
                return (
                  (
                    find(paramOptions, option =>
                      areSameValue(option.value, id),
                    ) || {}
                  ).title || ''
                )
              }
              return (findNodeByValue(paramOptions, id) || {}).title || ''
            },
          )

          return valueToDisplay.join(', ')
        }

        if (param.type === REPORT_PARAM_TYPES.BOOLEAN) {
          return <Checkbox checked={value} disabled />
        }

        return value
      },
    }))

    return [
      ...dynamicColumns,
      {
        key: 'user',
        title: 'User',
        render: (_, { user }) => {
          if (!user) {
            return ''
          }

          return user.name || user.email
        },
      },
      {
        key: 'createdAt',
        title: 'Created at',
        render: (_, { createdAt }) => formatDate(createdAt),
      },
      {
        key: 'status',
        title: 'Status',
        render: (_, { id, status, errorMessage }) => {
          if (status === REPORT_REQUEST_STATUS.Complete) {
            return (
              <Button
                style={styles.downloadLink}
                type="link"
                onClick={() => handleDownload(id)}
              >
                Download
              </Button>
            )
          }

          if (status === REPORT_REQUEST_STATUS.Failed) {
            return (
              <Tooltip title={errorMessage} placement="topRight">
                <span style={styles.failedStatus}>failed</span>
              </Tooltip>
            )
          }

          return status
        },
      },
    ]
  }, [reportConfig, handleDownload, curationMetadata])

  const handlePaginationChange = useCallback(
    newPage => {
      setQueryParams({ page: newPage })
    },
    [setQueryParams],
  )

  const handlePageSizeChange = useCallback(
    (_, size) => {
      setQueryParams({ page: DEFAULT_PAGINATION_CONFIG.PAGE, pageSize: size })
    },
    [setQueryParams],
  )

  const paginationConfig = useMemo(
    () => ({
      current: page,
      pageSize,
      total: totalCount,
      onChange: handlePaginationChange,
      pageSizeOptions: DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS,
      showSizeChanger: true,
      onShowSizeChange: handlePageSizeChange,
    }),
    [handlePageSizeChange, handlePaginationChange, page, pageSize, totalCount],
  )

  return (
    <Table
      loading={loading}
      style={styles.reportsTable}
      dataSource={loading ? [] : reports}
      columns={tableColumns}
      size="small"
      rowKey="id"
      pagination={paginationConfig}
    />
  )
}

ReportsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  reports: PropTypes.arrayOf(PropTypes.object),
  reportConfig: PropTypes.object,
  handleDownload: PropTypes.func.isRequired,
  curationMetadata: PropTypes.object,
  totalCount: PropTypes.number,
}

ReportsTable.defaultProps = {
  totalCount: undefined,
  reports: [],
  reportConfig: undefined,
  curationMetadata: {},
}

export default ReportsTable
