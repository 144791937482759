import { gql } from '@apollo/client'

import {
  COURSE_TYPE_HUMAN_CLEANUP_FRAGMENT,
  CUISINE_TYPE_HUMAN_CLEANUP_FRAGMENT,
  DISH_TYPE_HUMAN_CLEANUP_FRAGMENT,
  INGREDIENT_HUMAN_CLEANUP_FRAGMENT,
  LOCATION_TYPE_HUMAN_CLEANUP_FRAGMENT,
} from '../fragments'

const HUMAN_CLEANUPS_QUERY = gql`
  query humanCleanups(
    $taskId: ID!
    $currentPage: Int!
    $pageSize: Int!
    $metadataType: String!
    $filters: HumanCleanupsFiltersInput!
  ) {
    humanCleanups(
      taskId: $taskId
      currentPage: $currentPage
      pageSize: $pageSize
      metadataType: $metadataType
      filters: $filters
    ) {
      count
      cleanups {
        ... on IngredientHumanCleanup {
          ...IngredientHumanCleanupFragment
        }
        ... on DishTypeHumanCleanup {
          ...DishTypeHumanCleanupFragment
        }

        ... on CourseTypeHumanCleanup {
          ...CourseTypeHumanCleanupFragment
        }

        ... on CuisineTypeHumanCleanup {
          ...CuisineTypeHumanCleanupFragment
        }

        ... on LocationTypeHumanCleanup {
          ...LocationTypeHumanCleanupFragment
        }
      }
    }
  }
  ${INGREDIENT_HUMAN_CLEANUP_FRAGMENT}
  ${DISH_TYPE_HUMAN_CLEANUP_FRAGMENT}
  ${COURSE_TYPE_HUMAN_CLEANUP_FRAGMENT}
  ${CUISINE_TYPE_HUMAN_CLEANUP_FRAGMENT}
  ${LOCATION_TYPE_HUMAN_CLEANUP_FRAGMENT}
`

export default HUMAN_CLEANUPS_QUERY
