import React from 'react'
import { Space } from 'antd'
import { PropTypes } from 'prop-types'

import { BrandCorrectionNumber } from '../../atoms'

const BrandCorrectionsCount = ({ corrections }) => {
  const {
    styledName,
    styledDescription,
    styledIngredientsText,
    dishTypeId,
    courseTypeId,
    mainIngredientIds,
    additionalIngredientIds,
    choiceIngredientIds,
    cuisineTypeId,
    locationTypeId,
    languageIds,
    allergenLabelLegend,
    dietLabelLegend,
    menuTitles,
    dietIds,
    allergenIds,
    value,
    type,
    ingredientMatchType,
    languageId,
    allergenId,
    dietId,
    ingredientId,
  } = corrections || {}

  return (
    <>
      <Space>
        <BrandCorrectionNumber
          value={styledName}
          tooltip="Dish name corrections"
        />
        <BrandCorrectionNumber
          value={styledDescription}
          tooltip="Dish description corrections"
        />
        <BrandCorrectionNumber
          value={styledIngredientsText}
          tooltip="Dish ingredients text corrections"
        />
        <BrandCorrectionNumber
          value={cuisineTypeId}
          tooltip="Brand cuisine type corrections"
        />
        <BrandCorrectionNumber
          value={locationTypeId}
          tooltip="Brand location type corrections"
        />
        <BrandCorrectionNumber
          value={languageIds}
          tooltip="Brand language corrections"
        />
        <BrandCorrectionNumber
          value={dietLabelLegend}
          tooltip="Brand diet label legend corrections"
        />
        <BrandCorrectionNumber
          value={allergenLabelLegend}
          tooltip="Brand allergen label legend corrections"
        />
        <BrandCorrectionNumber
          value={menuTitles}
          tooltip="Brand menu title description corrections"
        />
      </Space>
      <Space>
        <BrandCorrectionNumber
          value={dietIds}
          tooltip="Dish diets corrections"
        />
        <BrandCorrectionNumber
          value={allergenIds}
          tooltip="Dish allergen corrections"
        />
        <BrandCorrectionNumber
          value={courseTypeId}
          tooltip="Dish course type corrections"
        />
        <BrandCorrectionNumber
          value={dishTypeId}
          tooltip="Dish type corrections"
        />
        <BrandCorrectionNumber
          value={mainIngredientIds}
          tooltip="Dish main ingredients corrections"
        />
        <BrandCorrectionNumber
          value={additionalIngredientIds}
          tooltip="Dish additional ingredients corrections"
        />
        <BrandCorrectionNumber
          value={choiceIngredientIds}
          tooltip="Dish choice ingredients corrections"
        />
      </Space>
      <Space>
        <BrandCorrectionNumber
          value={value}
          tooltip="Suggested word value corrections"
        />
        <BrandCorrectionNumber
          value={type}
          tooltip="Suggested word type corrections"
        />
        <BrandCorrectionNumber
          value={ingredientMatchType}
          tooltip="Suggested word ingredient match type corrections"
        />
        <BrandCorrectionNumber
          value={languageId}
          tooltip="Suggested word language corrections"
        />
        <BrandCorrectionNumber
          value={allergenId}
          tooltip="Suggested word allergen corrections"
        />
        <BrandCorrectionNumber
          value={dietId}
          tooltip="Suggested word diet corrections"
        />
        <BrandCorrectionNumber
          value={ingredientId}
          tooltip="Suggested word ingredient corrections"
        />
      </Space>
    </>
  )
}

BrandCorrectionsCount.propTypes = {
  corrections: PropTypes.shape({
    styledName: PropTypes.number,
    styledDescription: PropTypes.number,
    styledIngredientsText: PropTypes.number,
    dishTypeId: PropTypes.number,
    courseTypeId: PropTypes.number,
    dietIds: PropTypes.number,
    allergenIds: PropTypes.number,
    cuisineTypeId: PropTypes.number,
    locationTypeId: PropTypes.number,
    languageIds: PropTypes.number,
    allergenLabelLegend: PropTypes.number,
    dietLabelLegend: PropTypes.number,
    menuTitles: PropTypes.number,
  }),
}

BrandCorrectionsCount.defaultProps = {
  corrections: undefined,
}

export default BrandCorrectionsCount
