import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Col, Typography } from 'antd'
import PropTypes from 'prop-types'

import { ServicingHours } from '../../../../common/components'
import { servicingHourPropType } from '../../../../common/propTypes'

const BrandServicingHours = ({ servicingHours, isDisabled }) => {
  const { control } = useFormContext()

  return (
    <Col span={11}>
      <Controller
        control={control}
        defaultValue={servicingHours}
        name="servicingHours"
        render={({ field: { onChange, value } }) => (
          <>
            <Typography.Paragraph>Serving Hours</Typography.Paragraph>
            <ServicingHours
              newServicingHours={value}
              onValueChanged={onChange}
              disabled={isDisabled}
            />
          </>
        )}
      />
    </Col>
  )
}

BrandServicingHours.propTypes = {
  servicingHours: PropTypes.arrayOf(servicingHourPropType),
  isDisabled: PropTypes.bool,
}

BrandServicingHours.defaultProps = {
  servicingHours: [],
  isDisabled: false,
}

export default BrandServicingHours
