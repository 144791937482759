import { shallowEqual, useSelector } from 'react-redux'

/*
 * The useSelector from react-redux instead of doing a shallow equal when returning objects
 * does a "===" which would always result in a re-rendering
 * to solve this a wrapper over the normal useSelector is used
 * @param selector - the selector function used to get data from the state
 * @returns - the values from the selector
 */
export default function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual)
}
