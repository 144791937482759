const suggestionEndpoints = Object.freeze({
  variousText: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/dish/variousText/predict`,
  ingredients: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/dish/ingredients/predict`,
  dishTypes: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/dish/dishType/predict`,
  courseTypes: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/dish/courseType/predict`,
  labels: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/dish/labels/predict`,
  labelLegends: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/brand/labelLegends/predict`,
  cuisineType: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/brand/cuisineType/predict`,
  translateDish: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/dish/translation/predict`,
  languages: `${window._env_.REACT_APP_AI_SERVICE_BASE_ADDRESS}/brand/language/predict`,
})

export default suggestionEndpoints
