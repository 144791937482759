import React from 'react'
import { Col, Tooltip, Typography } from 'antd'
import { PropTypes } from 'prop-types'

const styles = {
  noCorrectionsCount: {
    color: '#49c634',
  },
  correctionsCount: {
    color: '#e21b1b',
  },
}

const BrandCorrectionNumber = ({ value, tooltip }) => (
  <Col>
    <Tooltip title={tooltip}>
      <Typography.Text
        style={value ? styles.correctionsCount : styles.noCorrectionsCount}
      >
        {value}
      </Typography.Text>
    </Tooltip>
  </Col>
)

BrandCorrectionNumber.propTypes = {
  value: PropTypes.number.isRequired,
  tooltip: PropTypes.string.isRequired,
}

export default BrandCorrectionNumber
