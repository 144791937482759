import { useContext, useMemo } from 'react'
import { omit, some, values } from 'lodash'

import { CURATION_STATUS, ROLES } from '../constants'
import { AuthContext } from '../contexts'

import useCachedBrand from './useCachedBrand'
import useUserHasRoles from './useUserHasRoles'

const useUserHasBrandChangePermission = brandToCheck => {
  const {
    userInfo: { id: currentUserId },
  } = useContext(AuthContext)

  const userCanEditAnyBrand = useUserHasRoles([
    ROLES.TEAM_LEAD.value,
    ROLES.LEAD_TRAINER.value,
    ROLES.ADMIN.value,
  ])

  const brand = useCachedBrand() || brandToCheck

  const {
    assignedCurator,
    assignedQa,
    assignedQQa,
    assignedUnknownWordsCurator,
    assignedUnknownWordsQa,
    status: brandStatus,
    requiresCuratorAttention,
  } = brand || {}

  const isUserCuratorOfBrand = useMemo(
    () => assignedCurator?.id === currentUserId,
    [assignedCurator?.id, currentUserId],
  )

  const isUserQaOfBrand = useMemo(
    () => assignedQa?.id === currentUserId,
    [assignedQa?.id, currentUserId],
  )

  const isUserUnknownWordsCuratorOfBrand = useMemo(
    () => assignedUnknownWordsCurator?.id === currentUserId,
    [assignedUnknownWordsCurator?.id, currentUserId],
  )

  const isUserUnknownWordsQaOfBrand = useMemo(
    () => assignedUnknownWordsQa?.id === currentUserId,
    [assignedUnknownWordsQa?.id, currentUserId],
  )

  const isUserQQaOfBrand = useMemo(
    () => assignedQQa?.id === currentUserId,
    [assignedQQa?.id, currentUserId],
  )

  if (userCanEditAnyBrand) {
    return {
      userHasBrandChangePermission: true,
      userHasAdminPermissions: true,
      isUserCuratorOfBrand,
      isUserQaOfBrand,
      isUserQQaOfBrand,
      isUserUnknownWordsCuratorOfBrand,
      isUserUnknownWordsQaOfBrand,
    }
  }

  if (
    isUserUnknownWordsCuratorOfBrand &&
    brandStatus === CURATION_STATUS.UNKNOWN_WORDS_CURATION.value
  ) {
    return {
      userHasBrandChangePermission: true,
      isUserCuratorOfBrand,
      isUserQaOfBrand,
      isUserQQaOfBrand,
      isUserUnknownWordsCuratorOfBrand,
      isUserUnknownWordsQaOfBrand,
    }
  }

  if (
    isUserUnknownWordsQaOfBrand &&
    brandStatus === CURATION_STATUS.UNKNOWN_WORDS_QA.value
  ) {
    return {
      userHasBrandChangePermission: true,
      isUserCuratorOfBrand,
      isUserQaOfBrand,
      isUserQQaOfBrand,
      isUserUnknownWordsCuratorOfBrand,
      isUserUnknownWordsQaOfBrand,
    }
  }

  const curationStatusesAllowingCuratorModifcations = values(
    omit(CURATION_STATUS, [
      CURATION_STATUS.DONE.value,
      CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
      CURATION_STATUS.UNKNOWN_WORDS_QA.value,
      CURATION_STATUS.QA.value,
      CURATION_STATUS.QQA_CONFIRMATION.value,
    ]),
  )

  if (
    (isUserCuratorOfBrand &&
      some(
        curationStatusesAllowingCuratorModifcations,
        ({ value }) => value === brandStatus,
      )) ||
    requiresCuratorAttention
  ) {
    return {
      userHasBrandChangePermission: true,
      isUserCuratorOfBrand,
      isUserQaOfBrand,
      isUserQQaOfBrand,
      isUserUnknownWordsCuratorOfBrand,
      isUserUnknownWordsQaOfBrand,
    }
  }

  if (isUserQaOfBrand && brandStatus === CURATION_STATUS.QA.value) {
    return {
      userHasBrandChangePermission: true,
      isUserCuratorOfBrand,
      isUserQaOfBrand,
      isUserQQaOfBrand,
      isUserUnknownWordsCuratorOfBrand,
      isUserUnknownWordsQaOfBrand,
    }
  }

  if (
    isUserQQaOfBrand &&
    brand.status === CURATION_STATUS.QQA_CONFIRMATION.value
  ) {
    return {
      userHasBrandChangePermission: true,
      isUserCuratorOfBrand,
      isUserQaOfBrand,
      isUserQQaOfBrand,
      isUserUnknownWordsCuratorOfBrand,
      isUserUnknownWordsQaOfBrand,
    }
  }

  return {
    userHasBrandChangePermission: false,
    isUserCuratorOfBrand,
    isUserQaOfBrand,
    isUserQQaOfBrand,
    isUserUnknownWordsCuratorOfBrand,
    isUserUnknownWordsQaOfBrand,
  }
}

export default useUserHasBrandChangePermission
