import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { EVENT_TYPES } from '../constants'
import { googleAnalyticsEventsService } from '../services'

const usePageView = () => {
  const location = useLocation()

  useEffect(() => {
    googleAnalyticsEventsService.fireEvent(EVENT_TYPES.BASE_EVENTS.PAGE_VIEW, {
      location: location.pathname,
    })
  }, [location])
}

export default usePageView
