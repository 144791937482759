import { join } from 'path'

import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { Button, notification } from 'antd'
import { isNil } from 'lodash'

import {
  BRAND_QUERY,
  BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
  SIMPLE_BRAND_BY_ID_QUERY,
  SUGGESTED_WORDS_SUBSCRIPTION,
} from '../../brands/graphql'
import { APP_ROUTES, JOB_STATUS } from '../constants'
import { handleMutationError } from '../helpers'

const useSuggestedWordsSubscription = () => {
  const history = useHistory()
  const { brandId } = useParams()
  const { data } = useSubscription(SUGGESTED_WORDS_SUBSCRIPTION)

  const options = useMemo(
    () => ({
      variables: { id: brandId },
      onError: handleMutationError,
      fetchPolicy: 'network-only',
    }),
    [brandId],
  )

  const [fetchBrand] = useLazyQuery(BRAND_QUERY, options)
  const [fetchSimpleBrand] = useLazyQuery(SIMPLE_BRAND_BY_ID_QUERY, options)

  const [fetchBrandSuggestedWordsCompletenessCount] = useLazyQuery(
    BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
    {
      variables: { brandId },
      onError: handleMutationError,
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    if (isNil(data?.suggestedUnknownWords)) {
      return
    }

    if (isNil(data?.suggestedUnknownWords?.brandId)) {
      notification.error({
        message: 'Suggesting Unknown Words Error',
        description: `No brandId provided`,
        placement: 'topLeft',
      })
    }

    if (data?.suggestedUnknownWords?.jobStatus === JOB_STATUS.FAILED) {
      notification.error({
        message: 'Suggesting Unknown Words Error',
        description: `Failed to suggest unknown words on ${
          data?.suggestedUnknownWords?.brandName ||
          data?.suggestedUnknownWords?.brandId
        }.
            ${data?.suggestedUnknownWords?.reason}`,
        placement: 'topLeft',
      })
    }

    if (
      brandId !== data?.suggestedUnknownWords?.brandId &&
      data?.suggestedUnknownWords?.jobStatus === JOB_STATUS.SUCCESS
    ) {
      const { suggestedUnknownWords } = data
      const notificationKey = `open${Date.now()}`

      notification.success({
        key: notificationKey,
        message: 'Suggesting Unknown Words Finished',
        description: `Successfully suggested unknown words on brand ${suggestedUnknownWords.brandName}`,
        btn: (
          <Button
            onClick={() => {
              history.push(
                join(
                  APP_ROUTES.BRAND_ROUTES.BrandsPrefix,
                  suggestedUnknownWords.brandId,
                ),
              )
              notification.close(notificationKey)
            }}
          >
            See the brand
          </Button>
        ),
        placement: 'topLeft',
      })
    }

    if (!isNil(brandId)) {
      fetchBrand()
      return fetchBrandSuggestedWordsCompletenessCount()
    }

    return fetchSimpleBrand({
      variables: {
        id: data.suggestedUnknownWords.brandId,
      },
    })
  }, [
    brandId,
    history,
    fetchBrand,
    data,
    fetchBrandSuggestedWordsCompletenessCount,
    fetchSimpleBrand,
  ])
}

export default useSuggestedWordsSubscription
