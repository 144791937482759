import React, { useCallback, useMemo, useState } from 'react'
import ReactJson from 'react-json-view'
import PropTypes from 'prop-types'

const styles = {
  jsonWraper: {
    position: 'relative',
  },
  jsonClickCover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    cursor: 'pointer',
  },
}

const JsonViewer = ({ value }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const parsedValue = useMemo(() => JSON.parse(value), [value])

  // we do this to create a wrapper covering the json viewer
  // this way it will take over the collapse functionality
  // this is required when the json viewer is placed in a table
  // and we want the click action to not open the dish modal
  const handleCollapse = useCallback(
    e => {
      e.stopPropagation()
      setIsCollapsed(!isCollapsed)
    },
    [isCollapsed, setIsCollapsed],
  )

  return (
    <div style={styles.jsonWraper}>
      <ReactJson
        src={parsedValue}
        name={false}
        collapsed={isCollapsed}
        enableClipboard={false}
        displayDataTypes={false}
      />
      <div
        style={styles.jsonClickCover}
        onClick={handleCollapse}
        role="presentation"
      />
    </div>
  )
}

JsonViewer.propTypes = {
  value: PropTypes.string,
}

JsonViewer.defaultProps = {
  value: '[]',
}

export default JsonViewer
