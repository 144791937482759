import React, { useCallback, useMemo } from 'react'
import { Table } from 'antd'
import { includes, isEmpty, isNil, map, some } from 'lodash'
import PropTypes from 'prop-types'

import { useWindowSize } from '../../../../core/hooks'

import { METADATA_TYPES } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import {
  CLEANUP_TABLE_SCROLL_VALUES,
  CLEANUP_TASK_TYPES,
} from '../../../constants'
import { cleanupTasksMetadataTypePropType } from '../../../propTypes'
import { computeCleanupTaskTableColumns } from '../../../services'

const CleanupTaskTable = ({
  data,
  loading,
  metadataType,
  taskId,
  selectedItems,
  onItemSelect,
  onMultiItemsSelect,
  disabled,
  isTaskCurationDone,
  isTaskQaDone,
  taskType,
}) => {
  const {
    ingredientOptions,
    dishTypeOptions,
    courseTypeOptions,
    locationTypeOptions,
    cuisineTypeOptions,
  } = useCurationMetadata()

  const { height } = useWindowSize()
  const allItemIds = useMemo(() => map(data, 'id'), [data])

  const areAllItemsCurationDone = useMemo(
    () => !some(data, ({ isCurationDone }) => !isCurationDone),
    [data],
  )
  const areAllItemsQaDone = useMemo(
    () => !some(data, ({ isQaDone }) => !isQaDone),
    [data],
  )

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.INGREDIENTS]: ingredientOptions,
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.LOCATION_TYPES]: locationTypeOptions,
      [METADATA_TYPES.CUISINE_TYPES]: cuisineTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
    }),
    [
      courseTypeOptions,
      cuisineTypeOptions,
      dishTypeOptions,
      ingredientOptions,
      locationTypeOptions,
    ],
  )

  const scrollConfig = useMemo(
    () => ({
      y: isNil(height)
        ? CLEANUP_TABLE_SCROLL_VALUES.HEIGHT
        : height - CLEANUP_TABLE_SCROLL_VALUES.HEIGHT,
    }),
    [height],
  )

  const isDishMetadata = useMemo(
    () =>
      includes(
        [
          METADATA_TYPES.INGREDIENTS,
          METADATA_TYPES.DISH_TYPES,
          METADATA_TYPES.COURSE_TYPES,
        ],
        metadataType,
      ),
    [metadataType],
  )

  const handleSelectAll = useCallback(
    checked => {
      if (checked) {
        onMultiItemsSelect(allItemIds)
      } else {
        onMultiItemsSelect([])
      }
    },
    [allItemIds, onMultiItemsSelect],
  )

  const isMenuTitleDescriptorsShown = useMemo(() => {
    const isMenuTitleDescriptorsExisting =
      isDishMetadata &&
      some(data, ({ dish }) => !isEmpty(dish.menuTitle?.addonDescriptors))

    return (
      isMenuTitleDescriptorsExisting &&
      (metadataType === METADATA_TYPES.INGREDIENTS ||
        taskType === CLEANUP_TASK_TYPES.REVIEW)
    )
  }, [data, isDishMetadata, metadataType, taskType])

  const isDishDescriptorsShown = useMemo(() => {
    const isDishDescriptorsExisting =
      isDishMetadata &&
      some(data, ({ dish }) => !isEmpty(dish.addonDescriptors))

    return (
      isDishDescriptorsExisting &&
      (metadataType === METADATA_TYPES.INGREDIENTS ||
        taskType === CLEANUP_TASK_TYPES.REVIEW)
    )
  }, [data, isDishMetadata, metadataType, taskType])

  const tableColumns = useMemo(
    () =>
      computeCleanupTaskTableColumns({
        allItemIds,
        areAllItemsCurationDone,
        areAllItemsQaDone,
        curationMetadata,
        disabled,
        handleSelectAll,
        isDishMetadata,
        isTaskCurationDone,
        isTaskQaDone,
        metadataType,
        onItemSelect,
        selectedItems,
        taskId,
        isMenuTitleDescriptorsShown,
        isDishDescriptorsShown,
      }),
    [
      allItemIds,
      areAllItemsCurationDone,
      areAllItemsQaDone,
      curationMetadata,
      disabled,
      handleSelectAll,
      isDishDescriptorsShown,
      isDishMetadata,
      isMenuTitleDescriptorsShown,
      isTaskCurationDone,
      isTaskQaDone,
      metadataType,
      onItemSelect,
      selectedItems,
      taskId,
    ],
  )

  return (
    <Table
      className="cleanup-table"
      loading={loading}
      rowKey="id"
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      size="small"
      scroll={scrollConfig}
      sticky
    />
  )
}

CleanupTaskTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  metadataType: cleanupTasksMetadataTypePropType.isRequired,
  taskId: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  onItemSelect: PropTypes.func.isRequired,
  onMultiItemsSelect: PropTypes.func.isRequired,
  taskType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isTaskCurationDone: PropTypes.bool,
  isTaskQaDone: PropTypes.bool,
}

CleanupTaskTable.defaultProps = {
  loading: false,
  selectedItems: [],
  disabled: false,
  isTaskCurationDone: false,
  isTaskQaDone: false,
}

export default CleanupTaskTable
