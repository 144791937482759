import { pick } from 'lodash'

export const transformTaskToDto = formValues => {
  const transformedTask = pick(formValues, [
    'metadataType',
    'metadataId',
    'keywords',
    'startDate',
    'endDate',
  ])

  return transformedTask
}
