import { gql } from '@apollo/client'

import SIMPLE_ENTITY_FRAGMENT from './simpleEntityFragment'

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    name
    email
    cuisineTypes {
      ...SimpleEntityFragment
    }
    languages {
      ...SimpleEntityFragment
    }
    roles {
      id
      name
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
`

export default USER_FRAGMENT
