import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../../../core/hooks'

import { JsonFileUploadButton } from '../../../../common/components'
import { handleMutationError } from '../../../../common/helpers'
import { useAppLoading } from '../../../../common/hooks'

import { MENU_TITLE } from '../../../constants'
import { IMPORT_MENU_MUTATION } from '../../../graphql'
import { menuImportTransformer } from '../../../transformers'

const MenuImportButton = ({ disabled }) => {
  const { startLoading, stopLoading, isLoading } = useAppLoading()
  const { brandId } = useParams()

  const {
    formState: { isDirty },
  } = useFormContext()

  const menuTitleSuggestions = useShallowEqualSelector(({ aiSuggestions }) =>
    get(aiSuggestions, `brands.${brandId}.${MENU_TITLE}`),
  )

  const [importMenu] = useMutation(IMPORT_MENU_MUTATION, {
    onError: handleMutationError,
  })

  const onUploadMenu = useCallback(
    async jsonFileContents => {
      startLoading()
      await importMenu({
        variables: {
          input: {
            brandId,
            menu: menuImportTransformer.transformMenuToDto(jsonFileContents),
          },
        },
      })
      stopLoading()
    },
    [brandId, importMenu, startLoading, stopLoading],
  )

  return (
    <JsonFileUploadButton
      disabled={
        disabled || isDirty || !isEmpty(menuTitleSuggestions) || isLoading
      }
      text="Import menu from AutoEat"
      onUpload={onUploadMenu}
    />
  )
}

MenuImportButton.propTypes = {
  disabled: PropTypes.bool,
}

MenuImportButton.defaultProps = {
  disabled: false,
}

export default memo(MenuImportButton)
