import { gql } from '@apollo/client'

import { SIMPLE_ENTITY_FRAGMENT } from '../../../common/graphql'

export default gql`
  query roles {
    roles {
      ...SimpleEntityFragment
    }
  }
  ${SIMPLE_ENTITY_FRAGMENT}
`
