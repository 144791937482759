import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Typography } from 'antd'
import update from 'immutability-helper'
import { filter, findIndex, map } from 'lodash'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { areSameValue } from '../../../../core/utils'

import { EducationRow } from '../EducationRow'

const createDefaultEducationRow = () => ({
  id: shortid.generate(),
  created: true,
  schoolName: '',
  degree: '',
  fieldOfStudy: '',
})

const UserEducation = ({ education, disabled }) => {
  const { control } = useFormContext()

  const handleChange = useCallback((onChange, existingValue, newValue) => {
    const { id, created, deleted } = newValue
    const index = findIndex(existingValue, ({ id: educationId }) =>
      areSameValue(id, educationId),
    )

    let updatedEducation
    if (created && deleted) {
      updatedEducation = update(existingValue, {
        $splice: [[index, 1]],
      })
    } else {
      updatedEducation = update(existingValue, {
        [index]: {
          $set: { ...newValue, ...(!newValue.created && { updated: true }) },
        },
      })
    }

    onChange(updatedEducation)
  }, [])

  const handleAdd = useCallback(
    (onChange, existingValue) =>
      onChange([...existingValue, createDefaultEducationRow()]),
    [],
  )

  return (
    <Controller
      control={control}
      defaultValue={education}
      name="education"
      render={({ field: { onChange, value } }) => (
        <>
          <Typography.Paragraph>Education</Typography.Paragraph>

          {map(
            filter(value, ({ deleted }) => !deleted),
            educationRow => (
              <EducationRow
                key={educationRow.id}
                educationRow={educationRow}
                onValueChanged={newValue =>
                  handleChange(onChange, value, newValue)
                }
                disabled={disabled}
              />
            ),
          )}

          <Button
            onClick={() => handleAdd(onChange, value)}
            disabled={disabled}
          >
            Add education
          </Button>
        </>
      )}
    />
  )
}

UserEducation.propTypes = {
  education: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
}

UserEducation.defaultProps = {
  education: [],
  disabled: false,
}

export default UserEducation
