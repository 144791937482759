import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import { APP_ROUTES } from '../../constants'
import { useAuthentication } from '../../hooks'

const GuestRoute = ({ component: Component, ...rest }) => {
  const { userInfo } = useAuthentication()

  const renderComponent = props => {
    if (!userInfo) {
      return <Component {...props} />
    }
    return (
      <Redirect
        to={{
          pathname: APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations,
        }}
      />
    )
  }

  return <Route {...rest} render={renderComponent} />
}

GuestRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default GuestRoute
