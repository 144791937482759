import React, { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Typography } from 'antd'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { Spinner } from '../../core/components'

import { MetadataOptionsContext } from '../contexts'
import { METADATA_OPTIONS_QUERY } from '../graphql'
import { createSelectOptions, createTreeSelectOptions } from '../helpers'
import { useAuthentication } from '../hooks'
import { flattenTreeStructure } from '../utils'

const MetadataOptionsProvider = ({ children }) => {
  const { userInfo } = useAuthentication()

  const [
    fetchMetadata,
    {
      data: {
        dishTypes,
        courseTypes,
        locationTypes,
        cuisineTypes,
        diets,
        allergens,
        languages,
        ingredients,
      } = {
        dishTypes: [],
        courseTypes: [],
        locationTypes: [],
        cuisineTypes: [],
        diets: [],
        allergens: [],
        languages: [],
        ingredients: [],
      },
      loading: isMetadataOptionsLoading,
      error: metadataOptionsError,
    },
  ] = useLazyQuery(METADATA_OPTIONS_QUERY)

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      fetchMetadata()
    }
  }, [fetchMetadata, userInfo])

  const courseTypeOptions = useMemo(
    () =>
      createTreeSelectOptions(
        courseTypes,
        'name',
        'id',
        node => !node.isCurationEnabled,
        ['isIgnored', 'dishTypeId', 'level'],
      ),
    [courseTypes],
  )
  const dishTypeOptions = useMemo(
    () =>
      createTreeSelectOptions(
        dishTypes,
        'name',
        'id',
        node => !node.isCurationEnabled,
        ['isIgnored', 'courseTypeId', 'level'],
      ),
    [dishTypes],
  )
  const locationTypeOptions = useMemo(
    () =>
      createTreeSelectOptions(
        locationTypes,
        'name',
        'id',
        node => !node.isCurationEnabled,
        ['level'],
      ),
    [locationTypes],
  )
  const cuisineTypeOptions = useMemo(
    () =>
      createTreeSelectOptions(
        cuisineTypes,
        'name',
        'id',
        node => !node.isCurationEnabled,
        ['isErrorProne', 'level'],
      ),
    [cuisineTypes],
  )
  const ingredientOptions = useMemo(
    () =>
      createTreeSelectOptions(
        ingredients,
        'name',
        'id',
        node => !node.isCurationEnabled,
        ['level'],
      ),
    [ingredients],
  )

  const flattenedIngredientOptions = useMemo(
    () => flattenTreeStructure(ingredientOptions),
    [ingredientOptions],
  )

  const dietOptions = useMemo(
    () =>
      createSelectOptions(diets, 'name', 'id', node => !node.isCurationEnabled),
    [diets],
  )

  const allergenOptions = useMemo(
    () =>
      createSelectOptions(
        allergens,
        'name',
        'id',
        node => !node.isCurationEnabled,
      ),
    [allergens],
  )

  const languageOptions = useMemo(
    () => createSelectOptions(languages, 'name', 'id', () => false, ['code']),
    [languages],
  )

  if (metadataOptionsError) {
    return (
      <Typography.Paragraph>
        Error occured while fetching the metadata
      </Typography.Paragraph>
    )
  }

  if (isMetadataOptionsLoading) {
    return <Spinner size="large" />
  }

  return (
    <MetadataOptionsContext.Provider
      value={{
        courseTypeOptions,
        dishTypeOptions,
        locationTypeOptions,
        cuisineTypeOptions,
        ingredientOptions,
        flattenedIngredientOptions,
        dietOptions,
        allergenOptions,
        languageOptions,
      }}
    >
      {children}
    </MetadataOptionsContext.Provider>
  )
}

MetadataOptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MetadataOptionsProvider
