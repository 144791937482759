import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { forEach, map } from 'lodash'

import { sessionManager } from '../../common/services'

const createApolloClient = async () => {
  const possibleTypes = {}
  const graphQlUri = `${window._env_.REACT_APP_SERVER_URL}/graphql`
  const subscriptionsUri = `${window._env_.REACT_APP_WEBSOCKET_SERVER_URL}/subscriptions`

  try {
    const schema = await fetch(graphQlUri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        variables: {},
        query: `
              {
                __schema {
                  types {
                    kind
                    name
                    possibleTypes {
                      name
                    }
                  }
                }
              }
            `,
      }),
    })
    const result = await schema.json()

    forEach(result.data.__schema.types, supertype => {
      if (supertype.possibleTypes) {
        possibleTypes[supertype.name] = map(supertype.possibleTypes, 'name')
      }
    })

    // eslint-disable-next-line no-console
    console.log('Fragment types successfully extracted!')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Introspection did not work')
  }

  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
      BrandMenuTitleNode: {
        fields: {
          children: {
            merge: (_, incoming) => [...incoming],
          },
        },
      },
      ConcreteSimpleEntity: {
        keyFields: ['id', 'name'],
      },
      BrandLanguage: {
        keyFields: ['id', 'brandId'],
      },
      LocationDish: {
        keyFields: ['dishId', 'locationId'],
      },
      BrandDishMainIngredient: {
        keyFields: ['dishId', 'id'],
      },
      BrandDishAdditionalIngredient: {
        keyFields: ['dishId', 'id'],
      },
      BrandDishChoiceIngredient: {
        keyFields: ['dishId', 'id'],
      },
      BrandDishLabel: {
        keyFields: ['id', 'name'],
      },
      AlternativeIngredientNode: {
        keyFields: ['id', 'dietId'],
      },
      BrandDishRows: {
        fields: {
          rows: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
      BrandDish: {
        fields: {
          choiceIngredients: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
    },
  })

  const httpLink = new HttpLink({
    uri: graphQlUri,
  })

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Accept: 'application/json',
      accessToken: sessionManager.getAccessToken(),
    },
  }))

  const wsLink = new WebSocketLink({
    uri: subscriptionsUri,
    options: {
      reconnect: true,
      connectionParams: () => ({
        accessToken: sessionManager.getAccessToken(),
      }),
    },
  })

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    authLink.concat(httpLink),
  )

  return new ApolloClient({
    link: from([splitLink]),
    uri: '/graphql',
    cache,
  })
}

export default createApolloClient
