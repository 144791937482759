import React from 'react'
import { notification, Typography } from 'antd'
import { get, isEmpty, map, omit } from 'lodash'

import { suggestionEndpoints } from '../../common/constants'
import { getAiSuggestions } from '../../common/utils'

import { dishVariousTextSuggestionTransformer } from '../transformers'

export default async function suggestVariousText({ dish }) {
  try {
    // this endpoint gives us suggestions for multiple dishes, that's why we destruct here
    const [suggestion] = await getAiSuggestions(
      suggestionEndpoints.variousText,
      [dishVariousTextSuggestionTransformer.transformDishToAiRequestDto(dish)],
    )

    const aiMessages = get(suggestion, 'messages')
    if (!isEmpty(aiMessages)) {
      notification.error({
        message: 'An error occured',
        description: map(aiMessages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })
      return null
    }

    return {
      aiSuggestions: {
        miscAndChoice: omit(suggestion, 'id'),
      },
    }
  } catch (e) {
    notification.error({
      message: 'An error occured',
      description: e.message,
      placement: 'topLeft',
    })
    return null
  }
}
