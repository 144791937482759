import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { isNil } from 'lodash'

import { Spinner } from '../../core/components'

import { useAppLoading } from '../../common/hooks'
import { AuthProvider, MetadataOptionsProvider } from '../../common/providers'

import { createApolloClient } from '../services'

import MainRouter from './MainRouter'

import 'antd/dist/antd.css'

const Bootstrap = () => {
  const [apolloClient, setApolloClient] = useState()
  const { isLoading } = useAppLoading()

  useEffect(() => {
    const createClient = async () => {
      if (isNil(apolloClient)) {
        setApolloClient(await createApolloClient())
      }
    }

    createClient()
  }, [apolloClient])

  if (isNil(apolloClient)) {
    return null
  }

  return (
    <>
      {isLoading ? <Spinner size="large" /> : null}
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <MetadataOptionsProvider>
            <BrowserRouter>
              <MainRouter />
            </BrowserRouter>
          </MetadataOptionsProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  )
}

export default Bootstrap
