import { gql } from '@apollo/client'

import {
  DISHES_SUMMARY_FRAGMENT,
  QA_BRAND_AGGREGATED_CORRECTION_COUNTS_FRAGMENT,
  SIMPLE_USER_FRAGMENT,
} from '../../../common/graphql'

const SIMPLE_BRAND_FRAGMENT = gql`
  fragment SimpleBrandFragment on SimpleBrand {
    id
    name
    status
    pendingStatus
    web
    createdAt
    updatedAt
    lastImportedAt
    requiresCuratorAttention
    requiresQaAttention
    qaDetailLevel

    cuisineType {
      id
      name
    }

    languages {
      id
      brandId
      name
      position
      isSuggestedByAi
    }

    suggestedWordsCompletenessCounts {
      curatedWordsCount
      qadWordsCount
      wordsToResolveTotal
    }

    notExportedBrandSuggestedWordsMostUsedLanguages {
      name
      count
    }

    dishesSummary {
      ...DishesSummaryFragment
    }

    assignedUnknownWordsCurator {
      ...SimpleUserFragment
    }

    assignedUnknownWordsQa {
      ...SimpleUserFragment
    }

    assignedCurator {
      ...SimpleUserFragment
    }

    assignedQa {
      ...SimpleUserFragment
    }

    assignedQQa {
      ...SimpleUserFragment
    }

    qaAggregatedCorrectionCounts {
      ...QaBrandAggregatedCorrectionCountsFragment
    }
  }
  ${DISHES_SUMMARY_FRAGMENT}
  ${SIMPLE_USER_FRAGMENT}
  ${QA_BRAND_AGGREGATED_CORRECTION_COUNTS_FRAGMENT}
`

export default SIMPLE_BRAND_FRAGMENT
