import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Table } from 'antd'
import { includes, isEmpty, isNil, split, toString } from 'lodash'
import { PropTypes } from 'prop-types'

import {
  DEFAULT_PAGINATION_CONFIG,
  HEADER_HEIGHT,
} from '../../../../common/constants'
import { useAuthentication } from '../../../../common/hooks'

import { DEFAULT_BRAND_LIST_FILTERS } from '../../../constants'
import { BRANDS_QUERY } from '../../../graphql'
import { useBrandFilters } from '../../../hooks'

import createBrandLocationTableColumns from './createBrandLocationTableColumns'

import '../../../../common/styles/smallTable.css'
import './brandLocationTable.css'

const BrandLocationTable = ({ users }) => {
  const {
    userInfo: {
      id: userId,
      permissions,
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    status = DEFAULT_BRAND_LIST_FILTERS.STATUS,
    tags = DEFAULT_BRAND_LIST_FILTERS.TAGS,
    searchText,
    cuisineTypes = DEFAULT_BRAND_LIST_FILTERS.CUISINE_TYPES,
    predominantLanguages = DEFAULT_BRAND_LIST_FILTERS.PREDOMINANT_LANGUAGES,
    unknownWordsLanguages = DEFAULT_BRAND_LIST_FILTERS.UNKNOWN_WORDS_LANGUAGES,
    assignedCurator = DEFAULT_BRAND_LIST_FILTERS.ASSIGNED_USER,
    assignedQa = DEFAULT_BRAND_LIST_FILTERS.ASSIGNED_USER,
    qaDetailLevel = DEFAULT_BRAND_LIST_FILTERS.QA_DETAIL_LEVEL,
    appropriateForUserId = userId,
    setBrandFilters,
  } = useBrandFilters()

  const selectedCuisineTypes = useMemo(
    () => (isEmpty(cuisineTypes) ? [] : split(cuisineTypes, ',')),
    [cuisineTypes],
  )

  const selectedPredominantLanguages = useMemo(
    () =>
      isEmpty(predominantLanguages) ? [] : split(predominantLanguages, ','),
    [predominantLanguages],
  )

  const selectedUnknownWordsLanguages = useMemo(
    () =>
      isEmpty(unknownWordsLanguages) ? [] : split(unknownWordsLanguages, ','),
    [unknownWordsLanguages],
  )

  const handleChangePagination = useCallback(
    (page, size) =>
      setBrandFilters({
        currentPage: size !== pageSize ? DEFAULT_PAGINATION_CONFIG.PAGE : page,
        pageSize: size,
      }),
    [pageSize, setBrandFilters],
  )

  const {
    loading,
    error,
    data = {},
    refetch: refetchBrands,
  } = useQuery(BRANDS_QUERY, {
    variables: {
      pagination: {
        currentPage: Number(currentPage),
        pageSize: Number(pageSize),
      },
      filters: {
        status,
        searchText: toString(searchText),
        tags,
        cuisineTypeIds: selectedCuisineTypes,
        predominantLanguageIds: selectedPredominantLanguages,
        unknownWordsLanguageIds: selectedUnknownWordsLanguages,
        assignedCuratorId: toString(assignedCurator),
        assignedQaId: toString(assignedQa),
        qaDetailLevel,
        appropriateForUserId,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const { results = [], total } = data?.brands || {}

  const columns = useMemo(
    () =>
      createBrandLocationTableColumns(
        permissions,
        users,
        assignedCurator,
        assignedQa,
        refetchBrands,
        tags,
      ),
    [permissions, users, assignedCurator, assignedQa, refetchBrands, tags],
  )

  const paginationConfig = useMemo(() => {
    if (isNil(total)) {
      return false
    }

    return {
      current: Number(currentPage),
      pageSize,
      total,
      onChange: handleChangePagination,
      onShowSizeChange: handleChangePagination,
      pageSizeOptions: DEFAULT_PAGINATION_CONFIG.PAGINATION_SIZE_OPTIONS,
    }
  }, [total, currentPage, pageSize, handleChangePagination])

  const getRowClassName = useCallback(
    brand => {
      if (
        !includes([brand.assignedQa?.id, brand.assignedCurator?.id], userId)
      ) {
        return ''
      }

      if (brand.requiresQaAttention) {
        return 'qa-attention'
      }

      if (brand.requiresCuratorAttention) {
        return 'curator-attention'
      }

      return ''
    },
    [userId],
  )

  if (error) {
    return <p>Error occurred while fetching the brands and locations list.</p>
  }

  return (
    <Table
      rowClassName={getRowClassName}
      rowKey="id"
      dataSource={results}
      columns={columns}
      loading={isEmpty(results) && loading}
      pagination={paginationConfig}
      childrenColumnName="locations"
      size="small"
      scroll={{ x: true }}
      sticky={{ offsetHeader: HEADER_HEIGHT }}
    />
  )
}

BrandLocationTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
}

BrandLocationTable.defaultProps = {
  users: [],
}

export default BrandLocationTable
