import React, { useMemo } from 'react'
import { Col, Row } from 'antd'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import { Input, Select } from '../../../../core/components'

import { idNew } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import { brandPropType } from '../../../propTypes'
import { brandTransformer } from '../../../transformers'
import { BrandLanguageSelect } from '../../molecules'

const BrandMenuInfoDietsAndLanguages = ({
  brand,
  isDisabled,
  qaCorrectionCounts,
}) => {
  const { languageOptions, dietOptions } = useCurationMetadata()

  const { id, menuSource, menuLink, diets, languages } = useMemo(
    () => brandTransformer.toBrandMenuInfoDietsAndLanguages(brand),
    [brand],
  )

  const dietDefaultValues = useMemo(() => map(diets, 'id'), [diets])

  return (
    <Row gutter={16}>
      {id !== idNew && (
        <Col span={6}>
          <BrandLanguageSelect
            brandLanguages={languages}
            options={languageOptions}
            disabled={isDisabled}
            isQaCorrected={qaCorrectionCounts?.languageIds > 0}
          />
        </Col>
      )}
      <Input
        span={6}
        labelAbove
        label="Menu Source"
        defaultValue={menuSource}
        name="menuSource"
        placeholder="Enter the brand menu source..."
        disabled={isDisabled}
      />
      <Input
        span={6}
        placeholder="Enter the brand menu link..."
        labelAbove
        label="Menu Link"
        defaultValue={menuLink}
        name="menuLink"
        disabled={isDisabled}
      />
      <Select
        span={6}
        labelAbove
        label="Brand Diet Labels"
        placeholder="Select brand diet labels..."
        mode="multiple"
        name="diets"
        defaultValue={dietDefaultValues}
        options={dietOptions}
        disabled={isDisabled}
      />
    </Row>
  )
}

BrandMenuInfoDietsAndLanguages.propTypes = {
  brand: brandPropType.isRequired,
  qaCorrectionCounts: PropTypes.object,
  isDisabled: PropTypes.bool,
}

BrandMenuInfoDietsAndLanguages.defaultProps = {
  isDisabled: false,
  qaCorrectionCounts: undefined,
}

export default BrandMenuInfoDietsAndLanguages
