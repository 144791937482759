import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'
import {
  DeleteOutlined,
  LinkOutlined,
  PlusOutlined,
  StopOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { Button, Form, Popconfirm, Table, Tooltip, Typography } from 'antd'
import { EditorState } from 'draft-js'
import {
  filter,
  flatten,
  forEach,
  get,
  groupBy,
  includes,
  isEmpty,
  isNil,
  map,
  noop,
  some,
  toLower,
  uniq,
} from 'lodash'
import { set as setIn } from 'lodash/fp'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import {
  useDeepCompareEffect,
  useShallowEqualSelector,
} from '../../../../core/hooks'

import { HighlightingTextField, ReadMore } from '../../../../common/components'
import { CURATION_STATUS } from '../../../../common/constants'
import { getContentStateFromText } from '../../../../common/helpers'
import {
  findNodesBy,
  flattenTreeStructure,
  formatDescriptors,
  isValidHttpUrl,
} from '../../../../common/utils'

import { DietDescriptorCollapse } from '../../../../dishes/components/molecules'
import { MENU_TITLE_STATUS_TYPE } from '../../../constants'
import { MenuTitleTreeItemPropType } from '../../../propTypes'
import {
  addMenuTitleToParent as addMenuTitleToParentAction,
  addParentToMenuTitle as addParentToMenuTitleAction,
  deleteMenuTitle as deleteMenuTitleAction,
  setExpandedRowKeys as setExpandedRowKeysAction,
  setHighlightedRowKey as setHighlightedRowKeyAction,
  setMenuTitles,
} from '../../../redux'
import { transformMenuTitleFromDto } from '../../../transformers/menuTitleTransformer'
import {
  MenuTitleDescriptionEditableCell,
  MenuTitleNameEditableCell,
} from '../../molecules'

import './menuTitleTable.css'

const EditableContext = createContext(null)

const EditableRow = props => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const styles = {
  menuButton: { marginRight: '5px' },
  menuIgnoreButton: { marginRight: '15px' },
  usedIgnoreButton: { backgroundColor: '#a3a3a3' },
}

const MenuTitleTable = ({
  ownerId,
  ownerType,
  menuTitles,
  menuTitlesHighlightWords,
  deleteMenu,
  setIgnoredDishType,
  brandStatus,
  isQaCorrected,
}) => {
  const dispatch = useDispatch()
  const {
    menuTitles: unsavedMenuTitles,
    expandedRowKeys,
    highlightedRowKey,
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const menuTitleSuggestions = useShallowEqualSelector(({ aiSuggestions }) =>
    get(aiSuggestions, `${ownerType}.${ownerId}.menuTitles.predictions`),
  )

  const setSuggestionOnTable = useCallback(() => {
    let menuTitlesWithSuggestions = isEmpty(unsavedMenuTitles)
      ? map(menuTitles, (menuTitleRoot, index) =>
          transformMenuTitleFromDto(
            menuTitleRoot,
            menuTitlesHighlightWords,
            0,
            `[${index}]`,
          ),
        )
      : [...unsavedMenuTitles]

    forEach(menuTitleSuggestions, suggestion => {
      const newStyledDescription = EditorState.createWithContent(
        getContentStateFromText(suggestion.styledDescription),
      )
      menuTitlesWithSuggestions = setIn(
        `${suggestion.path}.styledDescription`,
        newStyledDescription,
        menuTitlesWithSuggestions,
      )

      menuTitlesWithSuggestions = setIn(
        `${suggestion.path}.status`,
        suggestion.status,
        menuTitlesWithSuggestions,
      )
    })

    dispatch(
      setMenuTitles({
        menuTitles: menuTitlesWithSuggestions,
        state: { isDirty: true, isError: false },
      }),
    )

    // we expand all nodes for the suggestions to be seen
    dispatch(
      setExpandedRowKeysAction(
        uniq(
          flatten(
            map(menuTitleSuggestions, ({ id, parents }) =>
              map([...parents, id], menuTitleId => `menuTitle-${menuTitleId}`),
            ),
          ),
        ),
      ),
    )
  }, [
    dispatch,
    menuTitleSuggestions,
    menuTitles,
    menuTitlesHighlightWords,
    unsavedMenuTitles,
  ])

  const addMenuTitleToParent = useCallback(
    ({ id: parentId, path: parentPath, children, depth: parentDepth }) => {
      dispatch(
        addMenuTitleToParentAction({
          parentId,
          parentPath,
          children,
          parentDepth,
        }),
      )
    },
    [dispatch],
  )

  const deleteMenuTitle = useCallback(
    ({ id, path }) => {
      dispatch(deleteMenuTitleAction({ id, path }))
    },
    [dispatch],
  )

  const addParentToMenuTitle = useCallback(
    record => {
      const newId = shortid.generate()
      dispatch(addParentToMenuTitleAction({ newId, menuTitle: record }))
    },
    [dispatch],
  )

  const rowKeyFunction = useCallback(({ id }) => `menuTitle-${id}`, [])

  const rowClassNameFunction = useCallback(
    ({ id, status }) => {
      if (`menuTitle-${id}` === highlightedRowKey) {
        return 'menuTitleRow-legend-highlight'
      }

      return status === MENU_TITLE_STATUS_TYPE.PENDING
        ? `menuTitleRow-suggested`
        : 'menuTitleRow'
    },
    [highlightedRowKey],
  )

  useEffect(() => {
    if (!isNil(highlightedRowKey)) {
      const selectedRow = document.querySelector(
        `[data-row-key="${highlightedRowKey}"]`,
      )

      if (!isEmpty(selectedRow)) {
        selectedRow.scrollIntoView({ block: 'center' })
      }
    }
  }, [highlightedRowKey])

  const handleRowExpansion = useCallback(
    (expanded, { id }) =>
      dispatch(
        setExpandedRowKeysAction(
          expanded
            ? [...expandedRowKeys, `menuTitle-${id}`]
            : filter(expandedRowKeys, key => key !== `menuTitle-${id}`),
        ),
      ),
    [dispatch, expandedRowKeys],
  )

  const menuTitlesFlat = useMemo(
    () => flattenTreeStructure(menuTitles),
    [menuTitles],
  )

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
      },
    }),
    [],
  )

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '500px',
        key: 'name',
        render: (_, record) => (
          <MenuTitleNameEditableCell
            disabled={!isNil(record.menuId) || isNil(record.parentId)}
            dataIndex="name"
            record={record}
            size="small"
          />
        ),
      },
      {
        title: 'Description',
        dataIndex: 'styledDescription',
        width: '700px',
        key: 'styledDescription',
        render: (_, record) => (
          <MenuTitleDescriptionEditableCell
            dataIndex="styledDescription"
            record={record}
            size="small"
          />
        ),
      },
      ...(some(
        menuTitlesFlat,
        ({ miscDescriptors }) => !isEmpty(miscDescriptors),
      )
        ? [
            {
              title: 'Misc Descriptors',
              dataIndex: 'miscDescriptors',
              render: descriptors =>
                isEmpty(descriptors) ? null : (
                  <ReadMore
                    maxHeight={170}
                    component={formatDescriptors(descriptors)}
                  />
                ),
            },
          ]
        : []),
      ...(some(
        menuTitlesFlat,
        ({ addonDescriptors }) => !isEmpty(addonDescriptors),
      )
        ? [
            {
              title: 'Addon Descriptors',
              dataIndex: 'addonDescriptors',
              width: '160px',
              render: descriptors =>
                isEmpty(descriptors) ? null : (
                  <ReadMore
                    maxHeight={170}
                    maxWidth="160px"
                    component={formatDescriptors(descriptors)}
                  />
                ),
            },
          ]
        : []),
      ...(some(
        menuTitlesFlat,
        ({ dietDescriptors }) => !isEmpty(dietDescriptors),
      )
        ? [
            {
              title: 'Diet Descriptors',
              dataIndex: 'dietDescriptors',
              width: '170px',
              render: (_, { id, dietDescriptors }) =>
                !isEmpty(dietDescriptors) ? (
                  <ReadMore
                    maxWidth="170px"
                    maxHeight={170}
                    component={
                      <DietDescriptorCollapse
                        prefix="dietDescriptors"
                        value={dietDescriptors}
                        highlights={groupBy(
                          get(menuTitlesHighlightWords, id),
                          'originField',
                        )}
                      />
                    }
                  />
                ) : null,
            },
          ]
        : []),
      ...(some(
        menuTitlesFlat,
        ({ nutritionsDescriptors }) => !isEmpty(nutritionsDescriptors),
      )
        ? [
            {
              title: 'Nutritions Descriptors',
              dataIndex: 'nutritionsDescriptors',
              render: descriptors =>
                isEmpty(descriptors) ? null : (
                  <ReadMore
                    maxHeight={170}
                    component={formatDescriptors(descriptors)}
                  />
                ),
            },
          ]
        : []),
      ...(some(
        menuTitlesFlat,
        ({ allergenDescriptors }) => !isEmpty(allergenDescriptors),
      )
        ? [
            {
              title: 'Allergens Descriptors',
              dataIndex: 'allergenDescriptors',
              width: '170px',
              render: (_, { allergenDescriptors, id }) =>
                isEmpty(allergenDescriptors) ? null : (
                  <ReadMore
                    maxHeight={170}
                    maxWidth="170px"
                    component={
                      <HighlightingTextField
                        disabled
                        value={formatDescriptors(allergenDescriptors)}
                        highlightingList={
                          filter(
                            get(
                              menuTitlesHighlightWords,
                              id,
                              ({ originField }) =>
                                includes(
                                  toLower(originField),
                                  'allergendescriptors',
                                ),
                            ),
                          ) || []
                        }
                      />
                    }
                  />
                ),
            },
          ]
        : []),
      {
        key: 'actions',
        width: '150px',
        render: (_text, record) => (
          <>
            {record.depth < 5 && isNil(record.menuId) && (
              <Tooltip title="Add menu title">
                <Button
                  style={styles.menuButton}
                  icon={<PlusOutlined />}
                  shape="circle"
                  onClick={() => addMenuTitleToParent(record)}
                />
              </Tooltip>
            )}
            {record.depth === 1 && isNil(record.menuId) && (
              <Tooltip title="Add menu title above">
                <Button
                  style={styles.menuButton}
                  icon={<UpOutlined />}
                  shape="circle"
                  onClick={() => addParentToMenuTitle(record)}
                />
              </Tooltip>
            )}
            {record.depth !== 0 && !Number.isNaN(Number(record.id)) && (
              <Tooltip title="Set ignored dish type">
                <Button
                  icon={<StopOutlined />}
                  style={{
                    ...styles.menuIgnoreButton,
                    ...(!isNil(record.dishTypeId) && styles.usedIgnoreButton),
                  }}
                  shape="circle"
                  onClick={() => setIgnoredDishType(record.id)}
                />
              </Tooltip>
            )}
            {record.menuId === record.id ? (
              <>
                <Popconfirm
                  title={
                    <>
                      <Typography.Paragraph>
                        Are you sure to delete this menu?
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        All menu titles and dishes associated with this menu
                        will also be deleted once you press{' '}
                        <Typography.Paragraph strong>Yes</Typography.Paragraph>
                      </Typography.Paragraph>
                    </>
                  }
                  placement="topRight"
                  onConfirm={() => deleteMenu(record.id)}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{ danger: true }}
                >
                  <Button
                    style={styles.menuButton}
                    icon={<DeleteOutlined />}
                    shape="circle"
                    name="menuTitleTable"
                  />
                </Popconfirm>
                {isValidHttpUrl(
                  record.name.getCurrentContent().getPlainText(),
                ) && (
                  <a
                    href={record.name.getCurrentContent().getPlainText()}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button
                      style={styles.menuButton}
                      icon={<LinkOutlined />}
                      shape="circle"
                    />
                  </a>
                )}
              </>
            ) : (
              record.depth !== 0 &&
              isEmpty(record.children) && (
                <Popconfirm
                  title="Are you sure to delete this menu title?"
                  onConfirm={() => deleteMenuTitle(record)}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{ danger: true }}
                >
                  <Button
                    style={styles.menuButton}
                    icon={<DeleteOutlined />}
                    shape="circle"
                    name="menuTitleTable"
                  />
                </Popconfirm>
              )
            )}
          </>
        ),
      },
    ],
    [
      addMenuTitleToParent,
      addParentToMenuTitle,
      deleteMenu,
      deleteMenuTitle,
      menuTitlesFlat,
      menuTitlesHighlightWords,
      setIgnoredDishType,
    ],
  )

  useDeepCompareEffect(() => {
    // Update the menu title table with the suggestions
    if (!isNil(menuTitleSuggestions)) {
      setSuggestionOnTable()
    } else {
      const pendingNodes = findNodesBy(
        menuTitles,
        node => node.status === MENU_TITLE_STATUS_TYPE.PENDING,
      )
      const emptyPendingNodes = isEmpty(pendingNodes)

      // we initialize the table with the ones from props
      dispatch(
        setMenuTitles({
          menuTitles: map(menuTitles, (menuTitleRoot, index) =>
            transformMenuTitleFromDto(
              menuTitleRoot,
              menuTitlesHighlightWords,
              0,
              `[${index}]`,
            ),
          ),
          state: { isDirty: !emptyPendingNodes, isError: false },
        }),
      )

      dispatch(
        setExpandedRowKeysAction(
          uniq(
            flatten(
              map(pendingNodes, ({ id, parents }) =>
                map(
                  [...parents, id],
                  menuTitleId => `menuTitle-${menuTitleId}`,
                ),
              ),
            ),
          ),
        ),
      )
    }
  }, [menuTitles, menuTitleSuggestions, menuTitlesHighlightWords])

  useEffect(() => {
    if (
      includes(
        [
          CURATION_STATUS.SANITY_CHECK.value,
          CURATION_STATUS.MISC_AND_CHOICE.value,
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
        ],
        brandStatus,
      )
    ) {
      dispatch(
        setExpandedRowKeysAction(
          map(
            filter(menuTitlesFlat, 'id'),
            ({ id: menuTitleId }) => `menuTitle-${menuTitleId}`,
          ),
        ),
      )
    }
  }, [brandStatus, dispatch, menuTitlesFlat])

  useEffect(() => {
    let highlightTimeout
    if (!isNil(highlightedRowKey)) {
      highlightTimeout = setTimeout(
        () => dispatch(setHighlightedRowKeyAction(null)),
        5000,
      )
    }

    return () => clearTimeout(highlightTimeout)
  }, [dispatch, highlightedRowKey])

  return (
    <Table
      className={`menu-title-table ${isQaCorrected ? 'highlight-red' : ''}`}
      components={components}
      pagination={false}
      dataSource={!isEmpty(unsavedMenuTitles) ? unsavedMenuTitles : []}
      columns={columns}
      rowKey={rowKeyFunction}
      rowClassName={rowClassNameFunction}
      expandedRowKeys={expandedRowKeys}
      onExpand={handleRowExpansion}
    />
  )
}

MenuTitleTable.propTypes = {
  menuTitles: PropTypes.arrayOf(MenuTitleTreeItemPropType).isRequired,
  ownerId: PropTypes.string.isRequired,
  ownerType: PropTypes.string.isRequired,
  menuTitlesHighlightWords: PropTypes.object,
  deleteMenu: PropTypes.func,
  setIgnoredDishType: PropTypes.func,
  brandStatus: PropTypes.string,
  isQaCorrected: PropTypes.bool,
}

MenuTitleTable.defaultProps = {
  menuTitlesHighlightWords: {},
  deleteMenu: noop,
  setIgnoredDishType: noop,
  brandStatus: undefined,
  isQaCorrected: false,
}

export default memo(MenuTitleTable)
