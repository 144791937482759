import React, { useCallback } from 'react'
import { MinusOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row } from 'antd'
import PropTypes from 'prop-types'

const styles = {
  container: {
    marginBottom: 5,
  },
}

const EducationRow = ({ educationRow, onValueChanged, disabled }) => {
  const { schoolName, degree, fieldOfStudy } = educationRow

  const handleOnDelete = useCallback(() => {
    onValueChanged({ ...educationRow, deleted: true })
  }, [educationRow, onValueChanged])

  const handleOnSchoolNameChange = useCallback(
    event => {
      onValueChanged({ ...educationRow, schoolName: event?.target?.value })
    },
    [educationRow, onValueChanged],
  )

  const handleOnDegreeChange = useCallback(
    event => {
      onValueChanged({ ...educationRow, degree: event?.target?.value })
    },
    [educationRow, onValueChanged],
  )

  const handleOnFieldOfStudyChange = useCallback(
    event => {
      onValueChanged({ ...educationRow, fieldOfStudy: event?.target?.value })
    },
    [educationRow, onValueChanged],
  )

  return (
    <Row gutter={[8, 6]} style={styles.container}>
      <Col span={7}>
        <Input
          value={schoolName}
          placeholder="School name"
          onChange={handleOnSchoolNameChange}
          disabled={disabled}
        />
      </Col>

      <Col span={7}>
        <Input
          value={degree}
          placeholder="Degree"
          onChange={handleOnDegreeChange}
          disabled={disabled}
        />
      </Col>

      <Col span={7}>
        <Input
          value={fieldOfStudy}
          placeholder="Field of study"
          onChange={handleOnFieldOfStudyChange}
          disabled={disabled}
        />
      </Col>

      <Col span={3}>
        <Button
          icon={<MinusOutlined />}
          shape="circle"
          onClick={handleOnDelete}
          disabled={disabled}
        />
      </Col>
    </Row>
  )
}

EducationRow.propTypes = {
  educationRow: PropTypes.object.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

EducationRow.defaultProps = {
  disabled: false,
}

export default EducationRow
