import React, { useCallback } from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'

import { closeTimeSlots, openTimeSlots } from '../../../constants'
import { stylePropType } from '../../../propTypes'

const TimeSlotDropdown = ({
  disabled,
  timeSlots,
  selectedTimeSlot,
  onValueChanged,
  style,
}) => {
  const timeslotFormatDate = useCallback(
    date => date.substr(0, date.lastIndexOf(':')),
    [],
  )

  return (
    <Select
      disabled={disabled}
      value={selectedTimeSlot}
      onSelect={value => onValueChanged(value)}
      style={style}
    >
      {timeSlots.map(timeSlot => (
        <Select.Option key={timeSlot}>
          {timeslotFormatDate(timeSlot)}
        </Select.Option>
      ))}
    </Select>
  )
}

TimeSlotDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  selectedTimeSlot: PropTypes.string.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onValueChanged: PropTypes.func.isRequired,
  style: stylePropType,
}

TimeSlotDropdown.defaultProps = {
  style: undefined,
}

const styles = {
  timeSlotDropdown: {
    width: 100,
    marginLeft: 5,
  },
}

export default function TimeSlot({
  disabled,
  openTime,
  closeTime,
  kitchenCloseTime,
  onValueChanged,
  style,
}) {
  return (
    <div style={style}>
      <TimeSlotDropdown
        timeSlots={openTimeSlots}
        selectedTimeSlot={openTime}
        onValueChanged={value =>
          onValueChanged({
            openTime: value,
            closeTime,
            ...(kitchenCloseTime ? { kitchenCloseTime } : {}),
          })
        }
        disabled={disabled}
        style={styles.timeSlotDropdown}
      />
      <TimeSlotDropdown
        timeSlots={closeTimeSlots}
        selectedTimeSlot={closeTime}
        onValueChanged={value =>
          onValueChanged({
            openTime,
            closeTime: value,
            ...(kitchenCloseTime ? { kitchenCloseTime } : {}),
          })
        }
        disabled={disabled}
        style={styles.timeSlotDropdown}
      />
      {kitchenCloseTime && (
        <TimeSlotDropdown
          timeSlots={closeTimeSlots}
          selectedTimeSlot={kitchenCloseTime}
          onValueChanged={value =>
            onValueChanged({ openTime, closeTime, kitchenCloseTime: value })
          }
          disabled={disabled}
          style={styles.timeSlotDropdown}
        />
      )}
    </div>
  )
}

TimeSlot.propTypes = {
  disabled: PropTypes.bool.isRequired,
  openTime: PropTypes.string.isRequired,
  closeTime: PropTypes.string.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  kitchenCloseTime: PropTypes.string,
  style: stylePropType,
}

TimeSlot.defaultProps = {
  style: undefined,
  kitchenCloseTime: undefined,
}
