import { gql } from '@apollo/client'

import LABEL_LEGEND_FRAGMENT from './labelLegendFragment'

const ALLERGEN_LABEL_LEGEND_FRAGMENT = gql`
  fragment AllergenLabelLegendFragment on AllergenLabelLegend {
    ...LabelLegendFragment
    allergenId
  }
  ${LABEL_LEGEND_FRAGMENT}
`

export default ALLERGEN_LABEL_LEGEND_FRAGMENT
