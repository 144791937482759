import React, { memo, useCallback, useMemo, useState } from 'react'
import { Row, TreeSelect, Typography } from 'antd'
import { filter, map, size as _size, some, uniqBy } from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { areSameValue } from '../../../../core/utils'

import { handleTreeSelectCustomDropdownForSearch } from '../../../../common/helpers'
import { stylePropType, treeOptionPropType } from '../../../../common/propTypes'
import { findNodeByValue } from '../../../../common/utils'

const styles = {
  selectedValues: {
    margin: '0.5rem',
    marginTop: 0,
  },
  ingredient: {
    minWidth: '5rem',
  },
}

const ingredientSelectPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  highlightClassName: PropTypes.string,
  isEditable: PropTypes.bool,
  isSuggestedUsingMl: PropTypes.bool,
  isSuggestedUsingParser: PropTypes.bool,
  word: PropTypes.string,
})

const DishModalIngredientTreeSelect = ({
  ingredient,
  ingredients,
  treeData,
  size,
  showWord,
  disabled,
  onValueChanged,
  style,
  remove,
}) => {
  const [isTreeSelectClicked, setIsTreeSelectClicked] = useState(false)

  const handleSelectFocus = useCallback(() => {
    if (isTreeSelectClicked) {
      return
    }

    setIsTreeSelectClicked(true)
  }, [isTreeSelectClicked])

  const onChange = useCallback(
    value => {
      let nextIngredients
      const isAlreadySelected = some(ingredients, ({ id }) =>
        areSameValue(id, value),
      )

      if (isAlreadySelected) {
        return
      }

      const newIngredient = value && findNodeByValue(treeData, value)

      if (!newIngredient) {
        // Ingredient was removed -> delete it from the list.
        if (remove) {
          nextIngredients = remove(ingredient.id)
        } else {
          nextIngredients = filter(ingredients, i => i.id !== ingredient.id)
        }
      } else {
        // Ingredient was changed -> alter its value in the current list.
        nextIngredients = map(ingredients, i =>
          i.id === ingredient.id
            ? {
                ...newIngredient,
                id: newIngredient.value,
                name: newIngredient.name || newIngredient.title,
              }
            : i,
        )
      }

      onValueChanged(nextIngredients)
    },
    [ingredient.id, ingredients, onValueChanged, remove, treeData],
  )

  return (
    <GridFormItem
      label={
        showWord ? (
          <Typography.Text className={ingredient.highlightClassName}>
            {ingredient.isSuggestedUsingMl && !ingredient.isSuggestedUsingParser
              ? 'ML'
              : ingredient.word}
          </Typography.Text>
        ) : undefined
      }
      labelAbove={false}
      tooltip={ingredient.name}
      style={styles.selectedValues}
      key={`ingredient-${ingredient.id}`}
    >
      <TreeSelect
        size={size}
        dropdownMatchSelectWidth={300}
        className={ingredient.highlightClassName}
        treeData={
          isTreeSelectClicked
            ? treeData
            : [
                {
                  ...ingredient,
                  value: ingredient.id,
                  title: ingredient.name,
                },
              ]
        }
        value={ingredient.id}
        style={style}
        dropdownRender={handleTreeSelectCustomDropdownForSearch}
        onChange={onChange}
        onFocus={handleSelectFocus}
        treeDefaultExpandedKeys={[ingredient.id]}
        allowClear
        showSearch
        treeNodeFilterProp="title"
        disabled={disabled}
      />
    </GridFormItem>
  )
}

DishModalIngredientTreeSelect.propTypes = {
  ingredient: ingredientSelectPropType,
  treeData: PropTypes.arrayOf(treeOptionPropType).isRequired,
  ingredients: PropTypes.arrayOf(ingredientSelectPropType),
  style: stylePropType,
  onValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  remove: PropTypes.func,
  showWord: PropTypes.bool,
}

DishModalIngredientTreeSelect.defaultProps = {
  ingredient: null,
  ingredients: null,
  style: null,
  disabled: false,
  size: undefined,
  remove: undefined,
  showWord: false,
}

const DishModalIngredientsSelect = ({
  treeData,
  ingredients,
  newIngredients,
  style,
  onValueChanged,
  disabled,
  size,
  showWord,
  remove,
}) => {
  const [isTreeSelectClicked, setIsTreeSelectClicked] = useState(false)

  const handleSelectFocus = useCallback(() => {
    if (isTreeSelectClicked) {
      return
    }

    setIsTreeSelectClicked(true)
  }, [isTreeSelectClicked])

  const uniqueIngredients = useMemo(
    () => uniqBy(newIngredients || ingredients, 'id'),
    [ingredients, newIngredients],
  )

  const fieldStyle = useMemo(
    () => ({ ...style, ...styles.ingredient }),
    [style],
  )

  const handleOnChange = useCallback(
    value => {
      if (!value) {
        return
      }

      // we also want to use other properties of the ingredient and we will select it from the tree
      const newIngredient = findNodeByValue(treeData, value)

      const nextIngredients = uniqBy(
        [...uniqueIngredients, newIngredient],
        'id',
      )

      onValueChanged(
        map(nextIngredients, ingredient => ({
          ...ingredient,
          id: ingredient.id || ingredient.value,
          name: ingredient.name || ingredient.title,
        })),
      )
    },
    [onValueChanged, treeData, uniqueIngredients],
  )

  return (
    <Row gutter={16}>
      {map(uniqueIngredients, ingredient => (
        <DishModalIngredientTreeSelect
          key={ingredient.id}
          ingredient={ingredient}
          ingredients={ingredients}
          treeData={treeData}
          size={size}
          showWord={showWord}
          disabled={disabled}
          onValueChanged={onValueChanged}
          fieldStyle={fieldStyle}
          remove={remove}
        />
      ))}

      {!disabled && (
        <GridFormItem span={4}>
          <TreeSelect
            dropdownMatchSelectWidth={300}
            size={size}
            key={_size(uniqueIngredients)}
            treeData={isTreeSelectClicked ? treeData : []}
            placeholder="Select or search"
            dropdownRender={handleTreeSelectCustomDropdownForSearch}
            style={styles.treeSelect}
            onFocus={handleSelectFocus}
            onChange={handleOnChange}
            value={null}
            showSearch
            treeNodeFilterProp="title"
          />
        </GridFormItem>
      )}
    </Row>
  )
}

DishModalIngredientsSelect.propTypes = {
  treeData: PropTypes.arrayOf(treeOptionPropType).isRequired,
  ingredients: PropTypes.arrayOf(ingredientSelectPropType),
  newIngredients: PropTypes.arrayOf(ingredientSelectPropType),
  onValueChanged: PropTypes.func.isRequired,
  style: stylePropType,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  remove: PropTypes.func,
  showWord: PropTypes.bool,
}

DishModalIngredientsSelect.defaultProps = {
  ingredients: null,
  newIngredients: null,
  style: null,
  disabled: false,
  size: undefined,
  remove: undefined,
  showWord: false,
}

export default memo(DishModalIngredientsSelect)
