import { get, isNil, keys, map } from 'lodash'

const getFilterOptions = (options, labelPath, valuePath) =>
  map(keys(options), key => ({
    label: !isNil(labelPath)
      ? get(options, `${key}.${labelPath}`)
      : options[key],
    value: !isNil(valuePath) ? get(options, `${key}.${valuePath}`) : key,
  }))

export default getFilterOptions
