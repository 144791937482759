import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Col, notification, Row } from 'antd'
import { filter, find, get, map } from 'lodash'

import { useDocumentTitle, useQueryParams } from '../../../core/hooks'
import { formatDate } from '../../../core/utils'

import { PageWithHeader } from '../../../common/components'
import {
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
  MENU_ENTRY,
  METADATA_TYPES,
} from '../../../common/constants'
import { USERS_WITH_KNOWLEDGE_QUERY } from '../../../common/graphql'
import { handleMutationError } from '../../../common/helpers'
import {
  useAuthentication,
  useCurationMetadata,
  useUserHasRoles,
} from '../../../common/hooks'

import { BRANDS_WITH_DUPLICATES_QUERY } from '../../../brands/graphql'
import theme from '../../../theme'
import { REPORT_PARAM_TYPES } from '../../constants'
import {
  CREATE_USER_REPORT_REQUEST_MUTATION,
  REPORT_REQUEST_DOWNLOAD_URL_QUERY,
  USER_REPORT_REQUESTS_QUERY,
} from '../../graphql'
import { sendUserReportRequest } from '../../services'
import { ReportsInputs, ReportsTable } from '../sections'

const styles = {
  container: {
    padding: theme.padding,
  },
}

const CUSTOM_METADATA_TYPES = {
  TOP_LEVEL_INGREDIENTS: 'topLevelIngredients',
  BRANDS_WITH_DUPLICATES: 'brandsWithDuplicates',
  USERS: 'users',
}

const Reports = () => {
  const { reportName } = useParams()
  const {
    dishTypeOptions,
    courseTypeOptions,
    locationTypeOptions,
    cuisineTypeOptions,
    ingredientOptions,
    allergenOptions,
  } = useCurationMetadata()
  const { userInfo } = useAuthentication()
  const isUserLearner = useUserHasRoles(['learner'])
  const availableReports = useSelector(({ app }) => app.availableReports)

  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userInfo?.settings?.pageSize ||
      DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
  } = useQueryParams()

  const {
    data: { users } = {},
    loading: usersLoading,
    error: usersWithKnowledgeError,
  } = useQuery(USERS_WITH_KNOWLEDGE_QUERY, {
    variables: {
      filters: {
        isActive: true,
      },
    },
  })

  const { data: brandsData, error: brandsError } = useQuery(
    BRANDS_WITH_DUPLICATES_QUERY,
    {
      variables: {
        filters: {
          status: [
            CURATION_STATUS.QA.value,
            CURATION_STATUS.QQA_CONFIRMATION.value,
            CURATION_STATUS.DONE.value,
          ],
          ...(isUserLearner && { assignedUserId: userInfo.id }),
        },
      },
      fetchPolicy: 'network-only',
    },
  )

  const reportConfig = useMemo(
    () => find(availableReports, report => report.name === reportName),
    [reportName, availableReports],
  )

  const brandsWithDuplicates = useMemo(
    () =>
      map(get(brandsData, 'brandsWithDuplicates'), brand => ({
        ...brand,
        label: brand.name,
        value: brand.id,
        children: map(brand.duplicates, duplicate => ({
          ...duplicate,
          label: duplicate.name,
          value: duplicate.id,
        })),
      })),
    [brandsData],
  )

  const curationMetadata = useMemo(
    () => ({
      [METADATA_TYPES.INGREDIENTS]: ingredientOptions,
      [METADATA_TYPES.DISH_TYPES]: dishTypeOptions,
      [METADATA_TYPES.LOCATION_TYPES]: locationTypeOptions,
      [METADATA_TYPES.CUISINE_TYPES]: cuisineTypeOptions,
      [METADATA_TYPES.COURSE_TYPES]: courseTypeOptions,
      [METADATA_TYPES.ALLERGENS]: allergenOptions,
      [CUSTOM_METADATA_TYPES.TOP_LEVEL_INGREDIENTS]: [
        {
          value: `select_all`,
          label: `Select all`,
        },
        {
          value: `select_all_level:1`,
          label: `Select all level 1`,
        },
        {
          value: `select_all_level:2`,
          label: `Select all level 2`,
        },
        ...ingredientOptions,
      ],
      [CUSTOM_METADATA_TYPES.BRANDS_WITH_DUPLICATES]: brandsWithDuplicates,
      [CUSTOM_METADATA_TYPES.USERS]: map(users, user => ({
        ...user,
        value: user.id,
        title: user.name,
      })),
    }),
    [
      ingredientOptions,
      dishTypeOptions,
      locationTypeOptions,
      cuisineTypeOptions,
      courseTypeOptions,
      allergenOptions,
      brandsWithDuplicates,
      users,
    ],
  )

  const userReportRequestsQueryVariables = useMemo(
    () => ({
      currentPage: Number(page),
      pageSize: Number(pageSize),
      filters: {
        type: reportName,
        ...(isUserLearner && { userId: userInfo.id }),
      },
    }),
    [page, pageSize, reportName, userInfo.id, isUserLearner],
  )

  const {
    loading,
    error,
    data = {},
  } = useQuery(USER_REPORT_REQUESTS_QUERY, {
    variables: userReportRequestsQueryVariables,
  })

  const { count: totalCount, requests = [] } = data.userReportRequests || {}
  const userReportRequests = useMemo(
    () =>
      map(requests, report => {
        let parsedParams
        try {
          parsedParams = JSON.parse(report.params)
        } catch (e) {
          handleMutationError(e)
          parsedParams = {}
        }

        return {
          ...report,
          params: parsedParams,
        }
      }),
    [requests],
  )
  const areReportsLoading = useMemo(
    () => loading || !availableReports || usersLoading,
    [availableReports, loading, usersLoading],
  )

  useDocumentTitle(
    `Report ${
      get(reportConfig, 'displayName')
        ? ` | ${get(reportConfig, 'displayName')}`
        : ''
    }`,
  )

  const [loadReportRequestDownloadUrl] = useLazyQuery(
    REPORT_REQUEST_DOWNLOAD_URL_QUERY,
    {
      fetchPolicy: 'no-cache',
      onError: handleMutationError,
      onCompleted: response =>
        window.open(response?.reportRequestDownloadUrl?.url, '_blank'),
    },
  )

  const handleReportCreateComplete = useCallback(
    async report => {
      try {
        const parsedParams = JSON.parse(report.params)
        const parameters = {}
        reportConfig.parameters.forEach(paramConfig => {
          const paramValue = parsedParams[paramConfig.name]
          parameters[paramConfig.name] =
            paramConfig.type === REPORT_PARAM_TYPES.DATE
              ? formatDate(paramValue)
              : paramValue
        })

        await sendUserReportRequest({
          taskId: report.id,
          reportType: reportName,
        })

        if (reportConfig.isTaskSlow) {
          notification.info({
            message: 'Notice',
            description:
              'This type of report might take a long time to complete, please be patient.',
            placement: 'topLeft',
          })
        }
      } catch (err) {
        handleMutationError(err)
      }
    },
    [reportConfig, reportName],
  )

  const [createUserReportRequest] = useMutation(
    CREATE_USER_REPORT_REQUEST_MUTATION,
    {
      refetchQueries: [
        {
          query: USER_REPORT_REQUESTS_QUERY,
          variables: userReportRequestsQueryVariables,
        },
      ],
      onError: handleMutationError,
      onCompleted: async ({ createUserReportRequest: result }) =>
        handleReportCreateComplete(result),
    },
  )

  const handleCreate = useCallback(
    async (params = {}) => {
      await createUserReportRequest({
        variables: {
          input: {
            params: JSON.stringify(params),
            type: reportName,
          },
        },
      })
    },
    [createUserReportRequest, reportName],
  )

  const handleDownload = useCallback(
    id => {
      loadReportRequestDownloadUrl({ variables: { id } })
    },
    [loadReportRequestDownloadUrl],
  )

  if (brandsError || usersWithKnowledgeError) {
    return <p>Failed to get required metadata</p>
  }

  if (error || (availableReports && !reportConfig)) {
    return <p>This report type does not exist</p>
  }

  return (
    <PageWithHeader menuItem={MENU_ENTRY.REPORTS}>
      <div style={styles.container}>
        {get(reportConfig, 'displayName') && (
          <h3>{reportConfig.displayName}</h3>
        )}
        {reportConfig && (
          <ReportsInputs
            reportConfig={reportConfig}
            reportName={reportName}
            curationMetadata={curationMetadata}
            onCreate={handleCreate}
          />
        )}
        <Row>
          <Col span={24}>
            <ReportsTable
              totalCount={totalCount}
              loading={areReportsLoading}
              reports={filter(
                userReportRequests,
                ({ type }) => reportName === type,
              )}
              reportConfig={reportConfig}
              curationMetadata={curationMetadata}
              handleDownload={handleDownload}
            />
          </Col>
        </Row>
      </div>
    </PageWithHeader>
  )
}

export default Reports
