import { gql } from '@apollo/client'

import { BRAND_DISH_FRAGMENT } from '../fragments'

const BRAND_DISHES_QUERY = gql`
  query brandDishesQuery(
    $brandId: ID!
    $paginationFiltersAndSorters: DishesPaginationFiltersAndSorters
  ) {
    brandDishes(
      brandId: $brandId
      paginationFiltersAndSorters: $paginationFiltersAndSorters
    ) {
      rows {
        ...BrandDishFragment
      }
      count
    }
  }
  ${BRAND_DISH_FRAGMENT}
`

export default BRAND_DISHES_QUERY
