import React, { useCallback, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Card, notification, Typography } from 'antd'

import { useDocumentTitle } from '../../../core/hooks'

import { useAppLoading, useAuthentication } from '../../../common/hooks'
import { sessionManager } from '../../../common/services'
import { decodeToken } from '../../../common/utils'

import { LOGIN_WITH_GOOGLE_ID_TOKEN_MUTATION } from '../../graphql/mutations'
import { GoogleLoginButton } from '../sections'

const styles = {
  loginContainer: { height: '100vh', display: 'flex' },
  loginRow: {
    justifyContent: 'center',
    alignSelf: 'center',
    display: 'flex',
    width: '100%',
  },
  loginCard: { width: '30%', textAlign: 'center' },
}

const LoginPage = () => {
  useDocumentTitle('Login')
  const { startLoading, stopLoading } = useAppLoading()
  const { setUserInfo } = useAuthentication()
  const googleAccessToken = useRef()

  const [loginWithGoogleIdToken] = useMutation(
    LOGIN_WITH_GOOGLE_ID_TOKEN_MUTATION,
    {
      onCompleted: ({
        loginWithGoogleIdToken: { accessToken, refreshToken },
      }) => {
        stopLoading()
        const user = decodeToken(accessToken)
        user.id = user.id.toString()

        sessionManager.createSession(
          user,
          accessToken,
          refreshToken,
          googleAccessToken.current,
        )

        setUserInfo(user)
      },

      onError: ({ message }) => {
        stopLoading()
        notification.open({
          message: 'Login with google failed.',
          description: message,
          type: 'error',
        })
      },
    },
  )

  const onGoogleLoginSuccess = useCallback(
    ({ tokenId, accessToken }) => {
      startLoading()
      googleAccessToken.current = accessToken
      loginWithGoogleIdToken({ variables: { googleIdToken: tokenId } })
    },
    [loginWithGoogleIdToken, startLoading],
  )

  return (
    <div style={styles.loginContainer}>
      <div style={styles.loginRow}>
        <Card style={styles.loginCard}>
          <Typography.Title level={2}>
            Curator Tool - FoodStyles
          </Typography.Title>
          <GoogleLoginButton onSuccess={onGoogleLoginSuccess} />
        </Card>
      </div>
    </div>
  )
}

export default LoginPage
