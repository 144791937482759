import { map, pick } from 'lodash'

export const transformTaskToDto = formValues =>
  pick(formValues, ['metadataType', 'metadataIds', 'startDate', 'endDate'])

export const transformTaskJsonToDto = (taskJson = {}) => {
  const { metadataType, dishes } = taskJson

  return {
    metadataType,
    dishes: map(dishes, dish => pick(dish, ['id', 'suggestedId'])),
  }
}
