import { gql } from '@apollo/client'

const SUGGEST_UNKNOWN_WORDS_MUTATION = gql`
  mutation suggestUnknownWordsMutation($brandId: ID!) {
    suggestUnknownWords(brandId: $brandId) {
      id
      pendingStatus
      status
    }
  }
`

export default SUGGEST_UNKNOWN_WORDS_MUTATION
