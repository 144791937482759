import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { capitalize, get, isEmpty, isNil, keys, map, pick } from 'lodash'
import PropTypes from 'prop-types'

import { CURATION_STATUS, EVENT_TYPES } from '../../common/constants'
import { handleMutationError } from '../../common/helpers'
import {
  useCachedBrand,
  useCurationMetadata,
  useUserHasBrandChangePermission,
} from '../../common/hooks'
import { googleAnalyticsEventsService } from '../../common/services'
import { variousTextStringTransformer } from '../../common/transformers'
import {
  mergeArrayOfObjects,
  replaceNewLineAndSpace,
  replaceTextFragments,
} from '../../common/utils'

import { BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY } from '../../brands/graphql'
import { useBrandCurationMetadata } from '../../brands/hooks'
import { DishTableEditableRowLoader } from '../components/molecules'
import { DISH_FIELDS } from '../constants'
import { DishEditableSelectsRowContext } from '../contexts'
import { UPSERT_BRAND_DISH_MUTATION } from '../graphql'
import { getAiSuggestionStepForStatus } from '../services'
import { dishTransformer } from '../transformers'

const DishEditableSelectsRowProvider = ({ rowProps }) => {
  const rowRef = useRef(null)
  const { brandId } = useParams()
  const brand = useCachedBrand()

  const { userHasBrandChangePermission, isUserQaOfBrand } =
    useUserHasBrandChangePermission(brand)

  const { dishTypeOptions, dietOptions, ingredientOptions } =
    useCurationMetadata()

  const { menuTitleOptions } = useBrandCurationMetadata()
  const [isSuggestionLoading, toggleSuggestionLoading] = useState(false)

  const { children, className, ...restProps } = rowProps

  const assignedUserType = useMemo(
    () =>
      brand.status === CURATION_STATUS.UNKNOWN_WORDS_CURATION.value
        ? CURATION_STATUS.UNKNOWN_WORDS_CURATION.value
        : CURATION_STATUS.UNKNOWN_WORDS_QA.value,
    [brand.status],
  )

  const [upsertBrandDish, { loading: isCurationSaveLoading }] = useMutation(
    UPSERT_BRAND_DISH_MUTATION,
    {
      onError: handleMutationError,
      refetchQueries: [
        {
          query: BRAND_SUGGESTED_WORDS_COMPLETENESS_COUNT_QUERY,
          variables: { brandId, assignedUserType },
        },
      ],
    },
  )

  const handleSaveCuration = useCallback(
    async (dish, modifiedDish = {}, aiSuggestions) => {
      const preparedDish = dishTransformer.transformDishToDto(
        modifiedDish,
        aiSuggestions,
        brandId,
        dish,
      )

      await upsertBrandDish({
        variables: {
          input: preparedDish,
          isDishListAction: true,
          isQaUpdate: isUserQaOfBrand && userHasBrandChangePermission,
        },
      })
    },
    [brandId, isUserQaOfBrand, upsertBrandDish, userHasBrandChangePermission],
  )

  const handleSuggestCuration = useCallback(
    async (dish, additionalInformation = {}, changes = {}) => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.SUGGEST,
        { for: brand.status },
      )
      const step = getAiSuggestionStepForStatus(
        CURATION_STATUS.CURATION_CONFIRMATION.value,
      )
      toggleSuggestionLoading(true)

      const suggestionResult = await step.suggest({
        dish,
        additionalInformation: {
          ...additionalInformation,
          menuTitleOptions,
          dietOptions,
          ingredientOptions,
          dishTypeOptions,
          brand,
        },
      })

      if (!isEmpty(suggestionResult)) {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_DISH_EVENTS.SAVE_SUGGESTIONS,
          {
            for: CURATION_STATUS.CURATION_CONFIRMATION.value,
          },
        )

        await handleSaveCuration(
          dish,
          { ...changes, ...(suggestionResult.unsavedDish || {}) },
          additionalInformation.isRefetchFromVariousText
            ? {
                ...changes.aiSuggestions,
                ...suggestionResult.aiSuggestions,
              }
            : suggestionResult.aiSuggestions,
        )
      }

      toggleSuggestionLoading(false)
    },
    [
      brand,
      dietOptions,
      dishTypeOptions,
      ingredientOptions,
      menuTitleOptions,
      handleSaveCuration,
    ],
  )

  const handleChangeCuration = useCallback(
    (dish, name, value) => {
      if (name === DISH_FIELDS.DISH_TYPE) {
        if (isNil(value)) {
          return handleSaveCuration(dish, {
            courseTypeId: dish?.courseType?.isIgnored
              ? null
              : dish?.courseType?.id,
            dishTypeId: null,
          })
        }

        if (value?.isIgnored) {
          return handleSaveCuration(dish, {
            dishTypeId: value.value,
            ...(!isNil(value.courseTypeId) && {
              courseTypeId: value.courseTypeId,
            }),
          })
        }

        return handleSuggestCuration(
          dish,
          {
            dishType: { id: value.value },
            isRefetchFromDishType: true,
            isDishIgnored: get(dish, 'dishType.isIgnored'),
          },
          {
            dishTypeId: value.value,
          },
        )
      }

      if (name === DISH_FIELDS.ALLERGENS) {
        return handleSaveCuration(dish, { allergenIds: value })
      }

      if (name === DISH_FIELDS.DIETS) {
        return handleSuggestCuration(
          dish,
          {
            dietIds: value,
            isRefetchFromDietIds: true,
          },
          {
            dietIds: value,
          },
        )
      }

      if (name === DISH_FIELDS.COURSE_TYPE) {
        if (isNil(value) && dish?.dishType?.isIgnored) {
          return handleSaveCuration(dish, {
            courseTypeId: null,
            dishTypeId: dish?.dishType?.isIgnored ? null : dish?.dishType?.id,
          })
        }

        if (value?.isIgnored) {
          return handleSaveCuration(dish, {
            courseTypeId: value.value,
            dishTypeId: value.dishTypeId,
          })
        }
        return handleSaveCuration(dish, {
          courseTypeId: isNil(value) ? null : value.value,
        })
      }

      return handleSaveCuration(dish, value)
    },
    [handleSaveCuration, handleSuggestCuration],
  )

  const handleChangeVariousText = useCallback(
    async (dish, changedDish, field) => {
      if (field === DISH_FIELDS.NAME) {
        return handleSaveCuration(dish, changedDish, {})
      }

      handleSuggestCuration(
        {
          ...dish,
          ...changedDish,
          aiSuggestions: {
            ...dish.aiSuggestions,
            ...changedDish.aiSuggestions,
          },
        },
        {
          isRefetchFromVariousText: true,
        },
        changedDish,
      )
    },
    [handleSuggestCuration, handleSaveCuration],
  )

  const handleSuggestVariousText = useCallback(
    async dish => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.SUGGEST,
        { for: brand.status },
      )
      toggleSuggestionLoading(true)
      const currentStatusStep = getAiSuggestionStepForStatus(
        CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
      )

      const suggestionResult = get(
        await currentStatusStep.suggest({
          dish,
          additionalInformation: {
            brand,
          },
        }),
        'aiSuggestions',
      )

      const variousTextPartsForDishFields = mergeArrayOfObjects(
        map(
          keys(
            pick(suggestionResult?.miscAndChoice, [
              'name',
              'description',
              'ingredientsText',
            ]),
          ),
          dishField => ({
            [dishField]: [
              ...map(
                get(suggestionResult, `miscAndChoice.${dishField}.misc`),
                ({ index }) => ({
                  word: variousTextStringTransformer.toMiscText(
                    get(
                      suggestionResult,
                      `miscAndChoice.${dishField}.text`,
                    ).substring(index[0], index[1]),
                  ),
                  index,
                }),
              ),
              ...map(
                get(suggestionResult, `miscAndChoice.${dishField}.choices`),
                ({ index }) => ({
                  word: variousTextStringTransformer.toChoiceText(
                    get(
                      suggestionResult,
                      `miscAndChoice.${dishField}.text`,
                    ).substring(index[0], index[1]),
                  ),
                  index,
                }),
              ),
            ],
          }),
        ),
      )

      const styledFields = mergeArrayOfObjects(
        map(keys(variousTextPartsForDishFields), dishField => ({
          [`styled${capitalize(dishField)}`]: `<p>${replaceNewLineAndSpace(
            replaceTextFragments(
              `${get(suggestionResult, `miscAndChoice.${dishField}.text`)}`,
              variousTextPartsForDishFields[dishField],
            ),
          )}</p>`,
        })),
      )

      if (!isEmpty(suggestionResult)) {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_DISH_EVENTS.SAVE_SUGGESTIONS,
          { for: brand.status },
        )
        handleChangeVariousText(dish, {
          ...styledFields,
          aiSuggestions: suggestionResult,
        })
      }
    },
    [brand, handleChangeVariousText],
  )

  const loading = isSuggestionLoading || isCurationSaveLoading

  return (
    <DishEditableSelectsRowContext.Provider
      value={{
        saveVariousText: handleChangeVariousText,
        saveCuration: handleChangeCuration,
        loading,
        suggestCuration: handleSuggestCuration,
        suggestVariousText: handleSuggestVariousText,
      }}
    >
      <>
        <DishTableEditableRowLoader
          loading={loading}
          height={get(rowRef, 'current.offsetHeight')}
        />
        <tr ref={rowRef} className={className} {...restProps}>
          {children}
        </tr>
      </>
    </DishEditableSelectsRowContext.Provider>
  )
}

DishEditableSelectsRowProvider.propTypes = {
  rowProps: PropTypes.object.isRequired,
}

export default DishEditableSelectsRowProvider
