import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, Typography } from 'antd'
import { has, values } from 'lodash'
import PropTypes from 'prop-types'

import { APP_ROUTES } from '../../../../common/constants'

import { BRAND_PENDING_STATUS } from '../../../constants'

const styles = {
  modal: { width: '520px' },
}

const pendingStatusMessages = {
  [BRAND_PENDING_STATUS.DUPLICATE_PENDING]: {
    title: 'Duplicating brand...',
    description: 'Please wait until the duplication of the brand finishes',
  },
  [BRAND_PENDING_STATUS.MENU_IMPORT_PENDING]: {
    title: 'Importing menu...',
    description:
      'The import of the menu is in progress. You will receive a notification when the import is finished if you are on another page.',
  },
  [BRAND_PENDING_STATUS.BATCH_CURATION_PENDING]: {
    title: 'Processing dishes...',
    description: 'Please wait until all dishes have been processed.',
  },
  [BRAND_PENDING_STATUS.MISC_AND_CHOICE_PENDING]: {
    title: 'Processing dishes...',
    description: 'Please wait until all dishes have been processed.',
  },
  [BRAND_PENDING_STATUS.SUGGEST_UNKNOWN_WORDS_PENDING]: {
    title: 'Suggesting Unknown Words...',
    description: 'Please wait until all unknown words have been suggested.',
  },
  [BRAND_PENDING_STATUS.EXPORT_WORDS_PENDING]: {
    title: 'Exporting Suggested Words...',
    description: 'Please wait until all suggested words have been exported',
  },
}

const BrandPendingModal = ({ pendingStatus }) => (
  <>
    {has(pendingStatusMessages, pendingStatus) && (
      <Modal
        centered
        closable={false}
        width={styles.modal.width}
        title={pendingStatusMessages[pendingStatus].title}
        visible
        footer={null}
      >
        <Typography.Paragraph>
          {pendingStatusMessages[pendingStatus].description}
        </Typography.Paragraph>
        <Link target="_blank" to={APP_ROUTES.BRAND_ROUTES.BrandsPrefix}>
          Go to brands page
        </Link>
      </Modal>
    )}
  </>
)

BrandPendingModal.propTypes = {
  pendingStatus: PropTypes.oneOf(values(BRAND_PENDING_STATUS)),
}

BrandPendingModal.defaultProps = {
  pendingStatus: null,
}

export default BrandPendingModal
