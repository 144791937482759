import { gql } from '@apollo/client'

import { SIMPLE_BRAND_FRAGMENT } from '../fragments'

const SIMPLE_BRAND_BY_ID_QUERY = gql`
  query simpleBrandQuery($id: ID!) {
    simpleBrand(id: $id) {
      ...SimpleBrandFragment
    }
  }
  ${SIMPLE_BRAND_FRAGMENT}
`

export default SIMPLE_BRAND_BY_ID_QUERY
