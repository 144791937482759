import { gql } from '@apollo/client'

import { LOCATION_FRAGMENT } from '../fragments'

export default gql`
  query location(
    $id: ID!
    $paginationFiltersAndSorters: DishesPaginationFiltersAndSorters
  ) {
    location(
      id: $id
      paginationFiltersAndSorters: $paginationFiltersAndSorters
    ) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`
