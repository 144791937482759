import { gql } from '@apollo/client'

import { CLEANUP_TASK_HISTORY_FRAGMENT } from '../fragments'

const CLEANUP_TASK_HISTORY_QUERY = gql`
  query cleanupTaskHistory($currentPage: Int!, $pageSize: Int!) {
    cleanupTaskHistory(currentPage: $currentPage, pageSize: $pageSize) {
      count
      tasks {
        ...CleanupTaskHistoryFragment
      }
    }
  }
  ${CLEANUP_TASK_HISTORY_FRAGMENT}
`

export default CLEANUP_TASK_HISTORY_QUERY
