import { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import { forEach, includes, isEmpty, isNil } from 'lodash'

import { AVAILABLE_HOTKEYS } from '../constants'

const CSS_CLASSES = {
  SELECTED_ROW: 'selected-row',
  SELECTED_CELL: 'selected-cell',
  BORDER_HIGHLIGHT: 'border-highlight',
}

const ARROW_KEYS = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
}

const useRowHighlight = ({
  tableSelector,
  rowsSelector,
  scrollContainerSelector,
  cellsSelector,
  disableHotkeys,
  disabledCellSelection,
  disabledRowSelection,
}) => {
  const [highlightIndex, setHighlightIndex] = useState(null)
  const [maxHighlightIndex, setMaxHighlightIndex] = useState(null)
  const [cellHighlightIndex, setCellHighlightIndex] = useState(null)
  const [maxCellHighlightIndex, setCellMaxHighlightIndex] = useState(null)
  const [selectedRowKey, setSelectedRowKey] = useState(null)
  const [selectedCellKey, setSelectedCellKey] = useState(null)
  const tableRef = useRef()
  const rowsRef = useRef()
  const scrollRef = useRef()
  const cellsRef = useRef()
  const highlightedCellRef = useRef()
  const highlightedRowRef = useRef()

  const highlightRowAtIndex = useCallback(
    rowIndex => {
      if (disabledRowSelection || isNil(rowIndex)) {
        setSelectedRowKey(null)
        return
      }

      highlightedRowRef.current?.classList.remove(CSS_CLASSES.SELECTED_ROW)
      highlightedRowRef.current?.classList.remove(CSS_CLASSES.BORDER_HIGHLIGHT)
      highlightedRowRef.current = rowsRef.current[rowIndex]
      highlightedRowRef.current?.classList.add(CSS_CLASSES.SELECTED_ROW)
      if (disabledCellSelection) {
        highlightedRowRef.current?.classList.add(CSS_CLASSES.BORDER_HIGHLIGHT)
      }

      // set default highlighted cell
      cellsRef.current =
        highlightedRowRef.current?.querySelectorAll(cellsSelector)

      setCellMaxHighlightIndex(get(cellsRef.current, 'length', 1) - 1)

      setSelectedRowKey(highlightedRowRef.current?.dataset.rowKey)
    },
    [cellsSelector, disabledCellSelection, disabledRowSelection],
  )

  const highlightCellAtIndex = useCallback(
    cellIndex => {
      if (
        disabledCellSelection ||
        disabledRowSelection ||
        isNil(cellIndex) ||
        isEmpty(cellsRef.current)
      ) {
        setSelectedCellKey(null)
        return
      }

      highlightedCellRef.current?.classList.remove(CSS_CLASSES.SELECTED_CELL)
      highlightedCellRef.current = cellsRef.current[cellIndex]
      highlightedCellRef.current?.classList.add(CSS_CLASSES.SELECTED_CELL)

      const classList = highlightedCellRef.current?.classList || []
      let key
      forEach(classList, className => {
        if (className.indexOf('field-') === 0) {
          const splits = className.split('-')
          // eslint-disable-next-line prefer-destructuring
          key = splits[1]
        }
      })

      if (key) {
        setSelectedCellKey(key)
      }
    },
    [disabledCellSelection, disabledRowSelection],
  )

  const incrementHighlightIndex = useCallback(
    (isCell = false, goToEnd = false) => {
      const maxIndex = isCell ? maxCellHighlightIndex : maxHighlightIndex
      const prevIndex = isCell ? cellHighlightIndex : highlightIndex
      let newIndex

      if (goToEnd) {
        newIndex = maxIndex
      } else {
        newIndex = prevIndex < maxIndex ? prevIndex + 1 : 0
      }

      if (isCell) {
        setCellHighlightIndex(newIndex)
      } else {
        setHighlightIndex(newIndex)
      }
    },
    [
      cellHighlightIndex,
      highlightIndex,
      maxCellHighlightIndex,
      maxHighlightIndex,
    ],
  )

  const decrementHighlightIndex = useCallback(
    (isCell = false, goToStart = false) => {
      const maxIndex = isCell ? maxCellHighlightIndex : maxHighlightIndex
      const prevIndex = isCell ? cellHighlightIndex : highlightIndex
      let newIndex

      if (goToStart) {
        newIndex = 0
      } else {
        newIndex = prevIndex > 0 ? prevIndex - 1 : maxIndex
      }

      if (isCell) {
        setCellHighlightIndex(newIndex)
      } else {
        setHighlightIndex(newIndex)
      }
    },
    [
      cellHighlightIndex,
      highlightIndex,
      maxCellHighlightIndex,
      maxHighlightIndex,
    ],
  )

  const shouldIgnoreArrowEvent = useCallback(
    event =>
      disableHotkeys ||
      event.target instanceof HTMLInputElement ||
      disabledRowSelection ||
      (includes([ARROW_KEYS.LEFT, ARROW_KEYS.RIGHT], event.key) &&
        disabledCellSelection),
    [disableHotkeys, disabledRowSelection, disabledCellSelection],
  )

  useHotkeys(
    [
      AVAILABLE_HOTKEYS.UP.hotkey,
      AVAILABLE_HOTKEYS.DOWN.hotkey,
      AVAILABLE_HOTKEYS.LEFT.hotkey,
      AVAILABLE_HOTKEYS.RIGHT.hotkey,
      AVAILABLE_HOTKEYS.SHIFT_UP.hotkey,
      AVAILABLE_HOTKEYS.SHIFT_DOWN.hotkey,
      AVAILABLE_HOTKEYS.SHIFT_LEFT.hotkey,
      AVAILABLE_HOTKEYS.SHIFT_RIGHT.hotkey,
    ].join(','),
    event => {
      if (shouldIgnoreArrowEvent(event)) {
        return
      }

      if (event.key === ARROW_KEYS.UP) {
        decrementHighlightIndex(false, event.shiftKey)
      }
      if (event.key === ARROW_KEYS.DOWN) {
        incrementHighlightIndex(false, event.shiftKey)
      }
      if (event.key === ARROW_KEYS.LEFT) {
        decrementHighlightIndex(true, event.shiftKey)
      }
      if (event.key === ARROW_KEYS.RIGHT) {
        incrementHighlightIndex(true, event.shiftKey)
      }

      if (!isEmpty(rowsRef.current)) {
        event.preventDefault()
      }
    },
    [
      shouldIgnoreArrowEvent,
      decrementHighlightIndex,
      incrementHighlightIndex,
      disabledRowSelection,
    ],
  )

  useEffect(() => {
    if (isEmpty(rowsRef.current) || isNil(highlightIndex)) {
      return
    }

    tableRef.current?.scrollIntoView()
    const currentRow = rowsRef.current[highlightIndex]
    const containerHeight = scrollRef.current?.offsetHeight
    const containerWidth = scrollRef.current?.offsetWidth

    let offsetPositionX
    if (!isEmpty(cellsRef.current) && !isNil(cellHighlightIndex)) {
      const currentCell = cellsRef.current[cellHighlightIndex]
      offsetPositionX = currentCell.offsetLeft - containerWidth / 2
    }
    const offsetPositionY = currentRow.offsetTop - containerHeight / 2

    scrollRef.current?.scrollTo(offsetPositionX, offsetPositionY)
  }, [highlightIndex, rowsRef, cellsRef, cellHighlightIndex])

  const initHighlight = useCallback(() => {
    tableRef.current = document.querySelector(tableSelector)
    ;[scrollRef.current] = document.querySelectorAll(scrollContainerSelector)
    rowsRef.current = document.querySelectorAll(rowsSelector)
    setMaxHighlightIndex(rowsRef.current.length - 1)

    if (!disabledRowSelection) {
      setHighlightIndex(0)
      highlightRowAtIndex(0)

      if (!disabledCellSelection) {
        setCellHighlightIndex(0)
        highlightCellAtIndex(0)
      }
    }
  }, [
    disabledCellSelection,
    disabledRowSelection,
    highlightCellAtIndex,
    highlightRowAtIndex,
    rowsSelector,
    scrollContainerSelector,
    tableSelector,
  ])

  useEffect(() => {
    highlightRowAtIndex(highlightIndex)
  }, [highlightIndex, highlightRowAtIndex])

  useEffect(() => {
    highlightCellAtIndex(cellHighlightIndex)
  }, [highlightIndex, cellHighlightIndex, highlightCellAtIndex])

  useEffect(() => {
    if (disabledCellSelection || disabledRowSelection) {
      setCellHighlightIndex(null)

      if (!isEmpty(highlightedCellRef.current)) {
        highlightedCellRef.current.classList.remove(CSS_CLASSES.SELECTED_CELL)
      }
    }
  }, [disabledCellSelection, disabledRowSelection, highlightedCellRef])

  useEffect(() => {
    if (disabledRowSelection) {
      setHighlightIndex(null)

      if (!isEmpty(highlightedRowRef.current)) {
        highlightedRowRef.current.classList.remove(CSS_CLASSES.SELECTED_ROW)
        highlightedRowRef.current.classList.remove(CSS_CLASSES.BORDER_HIGHLIGHT)
      }
    }
  }, [disabledRowSelection, highlightedRowRef])

  return {
    highlightIndex,
    initHighlight,
    selectedRowKey,
    selectedCellKey,
  }
}

export default useRowHighlight
