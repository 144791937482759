import { SHORT_WEEKDAYS } from '../constants'

export default function formatServicingHour({
  id,
  menu,
  startDayOfWeek,
  endDayOfWeek,
}) {
  return {
    id,
    name: `${menu} ${SHORT_WEEKDAYS[startDayOfWeek]}-${SHORT_WEEKDAYS[endDayOfWeek]}`,
  }
}
