import { gql } from '@apollo/client'

const QA_DISH_CORRECTION_COUNTS_FRAMGNET = gql`
  fragment QaDishCorrectionCountsFragment on QaDishCorrectionCounts {
    styledName
    styledDescription
    styledIngredientsText
    dishTypeId
    courseTypeId
    mainIngredientIds
    additionalIngredientIds
    choiceIngredientIds
    dietIds
    allergenIds
  }
`

export default QA_DISH_CORRECTION_COUNTS_FRAMGNET
