import React, { useMemo } from 'react'
import { get, isNil, keys, map, sortBy } from 'lodash'
import PropTypes from 'prop-types'

import { mergeArrayOfObjects } from '../../../../common/utils'

import { DISH_FIELDS } from '../../../constants'
import { dishIngredientForDisplayTransformer } from '../../../transformers'
import { DishAiHighlightingFieldValue } from '../DishAiHighlightingFieldValue'

const DishTableIngredientsCell = ({ ingredients }) => {
  const sortedIngredients = useMemo(
    () =>
      mergeArrayOfObjects(
        map(keys(ingredients), field => ({
          [field]: sortBy(
            map(
              get(ingredients, field),
              field === DISH_FIELDS.ADDITIONAL_INGREDIENTS
                ? dishIngredientForDisplayTransformer.transformAdditionIngredientForDisplay
                : dishIngredientForDisplayTransformer.transformMainOrChoiceIngredientForDisplay,
            ),
            'priority',
          ),
        })),
      ),
    [ingredients],
  )

  return map(keys(sortedIngredients), field => (
    <div key={field}>
      {map(
        sortedIngredients[field],
        (
          { id: ingredientId, name, highlight, isDiscrepancyAccepted },
          currentIndex,
        ) => (
          <DishAiHighlightingFieldValue
            key={ingredientId}
            highlight={highlight}
            strikethroughIngredient={
              !isNil(isDiscrepancyAccepted) && !isDiscrepancyAccepted
            }
            useTrailingSeparator={
              currentIndex !== sortedIngredients[field].length - 1
            }
            separator=" ; "
          >
            {name}
          </DishAiHighlightingFieldValue>
        ),
      )}
    </div>
  ))
}

DishTableIngredientsCell.propTypes = {
  ingredients: PropTypes.object.isRequired,
}

export default DishTableIngredientsCell
