import React from 'react'
import { notification, Typography } from 'antd'
import { get, isEmpty, map } from 'lodash'

import { suggestionEndpoints } from '../../common/constants'
import { getAiSuggestions } from '../../common/utils'

import { dishLabelSuggestionTransformer } from '../transformers'

/**
 * Calls the AI servicing for suggesting diet label.
 * It should:
 *   - fetch suggestions
 *
 * Returns a promise.
 */
export default async function suggestLabels({ dish, additionalInformation }) {
  try {
    // this endpoint gives us suggestions for multiple dishes, that's why we destruct here
    const [suggestions] = await getAiSuggestions(suggestionEndpoints.labels, [
      dishLabelSuggestionTransformer.transformDishToAiRequestDto(
        dish,
        additionalInformation,
      ),
    ])

    const aiMessages = get(suggestions, 'messages')
    if (!isEmpty(aiMessages)) {
      notification.error({
        message: 'An error occured',
        description: map(aiMessages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
      })

      return null
    }

    const dietIds = map(suggestions.diets.predictions, ({ id: dietId }) =>
      dietId.toString(),
    )

    const allergenIds = map(
      suggestions.allergens.predictions,
      ({ id: allergenId }) => allergenId.toString(),
    )

    return {
      dietLabelSuggestions: {
        aiSuggestions: suggestions.diets,
        dietIds,
      },
      allergenLabelSuggestions: {
        aiSuggestions: suggestions.allergens,
        allergenIds,
      },
    }
  } catch (e) {
    notification.error({
      message: 'An error occured while suggesting labels',
      description: e.message,
      placement: 'topLeft',
    })
    return null
  }
}
