// TODO move these into the diets page to be added by ops

const DIET_ABBREVIATIONS = Object.freeze({
  vegan: 'VgN',
  vegetarian: 'VgT',
  'dairy-free': 'DF',
  'gluten-free': 'GF',
  'egg-free': 'EF',
  'soy-free': 'SoF',
  'grain-free': 'GrF',
  'sugar-free': 'SuF',
  'wheat-free': 'WF',
  'plant-based': 'PB',
  'nut-free': 'NF',
})

export default DIET_ABBREVIATIONS
