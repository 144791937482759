import React, { memo, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Table } from 'antd'
import { get, includes, isEmpty, isNil, keys, map, omit, some } from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector, useWindowSize } from '../../../../core/hooks'

import { CURATION_STATUS, PERMISSIONS } from '../../../../common/constants'
import {
  useAuthentication,
  useCurationMetadata,
  useMlIngredientsEnabled,
  useUserHasBrandChangePermission,
} from '../../../../common/hooks'

import { useBrandCurationMetadata } from '../../../../brands/hooks'
import {
  DISH_FIELD_SUGGESTION_PATH,
  DISH_FIELDS,
  DISH_TABLE_SCROLL_VALUES,
} from '../../../constants'
import { useDishFields } from '../../../hooks'
import { dishPropType } from '../../../propTypes'
import { DishEditableSelectsRow } from '../DishEditableSelectsRow'

import createDishTableColumns from './createDishTableColumns'

import './dishTable.css'

const DishTable = ({
  dishes,
  dishesHighlightWords,
  brandStatus,
  refetchBrand,
  dishIsStatusSameAsBrandFilter,
  dishHasErrorsFilter,
  loading,
}) => {
  const { height } = useWindowSize()
  const { dishFields } = useDishFields(
    brandStatus,
    dishIsStatusSameAsBrandFilter,
    dishHasErrorsFilter,
    dishes,
  )
  const { dishTypeOptions, courseTypeOptions } = useCurationMetadata()
  const { menuTitleOptions } = useBrandCurationMetadata()
  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const areMlIngredientsEnabled = useMlIngredientsEnabled()

  const {
    state: { isDirty: isMenuTitlesDirty },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const {
    formState: { isDirty },
  } = useFormContext()

  const {
    userInfo: { permissions },
  } = useAuthentication()

  const shouldShowButtonBasedOnPermission = useMemo(
    () => includes(permissions, PERMISSIONS.DISHES.UPSERT_CURATION),
    [permissions],
  )

  const {
    dishIds,
    dishesOnPageValidated,
    dishesOnPageValidatedWithQaDone,
    dishesOnPageValidatedWithQqaDone,
  } = useMemo(
    () => ({
      dishIds: map(dishes, 'id'),
      dishesOnPageValidated: !some(dishes, dish => !dish.isValidated),
      dishesOnPageValidatedWithQaDone: !some(dishes, dish => !dish.isQaDone),
      dishesOnPageValidatedWithQqaDone: !some(dishes, dish => !dish.isQqaDone),
    }),
    [dishes],
  )

  const columns = useMemo(
    () =>
      createDishTableColumns(
        dishFields,
        brandStatus,
        shouldShowButtonBasedOnPermission,
        dishIsStatusSameAsBrandFilter,
        dishHasErrorsFilter,
        refetchBrand,
        dishTypeOptions,
        courseTypeOptions,
        menuTitleOptions,
        dishesHighlightWords,
        isDirty,
        isMenuTitlesDirty,
        dishIds,
        dishesOnPageValidated,
        dishesOnPageValidatedWithQaDone,
        dishesOnPageValidatedWithQqaDone,
        userHasBrandChangePermission,
        areMlIngredientsEnabled,
      ),
    [
      dishFields,
      brandStatus,
      shouldShowButtonBasedOnPermission,
      dishIsStatusSameAsBrandFilter,
      dishHasErrorsFilter,
      refetchBrand,
      dishTypeOptions,
      courseTypeOptions,
      menuTitleOptions,
      dishesHighlightWords,
      isDirty,
      isMenuTitlesDirty,
      dishIds,
      dishesOnPageValidated,
      dishesOnPageValidatedWithQaDone,
      dishesOnPageValidatedWithQqaDone,
      userHasBrandChangePermission,
      areMlIngredientsEnabled,
    ],
  )

  const components = useMemo(
    () => ({
      body: {
        row: DishEditableSelectsRow,
      },
    }),
    [],
  )

  const getRowClassName = useCallback(dish => {
    const hasMessages = some(
      keys(
        omit(DISH_FIELD_SUGGESTION_PATH, [
          DISH_FIELDS.NAME,
          DISH_FIELDS.DESCRIPTION,
          DISH_FIELDS.INGREDIENTS_TEXT,
        ]),
      ),
      // we need to test against null as well and has only checks if the field is undefined
      fieldKey =>
        !isEmpty(
          get(dish, `${DISH_FIELD_SUGGESTION_PATH[fieldKey]}.messages`),
        ) ||
        !isEmpty(
          get(dish, `${DISH_FIELD_SUGGESTION_PATH[fieldKey]}[0].messages`),
        ),
    )

    if (hasMessages) {
      return `error-row`
    }

    if (get(dish, 'courseType.isIgnored') || get(dish, 'dishType.isIgnored')) {
      return `ignored-row`
    }

    if (
      includes(
        [
          CURATION_STATUS.CURATION_CONFIRMATION.value,
          CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
        ],
        dish.status,
      )
    ) {
      return `curation-row`
    }

    if (dish.status !== CURATION_STATUS.DONE.value) {
      return `uncurated-row`
    }

    return `curated-row`
  }, [])
  return (
    <Table
      loading={loading}
      components={components}
      dataSource={dishes}
      rowKey="id"
      size="small"
      className="dishes-table"
      rowClassName={getRowClassName}
      // for scroll y we compute the maximum space the dish table
      // can fill by substracting the height of the header, table header and pagination
      scroll={{
        y: isNil(height)
          ? DISH_TABLE_SCROLL_VALUES.HEIGHT
          : height - DISH_TABLE_SCROLL_VALUES.HEIGHT,
      }}
      sticky
      columns={columns}
      pagination={false}
    />
  )
}

DishTable.propTypes = {
  dishes: PropTypes.arrayOf(dishPropType),
  dishesHighlightWords: PropTypes.object,
  refetchBrand: PropTypes.func,
  brandStatus: PropTypes.string.isRequired,
  dishIsStatusSameAsBrandFilter: PropTypes.bool,
  dishHasErrorsFilter: PropTypes.bool,
  loading: PropTypes.bool,
}

DishTable.defaultProps = {
  dishes: [],
  dishesHighlightWords: {},
  refetchBrand: undefined,
  dishIsStatusSameAsBrandFilter: false,
  loading: false,
  dishHasErrorsFilter: false,
}

export default memo(DishTable)
