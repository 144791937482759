import { gql } from '@apollo/client'

const SET_DISH_QQA_DONE_MUTATION = gql`
  mutation setDishQqaDone($ids: [ID]!, $value: Boolean!) {
    setDishQqaDone(ids: $ids, value: $value) {
      id
      isQqaDone
    }
  }
`

export default SET_DISH_QQA_DONE_MUTATION
