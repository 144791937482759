import React, { useMemo } from 'react'
import { Divider, Row } from 'antd'
import PropTypes from 'prop-types'

import { idNew } from '../../../../common/constants'
import { useUserHasBrandChangePermission } from '../../../../common/hooks'

import { brandPropType } from '../../../propTypes'
import { BrandServicingHours } from '../../molecules'
import { BrandAssignation } from '../BrandAssignation'
import { BrandCuration } from '../BrandCuration'
import { BrandLabelLegends } from '../BrandLabelLegends'
import { BrandMenuInfoDietsAndLanguages } from '../BrandMenuInfoDietsAndLanguages'
import { BrandModificationDates } from '../BrandModificationDates'
import { BrandTags } from '../BrandTags'

import './BrandEntry.css'

const styles = {
  container: {
    padding: '0.5rem 0',
  },
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}

const BrandEntry = ({ brand, users, qaCorrectionCounts }) => {
  const { userHasBrandChangePermission } = useUserHasBrandChangePermission()

  const isDisabled = useMemo(
    () => (userHasBrandChangePermission ? false : brand.id !== idNew),
    [brand.id, userHasBrandChangePermission],
  )

  const isUpdating = useMemo(() => brand.id !== idNew, [brand.id])

  return (
    <div className="brand-entry" style={styles.container}>
      <BrandCuration
        brand={brand}
        isDisabled={isDisabled}
        isUpdating={isUpdating}
        qaCorrectionCounts={qaCorrectionCounts}
      />
      <Divider style={styles.divider} />
      <BrandTags brand={brand} />
      <Divider style={styles.divider} />

      <BrandMenuInfoDietsAndLanguages
        brand={brand}
        isDisabled={isDisabled}
        qaCorrectionCounts={qaCorrectionCounts}
      />

      {isUpdating && (
        <>
          <Divider style={styles.divider} />
          <BrandModificationDates brand={brand} isDisabled={isDisabled} />
          <Divider style={styles.divider} />
          <BrandAssignation
            brand={brand}
            users={users}
            isDisabled={isDisabled}
          />
        </>
      )}
      <Divider style={styles.divider} />
      <Row gutter={16}>
        <BrandServicingHours
          servicingHours={brand.servicingHours}
          isDisabled={isDisabled}
        />
        <BrandLabelLegends
          brand={brand}
          isDisabled={isDisabled}
          qaCorrectionCounts={qaCorrectionCounts}
        />
      </Row>
    </div>
  )
}

BrandEntry.propTypes = {
  brand: brandPropType.isRequired,
  qaCorrectionCounts: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
}

BrandEntry.defaultProps = {
  users: [],
  qaCorrectionCounts: undefined,
}

export default BrandEntry
