const BASE_EVENTS = Object.freeze({
  LOG_IN: 'log_in',
  LOG_OUT: 'log_out',
  PAGE_VIEW: 'page_view',
})

const USER_EVENTS = Object.freeze({
  NEW_CLICK: 'user_new_click',
  INPUT_CHANGE: 'user_input_change',
  SAVE_CLICK: 'user_save_click',
  CANCEL_SAVE: 'user_save_cancel_click',
  DELETE_CLICK: 'user_delete_click',
})

const BRAND_EVENTS = Object.freeze({
  NEW_CLICK: 'brand_new_click',
  SAVE_CLICK: 'brand_save',
  INPUT_CHANGE: 'brand_input_change',
  CANCEL_SAVE: 'brand_save_cancel',
  EXTERNAL_URL_CLICK: 'brand_external_url_click',
  DELETE_BRAND_CLICK: 'brand_delete_click',
  MENU_TITLES_CHANGE: 'brand_menu_titles_change',
  SUGGEST_LEGENDS_CLICK: 'suggest_legends_click',
  SUGGEST_CUISINE_TYPE_CLICK: 'suggest_cuisine_type',
  SUGGEST_LANGUAGES_CLICK: 'suggest_languages',
})

const BRAND_DISH_EVENTS = Object.freeze({
  FILTER_CHANGE: 'filter_dishes',
  PUBLISH_CLICK: 'brand_dish_publish',
  MODAL_OPEN: 'brand_dish_modal_open',
  MODAL_CLOSE: 'brand_dish_modal_close',
  INPUT_CHANGE: 'brand_dish_input_change',
  MODAL_NAVIGATE_BUTTON_CLICK: 'brand_dish_modal_navigate_button_click',
  MODAL_NAVIGATE_SELECT_CLICK: 'brand_dish_modal_navigate_select_click',
  ADD_CLICK: 'brand_dish_add',
  DELETE_CLICK: 'brand_dish_delete_click',
  DELETE_CURATION_DATA_CLICK: 'brand_dish_delete_curation_delete_click',
  DELETE_CONFIRM: 'brand_dish_delete_confirm',
  DELETE_CURATION_DATA_CONFIRM: 'brand_dish_delete_curation_data_confirm',
  DELETE_CANCEL: 'brand_dish_delete_cancel',
  DELETE_CURATION_DATA_CANCEL: 'brand_dish_delete_curation_data_cancel',
  SAVE_CLICK: 'brand_dish_save_click',
  SAVE_HOTKEYS: 'brand_dish_save_hotkeys',
  REMOVE_ALL_INGREDIENTS: 'brand_dish_all_ingredients_remove',
  SUGGEST: 'brand_dish_suggest',
  SAVE_SUGGESTIONS: 'brand_dish_suggest_save',
  SUGGEST_SKIP: 'brand_dish_suggest_skip',
  NO_SUGGESTIONS: 'brand_dish_no_suggestions',
  DUPLICATE_DISH_CLICK: 'brand_dish_duplicate_click',
})

const BRAND_LOCATION_EVENTS = Object.freeze({
  CREATE_CLICK: 'brand_location_create_click',
  SAVE_CLICK: 'brand_location_save_click',
  CANCEL_SAVE: 'brand_location_save_cancel',
  INPUT_CHANGE: 'brand_location_input_change',
  DELETE_CLICK: 'brand_location_delete_click',
})

const EVENT_TYPES = {
  BASE_EVENTS,
  USER_EVENTS,
  BRAND_EVENTS,
  BRAND_DISH_EVENTS,
  BRAND_LOCATION_EVENTS,
}

export default EVENT_TYPES
