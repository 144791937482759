import { gql } from '@apollo/client'

import { LOCATION_DISH_FRAGMENT } from '../fragments'

export default gql`
  mutation upsertLocationDish($input: UpsertLocationDishInput!) {
    upsertLocationDish(input: $input) {
      ...LocationDishFragment
    }
  }
  ${LOCATION_DISH_FRAGMENT}
`
