const AI_INGREDIENT_MATCH_TYPE = Object.freeze({
  EXACT: 'exact',
  NEAR: 'near',
  SYNONYM_LEVEL_1: 'synonym_level_1',
  SYNONYM_LEVEL_2: 'synonym_level_2',
  SYNONYM_LEVEL_3: 'synonym_level_3',
  SYNONYM: 'synonym',
  VARIETY_LEVEL_1: 'variety_level_1',
  VARIETY_LEVEL_2: 'variety_level_2',
  VARIETY_LEVEL_3: 'variety_level_3',
  VARIETY: 'variety',
  ALREADY_FOUND: 'already_found',
  NEGATIVE_SYNONYM: 'negativeSynonym',
  COMMON_NEGATIVE_SYNONYM: 'commonNegativeSynonym',
  OVERRIDES: 'overrides',
  PAIRING_PHRASE: 'pairingPhrase',
  MIDDLE_PAIRING_PHRASE: 'middlePairingPhrase',
  REPLACEMENT: 'replacement',
  REFERENCING_WORD: 'referencingWord',
  MIDDLE_REFERENCING_WORD: 'middleReferencingWord',
  ML_SUGGESTED: 'mlSuggested',
})

export default AI_INGREDIENT_MATCH_TYPE
