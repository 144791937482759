import { gql } from '@apollo/client'

const DEPARTMENTS_QUERY = gql`
  query departments {
    departments {
      id
      value
      label
    }
  }
`

export default DEPARTMENTS_QUERY
