import React, { useMemo } from 'react'
import { Col, Row, Typography } from 'antd'
import { includes, map, values } from 'lodash'
import PropTypes from 'prop-types'

import { Select } from '../../../../core/components'

import { CURATION_STATUS, PERMISSIONS } from '../../../../common/constants'
import {
  useUserHasBrandChangePermission,
  useUserHasPermissions,
} from '../../../../common/hooks'

import {
  BRAND_ASSIGNED_USER_ACTIONS,
  BRAND_QA_DETAIL_LEVELS,
} from '../../../constants'
import { brandPropType } from '../../../propTypes'
import { brandTransformer } from '../../../transformers'
import {
  BrandQaCurationAttentionButton,
  BrandUserAssignment,
} from '../../molecules'

const BrandAssignation = ({ brand, users, isDisabled }) => {
  const userCanSetQaDetailLevel = useUserHasPermissions([
    PERMISSIONS.BRANDS.SET_QA_DETAIL_LEVEL,
  ])

  const { isUserCuratorOfBrand, isUserQaOfBrand } =
    useUserHasBrandChangePermission()

  const {
    id,
    status,
    assignedUnknownWordsCurator,
    assignedUnknownWordsQa,
    assignedCurator,
    assignedQa,
    assignedQQa,
    requiresQaAttention,
    requiresCuratorAttention,
    qaDetailLevel,
  } = useMemo(() => brandTransformer.toBrandAssignation(brand), [brand])

  const alreadyAssignedUsersDuringUnknownWordsCuration = useMemo(
    () => [assignedUnknownWordsQa],
    [assignedUnknownWordsQa],
  )

  const alreadyAssignedUsersDuringUnknownWordsQa = useMemo(
    () => [assignedUnknownWordsCurator],
    [assignedUnknownWordsCurator],
  )

  const alreadyAssignedUsersDuringCuration = useMemo(
    () => [assignedQQa, assignedQa],
    [assignedQQa, assignedQa],
  )

  const alreadyAssignedUsersDuringQa = useMemo(
    () => [assignedQQa, assignedCurator],
    [assignedQQa, assignedCurator],
  )

  const alreadyAssignedUsersDuringQQa = useMemo(
    () => [assignedCurator, assignedQa],
    [assignedCurator, assignedQa],
  )

  const isQaDetailLevelDisabled = useMemo(
    () =>
      isDisabled ||
      status !== CURATION_STATUS.QA.value ||
      !userCanSetQaDetailLevel,
    [isDisabled, status, userCanSetQaDetailLevel],
  )

  const qaDetailLevelOptions = useMemo(
    () =>
      map(values(BRAND_QA_DETAIL_LEVELS), level => ({
        label: level,
        value: level,
      })),
    [],
  )

  const isUnknownWordsAssignmentShown = useMemo(
    () =>
      includes(
        [
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
        ],
        status,
      ),
    [status],
  )

  const isQqaAssignmentShown = useMemo(
    () => status === CURATION_STATUS.QQA_CONFIRMATION.value,
    [status],
  )

  return (
    <Row gutter={16}>
      {isUnknownWordsAssignmentShown && (
        <>
          <Col span={4}>
            <Row>
              <Col span={24}>
                <Typography.Paragraph>
                  Assigned U.W. Curator
                </Typography.Paragraph>
              </Col>
              <BrandUserAssignment
                brandId={id}
                assignedUser={assignedUnknownWordsCurator}
                alreadyAssignedUsers={
                  alreadyAssignedUsersDuringUnknownWordsCuration
                }
                availableUsers={users}
                assignedUserAction={
                  BRAND_ASSIGNED_USER_ACTIONS.UNKNOWN_WORDS_CURATION
                }
              />
            </Row>
          </Col>
          <Col span={4}>
            <Row>
              <Col span={24}>
                <Typography.Paragraph>Assigned U.W. QA</Typography.Paragraph>
              </Col>
              <BrandUserAssignment
                brandId={id}
                assignedUser={assignedUnknownWordsQa}
                alreadyAssignedUsers={alreadyAssignedUsersDuringUnknownWordsQa}
                availableUsers={users}
                assignedUserAction={
                  BRAND_ASSIGNED_USER_ACTIONS.UNKNOWN_WORDS_QA
                }
              />
            </Row>
          </Col>
        </>
      )}
      <Col span={4}>
        <Row>
          <Col span={24}>
            <Typography.Paragraph>Assigned Curator</Typography.Paragraph>
          </Col>
          <BrandUserAssignment
            brandId={id}
            assignedUser={assignedCurator}
            alreadyAssignedUsers={alreadyAssignedUsersDuringCuration}
            availableUsers={users}
            assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.CURATION}
          />
        </Row>
      </Col>
      <Col span={4}>
        <Row>
          <Col span={24}>
            <Typography.Paragraph>Assigned QA</Typography.Paragraph>
          </Col>
          <BrandUserAssignment
            brandId={id}
            assignedUser={assignedQa}
            alreadyAssignedUsers={alreadyAssignedUsersDuringQa}
            availableUsers={users}
            assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.QA}
          />
        </Row>
      </Col>
      {isQqaAssignmentShown && (
        <Col span={4}>
          <Row>
            <Col span={24}>
              <Typography.Paragraph>Assigned QQA</Typography.Paragraph>
            </Col>
            <BrandUserAssignment
              brandId={id}
              assignedUser={assignedQQa}
              alreadyAssignedUsers={alreadyAssignedUsersDuringQQa}
              availableUsers={users}
              assignedUserAction={BRAND_ASSIGNED_USER_ACTIONS.QQA}
            />
          </Row>
        </Col>
      )}
      <Select
        span={4}
        labelAbove
        label="QA detail level"
        defaultValue={qaDetailLevel}
        allowClear
        name="qaDetailLevel"
        options={qaDetailLevelOptions}
        placeholder="Select a qa detail level..."
        disabled={isQaDetailLevelDisabled}
      />
      <Col span={4}>
        {isUserQaOfBrand && (
          <BrandQaCurationAttentionButton
            requiresQaAttention={requiresQaAttention}
            requiresCuratorAttention={requiresCuratorAttention}
            brandId={id}
            action={BRAND_ASSIGNED_USER_ACTIONS.QA}
          />
        )}
        {isUserCuratorOfBrand && (
          <BrandQaCurationAttentionButton
            requiresCuratorAttention={requiresCuratorAttention}
            requiresQaAttention={requiresQaAttention}
            brandId={id}
            action={BRAND_ASSIGNED_USER_ACTIONS.CURATION}
          />
        )}
      </Col>
    </Row>
  )
}

BrandAssignation.propTypes = {
  brand: brandPropType.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
}

BrandAssignation.defaultProps = {
  users: [],
  isDisabled: false,
}

export default BrandAssignation
