import React from 'react'
import PropTypes from 'prop-types'

import styles from './originalTextDecoratorStyles'

const OriginalTextDecorator = ({ children }) => (
  <span style={styles.originalText}>{children}</span>
)

OriginalTextDecorator.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export default OriginalTextDecorator
