import { gql } from '@apollo/client'

const UPDATE_CLEANUP_TASK_CURATION_DONE_MUTATION = gql`
  mutation updateCleanupTaskCurationDone(
    $id: ID!
    $metadataType: String!
    $value: Boolean!
  ) {
    updateCleanupTaskCurationDone(
      id: $id
      metadataType: $metadataType
      value: $value
    ) {
      id
      isCurationDone
    }
  }
`

export default UPDATE_CLEANUP_TASK_CURATION_DONE_MUTATION
