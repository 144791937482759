import { gql } from '@apollo/client'

const SUGGESTED_WORDS_SUBSCRIPTION = gql`
  subscription onSuggestedUnknownWords {
    suggestedUnknownWords {
      brandId
      brandName
      jobStatus
      reason
    }
  }
`

export default SUGGESTED_WORDS_SUBSCRIPTION
