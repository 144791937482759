import { gql } from '@apollo/client'

export default gql`
  fragment ServicingHourFragment on ServicingHour {
    id
    menu
    startDayOfWeek
    endDayOfWeek
    startTime
    endTime
  }
`
