import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

const styles = {
  readMoreContainer: {
    overflow: 'hidden',
  },
}

const ReadMore = ({ maxHeight, component, maxWidth }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [height, setHeight] = useState(0)
  const componentRef = useRef(null)

  const readMoreContainerStyle = useMemo(
    () => ({
      ...styles.readMoreContainer,
      maxHeight: isVisible ? '100%' : maxHeight,
    }),
    [isVisible, maxHeight],
  )

  const handleToggleVisibility = useCallback(
    e => {
      setIsVisible(!isVisible)
      e.stopPropagation()
    },
    [isVisible],
  )

  // we need to use a state for the height because on re-render the ref loses its value and when it gets it from the div
  // it doesn't trigger a re-render
  useEffect(() => {
    setHeight(get(componentRef, 'current.offsetHeight'))
  }, [])

  return (
    <div style={maxWidth ? { maxWidth } : undefined}>
      <div style={readMoreContainerStyle}>
        <div ref={componentRef}>{component}</div>
      </div>
      {height > maxHeight && (
        <Button type="link" onClick={handleToggleVisibility}>
          {isVisible ? 'Read less' : 'Read more'}
        </Button>
      )}
    </div>
  )
}

ReadMore.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  component: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
}

ReadMore.defaultProps = {
  maxWidth: undefined,
}

export default ReadMore
