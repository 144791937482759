import { gql } from '@apollo/client'

const EXPORTED_BRAND_SUGGESTED_WORDS_SUBSCRIPTION = gql`
  subscription onExportedBrandSuggestedWords {
    exportedBrandSuggestedWords {
      brandId
      brandName
      jobStatus
      reason
    }
  }
`

export default EXPORTED_BRAND_SUGGESTED_WORDS_SUBSCRIPTION
