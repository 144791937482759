import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Button, Typography } from 'antd'
import update from 'immutability-helper'
import {
  filter,
  findIndex,
  head,
  includes,
  isEmpty,
  keys,
  map,
  reduce,
} from 'lodash'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { getFilterOptions } from '../../../../common/helpers'

import { USER_COMPETENCY_MAP, USER_COMPETENCY_TYPE } from '../../../constants'
import { CompetencyRow } from '../CompetencyRow'

const createDefaultCompetencyRow = competencyOptions => ({
  id: shortid.generate(),
  created: true,
  competencyId: head(competencyOptions)?.value,
  isActive: true,
  type: head(keys(USER_COMPETENCY_TYPE)),
})

const UserCompetencies = ({
  selectedDepartments,
  userCompetencies,
  competencies,
  disabled,
}) => {
  const { control } = useFormContext()

  const competencyOptions = useMemo(
    () =>
      reduce(
        selectedDepartments,
        (result, departmentValue) => [
          ...result,
          ...getFilterOptions(
            filter(competencies, ({ value }) =>
              includes(USER_COMPETENCY_MAP[departmentValue], value),
            ),
            'label',
            'id',
          ),
        ],
        [],
      ),

    [competencies, selectedDepartments],
  )

  const isAddDisabled = useMemo(
    () => disabled || isEmpty(competencyOptions),
    [competencyOptions, disabled],
  )

  const handleChange = useCallback((onChange, existingValue, newValue) => {
    const { id, created, deleted } = newValue
    const index = findIndex(
      existingValue,
      ({ id: existingId }) => existingId === id,
    )

    let updatedCompetencies
    if (created && deleted) {
      updatedCompetencies = update(existingValue, {
        $splice: [[index, 1]],
      })
    } else {
      updatedCompetencies = update(existingValue, {
        [index]: {
          $set: { ...newValue, ...(!newValue.created && { updated: true }) },
        },
      })
    }

    onChange(updatedCompetencies)
  }, [])

  const handleAdd = useCallback(
    (onChange, existingValue) =>
      onChange([
        ...existingValue,
        createDefaultCompetencyRow(competencyOptions),
      ]),
    [competencyOptions],
  )

  return (
    <Controller
      control={control}
      defaultValue={userCompetencies}
      name="competencies"
      render={({ field: { onChange, value } }) => (
        <>
          <Typography.Paragraph>Competencies</Typography.Paragraph>

          {map(
            filter(value, ({ deleted }) => !deleted),
            competency => (
              <CompetencyRow
                key={competency.id}
                competency={competency}
                competencyOptions={competencyOptions}
                onValueChanged={newValue =>
                  handleChange(onChange, value, newValue)
                }
                disabled={disabled}
              />
            ),
          )}

          <Button
            onClick={() => handleAdd(onChange, value)}
            disabled={isAddDisabled}
          >
            Add competencies
          </Button>
        </>
      )}
    />
  )
}

UserCompetencies.propTypes = {
  competencies: PropTypes.arrayOf(PropTypes.object),
  userCompetencies: PropTypes.arrayOf(PropTypes.object),
  selectedDepartments: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
}

UserCompetencies.defaultProps = {
  competencies: [],
  userCompetencies: [],
  selectedDepartments: [],
  disabled: false,
}

export default UserCompetencies
