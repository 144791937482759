const USER_COMPETENCY_MAP = {
  SCRAPING: [
    'INITIAL_TRAINING',
    'SCRAPING_PROBATION',
    'SCRAPING_PRODUCTION',
    'AUTOEAT',
    'AUTOEAT_QUALITY_CHECK',
    'DISH_REVIEW',
    'QQA_QUALITY_CHECK',
    'QQA_DISH_REVIEW',
    'INITIAL_TRAINING_TRAINER',
    'AUTOEAT_TRAINER',
    'BUDDY_HANDLER_SCRAPING',
    'SQUAD_LEAD',
    'JSQA',
    'INITIAL_TRAINING_LEAD',
    'BUDDY_SYSTEM_LEAD',
    'AUTOEAT_LEAD',
    'SCRAPING_LEAD',
    'ANDON_LEAD',
    'SQA',
    'VIRTUOSO',
    'SENIOR_TEAM_QA_MEETING_MEMBERS',
    'OPS_MANAGEMENT',
  ],
  CURATION: [
    'INITIAL_TRAINING',
    'COPYWRITING',
    'CURATION_PROBATION',
    'CURATION_PRODUCTION',
    'AUTOEAT',
    'ANNOTATOR',
    'CLEAN_UP',
    'QA',
    'QQA',
    'LOC_SOP_DRAFT',
    'JUNIOR_AI_PROBATION',
    'ANNOTATION_VALIDATORS',
    'CLEAN_UP_VALIDATORS',
    'INITIAL_TRAINING_JUNIOR_TRAINER',
    'BUDDY_HANDLER_CURATION',
    'JUNIOR_AI_PRODUCTION',
    'BUDDY_HANDLER_AI_REPORTS',
    'SENIOR_AI',
    'SQUAD_LEAD',
    'ML_DETECTIVE_DT_CT',
    'ML_DETECTIVE_MC_DA',
    'JSQA',
    'INITIAL_TRAINING_LEAD',
    'BUDDY_SYSTEM_LEAD',
    'CURATION_QA_LEAD',
    'AI_REPORTS_LEAD',
    'ML_DETECTIVES_LEAD',
    'ANDON_LEAD',
    'SQA',
    'VIRTUOSO',
    'SENIOR_TEAM_QA_MEETING_MEMBERS',
    'OPS_MANAGEMENT',
  ],
  PRECRUITER: ['RECRUITMENT_PRECRUIT'],
  RECRUITMENT: [
    'RECRUITMENT_OPS_JUNIOR',
    'RECRUITMENT_DEV_JUNIOR',
    'RECRUITMENT_OPS_SENIOR',
    'RECRUITMENT_DEV_SENIOR',
    'RECRUITMENT_LEAD',
  ],
  MANAGEMENT: ['SENIOR_TEAM_QA_MEETING_MEMBERS', 'OPS_MANAGEMENT'],
}

export default USER_COMPETENCY_MAP
