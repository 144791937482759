import React from 'react'
import { notification, Typography } from 'antd'
import { filter, find, get, isEmpty, map, sortBy } from 'lodash'

import { suggestionEndpoints } from '../../common/constants'
import { getAiSuggestions } from '../../common/utils'

import { brandLanguagesSuggestionTransformer } from '../transformers'

/**
 * Calls the Ai servicing for brand languages
 * It should:
 *   - fetch suggestions
 *   - return the suggestions
 *
 * Returns a promise.
 */
export default async function suggestLanguages({ brandId, languageOptions }) {
  try {
    // this endpoint gives us suggestions for multiple brands, that's why we destruct here
    const [result] = await getAiSuggestions(
      suggestionEndpoints.languages,
      [
        brandLanguagesSuggestionTransformer.transformBrandToAiRequestDto(
          brandId,
        ),
      ],
      brandLanguagesSuggestionTransformer.transformDishFromAiResponseDto,
    )

    if (!isEmpty(result.messages)) {
      notification.error({
        message: 'An error occured',
        description: map(result.messages, ({ message }, index) => (
          <Typography.Paragraph style={{ marginBottom: 0 }} key={index}>
            {message}
          </Typography.Paragraph>
        )),
        placement: 'topLeft',
        duration: 0,
      })
      return null
    }
    const suggestions = get(result, 'result')
    const langsFromSuggestions = filter(
      map(sortBy(suggestions, 'score'), suggestion => {
        const lang = find(
          languageOptions,
          ({ code }) => code === suggestion.langCode,
        )

        return {
          id: lang?.value,
          isSuggestedByAi: true,
        }
      }),
      'id',
    )

    return {
      languages: langsFromSuggestions,
      aiSuggestions: {
        languages: suggestions,
      },
    }
  } catch (e) {
    notification.error({
      message: 'An error occured',
      description: e.message,
      placement: 'topLeft',
    })
    return null
  }
}
