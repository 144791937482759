import { join } from 'path'

import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Modal, Table, Typography } from 'antd'
import { get, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'

import { getRouteOfDish } from '../../../../common/helpers'

const styles = {
  Error: {
    color: '#ff4d4f',
  },
  Warning: {
    color: '#faad14',
  },
}

const BrandValidationModal = ({ errors }) => {
  const [visible, toggleVisible] = useState(false)
  const { brandId } = useParams()

  const columnsConfiguration = useMemo(
    () => [
      {
        title: 'Dish link',
        render: (_, { dishId }) => {
          const relativePath = getRouteOfDish(brandId, dishId)
          const absolutePath = join(window.location.origin, relativePath)

          return (
            <Link target="_blank" to={relativePath}>
              {absolutePath}
            </Link>
          )
        },
      },
      {
        title: 'Messages',
        width: '60%',
        render: (_, { messages }) =>
          map(messages, ({ level, message }, index) => (
            <Typography.Paragraph key={index}>
              <Typography.Text style={get(styles, level)} strong>
                {level}:{' '}
              </Typography.Text>
              {message}
            </Typography.Paragraph>
          )),
      },
    ],
    [brandId],
  )

  useEffect(() => {
    if (!isEmpty(errors)) {
      toggleVisible(true)
    }
  }, [errors])

  return (
    <Modal
      width="80%"
      onCancel={() => toggleVisible(false)}
      centered
      title="Validation Errors"
      visible={visible}
      footer={null}
    >
      <Table
        rowKey="id"
        size="small"
        dataSource={errors}
        columns={columnsConfiguration}
      />
    </Modal>
  )
}

BrandValidationModal.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      dishId: PropTypes.string.isRequired,
      level: PropTypes.oneOf(['Error', 'Warning']).isRequired,
      message: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default BrandValidationModal
