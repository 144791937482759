import React from 'react'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'

import { DishAiHighlightingFieldValue } from '../DishAiHighlightingFieldValue'

const DishIngredientsPopoverMenuText = ({
  menuText,
  highlight,
  isSuggestedUsingMl,
  isSuggestedUsingParser,
}) => {
  if (isSuggestedUsingMl && !isSuggestedUsingParser) {
    return 'ML suggested'
  }

  if (isNil(menuText)) {
    return ''
  }

  return !isNil(highlight) ? (
    <DishAiHighlightingFieldValue highlight={highlight}>
      {menuText}
    </DishAiHighlightingFieldValue>
  ) : (
    menuText
  )
}

DishIngredientsPopoverMenuText.propTypes = {
  menuText: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  isSuggestedUsingMl: PropTypes.bool,
  isSuggestedUsingParser: PropTypes.bool,
}

DishIngredientsPopoverMenuText.defaultProps = {
  isSuggestedUsingMl: false,
  isSuggestedUsingParser: false,
}

export default DishIngredientsPopoverMenuText
