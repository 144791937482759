import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from '../../../brands/graphql'
import { BRAND_DISH_FRAGMENT } from '../../../dishes/graphql'

const DISH_TYPE_ML_CLEANUP_FRAGMENT = gql`
  fragment DishTypeMlCleanupFragment on DishTypeMlCleanup {
    id
    brand {
      ...BrandFragment
    }
    dish {
      ...BrandDishFragment
    }
    isCurationDone
    isQaDone
    currentDishTypeId
    suggestedDishTypeId
    otherDishTypeId
    choice
  }
  ${BRAND_DISH_FRAGMENT}
  ${BRAND_FRAGMENT}
`

export default DISH_TYPE_ML_CLEANUP_FRAGMENT
