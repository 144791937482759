import { METADATA_TYPES } from '../../common/constants'

const DEFAULT_CLEANUP_TASKS_FILTERS = Object.freeze({
  METADATA_TYPE: METADATA_TYPES.DISH_TYPES,
  STATUS: null,
  TYPE: null,
  ASSIGNED_USER: null,
})

export default DEFAULT_CLEANUP_TASKS_FILTERS
