import { get } from 'lodash'

import { extractChoiceText, extractMiscText } from '../../common/utils'

import { styledFieldMap } from '../constants'

import findMatchedWordsIndexesFromTextField from './findMatchedWordsIndexesFromTextField'

const mapStyledTextIndexesToDish = dish => key => {
  const styledKey = styledFieldMap[key]
  const matchedMiscWords = extractMiscText(get(dish, styledKey))
  const matchedChoiceWords = extractChoiceText(get(dish, styledKey))
  const dishTextField = get(dish, key) || ''
  const dishStyledTextField = get(dish, styledKey) || ''

  return {
    [key]: {
      text: dishTextField,
      misc: findMatchedWordsIndexesFromTextField(
        matchedMiscWords,
        dishStyledTextField,
      ),
      choices: findMatchedWordsIndexesFromTextField(
        matchedChoiceWords,
        dishStyledTextField,
      ),
    },
  }
}

export default mapStyledTextIndexesToDish
