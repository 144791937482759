import React, { memo, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Popconfirm } from 'antd'
import { get, isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { useQueryParams, useShallowEqualSelector } from '../../../../core/hooks'
import { isStringTrue } from '../../../../core/utils'

import { SaveButton } from '../../../../common/components'
import {
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
} from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import {
  useAppLoading,
  useAuthentication,
  useCachedBrand,
} from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import { DEFAULT_DISH_LIST_FILTERS } from '../../../../dishes/constants'
import { BRAND_DISHES_QUERY } from '../../../../dishes/graphql'
import { useUpsertBrand } from '../../../hooks'
import { brandTransformer } from '../../../transformers'

const BrandSaveButton = ({ brandId }) => {
  const brand = useCachedBrand()

  const {
    menuTitles: unsavedMenuTitles,
    state: { isDirty: isMenuTitlesDirty, isError: isMenuTitleError },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
  } = useFormContext()

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    currentPage = DEFAULT_PAGINATION_CONFIG.PAGE,
    sortDishesBy,
    isStatusSameAsBrand:
      dishIsStatusSameAsBrandFilter = DEFAULT_DISH_LIST_FILTERS.IS_STATUS_SAME_AS_BRAND,
    hasErrors: dishHasErrorsFilter = DEFAULT_DISH_LIST_FILTERS.HAS_ERRORS,
    published: dishPublishedFilter = DEFAULT_DISH_LIST_FILTERS.PUBLISHED,
    ignored: dishIgnoredFilter = DEFAULT_DISH_LIST_FILTERS.IGNORED,
    servicingHours: servicingHoursFilter,
    menuTitleId,
    search,
  } = useQueryParams()

  const statusForDishFiltering = useMemo(
    () => (isStringTrue(dishIsStatusSameAsBrandFilter) ? brand?.status : null),
    [dishIsStatusSameAsBrandFilter, brand?.status],
  )

  const isNewBrand = useMemo(() => isNil(brand), [brand])

  const { isLoading, startLoading, stopLoading } = useAppLoading()

  const upsertBrand = useUpsertBrand({
    brandId,
    refetchQueries: isNewBrand
      ? []
      : [
          {
            query: BRAND_DISHES_QUERY,
            variables: {
              brandId,
              paginationFiltersAndSorters: {
                pageSize: Number(pageSize),
                currentPage: Number(currentPage),
                filters: {
                  status: statusForDishFiltering,
                  servicingHours: servicingHoursFilter,
                  published: dishPublishedFilter,
                  ignored: dishIgnoredFilter,
                  hasErrors: isStringTrue(dishHasErrorsFilter),
                  menuTitle: menuTitleId,
                  search,
                },
                sortDishesBy,
              },
            },
          },
        ],
  })

  // A quirk of react-hook-forms is that the errors object
  // doesn't change reference when fixing validation errors or adding them
  // This means that if we add this in a useMemo it won't recompute the value
  const isSaveDisabled =
    !(isDirty || isMenuTitlesDirty) || !isEmpty(errors) || isMenuTitleError

  const buttonErrorMessage = !isEmpty(errors)
    ? 'Save failed. Please check form errors'
    : null

  const brandAiSuggestions = useShallowEqualSelector(({ aiSuggestions }) =>
    get(aiSuggestions?.brands, `${brandId}`),
  )

  const handleSaveClick = useCallback(
    async values => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_EVENTS.SAVE_CLICK,
      )
      startLoading()

      try {
        await upsertBrand(
          brandTransformer.transformToSaveBrandArgs(
            isMenuTitlesDirty
              ? { ...values, menuTitles: unsavedMenuTitles }
              : values,
            brandAiSuggestions,
          ),
        )
      } catch (error) {
        handleMutationError(error)
      }

      stopLoading()
    },
    [
      brandAiSuggestions,
      isMenuTitlesDirty,
      startLoading,
      stopLoading,
      unsavedMenuTitles,
      upsertBrand,
    ],
  )

  return (
    <>
      {isNewBrand && (
        <Popconfirm
          placement="left"
          title="Before saving, please make sure that the 'is fake' and 'is test' flag are correct. Do you want to continue?"
          onConfirm={handleSubmit(handleSaveClick)}
          okText="Yes"
          cancelText="No"
          disabled={isSaveDisabled}
        >
          <SaveButton
            loading={isSubmitting || isLoading}
            disabled={isSaveDisabled}
            errorMessage={buttonErrorMessage}
          />
        </Popconfirm>
      )}
      {!isNewBrand && (
        <SaveButton
          loading={isSubmitting || isLoading}
          disabled={isSaveDisabled}
          onClick={handleSubmit(handleSaveClick)}
          errorMessage={buttonErrorMessage}
        />
      )}
    </>
  )
}

BrandSaveButton.propTypes = {
  brandId: PropTypes.string.isRequired,
}

export default memo(BrandSaveButton)
