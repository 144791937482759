import { gql } from '@apollo/client'

const MENU_IMPORTED_SUBSCRIPTION = gql`
  subscription onMenuImported {
    menuImported {
      brandId
      brandName
      menuUrl
      jobStatus
      reason
    }
  }
`

export default MENU_IMPORTED_SUBSCRIPTION
