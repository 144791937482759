import React, { useCallback } from 'react'
import { useController } from 'react-hook-form'
import { TreeSelect as AntTreeSelect } from 'antd'
import { isNil, map } from 'lodash'
import PropTypes from 'prop-types'

import { handleTreeSelectCustomDropdownForSearch } from '../../../../common/helpers'
import { treeOptionPropType } from '../../../../common/propTypes'

import { SpanPropType } from '../../../propTypes'
import { GridFormItem } from '../../atoms'

const inputStyles = {
  width: '100%',
}

const TreeSelect = ({
  name,
  style,
  treeData,
  defaultValue,
  allowClear,
  disabled,
  size,
  placeholder,
  labelProp,
  label,
  labelAbove,
  multi,
  span,
  className,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error: { message } = {} },
  } = useController({ name, defaultValue })

  let selectedValue

  if (multi) {
    selectedValue = isNil(value) ? [] : map(value, item => item.toString())
  } else {
    selectedValue = isNil(value) ? null : value.toString()
  }

  const handleChange = useCallback(
    newValue => (isNil(newValue) ? onChange(null) : onChange(newValue)),
    [onChange],
  )

  return (
    <GridFormItem
      style={{ ...inputStyles, ...style }}
      validateStatus={invalid ? 'error' : undefined}
      help={message}
      label={label}
      labelAbove={labelAbove}
      span={span}
    >
      <AntTreeSelect
        className={className}
        multiple={multi}
        treeNodeLabelProp={labelProp}
        disabled={disabled}
        allowClear={allowClear}
        style={style}
        size={size}
        showSearch
        treeData={treeData}
        value={selectedValue}
        treeDefaultExpandedKeys={[value ? value.toString() : '']}
        treeNodeFilterProp="title"
        dropdownRender={handleTreeSelectCustomDropdownForSearch}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </GridFormItem>
  )
}

TreeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  disabled: PropTypes.bool,
  treeData: PropTypes.arrayOf(treeOptionPropType).isRequired,
  allowClear: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  placeholder: PropTypes.string,
  labelProp: PropTypes.string,
  label: PropTypes.string,
  labelAbove: PropTypes.bool,
  multi: PropTypes.bool,
  span: SpanPropType,
  className: PropTypes.string,
}

TreeSelect.defaultProps = {
  defaultValue: null,
  labelProp: 'title',
  style: undefined,
  disabled: false,
  allowClear: true,
  size: 'normal',
  placeholder: 'Select...',
  label: undefined,
  labelAbove: false,
  multi: false,
  span: undefined,
  className: undefined,
}

export default TreeSelect
