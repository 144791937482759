import { filter, flatten, includes, map } from 'lodash'

const getAllIdsFromTreeLevel = (nodes, currentLevel, maxLevel) => {
  if (currentLevel === maxLevel) {
    return map(
      filter(
        nodes,
        node => !node.disabled && node.value && !includes(node.value, 'all'),
      ),
      'value',
    )
  }

  return flatten(
    map(
      filter(
        nodes,
        node => !node.disabled && node.value && !includes(node.value, 'all'),
      ),
      node => getAllIdsFromTreeLevel(node.children, currentLevel + 1, maxLevel),
    ),
  )
}

export default getAllIdsFromTreeLevel
