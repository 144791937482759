import React from 'react'
import { useController } from 'react-hook-form'
import { Input as AntInput } from 'antd'
import PropTypes from 'prop-types'

import { SpanPropType } from '../../../propTypes'
import { GridFormItem } from '../../atoms'

const inputStyles = {
  width: '100%',
}

const Input = ({
  name,
  defaultValue,
  disabled,
  style,
  size,
  autoSize,
  label,
  labelAbove,
  placeholder,
  span,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error: { message } = {} },
  } = useController({ name, defaultValue })

  return (
    <GridFormItem
      span={span}
      style={{ ...inputStyles, ...style }}
      validateStatus={invalid ? 'error' : undefined}
      help={message}
      label={label}
      labelAbove={labelAbove}
    >
      {autoSize ? (
        <AntInput.TextArea
          size={size}
          disabled={disabled}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          autoSize={autoSize}
          placeholder={placeholder}
        />
      ) : (
        <AntInput
          size={size}
          disabled={disabled}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      )}
    </GridFormItem>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  autoSize: PropTypes.bool,
  label: PropTypes.string,
  labelAbove: PropTypes.bool,
  placeholder: PropTypes.string,
  span: SpanPropType,
}

Input.defaultProps = {
  span: undefined,
  disabled: false,
  defaultValue: '',
  style: undefined,
  size: 'default',
  autoSize: false,
  label: undefined,
  labelAbove: false,
  placeholder: 'Enter...',
}

export default Input
