import React, { useCallback, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { CURATION_STATUS, EVENT_TYPES } from '../../common/constants'
import { handleMutationError } from '../../common/helpers'
import { useCachedBrand } from '../../common/hooks'
import { googleAnalyticsEventsService } from '../../common/services'

import { DishTableEditableRowLoader } from '../components/molecules'
import { DishEditableVariousTextRowContext } from '../contexts'
import { UPDATE_DISH_TEXT_FIELDS_MUTATION } from '../graphql'
import { getAiSuggestionStepForStatus } from '../services'

const DishEditableVariousTextRowProvider = ({ rowProps }) => {
  const rowRef = useRef(null)
  const brand = useCachedBrand()
  const [isSuggestionLoading, toggleSuggestionLoading] = useState(false)

  const { children, className, ...restProps } = rowProps

  const [updateDishTextFields, { loading: isMutationSaving }] = useMutation(
    UPDATE_DISH_TEXT_FIELDS_MUTATION,
    {
      onError: handleMutationError,
    },
  )

  const handleChangeVariousText = useCallback(
    (_, input) =>
      updateDishTextFields({
        variables: {
          input,
        },
      }),
    [updateDishTextFields],
  )

  const handleSuggestVariousText = useCallback(
    async dish => {
      googleAnalyticsEventsService.fireEvent(
        EVENT_TYPES.BRAND_DISH_EVENTS.SUGGEST,
        {
          for: CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
        },
      )
      toggleSuggestionLoading(true)
      const currentStatusStep = getAiSuggestionStepForStatus(
        CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
      )

      const suggestionResult = await currentStatusStep.suggest({
        dish,
        additionalInformation: {
          brand,
        },
      })

      if (!isEmpty(suggestionResult)) {
        googleAnalyticsEventsService.fireEvent(
          EVENT_TYPES.BRAND_DISH_EVENTS.SAVE_SUGGESTIONS,
          {
            for: CURATION_STATUS.MISC_AND_CHOICE_CONFIRMATION.value,
          },
        )
        await updateDishTextFields({
          variables: {
            input: {
              id: dish.id,
              aiSuggestions: suggestionResult.aiSuggestions,
            },
          },
        })
      }
      toggleSuggestionLoading(false)
    },
    [brand, updateDishTextFields],
  )

  const loading = isMutationSaving || isSuggestionLoading

  return (
    <DishEditableVariousTextRowContext.Provider
      value={{
        saveVariousText: handleChangeVariousText,
        loading,
        suggestVariousText: handleSuggestVariousText,
      }}
    >
      <DishTableEditableRowLoader
        loading={loading}
        height={get(rowRef, 'current.offsetHeight')}
      />
      <tr ref={rowRef} className={className} {...restProps}>
        {children}
      </tr>
    </DishEditableVariousTextRowContext.Provider>
  )
}

DishEditableVariousTextRowProvider.propTypes = {
  rowProps: PropTypes.object.isRequired,
}

export default DishEditableVariousTextRowProvider
