import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { Input, Select, Typography } from 'antd'
import update from 'immutability-helper'
import { filter, find, findIndex, includes, map } from 'lodash'
import PropTypes from 'prop-types'

import { useOutsideClick } from '../../../../core/hooks'

import { usePersistedDishField } from '../../../hooks'
import { setDishSelectedLocations } from '../../../redux'

import dishLocationAvailabilityStyles from './dishLocationAvailabilityStyles'

const DishLocationAvailabilitySelect = ({
  size,
  disabled,
  options,
  defaultValue,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const dishLocationSelectRef = useRef(null)

  useOutsideClick(dishLocationSelectRef, () => setIsOpen(false))

  const {
    selectedLocations: persistedSelectedLocations,
    onValueChanged: handleSelectedLocationsChange,
  } = usePersistedDishField('selectedLocations', setDishSelectedLocations)

  const selectedLocations = useMemo(() => {
    const locationValues = persistedSelectedLocations || defaultValue
    if (locationValues && locationValues.length !== options.length) {
      return map(options, ({ id, name }) => ({
        locationId: id,
        name,
        disabled: false,
      }))
    }

    return locationValues
  }, [defaultValue, options, persistedSelectedLocations])

  const valueOfSelect = useMemo(
    () =>
      map(
        filter(
          selectedLocations,
          ({ disabled: isNotAvailableForLocation }) =>
            !isNotAvailableForLocation,
        ),
        ({ locationId }) => locationId,
      ),
    [selectedLocations],
  )

  const handleOnChangeOverridenPrice = useCallback(
    (locationId, overridenPrice, isMin) => {
      const locationIndex = findIndex(
        selectedLocations,
        ({ locationId: previousSelectedLocationId }) =>
          previousSelectedLocationId === locationId,
      )
      const newLocationsState = update(
        { collection: selectedLocations },
        {
          collection: {
            [locationIndex]: {
              $set: isMin
                ? {
                    ...selectedLocations[locationIndex],
                    overridenLowPrice: overridenPrice,
                  }
                : {
                    ...selectedLocations[locationIndex],
                    overridenHighPrice: overridenPrice,
                  },
            },
          },
        },
      )
      handleSelectedLocationsChange(newLocationsState.collection)
    },
    [handleSelectedLocationsChange, selectedLocations],
  )

  const handleLocationSelection = useCallback(
    selectedLocation => {
      const locationToSelect = map(options, location => ({
        ...location,
        disabled: !includes(selectedLocation, location.id),
      }))

      handleSelectedLocationsChange(
        map(locationToSelect, location => ({
          ...location,
          locationId: location.id,
        })),
      )
    },
    [options, handleSelectedLocationsChange],
  )

  return (
    <Select
      dropdownClassName="locationDropdown"
      open={isOpen}
      onFocus={() => setIsOpen(true)}
      dropdownMatchSelectWidth={false}
      mode="multiple"
      size={size}
      value={valueOfSelect}
      placeholder="Select Location"
      onChange={handleLocationSelection}
      disabled={disabled}
      maxTagCount="responsive"
      optionFilterProp="name"
      dropdownRender={menu => (
        <div
          style={dishLocationAvailabilityStyles.menuContainer}
          ref={dishLocationSelectRef}
        >
          {menu}
          <div style={dishLocationAvailabilityStyles.sideMenu}>
            {map(menu.props.flattenOptions, ({ key }) => {
              const selectedItem = find(
                selectedLocations,
                ({ locationId, id }) => (locationId || id) === key,
              )
              if (selectedItem && selectedItem.disabled === false) {
                return (
                  <div
                    key={`input-${selectedItem.locationId}`}
                    style={{
                      ...dishLocationAvailabilityStyles.inputContainer,
                      ...dishLocationAvailabilityStyles.priceInputSelected,
                    }}
                  >
                    <Typography.Text>£</Typography.Text>
                    <Input
                      type="number"
                      style={dishLocationAvailabilityStyles.priceInput}
                      value={selectedItem.overridenLowPrice}
                      onKeyDown={e => e.stopPropagation()}
                      onChange={({ target: { value: overridenPrice } }) =>
                        handleOnChangeOverridenPrice(
                          selectedItem.locationId,
                          overridenPrice,
                          true,
                        )
                      }
                    />
                    <Input
                      type="number"
                      style={dishLocationAvailabilityStyles.priceInput}
                      value={selectedItem.overridenHighPrice}
                      onKeyDown={e => e.stopPropagation()}
                      onChange={({ target: { value: overridenPrice } }) =>
                        handleOnChangeOverridenPrice(
                          selectedItem.locationId,
                          overridenPrice,
                          false,
                        )
                      }
                    />
                  </div>
                )
              }
              return (
                <div
                  key={`input-${key}`}
                  style={{ ...dishLocationAvailabilityStyles.inputContainer }}
                />
              )
            })}
          </div>
        </div>
      )}
    >
      {map(options, ({ id, name }) => (
        <Select.Option
          style={dishLocationAvailabilityStyles.option}
          name={name}
          key={id}
        >
          {name}
        </Select.Option>
      ))}
    </Select>
  )
}

DishLocationAvailabilitySelect.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.arrayOf(PropTypes.object),
}

DishLocationAvailabilitySelect.defaultProps = {
  size: 'small',
  disabled: false,
  defaultValue: [],
  options: [],
}

export default memo(DishLocationAvailabilitySelect)
