import axios from 'axios'

import { sessionManager } from '../services'

const getSuggestions = async (
  dto,
  endpoint,
  transformResponse,
  isAiSuggestion,
) => {
  try {
    const response = await axios.post(endpoint, dto, {
      headers: {
        authorization: isAiSuggestion
          ? `Bearer ${window._env_.REACT_APP_AI_SERVICE_AUTHORIZATION_TOKEN}`
          : sessionManager.getAccessToken(),
        'Content-Type': 'application/json',
      },
      timeout: 1000 * 60 * 60 * 3, // 3 hour timeout
    })

    if (response.status !== 200) {
      throw new Error(JSON.stringify(response))
    }

    return transformResponse ? transformResponse(response.data) : response.data
  } catch (error) {
    throw new Error(error)
  }
}

export const getAiSuggestions = async (endpoint, dto, transformResponse) =>
  getSuggestions(dto, endpoint, transformResponse, true)

export const getCuratorSuggestions = async (endpoint, dto, transformResponse) =>
  getSuggestions(dto, endpoint, transformResponse, false)
