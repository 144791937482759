import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row, Typography } from 'antd'

import { Spinner } from '../../../../core/components'
import { useDocumentTitle, useQueryParams } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import { DEFAULT_PAGINATION_CONFIG } from '../../../../common/constants'
import { USERS_WITH_KNOWLEDGE_QUERY } from '../../../../common/graphql'
import { useAuthentication } from '../../../../common/hooks'

import { DEFAULT_CLEANUP_TASKS_FILTERS } from '../../../constants'
import {
  CLEANUP_TASKS_QUERY,
  CLEANUP_TASKS_SUMMARY_QUERY,
} from '../../../graphql'
import {
  CleanupTaskHistoryModal,
  CleanupTasksFilters,
  CleanupTasksTable,
  CleanupTasksTabs,
} from '../../sections'

const styles = {
  pageWrapper: { padding: '1rem', paddingBottom: 0 },
}

const CleanupTasks = () => {
  useDocumentTitle('Cleanup tasks')

  const {
    userInfo: {
      settings: { pageSize: userDefaultPageSize },
    },
  } = useAuthentication()

  const {
    page = DEFAULT_PAGINATION_CONFIG.PAGE,
    pageSize = userDefaultPageSize || DEFAULT_PAGINATION_CONFIG.PAGE_SIZE,
    status = DEFAULT_CLEANUP_TASKS_FILTERS.STATUS,
    type = DEFAULT_CLEANUP_TASKS_FILTERS.TYPE,
    assignedUserId = DEFAULT_CLEANUP_TASKS_FILTERS.ASSIGNED_USER,
    metadataType = DEFAULT_CLEANUP_TASKS_FILTERS.METADATA_TYPE,
    setQueryParams,
  } = useQueryParams()

  const {
    data: { users } = {},
    loading: usersLoading,
    error: usersWithKnowledgeError,
  } = useQuery(USERS_WITH_KNOWLEDGE_QUERY, {
    variables: {
      filters: {
        isActive: true,
      },
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { cleanupTasksSummary } = {},
    error: cleanupTasksSummaryError,
  } = useQuery(CLEANUP_TASKS_SUMMARY_QUERY, { fetchPolicy: 'network-only' })

  const {
    error,
    loading,
    data: { cleanupTasks } = {},
  } = useQuery(CLEANUP_TASKS_QUERY, {
    variables: {
      metadataType,
      currentPage: Number(page),
      pageSize: Number(pageSize),
      filters: {
        status,
        type,
        assignedUserId,
      },
    },
    fetchPolicy: 'network-only',
  })
  const { count, tasks = [] } = cleanupTasks || {}

  const handlePaginationChange = useCallback(
    newPage => {
      setQueryParams({ page: newPage })
    },
    [setQueryParams],
  )

  const handlePageSizeChange = useCallback(
    (_, size) => {
      setQueryParams({ page: DEFAULT_PAGINATION_CONFIG.PAGE, pageSize: size })
    },
    [setQueryParams],
  )

  if (error || usersWithKnowledgeError || cleanupTasksSummaryError) {
    return <p>Failed to get tasks</p>
  }

  if (usersLoading) {
    return <Spinner size="large" />
  }

  return (
    <PageWithHeader>
      <div style={styles.pageWrapper}>
        <Row>
          <Col span={24}>
            <Typography.Title level={3}>Cleanup tasks</Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CleanupTaskHistoryModal />
          </Col>
        </Row>
        <CleanupTasksFilters users={users} />
        <CleanupTasksTabs summary={cleanupTasksSummary} />
      </div>

      <CleanupTasksTable
        metadataType={metadataType}
        users={users}
        data={tasks}
        total={count}
        loading={loading}
        onPaginationChange={handlePaginationChange}
        onPageSizeChange={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
      />
    </PageWithHeader>
  )
}

export default CleanupTasks
