import { gql } from '@apollo/client'

const BRAND_BATCH_AI_SUGGESTIONS_SUBSCRIPTION = gql`
  subscription onBrandBatchAiSuggestions {
    brandBatchAiSuggestions {
      brandId
      brandName
      reason
      jobStatus
      status
      retry
    }
  }
`

export default BRAND_BATCH_AI_SUGGESTIONS_SUBSCRIPTION
