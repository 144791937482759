import { deburr, toLower } from 'lodash'

import { replaceNbspFromText } from '../utils'

const findWordIndexInDraftJsText = (inputText = '', word, startIndex) =>
  deburr(toLower(replaceNbspFromText(inputText))).indexOf(
    deburr(toLower(word), startIndex),
  )

export default findWordIndexInDraftJsText
