import { decode } from 'he'
import { get, includes, join, keys, omit, size } from 'lodash'
import * as Yup from 'yup'

import { stripHtmlFromText } from '../../common/utils'

import { BRAND_SUGGESTED_WORD_TYPE } from '../constants'

const termMatcher = join(
  keys(omit(BRAND_SUGGESTED_WORD_TYPE, BRAND_SUGGESTED_WORD_TYPE.UNKNOWN)),
  '|',
)

const brandSuggestedWordValidationSchema = (context, posIndex) =>
  Yup.object().shape({
    type: Yup.string()
      .matches(termMatcher, "You can't save Unknown type for word")
      .required(),
    context: Yup.string()
      .test('is-continuous', 'Must be continous selection of text', value => {
        const selectedParts = decode(value)
          .replaceAll('\n', ' ')
          .match(/<strong>(.*?)<\/strong>/g)

        return size(selectedParts) < 2
      })
      .test(
        'contains-original',
        'Selection must contain original suggestion',
        value => {
          const wordFromContext = context.substring(posIndex[0], posIndex[1])
          const boldText = get(
            decode(value)
              .replaceAll('\n', '<br>')
              .match(/<strong>(.*?)<\/strong>/g),
            '[0]',
          )

          return includes(stripHtmlFromText(boldText), wordFromContext)
        },
      )
      .required(),
    languageId: Yup.string()
      .when('type', {
        is: type => type === BRAND_SUGGESTED_WORD_TYPE.COMMON_FOREIGN_WORD,
        then: Yup.string().required('Language is required').nullable(),
      })
      .nullable(),
    ingredientKeywordMatchType: Yup.string()
      .when('type', {
        is: type =>
          includes(
            [
              BRAND_SUGGESTED_WORD_TYPE.NEW_INGREDIENT,
              BRAND_SUGGESTED_WORD_TYPE.EXISTING_INGREDIENT,
            ],
            type,
          ),
        then: Yup.string()
          .required('Keyword Match Type is required')
          .nullable(),
      })
      .nullable(),
    ingredientId: Yup.string()
      .when('type', {
        is: type =>
          includes(
            [
              BRAND_SUGGESTED_WORD_TYPE.NEW_INGREDIENT,
              BRAND_SUGGESTED_WORD_TYPE.EXISTING_INGREDIENT,
              BRAND_SUGGESTED_WORD_TYPE.BRAND_EXISTING_INGREDIENT,
            ],
            type,
          ),
        then: Yup.string()
          .required('Ingredient is required')
          .test(
            'should-not-select-all',
            'Ingredient should not be "All"',
            value => Number(value) > 1,
          )
          .nullable(),
      })
      .nullable(),
    allergenId: Yup.string()
      .when('type', {
        is: type => type === BRAND_SUGGESTED_WORD_TYPE.ALLERGEN_LABEL,
        then: Yup.string().required('Allergen is required').nullable(),
      })
      .nullable(),
    dietId: Yup.string()
      .when('type', {
        is: type => type === BRAND_SUGGESTED_WORD_TYPE.DIET_LABEL,
        then: Yup.string().required('Diet is required').nullable(),
      })
      .nullable(),
  })

export default brandSuggestedWordValidationSchema
