import { gql } from '@apollo/client'

const UPDATE_CLEANUP_TASK_QA_DONE_MUTATION = gql`
  mutation updateCleanupTaskQaDone(
    $id: ID!
    $metadataType: String!
    $value: Boolean!
  ) {
    updateCleanupTaskQaDone(
      id: $id
      metadataType: $metadataType
      value: $value
    ) {
      id
      isQaDone
    }
  }
`

export default UPDATE_CLEANUP_TASK_QA_DONE_MUTATION
