import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Col, Row } from 'antd'
import { get, includes, map } from 'lodash'
import PropTypes from 'prop-types'

import { LabelLegends } from '../../../../common/components'
import { CURATION_STATUS } from '../../../../common/constants'
import { useCurationMetadata } from '../../../../common/hooks'

import { brandPropType } from '../../../propTypes'
import { brandTransformer } from '../../../transformers'

const BrandLabelLegends = ({ brand, isDisabled, qaCorrectionCounts }) => {
  const { control } = useFormContext()
  const { dietOptions, allergenOptions } = useCurationMetadata()

  const {
    dietLabelLegends = [],
    allergenLabelLegends = [],
    status,
  } = useMemo(() => brandTransformer.transformBrandFromDto(brand), [brand])

  const isLabelLegendsDisabled = useMemo(
    () =>
      isDisabled ||
      !includes(
        [
          CURATION_STATUS.LABEL_LEGENDS.value,
          CURATION_STATUS.LABEL_LEGENDS_CONFIRMATION.value,
        ],
        status,
      ),
    [isDisabled, status],
  )

  return (
    <>
      <Col span={6}>
        <Row>
          <Col span={24}>
            <Controller
              control={control}
              defaultValue={dietLabelLegends}
              name="dietLabelLegends"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <LabelLegends
                    newLabelLegends={value}
                    options={dietOptions}
                    onValueChanged={onChange}
                    forEntity="diet"
                    disabled={isLabelLegendsDisabled}
                    isQaCorrected={qaCorrectionCounts?.dietLabelLegend > 0}
                  />
                  {map(error, (dietLabelLegendError, index) => (
                    <div
                      key={`dietLabelLegendErrorLabel-${index}`}
                      style={{ color: 'red' }}
                    >
                      {get(dietLabelLegendError, 'label.message')}
                    </div>
                  ))}
                </>
              )}
            />
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Controller
          control={control}
          defaultValue={allergenLabelLegends}
          name="allergenLabelLegends"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <LabelLegends
                newLabelLegends={value}
                forEntity="allergen"
                options={allergenOptions}
                onValueChanged={onChange}
                disabled={isLabelLegendsDisabled}
                isQaCorrected={qaCorrectionCounts?.allergenLabelLegend > 0}
              />
              {map(error, (allergenLabelLegendError, index) => (
                <div
                  key={`dietLabelLegendErrorLabel-${index}`}
                  style={{ color: 'red' }}
                >
                  {get(allergenLabelLegendError, 'label.message')}
                </div>
              ))}
            </>
          )}
        />
      </Col>
    </>
  )
}

BrandLabelLegends.propTypes = {
  brand: brandPropType.isRequired,
  qaCorrectionCounts: PropTypes.object,
  isDisabled: PropTypes.bool,
}

BrandLabelLegends.defaultProps = {
  isDisabled: false,
  qaCorrectionCounts: undefined,
}

export default BrandLabelLegends
