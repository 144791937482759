import PropTypes from 'prop-types'

const servicingHourPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  menu: PropTypes.string.isRequired,
  startDayOfWeek: PropTypes.number.isRequired,
  endDayOfWeek: PropTypes.number.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
})

export default servicingHourPropType
