import React, { useCallback } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

import { stylePropType } from '../../../propTypes'

import DebouncedInput from './DebouncedInput'

const parseValueByInputType = {
  text: value => value,
  float: value => (value !== '' ? parseFloat(value) : null),
}

const DebouncedTextField = ({
  text,
  newText,
  style,
  prefix,
  disabled,
  inputType,
  autoSize,
  size,
  onValueChanged,
  placeholder,
}) => {
  const onChange = useCallback(
    value => {
      onValueChanged(parseValueByInputType[inputType](value))
    },
    [inputType, onValueChanged],
  )

  return (
    <DebouncedInput
      InputComponent={autoSize ? Input.TextArea : Input}
      value={newText}
      defaultValue={text}
      onValueChanged={onChange}
      style={style}
      prefix={prefix}
      disabled={disabled}
      inputType={inputType}
      autoSize={autoSize}
      size={size}
      placeholder={placeholder}
    />
  )
}

DebouncedTextField.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChanged: PropTypes.func.isRequired,
  style: stylePropType,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  inputType: PropTypes.oneOf(['text', 'float']),
  autoSize: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
}

DebouncedTextField.defaultProps = {
  text: null,
  newText: undefined,
  style: null,
  prefix: null,
  disabled: false,
  inputType: 'text',
  autoSize: false,
  size: undefined,
  placeholder: 'Enter...',
}

export default DebouncedTextField
