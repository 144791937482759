import React from 'react'
import { useController } from 'react-hook-form'
import { Checkbox as AntCheckbox } from 'antd'
import PropTypes from 'prop-types'

import { SpanPropType } from '../../../propTypes'
import { GridFormItem } from '../../atoms'

import styles from './checkboxStyles'

const Checkbox = ({
  name,
  defaultValue,
  style,
  disabled,
  label,
  labelAbove,
  span,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid, error: { message } = {} },
  } = useController({ name, defaultValue })

  return (
    <GridFormItem
      style={{ ...styles.flexWrapper, ...(style || {}) }}
      validateStatus={invalid ? 'error' : undefined}
      help={message}
      span={span}
      label={label}
      labelAbove={labelAbove}
    >
      <AntCheckbox
        disabled={disabled}
        onBlur={onBlur}
        onChange={e => onChange(e.target.checked)}
        checked={value}
      />
    </GridFormItem>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
  labelAbove: PropTypes.bool,
  span: SpanPropType,
}

Checkbox.defaultProps = {
  style: undefined,
  disabled: false,
  defaultValue: false,
  label: undefined,
  labelAbove: false,
  span: undefined,
}

export default Checkbox
