import React, { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Button, Col, notification, Row, Typography } from 'antd'

import { PageWithHeader } from '../../../../common/components'
import { handleMutationError } from '../../../../common/helpers'

import { CREATE_REVIEW_CLEANUP_TASK_MUTATION } from '../../../graphql'
import { reviewCleanupTaskTransformer } from '../../../transformers'
import { ReviewTaskForm } from '../../sections'

const styles = {
  pageWrapper: {
    padding: '1rem',
  },
}

const CreateReviewTask = () => {
  const { formState, handleSubmit, reset, ...formProps } = useForm({})

  const [createReviewCleanupTask, { loading }] = useMutation(
    CREATE_REVIEW_CLEANUP_TASK_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: async ({ createReviewCleanupTask: result }) => {
        reset()
        notification.success({
          message: 'Task created',
          description: `Review task ${result.name} successfully created`,
          placement: 'topLeft',
        })
      },
    },
  )

  const handleCreate = useCallback(
    async formValues => {
      const input = reviewCleanupTaskTransformer.transformTaskToDto(formValues)
      await createReviewCleanupTask({ variables: { input } })
    },
    [createReviewCleanupTask],
  )

  return (
    <FormProvider
      {...formProps}
      reset={reset}
      formState={formState}
      handleSubmit={handleSubmit}
    >
      <PageWithHeader>
        <div style={styles.pageWrapper}>
          <Row>
            <Col span={24}>
              <Typography.Title level={3}>Create review task</Typography.Title>
            </Col>
          </Row>
          <ReviewTaskForm />
          <Row>
            <Col span={6}>
              <Button onClick={handleSubmit(handleCreate)} loading={loading}>
                Create
              </Button>
            </Col>
          </Row>
        </div>
      </PageWithHeader>
    </FormProvider>
  )
}

export default CreateReviewTask
