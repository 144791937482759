import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
} from '@draft-js-plugins/buttons'
import Editor from '@draft-js-plugins/editor'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import { ErrorMessage } from '@hookform/error-message'
import { EditorState, RichUtils } from 'draft-js'
import { isEmpty, isNil, map, values } from 'lodash'
import PropTypes from 'prop-types'

import { ReadMore } from '../../../../common/components'
import {
  getContentStateFromText,
  getStyledTextFromContentState,
} from '../../../../common/helpers'
import { stripHtmlFromText } from '../../../../common/utils'

import { SpanPropType } from '../../../propTypes'
import { GridFormItem } from '../../atoms'

import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import 'draft-js/dist/Draft.css'

const styles = {
  editor: {
    errorInput: {
      borderColor: '#ff4d4f',
    },
    input: {
      minHeight: 0,
      height: 'unset',
      padding: '1px 0.5rem',
    },
    inputDisabled: {
      minHeight: 0,
      height: 'unset',
      padding: '1px 0.5rem',
      color: 'rgba(0, 0, 0, 0.25)',
      backgroundColor: '#f5f5f5',
      cursor: 'not-allowed',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      lineHeight: '21px',
    },
    errorToolBarButton: {
      color: 'red',
    },
    focusedContainer: {
      borderColor: '#40a9ff',
      outline: 0,
      boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
    },
  },
}

const InlineStyleButtons = {
  underline: 'underline',
  italic: 'italic',
  bold: 'bold',
}

const getToolbarButton = (name, externalProps) => item => {
  switch (item) {
    case InlineStyleButtons.underline:
      return (
        <UnderlineButton key={`${name}-${item}-button`} {...externalProps} />
      )
    case InlineStyleButtons.italic:
      return <ItalicButton key={`${name}-${item}-button`} {...externalProps} />
    case InlineStyleButtons.bold:
      return <BoldButton key={`${name}-${item}-button`} {...externalProps} />
    default:
      return <span style={styles.editor.errorToolBarButton}>Not Found</span>
  }
}

const RichTextInput = ({
  name,
  className,
  defaultValue,
  disabled,
  style,
  toolbarItems,
  onlySelection,
  label,
  labelAbove,
  span,
  decorator,
  maxEditorHeight,
}) => {
  const {
    field: { onChange, onBlur, value },
    formState: { errors },
  } = useController({ name, defaultValue })

  const initialValue = useRef(value)
  const isMounting = useRef(true)

  const editorRef = useRef(null)
  const [isFocused, setFocus] = useState(false)

  const [editorState, setEditorState] = useState(() =>
    isEmpty(value)
      ? EditorState.createEmpty(decorator)
      : EditorState.createWithContent(getContentStateFromText(value)),
  )

  const plugins = useMemo(() => createInlineToolbarPlugin(), [])

  const valueStripedOfHtml = useMemo(() => stripHtmlFromText(value), [value])

  const focusEditor = useCallback(() => {
    editorRef.current.focus()
    setFocus(true)
  }, [editorRef])

  const handleChange = useCallback(
    newEditorState => {
      if (onlySelection) {
        const changedText = getStyledTextFromContentState(
          newEditorState.getCurrentContent(),
        )

        const newValueStripedOfHtml = stripHtmlFromText(changedText)
        if (valueStripedOfHtml === newValueStripedOfHtml) {
          setEditorState(newEditorState)
        }
      } else {
        setEditorState(newEditorState)
      }
    },
    [onlySelection, valueStripedOfHtml],
  )

  const handleBlur = useCallback(
    event => {
      if (onlySelection) {
        onChange(getStyledTextFromContentState(editorState.getCurrentContent()))
      } else if (
        !isMounting.current &&
        value !== getStyledTextFromContentState(editorState.getCurrentContent())
      ) {
        onChange(editorState)
      }
      onBlur(event)
      setFocus(false)
    },
    [editorState, onBlur, onChange, onlySelection, value],
  )

  // needed because the draftjs editor calls on change everytime the editorState changes, even for the first time
  useEffect(() => {
    isMounting.current = false
  }, [])

  useEffect(() => {
    if (value === initialValue.current) {
      setEditorState(
        isEmpty(value)
          ? EditorState.createEmpty(decorator)
          : EditorState.createWithContent(
              getContentStateFromText(value),
              decorator,
            ),
      )
    }
  }, [decorator, value])

  const handleReturn = useCallback(() => {
    handleChange(RichUtils.insertSoftNewline(editorState))
    return 'handled'
  }, [editorState, handleChange])

  const editor = useMemo(
    () => (
      <>
        <Editor
          ref={editorRef}
          readOnly={disabled}
          editorState={editorState}
          onChange={handleChange}
          decorators={decorator && [decorator]}
          handleReturn={handleReturn}
          onBlur={handleBlur}
          plugins={toolbarItems && [plugins]}
        />
        {toolbarItems && plugins && (
          <plugins.InlineToolbar>
            {externalProps => (
              <>{map(toolbarItems, getToolbarButton(name, externalProps))}</>
            )}
          </plugins.InlineToolbar>
        )}
      </>
    ),
    [
      decorator,
      disabled,
      editorState,
      handleBlur,
      handleChange,
      handleReturn,
      name,
      plugins,
      toolbarItems,
    ],
  )

  return (
    <GridFormItem
      span={span}
      className={!isEmpty(errors) ? 'has-error' : undefined}
      label={label}
      labelAbove={labelAbove}
    >
      <div
        aria-hidden="true"
        onClick={!disabled ? focusEditor : undefined}
        style={{
          ...style,
          ...(!isEmpty(errors) ? styles.editor.errorInput : {}),
          ...(!disabled ? styles.editor.input : styles.editor.inputDisabled),
          ...(isFocused ? styles.editor.focusedContainer : {}),
        }}
        className={`${disabled ? 'disabledRichInput' : 'ant-input textarea'} ${
          className || ''
        }`}
      >
        {!isNil(maxEditorHeight) ? (
          <ReadMore component={editor} maxHeight={maxEditorHeight} />
        ) : (
          editor
        )}
      </div>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="ant-form-item-explain">
            <div role="alert" className="ant-form-item-explain-error">
              {message}
            </div>
          </div>
        )}
      />
    </GridFormItem>
  )
}

RichTextInput.propTypes = {
  className: PropTypes.string,
  span: SpanPropType,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  toolbarItems: PropTypes.arrayOf(PropTypes.oneOf(values(InlineStyleButtons))),
  onlySelection: PropTypes.bool,
  label: PropTypes.string,
  labelAbove: PropTypes.bool,
  decorator: PropTypes.object,
  maxEditorHeight: PropTypes.number,
}

RichTextInput.defaultProps = {
  className: undefined,
  span: undefined,
  disabled: false,
  defaultValue: undefined,
  toolbarItems: undefined,
  style: undefined,
  onlySelection: false,
  label: undefined,
  labelAbove: false,
  decorator: undefined,
  maxEditorHeight: undefined,
}

export default RichTextInput
