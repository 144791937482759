import { gql } from '@apollo/client'

const ML_CLEANUP_TASK_REQUESTS_QUERY = gql`
  query mlCleanupTaskRequests {
    mlCleanupTaskRequests {
      id
      metadataType
      status
      dishTypeIdsParam
      courseTypeIdsParam
      message
      startDateParam
      endDateParam
      createdAt
    }
  }
`

export default ML_CLEANUP_TASK_REQUESTS_QUERY
