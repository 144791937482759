import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { BRAND_QUERY } from '../../brands/graphql'

const useCachedBrand = () => {
  const { brandId } = useParams()

  const { data: { brand } = {} } = useQuery(BRAND_QUERY, {
    variables: {
      id: brandId,
    },
    fetchPolicy: 'cache-only',
  })

  return brandId ? brand : null
}

export default useCachedBrand
