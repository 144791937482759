import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Checkbox, Spin, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { handleMutationError } from '../../../../common/helpers'
import { stylePropType } from '../../../../common/propTypes'

import { PUBLISH_DISH_MUTATION } from '../../../graphql'
import { useSaveBrandDish } from '../../../hooks'
import { dishPropType } from '../../../propTypes'
import { removeUnsavedDish } from '../../../redux'

const PublishCheckbox = ({
  dish,
  disabled,
  unsaved,
  checked,
  style,
  track,
  children,
  big,
  tooltip,
}) => {
  const { id } = dish || {}
  const dispatch = useDispatch()
  const { brandId } = useParams()

  const { save } = useSaveBrandDish({
    brandId,
    dishId: id,
    originalDish: dish,
  })

  const [publishDish, { loading }] = useMutation(PUBLISH_DISH_MUTATION, {
    onError: handleMutationError,
  })

  const handlePublishClick = useCallback(
    async ({ target: { checked: newCheckedState } }) => {
      if (unsaved) {
        await save({
          callback: async ({ id: upsertedDishId }) => {
            await publishDish({
              variables: { id: upsertedDishId, published: newCheckedState },
            })
            dispatch(removeUnsavedDish(id))
          },
        })
      } else {
        await publishDish({ variables: { id, published: newCheckedState } })
      }
    },
    [unsaved, save, publishDish, dispatch, id],
  )

  const onClick = useCallback(
    e => {
      e.stopPropagation()
      track()
    },
    [track],
  )

  return loading ? (
    <Spin size="small" style={style} />
  ) : (
    <Tooltip title={tooltip}>
      <Checkbox
        className={big ? 'big-checkbox' : undefined}
        disabled={disabled}
        checked={checked}
        onChange={handlePublishClick}
        onClick={onClick}
        style={style}
      >
        {children}
      </Checkbox>
    </Tooltip>
  )
}

PublishCheckbox.propTypes = {
  dish: dishPropType.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  unsaved: PropTypes.bool,
  style: stylePropType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  track: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  big: PropTypes.bool,
}

PublishCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  unsaved: false,
  children: null,
  style: undefined,
  tooltip: undefined,
  big: false,
}

export default memo(PublishCheckbox)
