/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app'

import 'firebase/analytics'

const firebaseConfig = {
  apiKey: window._env_.REACT_APP_FIREBASE_API_KEY,
  projectId: window._env_.REACT_APP_FIREBASE_PROJECT_ID,
  appId: window._env_.REACT_APP_FIREBASE_APP_ID,
  measurementId: window._env_.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig)
// const analytics = app.analytics()

let appUserId
let appUserRoles

export const fireEvent = (eventName, args = {}) => {}
// analytics.logEvent(eventName, {
//   timestamp: Math.floor(new Date().getTime() / 1000),
//   userId: appUserId,
//   userRoles: appUserRoles,
//   ...args,
// })

/**
 * Sets the user id and roles
 * Leave empty to remove the properties
 */
export const setAnalyticsUser = ({ id, roles } = {}) => {
  // appUserId = id
  // appUserRoles = (roles || []).map(({ name }) => name)
}
