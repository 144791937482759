import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_ROUTES } from '../../common/constants'

import {
  CleanupTasks,
  CreateMlTask,
  CreateReviewTask,
  MlCleanupTask,
  ReplaceCleanupTask,
  ReviewCleanupTask,
} from './pages'

const CleanupTasksRouter = () => (
  <Switch>
    <Route
      exact
      path={APP_ROUTES.CLEANUP_TASK_ROUTES.CleanupTasks}
      component={CleanupTasks}
    />
    <Route
      exact
      path={APP_ROUTES.CLEANUP_TASK_ROUTES.ReviewCleanupTaskDetails}
      component={ReviewCleanupTask}
    />
    <Route
      exact
      path={APP_ROUTES.CLEANUP_TASK_ROUTES.ReviewCleanupTaskCreate}
      component={CreateReviewTask}
    />
    <Route
      exact
      path={APP_ROUTES.CLEANUP_TASK_ROUTES.ReplaceCleanupTaskDetails}
      component={ReplaceCleanupTask}
    />
    <Route
      exact
      path={APP_ROUTES.CLEANUP_TASK_ROUTES.MlCleanupTaskCreate}
      component={CreateMlTask}
    />
    <Route
      exact
      path={APP_ROUTES.CLEANUP_TASK_ROUTES.MlCleanupTaskDetails}
      component={MlCleanupTask}
    />
  </Switch>
)

export default CleanupTasksRouter
