import { gql } from '@apollo/client'

import {
  ALLERGEN_LABEL_LEGEND_FRAGMENT,
  DIET_LABEL_LEGEND_FRAGMENT,
} from '../../../common/graphql'

const RESET_STATUS_MUTATION = gql`
  mutation resetStatus($input: ResetStatusInput!) {
    resetStatus(input: $input) {
      id
      status

      dietLabelLegends {
        ...DietLabelLegendFragment
      }

      allergenLabelLegends {
        ...AllergenLabelLegendFragment
      }
    }
  }
  ${DIET_LABEL_LEGEND_FRAGMENT}
  ${ALLERGEN_LABEL_LEGEND_FRAGMENT}
`

export default RESET_STATUS_MUTATION
