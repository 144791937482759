const { reduce, sortBy } = require('lodash')

const replaceTextFragments = (text, fragments) => {
  let indexOffset = 0

  const sortedFragments = sortBy(fragments, 'index[0]')

  return reduce(
    sortedFragments,
    (acc, fragment) => {
      const result =
        acc.substring(0, fragment.index[0] + indexOffset) +
        fragment.word +
        acc.substring(fragment.index[1] + indexOffset)

      indexOffset = result.length - text.length

      return result
    },
    text,
  )
}

export default replaceTextFragments
