import { gql } from '@apollo/client'

import { DISHES_SUMMARY_FRAGMENT } from '../../../common/graphql'

import { SIMPLE_LOCATION_FRAGMENT } from '../../../locations/graphql'
import { SIMPLE_BRAND_FRAGMENT } from '../fragments'

const BRANDS_QUERY = gql`
  query brandsQuery(
    $pagination: PaginationInput!
    $filters: BrandsFiltersInput!
  ) {
    brands(pagination: $pagination, filters: $filters) {
      results {
        ...SimpleBrandFragment
        locations {
          ...SimpleLocationFragment
        }
      }
      total
    }
  }
  ${SIMPLE_BRAND_FRAGMENT}
  ${DISHES_SUMMARY_FRAGMENT}
  ${SIMPLE_LOCATION_FRAGMENT}
`

export default BRANDS_QUERY
