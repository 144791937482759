import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { pickBy } from 'lodash'
import { assign } from 'lodash/fp'
import { parse, stringify } from 'qs'

import { isFalsy } from '../utils'

const customDecoder = ({
  parseNumbers = true,
  parseBool = true,
  ignoreNull = true,
  ignoreEmptyString = true,
}) =>
  function decoder(str, _decoder, charset) {
    const strWithoutPlus = str.replace(/\+/g, ' ')
    if (charset === 'iso-8859-1') {
      return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
    }

    if (parseNumbers && /^(\d+|\d*\.\d+)$/.test(str)) {
      return parseFloat(str)
    }

    if (ignoreEmptyString && str.length === 0) {
      return null
    }

    const keywords = {
      null: ignoreNull ? undefined : null,
      undefined,
    }

    if (str in keywords) {
      return keywords[str]
    }

    const boolKeywords = {
      true: true,
      false: false,
    }

    if (parseBool && str in boolKeywords) {
      return boolKeywords[str]
    }

    try {
      return decodeURIComponent(strWithoutPlus)
    } catch (e) {
      return strWithoutPlus
    }
  }

const useQueryParams = () => {
  const history = useHistory()
  const { search, pathname } = useLocation()

  const queryObject = useMemo(
    () =>
      parse(search, {
        ignoreQueryPrefix: true,
        decoder: customDecoder({}),
      }),
    [search],
  )

  const setQueryParams = useCallback(
    (queryParamValues, replace) => {
      const newQueryString = stringify(
        pickBy(
          assign(queryObject, queryParamValues),
          value => !isFalsy(value, { allowZero: true }),
        ),
        {
          addQueryPrefix: true,
          encode: true,
        },
      )

      if (replace) {
        history.replace(`${pathname}${newQueryString}`)
      } else {
        history.push(`${pathname}${newQueryString}`)
      }
    },
    [history, pathname, queryObject],
  )

  return { ...queryObject, setQueryParams }
}

export default useQueryParams
