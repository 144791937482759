import { gql } from '@apollo/client'

const UPDATE_ML_CLEANUP_QA_DONE_MUTATION = gql`
  mutation updateMlCleanupQaDone(
    $ids: [ID]!
    $taskId: ID!
    $metadataType: String!
    $value: Boolean!
  ) {
    updateMlCleanupQaDone(
      ids: $ids
      taskId: $taskId
      metadataType: $metadataType
      value: $value
    ) {
      ... on DishTypeMlCleanup {
        id
        isQaDone
      }

      ... on CourseTypeMlCleanup {
        id
        isQaDone
      }
    }
  }
`

export default UPDATE_ML_CLEANUP_QA_DONE_MUTATION
