import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { ClearOutlined } from '@ant-design/icons'
import { Button, Col, Row, Tooltip } from 'antd'
import { get, includes, isEmpty, isNil, noop } from 'lodash'
import PropTypes from 'prop-types'

import { usePrevious } from '../../../../core/hooks'

import { HighlightingTextField } from '../../../../common/components'
import { AVAILABLE_HOTKEYS } from '../../../../common/constants'
import {
  clearMiscAndChoice,
  containsMiscOrChoiceText,
} from '../../../../common/utils'

import { DishEditableVariousTextRowContext } from '../../../contexts'
import { useEditableCellHotkeyEdit } from '../../../hooks'
import { dishPropType } from '../../../propTypes'

const styles = {
  editableDiv: {
    cursor: 'text',
    marginLeft: '0.5rem',
  },
}

const EditableCellHighlightingTextField = ({
  name,
  disabled,
  highlights,
  inputStyle,
  contextToUse,
  dish,
}) => {
  const { saveVariousText } = useContext(contextToUse)
  const [editing, setEditing] = useState(false)
  const [newValue, setNewValue] = useState()
  const { aiSuggestions, ...rest } = dish
  const cachedValue = get(rest, name)
  const styledFieldName = `styled${name[0].toUpperCase()}${name.substring(
    1,
    name.length,
  )}`
  const cachedStyledValue = get(rest, styledFieldName) || cachedValue
  const hasMiscAndChoice = containsMiscOrChoiceText(cachedStyledValue)
  const { forceEditState, isFocusOnCell, setHotkeyEditMode } =
    useEditableCellHotkeyEdit(dish.id, name)

  useEffect(() => {
    setNewValue({ styledText: cachedStyledValue, text: cachedValue })
  }, [cachedStyledValue, cachedValue])

  const handleSave = useCallback(
    (styledText, text) => {
      if (!isNil(styledText) && styledText !== cachedStyledValue) {
        const hasAiErrors = !isEmpty(
          get(aiSuggestions, 'miscAndChoice.messages'),
        )

        saveVariousText(
          dish,
          {
            id: dish.id,
            [name]: text,
            [styledFieldName]: styledText,
            ...(hasAiErrors && {
              aiSuggestions: { miscAndChoice: { certainty: 1 } },
            }),
          },
          name,
        )
      }
    },
    [
      aiSuggestions,
      cachedStyledValue,
      dish,
      name,
      saveVariousText,
      styledFieldName,
    ],
  )

  const prevForceEditState = usePrevious(forceEditState)
  const handleSaveFromEdit = useCallback(() => {
    setEditing(false)
    setHotkeyEditMode(false)
    handleSave(get(newValue, 'styledText'), get(newValue, 'text'))
  }, [setHotkeyEditMode, handleSave, newValue])

  useEffect(() => {
    if (!prevForceEditState && forceEditState) {
      setEditing(true)
    }
  }, [prevForceEditState, forceEditState])

  const handleClick = useCallback(() => {
    setEditing(true)
    setHotkeyEditMode(false)
  }, [setHotkeyEditMode])

  const handleClearMiscAndChoiceText = useCallback(() => {
    const clearedStyledValue = clearMiscAndChoice(cachedStyledValue)
    handleSave(clearedStyledValue, cachedValue)
    setNewValue({ styledText: clearedStyledValue, text: cachedValue })
  }, [cachedStyledValue, cachedValue, handleSave])

  useHotkeys(
    AVAILABLE_HOTKEYS.ESC.hotkey,
    noop,
    {
      enabled: editing,
      enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
      filter: () => {
        if (includes(['name', 'description', 'ingredientsText'], name)) {
          handleSaveFromEdit()
          return true
        }
        return false
      },
    },
    [name, editing],
  )

  // Use C hotkey to clear text when row is in focus
  useHotkeys(
    AVAILABLE_HOTKEYS.C.hotkey,
    handleClearMiscAndChoiceText,
    {
      enabled: !editing && isFocusOnCell && !disabled && hasMiscAndChoice,
    },
    [
      editing,
      isFocusOnCell,
      disabled,
      hasMiscAndChoice,
      handleClearMiscAndChoiceText,
    ],
  )

  return editing ? (
    <HighlightingTextField
      style={inputStyle}
      value={cachedStyledValue}
      newValue={get(newValue, 'styledText')}
      onValueChanged={setNewValue}
      highlightingList={highlights}
      onBlur={handleSaveFromEdit}
    />
  ) : (
    <Row gutter={16} align="middle">
      {hasMiscAndChoice && !disabled && (
        <Col span={1}>
          <Tooltip title="Clear misc&choice text">
            <Button
              shape="circle"
              onClick={handleClearMiscAndChoiceText}
              icon={<ClearOutlined />}
            />
          </Tooltip>
        </Col>
      )}

      <Col span={23}>
        <div
          style={styles.editableDiv}
          aria-hidden="true"
          onClick={disabled ? undefined : handleClick}
        >
          <HighlightingTextField
            value={get(newValue, 'styledText') || cachedStyledValue}
            highlightingList={highlights}
            disabled
          />
        </div>
      </Col>
    </Row>
  )
}

EditableCellHighlightingTextField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      word: PropTypes.string.isRequired,
      matchType: PropTypes.string,
    }),
  ),
  inputStyle: PropTypes.object,
  contextToUse: PropTypes.object,
  dish: dishPropType.isRequired,
}

EditableCellHighlightingTextField.defaultProps = {
  disabled: false,
  highlights: [],
  inputStyle: {},
  contextToUse: DishEditableVariousTextRowContext,
}

export default EditableCellHighlightingTextField
