import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Checkbox,
  Col,
  InputNumber,
  notification,
  Popconfirm,
  Row,
} from 'antd'

import { handleMutationError } from '../../../../common/helpers'

import {
  CANCEL_INGREDIENTS_RERUN_MUTATION,
  START_INGREDIENTS_RERUN_MUTATION,
} from '../../../graphql'

const styles = {
  button: {
    marginRight: 6,
  },
  dishCountInput: {
    width: 200,
    marginLeft: 10,
  },
  dishCountContainer: {
    marginBottom: 12,
  },
}

const IngredientsRerunActionButtons = () => {
  const [dishCount, setDishCount] = useState(null)
  const [useDishCount, setUseDishCount] = useState(false)

  const [start, { loading: isStartLoading }] = useMutation(
    START_INGREDIENTS_RERUN_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: () => {
        notification.success({
          message: 'Updated',
          description: `Ingredient rerun start command sent`,
          placement: 'topLeft',
        })
      },
    },
  )

  const [cancel, { loading: isCancelLoading }] = useMutation(
    CANCEL_INGREDIENTS_RERUN_MUTATION,
    {
      onError: handleMutationError,
      onCompleted: () => {
        notification.success({
          message: 'Updated',
          description: `Ingredient rerun stop command sent`,
          placement: 'topLeft',
        })
      },
    },
  )

  const handleStart = useCallback(() => {
    start({
      variables: {
        dishCount: useDishCount ? dishCount : null,
      },
    })
  }, [start, dishCount, useDishCount])

  const onDishCountChange = useCallback(value => {
    setDishCount(value)
  }, [])

  const onUseDishCountChange = useCallback(e => {
    setUseDishCount(e.target.checked)
  }, [])

  return (
    <Row>
      <Col span={24} style={styles.dishCountContainer}>
        <Checkbox onChange={onUseDishCountChange}>Use dish count</Checkbox>
        <InputNumber
          style={styles.dishCountInput}
          disabled={!useDishCount}
          onChange={onDishCountChange}
          value={dishCount}
        />
      </Col>

      <Col span={24}>
        <Popconfirm
          title="Are you sure you want to start a rerun?"
          onConfirm={handleStart}
        >
          <Button style={styles.button} type="primary" loading={isStartLoading}>
            Start
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Are you sure you want to cancel the pending rerun"
          onConfirm={cancel}
        >
          <Button style={styles.button} loading={isCancelLoading}>
            Cancel
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  )
}

export default IngredientsRerunActionButtons
