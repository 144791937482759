import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import PropTypes from 'prop-types'

import { AVAILABLE_HOTKEYS } from '../../common/constants'
import { useRowHighlight } from '../../common/hooks'

import { DishPopoverTableFocusActionsContext } from '../contexts'

const DishPopoverTableFocusActionsProvider = ({ children }) => {
  const [navigationEnabled, setNavigationEnabled] = useState(false)

  const { initHighlight, selectedRowKey } = useRowHighlight({
    tableSelector: '.dish-popover-table .ant-table-container table',
    rowsSelector:
      '.dish-popover-table .ant-table-container table tbody > tr:not(.ant-table-measure-row)',
    scrollContainerSelector: '.dish-popover-table .ant-table-container',
    disableHotkeys: !navigationEnabled,
    disabledCellSelection: true,
    disabledRowSelection: !navigationEnabled,
  })

  useEffect(() => {
    setTimeout(initHighlight, 0)
  }, [initHighlight])

  // the first press of the "down" key will activate the table navigation and bring the table into view
  // only users that have permissions on that brand can activate the navigation
  useHotkeys(
    AVAILABLE_HOTKEYS.DOWN.hotkey,
    async () => {
      setNavigationEnabled(true)
      const [scrollContainer] = document.querySelectorAll(
        '.dish-popover-table .ant-table-container',
      )
      if (scrollContainer) {
        scrollContainer.scrollIntoView()
      }
    },
    {
      enabled: !navigationEnabled,
    },
    [navigationEnabled],
  )

  return (
    <DishPopoverTableFocusActionsContext.Provider
      value={{
        selectedRowKey,
      }}
    >
      {children}
    </DishPopoverTableFocusActionsContext.Provider>
  )
}

DishPopoverTableFocusActionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DishPopoverTableFocusActionsProvider
