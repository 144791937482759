import React, { memo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { filter, get, isEmpty, isNil, map, omit } from 'lodash'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../../../core/hooks'

import { DiscardButton } from '../../../../common/components'
import { APP_ROUTES, EVENT_TYPES } from '../../../../common/constants'
import { useAppLoading, useCachedBrand } from '../../../../common/hooks'
import { removeUnsavedAiSuggestionsForOwner } from '../../../../common/redux/actions'
import { googleAnalyticsEventsService } from '../../../../common/services'
import { findNodesBy } from '../../../../common/utils'

import { MENU_TITLE_STATUS_TYPE } from '../../../../menuTitles/constants'
import { setMenuTitles } from '../../../../menuTitles/redux'
import { menuTitleTransformer } from '../../../../menuTitles/transformers'
import { transformMenuTitleFromDto } from '../../../../menuTitles/transformers/menuTitleTransformer'
import { MENU_TITLE } from '../../../constants'
import { useUpsertBrand } from '../../../hooks'
import { brandTransformer } from '../../../transformers'

const BrandDiscardButton = ({ menuTitlesHighlightWords }) => {
  const { brandId } = useParams()
  const dispatch = useDispatch()
  const { isLoading, startLoading, stopLoading } = useAppLoading()
  const upsertBrand = useUpsertBrand({ brandId })

  const {
    reset,
    formState: { isDirty },
  } = useFormContext()

  const menuTitleSuggestions = useShallowEqualSelector(({ aiSuggestions }) =>
    get(aiSuggestions, `brands.${brandId}.${MENU_TITLE}`),
  )

  const {
    state: { isDirty: isMenuTitlesDirty },
  } = useShallowEqualSelector(state => get(state, 'unsaved.unsavedMenuTitles'))

  const brand = useCachedBrand()

  const handleDiscardChanges = useCallback(async () => {
    googleAnalyticsEventsService.fireEvent(
      EVENT_TYPES.BRAND_EVENTS.CANCEL_SAVE,
      { brandId },
    )
    const originalBrand = brandTransformer.transformBrandFromDto(brand)
    reset(
      omit(
        {
          ...originalBrand,
          locationTypeId: get(originalBrand, 'locationType.id') || null,
          cuisineTypeId: get(originalBrand, 'cuisineType.id') || null,
        },
        ['locationType', 'cuisineType', 'menuTitles'],
      ),
      { isDirty: false },
    )

    if (
      isEmpty(menuTitleSuggestions) &&
      !isEmpty(
        findNodesBy(
          brand.menuTitles,
          node => node.status === MENU_TITLE_STATUS_TYPE.PENDING,
        ),
      )
    ) {
      startLoading()
      await upsertBrand({
        id: brand.id,
        menuTitles: map(
          filter(brand.menuTitles, ({ menuId }) => !isNil(menuId)),
          menuRoot =>
            menuTitleTransformer.transformMenuTitleDtoToDiscardDto(menuRoot),
        ),
      })
      stopLoading()
    }

    dispatch(
      setMenuTitles({
        menuTitles: map(brand.menuTitles, (menuTitleRoot, index) =>
          transformMenuTitleFromDto(
            menuTitleRoot,
            menuTitlesHighlightWords,
            0,
            `[${index}]`,
          ),
        ),
        state: { isDirty: false, isError: false },
      }),
    )
    dispatch(
      removeUnsavedAiSuggestionsForOwner({ ownerId: brandId, type: 'brands' }),
    )
  }, [
    brand,
    brandId,
    dispatch,
    menuTitleSuggestions,
    menuTitlesHighlightWords,
    reset,
    startLoading,
    stopLoading,
    upsertBrand,
  ])

  if (isNil(brandId)) {
    return (
      <Link to={APP_ROUTES.BRANDS_AND_LOCATIONS_ROUTES.BrandsAndLocations}>
        <DiscardButton />
      </Link>
    )
  }

  return (
    <DiscardButton
      isLoading={isLoading}
      disabled={
        isEmpty(menuTitleSuggestions) ? !(isDirty || isMenuTitlesDirty) : false
      }
      onClick={handleDiscardChanges}
    />
  )
}

BrandDiscardButton.propTypes = {
  menuTitlesHighlightWords: PropTypes.object,
}

BrandDiscardButton.defaultProps = {
  menuTitlesHighlightWords: {},
}

export default memo(BrandDiscardButton)
