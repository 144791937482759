import { createPayloadAction } from '../../../common/utils'

import { UNSAVED_DISH_ACTION_TYPES } from '../actionTypes'

export const addUnsavedDish = createPayloadAction(
  UNSAVED_DISH_ACTION_TYPES.ADD_UNSAVED_DISH,
)
export const duplicateDish = createPayloadAction(
  UNSAVED_DISH_ACTION_TYPES.DUPLICATE_DISH,
)
export const removeUnsavedDish = createPayloadAction(
  UNSAVED_DISH_ACTION_TYPES.REMOVE_UNSAVED_DISH,
)
