import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'

import { Spinner } from '../../../../core/components'
import { useDocumentTitle } from '../../../../core/hooks'

import { PageWithHeader } from '../../../../common/components'
import { MENU_ENTRY, PERMISSIONS } from '../../../../common/constants'
import { useUserHasPermissions } from '../../../../common/hooks'
import { setActiveUserEmail } from '../../../../common/redux/actions'

import { userIdNew } from '../../../constants'
import {
  COMPETENCIES_QUERY,
  DEPARTMENTS_QUERY,
  LOGGED_IN_USER_QUERY,
  QUALIFICATIONS_QUERY,
  USER_BY_ID_QUERY,
} from '../../../graphql'
import { createUserValidationSchema } from '../../../schemas'
import { userTransformer } from '../../../transformers'
import { UserDiscardButton, UserSaveButton } from '../../molecules'
import { UserEditorForm } from '../../sections'

const UserEditorPage = () => {
  const { id: userId } = useParams()
  const documentTitle = userId ? 'Edit user' : 'Create user'
  useDocumentTitle(documentTitle)
  const dispatch = useDispatch()

  const hasChangePermission = useUserHasPermissions([PERMISSIONS.USERS.UPDATE])

  const { data = {}, loading: isLoadingUser } = useQuery(
    hasChangePermission ? USER_BY_ID_QUERY : LOGGED_IN_USER_QUERY,
    {
      ...(hasChangePermission && { variables: { id: userId }, skip: !userId }),
    },
  )
  const user = data.user || data.loggedInUser

  const { data: { departments } = {} } = useQuery(DEPARTMENTS_QUERY)
  const { data: { qualifications } = {} } = useQuery(QUALIFICATIONS_QUERY)
  const { data: { competencies } = {} } = useQuery(COMPETENCIES_QUERY)

  const { reset, ...restFormMethos } = useForm({
    resolver: yupResolver(createUserValidationSchema(departments)),
    defaultValues: {
      isNameGraphRelevant: true,
      isBirthdateGraphRelevant: false,
      isGenderGraphRelevant: false,
      isEducationGraphRelevant: false,
      isDepartmentGraphRelevant: true,
      isQualificationGraphRelevant: true,
      isCompetencyGraphRelevant: true,
      isCuisineKnowlegeGraphRelevant: true,
      isLanguageGraphRelevant: true,
      isCountryGraphRelevant: true,
      isTimeZoneGraphRelevant: true,
      isWorkScheduleGraphRelevant: false,
      isPhoneGraphRelevant: false,
      isEmailGraphRelevant: false,
      isJoinedAtGraphRelevant: false,
      isPreviousWorkExperienceGraphRelevant: false,
      isStatusGraphRelevant: true,
      isTierGraphRelevant: true,
      isLastReviewAtGraphRelevant: false,
    },
  })

  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(setActiveUserEmail(user.email))
      reset(userTransformer.toEditorFields(user), { isDirty: false })
    } else {
      dispatch(setActiveUserEmail('<Unsaved user>'))
    }
  }, [user, dispatch, reset])

  return isLoadingUser ? (
    <Spinner size="large" />
  ) : (
    <FormProvider {...restFormMethos} reset={reset}>
      <PageWithHeader
        menuItem={MENU_ENTRY.USER}
        renderRightMenu={() => (
          <>
            {hasChangePermission && (
              <>
                <UserSaveButton userId={userId || userIdNew} />
                <UserDiscardButton userId={userId || userIdNew} />
              </>
            )}
          </>
        )}
      >
        <UserEditorForm
          user={user}
          departments={departments}
          qualifications={qualifications}
          competencies={competencies}
          isReadOnly={!hasChangePermission}
        />
      </PageWithHeader>
    </FormProvider>
  )
}

export default UserEditorPage
