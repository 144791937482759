import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Input, Row, Select, TreeSelect } from 'antd'
import {
  filter,
  find,
  includes,
  isEmpty,
  map,
  pickBy,
  some,
  split,
  toString,
  values,
} from 'lodash'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { areSameValue, selectFilterOption } from '../../../../core/utils'

import {
  APP_ROUTES,
  CURATION_STATUS,
  DEFAULT_PAGINATION_CONFIG,
  EVENT_TYPES,
  SELECT_ALL_OPTION,
} from '../../../../common/constants'
import {
  getFilterOptions,
  handleTreeSelectCustomDropdownForSearch,
} from '../../../../common/helpers'
import {
  useAuthentication,
  useCurationMetadata,
} from '../../../../common/hooks'
import { googleAnalyticsEventsService } from '../../../../common/services'

import theme from '../../../../theme'
import {
  BRAND_QA_DETAIL_LEVELS,
  BRAND_TAGS,
  BRAND_UNASSIGNED_USER_FILTER_VALUE,
  NEGATIVE_BRAND_TAGS,
} from '../../../constants'
import { useBrandFilters } from '../../../hooks'

const styles = {
  filterInput: {
    width: '100%',
  },
  row: {
    paddingBottom: theme.padding,
  },
  boldText: {
    fontWeight: '600',
  },
}

const BrandLocationListFilter = ({ users }) => {
  const history = useHistory()
  const { userInfo } = useAuthentication()

  const {
    status,
    searchText,
    cuisineTypes,
    tags,
    predominantLanguages,
    unknownWordsLanguages,
    assignedCurator,
    assignedQa,
    qaDetailLevel,
    appropriateForUserId,
    setBrandFilters,
  } = useBrandFilters()

  const { cuisineTypeOptions, languageOptions } = useCurationMetadata()

  const selectedCuisineTypes = useMemo(
    () => (isEmpty(cuisineTypes) ? [] : split(cuisineTypes, ',')),
    [cuisineTypes],
  )

  const selectedPredominantLanguages = useMemo(
    () =>
      isEmpty(predominantLanguages) ? [] : split(predominantLanguages, ','),
    [predominantLanguages],
  )

  const selectedUnknownWordsLanguages = useMemo(
    () =>
      isEmpty(unknownWordsLanguages) ? [] : split(unknownWordsLanguages, ','),
    [unknownWordsLanguages],
  )

  const tagsOptions = useMemo(
    () => [
      ...getFilterOptions(
        pickBy(
          BRAND_TAGS,
          tag =>
            !some(tag.excludedBy, excludedByTag =>
              includes(tags, excludedByTag),
            ),
        ),
        'label',
        'value',
      ),
      ...getFilterOptions(
        pickBy(
          NEGATIVE_BRAND_TAGS,
          tag =>
            !some(tag.excludedBy, excludedByTag =>
              includes(tags, excludedByTag),
            ),
        ),
        'label',
        'value',
      ),
    ],
    [tags],
  )

  const usersWithKnowledgeOptions = useMemo(
    () => [
      ...map(
        filter(users, ({ id }) => areSameValue(id, userInfo?.id)),
        ({ id, name }) => ({ label: name, value: id }),
      ),
      { label: SELECT_ALL_OPTION.label, value: SELECT_ALL_OPTION.label },
      {
        label: BRAND_UNASSIGNED_USER_FILTER_VALUE,
        value: BRAND_UNASSIGNED_USER_FILTER_VALUE,
      },
      ...map(
        filter(users, ({ id }) => !areSameValue(id, userInfo?.id)),
        ({ id, name }) => ({ label: name, value: id }),
      ),
    ],
    [userInfo?.id, users],
  )

  const statusOptions = useMemo(
    () => [
      SELECT_ALL_OPTION,
      ...getFilterOptions(CURATION_STATUS, 'label', 'value'),
    ],
    [],
  )

  const handleAddBrandClick = useCallback(() => {
    googleAnalyticsEventsService.fireEvent(EVENT_TYPES.BRAND_EVENTS.NEW_CLICK)
    history.push(APP_ROUTES.BRAND_ROUTES.NewBrand)
  }, [history])

  const setSearchFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        searchText: value,
      })
    },
    [setBrandFilters],
  )

  const setStatusFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        status: value,
      })
    },
    [setBrandFilters],
  )

  const setAssignedCuratorFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        assignedCurator: value,
      })
    },
    [setBrandFilters],
  )

  const setAssignedQaFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        assignedQa: value,
      })
    },
    [setBrandFilters],
  )

  const setQaDetailLevelFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        qaDetailLevel: value,
      })
    },
    [setBrandFilters],
  )

  const setTagsFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        tags: value,
      })
    },
    [setBrandFilters],
  )

  const setCuisineTypesFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        cuisineTypes: value,
      })
    },
    [setBrandFilters],
  )

  const setPredominantLanguagesFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        predominantLanguages: value,
      })
    },
    [setBrandFilters],
  )

  const setUnknownWordsLanguagesFilterValue = useCallback(
    value => {
      setBrandFilters({
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        unknownWordsLanguages: value,
      })
    },
    [setBrandFilters],
  )

  const handleUserChange = useCallback(
    filterValue => {
      if (
        includes(
          [SELECT_ALL_OPTION.label, BRAND_UNASSIGNED_USER_FILTER_VALUE],
          filterValue,
        )
      ) {
        return setBrandFilters({
          appropriateForUserId: filterValue,
          currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
          predominantLanguages: [],
          unknownWordsLanguages: [],
          cuisineTypes: [],
        })
      }

      const newUser = find(users, ({ id }) => id === filterValue)
      setBrandFilters({
        appropriateForUserId: filterValue,
        currentPage: DEFAULT_PAGINATION_CONFIG.PAGE,
        cuisineTypes: map(newUser?.cuisineTypes, 'id'),
        predominantLanguages: map(newUser?.languages, 'id'),
        unknownWordsLanguages: map(newUser?.languages, 'id'),
      })
    },
    [setBrandFilters, users],
  )

  return (
    <>
      <Row style={styles.row}>
        <Button onClick={handleAddBrandClick}>Add brand</Button>
      </Row>

      <Row gutter={16} style={styles.row}>
        <Col span={6}>
          <Input.Search
            placeholder="Search Brands and Locations..."
            defaultValue={searchText}
            onSearch={setSearchFilterValue}
            style={styles.filterInput}
          />
        </Col>
      </Row>
      <Row gutter={16} style={styles.row}>
        <GridFormItem span={3} label="Status">
          <Select
            options={statusOptions}
            value={status}
            onChange={setStatusFilterValue}
            style={styles.filterInput}
          />
        </GridFormItem>
        <GridFormItem span={3} label="Assigned Curator">
          <Select
            showSearch
            filterOption={selectFilterOption}
            allowClear
            value={toString(assignedCurator)}
            placeholder="Filter by Curator..."
            onChange={setAssignedCuratorFilterValue}
            style={styles.filterInput}
            options={usersWithKnowledgeOptions}
          />
        </GridFormItem>
        <GridFormItem span={3} label="Assigned Qa">
          <Select
            showSearch
            filterOption={selectFilterOption}
            allowClear
            value={toString(assignedQa)}
            placeholder="Filter by Qa..."
            onChange={setAssignedQaFilterValue}
            style={styles.filterInput}
            options={usersWithKnowledgeOptions}
          />
        </GridFormItem>
        <GridFormItem span={4} label="QA detail level">
          <Select
            filterOption={selectFilterOption}
            allowClear
            value={qaDetailLevel}
            placeholder="Filter by QA detail level"
            onChange={setQaDetailLevelFilterValue}
            style={styles.filterInput}
            options={map(values(BRAND_QA_DETAIL_LEVELS), level => ({
              label: level,
              value: level,
            }))}
          />
        </GridFormItem>
        <GridFormItem span={5} label="Brand tags">
          <Select
            filterOption={selectFilterOption}
            allowClear
            mode="multiple"
            value={tags}
            onChange={setTagsFilterValue}
            style={styles.filterInput}
            options={tagsOptions}
            placeholder="Filter by brand tags"
          />
        </GridFormItem>
      </Row>

      <Row gutter={16} style={styles.row}>
        <GridFormItem labelAbove span={6} label="Appropriate for">
          <Select
            allowClear={false}
            showSearch
            placeholder="Sort by User knowledge..."
            value={toString(appropriateForUserId)}
            options={usersWithKnowledgeOptions}
            filterOption={selectFilterOption}
            onChange={handleUserChange}
          />
        </GridFormItem>
        <GridFormItem labelAbove label="Cuisine Types" span={6}>
          <TreeSelect
            allowClear
            showSearch
            multiple
            placeholder="Select Cuisine Type..."
            value={selectedCuisineTypes}
            treeData={cuisineTypeOptions}
            treeNodeFilterProp="title"
            dropdownRender={handleTreeSelectCustomDropdownForSearch}
            onChange={setCuisineTypesFilterValue}
          />
        </GridFormItem>

        <GridFormItem labelAbove span={6} label="Predominant languages">
          <Select
            allowClear
            showSearch
            mode="multiple"
            placeholder="Select Language..."
            value={selectedPredominantLanguages}
            options={languageOptions}
            filterOption={selectFilterOption}
            onChange={setPredominantLanguagesFilterValue}
          />
        </GridFormItem>
        <GridFormItem labelAbove span={6} label="Unknown words languages">
          <Select
            allowClear
            showSearch
            mode="multiple"
            placeholder="Select Language..."
            value={selectedUnknownWordsLanguages}
            options={languageOptions}
            filterOption={selectFilterOption}
            onChange={setUnknownWordsLanguagesFilterValue}
          />
        </GridFormItem>
      </Row>
    </>
  )
}

BrandLocationListFilter.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
}

BrandLocationListFilter.defaultProps = {
  users: [],
}

export default BrandLocationListFilter
