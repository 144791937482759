import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from '../fragments'

const BRAND_QUERY = gql`
  query brandQuery($id: ID!) {
    brand(id: $id) {
      ...BrandFragment
    }
  }
  ${BRAND_FRAGMENT}
`

export default BRAND_QUERY
