const METADATA_TYPE_NAMES = {
  INGREDIENTS: 'Ingredients',
  DISH_TYPES: 'Dish Types',
  LOCATION_TYPES: 'Location Types',
  CUISINE_TYPES: 'Cuisine Types',
  COURSE_TYPES: 'Course Types',
  ALLERGENS: 'Allergens',
}

export default METADATA_TYPE_NAMES
