import { gql } from '@apollo/client'

import LABEL_LEGEND_FRAGMENT from './labelLegendFragment'

const DIET_LABEL_LEGEND_FRAGMENT = gql`
  fragment DietLabelLegendFragment on DietLabelLegend {
    ...LabelLegendFragment
    dietId
  }
  ${LABEL_LEGEND_FRAGMENT}
`

export default DIET_LABEL_LEGEND_FRAGMENT
