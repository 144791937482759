import React, { memo, useCallback, useMemo } from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { Button, Col, Row, Tooltip } from 'antd'
import update from 'immutability-helper'
import { filter, findIndex, get, map } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { labelLegendPropType } from '../../../propTypes'

import LabelLegend from './LabelLegend'

const styles = {
  qaCorrectionsIcon: {
    marginLeft: 8,
  },
}

const createDefaultLabelLegend = (forEntity, options) => ({
  id: shortid.generate(),
  created: true,
  [`${forEntity}Id`]: get(options, '[0].id'),
})

const LabelLegends = ({
  forEntity,
  labelLegends,
  newLabelLegends,
  options,
  onValueChanged,
  disabled,
  showAddButton,
  size,
  valueProp,
  labelProp,
  isQaCorrected,
}) => {
  const nonNullLabelLegends = useMemo(
    () => omitDeep(newLabelLegends || labelLegends || [], '__typename'),
    [newLabelLegends, labelLegends],
  )

  const visibleLabels = useMemo(
    () => filter(nonNullLabelLegends, ({ deleted }) => !deleted),
    [nonNullLabelLegends],
  )

  const handleOnChange = useCallback(
    modifiedLabelLegend => {
      const { id, created, deleted } = modifiedLabelLegend
      const index = findIndex(
        nonNullLabelLegends,
        ({ id: labelLegendId }) => labelLegendId === id,
      )
      let updatedLabelLegends

      if (created && deleted) {
        updatedLabelLegends = update(nonNullLabelLegends, {
          $splice: [[index, 1]],
        })
      } else {
        updatedLabelLegends = update(nonNullLabelLegends, {
          [index]: { $set: modifiedLabelLegend },
        })
      }

      onValueChanged(updatedLabelLegends)
    },
    [nonNullLabelLegends, onValueChanged],
  )

  const addNewLabelLegend = useCallback(() => {
    const updatedLabelLegends = [
      ...nonNullLabelLegends,
      createDefaultLabelLegend(forEntity, options),
    ]
    onValueChanged(updatedLabelLegends)
  }, [forEntity, nonNullLabelLegends, onValueChanged, options])

  return (
    <Row>
      <Col span={24}>
        {map(visibleLabels, labelLegend => (
          <LabelLegend
            key={labelLegend.id}
            size={size}
            labelLegend={labelLegend}
            options={options}
            valueProp={valueProp}
            labelProp={labelProp}
            forEntity={forEntity}
            disabled={disabled}
            onValueChanged={handleOnChange}
          />
        ))}
      </Col>

      {showAddButton && (
        <Col span={20} offset={4}>
          <Button size={size} disabled={disabled} onClick={addNewLabelLegend}>
            Add {forEntity} legend
          </Button>
          {isQaCorrected && (
            <Tooltip title="This legend contains QA corrections">
              <WarningOutlined style={styles.qaCorrectionsIcon} />
            </Tooltip>
          )}
        </Col>
      )}
    </Row>
  )
}

LabelLegends.propTypes = {
  forEntity: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelLegends: PropTypes.arrayOf(labelLegendPropType),
  newLabelLegends: PropTypes.arrayOf(labelLegendPropType),
  onValueChanged: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  showAddButton: PropTypes.bool,
  valueProp: PropTypes.string,
  labelProp: PropTypes.string,
  isQaCorrected: PropTypes.bool,
}

LabelLegends.defaultProps = {
  labelLegends: null,
  newLabelLegends: null,
  onValueChanged: undefined,
  showAddButton: true,
  size: undefined,
  disabled: false,
  valueProp: 'value',
  labelProp: 'label',
  isQaCorrected: false,
}

export default memo(LabelLegends)
