import React from 'react'
import { Switch } from 'react-router-dom'

import { GuestRoute } from '../../common/components/routes'
import { APP_ROUTES } from '../../common/constants'

import { LoginPage, TokenLoginPage } from './pages'

const AccountRouter = () => (
  <Switch>
    <GuestRoute path={APP_ROUTES.ACCOUNT_ROUTES.Login} component={LoginPage} />
    <GuestRoute
      path={APP_ROUTES.ACCOUNT_ROUTES.TokenLogin}
      component={TokenLoginPage}
    />
  </Switch>
)

export default AccountRouter
