import { createContext } from 'react'
import { noop } from 'lodash'

export const defaultState = {
  saveVariousText: noop,
  loading: false,
  suggestVariousText: noop,
}

export default createContext(defaultState)
