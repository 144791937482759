import { gql } from '@apollo/client'

const SEND_BRAND_TO_CURATOR_MUTATION = gql`
  mutation sendBrandToCurator($id: ID!) {
    sendBrandToCurator(id: $id) {
      id
      requiresCuratorAttention
      requiresQaAttention
    }
  }
`

export default SEND_BRAND_TO_CURATOR_MUTATION
