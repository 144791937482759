import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CheckOutlined, CopyTwoTone } from '@ant-design/icons'
import { Button, Col, Row, Tooltip, Typography } from 'antd'
import { get, includes, isNil } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import { GridFormItem } from '../../../../core/components'
import { formatDate } from '../../../../core/utils'

import { CURATION_STATUS } from '../../../../common/constants'
import { handleMutationError } from '../../../../common/helpers'
import { useAuthentication } from '../../../../common/hooks'

import { brandPropType } from '../../../propTypes'
import { brandTransformer } from '../../../transformers'
import { BrandSuggestedWordsButtons } from '../BrandSuggestedWordsButtons'

const styles = {
  updatedText: {
    lineHeight: '24px',
  },
  formItemMargin: {
    margin: 8,
  },
  copiedCheck: { color: '#52c41a' },
}

const BrandModificationDates = ({ brand, isDisabled }) => {
  const [isCopied, setIsCopied] = useState(false)
  const { userInfo } = useAuthentication()

  const {
    id,
    name,
    status,
    updatedBy,
    updatedAt,
    lastImportedBy,
    lastImportedAt,
    latestMenuInfo,
    curatedAt,
    qadAt,
    qqadAt,
    curatedBy,
    qadBy,
    qqadBy,
  } = useMemo(() => brandTransformer.toBrandModificationDates(brand), [brand])

  const isBrandSuggestedWordsButtonsDisabled = useMemo(
    () =>
      isDisabled ||
      !includes(
        [
          CURATION_STATUS.UNKNOWN_WORDS_CURATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_CURATION_CONFIRMATION.value,
          CURATION_STATUS.UNKNOWN_WORDS_QA.value,
        ],
        status,
      ),
    [isDisabled, status],
  )

  const copyScraperQualityTrackerData = useCallback(() => {
    try {
      navigator.clipboard.writeText(
        `${moment(new Date(), moment.defaultFormatUtc).format('ddd DD MMM')}\t${
          get(userInfo, 'email') || get(lastImportedBy, 'email')
        }\t${name}\t${latestMenuInfo.fullAnnotation}`,
      )
      setIsCopied(true)
    } catch (e) {
      handleMutationError(e)
    }
  }, [lastImportedBy, latestMenuInfo, name, userInfo])

  useEffect(() => {
    let copiedTimeout

    if (isCopied) {
      copiedTimeout = setTimeout(() => setIsCopied(false), 5000)
    }

    return () => clearTimeout(copiedTimeout)
  }, [isCopied])

  return (
    <Row gutter={16}>
      <GridFormItem span={4} label="Status">
        <Typography.Text strong>
          {CURATION_STATUS[brand.status].label}
        </Typography.Text>
      </GridFormItem>
      {!isNil(updatedAt) && (
        <Col span={8}>
          <Row>
            <Col span={24}>
              <Typography.Paragraph>Updated</Typography.Paragraph>
            </Col>
            <Typography.Text style={styles.updatedText}>{`${formatDate(
              updatedAt,
            )}${updatedBy ? ` - ${updatedBy.email}` : ''}`}</Typography.Text>
            {!isNil(curatedAt) && !isNil(curatedBy) && (
              <Typography.Text style={styles.updatedText}>{`${formatDate(
                curatedAt,
              )}${
                curatedBy ? `- Curation - ${curatedBy.email}` : ''
              }`}</Typography.Text>
            )}
            {!isNil(qadAt) && !isNil(qadBy) && (
              <Typography.Text style={styles.updatedText}>{`${formatDate(
                qadAt,
              )}${qadBy ? ` - QA - ${qadBy.email}` : ''}`}</Typography.Text>
            )}
            {!isNil(qqadAt) && !isNil(qqadBy) && (
              <Typography.Text style={styles.updatedText}>{`${formatDate(
                qqadAt,
              )}${qqadBy ? ` - QQA - ${qqadBy.email}` : ''}`}</Typography.Text>
            )}
          </Row>
        </Col>
      )}
      {lastImportedAt && (
        <Col span={8}>
          <Row>
            <Col span={24}>
              {latestMenuInfo ? (
                <Typography.Paragraph>
                  Imported{' '}
                  {isCopied ? (
                    <Tooltip title="Copied">
                      <CheckOutlined style={styles.copiedCheck} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Copy for scraper quality tracker">
                      <CopyTwoTone onClick={copyScraperQualityTrackerData} />
                    </Tooltip>
                  )}
                </Typography.Paragraph>
              ) : (
                <Typography.Paragraph>Menus deleted</Typography.Paragraph>
              )}
            </Col>
            <Typography.Paragraph style={styles.updatedText}>{`${formatDate(
              lastImportedAt,
            )} - ${lastImportedBy?.email || ''} - ${
              latestMenuInfo?.importedDishesCount || 0
            } imported dishes`}</Typography.Paragraph>
            {latestMenuInfo?.fullAnnotation && (
              <Typography.Paragraph>
                Menu annotation: {latestMenuInfo?.fullAnnotation}
              </Typography.Paragraph>
            )}
            <Button />
          </Row>
        </Col>
      )}

      <BrandSuggestedWordsButtons
        brandId={id}
        disabled={isBrandSuggestedWordsButtonsDisabled}
      />
    </Row>
  )
}

BrandModificationDates.propTypes = {
  brand: brandPropType.isRequired,
  isDisabled: PropTypes.bool,
}

BrandModificationDates.defaultProps = {
  isDisabled: false,
}

export default BrandModificationDates
