import { useContext } from 'react'
import { castArray, intersection, isArray, isEmpty } from 'lodash'

import { AuthContext } from '../contexts'

const useUserHasPermissions = permissions => {
  const permissionsArray = isArray(permissions)
    ? permissions
    : castArray(permissions)
  const {
    userInfo: { permissions: userPermissions },
  } = useContext(AuthContext)
  return !isEmpty(intersection(userPermissions, permissionsArray))
}

export default useUserHasPermissions
