import { gql } from '@apollo/client'

const UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION = gql`
  mutation updateHumanCleanupCurationValues(
    $ids: [ID]!
    $metadataType: String!
    $curationValues: HumanCleanupCurationValuesInput!
    $isQaAction: Boolean!
  ) {
    updateHumanCleanupCurationValues(
      ids: $ids
      metadataType: $metadataType
      curationValues: $curationValues
      isQaAction: $isQaAction
    ) {
      ... on IngredientHumanCleanup {
        id
        cleanupIngredientIds
        isCurationDone
        isQaDone
      }

      ... on DishTypeHumanCleanup {
        id
        cleanupDishTypeId
        isCurationDone
        isQaDone
      }

      ... on CourseTypeHumanCleanup {
        id
        cleanupCourseTypeId
        isCurationDone
        isQaDone
      }

      ... on CuisineTypeHumanCleanup {
        id
        cleanupCuisineTypeId
        isCurationDone
        isQaDone
      }

      ... on LocationTypeHumanCleanup {
        id
        cleanupLocationTypeId
        isCurationDone
        isQaDone
      }
    }
  }
`

export default UPDATE_HUMAN_CLEANUP_CURATION_VALUES_MUTATION
