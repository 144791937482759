/* eslint-disable no-plusplus */
// We use HH:MM:SS format so we are in sync with PostgreSQL "time without time zone" type
let openTimeSlotStart = 0
let closeTimeSlotStart = 0

export const openTimeSlots = []
export const closeTimeSlots = []

for (let index = 0; index < 96; index++) {
  switch (index % 4) {
    case 0:
      if (index > 3) {
        if (openTimeSlotStart + 1 > 23) {
          openTimeSlotStart = 0
        } else {
          openTimeSlotStart++
        }

        if (closeTimeSlotStart + 1 > 23) {
          closeTimeSlotStart = 0
        } else {
          closeTimeSlotStart++
        }
      }

      openTimeSlots.push(
        `${
          openTimeSlotStart > 9 ? openTimeSlotStart : `0${openTimeSlotStart}`
        }:00:00`,
      )
      closeTimeSlots.push(
        `${
          closeTimeSlotStart > 9 ? closeTimeSlotStart : `0${closeTimeSlotStart}`
        }:00:00`,
      )
      break
    case 1:
      openTimeSlots.push(
        `${
          openTimeSlotStart > 9 ? openTimeSlotStart : `0${openTimeSlotStart}`
        }:15:00`,
      )
      closeTimeSlots.push(
        `${
          closeTimeSlotStart > 9 ? closeTimeSlotStart : `0${closeTimeSlotStart}`
        }:15:00`,
      )
      break
    case 2:
      openTimeSlots.push(
        `${
          openTimeSlotStart > 9 ? openTimeSlotStart : `0${openTimeSlotStart}`
        }:30:00`,
      )
      closeTimeSlots.push(
        `${
          closeTimeSlotStart > 9 ? closeTimeSlotStart : `0${closeTimeSlotStart}`
        }:30:00`,
      )
      break
    case 3:
      openTimeSlots.push(
        `${
          openTimeSlotStart > 9 ? openTimeSlotStart : `0${openTimeSlotStart}`
        }:45:00`,
      )
      closeTimeSlots.push(
        `${
          closeTimeSlotStart > 9 ? closeTimeSlotStart : `0${closeTimeSlotStart}`
        }:45:00`,
      )
      break
    default:
      break
  }
}
