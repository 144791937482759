import { filter, get, initial, isEmpty, isNil, map, some } from 'lodash'

import { areSameValue } from '../../core/utils'

import { findNodeBy, findNodesBy } from '../../common/utils'

export const transformDishToAiRequestDto = (dish, additionalInformation) => {
  let menuTitleList = []
  if (!isNil(dish.menuTitle) && !isEmpty(additionalInformation)) {
    const { menuTitleOptions } = additionalInformation
    const selectedMenuTitle = findNodeBy(menuTitleOptions, ({ id }) =>
      areSameValue(id, dish.menuTitle.id),
    )

    menuTitleList = filter(
      [
        selectedMenuTitle,
        ...findNodesBy(menuTitleOptions, node =>
          some(
            initial(selectedMenuTitle.parents),
            parentId => node.id === parentId,
          ),
        ),
      ],
      ({ parentId }) => !isNil(parentId),
    )

    // this means that the menu title is actually a child of the root menu title and we don't want to send its parentId
    if (menuTitleList.length === 1) {
      menuTitleList[0] = {
        ...menuTitleList[0],
        parentId: null,
      }
    }
  }

  return {
    id: dish.id,
    name: dish.name,
    description: dish.description || '',
    ingredientsText: dish.ingredientsText || '',
    lowPrice: dish.lowPrice || 0,
    highPrice: dish.highPrice || 0,
    alternativeBrandName: dish?.alternativeBrandName,
    brand: {
      id: get(additionalInformation, 'brand.id'),
      name: get(additionalInformation, 'brand.name'),
    },
    menuTitles: map(menuTitleList, ({ id, name, description, parentId }) => ({
      id,
      name,
      description: description || '',
      parentId,
    })),
  }
}

export const transformDishFromAiResponseDto = responseBody =>
  map(responseBody, ({ result, matchType, definiteResult, messages }) =>
    !isEmpty(messages)
      ? { messages }
      : map(result, dishType => ({
          ...dishType,
          // Make sure IDs are passed as strings, not as numbers.
          id: `${dishType.id}`,
          // Remove all decimals except 2
          score: parseFloat((dishType.score * 100).toFixed(2), 10),
          matchType,
          definiteResult,
        })),
  )
