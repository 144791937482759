import { gql } from '@apollo/client'

import { CLEANUP_TASK_FRAGMENT } from '../fragments'

const IMPORT_ML_CLEANUP_TASK_MUTATION = gql`
  mutation importMlCleanupTask($input: MlCleanupTaskImportInput!) {
    importMlCleanupTask(input: $input) {
      ...CleanupTaskFragment
    }
  }
  ${CLEANUP_TASK_FRAGMENT}
`

export default IMPORT_ML_CLEANUP_TASK_MUTATION
