import { gql } from '@apollo/client'

import { USER_REPORT_REQUEST_FRAGMENT } from '../fragments'

const USER_REPORT_REQUESTS_QUERY = gql`
  query userReportRequests(
    $currentPage: Int!
    $pageSize: Int!
    $filters: UserReportRequestsFiltersInput!
  ) {
    userReportRequests(
      pageSize: $pageSize
      currentPage: $currentPage
      filters: $filters
    ) {
      count
      requests {
        ...UserReportRequestFragment
      }
    }
  }
  ${USER_REPORT_REQUEST_FRAGMENT}
`

export default USER_REPORT_REQUESTS_QUERY
